import React from 'react'
import { css, StyleSheet } from 'aphrodite'
import { Form, Formik } from 'formik'
import { components } from 'react-select'
import Highlighter from 'react-highlight-words'
import cn from 'classnames'
import { COLORS } from '../../../utils/colors'
import { useLoad } from '../../../hooks/request'
import SelectForm from '../../common/SelectForm'
import { EMPLOYEE_BY_ROLE } from '../../../urls'
import { CheckSizeM } from '../../svgs/Svgs'

export default function AdminForm({ onSubmit, onClose }) {
    const loadEmployees = useLoad({ url: EMPLOYEE_BY_ROLE, params: { role: 2 } })
    const employees = loadEmployees.response ? loadEmployees.response.data.map((e) => ({
        value: e.id,
        role: e.role,
        photo: e.photo[4],
        position: e.position,
        department: e.department,
        label: `${e.firstName} ${e.lastName}`,
        shortName: e.firstName.slice(0, 1) + e.lastName.slice(0, 1),
    })) : []

    const Option = (props) => {
        const { selectProps, data } = props
        const { label, department, position, photo, shortName } = data

        return (
            <components.Option {...props}>
                <div className="align-center gap-2" style={{ height: 52 }}>
                    {photo ? <img className={css(s.img)} src={photo} alt="#avatar" /> : (
                        <div className={cn('flex-cent', css(s.defaultPhoto))}>
                            <h3>{shortName}</h3>
                        </div>
                    )}

                    <div className="is-flex direction-column align-start justify-center">
                        <Highlighter
                            textToHighlight={label.slice(0, 25) + (label.length > 25 ? '...' : '')}
                            activeClassName="highlight"
                            searchWords={[selectProps.inputValue.trim()]}
                            className={css(s.optLab)}
                        />

                        <div className="align-center gap-1">
                            <h3 className={css(s.txt)}>
                                {department.slice(0, 15) + (department.length > 15 ? '...' : '')}
                            </h3>

                            <span>•</span>

                            <h3 className={css(s.txt)}>
                                {position.slice(0, 15) + (position.length > 15 ? '...' : '')}
                            </h3>
                        </div>
                    </div>
                </div>
            </components.Option>
        )
    }

    return (
        <div className={css(s.wrap)}>
            <Formik initialValues={{ employeeIds: [] }} onSubmit={onSubmit}>
                {() => (
                    <Form className="is-flex direction-column gap-5">
                        <SelectForm
                            isMulti
                            closeMenuOnSelect={false}
                            customComponents={{ Option }}
                            placeholderStyles={{ color: COLORS.gray, fontFamily: 'Roboto, sans-serif' }}
                            valueContainerStyles={{ padding: '11.5px 16px' }}
                            indicatorStyle={{ paddingRight: 10 }}
                            label="Добавление"
                            optStyle={{ padding: '0 16px' }}
                            placeholder="Введите имя"
                            name="employeeIds"
                            options={employees} />

                        <div className="justify-end gap-5">
                            <button onClick={onClose} type="reset" className={css(s.btn, s.reject)}>
                                Отмена
                            </button>

                            <button type="submit" className={css(s.btn, s.approve)}>
                                Готово <CheckSizeM color="#FFFFFF" />
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    )
}

const s = StyleSheet.create({
    wrap: {
        marginTop: 16,
    },
    img: {
        borderRadius: 32,
    },
    defaultPhoto: {
        background: '#1B93C0',
        width: 32,
        height: 32,
        borderRadius: 32,
        fontWeight: '600',
        fontSize: 12,
        color: '#FFFFFF',
        border: '1px solid transparent',
        textTransform: 'uppercase',
    },
    txt: {
        fontWeight: '400',
        fontSize: 11,
        color: COLORS.midGray,
    },
    optLab: {
        fontWeight: '500',
        fontSize: 13,
        color: COLORS.dark,
    },
    btn: {
        height: 32,
        borderRadius: 4,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: 14,
        padding: '0 16px',
        fontWeight: '500',
        cursor: 'pointer',
    },
    reject: {
        color: COLORS.gray,
        border: `1px solid ${COLORS.gray}`,
        backgroundColor: 'transparent',
    },
    approve: {
        gap: 8,
        border: 'none',
        color: COLORS.white,
        background: 'linear-gradient(180deg, #2EA97D 0%, #138753 100%)',
        // eslint-disable-next-line max-len
        boxShadow: '0px 4px 12px rgba(44, 67, 50, 0.1), inset 0px -1px 0px rgba(0, 0, 0, 0.25), inset 0px 1px 0px rgba(255, 255, 255, 0.1)',
    },
})
