import React, { useEffect, useState } from 'react'
import { css, StyleSheet } from 'aphrodite'
import moment from 'moment'
import Calendar from '../common/Calendar'
import EventsBoard from './EventsBoard'
import { useGetRequest } from '../../hooks/request'
import { EVENTS } from '../../urls'

export default function UpcomingEvents() {
    const [date, setDate] = useState(new Date())
    const getEvents = useGetRequest({ url: EVENTS.replace('{date}', moment(date).format('YYYY-MM-DD')) })
    const events = getEvents.response ? getEvents.response.data : []

    useEffect(() => {
        getEvents.request()
    }, [date])

    return (
        <div className={css(s.cont)}>
            <div className={css(s.cl)}>
                <Calendar
                    setDate={setDate}
                    className={css(s.calendar)}
                    mode="single"
                    selected={date}
                    setSelected={setDate} />
            </div>

            <div className={css(s.cross)}>
                <div />
                <div />
            </div>

            <EventsBoard loading={getEvents.loading} date={date} events={events} />
        </div>
    )
}

const s = StyleSheet.create({
    cont: {
        margin: '2rem 0px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: '24.25rem',
        width: '100%',
    },
    cl: {
        width: '39%',
        height: '100%',
        display: 'flex',
    },
    calendar: {
        height: 'calc(100% - 20px)',
        padding: '16px 16px 4px',
        width: 'calc(100% - 32px)',
    },
    cross: {
        height: '90%',
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        width: '1.25rem',
        backgroundColor: '#fff',
        ':nth-child(1n) > *': {
            height: 7,
            width: '100%',
            backgroundColor: '#F6F6F6',
        },
        ':nth-child(1n) > :first-child': {
            borderRadius: '0 0 24px 24px',
        },
        ':nth-child(1n) > :last-child': {
            borderRadius: '24px 24px 0 0',
        },
    },
})
