import React, { useState } from 'react'
import cn from 'classnames'
import { css, StyleSheet } from 'aphrodite'
import { Form, Formik } from 'formik'
import { CheckSizeL, CloseSidebar, Eye, EyeClose, ShieldLock } from '../svgs/Svgs'
import Modal from '../common/Modal'
import { password as passwordValidator, required } from '../../utils/validators'
import FormInput from '../common/FormInput'
import { usePostRequest } from '../../hooks/request'
import { CHANGE_PASSWORD } from '../../urls'
import { useMessage } from '../../hooks/message'
import { COLORS } from '../../utils/colors'
import Button from '../common/Button'

export function PasswordChange({ userLoading }) {
    const changePassword = usePostRequest({ url: CHANGE_PASSWORD })
    const [passGroup, setPassGroup] = useState({ old_password: false, new_password: false, confirm_password: false })
    const [showModal, setShowModal] = useState(false)
    const [showMessage] = useMessage()

    const icons = (name) => (passGroup[name] ? (
        <Eye pointer onClick={() => setPassGroup(({ ...passGroup, [name]: false }))} />
    ) : (
        <EyeClose pointer onClick={() => setPassGroup(({ ...passGroup, [name]: true }))} />
    ))

    async function onSubmit(data, helpers) {
        if (data.new_password !== data.confirm_password) {
            helpers.setFieldError('confirm_password', 'Пароли не совпадают')
            return
        }

        const { error, success } = await changePassword.request({
            data: {
                old_password: data.old_password,
                new_password: data.new_password,
                confirm_password: data.confirm_password,
            },
        })

        if (success) {
            setShowModal(false)
            showMessage('Пароль успешно изменен', 'success-msg')
            return
        }

        showMessage(error.data.errors[0].message || error.data.message, 'error-msg')
    }

    return (
        <React.Fragment>
            <Button
                disabled={userLoading}
                onClick={() => setShowModal(true)}
                className={cn('flex-cent', css(s.btn))}>
                <ShieldLock className="pointer" /> Изменить пароль
            </Button>

            <Modal
                open={showModal}
                onClose={() => setShowModal(false)}
                closeOnDocumentClick
                closeOnEscape>
                <Formik
                    onSubmit={(data, helpers) => onSubmit(data, helpers)}
                    initialValues={{ old_password: '', new_password: '', confirm_password: '' }}>
                    {() => (
                        <Form>
                            <div className={css(s.cont)}>
                                <div className={cn(css(s.hd), 'justify-between')}>
                                    <h3>Изменение пароля</h3>

                                    <CloseSidebar onClick={() => setShowModal(false)} />
                                </div>

                                <div className={css(s.bd)}>
                                    <FormInput
                                        type={passGroup.old_password ? 'text' : 'password'}
                                        label="Старый пароль"
                                        name="old_password"
                                        placeholder="Введите"
                                        labelClassName={css(s.lab)}
                                        validate={required}
                                        fieldClassName={cn(css(s.field), 'dis_sl')}
                                        icon={icons('old_password')}
                                    />

                                    <FormInput
                                        type={passGroup.new_password ? 'text' : 'password'}
                                        label="Новый пароль"
                                        name="new_password"
                                        placeholder="Введите"
                                        labelClassName={css(s.lab)}
                                        validate={passwordValidator}
                                        fieldClassName={cn(css(s.field), 'dis_sl')}
                                        icon={icons('new_password')}
                                        help="* Пароль должен быть минимум 10 символов и включать в себя буквы и цифры"
                                    />

                                    <FormInput
                                        type={passGroup.confirm_password ? 'text' : 'password'}
                                        label="Повторите новый пароль"
                                        name="confirm_password"
                                        placeholder="Введите"
                                        labelClassName={css(s.lab)}
                                        validate={required}
                                        fieldClassName={cn(css(s.field), 'dis_sl')}
                                        icon={icons('confirm_password')}
                                    />
                                </div>

                                <div className={css(s.foot)}>
                                    <button onClick={() => setShowModal(false)}>Отмена</button>

                                    <button type="submit" className="flex-cent gap-2">
                                        Готово <CheckSizeL />
                                    </button>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            </Modal>
        </React.Fragment>
    )
}

const s = StyleSheet.create({
    btn: {
        cursor: 'pointer',
        background: 'transparent',
        border: `1px solid ${COLORS.midGray}`,
        borderRadius: 4,
        color: COLORS.midGray,
        padding: '7px 0',
        width: '11.75rem',
        gap: 8,
        outline: 'none',
        ':hover': {
            boxShadow: '0px 2px 4px 0px rgba(130, 130, 130, 0.15)',
        },
    },
    cont: {
        width: 542,
        backgroundColor: '#fff',
        borderRadius: 4,
    },
    hd: {
        padding: '20px 24px',
        ':nth-child(1n) > :first-child': {
            fontSize: 20,
            fontWeight: '600',
            color: COLORS.dark,
        },
    },
    bd: {
        backgroundColor: '#F9F9F9',
        width: 'calc(100% - 48px)',
        borderTop: `1px solid ${COLORS.lightGray}`,
        borderBottom: `1px solid ${COLORS.lightGray}`,
        padding: '16px 24px',
    },
    foot: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        padding: '16px 24px',
        gap: '1rem',
        fontSize: 14,
        fontWeight: 500,
        ':nth-child(1n) > :first-child': {
            borderRadius: 4,
            color: COLORS.gray,
            padding: '11.25px 16px',
            border: `1px solid ${COLORS.gray}`,
            backgroundColor: '#fff',
            cursor: 'pointer',
        },
        ':nth-child(1n) > :last-child': {
            background: `linear-gradient(180deg, ${COLORS.mainColor} 0%, ${COLORS.lightMainColor} 100%)`,
            // eslint-disable-next-line max-len
            boxShadow: '0px 4px 12px rgba(44, 67, 50, 0.1), inset 0px -1px 0px rgba(0, 0, 0, 0.25), inset 0px 1px 0px rgba(255, 255, 255, 0.1)',
            borderRadius: 4,
            borderWidth: 0,
            padding: '10px 16px',
            color: '#fff',
            cursor: 'pointer',
        },
    },
    lab: {
        fontSize: 12,
        fontWeight: '500',
        color: COLORS.midGray,
        marginBottom: 8,
    },
    field: {
        position: 'relative',
        paddingBottom: '6.4%',
    },
})
