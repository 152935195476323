import isEmpty from 'lodash/isEmpty'
import axios from 'axios'
import baseAxios, { domain } from './request'
import { TOKEN, USER_CHECK } from '../urls'

export function auth() {
    if (isEmpty(localStorage.token)) {
        return { 'accept-language': 'ru' }
    }

    return {
        headers: {
            Authorization: `Bearer ${JSON.parse(localStorage.token)}`,
            'accept-language': 'ru',
        },
    }
}

export function getNewTokenByRefresh() {
    const refreshToken = JSON.parse(localStorage.getItem('refresh_token'))
    if (!refreshToken) clearStorage()

    axios.post(domain + TOKEN, {
        refresh_token: refreshToken,
        grant_type: 'refresh_token',
        client_id: process.env.REACT_APP_CLIENT_ID || '',
        client_secret: process.env.REACT_APP_CLIENT_SECRET || '',
    })
        .then(({ data }) => {
            localStorage.setItem('token', JSON.stringify(data.data.access_token))
            localStorage.setItem('refresh_token', JSON.stringify(data.data.refresh_token))
        })
        .catch(() => clearStorage())
}

export async function checkUser(setText, setClassName) {
    if (!localStorage.token) return { error: 'Error' }

    try {
        await baseAxios({ ...auth(), url: USER_CHECK })
        // eslint-disable-next-line consistent-return
        return { success: true }
    } catch (e) {
        if (e.response === undefined || e.response.data === undefined) {
            setText('Проверьте интернет соединение')
            setClassName('error-msg')
        } else if (e.response.status >= 500) {
            setText('Ошибка сервера.')
            setClassName('error-msg')
        } else if (e.response.status >= 400 && e.response.status !== 401) clearStorage()

        else if (e.response.status === 401) getNewTokenByRefresh()

        // eslint-disable-next-line consistent-return
        return { success: false, error: e }
    }
}

export function clearStorage() {
    localStorage.clear()
    // eslint-disable-next-line no-restricted-globals
    location.reload()
}
