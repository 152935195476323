import React from 'react'
import cn from 'classnames'
import { css, StyleSheet } from 'aphrodite'
import { COLORS } from '../../../utils/colors'
import { LoadingSkeleton } from '../../common/LoadingSkeleton'

export function HeaderSkeleton() {
    return (
        <div className={cn('align-center gap-2', css(s.cont))}>
            <LoadingSkeleton height={18} width={18} />

            <LoadingSkeleton height={18} width={112} /> • <LoadingSkeleton height={18} width={65} />
        </div>
    )
}

export function TableItemSkeleton() {
    return (
        <tr className={css(s.item)}>
            <td>
                <div className="flex-cent">
                    <LoadingSkeleton width={16} height={14} />
                </div>
            </td>

            <td><LoadingSkeleton width={112} height={14} /></td>

            <td><LoadingSkeleton width={112} height={14} /></td>

            <td><LoadingSkeleton width={112} height={14} /></td>

            <td><LoadingSkeleton width={112} height={14} /></td>

            <td>
                <div className="flex-cent">
                    <LoadingSkeleton width={40} height={14} />
                </div>
            </td>

            <td>
                <div className="flex-cent">
                    <LoadingSkeleton width={40} height={14} />
                </div>
            </td>

            <td>
                <div className="flex-cent">
                    <LoadingSkeleton width={40} height={14} />
                </div>
            </td>

            <td><LoadingSkeleton width={28} height={14} /></td>
        </tr>
    )
}

const s = StyleSheet.create({
    cont: {
        padding: '0 16px',
        height: 40,
        borderRadius: '2px 2px 0 0',
        border: '1px solid #EBEBEB',
        borderBottom: `1px solid ${COLORS.lightGray}`,
        boxSizing: 'border-box',
        background: '#EAF4F0',
        color: COLORS.gray,
    },
    title: {
        color: COLORS.midGray,
        fontSize: 9,
        fontWeight: '500',
    },

    item: {
        height: 40,
        background: COLORS.white,
        ':nth-child(1n) > :not(:first-of-type)': {
            paddingLeft: 12,
        },
        ':nth-child(1n) > :not(:last-of-type)': {
            borderRight: `1px solid ${COLORS.lightGray}`,
        },
    },
    math: {
        color: COLORS.gray,
    },
})
