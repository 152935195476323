import React from 'react'
import CandidateKanbanBoard from './CandidateKanbanBoard'
import CandidateTable from './CandidateTable'
import CandidateArchiveTable from './CandidateArchiveTable'

export default function CandidateDashboard({ view, candidateFilter, selectedVacancy, setCandidatesCount }) {
    return view === 'archive' ? (
        <CandidateArchiveTable selectedVacancy={selectedVacancy} setCandidateCount={setCandidatesCount} />
    ) : view === 'board' ? (
        <CandidateKanbanBoard
            selectedVacancy={selectedVacancy}
            setCandidatesCount={setCandidatesCount}
            candidateFilter={candidateFilter}
        />
    ) : (
        <CandidateTable
            candidateFilter={candidateFilter}
            selectedVacancy={selectedVacancy}
            setCandidatesCount={setCandidatesCount}
        />
    )
}
