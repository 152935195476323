import React from 'react'
import { css, StyleSheet } from 'aphrodite'
import Table from '../../common/Table'
import VacancyTableHeader from './VacancyTableHeader'
import VacancyTableItem from './VacancyTableItem'
import VacancyInfoHeader from './VacancyInfoHeader'
import { COLORS } from '../../../utils/colors'
import { HeaderSkeleton } from './LoadingMetrics'
import { useInfiniteScroll } from '../../../hooks/request'
import { REPORT_VACANCY_METRICS_DETAIL } from '../../../urls'

const loadItemArr = new Array(5).fill({ isLoading: true })

export default function VacancyTable({ item = {}, loading, params }) {
    const hasMoreDate = item.datesMeta && item.datesMeta.pageCount > 1

    const loadExtraDates = useInfiniteScroll(
        { url: REPORT_VACANCY_METRICS_DETAIL.replace('{id}', item.id), params },
        [],
        2,
        !loading && hasMoreDate,
    )

    const initialDates = item.dates || []
    let dates = loadExtraDates.response ? [...initialDates, ...loadExtraDates.response.data] : initialDates
    dates = loadExtraDates.loading ? [...dates, ...loadItemArr] : dates

    return (
        <div>
            {!loading ? <VacancyInfoHeader item={item} /> : <HeaderSkeleton />}

            <div className={css(s.cont)}>
                <Table
                    loading={loading}
                    isStripped
                    cssClassName={s.table}
                    isScrollable
                    items={dates || []}
                    customHeader={(<VacancyTableHeader />)}
                    loaderItemCount={10}
                    renderItem={(vacancy, index) => (
                        <VacancyTableItem vacancy={vacancy} index={index} loading={loading} />
                    )} />

                {loadExtraDates.hasMore && (
                    <div ref={loadExtraDates.ref} />
                )}
            </div>

            {/* <VacancyInfoFooter index={index} /> */}
        </div>
    )
}

const s = StyleSheet.create({
    table: {
        border: 'none',
        width: '100%',
        borderBottomColor: COLORS.lightGray,
    },
    cont: {
        border: `1px solid ${COLORS.smoothGray}`,
        maxHeight: 336,
        overflowY: 'auto',
        position: 'relative',
        '::-webkit-scrollbar-track': {
            backgroundColor: 'rgba(201,210,206,0.25)',
        },
    },
})
