import React, { useEffect, useState } from 'react'
import { EditorState, ContentState } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import htmlToDraft from 'html-to-draftjs'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { css, StyleSheet } from 'aphrodite'
import { Field, getIn, useFormikContext } from 'formik'
import cn from 'classnames'
import { COLORS } from '../../utils/colors'

import boldIcon from '../../static/svgs/bold.svg'
import italic from '../../static/svgs/italic.svg'
import underline from '../../static/svgs/underline.svg'
import unordered from '../../static/svgs/unordered.svg'
import ordered from '../../static/svgs/ordered.svg'
import link from '../../static/svgs/link.svg'
import unlink from '../../static/svgs/unlink.svg'
import alignLeft from '../../static/svgs/alignLeft.svg'
import alignCenter from '../../static/svgs/alignCenter.svg'
import alignRight from '../../static/svgs/alignRight.svg'

const defaultContent = `Обязанности:
  •
  •
  •
Требования:
  •
  •
  •
Условия:
  •
  •
  •`

export default function EditorField({
    name,
    label,
    validate,
    onChange,
    defaultValue,
    toolbarClassName,
    wrapperClassName,
}) {
    const [editorState, setEditorState] = useState(() => {
        const content = ContentState.createFromText(defaultContent)
        return EditorState.createWithContent(content)
    })

    const { errors, touched } = useFormikContext()

    const isValid = getIn(errors, name) && getIn(touched, name)

    useEffect(() => {
        if (defaultValue && typeof defaultValue === 'string') {
            setEditorState(() => {
                const contentBlock = htmlToDraft(defaultValue)
                const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks)
                return EditorState.createWithContent(contentState)
            })
        } else if (defaultValue) {
            setEditorState(defaultValue)
        }
    }, [defaultValue])

    const toolbarOptions = {
        options: ['inline', 'list', 'link', 'textAlign'],
        inline: {
            className: css(s.toolbarWrapper),
            options: ['bold', 'italic', 'underline'],
            bold: { icon: boldIcon, title: 'Жирный' },
            italic: { icon: italic, title: 'Курсив' },
            underline: { icon: underline, title: 'Подчеркнутый' },
        },
        list: {
            className: css(s.toolbarWrapper),
            options: ['unordered', 'ordered'],
            unordered: { icon: unordered, title: 'Неупорядоченный Список' },
            ordered: { icon: ordered, title: 'Упорядоченный Список' },
        },
        link: {
            className: css(s.toolbarWrapper),
            options: ['link', 'unlink'],
            link: { icon: link, title: 'Ссылка' },
            unlink: { icon: unlink, title: '' },
        },
        textAlign: {
            className: css(s.toolbarWrapper),
            options: ['left', 'center', 'right'],
            left: { icon: alignLeft },
            center: { icon: alignCenter },
            right: { icon: alignRight },
        },
    }

    const editorStyle = { padding: '0 16px' }

    const isRequired = typeof validate === 'function'

    return (
        <div className="is-flex direction-column gap-2">
            {label ? (
                <span className={css(s.lab)}>
                    <label className={cn(css(s.label))} htmlFor={name}>
                        {label} {isRequired && (
                            <span style={{ color: isValid ? COLORS.lightRed : COLORS.midGray }}>
                                *
                            </span>
                        )}
                    </label>
                </span>
            ) : null}

            <Field name={name} validate={validate}>
                {({ form }) => (
                    <Editor
                        toolbar={toolbarOptions}
                        editorStyle={editorStyle}
                        editorState={editorState}
                        wrapperClassName={cn(css(s.cont), { [css(s.dng)]: isValid }, wrapperClassName)}
                        toolbarClassName={cn(css(s.toolbar), toolbarClassName)}
                        onEditorStateChange={(value) => {
                            setEditorState(value)
                            if (typeof onChange === 'function') onChange(value)
                            else form.setFieldValue(name, value)
                        }} />
                )}
            </Field>

            <div className={cn(css(s.label))}>
                <span style={{ color: errors.description ? COLORS.lightRed : COLORS.midGray }}>
                    Минимальное значение: 200 символов
                </span>
            </div>
        </div>
    )
}

const s = StyleSheet.create({
    cont: {
        border: `1px solid ${COLORS.lightGray}`,
        borderRadius: 4,
        margin: 0,
        padding: 0,
        color: COLORS.lightBlack,
        fontWeight: '500',
        fontSize: 14,
    },
    toolbar: {
        backgroundColor: COLORS.smoothGray,
        marginBottom: 0,
        padding: '6px 16px 0 16px',
        border: 'none',
        fontSize: 'unset',
        borderRadius: '3px 3px 0 0',
        gap: 16,
    },
    toolbarWrapper: {
        border: '1px solid #C9D2CE',
        borderRadius: 4,
        ':nth-child(1n) > *': {
            margin: 0,
            border: 'none',
            borderRadius: 0,
            borderRight: '1px solid #C9D2CE',
        },
        ':nth-child(1n) > :first-child': {
            borderRadius: '4px 0 0 4px',
        },
        ':nth-child(1n) > :last-child': {
            borderRadius: '0 4px 4px 0',
            borderRight: 'none',
        },
    },
    dng: {
        border: '1px solid red',
    },
    label: {
        fontSize: 12,
        color: COLORS.midGray,
        fontWeight: '500',
    },
})
