import React from 'react'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import { COLORS } from '../../../utils/colors'
import { useLoad } from '../../../hooks/request'
import { REPORT_APPLICANT_SOURCE_CHART } from '../../../urls'
import { ApplicantSources } from '../../../utils/options'
import { EyeSizeS, FileSymlink } from '../../svgs/Svgs'
import { BarChartCustom } from './BarChartCustom'
import { LoadingSkeleton } from '../../common/LoadingSkeleton'
import logo from '../../../static/images/logoSizeXS.svg'
import hh from '../../../static/images/hhSizeXS.svg'
import telegram from '../../../static/images/telegramSizeXS.svg'
import recruiter from '../../../static/images/editXS.svg'

const Legend = [{ name: 'Отклики', color: COLORS.mainColor }, { name: 'Просмотры', color: '#18D88B' }]

const EmptyChart = [
    { name: 'HeadHunter', logo, hired: 0, viewed: 0 },
    { name: 'Jobs Workly', logo: hh, hired: 0, viewed: 0 },
    { name: 'Telegram', logo: telegram, hired: 0, viewed: 0 },
    { name: 'Добавленные вручную', logo: recruiter, hired: 0, viewed: 0 },
]

function formatToChart(inputObject) {
    const resultArray = []

    Object.keys(inputObject).forEach((key) => {
        const { view = 0, hired = 0 } = inputObject[key]
        const source = ApplicantSources.find(({ value }) => value === key)
        const percent = (hired / (view || 1)) * 100
        const formattedPercent = Number.isNaN(+percent) ? '0' : percent.toFixed(2).replace(/\.00$/, '')

        resultArray.push({
            name: source.label,
            viewed: view,
            hired,
            logo: source.img,
            percent: formattedPercent,
        })
    })

    return resultArray
}

export default function ChartToolEffectiveness({ params }) {
    const loadChartData = useLoad({ url: REPORT_APPLICANT_SOURCE_CHART, params }, [params])
    const data = loadChartData.response ? loadChartData.response.data : {}
    const chartData = data.item ? formatToChart(data.item) : EmptyChart

    return (
        <div className={css(s.wrap)}>
            <div className={cn('justify-between', css(s.header))}>
                <h3 className={css(s.topic)}>Общая эффективность инструментов</h3>

                {/* <CloseSizeM color="#343836" /> */}
            </div>

            <div className={cn('direction-column ', css(s.cont))}>
                <div className={css(s.chart)}>
                    <BarChartCustom chartData={chartData} />
                </div>

                <ul className={cn('no_dot_list align-start', css(s.legend))}>
                    {Legend.map(({ color, name }, index) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <li key={index} className="align-center gap-2 flex-1">
                            <div className={markStyleSheet(color)} />

                            <h3 className={css(s.legendText)}>{name}</h3>
                        </li>
                    ))}
                </ul>
            </div>

            <div className={cn('align-center gap-5', css(s.footer))}>
                <div className={cn('align-center gap-1', css(s.fItem))}>
                    <EyeSizeS color={COLORS.gray} />

                    <div className="align-center gap-1">
                        <h3>Просмотров за этот период:</h3>

                        <span>
                            {!loadChartData.loading ? data.viewsCount : <LoadingSkeleton height={14} width={20} />}
                        </span>
                    </div>
                </div>

                <div className={cn('align-center gap-1', css(s.fItem))}>
                    <FileSymlink color={COLORS.gray} />

                    <div className="align-center gap-1">
                        <h3>Откликов за этот период:</h3>

                        <span>
                            {!loadChartData.loading ? data.addedCount : <LoadingSkeleton height={14} width={20} />}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    )
}

const s = StyleSheet.create({
    wrap: {
        flex: 1,
        height: '33rem',
        borderRadius: 2,
        border: `1px solid ${COLORS.smoothGray}`,
        background: '#FFF',
        display: 'flex',
        flexDirection: 'column',
        boxShadow: '0px 2px 4px 0px rgba(130, 130, 130, 0.05)',
    },
    topic: {
        color: COLORS.dark,
        fontSize: 16,
        fontWeight: '600',
    },
    header: {
        height: 40,
        padding: '0 24px',
        borderBottom: `1px solid ${COLORS.lightGray}`,
    },
    cont: {
        padding: '20px 24px 30px',
        // gap: 16,
    },
    footer: {
        borderTop: `1px solid ${COLORS.lightGray}`,
        padding: '8px 24px',
    },
    fItem: {
        color: COLORS.midGray,
        fontSize: 13,
        fontWeight: '500',
        ':nth-child(1n) > :last-child': {
            ':nth-child(1n) > span': {
                color: COLORS.dark,
                fontWeight: '600',
            },
        },
    },
    chart: {
        height: 380,
        width: '100%',
        // flex: 1,
    },
    legend: {
        width: '60%',
    },
    legendText: {
        color: COLORS.dark,
        fontSize: 14,
        fontWeight: '500',
    },
})

const markStyleSheet = (backgroundColor) => css(StyleSheet.create({
    main: {
        width: 20,
        height: 20,
        borderRadius: 4,
        backgroundColor,
    },
}).main)
