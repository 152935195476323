import { COLORS } from './colors'
import telegram from '../static/images/telegramSizeXS.svg'
import recruiter from '../static/images/editXS.svg'
import logo from '../static/images/logoSizeXS.svg'
import hh from '../static/images/hhSizeXS.svg'

export const educationLvl = [
    { value: 1, label: 'Не важно' },
    { value: 2, label: 'Общее образование' },
    { value: 3, label: 'Начальное (ПТУ, проф. лицей)' },
    { value: 4, label: 'Среднее (техникум, колледж)' },
    { value: 5, label: 'Высшее бакалавриат' },
    { value: 6, label: 'Высшее специалитет' },
    { value: 7, label: 'Высшее магистратура' },
    { value: 8, label: 'Высшее аспирантура' },
    { value: 9, label: 'Высшее докторантура' },
]

export const genderOptions = [
    { value: 'not_set', label: 'Не важно' },
    { value: 'male', label: 'Мужской' },
    { value: 'female', label: 'Женский' },
]

export const roleOptions = [
    { value: 1, label: 'Владелец' },
    { value: 2, label: 'Администратор' },
    { value: 3, label: 'Рекрутер' },
    { value: 4, label: 'Сотрудник' },
]

export const banknoteOptions = [
    { value: 1, label: 'UZS' },
    { value: 2, label: 'USD' },
    { value: 3, label: 'RUB' },
    { value: 4, label: 'EUR' },
]

export const AvailableLanguages = [
    { value: 1, en: 'English', ru: 'Английский', uz: 'Ingliz', short: 'en' },
    { value: 2, en: 'Russian', ru: 'Русский', uz: 'Rus', short: 'ru' },
    { value: 3, en: 'Uzbek', ru: 'Узбекский', uz: 'O\'zbek', short: 'uz' },
    { value: 4, en: 'German', ru: 'Немецкий', uz: 'Nemis', short: 'de' },
    { value: 5, en: 'Spanish', ru: 'Испанский', uz: 'Ispan', short: 'es' },
    { value: 6, en: 'French', ru: 'Французский', uz: 'Fransuz', short: 'fr' },
    { value: 7, en: 'Chinese', ru: 'Китайский', uz: 'Xitoy', short: 'zh' },
    { value: 8, en: 'Korean', ru: 'Корейский', uz: 'Koreys', short: 'ko' },
    { value: 9, en: 'Japanese', ru: 'Японский', uz: 'Yapon', short: 'ja' },
    { value: 10, en: 'Italian', ru: 'Итальянский', uz: 'Italyan', short: 'it' },
    { value: 11, en: 'Ukrainian', ru: 'Украинский', uz: 'Ukrain', short: 'uk' },
    { value: 12, en: 'Kazakh', ru: 'Казахский', uz: 'Qozoq', short: 'kk' },
    { value: 13, en: 'Indonesian', ru: 'Индонезийский', uz: 'Indonez', short: 'id' },
    { value: 14, en: 'Arabic', ru: 'Арабский', uz: 'Arab', short: 'ar' },
    { value: 15, en: 'Portuguese', ru: 'Португальский', uz: 'Portugal', short: 'pt' },
]

export const LanguageLevels = [
    { level: 'A1', en: 'Beginner', ru: 'Начальный', uz: 'Boshlang\'ich darajasi' },
    { level: 'A2', en: 'Elementary', ru: 'Элементарный', uz: 'Boshlang\'ich daraja' },
    { level: 'B1', en: 'Intermediate', ru: 'Средний', uz: 'O\'rta daraja' },
    { level: 'B2', en: 'Intermediate-advanced', ru: 'Средне-продвинутый', uz: 'O\'rta-ilg\'or daraja' },
    { level: 'C1', en: 'Advanced', ru: 'Продвинутый', uz: 'Yuqori daraja' },
    { level: 'C2', en: 'Perfect', ru: 'В совершенстве', uz: 'Mukammal' },
]

export const WorkFormat = [
    { value: 1, label: 'Полный день' },
    { value: 2, label: 'Сменный график' },
    { value: 3, label: 'Гибкий график' },
    { value: 4, label: 'Удаленная работа' },
    { value: 5, label: 'Вахтовый метод' },
]

export const Experience = [
    { value: 0, label: 'Без опыта' },
    { value: 1, label: 'От 1 года до 3 лет' },
    { value: 2, label: 'От 3 до 6 лет' },
    { value: 3, label: 'Более 6 лет' },
]

export const WorkSchedule = [
    { value: 1, label: 'Полная занятость' },
    { value: 2, label: 'Частичная занятость' },
    { value: 3, label: 'Проектная работа' },
    { value: 4, label: 'Контракт' },
    { value: 5, label: 'Сезонная работа' },
    { value: 6, label: 'Стажировка' },
    { value: 7, label: 'Волонтерство' },
]

export const ApplicantSources = [
    { value: 'headhunter', label: 'HeadHunter', color: '#D20A11', img: hh, cardLabel: 'hh.uz' },
    { value: 'web', label: 'Jobs Workly', color: COLORS.mainColor, img: logo, cardLabel: 'Jobs Workly' },
    { value: 'telegram', label: 'Telegram', color: '#229ED9', img: telegram, cardLabel: 'Telegram' },
    // eslint-disable-next-line max-len
    { value: 'recruiter', label: 'Добавленные вручную', color: '#856EC6', img: recruiter, cardLabel: 'Добавлено вручную' },
]

export const BillingTypes = [
    { value: 'free', label: 'Бесплатная' },
    { value: 'standard', label: 'Стандарт' },
    { value: 'standard_plus', label: 'Стандарт плюс' },
    { value: 'premium', label: 'Премиум' },
]
