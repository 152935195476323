import React from 'react'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import { isEmpty } from 'lodash'
import MainInfo from './MainInfo'
import EducationInfo from './EducationInfo'
import LanguageInfo from './LanguageInfo'

export default function CandidateGeneralInfo({ info }) {
    return (
        <div className={cn('is-flex direction-column gap-7', css(s.cont))}>
            {!isEmpty(info) ? (
                <MainInfo info={info} />
            ) : null}

            {!isEmpty(info.education) ? (
                <EducationInfo education={info.education} />
            ) : null}

            {!isEmpty(info.languages) ? (
                <LanguageInfo languages={info.languages} />
            ) : null}
        </div>
    )
}

const s = StyleSheet.create({
    cont: {
        padding: '16px 24px',
        overflowY: 'auto',
        height: '100%',
    },
})
