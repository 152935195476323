import React, { memo, useContext, useRef, useState } from 'react'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import { useNavigate } from 'react-router-dom'
import { isEmpty } from 'lodash'
import { ChevronNextRounded,
    ChevronPrevRounded,
    ExitSizeM,
    LogoM,
    RoundedArrows,
    Settings,
    UserCircle } from './svgs/Svgs'
import { ExitModal } from './settings/ExitModal'
import { COLORS } from '../utils/colors'
import { roleOptions } from '../utils/options'
import { Popup } from './Popup'
import { Context } from '../contexts/GlobalContext'
import { strCutter } from '../utils/string'

function ProfilePopup({ navigate, exitRef, onClose, username, photo, firstName, lastName }) {
    const imageSrc = !isEmpty(photo) ? photo[3] : ''

    return (
        <div className={cn('is-flex direction-column gap-4', css(s.profile))}>
            <div className="align-center gap-3">
                {imageSrc ? (
                    <img className={css(s.innerAvatar)} src={imageSrc} alt="#logo" />
                ) : (
                    <div style={{ width: 36, height: 36 }} className={cn('flex-cent', css(s.defaultAvatar))}>
                        <h3>
                            {firstName && lastName ? `${firstName.slice(0, 1)}${lastName.slice(0, 1)}` : ''}
                        </h3>
                    </div>
                )}

                <div className={cn('is-flex direction-column', css(s.name))}>
                    <h3>{firstName} {lastName}</h3>

                    <h3>{strCutter(username, 18)}</h3>
                </div>
            </div>

            <ul className={cn('is-flex direction-column gap-3', css(s.lst))}>
                <li onClick={() => navigate('/settings/personal')}><UserCircle /> Личный профиль</li>

                <li onClick={() => navigate('/settings/company')}><Settings className={css(s.icon)} /> Настройки</li>

                <hr className={css(s.hr)} />

                <li onClick={() => navigate('/projects', { state: { isAllowed: true } })}>
                    <RoundedArrows />Сменить компанию
                </li>

                <li onClick={() => {
                    onClose()
                    exitRef.current.onOpenModal()
                }}>
                    <ExitSizeM /> Выйти
                </li>
            </ul>
        </div>
    )
}

function MemoizeComponent() {
    const exitRef = useRef()
    const popupRef = useRef()
    const navigate = useNavigate()
    const { user } = useContext(Context)

    const [, setOpenList] = useState(false)

    // const [showSidebar, hideSidebar] = useSidebar(
    //     <NotificationBar onClose={() => hideSidebar()} />,
    // )

    const { firstName, lastName, username, role, photo } = user.data || {}
    const imageSrc = !isEmpty(photo) ? photo[5] : ''

    return (
        <header className={css(s.head)}>
            <span onClick={() => navigate('/dashboard')} className={css(s.logoBar)}>
                <LogoM />
            </span>

            <div className={css(s.navigateBar)}>
                <span>
                    <span
                        className={cn(css(s.nav_i), 'dis_sl')}
                        onClick={() => navigate(-1)}>
                        <ChevronPrevRounded />
                    </span>

                    <span
                        className={cn(css(s.nav_i), 'dis_sl')}
                        onClick={() => navigate(1)}>
                        <ChevronNextRounded />
                    </span>

                    {/* <h3>Главная</h3> */}
                </span>

                <div className={cn('justify-between gap-3', css(s.contPanel))}>
                    {/* <span className={cn('flex-cent gap-2 pointer', css(s.add))}> */}
                    {/*     Добавить <ChevronDownLayout /> */}
                    {/* </span> */}

                    {/* <span className="pointer" onClick={() => showSidebar()}> */}
                    {/*     <NotificationBell /> */}
                    {/* </span> */}

                    <Popup
                        ref={popupRef}
                        popupClassName={css(s.popup)}
                        position="right"
                        trigger={!user.loading ? (
                            <div
                                onClick={() => popupRef.current.onOpen()}
                                className={cn('justify-between pointer gap-2', css(s.profileBar))}>
                                <span>
                                    <h3>
                                        {roleOptions.find((e) => e.value === role)
                                        && roleOptions.find((e) => e.value === role).label}
                                    </h3>

                                    <h3>{firstName} {lastName}</h3>
                                </span>

                                {imageSrc ? (
                                    <img className={css(s.avatar)} src={imageSrc} alt="#logo" />
                                ) : (
                                    <div
                                        style={{ width: 28, height: 28 }}
                                        className={cn('flex-cent', css(s.defaultAvatar))}>
                                        <h3 className="is-flex align-center justify-center">
                                            {firstName && lastName
                                                ? `${firstName.slice(0, 1)}${lastName.slice(0, 1)}` : ''}
                                        </h3>
                                    </div>
                                )}
                            </div>
                        ) : null}>
                        <ProfilePopup
                            firstName={firstName}
                            lastName={lastName}
                            username={username}
                            exitRef={exitRef}
                            navigate={navigate}
                            photo={photo}
                            loading={user.loading}
                            onClose={() => setOpenList(false)} />
                    </Popup>
                </div>
            </div>

            <ExitModal ref={exitRef} onlyModal />
        </header>
    )
}

export const Header = memo(MemoizeComponent)

const s = StyleSheet.create({
    head: {
        height: '3.25rem',
        padding: '0 24px',
        display: 'flex',
        alignItems: 'center',
        zIndex: 2,
        gap: 15,
        position: 'relative',
        justifyContent: 'space-between',
        background: COLORS.mainColor,
        boxShadow: '0px 1px 16px rgba(72, 109, 96, 0.06)',
    },
    logoBar: {
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        color: '#FFF',
        fontSize: 14,
        fontWeight: '700',
        ':nth-child(1n) > :first-child': {
            marginRight: 2,
        },
    },
    navigateBar: {
        display: 'flex',
        flex: 1,
        justifyContent: 'space-between',
        alignItems: 'center',
        ':nth-child(1n) > :first-child': {
            // ':nth-child(1n) > :last-child': {
            //     fontSize: 12,
            //     fontWeight: '600',
            //     color: '#FFFFFF80',
            //     marginLeft: 12,
            // },
        },
    },
    nav_i: {
        cursor: 'pointer',
        padding: '2px 5px',
        border: '1px solid #FFFFFF80',
        borderRadius: 4,
        ':first-of-type': {
            marginRight: 8,
        },
    },
    add: {
        color: '#FFF',
        fontSize: 12,
        fontWeight: '500',
    },
    profileBar: {
        padding: '0 16px',
        height: 36,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        boxSizing: 'border-box',
        borderRadius: 4,
        border: '1px solid',
        borderColor: 'transparent',
        ':hover': {
            background: 'rgba(255, 255, 255, 0.1)',
            borderColor: 'rgba(255, 255, 255, 0.2)',
        },
        ':nth-child(1n) > :first-child': {
            display: 'flex',
            flexDirection: 'column',
            textAlign: 'end',
            ':nth-child(1n) > :first-child': {
                fontWeight: '500',
                fontSize: 11,
                color: '#FFFFFFBF',
            },
            ':nth-child(1n) > :last-child': {
                fontWeight: '700',
                fontSize: 12,
                color: '#FFF',
            },
        },
    },
    avatar: {
        width: 28,
        height: 28,
        marginLeft: 8,
        borderRadius: 50,
    },
    innerAvatar: {
        width: 34,
        height: 34,
        borderRadius: 50,
        border: '1px solid rgba(0, 0, 0, 0.3)',
    },
    profile: {
        marginTop: '0.5rem',
        width: '13rem',
        background: COLORS.white,
        border: `1px solid ${COLORS.lightGray}`,
        boxShadow: '0px 2px 8px rgba(136, 136, 136, 0.25)',
        borderRadius: 4,
        padding: 16,
    },
    name: {
        ':nth-child(1n) > :first-child': {
            color: COLORS.dark,
            fontWeight: '700',
            fontSize: 14,
        },
        ':nth-child(1n) > :last-child': {
            color: COLORS.gray,
            fontWeight: '500',
            fontSize: 13,
        },
    },
    lst: {
        fontWeight: '400',
        fontSize: 12,
        color: COLORS.lightBlack,
        listStyle: 'none',
        ':nth-child(1n) > *': {
            display: 'flex',
            alignItems: 'center',
            gap: 8,
            cursor: 'pointer',
        },
    },
    hr: {
        margin: 0,
        border: `0.5px solid ${COLORS.smoothGray}`,
    },
    icon: {
        ':nth-child(1n) > *': {
            stroke: COLORS.spanishGray,
        },
    },
    defaultAvatar: {
        background: '#1B93C0',
        borderRadius: 32,
        fontWeight: '600',
        fontSize: 12,
        color: '#FFFFFF',
        textTransform: 'uppercase',
    },
    popup: {
        top: 20,
    },
})
