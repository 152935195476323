import React, { memo, useContext } from 'react'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import { Form } from 'formik'
import { useLocation } from 'react-router-dom'
import FormInput from '../../common/FormInput'
import { CheckUpList } from '../../svgs/Svgs'
import { banknoteOptions, genderOptions, roleOptions } from '../../../utils/options'
import { useLoad } from '../../../hooks/request'
import { DEPARTMENT_LIST, EMPLOYEE_LIST, LOCATION_LIST, POSITIONS_LIST } from '../../../urls'
import FormInputCurrency from '../../common/FormInputCurrency'
import { date, email, required, validator } from '../../../utils/validators'
import SelectForm from '../../common/SelectForm'
import { COLORS } from '../../../utils/colors'
import EmployeeImage from './EmployeeImage'
import { Context } from '../../../contexts/GlobalContext'
import InputDate from '../../common/InputDate'

function mapValue(data) {
    return data.map((e) => ({ value: e.id, label: e.name || `${e.firstName} ${e.lastName}` }))
}

function RoleCreateForm({ employeeUser, userId, setFieldValue, setErrors, values }) {
    const noRole = { value: 5, label: 'Не указано' }
    const { pathname } = useLocation()

    let roles = [...roleOptions.slice(1)]
    roles = employeeUser.email ? [...roles, noRole] : roles

    const notDisabled = values.email && !email(values.email)
    const notSpecified = !pathname.endsWith('/create') && employeeUser.email && !email(employeeUser.email)

    return (
        <>
            <FormInput
                name="email"
                placeholder="Введите"
                label="E-mail для входа"
                errorWithMessage={false}
                noRequiredValidation
                labelClassName={css(s.addInpLab)}
                disabled={employeeUser.id && employeeUser.id !== userId}
                validate={validator(email, employeeUser.id ? required : () => {})}
                onChange={({ target }) => {
                    const { value } = target
                    setFieldValue('email', value)

                    if (!value || email(value)) {
                        setFieldValue('role', '')
                        setErrors('role', '')
                    }
                }}
            />

            <SelectForm
                validate={notDisabled ? required : null}
                errorWithMessage={false}
                name="role"
                isDisabled={!notDisabled}
                value={roles[values.role - 2] || values.role || (notSpecified && noRole)}
                isSearchable={false}
                label="Роль сотрудника в системе"
                placeholder="Например: “Наблюдатель”"
                placeholderStyles={{ fontSize: 14, fontWeight: '500', color: COLORS.gray }}
                className={css(s.addInp)}
                options={roles}
                onChange={({ value }) => setFieldValue('role', value)}
            />
        </>
    )
}

function MemoizedComponent({ setFieldValue, values, setErrors, employeeUser }) {
    const { user } = useContext(Context)

    const { id: userId } = user.data || {}

    const loadLocations = useLoad({ url: LOCATION_LIST })
    const loadDepartments = useLoad({ url: DEPARTMENT_LIST })
    const loadPositions = useLoad({ url: POSITIONS_LIST })
    const loadEmployees = useLoad({ url: EMPLOYEE_LIST })

    const locations = loadLocations.response ? mapValue(loadLocations.response.data) : []
    const departments = loadDepartments.response ? mapValue(loadDepartments.response.data) : []
    const positions = loadPositions.response ? mapValue(loadPositions.response.data) : []
    const employees = loadEmployees.response ? mapValue(loadEmployees.response.data) : []

    const customComponents = { ClearIndicator: () => {} }

    return (
        <div className={css(s.box)}>
            <div className={cn('align-center gap-2', css(s.formName))}>
                <CheckUpList /> <h3>Основная информация</h3>
            </div>

            <Form>
                <div className={css(s.form)}>
                    <EmployeeImage
                        values={values}
                        setFieldValue={setFieldValue} />

                    <div className={css(s.inpGr)}>
                        <FormInput
                            name="firstName"
                            validate={required}
                            errorWithMessage={false}
                            className={css(s.addInp)}
                            fieldClassName={css(s.fld)}
                            label="Имя сотрудника"
                            placeholder="Введите"
                            labelClassName={css(s.addInpLab)} />

                        <FormInput
                            name="lastName"
                            validate={required}
                            errorWithMessage={false}
                            className={css(s.addInp)}
                            fieldClassName={css(s.fld)}
                            label="Фамилия сотрудника"
                            placeholder="Введите"
                            labelClassName={css(s.addInpLab)} />

                        <SelectForm
                            validate={required}
                            errorWithMessage={false}
                            name="department"
                            value={values.department}
                            isClearable
                            customComponents={customComponents}
                            label="Отдел работы"
                            placeholder="Выберите отдел"
                            placeholderStyles={{ fontSize: 14, fontWeight: '500', color: COLORS.gray }}
                            className={css(s.addInp)}
                            options={departments}
                            onChange={(value) => setFieldValue('department', value)}
                        />

                        <SelectForm
                            validate={required}
                            errorWithMessage={false}
                            name="position"
                            value={values.position}
                            isClearable
                            customComponents={customComponents}
                            label="Должность сотрудника"
                            placeholder="Выберите должность"
                            placeholderStyles={{ fontSize: 14, fontWeight: '500', color: COLORS.gray }}
                            className={css(s.addInp)}
                            options={positions}
                            onChange={(value) => setFieldValue('position', value)}
                        />

                        <RoleCreateForm
                            userId={userId}
                            values={values}
                            setErrors={setErrors}
                            employeeUser={employeeUser}
                            setFieldValue={setFieldValue}
                        />

                        <SelectForm
                            name="gender"
                            value={genderOptions.find((e) => e.value === values.gender)}
                            isClearable
                            customComponents={customComponents}
                            label="Пол сотрудника"
                            placeholder="Выберите пол"
                            placeholderStyles={{ fontSize: 14, fontWeight: '500', color: COLORS.gray }}
                            className={css(s.addInp)}
                            options={genderOptions}
                            onChange={({ value }) => setFieldValue('gender', value)}
                        />

                        <InputDate validate={date} noRequiredValidation label="Год рождения" name="birthDate" />

                        <SelectForm
                            errorWithMessage={false}
                            name="headOfEmployee"
                            isSearchable={false}
                            value={values.headOfEmployee}
                            label="Начальник сотрудника"
                            placeholder="Выберите сотрудника"
                            placeholderStyles={{ fontSize: 14, fontWeight: '500', color: COLORS.gray }}
                            className={css(s.addInp)}
                            options={employees}
                            onChange={(value) => setFieldValue('headOfEmployee', value)}
                        />

                        <SelectForm
                            errorWithMessage={false}
                            name="location"
                            isClearable
                            customComponents={customComponents}
                            value={values.location}
                            label="Локация работы"
                            placeholder="Выберите регион или введите"
                            placeholderStyles={{ fontSize: 14, fontWeight: '500', color: COLORS.gray }}
                            className={css(s.addInp)}
                            options={locations}
                            onChange={(value) => setFieldValue('location', value)}
                        />

                        <InputDate
                            validate={date}
                            name="startOfWork"
                            noRequiredValidation
                            label="Начало работы в компании"
                        />

                        <FormInputCurrency
                            isSelectable
                            name="salary"
                            maxLength={11}
                            label="Заработная плата (опционально)"
                            options={banknoteOptions}
                            placeholder="Введите сумму"
                            fieldClassName={css(s.fld)}
                            className={cn(css(s.addInp))}
                            labelClassName={css(s.addInpLab)}
                        />
                    </div>
                </div>
            </Form>
        </div>
    )
}

export const MainInfo = memo(MemoizedComponent)

const s = StyleSheet.create({
    box: {
        background: COLORS.white,
        border: `1px solid ${COLORS.smoothGray}`,
        boxShadow: '0px 2px 4px rgba(130, 130, 130, 0.05)',
        borderRadius: 4,
    },
    formName: {
        height: 'calc(3rem - 1px)',
        padding: '0 22px 0 24px',
        borderBottom: `1px solid ${COLORS.lightGray}`,
        ':nth-child(1n) > :first-child': {
            fontSize: 13,
            fontWeight: '600',
            color: COLORS.dark,
            textTransform: 'uppercase',
        },
    },
    form: {
        padding: '16px 24px 24px',
        display: 'flex',
        flexDirection: 'column',
        gap: 24,
    },
    inpGr: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gridGap: 24,
        alignItems: 'start',
    },
    addInp: {
        position: 'relative',
        borderRadius: 4,
        fontSize: 14,
        fontWeight: '500',
        color: COLORS.dark,
    },
    addInpLab: {
        fontSize: 12,
        fontWeight: '500',
        color: COLORS.midGray,
        marginBottom: 8,
    },
    fld: {
        position: 'relative',
    },
})
