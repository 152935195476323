import React from 'react'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import { COLORS } from '../../utils/colors'

export default function TagsSection({ tags, filter, setFilter }) {
    function onSelect(id) {
        if (filter.tag !== id) {
            setFilter((prev) => ({ ...prev, tag: id }))
        } else {
            const { tag: sectionFilter, ...clearedFilter } = filter

            setFilter(clearedFilter)
        }
    }

    const isSelected = (id) => filter.tag && filter.tag === id

    return (
        <div className="is-flex direction-column gap-3">
            <div className="is-flex is-flex-wrap gap-1">
                {tags.map((tag) => (
                    <span
                        key={tag.id}
                        onClick={() => onSelect(tag.id)}
                        className={cn(
                            'flex-cent pointer', activeTag(tag.color, isSelected(tag.id)), css(s.tag),
                        )}>
                        {tag.name}
                    </span>
                ))}
            </div>
        </div>
    )
}

const s = StyleSheet.create({
    tag: {
        height: 19,
        borderRadius: 4,
        fontSize: 11,
        fontWeight: '500',
        boxSizing: 'border-box',
        padding: '4px 6px',
    },
})

export const activeTag = (color, isActive) => css(StyleSheet.create({
    main: {
        color: isActive ? COLORS.white : COLORS.gray,
        border: `1px solid ${isActive ? 'transparent' : COLORS.smoothGray}`,
        background: isActive ? `${color}` : COLORS.lotion,
        ':nth-child(1n) > :first-child': {
            ':nth-child(1n) > :first-child': {
                ':nth-child(1n) > *': {
                    stroke: color,
                },
            },
        },
    },
}).main)
