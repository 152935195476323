import React from 'react'
import cn from 'classnames'
import { Field, getIn, useFormikContext } from 'formik'
import { css, StyleSheet } from 'aphrodite'
import Select, { components } from 'react-select'
import { isEmpty } from 'lodash'
import ValidationErrorMessage from './ValidationErrorMessage'
import { format, integersOnly } from '../../utils/number'
import { CheckMark, ChevronDown } from '../svgs/Svgs'
import { COLORS } from '../../utils/colors'

export default function InputFromToForm({
    name,
    help,
    icon,
    label,
    loading,
    onInput,
    options,
    validate,
    onChange,
    cssClassName,
    staticText,
    placeholder,
    bothRequired,
    fieldClassName,
    errorWithMessage = true,
    labelClassName,
    isSelectable = false,
    ...attributes
}) {
    const { touched, errors, setFieldValue, values } = useFormikContext()
    const isValid = !errorWithMessage && getIn(errors, name) && getIn(touched, name)
    const isRequired = typeof validate === 'function'

    const selectStyle = {
        control: (base) => ({
            ...base,
            boxShadow: 'none',
            outline: 'none',
            height: 24,
            minHeight: 'auto',
            borderColor: 'transparent',
            cursor: 'pointer',
            '&:hover': {
                outline: 'none',
                boxShadow: 'none',
            },
        }),
        option: (optStyles, { isSelected }) => ({
            ...optStyles,
            backgroundColor: isSelected ? '#FAFFFC' : ' transparent',
            color: isSelected ? '#181A19' : '#6D7471',
            cursor: 'pointer',
            padding: '14px 8px 14px 16px',
            borderBottom: '1px solid #EDEDEE',
            ':hover': {
                backgroundColor: '#FAFFFC',
            },
            ':first-of-type': {
                borderRadius: '4px 4px 0 0',
            },
            ':last-of-type': {
                borderBottom: 'none',
                borderRadius: '0 0 4px 4px',
            },
        }),
        menu: (base) => ({
            ...base,
            right: 0,
            width: '8.5rem',
            ':nth-of-type(1n) > :first-of-type': {
                padding: 0,
            },
        }),
        valueContainer: (optStyles) => ({
            ...optStyles,
            padding: 0,
        }),
        dropdownIndicator: (optStyles) => ({
            ...optStyles,
            padding: 0,
        }),
        singleValue: (optStyles) => ({
            ...optStyles,
            fontSize: 16,
            fontWeight: '500',
            color: '#2EA97D',
        }),
    }

    const Menu = (props) => <components.Menu className={css(s.opt)} {...props} />

    const Option = (props) => {
        const { children, isSelected } = props

        return (
            <components.Option {...props}>
                <span className="justify-between " style={{ height: 16 }}>
                    <h3 className={cn(css(s.optLab))}>{children}</h3>

                    {isSelected ? (<CheckMark />) : ''}
                </span>
            </components.Option>
        )
    }

    const DropdownIndicator = (props) => (
        <components.DropdownIndicator {...props}>
            <span className={cn('expand expand-more is-flex')}>
                <ChevronDown />
            </span>
        </components.DropdownIndicator>
    )

    // const bothValueChecker = (type) => {
    //     const valuesArray = Object.values(values[name])
    //     if (valuesArray.every((e) => e) || valuesArray.every((e) => e === '')) return
    //
    //     setFieldError(`${name}.${type}`, 'Это обязательное поле')
    // }
    //
    // useEffect(() => {
    //     bothValueChecker('to')
    // }, [values[name]])

    return (
        <div className={cn('field', fieldClassName)}>
            <div className={cn('control', css(s.field))}>
                {label ? (
                    <label className={labelClassName} htmlFor={name}>
                        {label} {isRequired && (
                            <span style={{ color: isValid ? COLORS.lightRed : COLORS.midGray }}>
                                *
                            </span>
                        )}
                    </label>
                ) : null}

                <div
                    onClick={() => {
                        document.getElementById(`${name}.${isEmpty(values[name].from) ? 'from' : 'to'}`).focus()
                    }}
                    className={cn(
                        'form-inp_pl',
                        css(s.cont, icon && s.icon, cssClassName, isValid && s.dng),
                    )}>
                    <div className="align-start">
                        <div
                            className="align-center gap-1"
                            onClick={(e) => {
                                e.stopPropagation()
                                document.getElementById(`${name}.from`).focus()
                            }}>
                            <span className={css(s.text)}>
                                {placeholder[0]}
                            </span>

                            <div className={css(s.input_wrap)}>
                                <span aria-hidden="true">{values[name].from}</span>

                                <Field
                                    id={`${name}.from`}
                                    className={cn('input', css(s.inp))}
                                    name={`${name}.from`}
                                    validate={validate}
                                    type="text"
                                    onChange={(event) => {
                                        const { target } = event
                                        const value = format(integersOnly(target.value))
                                        setFieldValue(`${name}.from`, value)
                                        if (value.length !== 0) {
                                            target.parentElement.style.marginRight = '5px'
                                        } else target.parentElement.style.marginRight = '0'
                                        // eslint-disable-next-line no-param-reassign
                                        target.previousSibling.innerHTML = value
                                    }}
                                    {...attributes} />
                            </div>
                        </div>

                        <div>-</div>

                        <div
                            style={{ marginLeft: 5 }}
                            className="justify-center gap-1"
                            onClick={(e) => {
                                e.stopPropagation()
                                document.getElementById(`${name}.to`).focus()
                            }}>
                            <span className={css(s.text)}>
                                {placeholder[1]}
                            </span>

                            <div className={css(s.input_wrap)}>
                                <span aria-hidden="true">{values[name].to}</span>

                                <Field
                                    id={`${name}.to`}
                                    name={`${name}.to`}
                                    validate={validate}
                                    type="text"
                                    className={cn('input', css(s.inp))}
                                    onChange={(event) => {
                                        const value = format(integersOnly(event.target.value))
                                        setFieldValue(`${name}.to`, value)
                                        // eslint-disable-next-line no-param-reassign
                                        event.target.previousSibling.innerHTML = value
                                    }}
                                    {...attributes} />
                            </div>
                        </div>

                        {staticText && <div className={css(s.text)}>{staticText}</div>}
                    </div>

                    <div onClick={(e) => e.stopPropagation()}>
                        {isSelectable && (
                            <Select
                                options={options}
                                isSearchable={false}
                                styles={selectStyle}
                                value={values[name].currency || options[0]}
                                onChange={(event) => {
                                    setFieldValue(`${name}.currency`, event)
                                    if (typeof onChange === 'function') {
                                        onChange(event)
                                    }
                                }}
                                components={{ Option, Menu, DropdownIndicator, IndicatorSeparator: () => null }}
                            />
                        )}
                    </div>
                </div>

                {help && (!errors[name] || !touched[name]) ? (
                    <p className={css(s.formHint)}>{help}</p>
                ) : null}

                {errorWithMessage && (
                    <ValidationErrorMessage
                        name={`${name}.from`}
                        errorMsg={help ? <h3 style={{ fontSize: 12 }}>{help}</h3> : ''}
                    />
                )}

                {errorWithMessage && errors[name] && !errors[name].from && (
                    <ValidationErrorMessage
                        name={`${name}.to`}
                        errorMsg={help ? <h3 style={{ fontSize: 12 }}>{help}</h3> : ''}
                    />
                )}
            </div>
        </div>
    )
}

const s = StyleSheet.create({
    field: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
    },
    cont: {
        border: '1px solid #C9D2CE',
        borderRadius: 4,
        height: 42,
        padding: '0 9px 0 16px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        ':nth-child(1n) > :first-child': {
            gap: '0.25rem',
            ':nth-child(1n) > *': {
                cursor: 'text',
            },
        },
    },
    input_wrap: {
        position: 'relative',
        minWidth: 1,
        maxWidth: 100,
        height: 16,
        ':nth-child(1n) > input': {
            position: 'absolute',
            left: 0,
        },
    },
    inp: {
        outline: 'none',
        border: 'none',
        width: '100%',
        overflow: 'hidden',
        padding: 0,
        fontSize: 14,
        fontWeight: '500',
        color: '#181A19',
    },
    icon: {
        paddingRight: 46,
    },
    dng: {
        borderColor: '#F33B5C',
    },
    formHint: {
        marginTop: 4,
        fontSize: 12,
        fontWeight: '400',
        color: '#414644',
    },
    text: {
        color: '#969F9B',
        fontSize: 14,
        fontWeight: '400',
    },
})
