import React from 'react'
import { css, StyleSheet } from 'aphrodite'
import ru from 'date-fns/locale/ru'
import { DayPicker } from 'react-day-picker'
import cn from 'classnames'
import { CalendarNext, CalendarPrev } from '../svgs/Svgs'
import { COLORS } from '../../utils/colors'

function Next(props) {
    return (
        <CalendarNext {...props} />
    )
}

function Prev(props) {
    return (
        <CalendarPrev {...props} />
    )
}

export default function Calendar({
    mode,
    name,
    selected,
    className,
    setFieldValue,
    setSelected,
    ...args
}) {
    const weekEnd = [0, 6]

    const handleDayClick = (day) => {
        if (day) setSelected(day)
    }

    return (
        <DayPicker
            className={cn(css(s.dayPick), className)}
            locale={ru}
            mode={mode}
            fromYear={2010}
            toYear={2030}
            components={{ IconLeft: Prev, IconRight: Next }}
            modifiers={{ weekEnd: (day) => weekEnd.includes(day.getDay()) }}
            showOutsideDays
            modifiersClassNames={{
                weekEnd: css(s.weekEnd),
                selected: css(s.select),
                today: css(s.td),
                outside: css(s.ou),
            }}
            selected={selected}
            onSelect={handleDayClick}
            {...args}
        />
    )
}

const s = StyleSheet.create({
    dayPick: {
        borderRadius: 12,
        borderWidth: 0,
        background: COLORS.white,
        border: '1px solid #EDEDED',
        boxShadow: '0px 2px 8px rgba(136, 136, 136, 0.25)',
        zIndex: 1,
    },
    select: {
        border: `1px solid ${COLORS.skyblue}`,
        borderRadius: 2,
        color: COLORS.skyblue,
        fontSize: 16,
        fontWeight: '500',
    },
    ou: {
        color: '#BBC6C1',
    },
    td: {
        color: COLORS.skyblue,
        fontSize: 16,
        fontWeight: '500',
    },
    weekEnd: {
        color: COLORS.lightRed,
    },
})
