import React from 'react'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import { Header } from '../Header'
import { Sidebar } from '../Sidebar'

export default function Layout({ children, className, padded = true, hideSideBar, ...args }) {
    return (
        <div {...args} className={css(s.layout_head)}>
            <Header />

            <section className={css(s.section)}>
                {!hideSideBar && (
                    <Sidebar className={css(s.sidebar)} />
                )}

                <div id="mn-cont" className={cn('mn-cont', css(s.child), { [css(s.p)]: padded }, className)}>
                    {children}
                </div>
            </section>
        </div>
    )
}

const s = StyleSheet.create({
    layout_head: {
        height: '100%',
    },
    section: {
        display: 'flex',
        height: 'calc(100% - 3.25rem)',
    },
    child: {
        height: '100%',
        overflowY: 'auto',
        flex: 1,
        scrollbarGutter: 'stable',
    },
    p: {
        minWidth: '79.6%',
        padding: '0 1.5rem',
    },
    sidebar: {
        zIndex: 2,
    },
})
