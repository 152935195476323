import React from 'react'
import Slider from 'react-slick'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import uuid from 'react-uuid'
import { ChevronPrev } from '../svgs/Svgs'
import { EntryCont1, EntryCont2, EntryCont3, EntryCont4 } from '../svgs/SvgPictures'

/* eslint-disable */
const slides = [
    { img: EntryCont1, title: 'Успевать за всеми - легко!', paragraph: 'Платформа позволяет следить за всеми заявками и кандидатами. Эффективность HR менеджеров выросла на 25%' },
    { img: EntryCont2, title: 'Работать в команде эффективнее', paragraph: 'Цифровизируйте процесс рекруитинга и найма сотрудников для более оперативной и конструктивной работы всей HR команды' },
    { img: EntryCont3, title: 'Единая экосистема', paragraph: 'Пользуясь платформой, вы можете публиковать вакансии в разных платформах, а кандидаты будут автоматически добавляться в систему' },
    { img: EntryCont4, title: 'Следите за метриками', paragraph: 'Платформа позволяет вам следить за статистикой по заявкам и кандидатам, а также за успеваемостью HR отдела' },
]
/* eslint-enable */

function NextArrow({ onClick, className }) {
    return (
        <ChevronPrev className={cn(css(s.nextIcon), className)} onClick={onClick} />
    )
}

function PrevArrow({ onClick, className }) {
    return (
        <ChevronPrev className={cn(css(s.prevIcon), className)} onClick={onClick} />
    )
}

export default function Carousel() {
    const settings = {
        arrows: true,
        dots: false,
        infinite: true,
        autoplay: true,
        speed: 1000,
        autoplaySpeed: 4000,
        slidesToShow: 1,
        slidesToScroll: 1,
        cssEase: 'linear',
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
    }

    return (
        <div>
            <Slider {...settings}>
                {slides.map((slide) => (
                    <div key={uuid()} className={css(s.slide)}>
                        <div>
                            <slide.img />
                        </div>

                        <div className={cn('is-flex direction-column gap-3', css(s.txt))}>
                            <h3>{slide.title}</h3>

                            <p>{slide.paragraph}</p>
                        </div>
                    </div>
                ))}
            </Slider>
        </div>
    )
}

const s = StyleSheet.create({
    prevIcon: {
        top: '85%',
        left: -1,
        cursor: 'pointer',
        padding: '0 16px',
        zIndex: 1,
    },
    nextIcon: {
        transform: 'rotateY(180deg)',
        top: '85%',
        right: -2,
        left: 'auto',
        marginTop: 0,
        cursor: 'pointer',
        padding: '0 16px',
    },
    slide: {
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center',
        alignItems: 'center',
        gap: 27,
        ':nth-child(1n) > :first-child': {
            paddingLeft: 2,
        },
    },
    txt: {
        paddingLeft: 2,
        width: '76%',
        fontWeight: '500',
        ':nth-child(1n) > h3': {
            fontSize: 20,
            color: '#292B2E',
        },
        ':nth-child(1n) > p': {
            fontSize: 14,
            color: '#9CABB6',
        },
    },
})
