import React, { useState } from 'react'
import cn from 'classnames'
import { css, StyleSheet } from 'aphrodite'
import { Clear, MagnifierSizeM } from '../../../svgs/Svgs'
import { COLORS } from '../../../../utils/colors'

function Input({ name, placeholder }) {
    const [value, setValue] = useState('')

    return (
        <div
            id={name}
            style={{ marginLeft: 20 }}
            onClick={() => {
                document.querySelector(`#${name}`).classList.add(css(s.actSrBtn))
                document.querySelector(`#${name} input`).focus()
            }}>
            <MagnifierSizeM color={COLORS.mainColor} className={css(s.green_icon)} />

            <div className={css(s.inputWrap)}>
                <input
                    type="text"
                    autoComplete="off"
                    name={name}
                    value={value}
                    className={css(s.inp)}
                    placeholder={placeholder}
                    onChange={(event) => setValue(event.target.value)}
                    onBlur={() => (
                        value.length === 0 ? document.querySelector(`#${name}`).classList.remove(css(s.actSrBtn)) : {}
                    )}
                />

                {value.length > 0 && (
                    <span className="flex-cent pointer">
                        <Clear onClick={() => setValue('')} />
                    </span>
                )}
            </div>
        </div>
    )
}

export function TableHeader() {
    return (
        <thead className={css(s.tableHead)}>
            <tr>
                <th className={cn(css(s.searchInp), css(s.srBtn))}>
                    <Input name="name" placeholder="Должность, отдел" />
                </th>

                <th className={cn(css(s.pubDate), css(s.srBtn))}>
                    Дата публикации
                </th>

                <th className={css(s.deadline)}>
                    Дедлайн
                </th>

                <th className={css(s.desired_salary)}>
                    Зарплатная вилка
                </th>

                <th className={cn(css(s.location), css(s.srBtn))}>
                    Локация работы
                </th>

                <th className={css(s.menu)} />
            </tr>
        </thead>
    )
}

const s = StyleSheet.create({
    tableHead: {
        width: '100%',
        color: COLORS.midGray,
        fontSize: 13,
        textTransform: 'uppercase',
        textAlign: 'left',
        ':nth-child(1n) > :first-child': {
            background: '#EAF4F0',
            ':nth-child(1n) > *': {
                fontWeight: '500',
                paddingTop: 7,
                paddingBottom: 7,
            },
            ':nth-child(1n) > :first-child': {
                borderRadius: '4px 0 0 0',
                // paddingLeft: 20,
            },
        },
        whiteSpace: 'nowrap',
    },
    inp: {
        fontSize: 13,
        fontWeight: '400',
        color: COLORS.midGray,
        outline: 'none',
        border: 'none',
        backgroundColor: 'transparent',
        '::placeholder': {
            color: COLORS.midGray,
            fontSize: 13,
            fontWeight: '400',
        },
    },
    srBtn: {
        ':nth-child(1n) > :first-child': {
            display: 'flex',
            alignItems: 'center',
            gap: 8,
            height: '1.75rem',
            padding: '0 8px',
            border: '1px solid rgba(0, 0, 0, 0.06)',
            borderRadius: 4,
            ':nth-child(1n) > :last-child': {
                fontSize: 13,
                fontWeight: '400',
                color: COLORS.midGray,
                outline: 'none',
                border: 'none',
                backgroundColor: 'transparent',
            },
        },
    },
    inputWrap: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
    },
    actSrBtn: {
        backgroundColor: '#FFF',
        borderColor: COLORS.lightGray,
    },
    searchInp: {
        width: '30%',
        paddingRight: '48px',
    },
    pubDate: {
        width: '15%',
        textAlign: 'left',
    },
    deadline: {
        width: '14%',
    },
    desired_salary: {
        width: '18%',
    },
    location: {
        width: '22%',
    },
    menu: {
        width: '1%',
    },
})
