import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { convertToHTML } from 'draft-convert'
import VacancyForm from '../components/vacancies/VacancyForm'
import { useLoad, usePutRequest } from '../hooks/request'
import { useMessage } from '../hooks/message'
import { banknoteOptions, educationLvl, Experience, genderOptions, WorkFormat, WorkSchedule } from '../utils/options'
import { format, integersOnly } from '../utils/number'
import { VACANCY_DETAIL, VACANCY_UPDATE } from '../urls'

export default function VacancyUpdate() {
    const { id } = useParams()
    const navigate = useNavigate()
    const [showMessage] = useMessage()
    const update = usePutRequest({ url: VACANCY_UPDATE.replace('{id}', id) })
    const loadInfo = useLoad({ url: VACANCY_DETAIL.replace('{id}', id) })
    const info = loadInfo.response ? loadInfo.response.data : {}

    const onSubmit = async (submitData) => {
        const data = {
            ...submitData,
            // eslint-disable-next-line max-len
            description: submitData.description ? typeof submitData.description === 'object' ? convertToHTML(submitData.description.getCurrentContent()) : submitData.description : null,
            employeeCount: submitData.employeeCount.toString().replace(/\s/g, ''),
            education: submitData.education ? submitData.education.value : null,
            positionId: submitData.position.value,
            positionName: submitData.position.label,
            hhAreaId: submitData.region.value || (submitData.hhAreaId && `${submitData.hhAreaId}`),
            schedule: submitData.schedule.value || null,
            format: submitData.format.value || null,
            skill: submitData.skills.map((e) => e.id),
            knowledge: submitData.knowledge.map((e) => e.id),
            languageLevel: Object.values(submitData.languageLevel).filter((e) => !!e.language && !!e.level),
            departmentId: submitData.department.value,
            locationId: submitData.location ? submitData.location.value : '',
            experience: submitData.experience.value || 0,
            salaryFrom: submitData.salary.from.replace(/\s/g, '') || null,
            salaryTo: submitData.salary.to.replace(/\s/g, '') || null,
            currency: submitData.salary.currency ? submitData.salary.currency.label : '',
            recruiter: submitData.recruiter ? submitData.recruiter.value : '',
            responsible: submitData.responsible ? submitData.responsible.value : '',
        }

        const { success, error } = await update.request({ data })

        if (success) {
            navigate('/vacancies?view=board')
            return showMessage('Успешно обновлено!', 'success-msg')
        }

        return showMessage(error.data.errors[0].message || error.data.message, 'error-msg')
    }

    const initialValues = {
        ...info,
        skills: info.skill || [],
        pushToHh: info.hhVacancyId !== null,
        tag: info.tags ? info.tags.map((e) => e.id) : [],
        format: info.format ? WorkFormat[info.format - 1] : '',
        schedule: info.schedule ? WorkSchedule[info.schedule - 1] : '',
        experience: info.experience ? Experience[info.experience] : Experience[0],
        country: info.hhAreaId && { value: info.hhAreaId }, // TODO fix
        education: educationLvl.find((e) => e.value === info.education),
        gender: genderOptions.find((e) => e.value === info.gender),
        salary: {
            from: info.salary && info.salary.from ? format(integersOnly(info.salary.from)) : '',
            to: info.salary && info.salary.to ? format(integersOnly(info.salary.to)) : '',
            currency: banknoteOptions.find((e) => e.label === info.currency),
        },
        languageLevel: info.languageLevel ? [...info.languageLevel, { language: '', lvl: null }] : [],
        options: info.options || [{ key: '', value: '' }],
        position: info.position && { value: info.position.id, label: info.position.name },
        department: info.department && { value: info.department.id, label: info.department.name },
        // eslint-disable-next-line max-len
        location: info.location && info.location.id ? { ...info.location, value: info.location.id, label: info.location.name } : null,
        recruiter: info.recruiter && info.recruiter.id ? { value: info.recruiter.id, label: info.recruiter.name } : null, // eslint-disable-line max-len
        responsible: info.responsible && info.responsible.id ? { value: info.responsible.id, label: info.responsible.name } : null, // eslint-disable-line max-len
        isReinitialized: !!Object.keys(info).length,
    }

    return (
        <VacancyForm
            onSubmit={onSubmit}
            loading={update.loading}
            initialValues={initialValues}
        />
    )
}
