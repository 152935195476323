import cn from 'classnames'
import { css, StyleSheet } from 'aphrodite'
import uuid from 'react-uuid'
import React from 'react'
import { LoadingSkeleton } from '../common/LoadingSkeleton'
import { AvailableLanguages, educationLvl, LanguageLevels, WorkFormat, WorkSchedule } from '../../utils/options'
import { COLORS } from '../../utils/colors'

export function MoreInfo({ isShort, data, loading }) {
    return (
        <div className={cn(css(s.wrap), { [css(s.shortWrap)]: isShort })}>
            <div className={css(s.infLst)}>
                <ul className={cn('no_dot_list is-flex direction-column gap-2')}>
                    <li className={css(s.infItm)}>
                        <h3>Локация</h3>

                        <h3>
                            {!loading
                                ? data.location && (data.location.name || <div>&#8212;&#8212;&#8212;</div>)
                                : <LoadingSkeleton />}
                        </h3>
                    </li>

                    <hr className={css(s.hr)} />

                    <li className={css(s.infItm)}>
                        <h3>Формат</h3>

                        <h3>
                            {!loading
                                ? data.format ? WorkFormat[data.format - 1].label : <div>&#8212;&#8212;&#8212;</div>
                                : <LoadingSkeleton />}
                        </h3>
                    </li>

                    <hr className={css(s.hr)} />

                    <li className={css(s.infItm)}>
                        <h3>График</h3>

                        <h3>
                            {!loading
                                // eslint-disable-next-line max-len
                                ? data.schedule ? WorkSchedule[data.schedule - 1].label : <div>&#8212;&#8212;&#8212;</div>
                                : <LoadingSkeleton />}
                        </h3>
                    </li>

                    <hr className={css(s.hr)} />

                    <li className={css(s.infItm)}>
                        <h3>Рекрутеры</h3>

                        <h3>
                            {!loading
                                ? data.recruiter && data.recruiter.name
                                    ? data.recruiter.name : <div>&#8212;&#8212;&#8212;</div>
                                : <LoadingSkeleton />}
                        </h3>
                    </li>

                    <hr className={css(s.hr)} />

                    <li className={css(s.infItm)}>
                        <h3>Ответственный за заявку</h3>

                        <h3>
                            {!loading
                                ? data.responsible && data.responsible.name
                                    ? data.responsible.name : <div>&#8212;&#8212;&#8212;</div>
                                : <LoadingSkeleton />}
                        </h3>
                    </li>

                    <hr className={css(s.hr)} />

                    <li className={css(s.infItm)}>
                        <h3>Уровень образования</h3>

                        <h3>
                            {!loading
                                ? data.education
                                    ? educationLvl.find((e) => e.value === data.education).label
                                    : <div>&#8212;&#8212;&#8212;</div>
                                : <LoadingSkeleton width={60} />}
                        </h3>
                    </li>

                    {data.languageLevel && data.languageLevel.length !== 0 && <hr className={css(s.hr)} />}

                    {data.languageLevel ? data.languageLevel.map((lang, ind) => (
                        <React.Fragment key={uuid()}>

                            <li className={css(s.infItm)}>
                                <h3>{AvailableLanguages.find((e) => e.short === lang.language).ru} язык</h3>

                                <h3>{lang.level} ({LanguageLevels.find((e) => e.level === lang.level).ru})</h3>
                            </li>

                            {data.languageLevel.length !== ind + 1 && <hr className={css(s.hr)} />}
                        </React.Fragment>
                    )) : null }
                </ul>
            </div>

            <div className={css(s.stick)} />

            <div className={cn('is-flex direction-column gap-7', css(s.paragraph))}>
                {/* {data.skill.map(({ blockGroups }) => blockGroups.map((group) => ( */}
                {/*    <div key={group.groupId} className="is-flex direction-column gap-2"> */}
                {/*        <h3 className={css(s.tagsLabel)}>{group.groupName}</h3> */}

                {/*        <div className="is-flex gap-2 align-start is-flex-wrap"> */}
                {/*            {group.skills.map((skill) => ( */}
                {/*                <span */}
                {/*                    key={uuid()} */}
                {/*                    className={css(s.tag)}> */}
                {/*                    {skill.name} */}
                {/*                </span> */}
                {/*            ))} */}
                {/*        </div> */}
                {/*    </div> */}
                {/* )))} */}

                {/* <div className={css(s.fileCont)}> */}
                {/*     <div /> */}

                {/*     <div className={cn('is-flex direction-column flex-1', css(s.fileName))}> */}
                {/*         <h3>PDF file</h3> */}

                {/*         <h3>Требованию к кандидату на позицию UI UX дизайнера</h3> */}
                {/*     </div> */}

                {/*     <DownloadSizeL /> */}
                {/* </div> */}
            </div>
        </div>
    )
}

const s = StyleSheet.create({
    wrap: {
        height: '100%',
        display: 'grid',
        gridTemplateColumns: '1fr auto 1fr',
        padding: '0 1.5rem',
        gap: '1.5rem',
        transition: 'all 0.5s linear',
    },
    stick: {
        height: '100%',
        width: 1,
        backgroundColor: COLORS.smoothGray,
    },
    paragraph: {
        padding: '1.5rem 0',
        height: '100%',
        boxSizing: 'border-box',
        overflowY: 'auto',
    },
    tag: {
        padding: '5px 8px',
        background: '#2EA97D08',
        border: '1px solid #2EA97D33',
        borderRadius: 4,
        color: COLORS.mainColor,
        fontWeight: '500',
        fontSize: 11,
        outline: 'none',
    },
    tagsLabel: {
        color: COLORS.midGray,
        fontSize: 12,
        fontWeight: '500',
    },
    fileCont: {
        display: 'flex',
        padding: '0.75rem',
        background: COLORS.lotion,
        border: `1px solid ${COLORS.smoothGray}`,
        borderRadius: 4,
        gap: 12,
        alignItems: 'center',
        ':nth-child(1n) > :first-child': {
            width: '2.25rem',
            height: '2.25rem',
            background: '#2EA97D33',
            border: `1px solid ${COLORS.smoothGray}`,
            borderRadius: 2,
        },
    },
    fileName: {
        ':nth-child(1n) > :first-child': {
            color: COLORS.midGray,
            fontSize: 12,
            fontWeight: '500',
        },
        ':nth-child(1n) > :last-child': {
            color: COLORS.dark,
            fontSize: 13,
            fontWeight: '600',
        },
    },
    hr: {
        border: `0.5px solid ${COLORS.smoothGray}`,
        borderRadius: 12,
        margin: 0,
    },
    infLst: {
        padding: '1rem 0 1.5rem 0',
        height: '100%',
        boxSizing: 'border-box',
        overflowY: 'auto',
    },
    infItm: {
        color: COLORS.midGray,
        fontSize: 13,
        height: 24,
        fontWeight: '500',
        display: 'grid',
        alignItems: 'center',
        gridTemplateColumns: '45% 55%',
        ':nth-child(1n) > :first-child': {
            display: 'flex',
            alignItems: 'center',
            gap: 8,
            ':nth-child(1n) > :first-child': {
                ':nth-child(1n) > *': {
                    stroke: COLORS.spanishGray,
                },
            },
        },
        ':nth-child(1n) > :last-child': {
            color: COLORS.dark,
        },
    },
})
