import React, { useState } from 'react'
import cn from 'classnames'
import { css, StyleSheet } from 'aphrodite'
import uuid from 'react-uuid'
import SidebarLayout from '../../common/SidebarLayout'
import { COLORS } from '../../../utils/colors'
import { Popup } from '../../Popup'
import { ChevronNext, ThreeDotsMenu } from '../../svgs/Svgs'
import { cp } from '../../../utils/checkPermission'

export default function RolesList({ adminList, getAdmins, getRecruiters, recruiterList, onClose, popupItems, onAdd }) {
    const [admins, setAdmins] = useState(adminList)
    const [recruiters, setRecruiters] = useState(recruiterList)

    return (
        <SidebarLayout title="Рекрутеры и администраторы" onClose={onClose}>
            <div className={cn('is-flex direction-column gap-2', css(s.lst))}>
                <div className="justify-between">
                    <h3 className={css(s.title)}>Текущие рекрутеры</h3>

                    {cp('settings.company.edit_recruiter') && (
                        <h3 className={css(s.add)} onClick={() => onAdd()}>Добавить</h3>
                    )}
                </div>

                <ul className={cn('no_dot_list is-flex direction-column gap-2')}>
                    {recruiters.map((recruiter, ind) => {
                        const imageSrc = recruiter.photo && recruiter.photo.length > 0 ? recruiter.photo[4] : ''

                        return (
                            <React.Fragment key={recruiter.id}>
                                <li key={uuid()} className={cn('justify-between', css(s.list))}>
                                    <div className="flex-cent gap-2">
                                        {imageSrc ? (
                                            <img src={imageSrc} alt="#avatar" className={css(s.img)} />
                                        ) : (
                                            <div className={cn('flex-cent', css(s.defaultAvatar))}>
                                                <h3>
                                                    {recruiter.firstName && recruiter.lastName
                                                        // eslint-disable-next-line max-len
                                                        ? `${recruiter.firstName.slice(0, 1)}${recruiter.lastName.slice(0, 1)}`
                                                        : ''}
                                                </h3>
                                            </div>
                                        )}

                                        <div className={cn('is-flex direction-column', css(s.changeTxt))}>
                                            <h3 className={css(s.txt)}>
                                                {`${recruiter.firstName} ${recruiter.lastName}`}
                                            </h3>

                                            <span className={css(s.dep)}>
                                                {recruiter.department} • {recruiter.position}
                                            </span>
                                        </div>
                                    </div>

                                    {cp('settings.company.edit_recruiter') && (
                                        <Popup
                                            items={popupItems}
                                            renderItem={(item) => (
                                                <li
                                                    key={item.id}
                                                    onClick={() => {
                                                        item.onClick(
                                                            recruiter.id,
                                                            `${recruiter.firstName} ${recruiter.lastName}`,
                                                            () => {
                                                                getRecruiters()
                                                                setRecruiters(recruiters.filter(
                                                                    (e) => e.id !== recruiter.id,
                                                                ))
                                                            },
                                                        )
                                                    }}
                                                    className={cn('justify-between', css(s.cardSetting))}>
                                                    <div className="align-center">
                                                        <item.icon />
                                                        {item.value}
                                                    </div>

                                                    {item.navigate ? <ChevronNext /> : null}
                                                </li>
                                            )}
                                            trigger={(
                                                <span>
                                                    <ThreeDotsMenu className="pointer" style={{ marginRight: 16 }} />
                                                </span>
                                            )} />
                                    )}
                                </li>

                                {ind !== recruiters.length - 1 && <hr className={css(s.stick)} />}
                            </React.Fragment>
                        )
                    })}
                </ul>
            </div>

            <div className={cn('is-flex direction-column gap-2', css(s.lst))}>
                <div className="justify-between">
                    <h3 className={css(s.title)}>Текущие администраторы</h3>

                    {cp('settings.company.edit_admin') && (
                        <h3 className={css(s.add)} onClick={() => onAdd('admin')}>Добавить</h3>
                    )}
                </div>

                <ul className={cn('no_dot_list is-flex direction-column gap-2')}>
                    {admins.map((admin, ind) => {
                        const imageSrc = admin.photo && admin.photo.length > 0 ? admin.photo[4] : ''

                        return (
                            <React.Fragment key={admin.id}>
                                <li key={uuid()} className={cn('justify-between', css(s.list))}>
                                    <div className="flex-cent gap-2">
                                        {imageSrc ? (
                                            <img src={imageSrc} alt="#avatar" className={css(s.img)} />
                                        ) : (
                                            <div className={cn('flex-cent', css(s.defaultAvatar))}>
                                                <h3>
                                                    {admin.firstName && admin.lastName
                                                        // eslint-disable-next-line max-len
                                                        ? `${admin.firstName.slice(0, 1)}${admin.lastName.slice(0, 1)}`
                                                        : ''}
                                                </h3>
                                            </div>
                                        )}

                                        <div className={cn('is-flex direction-column', css(s.changeTxt))}>
                                            <h3 className={css(s.txt)}>{`${admin.firstName} ${admin.lastName}`}</h3>

                                            <span className={css(s.dep)}>
                                                {admin.department} • {admin.position}
                                            </span>
                                        </div>
                                    </div>

                                    {cp('settings.company.edit_admin') && (
                                        <Popup
                                            items={popupItems}
                                            renderItem={(item) => (
                                                <li
                                                    key={item.id}
                                                    onClick={() => {
                                                        item.onClick(
                                                            admin.id,
                                                            `${admin.firstName} ${admin.lastName}`,
                                                            () => {
                                                                getAdmins()
                                                                setAdmins(admins.filter((e) => e.id !== admin.id))
                                                            },
                                                        )
                                                    }}
                                                    className={cn('justify-between', css(s.cardSetting))}>
                                                    <div className="align-center">
                                                        <item.icon />
                                                        {item.value}
                                                    </div>

                                                    {item.navigate ? <ChevronNext /> : null}
                                                </li>
                                            )}
                                            trigger={(
                                                <span>
                                                    <ThreeDotsMenu className="pointer" style={{ marginRight: 16 }} />
                                                </span>
                                            )} />
                                    )}
                                </li>

                                {ind !== admins.length - 1 && <hr className={css(s.stick)} />}
                            </React.Fragment>
                        )
                    })}
                </ul>
            </div>
        </SidebarLayout>
    )
}

const s = StyleSheet.create({
    lst: {
        overFlowX: 'hidden',
        marginTop: 16,
        padding: 12,
        background: COLORS.white,
        border: `1px solid ${COLORS.smoothGray}`,
        boxShadow: '0px 2px 4px rgba(130, 130, 130, 0.05)',
        borderRadius: 4,
    },
    title: {
        fontWeight: 500,
        fontSize: 14,
        color: COLORS.lightBlack,
    },
    add: {
        cursor: 'pointer',
        fontWeight: 500,
        fontSize: 12,
        color: COLORS.skyblue,
    },
    stick: {
        width: '100%',
        border: `0.5px solid ${COLORS.smoothGray}`,
        margin: 0,
    },

    dep: {
        fontSize: 11,
        fontWeight: '400',
        color: COLORS.midGray,
    },
    img: {
        width: 30,
        height: 30,
        borderRadius: 50,
        border: `1px solid ${COLORS.lightGray}`,
        marginRight: 8,
    },
    changeTxt: {
        width: 'calc(100% - 36px)',
    },
    cardSetting: {
        width: '11rem',
        ':nth-child(1n) > :first-child': {
            ':nth-child(1n) > :first-child': {
                marginRight: 8,
            },
        },
    },
    txt: {
        fontSize: 13,
        fontWeight: '500',
        color: COLORS.lightBlack,
        lineHeight: 1,
        marginBottom: 3,
    },
    list: {
        height: 40,
        ':nth-child(1n) > :first-child': {
            flex: 1,
        },
    },
    defaultAvatar: {
        background: '#1B93C0',
        width: 32,
        height: 32,
        borderRadius: 32,
        fontWeight: '600',
        fontSize: 12,
        color: '#FFFFFF',
        textTransform: 'uppercase',
    },
})
