import React, { useContext } from 'react'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import { isEmpty } from 'lodash'
import { PlusRoundedSizeXl, ColorPalette, Crown, Recruiters } from '../svgs/Svgs'
import AmazingTooltip from '../common/AmazingTooltip'
import { ColorsPalette } from '../common/ColorsPalette'
import SidebarLayout from '../common/SidebarLayout'
import { Context } from '../../contexts/GlobalContext'
import { COLORS } from '../../utils/colors'
import { useLoad } from '../../hooks/request'
import { ADMIN_LIST, RECRUITER_LIST } from '../../urls'
import { cp } from '../../utils/checkPermission'

export default function EmployeesSettings({ onClose, setContent }) {
    const { theme, setTheme } = useContext(Context)
    const loadAdmins = useLoad({ url: ADMIN_LIST })
    const loadRecruiters = useLoad({ url: RECRUITER_LIST })

    const admins = loadAdmins.response ? loadAdmins.response.data : []
    const recruiters = loadRecruiters.response ? loadRecruiters.response.data : []

    return (
        <SidebarLayout onClose={onClose} title="Быстрые настройки">
            <div className={css(s.box)}>
                <div className={cn('align-center', css(s.heading))}>
                    <Crown /> Администраторы <span className={css(s.dot)}>&nbsp;•&nbsp;</span> {admins.length}
                </div>

                <div className={cn('align-center', css(s.imgCont))}>
                    {admins.slice(0, 5).map(({ photo, firstName, lastName, id }) => (
                        <AmazingTooltip
                            position="left"
                            key={id}
                            trigger={!isEmpty(photo) ? (
                                <img
                                    alt="#rec"
                                    src={photo[5]}
                                    className={css(s.recImg)} />
                            ) : (
                                <div className={cn('flex-cent', css(s.recImg, s.defaultImg))}>
                                    {firstName && lastName ? firstName.slice(0, 1) + lastName.slice(0, 1) : ''}
                                </div>
                            )}>
                            <div style={{ whiteSpace: 'nowrap' }}>
                                {firstName} {lastName}
                            </div>
                        </AmazingTooltip>
                    ))}

                    {admins.length > 5 && (
                        <span className={cn(css(s.moreCount), 'flex-cent')}>+{admins.length - 5}</span>
                    )}

                    {cp('settings.company.edit_admin') && (
                        <PlusRoundedSizeXl className={css(s.add)} onClick={() => setContent('admin')} />
                    )}
                </div>
            </div>

            <div className={css(s.box)}>
                <div className={cn('align-center', css(s.heading))}>
                    <Recruiters /> Рекрутеры <span className={css(s.dot)}>&nbsp;•&nbsp;</span> {recruiters.length}
                </div>

                <div className={cn('align-center', css(s.imgCont))}>
                    {recruiters.slice(0, 5).map(({ photo, firstName, lastName, id }) => (
                        <AmazingTooltip
                            position="left"
                            key={id}
                            trigger={!isEmpty(photo) ? (
                                <img
                                    alt="#rec"
                                    src={photo[5]}
                                    className={css(s.recImg)} />
                            ) : (
                                <div className={cn('flex-cent', css(s.recImg, s.defaultImg))}>
                                    {firstName && lastName ? firstName.slice(0, 1) + lastName.slice(0, 1) : ''}
                                </div>
                            )}>
                            <div style={{ whiteSpace: 'nowrap' }}>
                                {firstName} {lastName}
                            </div>
                        </AmazingTooltip>
                    ))}

                    {recruiters.length > 5 && (
                        <span className={cn(css(s.moreCount), 'flex-cent')}>+{recruiters.length - 5}</span>
                    )}

                    {cp('settings.company.edit_recruiter') && (
                        <PlusRoundedSizeXl className={css(s.add)} onClick={() => setContent('recruiter')} />
                    )}
                </div>
            </div>

            <div className={css(s.box)}>
                <div className={cn('align-center', css(s.heading))}>
                    <ColorPalette /> Цвет фона
                </div>

                <ColorsPalette defaultColor={theme.eB} onClick={({ index }) => {
                    setTheme({ ...theme, eB: index })
                }} />
            </div>
        </SidebarLayout>
    )
}

const s = StyleSheet.create({
    box: {
        background: COLORS.white,
        border: `1px solid ${COLORS.smoothGray}`,
        boxShadow: '0px 2px 4px rgba(130, 130, 130, 0.05)',
        borderRadius: 4,
        padding: 12,
        marginTop: 20,
        ':last-of-type': {
            marginBottom: 48,
        },
    },
    heading: {
        color: COLORS.lightBlack,
        fontSize: 14,
        fontWeight: '500',
        ':nth-child(1n) > :first-child': {
            marginRight: 4,
        },
    },
    dot: {
        color: COLORS.lightGray,
    },
    moreCount: {
        padding: '7.5px 7px',
        background: COLORS.lightBlack,
        borderRadius: 50,
        fontWeight: '600',
        fontSize: 14,
        // cursor: 'pointer',
        color: COLORS.white,
        // ':hover': {
        //     background: COLORS.skyblue,
        // },
    },
    add: {
        ':hover': {
            ':nth-child(1n) > *': {
                stroke: COLORS.skyblue,
            },
        },
    },
    recImg: {
        width: 30,
        height: 30,
        borderRadius: 50,
        cursor: 'pointer',
        border: `1px solid ${COLORS.lightGray}`,
    },
    defaultImg: {
        backgroundColor: '#1B93C0',
        color: COLORS.white,
        textTransform: 'uppercase',
        fontSize: 14,
    },
    imgCont: {
        marginTop: 12,
        ':nth-child(1n) > *': {
            marginRight: 10,
        },
        ':nth-child(1n) > :last-child': {
            marginRight: 0,
        },
    },
    statCont: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: 8,
        marginTop: 8,
        ':nth-child(1n) > :last-child': {
            cursor: 'pointer',
            border: '1px solid',
            borderColor: COLORS.smoothGray,
            ':hover': {
                borderColor: COLORS.lightGray,
                boxShadow: '0px 2px 4px rgba(168, 168, 168, 0.15)',
            },
        },
    },
    addStat: {
        color: COLORS.midGray,
        fontSize: 11,
        fontWeight: '500',
        backgroundColor: COLORS.lotion,
        padding: '2px 8px 2px 5px',
        borderRadius: 4,
        ':nth-child(1n) > :first-child': {
            marginRight: 4,
            ':nth-child(1n) > :first-child': {
                stroke: COLORS.midGray,
                strokeWidth: 1.2,
            },
        },
    },
    list: {
        padding: '16px 0',
        borderBottom: `1px solid ${COLORS.smoothGray}`,
        ':last-of-type': {
            borderBottom: '0px solid transparent',
            paddingBottom: 0,
        },
    },
    changeTxt: {
        width: 'calc(100% - 36px)',
    },
    txt: {
        fontSize: 13,
        fontWeight: '400',
        color: COLORS.lightBlack,
        lineHeight: 1,
        marginBottom: 4,
    },
    boldTxt: {
        fontWeight: '600',
        color: COLORS.dark,
    },
    time: {
        fontSize: 12,
        fontWeight: '400',
        color: COLORS.midGray,
    },
    showAll: {
        fontSize: 12,
        cursor: 'pointer',
        fontWeight: '500',
        color: COLORS.midGray,
        ':hover': {
            color: COLORS.skyblue,
            ':nth-child(1n) > :first-child': {
                ':nth-child(1n) > :first-child': {
                    stroke: COLORS.skyblue,
                },
            },
        },
        ':nth-child(1n) > :first-child': {
            ':nth-child(1n) > :first-child': {
                stroke: COLORS.midGray,
            },
        },
    },
})
