import React, { memo, useCallback, useState } from 'react'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import { COLORS } from '../../../utils/colors'
import EditableContent from '../../common/EditableContent'
import { useGetRequest } from '../../../hooks/request'
import { SKILLS_LIST } from '../../../urls'

function MemoizedComponent({ values, setFieldValue }) {
    const { skills } = values
    const [skillCreating, setSkillCreating] = useState(false)
    const getSkills = useGetRequest({ url: SKILLS_LIST })
    const [skillsList, setSkillsList] = useState([])

    const selectedSkills = skills.map((e) => e.id)

    const onRemove = (id) => {
        setFieldValue('skills', skills.filter((e) => e.id !== id))
    }

    const searchSkills = useCallback(async (text) => {
        const { response, success } = await getSkills.request({ params: { key: text } })
        if (success) setSkillsList(response.data.filter((e) => !selectedSkills.includes(e.id)))
    }, [selectedSkills])

    return (
        <div className="is-flex direction-column gap-3">
            <h3 className={css(s.title)}>
                Требования навыков для кандидата
            </h3>

            <div className={cn('align-center gap-5 is-flex-wrap', css(s.skills))}>
                {skills.map((skill) => (
                    <EditableContent
                        data={skills}
                        dataItem={skill}
                        setData={(e) => setFieldValue('skills', e)}
                        maxLength={100}
                        options={skillsList}
                        setOptions={setSkillsList}
                        onChange={searchSkills}
                        onRemove={onRemove}
                    />
                ))}

                {skillCreating && (
                    <EditableContent
                        data={skills}
                        onChange={searchSkills}
                        options={skillsList}
                        setOptions={setSkillsList}
                        setCreating={setSkillCreating}
                        creating={skillCreating}
                        dataItem={{ id: '', name: '' }}
                        setData={(e) => setFieldValue('skills', e)}
                        maxLength={100}
                    />
                )}

                {!skillCreating && (
                    <span className="pointer" onClick={() => setSkillCreating(true)}>
                        <h3 className={css(s.add)}>+ Добавить навык</h3>
                    </span>
                )}
            </div>
        </div>
    )
}

export const Skills = memo(MemoizedComponent)

const s = StyleSheet.create({
    add: {
        color: COLORS.mainColor,
        fontSize: 14,
        fontWeight: '500',
    },
    skills: {
        minHeight: 28,
    },
    title: {
        color: COLORS.midGray,
        fontSize: 14,
        fontWeight: '500',
    },
})
