import React, { useEffect, useState } from 'react'
import { css, StyleSheet } from 'aphrodite'
import { useGetRequest } from '../../hooks/request'
import { CANDIDATE_BOARD_LIST, VACANCY_CANDIDATES } from '../../urls'
import { KanbanBoard } from '../common/kanban/KanbanBoard'
import { fetchDataByFilter } from '../../utils/filter'

export default function CandidateKanbanBoard({ candidateFilter, setCandidatesCount, selectedVacancy }) {
    const [columns, setColumns] = useState([])
    const getAllCandidates = useGetRequest({ url: CANDIDATE_BOARD_LIST })
    const getVacancyCandidates = useGetRequest({ url: VACANCY_CANDIDATES.replace('{id}', selectedVacancy) })

    const allCandidates = getAllCandidates.response ? getAllCandidates.response.data : []
    const vacancyCandidates = getVacancyCandidates.response ? getVacancyCandidates.response.data : []

    const getData = selectedVacancy === -1 ? getAllCandidates : getVacancyCandidates
    const data = selectedVacancy === -1 ? allCandidates : vacancyCandidates

    useEffect(() => {
        fetchDataByFilter(getData, candidateFilter)
    }, [candidateFilter, selectedVacancy])

    function onSetColumns(d) {
        if (d.length !== 0) {
            const result = {}

            for (let i = 0; i < d.length; i++) {
                result[i] = d[i]
            }

            setColumns(result)
            setCandidatesCount(d.map((col) => col.count).reduce((x, y) => x + y, 0))
        }
    }

    useEffect(() => {
        onSetColumns(allCandidates)
    }, [allCandidates])

    useEffect(() => {
        onSetColumns(vacancyCandidates)
    }, [vacancyCandidates])

    return (
        <div className={css(s.pWrp)}>
            <KanbanBoard
                isRow
                sibHeight="0px"
                getData={getData}
                columns={columns}
                data={data}
                setColumns={setColumns}
                vacancyId={selectedVacancy}
            />
        </div>
    )
}

const s = StyleSheet.create({
    pWrp: {
        borderRadius: 5,
        height: 'calc(100% - 60px)',
    },
})
