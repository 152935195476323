import React from 'react'
import { BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    ResponsiveContainer,
    LabelList } from 'recharts'
import { COLORS } from '../../../utils/colors'

function CustomTick({ y, index, payload, height, chartData }) {
    return (
        <>
            <text
                x={0}
                y={y + 18}
                width={100}
                height={height}
                orientation="left"
                fill={COLORS.dark}
                fontSize={12}
                fontWeight={500}
                className="recharts-text recharts-cartesian-axis-tick-value"
            >
                {index !== 2 ? (
                    <tspan>{payload.value}</tspan>
                ) : payload.value.split(' ').map((word, arrIndex) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <tspan fontSize={11} x={0} dy={arrIndex > 0 ? '1em' : 0} key={arrIndex}>
                        {word}
                    </tspan>
                )) }
            </text>

            <image
                y={y - 24}
                href={chartData[index].logo}
                width={28}
                height={28}
            />
        </>
    )
}

const CustomLabel = (props) => {
    const { x, y, width, value, index } = props

    return index !== 2 && (
        <g>
            <text
                x={x + width + 8}
                y={y + 5}
                fill={COLORS.dark}
                fontSize={12}
                fontWeight={500}
                textAnchor="start"
                dominantBaseline="hanging">
                {value ? `${value}%` : null}
            </text>
        </g>
    )
}

export function BarChartCustom({ chartData }) {
    return (
        <ResponsiveContainer>
            <BarChart
                layout="vertical"
                data={chartData}
                barGap={8}
                margin={{ top: 20, right: 20, left: 20, bottom: 20 }}
            >
                <CartesianGrid strokeDasharray={4} horizontal={false} />

                <XAxis type="number" axisLine={false} tickLine={false} />

                <YAxis
                    axisLine={false}
                    tickLine={false}
                    dataKey="name"
                    type="category"
                    tick={<CustomTick chartData={chartData} />}
                />

                <Bar
                    radius={4}
                    barSize={20}
                    name="Отклики"
                    dataKey="hired"
                    fill={COLORS.mainColor}
                    label={{ fill: COLORS.white, fontSize: 12, fontWeight: '600' }}
                >
                    <LabelList dataKey="percent" content={<CustomLabel />} />
                </Bar>

                <Bar
                    radius={4}
                    barSize={20}
                    name="Просмотры"
                    fill="#18D88B"
                    dataKey="viewed"
                    label={{ fill: COLORS.white, fontSize: 12, fontWeight: '600' }}
                />
            </BarChart>
        </ResponsiveContainer>
    )
}
