import React from 'react'
import cn from 'classnames'
import { css, StyleSheet } from 'aphrodite'
import { COLORS } from '../../../utils/colors'

export default function MoreOptions({ values }) {
    const options = values.options.filter((option) => option.key)

    return !!options.length && (
        <div className={cn('is-flex direction-column gap-5')}>
            <h3 className={css(s.name)}>Дополнительная информация:</h3>

            <div className={cn('align-center gap-2')}>
                <ul className={cn('no_dot_list', css(s.lng))}>
                    {options.map((option) => (
                        <li>• {option.key} — <span>{option.value}</span></li>
                    ))}
                </ul>
            </div>
        </div>
    )
}
const s = StyleSheet.create({
    name: {
        color: COLORS.dark,
        fontSize: 20,
        fontWeight: '600',
    },
    lng: {
        color: '#343836',
        fontSize: 14,
        fontWeight: '600',
        ':nth-child(1n) > *': {
            ':nth-child(1n) > span': {
                fontWeight: '500',
            },
        },
    },
})
