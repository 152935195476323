import React from 'react'
import cn from 'classnames'
import { css, StyleSheet } from 'aphrodite'
import { COLORS } from '../../../../utils/colors'
import { fromToStr } from '../../../../utils/string'
import { BachelorHat, Briefcase, BuildingSizeM, DeviceLaptop, MapPin, WalletSizeM } from '../../../svgs/Svgs'
import { format } from '../../../../utils/number'
import { educationLvl, WorkFormat, WorkSchedule } from '../../../../utils/options'

export default function BaseInfo({ vacancy }) {
    const { experience, salary, location, education, format: workFormat, schedule } = vacancy

    const baseInfo = [
        {
            id: 1,
            title: 'Опыт',
            // eslint-disable-next-line max-len
            value: experience ? fromToStr(experience.from ? experience.from.toString() : '', experience.to ? experience.to.toString() : '', 'лет', 'Не важно') : '',
            icon: Briefcase,
        },
        {
            id: 2,
            title: 'Зарплата',
            value: salary ? fromToStr(
                salary.from ? format(salary.from) : '',
                salary.to ? format(salary.to) : '',
                vacancy.currency,
                'По итогам собеседование',
            ) : '',
            icon: WalletSizeM,
        },
        { id: 3, title: 'Локация работы', value: location ? location.name : null, icon: MapPin },
        { id: 4, title: 'Образование', value: education ? educationLvl[education - 1].label : null, icon: BachelorHat },
        // eslint-disable-next-line max-len
        { id: 5, title: 'Формат работы', value: workFormat ? WorkFormat[workFormat - 1].label : '', icon: DeviceLaptop },
        { id: 6, title: 'График работы', value: schedule ? WorkSchedule[schedule - 1].label : '', icon: BuildingSizeM },
    ].filter((e) => e.value)

    return (
        <div className={cn('align-center', css(s.items))}>
            {baseInfo.map((info) => (
                <div key={info.id} className={css(s.itemWrap)}>
                    <info.icon color={COLORS.mainColor} />

                    <div className={css(s.info)}>
                        <h3>{info.title}</h3>

                        <p>{info.value}</p>
                    </div>
                </div>
            ))}
        </div>
    )
}

const s = StyleSheet.create({
    items: {
        flexWrap: 'wrap',
        gap: 16,
        '@media (max-width: 768px)': {
            gap: 8,
        },
    },
    itemWrap: {
        display: 'flex',
        alignItems: 'center',
        padding: '6px 10px',
        gap: 10,
        ':nth-child(1n) > :first-child': {
            ':nth-child(1n) > path': {
                stroke: COLORS.mainColor,
            },
            '@media (max-width: 768px)': {
                width: 24,
            },
        },
        background: COLORS.white,
        border: `1px solid ${COLORS.smoothGray}`,
        boxShadow: '0px 2px 4px rgba(130, 130, 130, 0.05)',
        borderRadius: 4,
        '@media (max-width: 768px)': {
            padding: '4px 8px',
            gap: 4,
        },
    },
    info: {
        display: 'flex',
        gap: 4,
        flexDirection: 'column',
        ':nth-child(1n) > h3': {
            fontWeight: '500',
            fontSize: 12,
            color: COLORS.gray,
        },
        ':nth-child(1n) > p': {
            fontWeight: '600',
            fontSize: 13,
            color: COLORS.dark,
            '@media (max-width: 768px)': {
                fontSize: 12,
            },
        },
    },
})
