import { StyleSheet, css } from 'aphrodite'
import cn from 'classnames'
import { range } from 'lodash'
import React, { useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import uuid from 'react-uuid'
import { useLoad } from '../../../hooks/request'
import { useSidebar } from '../../../hooks/sidebar'
import { ADMIN_LIST, RECRUITER_LIST } from '../../../urls'
import { cp } from '../../../utils/checkPermission'
import { COLORS } from '../../../utils/colors'
import { Popup } from '../../Popup'
import { LoadingSkeleton } from '../../common/LoadingSkeleton'
import EmployeeDetailModal from '../../employees/EmployeeDetailModal'
import { ChevronNext, EditPencil, Key, ThreeDotsMenu, Trash } from '../../svgs/Svgs'
import { RemoveRole } from '../RemoveRole'
import AdminCreate from '../admins/AdminCreate'
import RecruiterCreate from '../recruiters/RecruiterCreate'
import RolesList from './RolesList'

export default function RolesSettings() {
    const [modal, setModal] = useState({ act: false })
    const modalRef = useRef()
    const navigate = useNavigate()

    const loadAdmins = useLoad({ url: ADMIN_LIST })
    const loadRecruiters = useLoad({ url: RECRUITER_LIST })

    const admins = loadAdmins.response ? loadAdmins.response.data : []
    const recruiters = loadRecruiters.response ? loadRecruiters.response.data : []

    const popupItems = [
        { id: 1, value: 'Изменить', icon: EditPencil, navigate: true, onClick: (id) => navigate(`/employees/update/${id}`) }, // eslint-disable-line max-len
        { id: 2, value: 'Удалить', icon: Trash, navigate: false, onClick: (id, name, request) => modalRef.current.openModal(id, name, request) }, // eslint-disable-line max-len
    ]

    const [showRecruiterBar, hideRecruiterBar] = useSidebar(
        <RecruiterCreate
            onSuccess={(employees) => {
                if (employees.some((e) => e.role === 2)) {
                    loadAdmins.setResponse((prev) => ({
                        ...loadRecruiters.response,
                        data: prev.data.filter((v) => !employees.some((e) => e.value === v.id)),
                    }))
                }

                loadRecruiters.request()
                hideAdministratorBar()
            }}
            onClose={() => hideRecruiterBar()} />,
    )

    const [showAdministratorBar, hideAdministratorBar] = useSidebar(
        <AdminCreate
            onSuccess={(employees) => {
                if (employees.some((e) => e.role === 3)) {
                    loadRecruiters.setResponse((prev) => ({
                        ...loadRecruiters.response,
                        data: prev.data.filter((v) => !employees.some((e) => e.value === v.id)),
                    }))
                }

                loadAdmins.request()
                hideAdministratorBar()
            }}
            onClose={() => hideAdministratorBar()} />,
    )

    const [showListSidebar, hideListSidebar] = useSidebar(
        <RolesList
            adminList={admins}
            popupItems={popupItems}
            recruiterList={recruiters}
            getAdmins={loadAdmins.request}
            onClose={() => hideListSidebar()}
            getRecruiters={loadRecruiters.request}
            onAdd={(type) => (type === 'admin' ? showAdministratorBar() : showRecruiterBar())}
        />,
    )

    return (
        <>
            <div className={css(s.form)}>
                <div className={css(s.formName)}>
                    <Key />

                    <h3>Роли и Права доступа</h3>
                </div>

                <div className={css(s.wrap)}>
                    <div>
                        <div className={cn('justify-between', css(s.listLegend))}>
                            <h3>Администраторы:</h3>

                            {cp('settings.company.edit_admin') && (
                                <h3
                                    className={css(s.add)}
                                    onClick={() => showAdministratorBar()}>
                                    Добавить
                                </h3>
                            )}
                        </div>

                        <ul className={css(s.listH)}>
                            {!loadAdmins.loading ? admins.slice(0, 2).map((admin) => {
                                const imageSrc = admin.photo && admin.photo.length > 0 ? admin.photo[4] : ''

                                return (
                                    <li key={uuid()} className={cn('justify-between', css(s.list))}>
                                        <div
                                            className="flex-cent gap-2 pointer"
                                            onClick={() => setModal({ act: true, id: admin.id })}>
                                            {imageSrc ? (
                                                <img src={imageSrc} alt="#avatar" className={css(s.img)} />
                                            ) : (
                                                <div className={cn('flex-cent', css(s.defaultAvatar))}>
                                                    <h3>
                                                        {admin.firstName && admin.lastName
                                                            // eslint-disable-next-line max-len
                                                            ? `${admin.firstName.slice(0, 1)}${admin.lastName.slice(0, 1)}`
                                                            : ''}
                                                    </h3>
                                                </div>
                                            )}

                                            <div className={cn('is-flex direction-column', css(s.changeTxt))}>
                                                <h3 className={css(s.txt)}>{`${admin.firstName} ${admin.lastName}`}</h3>

                                                <span className={css(s.dep)}>
                                                    {admin.department} • {admin.position}
                                                </span>
                                            </div>
                                        </div>

                                        {cp('settings.company.edit_admin') && (
                                            <Popup
                                                items={popupItems}
                                                renderItem={(item) => (
                                                    <li
                                                        key={item.id}
                                                        onClick={() => item.onClick(
                                                            admin.id,
                                                            `${admin.firstName} ${admin.lastName}`,
                                                            loadAdmins.request,
                                                        )}
                                                        className={cn('justify-between', css(s.cardSetting))}>
                                                        <div className="align-center">
                                                            <item.icon /> {item.value}
                                                        </div>

                                                        {item.navigate ? <ChevronNext /> : null}
                                                    </li>
                                                )}
                                                trigger={(
                                                    <span>
                                                        <ThreeDotsMenu
                                                            className="pointer"
                                                            style={{ marginRight: 16 }} />
                                                    </span>
                                                )} />
                                        )}
                                    </li>
                                )
                            }) : range(2).map(() => (
                                <li key={uuid()} className={cn('align-center gap-2', css(s.list))}>
                                    <LoadingSkeleton height={32} width={32} borderRadius={64} />

                                    <div className="is-flex direction-column gap-1">
                                        <LoadingSkeleton inline height={15} width={216} borderRadius={4} />

                                        <LoadingSkeleton inline height={13} width={192} borderRadius={4} />
                                    </div>
                                </li>
                            )) }
                        </ul>
                    </div>

                    <div>
                        <div className={cn('justify-between', css(s.listLegend))}>
                            <h3>Рекрутеры:</h3>

                            {cp('settings.company.edit_recruiter') && (
                                <h3
                                    className={css(s.add)}
                                    onClick={() => showRecruiterBar()}>
                                    Добавить
                                </h3>
                            )}
                        </div>

                        <ul className={css(s.listH)}>
                            {!loadRecruiters.loading ? recruiters.slice(0, 6).map((recruiter) => {
                                const imageSrc = recruiter.photo && recruiter.photo.length > 0 ? recruiter.photo[4] : ''

                                return (
                                    <li key={uuid()} className={cn('justify-between', css(s.list))}>
                                        <div className="flex-cent gap-2 pointer"
                                            onClick={() => setModal({ act: true, id: recruiter.id })}>
                                            {imageSrc ? (
                                                <img src={imageSrc} alt="#avatar" className={css(s.img)} />
                                            ) : (
                                                <div className={cn('flex-cent', css(s.defaultAvatar))}>
                                                    <h3>
                                                        {recruiter.firstName && recruiter.lastName
                                                            // eslint-disable-next-line max-len
                                                            ? `${recruiter.firstName.slice(0, 1)}${recruiter.lastName.slice(0, 1)}`
                                                            : ''}
                                                    </h3>
                                                </div>
                                            )}

                                            <div className={cn('is-flex direction-column', css(s.changeTxt))}>
                                                <h3 className={css(s.txt)}>
                                                    {`${recruiter.firstName} ${recruiter.lastName}`}
                                                </h3>

                                                <span className={css(s.dep)}>
                                                    {recruiter.department} • {recruiter.position}
                                                </span>
                                            </div>
                                        </div>

                                        {cp('settings.company.edit_recruiter') && (
                                            <Popup
                                                items={popupItems}
                                                renderItem={(item) => (
                                                    <li
                                                        key={item.id}
                                                        onClick={() => item.onClick(
                                                            recruiter.id,
                                                            `${recruiter.firstName} ${recruiter.lastName}`,
                                                            loadRecruiters.request,
                                                        )}
                                                        className={cn('justify-between', css(s.cardSetting))}>
                                                        <div className="align-center">
                                                            <item.icon /> {item.value}
                                                        </div>

                                                        {item.navigate ? <ChevronNext /> : null}
                                                    </li>
                                                )}
                                                trigger={(
                                                    <span>
                                                        <ThreeDotsMenu
                                                            className="pointer"
                                                            style={{ marginRight: 16 }} />
                                                    </span>
                                                )} />
                                        )}
                                    </li>
                                )
                            }) : range(6).map(() => (
                                <li key={uuid()} className={cn('align-center gap-2', css(s.list))}>
                                    <LoadingSkeleton height={32} width={32} borderRadius={64} />

                                    <div className="is-flex direction-column gap-1">
                                        <LoadingSkeleton inline height={15} width={216} borderRadius={4} />

                                        <LoadingSkeleton inline height={13} width={192} borderRadius={4} />
                                    </div>
                                </li>
                            )) }
                        </ul>
                    </div>
                </div>

                {(admins.length > 2 || recruiters.length > 6) && (
                    <div onClick={() => showListSidebar()} className={css(s.foot)}>
                        + Ещё {
                            (admins.length - 2 > 0 ? admins.length - 2 : 0)
                        + (recruiters.length - 6 > 0 ? recruiters.length - 6 : 0)
                        }
                    </div>
                ) }
            </div>

            <RemoveRole ref={modalRef} />

            <EmployeeDetailModal
                id={modal.id}
                openModal={modal.act}
                onClose={() => setModal({ act: false })}
                onSuccess={() => {
                    // request()
                    setModal({ act: false })
                }}
                // onArchive={() => {
                //     // setArchiveModal({ act: true, item: { id, name } })
                //     setModal({ act: false })
                // }}
            />
        </>
    )
}

const s = StyleSheet.create({
    form: {
        backgroundColor: '#fff',
        borderRadius: 4,
    },
    formName: {
        height: 'calc(3rem - 1px)',
        padding: '0 24px',
        display: 'flex',
        alignItems: 'center',
        fontSize: 13,
        fontWeight: '600',
        color: COLORS.dark,
        borderBottom: `1px solid ${COLORS.lightGray}`,
        textTransform: 'uppercase',
        ':nth-child(1n) > :first-child': {
            marginRight: 8,
        },
    },
    add: {
        color: COLORS.skyblue,
        cursor: 'pointer',
        ':hover': {
            // eslint-disable-next-line max-len
            background: `linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(0deg, ${COLORS.skyblue}, ${COLORS.skyblue})`,
            '-webkit-background-clip': 'text',
            '-webkit-text-fill-color': 'transparent',
        },
    },
    wrap: {
        padding: '16px 24px',
        display: 'flex',
        flexDirection: 'column',
        gap: 24,
    },
    dep: {
        fontSize: 11,
        fontWeight: '400',
        color: COLORS.midGray,
    },
    img: {
        width: 30,
        height: 30,
        borderRadius: 50,
        border: `1px solid ${COLORS.lightGray}`,
        marginRight: 8,
    },
    changeTxt: {
        width: 'calc(100% - 36px)',
    },
    cardSetting: {
        width: '11rem',
        ':nth-child(1n) > :first-child': {
            ':nth-child(1n) > :first-child': {
                marginRight: 8,
            },
        },
    },
    txt: {
        fontSize: 13,
        fontWeight: '500',
        color: COLORS.lightBlack,
        lineHeight: 1,
        marginBottom: 3,
    },
    listLegend: {
        marginBottom: 8,
        ':nth-child(1n) > :first-child': {
            fontWeight: '500',
            fontSize: 12,
            color: COLORS.midGray,
        },
        ':nth-child(1n) > :last-child': {
            fontWeight: '500',
            fontSize: 12,
        },
    },
    list: {
        height: 40,
        // ':nth-child(1n) > :first-child': {
        //     flex: 1,
        // },
    },
    listH: {
        listStyle: 'none',
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        justifyContent: 'space-between',
        gap: '16px 32px',
    },
    defaultAvatar: {
        background: '#1B93C0',
        width: 32,
        height: 32,
        borderRadius: 32,
        fontWeight: '600',
        fontSize: 12,
        color: '#FFFFFF',
        textTransform: 'uppercase',
    },
    foot: {
        borderTop: `1px solid ${COLORS.smoothGray}`,
        padding: '12px 0',
        textAlign: 'center',
        fontWeight: '500',
        fontSize: 11,
        textTransform: 'uppercase',
        color: COLORS.skyblue,
        cursor: 'pointer',
        ':hover': {
            // eslint-disable-next-line max-len
            background: `linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(0deg, ${COLORS.skyblue}, ${COLORS.skyblue})`,
            '-webkit-background-clip': 'text',
            '-webkit-text-fill-color': 'transparent',
        },
    },
})
