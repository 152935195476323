import React from 'react'
import { css, StyleSheet } from 'aphrodite'
import MainReportItem from './MainReportItem'
import { useLoad } from '../../hooks/request'
import { COLUMN_STATISTICS } from '../../urls'

const columnsName = {
    new: 'Вакансий создано',
    inProgress: 'Вакансии в работе',
    stopped: 'Приостановленные вакансии',
    closed: 'Закрытые вакансии',

}

export default function MainReports() {
    const getReports = useLoad({ url: COLUMN_STATISTICS })
    const reports = getReports.response ? getReports.response.data : {}
    const { new: newColumn, inProgress, stopped, closed } = reports

    return (
        <div className={css(s.gr)}>
            <MainReportItem
                loading={getReports.loading}
                title={columnsName.new}
                column={newColumn}
            />

            <MainReportItem
                loading={getReports.loading}
                title={columnsName.inProgress}
                column={inProgress}
            />

            <MainReportItem
                loading={getReports.loading}
                title={columnsName.stopped}
                column={stopped}
            />

            <MainReportItem
                loading={getReports.loading}
                title={columnsName.closed}
                column={closed}
            />
        </div>
    )
}

const s = StyleSheet.create({
    gr: {
        marginTop: '1.25rem',
        width: '100%',
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fit, minmax(220px, 1fr))',
        gridGap: 20,
        gridAutoRows: 'minmax(1fr, 1fr)',
    },
})
