export const REGISTRATION = 'v1/registration'
export const TOKEN = 'v1/oauth/token'
export const VERIFY_EMAIL = 'v1/verify-email'
export const USER_CHECK = 'v1/check-access'
export const USER_INFO = 'v1/user/info'
export const LOGOUT = 'v1/user/logout'
export const SET_ROLE = 'v1/user/set-role'
export const TRANSFER_OWNER_ROLE = 'v1/user/transfer-owner-role'
export const TRANSFER_OWNER_ROLE_CONFIRM = '/v1/confirm-transfer-owner-role'
export const USER_DELETE = 'v1/user/delete-account'
export const USER_DELETE_CONFIRM = 'v1/confirm-delete-account'
export const CHANGE_PASSWORD = 'v1/user/change-password'
export const FORGOT_PASSWORD = 'v1/forgot-password'
export const RESET_PASSWORD = 'v1/reset-password'
export const NEW_PASSWORD = 'v1/create-new-password'

export const INTEGRATION_CHECK_HH = 'v1/company/hh-integration'
export const HH_BILLING_TYPES = 'v1/vacancy/hh-billing-type'

export const COLUMN_STATISTICS = 'v1/dashboard/vacancies-count'
export const EMPLOYEE_STATISTICS = 'v1/dashboard/employee'
export const APPLIED_VACANCIES_STATISTICS = 'v1/dashboard/applied-vacancies'
export const EVENTS = 'v1/dashboard/event/{date}'

export const COMPANY_INFO = 'v1/company/info'
export const COMPANY_LIST = 'v1/company/list'
export const COMPANY_SELECT = 'v1/company/select'
export const COMPANY_CREATE = 'v1/company/create'
export const COMPANY_UPDATE = 'v1/company/update'
export const COMPANY_LOGO = 'v1/company/logo'
export const INDUSTRIES_LIST = 'v1/industries'
export const COMPANY_BANNER = 'v1/company/banner'
export const COMPANY_PHOTOS = 'v1/company/photos'
export const COMPANY_VACANCIES = 'v1/company/vacancies'

export const INTEGRATION_HH = '/v1/company/set-auth-code'

export const LOCATION_LIST = 'v1/locations'
export const LOCATION_DETAIL = 'v1/locations/{id}'
export const LOCATION_CREATE = 'v1/locations'
export const LOCATION_UPDATE = 'v1/locations/{id}'
export const LOCATION_DELETE = 'v1/locations/{id}'

export const DEPARTMENT_LIST = 'v1/departments'
export const DEPARTMENT_CREATE = 'v1/departments'
export const DEPARTMENT_UPDATE = 'v1/departments/{id}'
export const DEPARTMENT_DELETE = 'v1/departments/{id}'

export const POSITIONS_LIST = 'v1/positions'
export const POSITIONS_CREATE = 'v1/positions'
export const POSITIONS_UPDATE = 'v1/positions/{id}'
export const POSITIONS_DELETE = 'v1/positions/{id}'

export const TAG_LIST = 'v1/vacancy/tag'
export const TAG_CREATE = 'v1/vacancy/tag'
export const TAG_UPDATE = 'v1/vacancy/tag/{id}'
export const TAG_DELETE = 'v1/vacancy/tag/{id}'

export const SKILLS_LIST = 'v1/vacancy/skills'
export const KNOWLEDGE_LIST = 'v1/vacancy/knowledge'

export const TEMPLATE_LIST = 'v1/vacancy/template/list'
export const TEMPLATE_DETAIL = 'v1/vacancy/template/{id}'
export const TEMPLATE_DELETE = 'v1/vacancy/template/{id}'

export const VACANCY_LIST = 'v1/vacancy/list'
export const VACANCY_ARCHIVE_LIST = 'v1/vacancy/archive'
export const VACANCY_CREATE = 'v1/vacancy/create'
export const VACANCY_COLUMN = 'v1/vacancy/kanban/{id}'
export const VACANCY_DETAIL = 'v1/vacancy/info/{id}'
export const VACANCY_UPDATE = 'v1/vacancy/update/{id}'
export const VACANCY_ARCHIVE = 'v1/vacancy/archive/{id}'
export const VACANCY_CANDIDATES = 'v1/vacancy/candidates/{id}'
export const VACANCY_MOVE = 'v1/move/vacancy'
export const VACANCY_INFO = 'v1/vacancy/info/{id}'
export const VACANCY_PUBLISH = 'v1/vacancy/publish/{id}'

export const COLUMN_LIST = 'v1/vacancy/kanban'
export const COLUMN_CARDS = 'v1/vacancy/kanban/{id}'

export const CANDIDATE_TABLE_LIST = 'v1/candidate/grid'
export const CANDIDATE_BOARD_LIST = 'v1/candidate/list'
export const CANDIDATE_ARCHIVE_LIST = 'v1/candidate/archive/grid'
export const CANDIDATE_CARDS = 'v1/candidate/column/{id}'
export const CANDIDATE_INFO = 'v1/candidate/info/{id}'
export const CANDIDATE_CREATE = 'v1/candidate'
export const CANDIDATE_MOVE = 'v1/move/candidate'
export const CANDIDATE_COLUMN = 'v1/candidate/column/{id}'

export const CANDIDATE_OFFER = 'v1/candidate/offer'
export const CANDIDATE_REJECT = 'v1/candidate/reject'
export const CANDIDATE_MEETUP = 'v1/candidate/meetup'
export const CANDIDATE_ACCEPT = 'v1/candidate/accept'

export const CANDIDATE_TAGS = 'v1/candidate/tag'
export const CANDIDATE_TAG_CREATE = 'v1/candidate/tag'
export const CANDIDATE_TAG_UPDATE = 'v1/candidate/tag/{id}'
export const CANDIDATE_TAG_DELETE = 'v1/candidate/tag/{id}'

export const EMPLOYEE_LIST = 'v1/employees/list'
export const EMPLOYEE_ARCHIVE_LIST = 'v1/employees/archive/list'
export const EMPLOYEE_CREATE = 'v1/employees'
export const EMPLOYEE_INFO = 'v1/employees/{id}'
export const EMPLOYEE_UPDATE = 'v1/employees/{id}'
export const EMPLOYEE_PHOTO_POST = 'v1/employees/photo/{id}'
export const EMPLOYEE_PHOTO_DELETE = 'v1/employees/photo/{id}'
export const EMPLOYEE_ARCHIVE = 'v1/employees/archive/{id}'
export const EMPLOYEE_UN_ARCHIVE = 'v1/employees/unarchive/{id}'
export const CHECK_VERIFY_TOKEN = 'v1/check-verify-token'

export const ADMIN_LIST = 'v1/employees/get-only-admins'
export const RECRUITER_LIST = 'v1/employees/get-only-recruiters'
export const EMPLOYEE_ONLY_LIST = 'v1/employees/get-only-employees'
export const EMPLOYEE_BY_ROLE = 'v1/employees/find-filtered-employees'

export const REPORT_VACANCY_METRICS = '/v1/report/working-with-vacancies'
export const REPORT_VACANCY_METRICS_DETAIL = '/v1/report/working-with-vacancies/{id}'

export const REPORT_VACANCY_INFO = '/v1/report/vacancy-by-department'
export const REPORT_VACANCY_INFO_CHART = '/v1/report/vacancy-by-department/chart'
export const REPORT_VACANCY_INFO_DETAIL = '/v1/report/vacancy-by-department/{id}'

export const REPORT_APPLICANT_SOURCE = '/v1/report/vacancy-applies'
export const REPORT_APPLICANT_SOURCE_DETAIL = '/v1/report/vacancy-applies/{id}'
export const REPORT_APPLICANT_SOURCE_CHART = '/v1/report/vacancy-applies/chart'
export const REPORT_APPLICANT_SOURCE_CHART_ACCEPT = '/v1/report/vacancy-applies/chart-accept'
