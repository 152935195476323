import React, { useEffect } from 'react'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import { convertToHTML } from 'draft-convert'
import { useNavigate } from 'react-router-dom'
import { ChevronNextSizeL } from '../svgs/Svgs'
import { COLORS } from '../../utils/colors'
import Topic from './preview/Topic'
import BaseInfo from './preview/BaseInfo'
import Description from './preview/Description'
import Languages from './preview/Languages'
import KeySkills from './preview/KeySkills'
import Location from './preview/Location'
import MoreOptions from './preview/MoreOptions'

export default function Preview({ values, requiredFields }) {
    const navigate = useNavigate()

    useEffect(() => {
        if (!Object.values(requiredFields).every((e) => e !== '')) navigate('?mode=edit')
    }, [])

    return (
        <div>
            <div className={cn('align-start', css(s.wrapper))}>
                <div onClick={() => navigate('?mode=edit')} className={cn('flex-cent pointer', css(s.backTo))}>
                    <ChevronNextSizeL color={COLORS.dark} rotate={180} />
                </div>

                <div className={cn('flex-1', css(s.cont))}>
                    <Topic values={values} />

                    <div className={cn('is-flex direction-column', css(s.body))}>
                        <BaseInfo values={values} />

                        {values.description
                            && (typeof values.description === 'object'
                                ? convertToHTML(values.description.getCurrentContent()) !== '<p></p>'
                                : values.description !== '<p></p>'
                            )
                            && (
                                <Description values={values} />
                            )}

                        {values.languageLevel.length > 1 && (
                            <Languages values={values} />
                        )}

                        {(!!values.skills.length || !!values.knowledge.length) && (
                            <KeySkills values={values} />
                        )}

                        {!!values.location && (
                            <Location values={values} />
                        )}

                        <MoreOptions values={values} />
                    </div>
                </div>
            </div>
        </div>
    )
}

const s = StyleSheet.create({
    wrapper: {
        gap: 20,
    },
    backTo: {
        borderRadius: 64,
        border: `1px solid ${COLORS.smoothGray}`,
        background: COLORS.white,
        minWidth: 36,
        minHeight: 36,
    },
    cont: {
        background: COLORS.white,
        borderRadius: 4,
        border: `1px solid ${COLORS.smoothGray}`,
        boxShadow: '0px 2px 4px 0px rgba(130, 130, 130, 0.05)',
    },
    body: {
        gap: 32,
        padding: 24,
    },
})
