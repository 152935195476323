import React from 'react'
import { css, StyleSheet } from 'aphrodite'
import { Form, Formik } from 'formik'
import cn from 'classnames'
import { isEmpty } from 'lodash'
import { COLORS } from '../../../utils/colors'
import { MainInformation } from './MainInformation'
import { WorkingObligations } from './WorkingObligations'
import { More } from './More'
import Button from '../../common/Button'
import { ChevronNextSizeL } from '../../svgs/Svgs'
import { useGetRequest, usePostRequest } from '../../../hooks/request'
import { CANDIDATE_OFFER, CANDIDATE_TABLE_LIST } from '../../../urls'
import { useMessage } from '../../../hooks/message'
import SendOthers from './SendOthers'
import ContactInformation from './ContactInformation'
import { deepCopy } from '../../../utils/object'

export function Offer({
    id,
    name,
    after,
    onClose,
    onSuccess,
    setColumns,
    sourceColumnIndex,
    cardIndex = 0,
    destinationIndex = 0,
}) {
    const [showMessage] = useMessage()

    const getCandidates = useGetRequest(
        { url: CANDIDATE_TABLE_LIST },
    )
    const offer = usePostRequest({ url: CANDIDATE_OFFER })

    const candidates = getCandidates.response ? getCandidates.response.data.map((item) => ({
        value: item.id, label: item.fullname,
    })) : []

    const onSubmit = async (data) => {
        const { success, error } = await offer.request({ data: {
            ...data,
            after,
            candidateId: id,
            phone: data.phone ? `+998${data.phone.replace(/\s/g, '')}` : undefined,
            director: data.director.value,
            candidates: data.candidates ? data.candidates.map((item) => item.value) : undefined,
        } })

        if (success) {
            if (typeof setColumns === 'function') {
                setColumns((prevColumns) => {
                    let updatedColumns = deepCopy(prevColumns)

                    const movedCard = updatedColumns[2].cards.splice(cardIndex, 1)[0]

                    movedCard.params = {
                        status: 1,
                        salary: data.salary,
                    }

                    updatedColumns[3].cards.splice(destinationIndex, 0, movedCard)

                    updatedColumns = {
                        ...updatedColumns,
                        [sourceColumnIndex]: {
                            ...updatedColumns[sourceColumnIndex],
                            count: updatedColumns[sourceColumnIndex].count - 1,
                        },
                        3: {
                            ...updatedColumns[3],
                            count: updatedColumns[3].count + 1,
                        },
                    }

                    return updatedColumns
                })
            }

            onSuccess()
            showMessage('Успешно!', 'success-msg')
            return
        }

        showMessage(error.data.errors[0].message || error.data.message, 'error-msg')
    }

    return (
        <Formik
            onSubmit={onSubmit}
            initialValues={{
                startTime: '00:00',
                endTime: '00:00',
                schedule: '',
                salary: '',
                receiveSalary: '',
                prepayment: '',
                probation: '',
                description: '',
                holiday: '',
                sick: '',
                director: '',
                candidates: '',
                phone: '',
                email: '',
                headOfEmployee: '',
                location: '',
                remote: false,
            }}>
            {({ errors, values, setFieldValue, handleChange, handleBlur, touched }) => (
                <Form className={css(s.cont)}>
                    <div className={cn(css(s.hd), 'justify-between')}>
                        <h3>Оффер кандидату {name}</h3>

                        <div className="align-center gap-4">
                            <Button onClick={onClose} type="reset" className={css(s.cnl)}>
                                Отмена
                            </Button>

                            <Button
                                iconRight
                                type="submit"
                                strokeWidth={4}
                                loaderWidth={24}
                                loading={offer.loading}
                                loaderColor={COLORS.white}
                                icon={<ChevronNextSizeL />}
                                className={cn(css(s.btn), {
                                    [css(s.sbm)]: isEmpty(errors) && !isEmpty(touched),
                                })}>
                                Отправить
                            </Button>
                        </div>
                    </div>

                    <div className={css(s.form)}>
                        <MainInformation setFieldValue={setFieldValue} values={values} />

                        <WorkingObligations />

                        <More setFieldValue={setFieldValue} values={values} />

                        <div className="is-flex justify-center gap-7">
                            <SendOthers getCandidates={getCandidates} data={candidates} />

                            <ContactInformation handleChange={handleChange} handleBlur={handleBlur} />
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
    )
}

const s = StyleSheet.create({
    cont: {
        width: '69%',
        backgroundColor: COLORS.smoothGray,
        borderRadius: 4,
        paddingTop: 1,
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    hd: {
        backgroundColor: COLORS.white,
        padding: '20px 24px',
        borderRadius: '4px 4px 0 0',
        margin: '0 1px',
        borderBottom: `1px solid ${COLORS.lightGray}`,
        ':nth-child(1n) > :first-child': {
            fontSize: 20,
            fontWeight: '600',
            color: COLORS.dark,
        },
    },
    form: {
        padding: 24,
        display: 'flex',
        flexDirection: 'column',
        gap: 24,
        overflowY: 'auto',
    },
    cnl: {
        padding: '11px 16px',
        color: COLORS.gray,
        fontSize: 14,
        fontWeight: '500',
        backgroundColor: 'transparent',
        border: `1px solid ${COLORS.gray}`,
        borderRadius: 4,
        ':hover': {
            background: COLORS.lotion,
        },
    },
    btn: {
        padding: '10px 16px',
        color: COLORS.white,
        fontSize: 14,
        height: 40,
        boxSizing: 'border-box',
        fontWeight: '500',
        borderRadius: 4,
        background: COLORS.gray,
        cursor: 'not-allowed',
        boxShadow: '0px 4px 12px rgba(44, 67, 50, 0.1), inset 0px -1px 0px rgba(0, 0, 0, 0.25), inset 0px 1px 0px rgba(255, 255, 255, 0.1)', // eslint-disable-line
    },
    sbm: {
        cursor: 'pointer',
        boxShadow: '0px 4px 12px rgba(44, 67, 50, 0.1), inset 0px -1px 0px rgba(0, 0, 0, 0.25), inset 0px 1px 0px rgba(255, 255, 255, 0.1)', // eslint-disable-line
        background: `linear-gradient(180deg, ${COLORS.mainColor} 0%, ${COLORS.lightMainColor} 100%)`,
        ':hover': {
            background: `linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), linear-gradient(180deg, ${COLORS.mainColor} 0%, ${COLORS.lightMainColor} 100%)`, // eslint-disable-line
            boxShadow: '0px 2px 4px rgba(130, 130, 130, 0.2), inset 0px -1px 0px rgba(0, 0, 0, 0.25), inset 0px 1px 0px rgba(255, 255, 255, 0.1)', // eslint-disable-line
        },
    },
})
