import React from 'react'
import { Form, Formik } from 'formik'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import { isEmpty } from 'lodash'
import InputForm from '../../common/InputForm'
import SelectForm from '../../common/SelectForm'
import { useLoad } from '../../../hooks/request'
import { EMPLOYEE_LIST } from '../../../urls'
import { CheckSizeL } from '../../svgs/Svgs'
import { COLORS } from '../../../utils/colors'

export default function DepartmentForm({ onSubmit, onClose, loading, initialValues = {} }) {
    const loadEmployees = useLoad({ url: EMPLOYEE_LIST })
    const employees = loadEmployees.response
        ? loadEmployees.response.data.map(({ id: value, firstName, lastName, position }) => (
            { value, label: `${firstName} ${lastName}`, position }
        ))
        : []

    return (
        <Formik
            enableReinitialize
            onSubmit={onSubmit}
            initialValues={{ headOfDepartment: '', name: '', ...initialValues }}>
            {({ values, dirty }) => (
                <Form className={cn('is-flex direction-column gap-5', css(s.form))}>
                    <InputForm
                        name="name"
                        className={css(s.inp)}
                        label="Название отдела"
                        placeholder="Введите название"
                        labelClassName={css(s.label)} />

                    <SelectForm
                        loading={loadEmployees.loading}
                        options={employees}
                        name="headOfDepartment"
                        label="Начальник отдела"
                        placeholder="Выберите начальника"
                        indicatorStyle={{ paddingRight: 10 }}
                        valueContainerStyles={{ padding: '11.5px 16px' }}
                        placeholderStyles={{ color: COLORS.gray, fontFamily: 'Roboto, sans-serif' }} />

                    {/* <SelectForm */}
                    {/*     isMulti */}
                    {/*     placeholderStyles={{ color: COLORS.gray, fontFamily: 'Roboto, sans-serif' }} */}
                    {/*     valueContainerStyles={{ padding: '11.5px 16px' }} */}
                    {/*     indicatorStyle={{ paddingRight: 10 }} */}
                    {/*     label="Добавление сотрудников" */}
                    {/*     placeholder="Выберите сотрудников" */}
                    {/*     name="employees" */}
                    {/*     options={employees} /> */}

                    {/* {!isEmpty(values.employees) ? ( */}
                    {/*     <div className={css(s.lst)}> */}
                    {/*         <h3>Сотрудники</h3> */}

                    {/*         <ul className="no_dot_list is-flex direction-column gap-2"> */}
                    {/*             {values.employees.map(({ value, label, position }, ind) => ( */}
                    {/*                 <React.Fragment key={value}> */}
                    {/*                     <li className={cn('align-center gap-2', css(s.lstItem))}> */}
                    {/*                         <img className={css(s.img)} src={avatar} alt="#avatar" /> */}

                    {/*                         <div className="is-flex direction-column gap-1"> */}
                    {/*                             <h3>{label}</h3> */}
                    {/*                             <h3>{position}</h3> */}
                    {/*                         </div> */}
                    {/*                     </li> */}

                    {/*                     {ind !== values.employees.length - 1
                                                    && <hr className={css(s.stick)} />} */}
                    {/*                 </React.Fragment> */}
                    {/*             ))} */}
                    {/*         </ul> */}
                    {/*     </div> */}
                    {/* ) : null} */}

                    <div className="justify-end gap-5">
                        <button className={css(s.cancel)} onClick={onClose} type="reset">Отмена</button>

                        <button
                            disabled={isEmpty(values.name) || loading || !dirty}
                            type="submit"
                            className={cn(
                                'align-center gap-2', css(s.add),
                                { [css(s.disBtn)]: isEmpty(values.name) || loading || !dirty },
                            )}>
                            {isEmpty(initialValues) ? 'Создать' : 'Сохранить'} <CheckSizeL />
                        </button>
                    </div>
                </Form>
            )}
        </Formik>
    )
}

const s = StyleSheet.create({
    inp: {
        width: '100%',
        border: `1px solid ${COLORS.lightGray}`,
        borderRadius: 4,
        padding: '13.5px 16px',
    },
    label: {
        paddingBottom: 8,
        fontWeight: '500',
        fontSize: 12,
        color: COLORS.midGray,
    },
    form: {
        marginTop: 16,
    },
    lst: {
        background: COLORS.white,
        border: `1px solid ${COLORS.smoothGray}`,
        boxShadow: '0px 2px 4px rgba(130, 130, 130, 0.05)',
        borderRadius: 4,
        padding: 12,
        display: 'flex',
        flexDirection: 'column',
        gap: 14,
        ':nth-child(1n) > :first-child': {
            fontWeight: '500',
            fontSize: 14,
            color: COLORS.lightBlack,
        },
    },
    lstItem: {
        ':nth-child(1n) > :last-child': {
            ':nth-child(1n) > :first-child': {
                fontWeight: '500',
                fontSize: 13,
                color: COLORS.lightBlack,
            },
            ':nth-child(1n) > :last-child': {
                fontWeight: '400',
                fontSize: 11,
                color: COLORS.midGray,
            },
        },
    },
    img: {
        width: 32,
        height: 32,
        borderRadius: 64,
        boxSizing: 'border-box',
        border: `1px solid ${COLORS.lightGray}`,
    },
    stick: {
        width: '100%',
        border: `0.5px solid ${COLORS.smoothGray}`,
        margin: 0,
    },
    cancel: {
        borderRadius: 4,
        height: 32,
        padding: '0 16px',
        fontWeight: '500',
        fontSize: 14,
        border: `1px solid ${COLORS.gray}`,
        background: 'transparent',
        color: COLORS.gray,
        cursor: 'pointer',
    },
    add: {
        cursor: 'pointer',
        color: COLORS.white,
        borderRadius: 4,
        height: 32,
        padding: '0 16px',
        fontWeight: '500',
        fontSize: 14,
        border: 'none',
        background: `linear-gradient(180deg, ${COLORS.mainColor} 0%, ${COLORS.lightMainColor} 100%)`,
        boxShadow: '0px 4px 12px rgba(44, 67, 50, 0.1), inset 0px -1px 0px rgba(0, 0, 0, 0.25), inset 0px 1px 0px rgba(255, 255, 255, 0.1)', // eslint-disable-line
    },
    disBtn: {
        cursor: 'not-allowed',
        background: COLORS.gray,
        boxShadow: '0px 4px 12px rgba(44, 67, 50, 0.1), inset 0px -1px 0px rgba(0, 0, 0, 0.25), inset 0px 1px 0px rgba(255, 255, 255, 0.1)', // eslint-disable-line
    },
})
