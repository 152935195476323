import React from 'react'
import cn from 'classnames'
import { css, StyleSheet } from 'aphrodite'
import { CompanySettings, PersonalSettings, Templates } from '../svgs/Svgs'
import { COLORS } from '../../utils/colors'
import { cp } from '../../utils/checkPermission'

export default function NavigationBar({ location, navigate }) {
    return (
        <div className={cn('justify-between', css(s.wrapper))}>
            <div className={cn('align-center', css(s.types))}>
                <div
                    onClick={() => navigate('/settings/personal')}
                    datatype={location.pathname.endsWith('personal') ? 'selected' : undefined} // eslint-disable-line
                    className={css(s.tab)}>
                    <span className="align-center">
                        {location.pathname.endsWith('personal') && <PersonalSettings style={{ marginRight: 8 }} />}

                        <h3 className={cn(
                            'align-center',
                            css(s.typeTxt),
                            { [css(s.actTypeTxt)]: location.pathname.endsWith('personal') },
                        )}>
                            Личные настройки
                        </h3>
                    </span>

                    <div className={cn(
                        css(s.indicator),
                        { [css(s.actType)]: location.pathname.endsWith('personal') },
                    )} />
                </div>

                <div
                    onClick={() => navigate('/settings/company')}
                    datatype={location.pathname.endsWith('company') ? 'selected' : undefined} // eslint-disable-line
                    className={css(s.tab)}>
                    <span className="align-center">
                        {location.pathname.endsWith('company') && <CompanySettings style={{ marginRight: 8 }} />}

                        <h3 className={cn(
                            'align-center',
                            css(s.typeTxt),
                            { [css(s.actTypeTxt)]: location.pathname.endsWith('company') },
                        )}>
                            Настройки компании
                        </h3>
                    </span>

                    <div className={cn(
                        css(s.indicator),
                        { [css(s.actType)]: location.pathname.endsWith('company') },
                    )} />
                </div>

                {/* <div */}
                {/*     datatype={location.pathname.endsWith('templates') ? 'selected' : undefined}}
                {/*     className={css(s.tab)}> */}
                {/*     <span className="align-center"> */}
                {/*         {location.pathname.endsWith('templates') && <Templates style={{ marginRight: 8 }} />} */}

                {/*         <h3 */}
                {/*             onClick={() => navigate('/settings/templates')} */}
                {/*             className={cn( */}
                {/*                 'align-center', */}
                {/*                 css(s.typeTxt), */}
                {/*                 { [css(s.actTypeTxt)]: location.pathname.endsWith('templates') }, */}
                {/*             )}> */}
                {/*             Шаблоны */}
                {/*         </h3> */}
                {/*     </span> */}

                {/*     <div className={cn( */}
                {/*         css(s.indicator), */}
                {/*         { [css(s.actType)]: location.pathname.endsWith('templates') }, */}
                {/*     )} /> */}
                {/* </div> */}

                {cp('settings.companyPublic.view') && (
                    <div
                        onClick={() => navigate('/settings/company-info')}
                    datatype={location.pathname.endsWith('company-info') ? 'selected' : undefined} // eslint-disable-line
                        className={css(s.tab)}>
                        <span className="align-center">
                            {location.pathname.endsWith('company-info') && <Templates style={{ marginRight: 8 }} />}

                            <h3 className={cn(
                                'align-center',
                                css(s.typeTxt),
                                { [css(s.actTypeTxt)]: location.pathname.endsWith('company-info') },
                            )}>
                                О компании
                            </h3>
                        </span>

                        <div className={cn(
                            css(s.indicator),
                            { [css(s.actType)]: location.pathname.endsWith('company-info') },
                        )} />
                    </div>
                )}

                {/* <div */}
                {/*     datatype={location.pathname.endsWith('billing') ? 'selected' : undefined}}
                {/*     className={css(s.tab)}> */}
                {/*     <span className="align-center"> */}
                {/*         <div style={{ height: 24 }} /> */}

                {/*         <h3 */}
                {/*             onClick={() => navigate('/settings/billing')} */}
                {/*             className={cn( */}
                {/*                 'align-center', */}
                {/*                 css(s.typeTxt), */}
                {/*                 { [css(s.actTypeTxt)]: location.pathname.endsWith('billing') }, */}
                {/*             )}> */}
                {/*             Тарифный план */}
                {/*         </h3> */}
                {/*     </span> */}

                {/*     <div className={cn( */}
                {/*         css(s.indicator), */}
                {/*         { [css(s.actType)]: location.pathname.endsWith('billing') }, */}
                {/*     )} /> */}
                {/* </div> */}
            </div>
        </div>
    )
}

const s = StyleSheet.create({
    wrapper: {
        background: COLORS.white,
        borderBottom: `1px solid ${COLORS.lightGray}`,
        padding: '14px 32px 0',
        alignItems: 'flex-end',
        height: 69,
        boxSizing: 'border-box',
    },
    lst: {
        listStyle: 'none',
        fontSize: 16,
        fontWeight: '600',
        color: COLORS.gray,
        textTransform: 'uppercase',
    },
    actType: {
        backgroundColor: COLORS.dark,
    },
    actTypeTxt: {
        color: COLORS.dark,
    },
    typeTxt: {
        textTransform: 'uppercase',
        fontSize: 16,
        fontWeight: '600',
        color: COLORS.gray,
        ':nth-child(1n) > :last-child': {
            marginRight: 8,
        },
    },
    tab: {
        cursor: 'pointer',
        ':nth-child(1n) > :first-child': {
            height: 24,
        },
        ':not([datatype=selected])': {
            ':hover': {
                ':nth-child(1n) > :last-child': {
                    backgroundColor: COLORS.gray,
                },
            },
        },
    },
    indicator: {
        width: '100%',
        height: 3,
        marginTop: 16,
        borderTopLeftRadius: 12,
        borderTopRightRadius: 12,
        marginBottom: -1,
    },
    types: {
        ':nth-child(1n) > *': {
            marginRight: '2rem',
        },
    },
})
