import React, { memo } from 'react'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import { COLORS } from '../../../utils/colors'
import FormInput from '../../common/FormInput'
import { AdditionalCriteria } from './AdditionalCriteria'

function MemoizedComponent({ values, setFieldValue, innerRef }) {
    return (
        <div id="step-4" ref={innerRef} data-step={4} className={css(s.box)}>
            <div className={cn('justify-between', css(s.formName))}>
                <h3>Доп. параметры</h3>
            </div>

            <div className={cn('is-flex direction-column gap-7', css(s.cont))}>
                <AdditionalCriteria values={values} setFieldValue={setFieldValue} />

                <FormInput
                    label="Комментарий"
                    fieldClassName="flex-1"
                    placeholder="Введите комментарий"
                    name="comment"
                    labelClassName={css(s.label)}
                />
            </div>
        </div>
    )
}

export const MoreOptions = memo(MemoizedComponent)

const s = StyleSheet.create({
    box: {
        scrollMarginTop: 24,
        background: COLORS.white,
        border: `1px solid ${COLORS.smoothGray}`,
        boxShadow: '0px 2px 4px rgba(130, 130, 130, 0.05)',
        borderRadius: 4,
    },
    formName: {
        height: 'calc(3rem - 1px)',
        padding: '0 22px 0 24px',
        fontSize: 13,
        fontWeight: '600',
        color: COLORS.dark,
        borderBottom: `1px solid ${COLORS.lightGray}`,
        textTransform: 'uppercase',
        ':nth-child(1n) > :last-child': {
            ':nth-child(1n) > *': {
                ':hover': {
                    ':nth-child(1n) > *': {
                        stroke: COLORS.skyblue,
                    },
                },
            },
        },
    },
    title: {
        color: COLORS.midGray,
        fontSize: 12,
        fontWeight: '500',
    },
    cont: {
        padding: '15px 1.5rem 1.5rem',
    },
    trash: {
        width: 44,
        height: 44,
        borderRadius: 4,
        background: COLORS.smoothGray,
        boxShadow: '0px 2px 4px 0px rgba(130, 130, 130, 0.05)',
        ':hover': {
            boxShadow: '0px 2px 4px 0px rgba(130, 130, 130, 0.1)',
        },
    },
    btn: {
        height: 40,
        gap: 8,
        borderRadius: 4,
        border: '1px solid #DAF1E9',
        background: COLORS.mintCream,
        boxShadow: '0px 2px 4px 0px rgba(130, 130, 130, 0.05)',
        color: COLORS.mainColor,
        fontSize: 14,
        fontWeight: '500',
        ':hover': {
            boxShadow: '0px 2px 4px 0px rgba(130, 130, 130, 0.1)',
        },
    },
    label: {
        fontSize: 12,
        fontWeight: '500',
        color: COLORS.midGray,
        marginBottom: 7,
    },
})
