import cn from 'classnames'
import React, { useEffect, useState } from 'react'
import { css, StyleSheet } from 'aphrodite'
import { XCircleSizeL } from '../svgs/Svgs'
import { COLORS } from '../../utils/colors'

export default function Message({ text, className, closeMessage, delay = 3 }) {
    const [hiding, setHiding] = useState(false)

    useEffect(() => {
        const hidingTimeout = setTimeout(() => setHiding(true), delay * 1000)
        const hideTimeout = setTimeout(closeMessage, (delay + 1) * 1000)

        return () => {
            clearTimeout(hidingTimeout)
            clearTimeout(hideTimeout)
        }
    }, [closeMessage, delay])

    return (
        <article className={cn(
            'justify-between gap-2',
            css(styles.message), className,
            { [css(styles.hide)]: hiding },
        )}>
            <span>{text}</span>
            <XCircleSizeL className={css(styles.icon)} onClick={closeMessage} />
        </article>
    )
}

const styles = StyleSheet.create({
    message: {
        padding: '0.75rem',
        borderRadius: 4,
        fontWeight: '500',
        fontSize: 12,
        color: COLORS.white,
        boxShadow: '0px 4px 12px rgba(72, 86, 106, 0.35)',
        backdropFilter: 'blur(6px)',
        position: 'fixed',
        top: '4rem',
        minWidth: '15rem',
        right: '2rem',
        animationName: {
            from: { opacity: 0 },
            to: { opacity: 1 },
        },
        animationDuration: '1s',
        zIndex: 1000,
    },
    hide: {
        animationName: {
            from: { opacity: 1 },
            to: { opacity: 0 },
        },
        animationDuration: '1s',
    },
    icon: {
        cursor: 'pointer',
        ':nth-child(1n) > :first-child': {
            fill: 'none',
        },
        ':nth-child(1n) > :last-child': {
            fill: '#FFFFFF80',
        },
    },
})
