import React, { useRef } from 'react'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import Highlighter from 'react-highlight-words'
import { useNavigate } from 'react-router-dom'
import { ChevronNext,
    ThreeDotsMenu,
    UserX,
    Interview,
    Money,
    Users } from '../svgs/Svgs'
import { Popup } from '../Popup'
import { LoadingSkeleton } from '../common/LoadingSkeleton'
import { COLORS, tagStyleSheet } from '../../utils/colors'
import { ageRuFormat } from '../../utils/number'
import { cp } from '../../utils/checkPermission'

function Skeleton() {
    return (
        <tr className={css(s.loadItem)}>
            <td className={css(s.colItem)}>
                <div className="align-center gap-2">
                    <LoadingSkeleton height={32} width={32} borderRadius={32} />

                    <div className={cn('align-start direction-column gap-1')}>
                        <LoadingSkeleton height={15} width="10rem" />

                        <LoadingSkeleton height={13} width="8rem" />
                    </div>
                </div>
            </td>

            <td><LoadingSkeleton height={20} width="50%" /></td>

            <td><LoadingSkeleton height={20} width="50%" /></td>

            <td><LoadingSkeleton height={20} width="50%" /></td>

            <td><LoadingSkeleton height={20} width="50%" /></td>

            <td><LoadingSkeleton height={20} width="50%" /></td>

            <td className={css(s.colItem)}>
                <div className="justify-end">
                    <div className={css(s.menuItem)}>
                        <ThreeDotsMenu className="pointer" />
                    </div>
                </div>
            </td>
        </tr>
    )
}

export default function CandidateTableItem({
    item,
    keyword,
    loading,
    onClick,
    onSendOffer,
    onReject,
    onMeetup,
    onAccept,
}) {
    const popupRef = useRef()
    const navigate = useNavigate()

    const popupItems = [
        { id: 1, value: 'Добавить в сотрудники', icon: Users, onClick: () => onAccept() },
        { id: 2, value: 'Отправить оффер', icon: Money, onClick: () => onSendOffer() },
        { id: 3, value: 'Провести собеседование', icon: Interview, onClick: () => onMeetup() },
        { id: 4, value: 'Отказать', icon: UserX, onClick: () => onReject() },
    ]

    const photo = item.photo && item.photo.length ? item.photo[4] : ''

    return !loading ? (
        <tr onClick={onClick} className={css(s.item)}>
            <td className={css(s.colItem)}>
                <div className="align-center gap-2">
                    {photo ? (
                        <img src={photo} alt="#avatar" className={css(s.img)} />
                    ) : (
                        <div className={cn('flex-cent', css(s.defaultAvatar))}>
                            <h3>
                                {item.fullname ? item.fullname.split(' ').map((e) => e.slice(0, 1)).join('') : ''}
                            </h3>
                        </div>
                    )}

                    <div className="is-flex direction-column gap-1 flex-1">
                        <Highlighter
                            textToHighlight={item.fullname || ''}
                            searchWords={[keyword]}
                            className={css(s.name)}
                            highlightClassName="highlight-bg"
                        />

                        <div className="align-center gap-1">
                            <Highlighter
                                textToHighlight={item.department || ''}
                                searchWords={[keyword]}
                                highlightClassName="highlight-bg"
                            />

                            <span>•</span>

                            <Highlighter
                                textToHighlight={item.position || ''}
                                searchWords={[keyword]}
                                highlightClassName="highlight-bg"
                            />
                        </div>
                    </div>
                </div>
            </td>

            <td>
                <h3
                    onClick={(e) => {
                        navigate(`/vacancies/${item.vacancyId}/info`)
                        e.stopPropagation()
                    }}
                    className={css(s.vName)}>
                    {item.vacancyName}
                </h3>
            </td>

            <td>{item.colName || <div style={{ paddingLeft: 15 }}>&#8212;&#8212;&#8212;</div>}</td>

            <td>{ageRuFormat(item.experience) || <div style={{ paddingLeft: 15 }}>&#8212;&#8212;&#8212;</div>}</td>

            <td>{item.recruiter || <div style={{ paddingLeft: 15 }}>&#8212;&#8212;&#8212;</div>}</td>

            <td>
                {item.tagName
                    ? (
                        <div className="is-flex">
                            <div className={cn('flex-cent', css(s.tag), tagStyleSheet(item.tagColor))}>
                                {item.tagName}
                            </div>
                        </div>
                    ) : (
                        <div style={{ paddingLeft: 15 }}>&#8212;&#8212;&#8212;</div>
                    )}
            </td>

            <td className={css(s.colItem)}>
                {cp('candidates.actions') && (
                    <div className="justify-end">
                        <Popup
                            ref={popupRef}
                            items={popupItems}
                            trigger={(
                                <div className={css(s.menuItem)}>
                                    <ThreeDotsMenu className="pointer" />
                                </div>
                            )}
                            renderItem={(e) => (
                                <li
                                    key={e.id}
                                    onClick={(event) => {
                                        event.stopPropagation()
                                        e.onClick(item.id, item.fullName)
                                        popupRef.current.onClose()
                                    }}
                                    className={cn('justify-between', css(s.cardSetting))}>
                                    <div className="align-center gap-2">
                                        <e.icon color="#CCCDCD" /> <h3>{e.value}</h3>
                                    </div>

                                    {e.navigate ? <ChevronNext /> : null}
                                </li>
                            )}
                        />
                    </div>
                )}
            </td>
        </tr>
    ) : <Skeleton />
}

const s = StyleSheet.create({
    status: {
        display: 'inline-flex',
        alignItems: 'center',
        borderRadius: 4,
        padding: '4.5px 8px',
        fontSize: 11,
        fontWeight: '500',
    },
    item: {
        height: '3.75rem',
        background: COLORS.white,
        color: '#181A19',
        fontSize: 13,
        fontWeight: '500',
        wordBreak: 'break-word',
        ':nth-child(1n) > :last-child': {
            paddingRight: '1rem',
        },
    },
    name: {
        fontWeight: '500',
        fontSize: 13,
        color: COLORS.dark,
    },
    cardSetting: {
        width: '15.125rem',
        ':nth-child(1n) > :first-child': {
            ':nth-child(1n) > :first-child': {
                ':nth-child(1n) > *': {
                    // stroke: '#CCCDCD',
                },
            },
        },
    },
    menuItem: {
        width: 20,
        height: 20,
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'center',
    },
    loadItem: {
        height: '3.75rem',
        background: '#FFFFFF',
        ':nth-child(1n) > :last-child': {
            paddingRight: '1rem',
        },
    },
    colItem: {
        ':first-of-type': {
            paddingLeft: '1.5rem',
            ':nth-child(1n) > :last-child': {
                fontWeight: '400',
                fontSize: 11,
                color: '#6D7471',
            },
        },
        ':last-of-type': {
            // textAlign: 'right',
        },
    },
    tag: {
        height: 24,
        padding: '0 6px',
        borderRadius: 4,
    },
    defaultAvatar: {
        background: '#1B93C0',
        width: 32,
        height: 32,
        borderRadius: 32,
        fontWeight: '600',
        fontSize: 12,
        color: '#FFFFFF',
        textTransform: 'uppercase',
    },
    vName: {
        ':hover': {
            textDecoration: 'underline',
            color: COLORS.blue,
        },
    },
    img: {
        borderRadius: 32,
        width: 32,
        height: 32,
        boxSizing: 'border-box',
        border: `1px solid ${COLORS.lightGray}`,
    },
})
