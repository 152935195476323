import React, { useEffect } from 'react'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import { useGetRequest } from '../../hooks/request'
import { EMPLOYEE_INFO } from '../../urls'
import EmployeeInfo from './employeeInfoModal/EmployeeInfo'
import EmployeeShortInfo from './employeeInfoModal/EmployeeShortInfo'
import Modal from '../common/Modal'

export default function EmployeeDetailModal({ onClose, id, onSuccess, onArchive, openModal, isArchive }) {
    const getInfo = useGetRequest({ url: EMPLOYEE_INFO.replace('{id}', id) })
    const info = getInfo.response ? getInfo.response.data : {}

    useEffect(() => {
        if (id) getInfo.request()
    }, [id])

    const modalContentStyle = {
        width: '100%',
        margin: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '12px 0',
    }

    return (
        <Modal
            closeOnEscape
            open={openModal}
            closeOnDocumentClick
            onClose={onClose}
            overlayStyle={{ top: 52, left: '5.625rem' }}
            contentStyle={modalContentStyle}>
            <div className={cn('is-flex', css(s.modal_body))}>
                <EmployeeShortInfo onArchive={onArchive} isArchive={isArchive} info={info} onSuccess={onSuccess} />

                <EmployeeInfo info={info} onClose={onClose} />
            </div>
        </Modal>
    )
}

const s = StyleSheet.create({
    modal_body: {
        background: '#FFF',
        borderRadius: 4,
        height: '100%',
        width: '76.8%',
        overflow: 'hidden',
    },
})
