import React, { useState } from 'react'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import uuid from 'react-uuid'
import { isEmpty } from 'lodash'
import { ChevronDownS } from '../svgs/Svgs'
import { COLORS } from '../../utils/colors'
import { LanguageLevels, AvailableLanguages } from '../../utils/options'

export default function LanguageSection({ filter, setFilter }) {
    const [isCollapsed, setIsCollapsed] = useState(false)

    const languages = [
        AvailableLanguages[2], // uz
        AvailableLanguages[1], // ru
        AvailableLanguages[0], // en
    ]

    function onSelect(lang, level) {
        if (!isEmpty(filter.languages) && !isEmpty(filter.languages[lang])) {
            if (filter.languages[lang].includes(level)) {
                setFilter({
                    ...filter,
                    languages: {
                        ...filter.languages,
                        [lang]: filter.languages[lang].filter((fil) => fil !== level),
                    },
                })
            } else {
                setFilter({
                    ...filter,
                    languages: { ...filter.languages, [lang]: [...filter.languages[lang], level] },
                })
            }
        } else setFilter({ ...filter, languages: { ...filter.languages, [lang]: [level] } })
    }

    // eslint-disable-next-line max-len
    const isSelected = (lang, lvl) => filter.languages && filter.languages[lang] && filter.languages[lang].includes(lvl)

    return (
        <div className="is-flex direction-column gap-2">
            <div className="justify-between">
                <h3 className={css(s.title)}>Знание языков</h3>

                <ChevronDownS
                    color={COLORS.dark}
                    onClick={() => setIsCollapsed(!isCollapsed)}
                    className={cn('pointer dis_sl', css(isCollapsed ? s.chevronDown : s.chevronUp))} />
            </div>

            <div className={collapsableSection(isCollapsed, isCollapsed ? 0 : 142)}>
                {languages.map((lang) => (
                    <div key={lang.value} className="is-flex full-width direction-column gap-1">
                        <h3 className={css(s.langName)}>
                            {lang.ru} язык

                            {filter.languages && !isEmpty(filter.languages[lang.short]) && (
                                <span
                                    onClick={() => {
                                        const { [lang.short]: sectionFilter, ...clearedFilter } = filter.languages
                                        setFilter({ ...filter, languages: { ...clearedFilter } })
                                    }}
                                    className="dis_sl">Очистить
                                </span>
                            )}
                        </h3>

                        <div className="is-flex">
                            {LanguageLevels.map((e) => (
                                <span
                                    key={uuid()}
                                    onClick={() => onSelect(lang.short, e.level)}
                                    className={cn(
                                        'flex-cent',
                                        css(s.lvl, isSelected(lang.short, e.level) ? s.active : s.inActive),
                                    )}>
                                    {e.level}
                                </span>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

const s = StyleSheet.create({
    title: {
        fontWeight: '600',
        fontSize: 14,
        color: COLORS.dark,
    },
    langName: {
        fontWeight: '500',
        fontSize: 10,
        color: COLORS.midGray,
        whiteSpace: 'nowrap',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        ':nth-child(1n) > span': {
            cursor: 'pointer',
            fontSize: 10,
            textTransform: 'uppercase',
            color: COLORS.skyblue,
            ':hover': {
                color: `${COLORS.skyblue}CC`,
            },
        },
    },
    lvl: {
        border: '1px solid rgba(0, 0, 0, 0.15)',
        borderLeftWidth: 0,
        height: 24,
        fontWeight: '500',
        fontSize: 12,
        cursor: 'pointer',
        flexGrow: 1,
        flexBasis: 0,
        ':first-of-type': {
            borderLeft: '1px solid rgba(0, 0, 0, 0.15)',
            borderRadius: '3px 0 0 3px',
        },
        ':last-of-type': {
            borderRadius: '0 3px 3px 0',
        },
    },
    active: {
        background: 'linear-gradient(180deg, #2EA97D 0%, #138753 100%)',
        borderRightColor: 'rgba(0, 0, 0, 0.15)',
        color: '#FFFFFF',
    },
    inActive: {
        background: COLORS.white,
        color: COLORS.lightGray,
        ':hover': {
            background: COLORS.smoothGray,
            color: COLORS.gray,
        },
    },
    chevronDown: {
        transform: 'rotateZ(0deg)',
        transition: 'transform 0.3s',
    },
    chevronUp: {
        transform: 'rotateZ(180deg)',
        transition: 'transform 0.3s',
    },
})

const collapsableSection = (collapse, maxHeight) => {
    const sy = StyleSheet.create({
        collapsed: {
            maxHeight,
            transition: 'max-height 0.15s linear',
            gap: 8,
        },
        unCollapsed: {
            maxHeight,
            transition: 'max-height 0.25s linear',
            gap: 8,
        },
        main: {
            overflowY: 'hidden',
            display: 'flex',
            flexDirection: 'column',
            height: 'auto',
            gap: 8,
        },
    })

    if (collapse) return css(sy.collapsed, sy.main)
    return css(sy.collapsed, sy.main)
}
