import React from 'react'
import { useMessage } from '../../../hooks/message'
import { usePutRequest } from '../../../hooks/request'
import { POSITIONS_UPDATE } from '../../../urls'
import SidebarLayout from '../../common/SidebarLayout'
import PositionForm from './PositionForm'

export default function PositionUpdate({ onSuccess, onClose, innerRef }) {
    const { id, name } = innerRef.current
    const update = usePutRequest({ url: POSITIONS_UPDATE.replace('{id}', id) })
    const [showMessage] = useMessage()

    const onSubmit = async (data) => {
        const { success } = await update.request({ data })

        if (success) {
            showMessage('Успешно!', 'success-msg')
            onSuccess()
        }
    }

    const initialValues = { name }

    return (
        <SidebarLayout title="Редактирование должности" onClose={onClose}>
            <PositionForm
                onClose={onClose}
                onSubmit={onSubmit}
                loading={update.loading}
                initialValues={initialValues}
            />
        </SidebarLayout>
    )
}
