import React from 'react'
import cn from 'classnames'
import { css, StyleSheet } from 'aphrodite'
import { CheckSizeM } from '../../svgs/Svgs'
import Modal from '../../common/Modal'
import { useDeleteRequest } from '../../../hooks/request'
import { USER_DELETE } from '../../../urls'
import { useMessage } from '../../../hooks/message'
import { COLORS } from '../../../utils/colors'
import Button from '../../common/Button'

export default function DeleteModal({ showModal, setShowModal }) {
    const logout = useDeleteRequest({ url: USER_DELETE })
    const [showMessage] = useMessage()

    async function onDelete() {
        const { success, error } = await logout.request()

        if (success) {
            setShowModal(false)
            showMessage('Ссылка отправлен на вашу почту', 'success-msg')
            return
        }

        showMessage(error.data.message || error.data.errors[0].message, 'error-msg')
    }

    return (
        <Modal
            open={showModal}
            onClose={() => setShowModal(false)}
            closeOnDocumentClick
            closeOnEscape>
            <div className={cn('is-flex direction-column', css(s.exitModal))}>
                <div className={css(s.modalTxt)}>
                    <h3>Вы уверены, что хотите удалить аккаунт?</h3>

                    <p className={css(s.delInf)}>
                        На ваш адрес электронной почты будет отправлено {' '}
                        письмо для подтверждения <br /> удаления профиля
                    </p>
                </div>

                <div className={cn('justify-end', css(s.btnGr))}>
                    <button
                        className={css(s.cancelBtn)}
                        onClick={() => setShowModal(false)}>
                        Отмена
                    </button>

                    <Button
                        iconRight
                        loaderWidth={24}
                        onClick={() => onDelete()}
                        loading={logout.loading}
                        loaderColor={COLORS.white}
                        strokeWidth={4}
                        icon={<CheckSizeM color={COLORS.white} />}
                        className={cn('flex-cent', css(s.delBtn))}
                    >
                        Подтвердить удаление
                    </Button>
                </div>
            </div>
        </Modal>
    )
}

const s = StyleSheet.create({
    exitModal: {
        background: COLORS.white,
        padding: '20px 24px',
        borderRadius: 4,
    },
    modalTxt: {
        color: COLORS.dark,
        fontSize: 20,
        fontWeight: '500',
        paddingBottom: 20,
        marginBottom: 20,
        borderBottom: `1px solid ${COLORS.lightGray}`,
        paddingRight: '3.5rem',
        ':nth-child(1n) > :last-child': {
            marginTop: 8,
        },
    },
    btnGr: {
        gap: 16,
    },
    cancelBtn: {
        fontWeight: '500',
        fontSize: 14,
        color: COLORS.gray,
        padding: '11px 16px',
        border: `1px solid ${COLORS.gray}`,
        borderRadius: 4,
        background: 'transparent',
        cursor: 'pointer',
        outline: 'none',
    },
    delInf: {
        fontWeight: '400',
        color: COLORS.lightBlack,
        fontSize: 14,
    },
    delBtn: {
        gap: 8,
        cursor: 'pointer',
        outline: 'none',
        borderRadius: 4,
        width: 'auto',
        background: COLORS.lightRed,
        color: COLORS.white,
        border: 'none',
        padding: '9px 16px',
        ':hover': {
            background: `linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), ${COLORS.lightRed}`,
            boxShadow: '0px 2px 4px rgba(168, 168, 168, 0.15), inset 0px -1px 0px rgba(0, 0, 0, 0.25), inset 0px 1px 0px rgba(255, 255, 255, 0.1)', // eslint-disable-line
        },
    },
})
