import React, { memo, useRef } from 'react'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import { useLocation, useNavigate } from 'react-router-dom'
import { DisplayByColumn,
    DisplayByRow,
    SettingsLayout,
    EditPencil,
    PlusRounded,
    ChevronDownS,
    CheckSizeL,
    List,
    Archive,
    DefaultLocation } from '../svgs/Svgs'
import { useSidebar } from '../../hooks/sidebar'
import { COLORS } from '../../utils/colors'
import { FilterSwitcher } from '../filter/FilterSwitcher'
import VacanciesSidebar from './VacanciesSidebar'
import { cp } from '../../utils/checkPermission'
import { Popup } from '../Popup'

function MemoizedHeader({ setVacancyFilter, vacancyFilter, count, view, vacancyId, vacancyName }) {
    const popupRef = useRef()
    const location = useLocation()
    const navigate = useNavigate()
    const [showSettingsBar, hideSettingsBar] = useSidebar(
        <VacanciesSidebar onClose={() => hideSettingsBar()} />,
    )

    const popupItems = [
        { id: 1, label: 'Активные вакансии', icon: List, types: ['board', 'table'] },
        { id: 2, label: 'Архив вакансий', icon: Archive, types: ['archive'] },
    ]
    const archiveView = view === 'archive'
    const isBoard = view === 'board'

    return (
        <div className={cn(css(s.hd), 'justify-between-start gap-6')}>
            <div className="align-center">
                {vacancyName ? (
                    <div className="align-center gap-3">
                        <h3 className={css(s.title)}>
                            {vacancyName}
                        </h3>

                        <span className={css(s.dot)}>•</span>

                        <h3 className={css(s.title)}>{count}</h3>
                    </div>
                ) : (
                    <div className="align-center gap-5">
                        <div className="align-center gap-3">
                            <div className="align-center gap-3">
                                <div className={cn('flex-cent', css(s.defaultCompanyLogo))}>
                                    <DefaultLocation color={COLORS.white} />
                                </div>

                                <h3 className={css(s.projectInfo)}>
                                    Вакансии
                                </h3>
                            </div>

                            <span className={css(s.dot)}>•</span>

                            <h3 className={css(s.projectInfo)}>{count}</h3>
                        </div>

                        <Popup
                            ref={popupRef}
                            trigger={(
                                <div className={cn(css(s.dTrigger), 'flex-cent gap-1')}>
                                    <h3>
                                        Показать: {' '}

                                        <span className={css(s.fil)}>
                                            {popupItems[archiveView ? 1 : 0].label}
                                        </span>
                                    </h3>

                                    <ChevronDownS />
                                </div>
                            )}
                            items={popupItems}
                            renderItem={(e) => (
                                <li
                                    key={e.id}
                                    onClick={() => {
                                        navigate(`?view=${e.types[0]}`)
                                        popupRef.current.onClose()
                                        setVacancyFilter({ act: false })
                                    }}
                                    className={cn(
                                        'justify-between',
                                        css(s.cardSetting),
                                    )}>
                                    <div className="align-center gap-2">
                                        <e.icon color={!e.types.includes(view) ? '#CCCDCD' : COLORS.white} />

                                        <h3 className={cn(
                                            css(s.popupItemLabel), { [css(s.slLabel)]: e.types.includes(view) },
                                        )}>
                                            {e.label}
                                        </h3>
                                    </div>

                                    {e.types.includes(view) && <CheckSizeL />}
                                </li>
                            )}
                        />
                    </div>
                )}

                {/* <ShowReportsPopup /> */}
            </div>

            <div className="align-center gap-5">
                {!archiveView && (
                    <>
                        {location.pathname.endsWith('/info') ? (
                            <div className="align-center gap-2">
                                {cp('candidates.create') && (
                                    <button
                                        onClick={() => {
                                            navigate(`/candidates/${vacancyId}/form/create`, { state: { vacancyName } })
                                        }}
                                        className={cn('align-center gap-2', css(s.add))}>
                                        <PlusRounded /> Добавить кандидата
                                    </button>
                                )}

                                <button
                                    onClick={() => navigate(`/vacancies/${vacancyId}/form/update?mode=edit`)}
                                    className={cn('align-center gap-2', css(s.add))}>
                                    <EditPencil /> Изменить вакансию
                                </button>
                            </div>
                        ) : (
                            <button
                                onClick={() => navigate('/vacancies/form/create?mode=edit')}
                                className={cn('align-center gap-2', css(s.add))}>
                                <PlusRounded /> Создать вакансию
                            </button>
                        )}

                        {!location.pathname.endsWith('/info') ? (
                            <div className={css(s.dpl)}>
                                <span
                                    datatype={isBoard ? 'selected' : undefined} // eslint-disable-line react/no-unknown-property, max-len
                                    onClick={() => navigate('?view=board')}
                                    className={cn(css(s.col), { [css(s.actCol)]: isBoard })}>
                                    <DisplayByRow />
                                </span>

                                <span
                                    datatype={!isBoard ? 'selected' : undefined} // eslint-disable-line react/no-unknown-property, max-len
                                    onClick={() => navigate('?view=table')}
                                    className={cn(css(s.col), { [css(s.actCol)]: !isBoard })}>
                                    <DisplayByColumn />
                                </span>
                            </div>
                        ) : null}

                        {!location.pathname.endsWith('/info')
                    && <FilterSwitcher setFilter={setVacancyFilter} filter={vacancyFilter} />}

                        {/* <SearchInput withTrigger /> */}
                    </>
                ) }

                <span className={css(s.panItem)} onClick={() => showSettingsBar()}><SettingsLayout /></span>
            </div>
        </div>
    )
}

export const Header = memo(MemoizedHeader)

const s = StyleSheet.create({
    hd: {
        margin: '16px 24px 14px',
        ':nth-child(1n) > :last-child': {
            flex: 'none',
        },
    },
    title: {
        fontWeight: '700',
        fontSize: 20,
        color: COLORS.dark,
        overflowWrap: 'anywhere',
    },
    dot: {
        color: COLORS.gray,
    },
    panItem: {
        cursor: 'pointer',
        display: 'inline-flex',
        padding: 6,
        background: COLORS.white,
        borderRadius: 4,
        border: '1px solid',
        borderColor: COLORS.smoothGray,
        ':hover': {
            borderColor: COLORS.lightGray,
            boxShadow: '0px 2px 4px rgba(168, 168, 168, 0.15)',
        },
        ':last-of-type': {
            ':nth-child(1n) > :first-child': {
                ':nth-child(1n) > *': {
                    stroke: COLORS.midGray,
                },
            },

        },
    },
    dpl: {
        borderRadius: 5,
        ':nth-child(1n) > *': {
            padding: 8,
            display: 'inline-flex',
        },
        ':nth-child(1n) > :first-child': {
            borderTopLeftRadius: 4,
            borderBottomLeftRadius: 4,
        },
        ':nth-child(1n) > :last-child': {
            borderTopRightRadius: 4,
            borderBottomRightRadius: 4,
        },
    },
    col: {
        background: '#fff',
        border: '1px solid',
        borderColor: 'transparent',
        boxSizing: 'border-box',
        ':first-of-type': {
            borderRight: 'none',
        },
        ':last-of-type': {
            borderLeft: 'none',
        },
        ':not([datatype=selected])': {
            borderColor: COLORS.smoothGray,
            cursor: 'pointer',
            ':hover': {
                borderColor: COLORS.lightGray,
            },
        },
        ':nth-child(1n) > :first-child': {
            ':nth-child(1n) > :first-child': {
                fill: '#92929D',
            },
        },
    },
    add: {
        height: 32,
        padding: '0 12px',
        background: COLORS.blue,
        boxShadow: '0px 4px 12px rgba(44, 67, 50, 0.1), inset 0px -1px 0px rgba(0, 0, 0, 0.25), inset 0px 1px 0px rgba(255, 255, 255, 0.1)', // eslint-disable-line
        borderRadius: 4,
        color: COLORS.white,
        border: 'none',
        outline: 'none',
        fontSize: 13,
        fontWeight: '500',
        cursor: 'pointer',
        ':hover': {
            background: `linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), ${COLORS.blue}`,
            boxShadow: '0px 2px 4px rgba(130, 130, 130, 0.2), inset 0px -1px 0px rgba(0, 0, 0, 0.25), inset 0px 1px 0px rgba(255, 255, 255, 0.1)', // eslint-disable-line
        },
        ':nth-child(1n) > :first-child': {
            ':nth-child(1n) > *': {
                stroke: COLORS.white,
            },
        },
    },
    actCol: {
        background: COLORS.mainColor,
        ':nth-child(1n) > :first-child': {
            ':nth-child(1n) > :first-child': {
                fill: '#FFF',
            },
        },
    },
    dTrigger: {
        border: `1px solid ${COLORS.lightGray}`,
        borderRadius: 4,
        height: 32,
        padding: '0 12px',
        cursor: 'pointer',
        background: COLORS.white,
        color: COLORS.midGray,
        fontSize: 14,
        fontWeight: '500',
    },
    cardSetting: {
        width: '15.25rem',
    },
    popupItemLabel: {
        fontWeight: '500',
        fontSize: 12,
        color: COLORS.lightGray,
    },
    popupItemIcon: {
        ':nth-child(1n) > *': {
            stroke: '#CCCDCD',
        },
    },
    slIcon: {
        ':nth-child(1n) > *': {
            stroke: COLORS.white,
        },
    },
    slLabel: {
        color: COLORS.white,
    },
    fil: {
        color: '#181A19',
        fontWeight: '600',
        fontSize: 14,
    },
    projectInfo: {
        color: COLORS.dark,
        fontSize: 20,
        fontWeight: '700',
    },
    defaultCompanyLogo: {
        height: 36,
        width: 36,
        borderRadius: 4,
        boxSizing: 'border-box',
        border: `1px solid ${COLORS.lightGray}`,
        background: COLORS.mainColor,
    },
})
