import React from 'react'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import { isEmpty } from 'lodash'
import ExperienceInfo from './ExperienceInfo'
import { COLORS } from '../../utils/colors'

export default function CandidateExperience({ info }) {
    return (
        <div className={cn('is-flex direction-column gap-7', css(s.cont))}>
            {!isEmpty(info.experience) ? (
                <ExperienceInfo experience={info.experience} />
            ) : (
                <div className="flex-cent flex-1">
                    <h3 style={{ color: COLORS.midGray, fontWeight: '400', fontSize: 22 }}>Пусто</h3>
                </div>
            )}
        </div>
    )
}

const s = StyleSheet.create({
    cont: {
        padding: '16px 24px',
        overflowY: 'auto',
        height: '100%',
    },
})
