import React, { memo } from 'react'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import { COLORS } from '../../../utils/colors'
import { Knowledge } from './Knowledge'
import { Skills } from './Skills'
import { Languages } from './Languages'

function MemoizedComponent({ values, setFieldValue, innerRef }) {
    return (
        <div id="step-3" ref={innerRef} data-step={3} className={css(s.box1)}>
            <div className={cn('justify-between', css(s.formName))}>
                <h3>навыки и языки</h3>
            </div>

            <div className={cn('is-flex direction-column gap-7', css(s.cont))}>
                <Knowledge values={values} setFieldValue={setFieldValue} />

                <Skills values={values} setFieldValue={setFieldValue} />

                <Languages values={values} setFieldValue={setFieldValue} />
            </div>
        </div>
    )
}

export const Requirements = memo(MemoizedComponent)

const s = StyleSheet.create({
    box1: {
        scrollMarginTop: 180,
        background: COLORS.white,
        border: `1px solid ${COLORS.smoothGray}`,
        boxShadow: '0px 2px 4px rgba(130, 130, 130, 0.05)',
        borderRadius: 4,
    },
    formName: {
        height: 'calc(3rem - 1px)',
        padding: '0 22px 0 24px',
        fontSize: 13,
        fontWeight: '600',
        color: COLORS.dark,
        borderBottom: `1px solid ${COLORS.lightGray}`,
        textTransform: 'uppercase',
        ':nth-child(1n) > :last-child': {
            ':nth-child(1n) > *': {
                ':hover': {
                    ':nth-child(1n) > *': {
                        stroke: COLORS.skyblue,
                    },
                },
            },
        },
    },
    cont: {
        padding: '15px 1.5rem 1.5rem',
    },
})
