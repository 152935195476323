import React from 'react'
import { css, StyleSheet } from 'aphrodite'
import { useLocation, useNavigate } from 'react-router-dom'
import Layout from '../components/common/Layout'
import NavigationBar from '../components/settings/NavigationBar'
import PersonalSettings from '../components/settings/PersonalSettings'
import CompanySettings from '../components/settings/company/CompanySettings'
import PublicView from '../components/settings/companyPublic/PublicView'

export default function Settings() {
    const location = useLocation()
    const navigate = useNavigate()

    return (
        <Layout padded={false} className={css(s.ly)}>
            <NavigationBar location={location} navigate={navigate} />

            {location.pathname.endsWith('personal') && <PersonalSettings />}

            {location.pathname.endsWith('company') && <CompanySettings />}

            {location.pathname.endsWith('company-info') && <PublicView />}
        </Layout>
    )
}

const s = StyleSheet.create({
    ly: {
        width: '88.4%',
        scrollbarGutter: 'auto',
    },
})
