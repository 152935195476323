import React from 'react'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import { Check, CheckSizeS } from '../svgs/Svgs'
import { COLORS } from '../../utils/colors'

export default function Checkbox({
    name,
    label,
    onClick,
    isLarge,
    checked,
    className,
    setChecked,
    stopPropagation = false,
}) {
    return (
        <div onClick={(e) => {
            if (stopPropagation) e.stopPropagation()
            if (typeof onClick === 'function') {
                onClick()
                return
            }
            setChecked(!checked)
        }} className="align-center pointer">
            <span className={cn(
                'checkbox',
                css(s.box),
                className,
                { [css(s.chBox)]: checked, [css(s.chBoxLg)]: isLarge },
            )}>
                {checked ? (
                    isLarge ? <CheckSizeS className={css(s.icon)} /> : <Check />
                ) : null}
            </span>

            {label ? (
                <label className="pointer" htmlFor={name}>
                    {label}
                </label>
            ) : null}
        </div>
    )
}

const s = StyleSheet.create({
    box: {
        borderRadius: 4,
        display: 'flex',
        width: 14,
        height: 14,
        justifyContent: 'center',
        alignItems: 'center',
    },
    chBoxLg: {
        width: '1.125rem',
        height: '1.125rem',
    },
    chBox: {
        borderColor: COLORS.mainColor,
    },
    icon: {
        ':nth-child(1n) > *': {
            fill: COLORS.mainColor,
        },
    },
})
