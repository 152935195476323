import React from 'react'
import { css, StyleSheet } from 'aphrodite'
import { Integration } from '../../svgs/Svgs'
import { COLORS } from '../../../utils/colors'
import { ServiceHeadHunter } from './IntegrationServices'

export default function Integrations() {
    return (
        <div className={css(s.form)}>
            <div className={css(s.formName)}>
                <Integration />

                <h3>Интеграции</h3>
            </div>

            <div className={css(s.wrap)}>
                <ServiceHeadHunter />

                {/* <ServiceTelegram /> */}
            </div>
        </div>
    )
}

const s = StyleSheet.create({
    form: {
        backgroundColor: '#fff',
        borderRadius: 4,
    },
    formName: {
        height: 'calc(3rem - 1px)',
        padding: '0 24px',
        display: 'flex',
        alignItems: 'center',
        fontSize: 13,
        fontWeight: '600',
        color: COLORS.dark,
        borderBottom: `1px solid ${COLORS.lightGray}`,
        textTransform: 'uppercase',
        ':nth-child(1n) > :first-child': {
            marginRight: 8,
        },
    },
    wrap: {
        padding: '9px 24px 24px',
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gap: 24,
    },
})
