import React, { useEffect, useRef } from 'react'
import cn from 'classnames'
import { css, StyleSheet } from 'aphrodite'
import Button from '../../common/Button'
import { AddPhoto, Trash } from '../../svgs/Svgs'
import { EmptyCompanyLogo } from '../../svgs/SvgPictures'
import { COLORS } from '../../../utils/colors'

export default function CompanyImage({ info, setFieldValue, img, setImg, setTouched, touched, disabled }) {
    const inputRef = useRef(null)

    useEffect(() => {
        if (info.logo && info.logo[1]) setImg(info.logo && info.logo[1])
    }, [info])

    const onChange = (e, setField) => {
        setField('logo', e.target.files[0])
        setTouched({ ...touched, logo: true })
        if (e.target.files[0]) setImg(URL.createObjectURL(e.target.files[0]))
    }

    return (
        <div className={cn('align-center', css(s.loadPhCont))}>
            {img ? (
                <React.Fragment>
                    <img
                        src={img}
                        alt="#avatar"
                        className={css(s.avatarImg)} />

                    {!disabled && (
                        <div className={cn('align-center', css(s.btn_group))}>
                            <Button type="button" onClick={() => inputRef.current.click()}>
                                Загрузить фотографию
                            </Button>

                            <Button
                                onClick={() => {
                                    setFieldValue('logo', '')
                                    inputRef.current.value = ''
                                    setTouched({ ...touched, logo: true })
                                    setImg('')
                                }}>
                                <Trash /> Удалить фотографию
                            </Button>
                        </div>
                    )}
                </React.Fragment>
            ) : (
                <React.Fragment>
                    <EmptyCompanyLogo size={98} />

                    {!disabled && (
                        <div
                            onClick={() => inputRef.current.click()}
                            className={cn('align-center pointer', css(s.loadPh))}>
                            <AddPhoto />

                            <h3>Загрузить логотип</h3>
                        </div>
                    )}
                </React.Fragment>
            )}

            <input
                id="file"
                type="file"
                readOnly="readonly"
                accept="image/png, image/jpg"
                ref={inputRef}
                className="not-visible"
                onChange={(e) => onChange(e, setFieldValue)} />
        </div>
    )
}

const s = StyleSheet.create({
    avatarImg: {
        width: 96,
        height: 96,
        border: `1px solid ${COLORS.lightGray}`,
        borderRadius: 4,
    },
    btn_group: {
        gap: 24,
        ':nth-child(1n) > :first-child': {
            padding: '12px 16px',
            color: COLORS.mainColor,
            fontSize: 14,
            fontWeight: '500',
            border: `1px solid ${COLORS.smoothGray}`,
            background: COLORS.mintCream,
            boxShadow: '0 4px 20px rgba(44, 67, 50, 0.05)',
            borderRadius: 4,
            height: 38,
        },
        ':nth-child(1n) > :last-child': {
            padding: '12px 15px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            border: `1px solid ${COLORS.gray}`,
            background: 'transparent',
            filter: 'drop-shadow(0px 4px 12px rgba(44, 67, 50, 0.1))',
            borderRadius: 4,
            color: COLORS.gray,
            fontSize: 13,
            fontWeight: '500',
            height: 38,
            ':nth-child(1n) > :first-child': {
                marginRight: 8,
            },
        },
    },
    loadPhCont: {
        gap: 32,
    },
    loadPh: {
        background: COLORS.mintCream,
        border: '1px solid #E3F8EE',
        boxShadow: '0px 2px 20px rgba(137, 152, 141, 0.02)',
        borderRadius: 4,
        padding: '10px 16px',
        fontWeight: '500',
        fontSize: 14,
        color: COLORS.mainColor,
        gap: 8,
        ':hover': {
            boxShadow: '0px 2px 4px rgba(168, 168, 168, 0.15)',
            border: '1px solid rgba(46, 169, 125, 0.5)',
        },
    },
})
