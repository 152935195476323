import React from 'react'
import cn from 'classnames'
import { css, StyleSheet } from 'aphrodite'
import moment from 'moment'
import { BachelorHat,
    Briefcase,
    BuildingSizeM,
    DeviceLaptop,
    HourGlass,
    MapPin,
    WalletSizeM } from '../../svgs/Svgs'
import { COLORS } from '../../../utils/colors'
import { fromToStr } from '../../../utils/string'

export default function BaseInfo({ values }) {
    const { experience, salary, location, education, format, schedule, deadline } = values

    const baseInfo = [
        {
            id: 1,
            title: 'Опыт',
            // eslint-disable-next-line max-len
            value: experience.label || '',
            icon: Briefcase,
        },
        {
            id: 2,
            title: 'Зарплата',
            // eslint-disable-next-line max-len
            value: fromToStr(salary.from, salary.to, salary.currency ? salary.currency.label : '', 'По итогам собеседование'),
            icon: WalletSizeM,
        },
        { id: 3, title: 'Локация работы', value: location ? location.label : null, icon: MapPin },
        { id: 4, title: 'Образование', value: education ? education.label : null, icon: BachelorHat },
        { id: 5, title: 'Формат работы', value: format.label, icon: DeviceLaptop },
        { id: 6, title: 'График работы', value: schedule.label, icon: BuildingSizeM },
        { id: 7, title: 'Дедлайн', value: deadline ? moment(deadline).format('DD MMMM YYYY') : '', icon: HourGlass },
    ].filter((e) => e.value)

    return !!baseInfo.length && (
        <div className="align-center gap-5 is-flex-wrap">
            {baseInfo.map((info) => (
                <div key={info.id} className={cn('align-center gap-2', css(s.card))}>
                    <info.icon color={COLORS.mainColor} />

                    <div className="is-flex direction-column gap-1">
                        <h3 className={css(s.title)}>{info.title}</h3>

                        <h3 className={css(s.value)}>{info.value}</h3>
                    </div>
                </div>
            ))}
        </div>
    )
}
const s = StyleSheet.create({
    card: {
        padding: '6px 10px',
        borderRadius: 4,
        border: `1px solid ${COLORS.smoothGray}`,
        background: COLORS.white,
        boxShadow: '0px 2px 4px 0px rgba(130, 130, 130, 0.05)',
    },
    title: {
        color: COLORS.gray,
        fontSize: 12,
        fontWeight: '500',
    },
    value: {
        color: COLORS.dark,
        fontSize: 13,
        fontWeight: 600,
    },
})
