import React, { forwardRef, useImperativeHandle, useState } from 'react'
import cn from 'classnames'
import { css, StyleSheet } from 'aphrodite'
import { ArrowForward } from '../svgs/Svgs'
import Modal from '../common/Modal'
import { COLORS } from '../../utils/colors'
import { useMessage } from '../../hooks/message'
import { usePutRequest } from '../../hooks/request'
import { SET_ROLE } from '../../urls'

export const RemoveRole = forwardRef((_, ref) => {
    const setRole = usePutRequest({ url: SET_ROLE })
    const [modal, setModal] = useState({ act: false })
    const [showMessage] = useMessage()

    useImperativeHandle(ref, () => ({
        openModal(id, name, request) { setModal({ act: true, id, name, request }) },
    }), [])

    const handleRemove = () => {
        setRole.request({ data: {
            employeeIds: [modal.id],
            role: 4,
        } }).then(() => {
            showMessage('Успешно!', 'success-msg')
            modal.request()
            setModal({ act: false })
        }).catch((err) => showMessage(err.response.data && err.response.data.errors[0].message, 'success-msg'))
    }

    return (
        <Modal
            open={modal.act}
            onClose={() => setModal({ act: false })}
            closeOnDocumentClick
            closeOnEscape>
            <div className={cn('is-flex direction-column', css(s.exitModal))}>
                <div className={cn(css(s.modalTxt), css(s.extModal))}>
                    <div>
                        <h3>
                            Вы уверены, что хотите убрать роль у <br />
                            <span style={{ fontWeight: 600 }}>{modal.name}</span>
                        </h3>
                    </div>
                </div>

                <div className={cn('justify-end', css(s.btnGr))}>
                    <button
                        className={css(s.cancelBtn)}
                        onClick={() => setModal({ act: false })}>
                        Отмена
                    </button>

                    <button
                        onClick={handleRemove}
                        className={cn('flex-cent', css(s.exitBtn))}>
                        <ArrowForward color={COLORS.white} size={20} /> Убрать
                    </button>
                </div>
            </div>
        </Modal>
    )
})

const s = StyleSheet.create({
    exitBtn: {
        cursor: 'pointer',
        height: 40,
        border: 'none',
        borderRadius: 4,
        color: COLORS.white,
        padding: '8px 0',
        width: '8.5rem',
        gap: 8,
        outline: 'none',
        background: 'linear-gradient(180deg, #2EA97D 0%, #138753 100%)',
    },
    exitModal: {
        background: COLORS.white,
        padding: '20px 24px',
        borderRadius: 4,
    },
    modalTxt: {
        color: COLORS.lightBlack,
        fontSize: 20,
        fontWeight: '500',
        paddingBottom: 20,
        marginBottom: 20,
        borderBottom: `1px solid ${COLORS.lightGray}`,
    },
    btnGr: {
        gap: 16,
    },
    cancelBtn: {
        fontWeight: '500',
        fontSize: 14,
        color: COLORS.gray,
        padding: '11px 16px',
        border: `1px solid ${COLORS.gray}`,
        borderRadius: 4,
        background: 'transparent',
        cursor: 'pointer',
        outline: 'none',
    },
    extModal: {
        paddingRight: '8.25rem',
        whiteSpace: 'nowrap',
    },
})
