import { useLayoutEffect } from 'react'
import { useParams } from 'react-router-dom'

export function AutoLogger() {
    const { token } = useParams()

    useLayoutEffect(() => {
        if (token) {
            localStorage.clear()
            localStorage.setItem('token', JSON.stringify(token))
            localStorage.setItem('user', JSON.stringify({ companyId: 'autologin' }))
            return window.location.replace('/dashboard')
        }

        return window.location.replace('/auth/recruiter')
    }, [])

    return null
}
