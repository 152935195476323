import React, { useState } from 'react'
import cn from 'classnames'
import { css, StyleSheet } from 'aphrodite'
import { Draggable } from 'react-beautiful-dnd'
import CandidateDetail from '../CandidateDetail'
import { Offer } from '../offer/Offer'
import { Meetup } from '../meetup/Meetup'
import { Accept } from '../accept/Accept'
import { Reject } from '../reject/Reject'
import { cp } from '../../../utils/checkPermission'
import Modal from '../../common/Modal'
import CardNew from './CardNew'
import CardInProgress from './CardInProgress'
import CardReject from './CardReject'
import CardAccept from './CardAccept'
import CardOffer from './CardOffer'
import CardMeetup from './CardMeetup'

const Cards = {
    0: CardNew,
    1: CardInProgress,
    2: CardMeetup,
    3: CardOffer,
    4: CardAccept,
    5: CardReject,
}

export function MainCard({ data, cardIndex, columnIndex, getData, setColumns }) {
    const [modal, setModal] = useState({ act: false, type: 'detail' })

    const contentStyle = {
        width: '100%',
        margin: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '12px 0',
    }

    const CardComponent = Cards[columnIndex]

    return (
        <React.Fragment>
            <Draggable
                key={data.id}
                index={cardIndex}
                draggableId={data.id.toString()}
                isDragDisabled={!cp('candidates.drag_and_drop')}
            >
                {(provided, snapshot) => (
                    <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={{ ...provided.draggableProps.style }}
                        className={cn(css(s.cont))}
                    >
                        <CardComponent
                            data={data}
                            index={cardIndex}
                            setModal={setModal}
                            setColumns={setColumns}
                            isDragging={snapshot.isDragging}
                        />
                    </div>
                )}
            </Draggable>

            <Modal
                closeOnEscape
                open={modal.act}
                closeOnDocumentClick
                onClose={() => setModal({ act: false })}
                overlayStyle={{ top: 52, left: '5.625rem' }}
                contentStyle={contentStyle}>
                {modal.type === 'detail' && (
                    <CandidateDetail
                        id={modal.id}
                        setModal={setModal}
                        onClose={() => setModal({ act: false })} />
                )}

                {modal.type === 'offer' && (
                    <Offer
                        id={modal.id}
                        name={modal.name}
                        cardIndex={cardIndex}
                        setColumns={setColumns}
                        sourceColumnIndex={columnIndex}
                        onClose={() => setModal({ act: false })}
                        onSuccess={() => setModal({ act: false })}
                    />
                )}

                {modal.type === 'meetup' && (
                    <Meetup
                        afterId={null}
                        id={modal.id}
                        name={modal.name}
                        sourceColumnIndex={columnIndex}
                        cardIndex={cardIndex}
                        setColumns={setColumns}
                        onSuccess={() => {
                            setModal({ act: false })
                        }}
                        onClose={() => setModal({ act: false })} />
                )}

                {modal.type === 'accept' && (
                    <Accept
                        id={modal.id}
                        email={modal.email}
                        name={modal.name}
                        cardIndex={cardIndex}
                        sourceColumnIndex={columnIndex}
                        setColumns={setColumns}
                        onSuccess={() => {
                            setModal({ act: false })
                        }}
                        onClose={() => setModal({ act: false })} />
                )}

                {modal.type === 'reject' && (
                    <Reject
                        id={modal.id}
                        name={modal.name}
                        sourceColumnIndex={columnIndex}
                        cardIndex={cardIndex}
                        setColumns={setColumns}
                        onSuccess={() => {
                            getData.request()
                            setModal({ act: false })
                        }}
                        onClose={() => setModal({ act: false })} />
                )}
            </Modal>
        </React.Fragment>
    )
}

const s = StyleSheet.create({
    bioCont: {
        width: 272,
    },
    jbInf: {
        gap: 40,
    },
    cont: {
        margin: '0 6px 12px 12px',
    },
})
