import React from 'react'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import { isEmpty, range } from 'lodash'
import { ChevronNext } from '../svgs/Svgs'
import { useInfiniteScroll } from '../../hooks/request'
import { VACANCY_LIST } from '../../urls'
import { LoadingSkeleton } from '../common/LoadingSkeleton'
import { COLORS } from '../../utils/colors'

function Skeleton() {
    return (
        <span className={cn('align-center', css(s.loadingItem))}>
            <LoadingSkeleton height={16} width={100} />
        </span>
    )
}

function VacanciesListItems({ setSelectedVacancy, selectedVacancy, archiveMode }) {
    const loadVacancies = useInfiniteScroll({
        url: VACANCY_LIST,
        params: { 'per-page': 20, archiveExists: archiveMode },
    }, [])

    const vacancies = loadVacancies.response ? loadVacancies.response.data : []

    const loadingCount = isEmpty(vacancies) ? 20 : 5

    return (
        <>
            {vacancies.map((item) => (
                <div
                    onClick={() => setSelectedVacancy(item.id)}
                    key={item.id}
                    className={cn('flex-cent justify-between direction-column pointer', css(s.vacItem))}>
                    <div className={cn(
                        'flex-cent', css(s.item), { [css(s.actItem)]: selectedVacancy === item.id },
                    )}>
                        {item.name}
                    </div>

                    <div className={cn(css(s.btm), { [css(s.actBtm)]: selectedVacancy === item.id })} />
                </div>
            ))}

            {!loadVacancies.loading ? <div ref={loadVacancies.ref} /> : range(0, loadingCount).map((key) => (
                <Skeleton key={key} />
            ))}
        </>
    )
}

export default function VacanciesList({ setSelectedVacancy, selectedVacancy, archiveMode }) {
    const listNode = document.getElementById('lst-rep')
    const hasTrigger = listNode && listNode.scrollWidth > listNode.clientWidth

    return (
        <div className={css(s.wrp)}>
            <div className={cn('align-center', css(s.lstBx))}>
                <div id="lst-rep" className={css(s.lst, hasTrigger && s.paddedLst)}>
                    {hasTrigger && (
                        <div
                            className={css(s.trg, s.prevTrg)}
                            onClick={(event) => event.stopPropagation()}
                        >
                            <span
                                className="flex-cent dis_sl pointer"
                                // eslint-disable-next-line no-return-assign
                                onClick={() => listNode.scrollLeft -= 250}>
                                <ChevronNext pointer rotate={180} color={COLORS.white} />
                            </span>
                        </div>
                    )}

                    <div
                        onClick={() => setSelectedVacancy(-1)}
                        className={cn('flex-cent justify-between direction-column pointer')}>
                        <div className={cn(
                            'flex-cent', css(s.item), { [css(s.actItem)]: selectedVacancy === -1 },
                        )}>
                            Все заявки
                        </div>

                        <div className={cn(css(s.btm), { [css(s.actBtm)]: selectedVacancy === -1 })} />
                    </div>

                    <VacanciesListItems
                        archiveMode={archiveMode}
                        setSelectedVacancy={setSelectedVacancy}
                        selectedVacancy={selectedVacancy} />
                </div>

                {hasTrigger && (
                    <div
                        onClick={(event) => event.stopPropagation()}
                        className={css(s.trg, s.nextTrg)}
                    >
                        <span
                            className="flex-cent dis_sl pointer"
                            // eslint-disable-next-line no-return-assign
                            onClick={() => listNode.scrollLeft += 250}>
                            <ChevronNext pointer color={COLORS.white} />
                        </span>
                    </div>
                )}
            </div>
        </div>
    )
}

const s = StyleSheet.create({
    wrp: {
        padding: '0 24px 0',
        borderRadius: 5,
    },
    lstBx: {
        position: 'relative',
        backgroundColor: '#FFFFFF',
        width: '100%',
        borderRadius: 5,
        height: 50,
        boxSizing: 'border-box',
        borderBottom: '1px solid #EDEDEE',
    },
    lst: {
        padding: '0 16px',
        display: 'flex',
        whiteSpace: 'nowrap',
        overflowX: 'auto',
        scrollBehavior: 'smooth',
        height: '100%',
        ':nth-child(1n) > :first-child': {
            height: '100%',
        },
        '::-webkit-scrollbar': {
            display: 'none',
        },
    },
    paddedLst: {
        padding: '0 4rem',
    },
    vacItem: {
        marginLeft: '2rem',
    },
    loadingItem: {
        marginLeft: 22,
    },
    item: {
        height: 'calc(100% - 6px)',
        fontWeight: '600',
        fontSize: 14,
        color: '#969F9B',
    },
    actItem: {
        color: '#2EA97D',
    },
    btm: {
        height: 3,
        width: '100%',
        borderRadius: '12px 12px 0 0',
    },
    actBtm: {
        backgroundColor: '#2EA97D',
    },
    nextTrg: {
        right: 0,
        paddingRight: 16,
        justifyContent: 'flex-end',
        background: 'linear-gradient(269.31deg, #FFFFFF 0.53%, #FFFFFF 75%, rgba(255, 255, 255, 0) 99.41%)',
    },
    prevTrg: {
        left: 0,
        justifyContent: 'flex-start',
        paddingLeft: 16,
        background: 'linear-gradient(90deg, #FFFFFF 0.53%, #FFFFFF 75%, rgba(255, 255, 255, 0) 99.41%)',
    },
    trg: {
        height: '100%',
        position: 'absolute',
        alignItems: 'center',
        width: '4rem',
        zIndex: 1,
        display: 'flex',
        boxSizing: 'border-box',
        ':nth-child(1n) > :first-child': {
            width: 32,
            height: 32,
            background: '#969F9B',
            borderRadius: 64,
            ':hover': {
                background: '#0095F2',
            },
        },
    },
})
