import React from 'react'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import Select, { components } from 'react-select'
import Highlighter from 'react-highlight-words'
import { CheckMark, ChevronDown } from '../svgs/Svgs'
import Checkbox from './Checkbox'
import { COLORS } from '../../utils/colors'

export default function SelectSimple({
    label,
    name,
    options,
    onChange,
    optStyle,
    className,
    styles,
    innerRef,
    defaultValue,
    customOption,
    contClassname,
    controlStyles,
    labelClassName,
    secondaryLabel,
    customComponent,
    placeholderStyles,
    labelContClassName,
    optionLabelClassName,
    valueContainerStyles,
    withCheckbox = false,
    ...attributes
}) {
    const customStyles = {
        control: (base) => ({
            ...base,
            boxShadow: 'none',
            outline: 'none',
            borderColor: 'transparent',
            ...controlStyles,
            '&:hover': {
                outline: 'none',
                boxShadow: 'none',
            },
        }),
        option: (optStyles, { isSelected, isFocused }) => ({
            ...optStyles,
            backgroundColor: isSelected ? COLORS.mintCream : isFocused ? COLORS.mintCream : ' transparent', // eslint-disable-line
            color: isSelected ? COLORS.dark : COLORS.midGray,
            cursor: 'pointer',
            padding: '14px 16px',
            fontWeight: '500',
            fontSize: 14,
            borderBottom: `1px solid ${COLORS.smoothGray}`,
            ':hover': {
                backgroundColor: COLORS.mintCream,
            },
            ...optStyle,
        }),
        valueContainer: (optStyles) => ({
            ...optStyles,
            padding: '13px 16px',
            ...valueContainerStyles,
        }),
        input: (optStyles) => ({
            ...optStyles,
            outline: 'none !important',
            padding: 0,
            margin: 0,
        }),
        placeholder: (optStyles) => ({
            ...optStyles,
            fontSize: 14,
            fontWeight: '500',
            color: COLORS.dark,
            ...placeholderStyles,
        }),
        singleValue: (optStyles) => ({
            ...optStyles,
            fontSize: 14,
            fontWeight: '500',
            color: COLORS.dark,
        }),
        ...styles,
    }

    const Menu = (props) => <components.Menu className={css(s.opt)} {...props} />

    const Option = (props) => {
        const { children, isSelected, selectProps } = props

        return (
            <components.Option {...props}>
                <div className="justify-between" style={{ height: 16 }}>
                    <div className="align-center gap-3">
                        {withCheckbox && <Checkbox isLarge checked={isSelected} />}
                        <Highlighter
                            textToHighlight={children}
                            activeClassName="highlight"
                            searchWords={[selectProps.inputValue.trim()]}
                            highlightClassName={cn(css(s.optLab), optionLabelClassName)}
                        />
                    </div>

                    {!withCheckbox && isSelected ? (<CheckMark />) : null}
                </div>
            </components.Option>
        )
    }

    const DropdownIndicator = (props) => (
        <components.DropdownIndicator {...props}>
            <span className={cn('expand expand-more is-flex')}>
                <ChevronDown />
            </span>
        </components.DropdownIndicator>
    )

    return (
        <div className={contClassname}>
            {label ? (
                <div className={cn('justify-between', labelContClassName)}>
                    <label className={cn(css(s.label), labelClassName)}>
                        {label}
                    </label>

                    {secondaryLabel || null}
                </div>
            ) : null}

            <Select
                ref={innerRef}
                components={{
                    Menu,
                    DropdownIndicator,
                    Option: customOption || Option,
                    IndicatorSeparator: () => null,
                    ...customComponent,
                }}
                className={cn('form-inp_pl', className)}
                styles={customStyles}
                autoFocus={false}
                closeMenuOnScroll
                defaultValue={defaultValue}
                options={options}
                {...attributes}
                onChange={(values) => {
                    if (typeof onChange === 'function') {
                        onChange(values)
                    }
                }} />
        </div>
    )
}

const s = StyleSheet.create({
    label: {
        paddingBottom: 8,
        fontSize: 12,
        color: COLORS.midGray,
        fontWeight: '500',
    },
    opt: {
        ':nth-child(1n) > :first-child': {
            padding: 0,
            ':nth-child(1n) > :first-child': {
                borderTopRightRadius: 4,
                borderTopLeftRadius: 4,
            },
            ':nth-child(1n) > :last-child': {
                borderBottomWidth: 0,
                borderBottomRightRadius: 4,
                borderBottomLeftRadius: 4,
            },
        },
    },
    optLab: {
        backgroundColor: 'transparent',
    },
})
