import React, { useState } from 'react'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import { Field, Form, Formik } from 'formik'
import uuid from 'react-uuid'
import { isEmpty } from 'lodash'
import { CheckSizeM, CloseSidebar } from '../../svgs/Svgs'
import FormInput from '../../common/FormInput'
import { required } from '../../../utils/validators'
import { COLORS, TAG_COLORS } from '../../../utils/colors'
import { usePostRequest, usePutRequest } from '../../../hooks/request'
import { CANDIDATE_TAG_CREATE, CANDIDATE_TAG_UPDATE, TAG_CREATE, TAG_UPDATE } from '../../../urls'
import { useMessage } from '../../../hooks/message'

export default function TagForm({ setShowModal, onSuccess = () => {}, candidate = false, initialValues = {} }) {
    const updateUrl = !candidate ? TAG_UPDATE : CANDIDATE_TAG_UPDATE
    const create = usePostRequest({ url: !candidate ? TAG_CREATE : CANDIDATE_TAG_CREATE })
    const update = usePutRequest({ url: updateUrl.replace('{id}', initialValues.id) })
    const [color, setColor] = useState(initialValues.color || TAG_COLORS[0])
    const [showMessage] = useMessage()

    async function onSubmit(data) {
        const $api = isEmpty(initialValues) ? create : update

        const { success, error } = await $api.request({ data: { ...data, color } })

        if (success) {
            showMessage('Успешно!', 'success-msg')
            onSuccess()
            return
        }

        showMessage(error.data.message || error.data.errors[0].message, 'error-msg')
    }

    return (
        <div className={css(s.cont)}>
            <div className={cn(css(s.hd), 'justify-between')}>
                <h3>Создание статуса</h3>

                <CloseSidebar onClick={() => setShowModal({ act: false })} />
            </div>

            {/* eslint-disable-next-line react/jsx-no-bind */}
            <Formik initialValues={{ name: '', ...initialValues }} onSubmit={onSubmit}>
                {() => (
                    <Form>
                        <div className={css(s.bd)}>
                            <Field className="not-visible" />

                            <FormInput
                                autoFocus={false}
                                label="Название статуса"
                                name="name"
                                errorWithMessage={false}
                                placeholder="Введите название"
                                labelClassName={css(s.lab)}
                                validate={required}
                                fieldClassName={cn(css(s.field), 'dis_sl')}
                            />

                            <div className={css(s.colors)}>
                                {TAG_COLORS.map((cl) => (
                                    <div
                                        key={uuid()}
                                        onClick={() => setColor(cl)}
                                        className={cn(css(s.colorBox), { [css(s.selected)]: cl === color })}>
                                        <div className={cn(colorFrames(cl), 'flex-cent')}>
                                            {cl === color && (
                                                <CheckSizeM className={cn(
                                                    css(s.icon), { [css(s.b_icon)]: color === COLORS.dark },
                                                )} />
                                            )}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>

                        <div className={css(s.foot)}>
                            <button type="reset" onClick={() => setShowModal({ act: false })}>
                                Отмена
                            </button>

                            <button type="submit" className="flex-cent gap-2">
                                {isEmpty(initialValues) ? 'Создать' : 'Сохранить' }
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    )
}

const s = StyleSheet.create({
    cont: {
        width: 542,
        backgroundColor: '#fff',
        borderRadius: 4,
    },
    hd: {
        padding: '20px 24px',
        ':nth-child(1n) > :first-child': {
            fontSize: 20,
            fontWeight: '600',
            color: COLORS.dark,
        },
    },
    bd: {
        backgroundColor: '#F9F9F9',
        width: 'calc(100% - 48px)',
        borderTop: `1px solid ${COLORS.lightGray}`,
        borderBottom: `1px solid ${COLORS.lightGray}`,
        padding: '16px 24px',
    },
    foot: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        padding: '16px 24px',
        gap: '1rem',
        fontSize: 14,
        fontWeight: 500,
        ':nth-child(1n) > :first-child': {
            borderRadius: 4,
            color: COLORS.gray,
            height: '2.5rem',
            padding: '0 16px',
            border: `1px solid ${COLORS.gray}`,
            backgroundColor: '#fff',
            cursor: 'pointer',
        },
        ':nth-child(1n) > :last-child': {
            background: `linear-gradient(180deg, ${COLORS.mainColor} 0%, ${COLORS.lightMainColor} 100%)`,
            // eslint-disable-next-line max-len
            boxShadow: '0px 4px 12px rgba(44, 67, 50, 0.1), inset 0px -1px 0px rgba(0, 0, 0, 0.25), inset 0px 1px 0px rgba(255, 255, 255, 0.1)',
            borderRadius: 4,
            height: '2.5rem',
            borderWidth: 0,
            padding: '0 21px',
            color: '#fff',
            cursor: 'pointer',
        },
    },
    lab: {
        fontSize: 12,
        fontWeight: '500',
        color: COLORS.midGray,
        marginBottom: 8,
    },
    field: {
        position: 'relative',
        paddingBottom: '6.4%',
    },
    colors: {
        display: 'grid',
        gridTemplateColumns: 'repeat(6, 1fr)',
        gap: ' 0.75rem 1rem',
    },
    selected: {
        border: `1px solid ${COLORS.dark}`,
    },
    colorBox: {
        border: '1px solid transparent',
        borderRadius: 5,
        padding: 1,
    },
    icon: {
        ':nth-child(1n) > *': {
            fill: COLORS.dark,
        },
    },
    b_icon: {
        ':nth-child(1n) > *': {
            fill: COLORS.white,
        },
    },
})

const colorFrames = (backgroundColor) => {
    const main = StyleSheet.create({
        cap: {
            backgroundColor,
            height: '2.25rem',
            borderRadius: 4,
            cursor: 'pointer',
            ':before': {
                content: '"\\200b"',
            },
        },
    })

    return css(main.cap)
}
