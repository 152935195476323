import React from 'react'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import { COLORS } from '../../../utils/colors'
import { ageRuFormat, format } from '../../../utils/number'
import { Lighting, Wallet } from '../../svgs/Svgs'
import { ApplicantSources } from '../../../utils/options'
import { strCutter } from '../../../utils/string'

export function CandidateExperience({ data }) {
    return (
        <div className="align-center gap-2">
            <div className={cn('align-center', css(s.bx))}>
                <Lighting />

                <div className="is-flex">
                    <h3>Опыт</h3>

                    <h3>{ageRuFormat(data.experience)}</h3>
                </div>
            </div>

            <div className={cn('align-center', css(s.bx))}>
                <Wallet />

                <div className="is-flex">
                    <h3>Желаемая зп</h3>

                    <h3>
                        {data.desiredSalary ? (
                            <>{format(data.desiredSalary.toString())} <span>UZS</span></>
                        ) : 'Отсутствует'}
                    </h3>
                </div>
            </div>
        </div>
    )
}

export function CandidateInfo({ data }) {
    const photo = data.photo.length ? data.photo[5] : ''

    return (
        <div className="align-center gap-2">
            {photo ? (
                <img src={photo} alt="#avatar" className={css(s.img)} />
            ) : (
                <div className={cn('flex-cent', css(s.defaultAvatar))}>
                    <h3>
                        {data.firstName.slice(0, 1)}{data.lastName.slice(0, 1)}
                    </h3>
                </div>
            )}

            <div className="is-flex direction-column align-start">
                <h3 className={css(s.fName)}>
                    {data.firstName} {data.lastName}
                </h3>

                <h3
                    title={data.location}
                    className={css(s.info)}>
                    {data.age && data.age !== '0' && ageRuFormat(data.age)}

                    {data.age && data.age !== '0' && data.location && ' • '}

                    {data.location ? data.location.length > 21
                        ? `${data.location.slice(0, 19)}...`
                        : data.location : ''}
                </h3>
            </div>
        </div>
    )
}

export function StatusAndSource({ data }) {
    const source = ApplicantSources.find(({ value }) => value === data.source)

    return (data.tagName || source) && (
        <div className={cn('justify-between-end', css(s.from))}>
            <div className="align-center gap-2">
                <h3>{source && source.cardLabel}</h3>
                {/* <span>•</span> */}
                {/* <span className="align-center"><Chat />12</span> */}
            </div>

            <span className={cn('flex-cent', css(s.tag), colorize(data.tagColor))}>
                {data.tagName && strCutter(data.tagName, 14)}
            </span>
        </div>
    )
}

const s = StyleSheet.create({
    fName: {
        fontWeight: '500',
        fontSize: 12,
        color: '#181A19',
    },
    info: {
        fontWeight: '400',
        fontSize: 11,
        color: COLORS.gray,
        whiteSpace: 'nowrap',
    },
    defaultAvatar: {
        background: '#1B93C0',
        width: 28,
        height: 28,
        borderRadius: 32,
        fontWeight: '500',
        fontSize: 11,
        color: '#FFFFFF',
        textTransform: 'uppercase',
    },
    img: {
        borderRadius: 28,
        width: 28,
        height: 28,
        boxSizing: 'border-box',
        border: `1px solid ${COLORS.lightGray}`,
    },
    bx: {
        gap: 4,
        height: 38,
        background: COLORS.mintCream,
        border: '1px solid #EBF3EF',
        borderRadius: 4,
        padding: '0 8px',
        ':nth-child(1n) > svg': {
            ':nth-child(1n) > :first-child': {
                stroke: COLORS.mainColor,
                strokeWidth: 1.5,
            },
        },
        ':nth-child(1n) > div': {
            flexDirection: 'column',
            gap: 2,
            ':nth-child(1n) > :first-child': {
                fontWeight: '500',
                fontSize: 10,
                color: COLORS.gray,
            },
            ':nth-child(1n) > :last-child': {
                whiteSpace: 'nowrap',
                fontWeight: '600',
                fontSize: 12,
                color: COLORS.lightBlack,
                ':nth-child(1n) > span': {
                    fontSize: 10,
                    // color: COLORS.gray,
                },
            },
        },
    },
    tag: {
        padding: '0 6px',
        height: 20,
        fontSize: 11,
        color: COLORS.white,
        fontWeight: '500',
        borderRadius: 4,
        whiteSpace: 'nowrap',
    },
    from: {
        fontSize: 12,
        fontWeight: '500',
        color: COLORS.midGray,
    },
})

const colorize = (backgroundColor) => css(StyleSheet.create({ cl: { backgroundColor } }).cl)
