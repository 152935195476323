import React from 'react'
import { css, StyleSheet } from 'aphrodite'
import moment from 'moment'
import { NavLink } from 'react-router-dom'
import { COLORS } from '../../../utils/colors'
import { TableItemSkeleton } from './LoadingMetrics'
import { fromToStr, strCutter } from '../../../utils/string'

export default function VacancyTableItem({ index, vacancy, loading }) {
    return !loading && !vacancy.isLoading ? (
        <tr className={css(s.item)}>
            <td className="text-centered">{index + 1}</td>

            <td className="text-start">
                <NavLink className={css(s.link)} to={`/vacancies/${vacancy.id}/info`}>
                    <h3>{vacancy.name}</h3>
                </NavLink>

                <br />

                <h5>{vacancy.columnName}</h5>
            </td>

            <td className="text-start">
                <h3>{vacancy.creatorFirstName} {vacancy.creatorLastName}</h3> <br />

                <h5>{strCutter(`${vacancy.creatorDepartmentName} • ${vacancy.creatorPositionName}`, 50)}</h5>
            </td>

            <td className="text-start">
                {vacancy.recruiterFirstName ? (
                    <React.Fragment>
                        <h3>{vacancy.recruiterFirstName} {vacancy.recruiterLastName}</h3> <br />

                        <h5>
                            {strCutter(
                                `${vacancy.recruiterDepartmentName} • ${vacancy.recruiterPositionName}`,
                                50,
                            )}
                        </h5>
                    </React.Fragment>
                ) : <div>&#8212;&#8212;&#8212;</div>}
            </td>

            <td className="text-start">
                {vacancy.responsibleFirstName ? (
                    <React.Fragment>
                        <h3>{vacancy.responsibleFirstName} {vacancy.responsibleLastName}</h3> <br />

                        <h5>
                            {strCutter(
                                `${vacancy.responsibleDepartmentName} • ${vacancy.responsiblePositionName}`,
                                50,
                            )}
                        </h5>
                    </React.Fragment>
                ) : <div>&#8212;&#8212;&#8212;</div>}
            </td>

            <td className="text-start">
                {moment(vacancy.createdAt).format('DD MMM YYYY')}
            </td>

            <td className="text-start">
                {vacancy.startedAt || vacancy.deadline ? (
                    <div className="align-center gap-1">
                        {vacancy.startedAt ? moment(vacancy.startedAt).format('DD MMM YYYY')
                            : <div>&#8212;&#8212;&#8212;</div>}
                        {' - '}
                        {vacancy.deadline ? moment(vacancy.deadline).format('DD MMM YYYY')
                            : <div>&#8212;&#8212;&#8212;</div>}
                    </div>
                ) : <div>&#8212;&#8212;&#8212;</div>}
            </td>

            <td className="text-start">
                {fromToStr(
                    vacancy.salaryMin,
                    vacancy.salaryMax,
                    'UZS',
                    <div>&#8212;&#8212;&#8212;</div>,
                )}
            </td>

            <td className="text-start">
                {vacancy.employeeAccept}/{vacancy.employeeCount}
            </td>
        </tr>
    ) : <TableItemSkeleton />
}

const s = StyleSheet.create({
    item: {
        width: '100%',
        height: '2.5rem',
        background: COLORS.white,
        color: '#181A19',
        fontSize: 13,
        fontWeight: '500',
        wordBreak: 'break-word',
        ':nth-child(1n) > *': {
            color: COLORS.dark,
            fontSize: 12,
            fontWeight: '500',
            ':nth-child(1n) > h5': {
                all: 'unset',
                color: COLORS.midGray,
                fontSize: 9,
                fontWeight: '400',
            },
        },
        ':nth-child(1n) > :not(:first-of-type)': {
            paddingLeft: 12,
        },
        ':nth-child(1n) > :not(:last-of-type)': {
            borderRight: `1px solid ${COLORS.lightGray}`,
        },
    },
    link: {
        textDecoration: 'none',
        color: COLORS.dark,
        ':hover': {
            textDecoration: 'underline',
            textDecorationColor: COLORS.skyblue,
            color: COLORS.skyblue,
        },
    },
    math: {
        paddingLeft: 4,
        ':nth-child(1n) > span': {
            color: COLORS.gray,
        },
    },
})
