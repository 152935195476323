import React from 'react'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import uuid from 'react-uuid'
import { Language } from '../svgs/Svgs'
import { AvailableLanguages, LanguageLevels } from '../../utils/options'
import { COLORS } from '../../utils/colors'

export default function LanguageInfo({ languages }) {
    return (
        <div className="is-flex direction-column gap-2">
            <label className={cn('align-center gap-2', css(s.label))}><Language /> Знание языков</label>

            <ul key={uuid()} className={cn('no_dot_list is-flex direction-column gap-3', css(s.list))}>
                {languages.map(({ lang, level }, ind) => (
                    <React.Fragment key={uuid()}>
                        <li className={cn('align-center', css(s.info_item))}>
                            <div>{AvailableLanguages.filter((e) => e.short === lang)[0].ru}</div>

                            <div>{LanguageLevels.filter((e) => e.level === level)[0].ru}</div>
                        </li>

                        {languages.length - 1 !== ind && <hr className={css(s.hr)} />}
                    </React.Fragment>
                ))}

            </ul>
        </div>
    )
}

const s = StyleSheet.create({
    list: {
        background: COLORS.white,
        padding: 20,
        border: `1px solid ${COLORS.chineseWhite}`,
        boxShadow: '0px 2px 4px rgba(130, 130, 130, 0.05)',
        borderRadius: 4,
    },
    label: {
        fontWeight: '600',
        fontSize: 13,
        color: '#181A19',
        textTransform: 'uppercase',
    },
    hr: {
        border: `.5px solid ${COLORS.smoothGray}`,
        borderRadius: 12,
        margin: 0,
    },
    info_item: {
        ':nth-child(1n) > :first-child': {
            width: '37%',
            fontWeight: '400',
            fontSize: 13,
            color: COLORS.midGray,
        },
        ':nth-child(1n) > :last-child': {
            width: '63%',
            fontWeight: '500',
            fontSize: 13,
            color: '#181A19',
        },
    },
})
