import React, { useEffect, useState } from 'react'
import { StyleSheet, css } from 'aphrodite'
import { ReactComponent as BlueCancelIcon } from '../../../static/images/blueCancel.svg'
import { COLORS } from '../../../utils/colors'

export function InstructionNotification() {
    const [showNotification, setShowNotification] = useState(false)

    useEffect(() => {
        const dismissed = localStorage.getItem('dismissedNotification')

        if (dismissed) {
            setShowNotification(false)
        }
    }, [])

    const onCancelNotification = () => {
        localStorage.setItem('dismissedNotification', JSON.stringify(true))
        setShowNotification(false)
    }

    return showNotification ? (
        <div className={css(s.mainWrapper)}>
            <div>
                <div className={css(s.title)}>Для чего нужна эта страница?</div>

                <p className={css(s.subtitle)}>
                    Эту страницу будут видеть те, кто получит ссылку о вашей компании.
                    Перейдя по ссылке вакансии, кандидат сможет заполнить данные и откликнуться на нее,
                    после чего он автоматически добавится в систему как новый кандидат
                </p>
            </div>

            <div onClick={onCancelNotification} className={css(s.cancelIconWrap)}>
                <BlueCancelIcon />
            </div>
        </div>
    ) : null
}

const s = StyleSheet.create({
    mainWrapper: {
        background: COLORS.white,
        border: '1px solid #0095F2',
        boxShadow: '0px 2px 4px rgba(130, 130, 130, 0.05)',
        borderRadius: '4px',
        padding: '12px 24px 12px 16px',
        margin: '20px 24px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: 'inherit',
    },
    title: {
        fontWeight: '600',
        fontSize: '16px',
        color: COLORS.dark,
    },
    subtitle: {
        fontWeight: '400',
        fontSize: '12px',
        color: COLORS.midGray,
        marginTop: '4px',
        width: '80%',
    },
    cancelIconWrap: {
        background: 'inherit',
        border: `1px solid ${COLORS.smoothGray}`,
        boxShadow: '0px 2px 4px rgba(130, 130, 130, 0.05)',
        borderRadius: '32px',
        width: '32px',
        height: '32px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
    },
})
