import React from 'react'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import { BachelorHat,
    Briefcase,
    BuildingSizeM,
    ChevronNextSizeL,
    DeviceLaptop,
    MapPin,
    WalletSizeM } from '../../../svgs/Svgs'
import { COLORS } from '../../../../utils/colors'
import { LoadingSkeleton } from '../../../common/LoadingSkeleton'

const baseInfo = [
    { id: 1, title: 'Опыт', icon: Briefcase },
    { id: 2, title: 'Зарплата', icon: WalletSizeM },
    { id: 3, title: 'Локация работы', icon: MapPin },
    { id: 4, title: 'Образование', icon: BachelorHat },
    { id: 5, title: 'Формат работы', icon: DeviceLaptop },
    { id: 6, title: 'График работы', icon: BuildingSizeM },
]

export default function DetailLoading({ navigate }) {
    return (
        <div className={css(s.infoWrap)}>
            <div className={css(s.contHeader)}>
                <div className={css(s.flxBtw)}>
                    <div className={css(s.companyBrief)}>
                        <h3 className={css(s.vacancyName)}>
                            <ChevronNextSizeL onClick={() => navigate(-1)} />

                            <LoadingSkeleton height={28} />
                        </h3>

                        <div className={cn('align-center', css(s.companyName))}>
                            <LoadingSkeleton height={16} width={16} />

                            <LoadingSkeleton height={16} />
                        </div>
                    </div>

                    <div className={cn('align-center gap-5', css(s.btnGrp))}>
                        <LoadingSkeleton width={32} height={32} />

                        <LoadingSkeleton height={32} width={193.3} />
                    </div>
                </div>
            </div>

            <div className={css(s.vacDescBody)}>
                <div className={cn('align-center', css(s.items))}>
                    {baseInfo.map(({ id, title, icon: Icon }) => (
                        <div key={id} className={css(s.itemWrap)}>
                            <Icon />

                            <div className={css(s.info)}>
                                <h3>{title}</h3>

                                <LoadingSkeleton height={15} />
                            </div>
                        </div>
                    ))}
                </div>

                <div className={css(s.mobileBtn)}>
                    <LoadingSkeleton />
                </div>
            </div>
        </div>
    )
}

const s = StyleSheet.create({
    companyName: {
        ':nth-child(1n) > img': {
            width: 16,
            height: 16,
            border: `1px solid ${COLORS.white}`,
            borderRadius: 2,
        },
        ':nth-child(1n) > p': {
            fontWeight: '500',
            fontSize: 14,
            color: COLORS.midGray,
        },
        gap: 6,
        marginTop: 8,
    },
    infoWrap: {
        flex: 2.5,
        background: COLORS.white,
        border: `1px solid ${COLORS.smoothGray}`,
        boxShadow: '0px 2px 4px rgba(130, 130, 130, 0.05)',
        borderRadius: 4,
        '@media (max-width: 1200px)': {
            borderRadius: 0,
            width: 'calc(100% - 2px)',
        },
        '@media (max-width: 768px)': {
            borderRadius: 0,
        },
    },
    vacDescBody: {
        maxHeight: 'calc(100vh - 255px)',
        overflowY: 'auto',
        transition: 'all 0.2s linear',
        scrollbarGutter: 'stable',
        padding: 24,
        display: 'flex',
        flexDirection: 'column',
        gap: 32,
        '-webkit-scrollbar': {
            display: 'none',
        },
        '@media (max-width: 768px)': {
            padding: 16,
            gap: 16,
        },
    },
    vacancyName: {
        display: 'flex',
        alignItems: 'center',
        color: COLORS.dark,
        gap: 4,
        ':nth-child(1n) > :first-child': {
            cursor: 'pointer',
            width: 18,
            '@media (min-width: 1200px)': {
                display: 'none',
            },
            transform: 'rotate(180deg)',
            ':nth-child(1n) > path': {
                stroke: COLORS.dark,
            },
        },
    },
    btnGrp: {
        '@media (max-width: 768px)': {
            display: 'none',
        },
    },
    shareBtn: {
        height: 32,
        background: COLORS.white,
        border: `1px solid ${COLORS.smoothGray}`,
        borderRadius: 4,
        padding: '0 6px',
        display: 'flex',
        alignItems: 'center',
        ':hover': {
            background: COLORS.smoothGray,
        },
    },
    mobileBtn: {
        width: '100%',
        '@media (min-width: 768px)': {
            display: 'none',
        },
        ':nth-child(1n)> *': {
            width: '100%',
        },
    },
    companyBrief: {
        '@media (max-width: 768px)': {
            borderBottom: `1px solid ${COLORS.lightGray}`,
            padding: '12px 16px 7px 16px',
            width: '100%',
        },
    },
    contHeader: {
        borderBottom: `1px solid ${COLORS.lightGray}`,
        padding: 24,
        '@media (max-width: 768px)': {
            padding: 0,
            borderBottom: 'none',
        },
    },
    flxBtw: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
    },
    items: {
        flexWrap: 'wrap',
        gap: 16,
        '@media (max-width: 768px)': {
            gap: 8,
        },
    },
    itemWrap: {
        display: 'flex',
        alignItems: 'center',
        padding: '6px 10px',
        gap: 10,
        ':nth-child(1n) > :first-child': {
            ':nth-child(1n) > path': {
                stroke: COLORS.mainColor,
            },
            '@media (max-width: 768px)': {
                width: 24,
            },
        },
        background: COLORS.white,
        border: `1px solid ${COLORS.smoothGray}`,
        boxShadow: '0px 2px 4px rgba(130, 130, 130, 0.05)',
        borderRadius: 4,
        '@media (max-width: 768px)': {
            padding: '4px 8px',
            gap: 4,
        },
    },
    info: {
        display: 'flex',
        gap: 4,
        flexDirection: 'column',
        ':nth-child(1n) > h3': {
            fontWeight: '500',
            fontSize: 12,
            color: COLORS.gray,
        },
        ':nth-child(1n) > p': {
            fontWeight: '600',
            fontSize: 13,
            color: COLORS.dark,
            '@media (max-width: 768px)': {
                fontSize: 12,
            },
        },
    },
})
