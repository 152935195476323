import React from 'react'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import { components } from 'react-select'
import { isEmpty } from 'lodash'
import { Users } from '../../svgs/Svgs'
import SelectForm from '../../common/SelectForm'
import { COLORS } from '../../../utils/colors'

export default function SendOthers({ data, getCandidates }) {
    const styles = {
        multiValue: (baseStyles) => ({
            ...baseStyles,
            background: COLORS.mainColor,
            borderRadius: 4,
            margin: 0,
            height: 24,
            padding: '0 8px',
            display: 'flex',
            gap: 4,
            alignItems: 'center',
            justifyContent: 'center',
            ':nth-child(1n) > :last-child': {
                padding: 0,
                ':nth-child(1n) > :first-child': {
                    ':nth-child(1n) > path': {
                        fill: COLORS.white,
                    },
                },
            },
        }),
        multiValueLabel: () => ({
            color: COLORS.white,
            fontSize: 11,
            fontWeight: 500,
        }),
    }

    const IndicatorsContainer = (props) => <components.IndicatorsContainer {...props} />

    return (
        <div className={css(s.box)}>
            <div className={cn('justify-between', css(s.formName))}>
                <div className="align-center gap-2">
                    <Users className={css(s.icon)} />

                    <h3>Отправить другим кандидатам</h3>
                </div>
            </div>

            <div className={css(s.inpGr)}>
                <SelectForm
                    isMulti
                    styles={styles}
                    name="candidates"
                    closeMenuOnSelect={false}
                    customComponents={{ IndicatorsContainer, ClearIndicator: () => null }}
                    labelContClassName={css(s.lab)}
                    placeholderStyles={{ color: COLORS.gray, fontFamily: 'Roboto, sans-serif' }}
                    labelClassName={css(s.lb)}
                    valueContainerStyles={{ padding: '8px 10px' }}
                    indicatorStyle={{ paddingRight: 10 }}
                    label="Кандидаты"
                    onMenuOpen={() => isEmpty(data) && getCandidates.request()}
                    placeholder="Выберите кандидатов"
                    options={data}
                />
            </div>
        </div>
    )
}

const s = StyleSheet.create({
    box: {
        flex: 1,
        background: COLORS.white,
        border: `1px solid ${COLORS.smoothGray}`,
        boxShadow: '0px 2px 4px rgba(130, 130, 130, 0.05)',
        borderRadius: 4,
    },
    formName: {
        height: 'calc(3rem - 1px)',
        padding: '0 22px 0 24px',
        fontSize: 13,
        fontWeight: '600',
        color: '#181A19',
        borderBottom: `1px solid ${COLORS.lightGray}`,
        textTransform: 'uppercase',
    },
    inpGr: {
        padding: '16px 24px 24px',
    },
    lb: {
        fontSize: 12,
        fontWeight: '500',
        color: COLORS.midGray,
    },
    addInp: {
        position: 'relative',
        border: `1px solid ${COLORS.lightGray}`,
        borderRadius: 4,
        fontSize: 14,
        fontWeight: '500',
        color: '#181A19',
    },
    addInpLab: {
        fontSize: 12,
        fontWeight: '500',
        color: COLORS.midGray,
        marginBottom: 8,
    },
    fld: {
        position: 'relative',
        // padding
    },
    lab: {
        ':nth-child(1n) > span': {
            display: 'inline-flex',
        },
    },
    switchBox: {
        padding: '13px 16px',
        border: `1px solid ${COLORS.lightGray}`,
        borderRadius: 4,
        fontWeight: '500',
        fontSize: 14,
        color: COLORS.gray,
    },
    switchCont: {
        gridColumn: '1 / 3',
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gridGap: 24,
        alignItems: 'end',
        height: '4.125rem',
    },
    icon: {
        ':nth-child(1n) > *': {
            stroke: '#181A19',
        },
    },
})
