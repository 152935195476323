import React, { memo } from 'react'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import { Phone } from '../../svgs/Svgs'
import FormInput from '../../common/FormInput'
import { email, phone } from '../../../utils/validators'
import FormInputPhone from '../../common/FormInputPhone'
import { COLORS } from '../../../utils/colors'

function MemoizedComponent() {
    return (
        <div className={css(s.box)}>
            <div className={cn('align-center gap-2', css(s.formName))}>
                <Phone /> <h3>контактная информация</h3>
            </div>

            <div className={css(s.inpGr)}>
                <FormInputPhone
                    errorWithMessage={false}
                    validate={phone}
                    name="phoneNumber"
                    noRequiredValidation
                    placeholder="_ _  _ _ _  _ _  _ _"
                    label="Номер телефона"
                    labelClassName={css(s.label)}
                />
                <FormInputPhone
                    errorWithMessage={false}
                    validate={phone}
                    name="scNumber"
                    noRequiredValidation
                    placeholder="_ _  _ _ _  _ _  _ _"
                    label="Дополнительный номер (Whatsapp, Telegram)"
                    labelClassName={css(s.label)}
                />

                <FormInput
                    validate={email}
                    placeholder="Введите почту"
                    name="email"
                    noRequiredValidation
                    errorWithMessage={false}
                    label="Электронная почта"
                    labelClassName={css(s.label)}
                />
            </div>
        </div>
    )
}

export const ContactInfo = memo(MemoizedComponent)

const s = StyleSheet.create({
    box: {
        background: COLORS.white,
        border: `1px solid ${COLORS.smoothGray}`,
        boxShadow: '0px 2px 4px rgba(130, 130, 130, 0.05)',
        borderRadius: 4,
    },
    formName: {
        height: 'calc(3rem - 1px)',
        padding: '0 22px 0 24px',
        fontSize: 13,
        fontWeight: '600',
        color: '#181A19',
        borderBottom: `1px solid ${COLORS.lightGray}`,
        textTransform: 'uppercase',
        ':nth-child(1n) > :first-child': {
            ':nth-child(1n) > *': {
                stroke: '#181A19',
            },
        },
    },
    inpGr: {
        padding: '16px 24px 24px',
        display: 'flex',
        flexDirection: 'column',
        gridGap: 24,
    },
    label: {
        display: 'block',
        paddingBottom: 8,
        fontWeight: '500',
        fontSize: 12,
        color: COLORS.midGray,
    },
})
