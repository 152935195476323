import React, { useContext, useEffect } from 'react'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import { useNavigate } from 'react-router-dom'
import { ChevronNext, DefaultLocation, EditPencil, MapPin, ThreeDotsMenu, Trash } from '../../svgs/Svgs'
import { useDeleteRequest, useLoad } from '../../../hooks/request'
import { LOCATION_LIST, LOCATION_DELETE } from '../../../urls'
import { useMessage } from '../../../hooks/message'
import { Popup } from '../../Popup'
import { useSidebar } from '../../../hooks/sidebar'
import LocationList from './LocationList'
import { Context } from '../../../contexts/GlobalContext'
import { COLORS } from '../../../utils/colors'
import { cp } from '../../../utils/checkPermission'

export default function Locations() {
    const { setLocations, locations } = useContext(Context)
    const deleteLocation = useDeleteRequest()
    const loadLocations = useLoad({ url: LOCATION_LIST })
    const meta = loadLocations.response ? loadLocations.response.meta : []
    const navigate = useNavigate()
    const [showMessage] = useMessage()

    useEffect(() => {
        if (loadLocations.response && loadLocations.response.data) setLocations(loadLocations.response.data)
    }, [loadLocations])

    const onDelete = async (id) => {
        const { success, error } = await deleteLocation.request({
            url: LOCATION_DELETE.replace('{id}', id),
        })

        if (success) {
            loadLocations.request()
            return showMessage('Успешно!', 'success-msg')
        }
        return showMessage(error.data.message || error.data.errors[0].message, 'error-msg')
    }

    const popupItems = [
        // { id: 1, value: 'Добавить сотрудника', icon: UserPlus, navigate: false },
        {
            id: 2,
            value: 'Изменить',
            icon: EditPencil,
            navigate: true,
            onClick: (id) => navigate(`/settings/company/location/form/${id}/update`),
        },
        { id: 3, value: 'Удалить', icon: Trash, navigate: false, onClick: (id) => onDelete(id) },
    ]

    const [showListSidebar, hideListSidebar] = useSidebar(
        <LocationList popupItems={popupItems} onClose={() => hideListSidebar()} />,
    )

    return (
        <div className={css(s.form)}>
            <div className={cn('justify-between', css(s.formName))}>
                <div className="align-center">
                    <MapPin />

                    <h3>Локации</h3>
                </div>

                {cp('settings.company.edit_location') && (
                    <h3 className={css(s.add)} onClick={() => navigate('/settings/company/location/form/create')}>
                        Добавить
                    </h3>
                )}
            </div>

            <div className={css(s.wrap)}>
                <ul className={css(s.listH)}>
                    {locations.slice(0, 5).map((loc) => (
                        <li key={loc.id} className={cn('no_dot_list justify-between', css(s.list))}>
                            <div className="align-center">
                                <div className={css(s.logo)}>
                                    <DefaultLocation />
                                </div>

                                <div className="is-flex direction-column">
                                    <h3 className={css(s.txt)}>{loc.name}</h3>

                                    <span className={css(s.dep)}>{loc.employeesCount} сотрудников</span>
                                </div>
                            </div>

                            {cp('settings.company.edit_location') && (
                                <Popup
                                    items={popupItems}
                                    renderItem={(item) => (
                                        <li
                                            key={item.id}
                                            onClick={() => item.onClick(loc.id)}
                                            className={cn('justify-between', css(s.cardSetting))}>
                                            <div className="align-center">
                                                <item.icon /> {item.value}
                                            </div>

                                            {item.navigate ? <ChevronNext /> : null}
                                        </li>
                                    )}
                                    trigger={(
                                        <span>
                                            <ThreeDotsMenu className="pointer" />
                                        </span>
                                    )} />
                            )}
                        </li>
                    ))}
                </ul>
            </div>

            {meta.totalCount > 5 && (
                <div onClick={() => showListSidebar()} className={css(s.foot)}>
                    + Ещё {meta.totalCount - 5}
                </div>
            )}
        </div>
    )
}

const s = StyleSheet.create({
    form: {
        backgroundColor: '#fff',
        borderRadius: 4,
        display: 'flex',
        flexDirection: 'column',
    },
    formName: {
        height: 'calc(3rem - 1px)',
        padding: '0 24px',
        fontSize: 13,
        fontWeight: '600',
        color: COLORS.dark,
        borderBottom: `1px solid ${COLORS.lightGray}`,
        textTransform: 'uppercase',
        ':nth-child(1n) > :first-child': {
            ':nth-child(1n) > :first-child': {
                marginRight: 8,
            },
        },
    },
    listH: {
        display: 'flex',
        flexDirection: 'column',
        gap: 16,
        ':nth-child(1n) > *': {
            height: 40,
        },
    },
    wrap: {
        padding: '15px 24px',
    },
    txt: {
        fontSize: 13,
        fontWeight: '500',
        color: COLORS.dark,
        lineHeight: 1,
        marginBottom: 3,
    },
    dep: {
        fontSize: 11,
        fontWeight: '400',
        color: COLORS.midGray,
    },
    list: {
        height: 40,
        ':nth-child(1n) > :first-child': {
            flex: 1,
            gap: 12,
        },
    },
    logo: {
        display: 'inline-flex',
        background: COLORS.mintCream,
        border: '1px solid #D3ECE3',
        borderRadius: 4,
        padding: 5,
        ':nth-child(1n) > :first-child': {
            ':nth-child(1n) > :first-child': {
                stroke: COLORS.mainColor,
            },
        },
    },
    cardSetting: {
        width: '11rem',
        ':nth-child(1n) > :first-child': {
            ':nth-child(1n) > :first-child': {
                marginRight: 8,
            },
        },
    },
    add: {
        fontWeight: 500,
        fontSize: 12,
        color: COLORS.skyblue,
        textTransform: 'capitalize',
        cursor: 'pointer',
        ':hover': {
            // eslint-disable-next-line max-len
            background: `linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(0deg, ${COLORS.skyblue}, ${COLORS.skyblue})`,
            '-webkit-background-clip': 'text',
            '-webkit-text-fill-color': 'transparent',
        },
    },
    foot: {
        borderTop: `1px solid ${COLORS.smoothGray}`,
        padding: '12px 0',
        textAlign: 'center',
        fontWeight: '500',
        fontSize: 11,
        textTransform: 'uppercase',
        color: COLORS.skyblue,
        cursor: 'pointer',
        ':hover': {
            // eslint-disable-next-line max-len
            background: `linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(0deg, ${COLORS.skyblue}, ${COLORS.skyblue})`,
            '-webkit-background-clip': 'text',
            '-webkit-text-fill-color': 'transparent',
        },
    },
})
