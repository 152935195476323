import React from 'react'
import cn from 'classnames'
import { Field, useFormikContext } from 'formik'
import { css, StyleSheet } from 'aphrodite'
import ValidationErrorMessage from './ValidationErrorMessage'
import { onChangePhoneNumber } from '../../utils/number'
import { COLORS } from '../../utils/colors'

export default function FormInputPhone({
    name,
    help,
    type,
    label,
    loading,
    validate,
    onChange,
    disabled,
    className,
    placeholder,
    fieldClassName,
    noRequiredValidation,
    labelClassName,
    errorWithMessage = true,
    ...attributes
}) {
    const { errors, touched, setFieldValue } = useFormikContext()
    const isValid = !errorWithMessage && errors[name] && touched[name]
    const isRequired = typeof validate === 'function' && !noRequiredValidation

    return (
        <div className={fieldClassName}>
            {label ? (
                <label className={labelClassName} htmlFor={name}>
                    {label} {isRequired && (
                        <span style={{ color: isValid ? COLORS.lightRed : COLORS.midGray }}>
                            *
                        </span>
                    )}
                </label>
            ) : null}

            <div className={cn(
                css(s.field, disabled && s.disabled),
                isValid ? css(s.dng) : css(s.brdCl),
            )}>
                <h3 className={css(s.countryCode)}>+998</h3>

                <Field
                    id={name}
                    name={name}
                    maxLength={12}
                    validate={validate}
                    type={type || 'text'}
                    placeholder={placeholder}
                    onChange={(event) => {
                        const value = onChangePhoneNumber(event.target.value, true)
                        setFieldValue(name, value)
                    }}
                    disabled={disabled}
                    className={cn(css(s.inp, disabled && s.disabled), 'form-inp_pl', className)}
                    {...attributes} />
            </div>

            {errorWithMessage && <ValidationErrorMessage name={name} />}
        </div>
    )
}

const s = StyleSheet.create({
    countryCode: {
        fontWeight: '500',
        fontSize: 13,
        color: COLORS.gray,
    },
    field: {
        borderWidth: 1,
        borderStyle: 'solid',
        borderRadius: 4,
        display: 'flex',
        alignItems: 'center',
        height: 42,
        gap: 8,
        padding: '0 16px',
    },
    inp: {
        border: 'none',
    },
    inpField: {
        borderWidth: 0,
        outline: 'none',
        width: '50%',
        padding: 0,
        fontSize: 14,
    },
    disabled: {
        backgroundColor: '#f2f2f2',
    },
    dng: {
        borderColor: COLORS.lightRed,
    },
    brdCl: {
        borderColor: COLORS.lightGray,
    },
    areaCode: {
        color: COLORS.gray,
        fontWeight: '500',
        fontSize: 14,
        marginRight: 8,
    },
    formHint: {
        marginTop: 4,
        fontSize: 12,
        fontWeight: '400',
        color: COLORS.lightBlack,
    },
    dngTxt: {
        fontWeight: '400',
        fontSize: 10,
        color: COLORS.lightRed,
        marginTop: 4,
    },
})
