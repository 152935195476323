import React, { useContext, useEffect, useLayoutEffect, useState } from 'react'
import cn from 'classnames'
import { css, StyleSheet } from 'aphrodite'
import { useNavigate } from 'react-router-dom'
import { LogoM, TrashSizeXL } from '../components/svgs/Svgs'
import { COLORS } from '../utils/colors'
import Button from '../components/common/Button'
import { useQueryParams } from '../hooks/queryString'
import { USER_DELETE_CONFIRM } from '../urls'
import { usePostRequest } from '../hooks/request'
import { useMessage } from '../hooks/message'
import LoadingSpinner from '../components/common/LoadingSpinner'
import { Context } from '../contexts/GlobalContext'

export default function DeleteAccount() {
    const { fetchInfo } = useContext(Context)
    const [loading, setLoading] = useState(true)
    const { token } = useQueryParams()
    const navigate = useNavigate()
    const [showMessage] = useMessage()
    const deleteUser = usePostRequest({ url: USER_DELETE_CONFIRM })

    async function handleDeleteUser() {
        const { error, success } = await deleteUser.request({ params: { token } })

        setTimeout(() => {
            setLoading(false)

            if (success) {
                showMessage('Успешно!', 'success-msg')
                localStorage.clear()
            } else {
                navigate('/auth/recruiter')
                fetchInfo()
                showMessage(error.data.errors[0].message || error.data.message, 'error-msg')
            }
        }, 1000)
    }

    useLayoutEffect(() => {
        handleDeleteUser()
    }, [])

    useEffect(() => {
        const metaTag = document.createElement('meta')
        metaTag.name = 'viewport'
        metaTag.content = 'width=device-width, initial-scale=1'

        document.head.appendChild(metaTag)

        return () => {
            document.head.removeChild(metaTag)
        }
    }, [])

    return !loading ? (
        <div className="full-width full-height is-flex direction-column">
            <header className={css(s.head)}>
                <span onClick={() => window.location.replace('/auth/recruiter')} className={css(s.logoBar)}>
                    <LogoM />
                </span>

                <Button
                    className={css(s.btn)}
                    onClick={() => window.location.replace('/registration/recruiter')}>
                    Зарегестрироваться
                </Button>
            </header>

            <div className={cn('full-width full-height is-flex justify-center', css(s.wrap))}>
                <div className="align-center direction-column gap-7 asd">
                    <div className={cn('flex-cent', css(s.trash))}>
                        <TrashSizeXL />
                    </div>

                    <div className="is-flex direction-column align-center gap-2">
                        <h3 className={css(s.title)}>Вы удалили свой аккаунт</h3>

                        <h3 className={css(s.subtitle)}>
                            Но сотрудники системы всегда могут добавить вас как нового сотрудника 😁
                        </h3>
                    </div>
                </div>
            </div>
        </div>
    ) : <div className="full-height flex-cent"> <LoadingSpinner width={128} /></div>
}

const s = StyleSheet.create({
    head: {
        height: '3.25rem',
        padding: '0 24px',
        display: 'flex',
        alignItems: 'center',
        zIndex: 2,
        gap: 15,
        position: 'relative',
        justifyContent: 'space-between',
        background: COLORS.mainColor,
        boxShadow: '0px 1px 16px rgba(72, 109, 96, 0.06)',
    },
    logoBar: {
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        color: '#FFF',
        fontSize: 14,
        fontWeight: '700',
        ':nth-child(1n) > :first-child': {
            marginRight: 2,
        },
    },
    trash: {
        padding: 12,
        borderRadius: 128,
        background: 'rgba(243, 59, 92, 0.10)',
        boxShadow: '0px 2px 4px 0px rgba(243, 59, 92, 0.20)',
    },
    title: {
        color: COLORS.dark,
        fontSize: 20,
        fontWeight: '700',
        textAlign: 'center',
    },
    subtitle: {
        color: '#343836',
        fontSize: 14,
        fontWeight: '400',
        textAlign: 'center',
    },
    btn: {
        height: 32,
        padding: '0 16px',
        color: COLORS.white,
        fontSize: 13,
        fontWeight: '500',
        borderRadius: 4,
        background: 'rgba(255, 255, 255, 0.20)',
    },
    wrap: {
        padding: '12vh 24px 0 24px',
        boxSizing: 'border-box',
        '@media (max-width: 425px)': {
            paddingTop: 'calc(25vh - 52px)',
        },
    },
})
