import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { isEmpty } from 'lodash'
import axios from 'axios'
import { useDeleteRequest, useLoad, usePutRequest } from '../hooks/request'
import { EMPLOYEE_INFO,
    EMPLOYEE_PHOTO_DELETE,
    EMPLOYEE_PHOTO_POST,
    EMPLOYEE_UN_ARCHIVE } from '../urls'
import { useMessage } from '../hooks/message'
import EmployeeForm from '../components/employees/EmployeeForm'
import { format, formatPhoneNumber, integersOnly } from '../utils/number'
import { domain } from '../utils/request'
import { auth } from '../utils/auth'

export default function EmployeeUnArchive() {
    const { id } = useParams()
    const navigate = useNavigate()
    const loadInfo = useLoad({ url: EMPLOYEE_INFO.replace('{id}', id) })
    const unArchive = usePutRequest({ url: EMPLOYEE_UN_ARCHIVE.replace('{id}', id) })
    const deletePhoto = useDeleteRequest({ url: EMPLOYEE_PHOTO_DELETE.replace('{id}', id) })
    const [showMessage] = useMessage()

    const info = loadInfo.response ? loadInfo.response.data : []

    const employeePhoto = info.photo && info.photo.length > 0 ? info.photo[2] : ''

    const uploadImage = async (avatar) => {
        let success = true

        if (typeof avatar === 'object') {
            const formData = new FormData()
            formData.append('file', avatar)

            try {
                await axios.post(domain + EMPLOYEE_PHOTO_POST.replace('{id}', id), formData, { ...auth() })
            } catch (error) {
                success = false
                showMessage(error.message, 'error-msg')
            }
        } else if (isEmpty(avatar) && !isEmpty(employeePhoto)) {
            try {
                await deletePhoto.request()
            } catch (error) {
                success = false
                showMessage(error.response.data.errors[0].message, 'error-msg')
            }
        }

        return success
    }

    // eslint-disable-next-line consistent-return
    const onSubmit = async (formData) => {
        const { email, ...submitData } = formData

        const data = {
            ...submitData,
            // eslint-disable-next-line max-len
            additionalPhoneNumber: formData.additionalPhoneNumber ? `+998${formData.additionalPhoneNumber.replace(/\s+/g, '')}` : null,
            phoneNumber: formData.phoneNumber ? `+998${formData.phoneNumber.replace(/\s+/g, '')}` : null,
            headOfEmployee: submitData.headOfEmployee ? submitData.headOfEmployee.value : null,
            salary: submitData.salary ? submitData.salary.replace(/\s/g, '') : null,
            departmentId: submitData.department.value,
            positionId: submitData.position.value,
            locationId: submitData.location && submitData.location.value,
        }

        const { error, success: isSuccess } = await unArchive.request({ data })
        showMessage(error && error.data && error.data.errors && error.data.errors[0].message, 'error-msg')

        const success = await uploadImage(submitData.avatar)

        if (success && isSuccess) {
            navigate(-1)
            return showMessage('Успешно изменено', 'success-msg')
        }
    }

    const getSkills = () => {
        const skills = []
        if (info.skillBlocks) {
            for (const item of info.skillBlocks) {
                for (const group of item.skillGroups) {
                    for (const skill of group.skills) {
                        skills.push(skill.id)
                    }
                }
            }

            return skills
        }
        return skills
    }

    const getLanguages = () => {
        const languages = {}
        if (info.languageLevel) {
            const lang = info.languageLevel

            for (let i = 0; i < lang.length; i++) {
                languages[i + 1] = {
                    lang: lang[i].language,
                    level: lang[i].level,
                }
            }

            return languages
        }
        return languages
    }

    const initialValues = {
        ...info,
        avatar: employeePhoto,
        skill: getSkills(),
        languageLevel: getLanguages(),
        startOfWork: info.startedAt || '',
        salary: format(integersOnly(info.salary)),
        phoneNumber: info.phoneNumber ? formatPhoneNumber(info.phoneNumber).replace('+998 ', '') : '',
        // eslint-disable-next-line max-len
        additionalPhoneNumber: info.additionalPhoneNumber ? formatPhoneNumber(info.additionalPhoneNumber).replace('+998 ', '') : '',
        experience: info.experience ? info.experience.map((edu) => ({
            job: edu.companyName,
            end_date: edu.endDate,
            start_date: edu.startDate,
            achievements: edu.achievement,
            position: edu.position,
        })) : [],
        education: info.education ? info.education.map((edu) => ({
            level: edu.type,
            end_date: edu.endDate,
            start_date: edu.startDate || '2023-12-12',
            additional: edu.additionalInfo,
            institution: edu.universityName,
        })) : [],
        position: info.position && { value: info.position.id, label: info.position.name },
        role: info.role ? info.role.id : 3,
        department: info.department && { value: info.department.id, label: info.department.name },
        location: info.location && Object.values(info.location).every((e) => e) ? { value: info.location.id, label: info.location.name } : null, // eslint-disable-line max-len
        headOfEmployee: info.headOfEmployee && { value: info.headOfEmployee.id, label: info.headOfEmployee.name },
    }

    return (
        <EmployeeForm
            type="archive"
            onSubmit={onSubmit}
            loading={unArchive.loading}
            onCancel={() => navigate(-1)}
            initialValues={initialValues}
        />
    )
}
