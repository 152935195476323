import React, { useCallback, useEffect, useRef } from 'react'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import { useLocation, useNavigate } from 'react-router-dom'
import { range } from 'lodash'
import { COLORS } from '../../../../utils/colors'
import { ChevronNextSizeL } from '../../../svgs/Svgs'
import { LoadingSkeleton } from '../../../common/LoadingSkeleton'
import RowViewItem from './RowViewItem'

function Skeleton() {
    return (
        <div className={cn('is-flex direction-column gap-6', css(s.cardWrap))}>
            <div className={css(s.compInfo)}>
                <LoadingSkeleton width={40} height={40} />

                <div className="is-flex direction-column gap-1">
                    <LoadingSkeleton height={17} />

                    <LoadingSkeleton width={100} height={14} />
                </div>
            </div>

            <div className="is-flex direction-column gap-5">
                <LoadingSkeleton height={24} width="80%" borderRadius={16} />

                <LoadingSkeleton height={24} borderRadius={16} />
            </div>
        </div>
    )
}

export default function RowView({ goTo, companyLogo, loadVacancies, vacancies, handleActivateVacancy }) {
    const navigate = useNavigate()
    const lstRef = useRef()
    const prevTrigRef = useRef()
    const nextTrigRef = useRef()

    const { pathname } = useLocation()

    const onTrig = (type) => {
        if (type === 'prev') {
            lstRef.current.scrollLeft -= 250
        } else { lstRef.current.scrollLeft += 250 }
    }

    const onScroll = useCallback((e) => {
        if (e.target.scrollLeft > 50) {
            prevTrigRef.current.classList.remove('not-visible')
        } else { prevTrigRef.current.classList.add('not-visible') }

        if (e.target.scrollWidth - e.target.scrollLeft - 10 < e.target.clientWidth) {
            nextTrigRef.current.classList.add('not-visible')
        } else { nextTrigRef.current.classList.remove('not-visible') }
    }, [prevTrigRef, nextTrigRef])

    useEffect(() => {
        lstRef.current.addEventListener('scroll', (e) => onScroll(e))
        return lstRef.current.removeEventListener('scroll', (e) => onScroll(e))
    }, [])

    return (
        <div className={cn('align-center', css(s.cont))}>
            <div
                ref={prevTrigRef}
                onClick={() => onTrig('prev')}
                className={cn('flex-cent pointer not-visible', css(s.trig))}>
                <ChevronNextSizeL rotate={180} color={COLORS.dark} />
            </div>

            <div ref={lstRef} className={css(s.slider)}>
                {vacancies.map((item) => (
                    <RowViewItem
                        item={item}
                        key={item.id}
                        companyLogo={companyLogo}
                        onEdit={() => navigate(`/vacancies/${item.id}/form/update?mode=edit`)}
                        onClick={() => navigate(goTo(item.id), { state: { from: pathname } })}
                        onActivate={(e) => {
                            /* to prevent unwilling navigation to detail page
                             when clicking on activate/deactivate */
                            e.stopPropagation()
                            handleActivateVacancy(item.id, item.publish, loadVacancies)
                        }}
                    />
                ))}

                {!loadVacancies.loading ? <div ref={loadVacancies.ref} /> : range(0, 5).map((key) => (
                    <Skeleton key={key} />
                ))}
            </div>

            <div onClick={onTrig} ref={nextTrigRef} className={cn('flex-cent pointer', css(s.trig, s.nextTrig))}>
                <ChevronNextSizeL color={COLORS.dark} />
            </div>
        </div>
    )
}

const s = StyleSheet.create({
    cont: {
        position: 'relative',
    },
    trig: {
        width: 46,
        height: 46,
        position: 'absolute',
        borderRadius: 64,
        zIndex: 2,
        border: '1px solid rgba(139, 139, 139, 0.20)',
        background: 'rgba(255, 255, 255, 0.80)',
        boxShadow: '0px 2px 12px 0px rgba(0, 0, 0, 0.20)',
        backdropFilter: 'blur(6px)',
        left: 24,
    },
    nextTrig: {
        right: 24,
        left: 'auto',
    },
    slider: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        whiteSpace: 'nowrap',
        overflowX: 'auto',
        scrollBehavior: 'smooth',
        paddingLeft: 24,
        gap: 24,
        '::-webkit-scrollbar': {
            display: 'none',
        },
    },
    paddedSlider: {
        padding: '0 54px 0 24px',
    },
    cardWrap: {
        cursor: 'pointer',
        minWidth: '17.25rem',
        backgroundColor: COLORS.white,
        border: `1px solid ${COLORS.smoothGray}`,
        boxShadow: '0px 2px 4px rgba(130, 130, 130, 0.05)',
        borderRadius: '4px',
        padding: 16,
        boxSizing: 'border-box',
        ':hover': {
            border: `1px solid ${COLORS.lightGray}`,
            boxShadow: '0px 2px 8px rgba(136, 136, 136, 0.25)',
        },
    },
    compInfo: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        gap: '12px',
        ':nth-child(1n) > img': {
            width: 40,
            height: 40,
        },
    },
    comp_titles: {
        display: 'flex',
        flexDirection: 'column',
        gap: '4px',
        ':nth-child(1n) > :first-child': {
            fontWeight: 700,
            fontSize: 16,
            color: COLORS.dark,
            '@media (max-width: 768px)': {
                fontSize: 13,
            },
        },
        ':nth-child(1n) > :last-child': {
            fontWeight: 400,
            fontSize: 13,
            color: COLORS.gray,
        },
    },
})
