import React from 'react'
import cn from 'classnames'
import { css, StyleSheet } from 'aphrodite'
import ToggleSwitch from '../common/ToggleSwitch'
import { COLORS } from '../../utils/colors'

export function FilterSwitcher({ setFilter, filter }) {
    return (
        <div
            onClick={() => setFilter((prev) => ({ act: !prev.act }))}
            className={cn(css(s.filter), 'align-center pointer gap-2 dis_sl')}>
            Фильтр

            <ToggleSwitch
                onClick={() => setFilter((prev) => ({ act: !prev.act }))}
                isChecked={filter.act} />
        </div>
    )
}

const s = StyleSheet.create({
    filter: {
        padding: '6px 8px',
        backgroundColor: COLORS.white,
        borderRadius: 4,
        color: '#414644',
        fontSize: 13,
        fontWeight: '500',
        border: '1px solid',
        borderColor: '#EDEDEE',
        ':hover': {
            borderColor: '#C9D2CE',
            boxShadow: '0px 2px 4px rgba(168, 168, 168, 0.15)',
        },
    },
})
