import React from 'react'
import cn from 'classnames'
import { css, StyleSheet } from 'aphrodite'
import moment from 'moment'
import { CalendarLayout, Copy, Interview, MailSizeM, Money, Phone, Users, UserX } from '../svgs/Svgs'
import { COLORS } from '../../utils/colors'
import AmazingTooltip from '../common/AmazingTooltip'
import { cp } from '../../utils/checkPermission'
import { formatPhoneNumber } from '../../utils/number'
import { CandidateColumnTypes, meetupInvalidCases, offerInvalidCases } from '../../utils/dragNDrop'

const checkStatus = (type, actionType, status, loading) => {
    if (loading || type === actionType) {
        return false
    }

    switch (type) {
    case CandidateColumnTypes.NEW:
    case CandidateColumnTypes.IN_PROGRESS:
        // eslint-disable-next-line max-len
        return [CandidateColumnTypes.MEETUP, CandidateColumnTypes.ACCEPT, CandidateColumnTypes.REJECT].includes(actionType)

    case CandidateColumnTypes.MEETUP:
        return !meetupInvalidCases[status].includes(actionType)

    case CandidateColumnTypes.OFFER:
        return !offerInvalidCases[status].includes(actionType)

    case CandidateColumnTypes.ACCEPT:
        return actionType === CandidateColumnTypes.REJECT

    case CandidateColumnTypes.REJECT:
        return actionType === CandidateColumnTypes.IN_PROGRESS

    default:
        return false
    }
}

export default function CandidateShortInfo({ info, setModal, loading }) {
    const imageSrc = info.photo && info.photo.length > 0 ? info.photo[1] : ''

    const { status, type } = info.params || {}

    return (
        <div className={cn('direction-column', css(s.short_info_cont))}>
            <div className="is-flex direction-column">
                {imageSrc ? (
                    <img className={css(s.candidate_img)} src={imageSrc} alt="#avatar" />
                ) : (
                    <div className={cn('flex-cent', css(s.avatar))}>
                        <h3>
                            {info.firstName && info.lastName
                                ? `${info.firstName.slice(0, 1)}${info.lastName.slice(0, 1)}` : ''}
                        </h3>
                    </div>
                )}

                <h3 className={css(s.name)}>{info.firstName} {info.lastName}</h3>

                <h3 className={css(s.position)}>
                    {info.position}
                    {info.position && info.birthDate && ' ・ '}
                    {info.birthDate && moment(info.birthDate).format('DD MMMM YYYY')}
                </h3>

                {/* <SelectSimple */}
                {/*     label="Вакансия" */}
                {/*     defaultValue={vacancies[0]} */}
                {/*     isSearchable={false} */}
                {/*     valueContainerStyles={{ padding: '10px 16px' }} */}
                {/*     placeholderStyles={{ fontSize: 14, fontWeight: '500', color: COLORS.gray }} */}
                {/*     className={css(s.select)} */}
                {/*     options={vacancies} /> */}

                <ul className={css(s.short_list)}>
                    {info.phoneNumber && (
                        <li>
                            <Phone />

                            <div className={css(s.lstItem)}>
                                <h3>Номер телефона</h3>

                                <h3>{formatPhoneNumber(info.phoneNumber)}</h3>
                            </div>

                            <Copy
                                className={css(s.clipboard)}
                                onClick={() => navigator.clipboard.writeText(info.phoneNumber)} />
                        </li>
                    )}

                    {info.scNumber && (
                        <li>
                            <Phone />

                            <div className={css(s.lstItem)}>
                                <h3>Дополнительный номер</h3>

                                <h3>{formatPhoneNumber(info.scNumber)}</h3>
                            </div>

                            <Copy
                                className={css(s.clipboard)}
                                onClick={() => navigator.clipboard.writeText(info.scNumber)} />
                        </li>
                    )}

                    {info.email && (
                        <li>
                            <MailSizeM />

                            <div className={css(s.lstItem)}>
                                <h3>Электронная почта</h3>

                                <h3>{info.email}</h3>
                            </div>

                            <Copy
                                className={css(s.clipboard)}
                                onClick={() => navigator.clipboard.writeText(info.email)} />
                        </li>
                    )}

                    {info.createdAt && (
                        <li>
                            <CalendarLayout className={css(s.icon)} />

                            <div className={css(s.lstItem)}>
                                <h3>Дата и время добавления</h3>

                                <h3>{moment(info.createdAt).format('DD MMMM YYYY • HH:mm')}</h3>
                            </div>
                        </li>
                    )}

                    {/* <li> */}
                    {/*     <FileSymlink /> */}

                    {/*     <div className={css(s.lstItem)}> */}
                    {/*         <h3>Способ добавления</h3> */}
                    {/*         <h3>headhunter.uz</h3> */}
                    {/*     </div> */}
                    {/* </li> */}
                </ul>

                {/* <div className={css(s.contact_via)}> */}
                {/*     <h3>Связаться с помощью:</h3> */}

                {/*     <div> */}
                {/*         {[telegram, gmail, hh, whatsapp].map((img) => ( */}
                {/*             <img key={uuid()} className={css(s.network_logo)} src={img} alt="#logo" /> */}
                {/*         ))} */}
                {/*     </div> */}
                {/* </div> */}
            </div>

            {cp('candidates.actions') && (
                <div className={css(s.reply)}>
                    {checkStatus(type, CandidateColumnTypes.REJECT, status, loading) && (
                        <AmazingTooltip
                            onClick={(prev) => setModal({
                                ...prev,
                                id: info.id,
                                type: 'reject',
                                name: `${info.firstName} ${info.lastName}`,
                            })}
                            position="left"
                            trigger={(
                                <span>
                                    <UserX color={COLORS.lightBlack} />
                                </span>
                            )}>
                            <h3 style={{ whiteSpace: 'nowrap' }}>Отказать кандидату</h3>
                        </AmazingTooltip>
                    )}

                    {checkStatus(type, CandidateColumnTypes.MEETUP, status, loading) && (
                        <AmazingTooltip
                            onClick={(prev) => setModal({
                                ...prev,
                                id: info.id,
                                type: 'meetup',
                                name: `${info.firstName} ${info.lastName}`,
                            })}
                            trigger={(
                                <span>
                                    <Interview color={COLORS.lightBlack} />
                                </span>
                            )}>
                            <h3 style={{ whiteSpace: 'nowrap' }}>Провести собеседование</h3>
                        </AmazingTooltip>
                    )}

                    {checkStatus(type, CandidateColumnTypes.OFFER, status, loading) && (
                        <AmazingTooltip
                            onClick={(prev) => setModal({
                                ...prev,
                                id: info.id,
                                type: 'offer',
                                name: `${info.firstName} ${info.lastName}`,
                            })}
                            trigger={<span><Money color={COLORS.lightBlack} /></span>}>
                            <h3 style={{ whiteSpace: 'nowrap' }}>Отправить оффер</h3>
                        </AmazingTooltip>
                    )}

                    {checkStatus(type, CandidateColumnTypes.ACCEPT, status, loading) && (
                        <AmazingTooltip
                            onClick={(prev) => setModal({
                                ...prev,
                                id: info.id,
                                type: 'accept',
                                email: info.email,
                                name: `${info.firstName} ${info.lastName}`,
                            })}
                            className={css(s.tip)}
                            trigger={(
                                <span>
                                    <Users color={COLORS.lightBlack} />
                                </span>
                            )}>
                            <div style={{ textAlign: 'center' }}>
                                <h3 style={{ whiteSpace: 'nowrap' }}>Добавить кандидата <br />в сотрудники</h3>
                            </div>
                        </AmazingTooltip>
                    )}
                </div>
            )}
        </div>
    )
}

const s = StyleSheet.create({
    short_info_cont: {
        padding: '1.25rem 1.5rem',
        borderRight: `1px solid ${COLORS.chineseWhite}`,
        display: 'flex',
        justifyContent: 'space-between',
    },
    candidate_img: {
        border: `1px solid ${COLORS.lightGray}`,
        borderRadius: 4,
        width: '15.75rem',
        height: '14rem',
        marginBottom: '1rem',
    },
    name: {
        fontSize: 16,
        color: '#181A19',
        fontWeight: '600',
    },
    position: {
        fontSize: 13,
        color: COLORS.gray,
        fontWeight: '500',
        marginBottom: '1rem',
    },
    select: {
        border: `1px solid ${COLORS.lightGray}`,
        borderRadius: 4,
    },
    short_list: {
        marginBottom: '0.75rem',
        padding: '0.75rem',
        listStyle: 'none',
        background: COLORS.lotion,
        border: `1px solid ${COLORS.smoothGray}`,
        boxShadow: '0px 2px 4px rgba(130, 130, 130, 0.05)',
        borderRadius: 4,
        display: 'flex',
        flexDirection: 'column',
        gap: 8,
        ':nth-child(1n) > *': {
            display: 'flex',
            alignItems: 'center',
            gap: 12,
            paddingBottom: 8,
            borderBottom: `1px solid ${COLORS.smoothGray}`,
        },
        ':nth-child(1n) > :last-child': {
            paddingBottom: 0,
            borderBottom: 'none',
        },
        // marginBottom: 80,
    },
    clipboard: {
        cursor: 'pointer',
        ':hover': {
            ':nth-child(1n) > *': {
                stroke: COLORS.skyblue,
            },
        },
    },
    lstItem: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        ':nth-child(1n) > :first-child': {
            fontSize: 12,
            color: COLORS.midGray,
            fontWeight: '400',
        },
        ':nth-child(1n) > :last-child': {
            fontSize: 13,
            color: '#181A19',
            fontWeight: '500',
        },
    },
    contact_via: {
        ':nth-child(1n) > :first-child': {
            fontSize: 12,
            color: COLORS.midGray,
            fontWeight: '500',
        },
        ':nth-child(1n) > :last-child': {
            marginTop: 8,
            display: 'flex',
            gap: '1rem',
        },
        marginBottom: 36, // TODO add margin more
    },
    network_logo: {
        width: 28,
        height: 28,
    },
    reply: {
        display: 'flex',
        gap: 12,
        ':nth-child(1n) > *': {
            flex: 1,
            borderRadius: 4,
            padding: '9px 0',
            cursor: 'pointer',
            alignItems: 'center',
            display: 'inline-flex',
            justifyContent: 'center',
            border: `1px solid ${COLORS.lightGray}`,
            ':hover': {
                background: 'rgba(0, 149, 242, 0.1)',
                border: '1px solid rgba(0, 149, 242, 0.2)',
                boxShadow: '0px 2px 8px rgba(136, 136, 136, 0.25)',
            },
        },
    },
    icon: {
        ':nth-child(1n) > :first-child': {
            ':nth-child(1n) > :first-child': {
                fill: COLORS.spanishGray,
            },
        },
    },
    avatar: {
        background: '#1B93C0',
        width: '15.75rem',
        height: '13.875rem',
        borderRadius: 4,
        fontWeight: '600',
        fontSize: 80,
        color: '#FFFFFF',
        marginBottom: '1rem',
        border: '1px solid transparent',
        textTransform: 'uppercase',
    },
    tip: {
        ':hover': {
            ':nth-child(1n) > :first-child': {
                top: -65,
            },
        },
    },
})
