import React from 'react'
import { css, StyleSheet } from 'aphrodite'
import { useLocation, useNavigate } from 'react-router-dom'
import { range } from 'lodash'
import { COLORS } from '../../../../utils/colors'
import { LoadingSkeleton } from '../../../common/LoadingSkeleton'
import GridViewItem from './GridViewItem'

function Skeleton() {
    return (
        <div className={css(s.mainWrap)}>
            <div className={css(s.bodyWrap)}>
                <div className={css(s.companyInfo)}>
                    <LoadingSkeleton width={40} height={40} />

                    <div className="is-flex direction-column">
                        <LoadingSkeleton height={16} />

                        <LoadingSkeleton height={13} />
                    </div>
                </div>

                <div className={css(s.detailsWrap)}>
                    <LoadingSkeleton height={28} borderRadius={16} />
                </div>
            </div>
        </div>
    )
}

export default function GridView({ loadVacancies, handleActivateVacancy, vacancies, companyLogo, goTo }) {
    const navigate = useNavigate()
    const { pathname } = useLocation()

    return (
        <div className={css(s.cont)}>
            <div className={css(s.vacancies)}>
                {vacancies.map((item) => (
                    <GridViewItem
                        item={item}
                        onClick={() => navigate(goTo(item.id), { state: { from: pathname } })}
                        companyLogo={companyLogo}
                        onEdit={() => navigate(`/vacancies/${item.id}/form/update?mode=edit`)}
                        onActivate={(e) => {
                            /* to prevent unwilling navigation to detail page
                            when clicking on activate/deactivate */
                            e.stopPropagation()
                            handleActivateVacancy(item.id, item.publish, loadVacancies)
                        }}
                    />
                ))}

                {loadVacancies.loading ? range(0, 20).map((key) => (
                    <Skeleton key={key} />)) : null}
            </div>

            {loadVacancies.hasMore ? <div ref={loadVacancies.ref} /> : null}
        </div>
    )
}

const s = StyleSheet.create({
    cont: {
        padding: '0 24px',
    },
    vacancies: {
        display: 'grid',
        position: 'relative',
        gridTemplateColumns: 'repeat( auto-fill, minmax(35rem,  1fr))',
        gap: 24,
    },
    mainWrap: {
        minWidth: '100%',
        cursor: 'pointer',
        background: COLORS.white,
        padding: '16px 16px 16px 16px',
        boxSizing: 'border-box',
        border: `1px solid ${COLORS.smoothGray}`,
        boxShadow: '0px 2px 4px rgba(130, 130, 130, 0.05)',
        borderRadius: 4,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        ':hover': {
            border: `1px solid ${COLORS.lightGray}`,
            boxShadow: '0px 2px 8px rgba(136, 136, 136, 0.25)',
        },
    },
    bodyWrap: {
        width: '100%',
    },
    companyInfo: {
        display: 'flex',
        alignItems: 'center',
        gap: '12px',
        ':nth-child(1n) > img': {
            width: 40,
            height: 40,
        },
    },
    detailsWrap: {
        marginTop: 17,
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        gap: '16px',
    },
})
