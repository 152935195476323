import React from 'react'
import cn from 'classnames'
import { css, StyleSheet } from 'aphrodite'
import { COLORS } from '../../../utils/colors'

export default function VacancyInfoHeader({ item, color }) {
    return (
        <div className={cn('full-width justify-between', css(s.cont))}>
            <div className="align-center flex-1">
                <div className="align-center gap-2">
                    <div className={markStyleSheet(color || '#969F9B')} />

                    <h3 className={css(s.name)}>
                        {item.name} <span>•</span> {item.count}
                    </h3>
                </div>
            </div>

            {/* <div className="align-center gap-3"> */}
            {/*    <Button className={css(s.fileBtn)}> */}
            {/*        <FilePDF /> */}
            {/*    </Button> */}

            {/*    <Button className={css(s.fileBtn)}> */}
            {/*        <img src={XLSX} alt="#xlsx" /> */}
            {/*    </Button> */}
            {/* </div> */}
        </div>
    )
}

const s = StyleSheet.create({
    cont: {
        padding: '0 16px',
        height: 40,
        borderRadius: '2px 2px 0 0',
        border: '1px solid #EBEBEB',
        boxSizing: 'border-box',
        borderBottom: `1px solid ${COLORS.lightGray}`,
        background: '#EAF4F0',
        ':nth-child(1n) > :first-child': {
            gap: 32,
        },
    },
    name: {
        color: COLORS.dark,
        fontSize: 15,
        fontWeight: '600',
        ':nth-child(1n) > span': {
            color: COLORS.midGray,
            fontSize: 12,
            fontWeight: '500',
        },
    },
    fileBtn: {
        border: 'none',
        padding: 0,
    },
})

const markStyleSheet = (backgroundColor) => css(StyleSheet.create({
    main: {
        width: 18,
        height: 18,
        borderRadius: 4,
        backgroundColor,
    },
}).main)
