import React, { useContext, useEffect, useState } from 'react'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import { useNavigate } from 'react-router-dom'
import Layout from '../components/common/Layout'
import { CandidateHeader } from '../components/candidates/CandidateHeader'
import { BaseContext } from '../contexts/BaseContextWrapper'
import { FilterContext } from '../contexts/FilterContext'
import { CandidateFilter } from '../components/candidates/CandidateFilter'
import { Context } from '../contexts/GlobalContext'
import VacanciesList from '../components/candidates/VacanciesList'
import { DASHBOARD_COLORS } from '../utils/colors'
import { useQueryParams } from '../hooks/queryString'
import CandidateDashboard from '../components/candidates/CandidateDashboard'

function CandidateInfo({ setCandidateFilter, candidateFilter }) {
    const { candidatesCount, setCandidatesCount } = useContext(BaseContext)
    const [selectedVacancy, setSelectedVacancy] = useState(-1)
    const { view } = useQueryParams()
    const navigate = useNavigate()

    useEffect(() => {
        if (!view || !['board', 'table', 'archive'].includes(view)) navigate('?view=board')
    }, [])

    return (
        <React.Fragment>
            <CandidateHeader
                view={view}
                candidatesCount={candidatesCount}
                candidateFilter={candidateFilter}
                setCandidateFilter={setCandidateFilter} />

            <div className={css(s.info)}>
                <VacanciesList
                    archiveMode={view === 'archive'}
                    selectedVacancy={selectedVacancy}
                    setSelectedVacancy={setSelectedVacancy} />

                <CandidateDashboard
                    view={view}
                    candidateFilter={candidateFilter}
                    selectedVacancy={selectedVacancy}
                    setCandidatesCount={setCandidatesCount}
                />
            </div>
        </React.Fragment>
    )
}

export default function Candidates() {
    const { theme } = useContext(Context)
    const { candidateFilter, setCandidateFilter } = useContext(FilterContext)
    // TODO clear filter on change company

    return (
        <Layout padded={false} className={css(s.ly, bgColor(DASHBOARD_COLORS[theme.cB]))}>
            <div className={cn('is-flex', css(s.cont))}>
                {candidateFilter.act
                    ? <CandidateFilter candidateFilter={candidateFilter} setCandidateFilter={setCandidateFilter} />
                    : null}

                <div className={css(s.infoCont, candidateFilter.act ? s.w80 : s.w100)}> {/* TODO fix width */}
                    <CandidateInfo
                        candidateFilter={candidateFilter}
                        setCandidateFilter={setCandidateFilter} />
                </div>
            </div>
        </Layout>
    )
}

const s = StyleSheet.create({
    ly: {
        width: '88.4%',
        scrollbarGutter: 'stable',
    },
    cont: {
        height: '100%',
    },
    info: {
        height: 'calc(100% - 66px)',
    },
    w100: {
        width: '100%',
    },
    w80: {
        width: '80%',
    },
    infoCont: {
        overflowY: 'auto',
        height: '100%',
        scrollbarGutter: 'stable',
    },
})

const bgColor = (backgroundColor) => StyleSheet.create({
    main: {
        backgroundColor,
    },
}).main
