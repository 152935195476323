import React, { useEffect, useLayoutEffect, useRef } from 'react'
import { css, StyleSheet } from 'aphrodite'
import { useLocation } from 'react-router-dom'
import LayoutRegister from '../../components/common/LayoutRegister'
import { Mail } from '../../components/svgs/Svgs'
import { CountdownTimer } from '../../components/common/ButtonTimer'
import { COLORS } from '../../utils/colors'

export default function Confirmation() {
    const { state } = useLocation()

    useLayoutEffect(() => {
        if (!state || (state && !state.isAllowed)) {
            const redirectUrl = window.location.pathname.replace('/confirmation/recruiter', '/auth/recruiter')
            window.location.pathname = redirectUrl
        }
    }, [])

    const childRef = useRef()

    useEffect(() => {
        childRef.current.onStart()
        onVerify()
    }, [])

    function onVerify() {}

    return (
        <LayoutRegister>
            <div className={css(s.cont)}>
                <h3 className={css(s.title)}>Подтверждение</h3>

                <div className={css(s.conf_wr)}>
                    <Mail />

                    <p>
                        Письмо с подтверждением был <br /> отправлен по электронной почте в адрес<br />
                        <span>{localStorage.user ? JSON.parse(localStorage.user).username : ''}</span>
                    </p>
                </div>

                <CountdownTimer
                    seconds={60}
                    size={287.5}
                    ref={childRef}
                    onClick={() => onVerify()}
                />
            </div>
        </LayoutRegister>
    )
}

const s = StyleSheet.create({
    cont: {
        marginTop: 20,
        maxWidth: 395,
        marginRight: 32,
        marginLeft: '19.5%',
    },
    title: {
        fontSize: 36,
        fontWeight: '600',
        color: '#181A19',
    },
    conf_wr: {
        margin: '33px 0',
        display: 'flex',
        alignItems: 'center',
        letterSpacing: '-0.3px',
        ':nth-child(1n) > :last-child': {
            fontSize: 16,
            fontWeight: '500',
            color: COLORS.midGray,
            marginLeft: 16,
            ':nth-child(1n) > :last-child': {
                color: COLORS.skyblue,
                textDecoration: 'underline',
            },
        },
    },
    btn: {
        cursor: 'pointer',
        width: '100%',
        height: 40,
        fontWeight: '500',
        fontSize: 14,
        color: '#414644',
        backgroundColor: COLORS.lightGray,
        border: 'none',
        borderRadius: 4,
    },
})
