import React, { useLayoutEffect, useState } from 'react'
import { Form, Formik } from 'formik'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import { isEmpty } from 'lodash'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import FormInput from '../../components/common/FormInput'
import { password as passwordValidator, required } from '../../utils/validators'
import Button from '../../components/common/Button'
import { Eye, EyeClose } from '../../components/svgs/Svgs'
import { usePostRequest } from '../../hooks/request'
import { CHECK_VERIFY_TOKEN, NEW_PASSWORD, RESET_PASSWORD } from '../../urls'
import { COLORS } from '../../utils/colors'
import LayoutAuth from '../../components/common/LayoutAuth'
import { useMessage } from '../../hooks/message'
import { domain } from '../../utils/request'
import { useQueryParams } from '../../hooks/queryString'

export default function NewPassword() {
    const { token, type } = useQueryParams()
    const navigate = useNavigate()
    const [showMessage] = useMessage()
    const updatePassword = usePostRequest({ url: RESET_PASSWORD, params: { token } })
    const newPassword = usePostRequest({ url: NEW_PASSWORD })
    const [passGroup, setPassGroup] = useState({ password: false, confirmPassword: false })

    useLayoutEffect(() => {
        if (['create_new_password', 'add_new_employee', 'candidate_to_employee'].includes(type)) {
            axios.get(domain + CHECK_VERIFY_TOKEN, { params: { token } })
                .catch(() => {
                    showMessage('Неправильный токен', 'error-msg')
                    navigate('/auth/recruiter')
                })
        } else if (type === 'forgot_password') {
            axios.get(domain + RESET_PASSWORD, { params: { token } })
                .catch(() => {
                    showMessage('Неправильный токен', 'error-msg')
                    navigate('/reset-password')
                })
        } else {
            window.location.pathname = window.location.pathname.replace('/new-password', '/auth/recruiter')
        }
    }, [])

    async function onSubmit(data, helpers) {
        const { setFieldError } = helpers
        const { password, confirmPassword } = data

        if (password !== confirmPassword) {
            setFieldError('confirmPassword', 'Пароли не совпадают')
            return
        }

        if (type === 'forgot_password') {
            updatePassword.request({ data: { password } }).then(() => {
                navigate('/auth/recruiter')
                showMessage('Пароль успешно изменен', 'success-msg')
            }).catch(({ error }) => {
                showMessage(error.data.errors[0].message || error.data.message, 'error-msg')
            })
        } else {
            newPassword.request({ data: { token, password } }).then(({ success }) => {
                if (success) {
                    navigate('/auth/recruiter')
                    showMessage('Пароль добавлен', 'success-msg')
                }
            }).catch(({ error }) => {
                showMessage(error.data.errors[0].message || error.data.message, 'error-msg')
            })
        }
    }

    const icons = (name) => (passGroup[name] ? (
        <Eye pointer onClick={() => setPassGroup((prev) => ({ ...prev, [name]: false }))} />
    ) : (
        <EyeClose pointer onClick={() => setPassGroup((prev) => ({ ...prev, [name]: true }))} />
    ))

    return (
        <LayoutAuth>
            <div className={css(s.form)}>
                <h3>Новый пароль</h3>

                <Formik
                    onSubmit={(data, helpers) => onSubmit(data, helpers)}
                    initialValues={{ password: '', confirmPassword: '' }}>
                    {({ errors, values }) => (
                        <React.Fragment>
                            <Form>
                                <FormInput
                                    type={passGroup.password ? 'text' : 'password'}
                                    label="Новый пароль"
                                    name="password"
                                    placeholder="Введите"
                                    help="* Пароль должен быть минимум 10 символов и включать в себя буквы и цифры"
                                    labelClassName={css(s.lab)}
                                    validate={passwordValidator}
                                    fieldClassName={cn(css(s.field), 'dis_sl')}
                                    icon={icons('password')}
                                />

                                <FormInput
                                    type={passGroup.confirmPassword ? 'text' : 'password'}
                                    name="confirmPassword"
                                    label="Повторите новый пароль"
                                    labelClassName={css(s.lab)}
                                    validate={required}
                                    fieldClassName={cn(css(s.field), 'dis_sl')}
                                    placeholder="Введите"
                                    icon={icons('confirmPassword')}
                                />

                                <Button
                                    type="submit"
                                    className={cn(
                                        css(s.btn),
                                        { [css(s.sbmBtn)]:
                                                    isEmpty(errors) && Object.values(values).every((e) => e !== '') },
                                    )}>
                                    Готово
                                </Button>
                            </Form>
                        </React.Fragment>
                    )}
                </Formik>
            </div>
        </LayoutAuth>
    )
}

const s = StyleSheet.create({
    form: {
        margin: 'auto',
        maxWidth: 'calc(24.75rem - 1px)',
        ':nth-child(1n) > :first-child': {
            fontSize: 36,
            color: '#181A19',
            fontWeight: '600',
            letterSpacing: 0.3,
        },
        ':nth-child(1n) > :last-child': {
            marginTop: 'calc(2rem + 1px)',
        },
    },
    lab: {
        fontSize: 12,
        fontWeight: '500',
        color: COLORS.midGray,
        marginBottom: 8,
    },
    field: {
        position: 'relative',
        paddingBottom: '1.125rem',
        ':last-of-type': {
            paddingBottom: '2rem',
        },
    },
    btn: {
        width: '100%',
        padding: '12px 0',
        background: COLORS.gray,
        // eslint-disable-next-line max-len
        boxShadow: '0 4px 12px rgba(44, 67, 50, 0.1), inset 0px -1px 0px rgba(0, 0, 0, 0.25), inset 0px 1px 0px rgba(255, 255, 255, 0.1)',
        borderRadius: 4,
        color: '#FFFFFF',
        fontSize: 14,
        fontWeight: '500',
    },
    sbmBtn: {
        background: `linear-gradient(180deg, #2EA97D 0%, ${COLORS.lightMainColor} 100%)`,
        // eslint-disable-next-line max-len
        boxShadow: '0px 4px 12px rgba(44, 67, 50, 0.1), inset 0px -1px 0px rgba(0, 0, 0, 0.25), inset 0px 1px 0px rgba(255, 255, 255, 0.1)',
    },
})
