import React, { useState } from 'react'
import cn from 'classnames'
import { css, StyleSheet } from 'aphrodite'
import uuid from 'react-uuid'
import { useDeleteRequest, useLoad } from '../../../hooks/request'
import { CANDIDATE_TAG_DELETE, CANDIDATE_TAGS, TAG_DELETE, TAG_LIST } from '../../../urls'
import { EditPencil, PlusRoundedSizeS, ThreeDotsMenuHorizontal, Trash } from '../../svgs/Svgs'
import { COLORS, tagStyleSheet } from '../../../utils/colors'
import { Popup } from '../../Popup'
import Modal from '../../common/Modal'
import TagForm from './TagForm'
import { useMessage } from '../../../hooks/message'

export function Tags({ values, setFieldValue, onSelectTag, isCandidateTags }) {
    const deleteUrl = !isCandidateTags ? TAG_DELETE : CANDIDATE_TAG_DELETE
    const [showModal, setShowModal] = useState({ act: false })
    const [showMessage] = useMessage()
    const deleteTags = useDeleteRequest()
    const loadTags = useLoad({ url: !isCandidateTags ? TAG_LIST : CANDIDATE_TAGS })
    const tags = loadTags.response ? loadTags.response.data : []

    const fieldName = !isCandidateTags ? 'tag' : 'tagId'
    const tagValue = values[fieldName]
    const activeTag = (tagId) => (!isCandidateTags ? tagValue.includes(tagId) : tagValue === tagId)

    const onDelete = async (tagId) => {
        const { success, error } = await deleteTags.request({
            url: deleteUrl.replace('{id}', tagId),
        })

        if (success) {
            if (activeTag(tagId)) {
                setFieldValue(fieldName, !isCandidateTags ? tagValue.filter((e) => e !== tagId) : null)
            }

            loadTags.setResponse({
                data: tags.filter((e) => e.id !== tagId),
                success: true,
            })

            return showMessage('Успешно!', 'success-msg')
        }

        return showMessage(error.data.errors[0].message || error.data.message, 'error-msg')
    }

    return (
        <React.Fragment>
            <div className={cn('is-flex direction-column gap-2', css(s.wrap))}>
                <h3>Выберите статус заявки</h3>

                <div className={cn('is-flex gap-2', css(s.tags))}>
                    {tags.map((tag) => (
                        <span
                            onClick={() => onSelectTag(tag.id)}
                            key={uuid()}
                            className={cn(
                                'stc-tag align-center gap-1 pointer dis_sl',
                                css(s.tag),
                                { [tagStyleSheet(tag.color)]: activeTag(tag.id) },
                            )}>
                            {tag.name}
                            <Popup
                                position="left"
                                activeTriggerClassName={css(s.actPp)}
                                items={[
                                    { id: 1, value: 'Изменить', icon: EditPencil },
                                    { id: 2, value: 'Удалить', icon: Trash },
                                ]}
                                renderItem={(item) => (
                                    <li
                                        key={item.id}
                                        onClick={(e) => {
                                            e.stopPropagation()
                                            if (item.id === 1) setShowModal({ act: true, initialValues: tag })
                                            if (item.id === 2) onDelete(tag.id)
                                        }}
                                        className={cn('justify-between', css(s.tag_menu))}>
                                        <div className="align-center gap-2">
                                            <item.icon /> {item.value}
                                        </div>
                                    </li>
                                )}
                                trigger={(
                                    <span className="tag_dots align-center">
                                        <ThreeDotsMenuHorizontal color={activeTag(tag.id) && tag.color} />
                                    </span>
                                )} />
                        </span>
                    ))}
                    <span
                        onClick={() => setShowModal({ act: true })}
                        className={cn('align-center gap-2 pointer', css(s.addTag))}>
                        <PlusRoundedSizeS /> Добавить
                    </span>
                </div>
            </div>

            <Modal
                closeOnEscape
                open={showModal.act}
                closeOnDocumentClick
                overlayStyle={{ top: 52 }}
                onClose={() => setShowModal({ act: false })}>
                <TagForm
                    candidate={isCandidateTags}
                    onSuccess={() => {
                        setShowModal({ act: false })
                        loadTags.request()
                    }}
                    initialValues={showModal.initialValues}
                    setShowModal={setShowModal} />
            </Modal>
        </React.Fragment>
    )
}

const s = StyleSheet.create({
    addTag: {
        height: '1.5rem',
        padding: '0 10px',
        background: COLORS.skyblue,
        borderRadius: 4,
        color: COLORS.white,
        fontWeight: '500',
        fontSize: 11,
    },
    tag: {
        height: 'calc(1.5rem - 2px)',
        padding: '0 8px',
        border: '1px solid transparent',
        background: COLORS.smoothGray,
        borderRadius: 4,
        color: COLORS.midGray,
        fontWeight: '500',
        fontSize: 11,
        outline: 'none',
        ':hover': {
            border: `1px solid ${COLORS.lightGray}`,
        },
    },
    tag_menu: {
        width: '10.75rem',
    },
    wrap: {
        position: 'relative',
        gridColumn: '1 / 3',
        fontWeight: '500',
        fontSize: 12,
        color: COLORS.midGray,
        border: '1px solid',
        borderColor: 'transparent',
        borderRadius: 5,
    },
    tags: {
        flexWrap: 'wrap',
    },
    actPp: {
        ':nth-child(1n) > :first-child': {
            display: 'flex',
        },
    },
})
