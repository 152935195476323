import React from 'react'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import { endsWith } from 'lodash'
import { FileSizeM, ReportSearch, TemplateTabler } from '../svgs/Svgs'
import { COLORS } from '../../utils/colors'
import Button from '../common/Button'

const ReportTypes = [
    { key: 1, name: 'Информация о вакансиях', icon: ReportSearch, route: 'vacancy-info' },
    { key: 2, name: 'Источники откликов', icon: TemplateTabler, route: 'applicant-sources' },
    { key: 3, name: 'Работа с вакансиями', icon: FileSizeM, route: 'vacancy-metrics' },
    // { key: 4, name: 'Отчет по пользователям', icon: Users, route: 'user-metrics' },
]

export default function Header({ location, navigate, formikRef, vacancyLoading, vacancyCount }) {
    const isDisabled = vacancyLoading || !vacancyCount

    return (
        <div className={cn('justify-between', css(s.cont))}>
            <div>
                <div className="align-center flex-1">
                    {ReportTypes.map(({ key, name, icon: Icon, route }) => (
                        <div
                            key={key}
                            onClick={() => navigate(`/reports/${route}`)}
                            className={cn(
                                'align-center gap-2 pointer',
                                css(s.item, endsWith(location, route) && s.actItem),
                            )}>
                            {endsWith(location, route) && <Icon color={COLORS.dark} />}

                            <h3>{name}</h3>
                        </div>
                    ))}
                </div>
            </div>

            <div className="align-center gap-3">
                {/* <Button className={css(s.fileBtn)}> */}
                {/*    <FilePDF /> */}
                {/* </Button> */}

                {/* <Button className={css(s.fileBtn)}> */}
                {/*    <img src={XLSX} alt="#xlsx" /> */}
                {/* </Button> */}

                <Button
                    type="submit"
                    className={css(s.generateBtn, isDisabled && s.disBtn)}
                    disabled={isDisabled}
                    onClick={() => formikRef.current.handleSubmit()}
                >
                    <FileSizeM color={COLORS.white} />

                    <h3>Составить отчет</h3>
                </Button>
            </div>
        </div>
    )
}

const s = StyleSheet.create({
    cont: {
        background: COLORS.white,
        height: 54,
        padding: '0 24px',
        borderBottom: `1px solid ${COLORS.smoothGray}`,
        ':nth-child(1n) > :first-child': {
            overflowX: 'auto',
        },
    },
    item: {
        color: '#969F9B',
        fontSize: 15,
        fontWeight: '600',
        borderRadius: 4,
        padding: '0 16px',
        height: 36,
    },
    actItem: {
        background: COLORS.smoothGray,
        color: COLORS.dark,
    },
    fileBtn: {
        width: 36,
        height: 36,
        borderRadius: 4,
        border: `1px solid ${COLORS.smoothGray}`,
        background: COLORS.white,
        boxShadow: '0px 2px 4px 0px rgba(130, 130, 130, 0.05)',
    },
    disBtn: {
        background: COLORS.lightGray,
        boxShadow: 'none',
        ':hover': {
            boxShadow: 'none',
        },
    },
    generateBtn: {
        borderRadius: 4,
        background: '#0095F2',
        height: 32,
        color: COLORS.white,
        fontSize: 14,
        fontWeight: '500',
        padding: '0 16px',
        // eslint-disable-next-line max-len
        boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.10) inset, 0px -1px 0px 0px rgba(0, 0, 0, 0.25) inset, 0px 4px 12px 0px rgba(44, 67, 50, 0.10)',
        ':hover': {
            // eslint-disable-next-line max-len
            boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.15) inset, 0px -1px 0px 0px rgba(0, 0, 0, 0.25) inset, 0px 4px 12px 0px rgba(44, 67, 50, 0.15)',
        },
    },
})
