import React, { useRef } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import moment from 'moment'
import { COLORS } from '../../../../utils/colors'
import { DefaultLocation,
    EditPencil,
    EyeCloseSizeM,
    EyeSizeM,
    ThreeDotsMenu } from '../../../svgs/Svgs'
import { Popup } from '../../../Popup'
import { LoadingSkeleton } from '../../../common/LoadingSkeleton'
import { format } from '../../../../utils/number'
import { fromToStr, strCutter } from '../../../../utils/string'

function Skeleton() {
    return (
        <React.Fragment>
            <tr className={css(s.item)}>
                <td>
                    <div className={cn('align-center gap-3', css(s.hero))}>
                        <LoadingSkeleton height={40} width={40} />

                        <div className={css(s.compInfo)}>
                            <LoadingSkeleton height={13} />

                            <LoadingSkeleton height={11} />
                        </div>
                    </div>
                </td>

                <td><LoadingSkeleton height={13} /></td>

                <td><LoadingSkeleton height={13} width={70} /></td>

                <td><LoadingSkeleton height={13} /></td>

                <td><LoadingSkeleton height={13} /></td>

                <td>
                    <div style={{ width: 20, height: '100%' }} />
                </td>
            </tr>
        </React.Fragment>
    )
}

export function TableViewItem({ item, info, handleActivateVacancy, getVacancies, goTo }) {
    const popUpRef = useRef()
    const navigate = useNavigate()
    const { pathname } = useLocation()

    return !getVacancies.loading ? (
        <React.Fragment>
            <tr
                key={item.id}
                className={cn(css(s.item))}
                id={!item.publish ? 'inactive-stripped' : undefined}
                onClick={() => navigate(goTo(item.id), { state: { from: pathname } })}
            >
                <td>
                    <div className={cn('align-center gap-3', css(s.hero))}>
                        {info.logo && info.logo.length ? (
                            <div className={css(s.logoCont)}>
                                <img src={info.logo[1]} alt="#CompanyLogo" />
                            </div>
                        ) : (
                            <div className={css(s.defaultLogo)}>
                                <DefaultLocation color={COLORS.white} />
                            </div>
                        )}

                        <div className="align-start direction-column gap-1">
                            <h3 title={item.name} className={css(s.positionName)}>
                                {strCutter(item.name, 44)}
                            </h3>

                            <h3 className={css(s.depart)} title={`${item.department} • ${item.position}`}>
                                {strCutter(item.department, 25)} • {strCutter(item.position, 25)}
                            </h3>
                        </div>
                    </div>
                </td>

                <td>
                    {moment(item.createdAt).format('DD MMMM YYYY')}
                </td>

                <td>
                    {item.deadline ? moment(item.deadline).format('DD MMMM YYYY') : '———'}
                </td>

                <td>
                    {fromToStr(
                        item.salaryMin ? format(item.salaryMin) : '',
                        item.salaryMax ? format(item.salaryMax) : '',
                        item.currency,
                        'По итогам собеседование',
                    )}
                </td>

                <td>{item.location ? item.location : '———'}</td>

                <td>
                    <Popup
                        ref={popUpRef}
                        trigger={(
                            <div
                                onClick={(event) => {
                                    event.stopPropagation()
                                    popUpRef.current.onOpen()
                                }}
                                className="flex-cent pointer"
                                style={{ width: 20, height: '100%' }}>
                                <ThreeDotsMenu />
                            </div>
                        )}
                    >
                        <ul className={css(s.popUpWrap)}>
                            <li
                                onClick={(e) => {
                                    e.stopPropagation()
                                    navigate(`/vacancies/${item.id}/form/update`)
                                }}
                                className={cn('justify-between', css(s.cardSetting))}>
                                <div className="align-center" style={{ gap: 8 }}>
                                    <EditPencil /> Изменить
                                </div>
                            </li>

                            <li
                                className={cn('justify-between', css(s.cardSetting))}
                                onClick={(e) => {
                                    e.stopPropagation()
                                    handleActivateVacancy(item.id, item.publish, getVacancies)
                                }}
                            >
                                <div className="align-center" style={{ gap: 8 }}>
                                    {item.publish ? <EyeCloseSizeM /> : <EyeSizeM />}

                                    {item.publish ? 'Деактивировать' : 'Активировать'}
                                </div>
                            </li>
                        </ul>
                    </Popup>
                </td>
            </tr>
        </React.Fragment>
    ) : <Skeleton />
}

const s = StyleSheet.create({
    positionName: {
        color: COLORS.dark,
        fontSize: 13,
        fontWeight: '500',
        textAlign: 'left',
    },
    depart: {
        color: COLORS.gray,
        fontSize: 11,
        fontWeight: '400',
    },
    hero: {
        paddingLeft: 20,
    },
    item: {
        background: COLORS.white,
        height: '3.75rem',
        color: '#181A19',
        fontSize: 13,
        fontWeight: '500',
        // userSelect: 'none',
        ':nth-child(1n) > td:last-child': {
            paddingRight: 11,
        },
        ':nth-child(1n) > td': {
            textAlign: 'left',
        },
    },
    cardSetting: {
        width: '10rem',
        userSelect: 'none',
    },
    popUpWrap: {
        borderRadius: 4,
        margin: 0,
        ':nth-child(1n) > *': {
            color: '#fff',
            fontSize: 12,
            background: 'rgba(65, 70, 68, 0.9)',
            backdropFilter: 'blur(8px)',
            fontWeight: '500',
            padding: '10px 12px',
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
            borderBottom: '1px solid #5e6360',
            ':hover': {
                background: 'rgba(87, 90, 89, 0.9)',
            },
            ':nth-child(1n) > :first-child': {
                marginRight: 8,
            },
        },
        ':nth-child(1n) > :last-child': {
            borderBottomWidth: 0,
            borderBottomLeftRadius: 4,
            borderBottomRightRadius: 4,
        },
        ':nth-child(1n) > :first-child': {
            borderTopLeftRadius: 4,
            borderTopRightRadius: 4,
        },
    },
    logoCont: {
        width: 40,
        height: 40,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 4,
        boxSizing: 'border-box',
        ':nth-child(1n) > img': {
            width: 'inherit',
            borderRadius: 4,
        },
    },
    defaultLogo: {
        width: 40,
        height: 40,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: COLORS.mainColor,
        border: `1px solid ${COLORS.white}`,
        borderRadius: 4,
        boxSizing: 'border-box',
    },
})
