import React from 'react'
import cn from 'classnames'
import { css, StyleSheet } from 'aphrodite'
import { COLORS } from '../../../utils/colors'
import { useLoad } from '../../../hooks/request'
import { COMPANY_INFO } from '../../../urls'

export default function Topic({ values }) {
    const loadCompanyInfo = useLoad({ url: COMPANY_INFO })
    const company = loadCompanyInfo.response ? loadCompanyInfo.response.data : {}

    return (
        <div className={cn('is-flex direction-column gap-2', css(s.topic))}>
            <h3 className={css(s.name)}>{values.name}</h3>

            <div className={cn('align-center gap-2', css(s.compInfo))}>
                <img
                    alt="#logo"
                    className={css(s.logo)}
                    src={company.logo ? company.logo[1] : ''}
                />

                <h3>{company.name}</h3>
            </div>
        </div>
    )
}
const s = StyleSheet.create({
    topic: {
        padding: 24,
        borderBottom: `1px solid ${COLORS.lightGray}`,
    },
    logo: {
        width: 20,
        height: 20,
    },
    name: {
        color: COLORS.dark,
        fontSize: 24,
        fontWeight: '600',
        overflowWrap: 'anywhere',
    },
    compInfo: {
        color: COLORS.midGray,
        fontSize: 14,
        fontWeight: '500',
    },
})
