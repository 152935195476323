import React, { memo, useEffect, useState } from 'react'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import uuid from 'react-uuid'
import { EditSizeL, Language, PlusRoundedSizeL, XCircleSizeL } from '../svgs/Svgs'
import SelectForm from './SelectForm'
import { AvailableLanguages, LanguageLevels } from '../../utils/options'
import { COLORS } from '../../utils/colors'

function LanguageBlock({ setFieldValue, index, count, removeMode, langs, onRemove, availableLanguages }) {
    const blockValue = langs[index] ? langs[index].lang : ''

    return (
        <>
            <div className={css(s.inpGr, removeMode && index !== 1 && s.removable_field)}>
                {removeMode && index !== 1 && (
                    <XCircleSizeL className="dis_sl" onClick={() => onRemove(index)} />
                )}

                <SelectForm
                    labelContClassName={css(s.lab)}
                    placeholderStyles={{ color: COLORS.gray, fontFamily: 'Roboto, sans-serif' }}
                    labelClassName={css(s.lb)}
                    valueContainerStyles={{ padding: '11.5px 16px' }}
                    indicatorStyle={{ paddingRight: 10 }}
                    label="Знание языков кандидата"
                    placeholder="Выберите язык"
                    value={AvailableLanguages.find((e) => e.short === blockValue)}
                    getOptionLabel={(option) => option.ru}
                    name={`languageLevel[${index}]`}
                    onChange={(value) => {
                        setFieldValue(
                            'languageLevel',
                            { ...langs, [index]: { lang: value.short, level: 'A1' } },
                        )
                    }}
                    options={availableLanguages}
                />

                <div className="is-flex direction-column gap-2">
                    <h3 className={css(s.topic)}>Уровень знания языка</h3>

                    <div className="is-flex">
                        {LanguageLevels.map((e) => (
                            <span
                                key={uuid()}
                                onClick={() => {
                                    setFieldValue(
                                        'languageLevel', { ...langs, [index]: { ...langs[index], level: e.level } },
                                    )
                                }}
                                className={cn(
                                    css(s.lvl), { [css(s.selected)]: langs[index] && langs[index].level === e.level },
                                )}>
                                {e.level}
                            </span>
                        ))}
                    </div>
                </div>
            </div>

            {index !== count && <div className={css(s.horizontalLine)} />}
        </>
    )
}

function MemoizedComponent({ setFieldValue, values }) {
    const [availableLanguages, setAvailableLanguages] = useState(AvailableLanguages)
    const [removeMode, setRemoveMode] = useState(false)
    const langs = values.languageLevel
    const langCount = Object.values(langs).length

    const onRemove = async (index) => {
        const { [index]: removedLang, ...clearedLangs } = langs
        await setFieldValue('languageLevel', clearedLangs)

        if (Object.keys(clearedLangs).length <= 1) setRemoveMode(false)
    }

    useEffect(() => {
        const langCodesSet = new Set(Object.values(langs).map((l) => l.lang))
        setAvailableLanguages(AvailableLanguages.filter((e) => !langCodesSet.has(e.short)))
    }, [langs])

    return (
        <div className={css(s.box)}>
            <div className={cn('justify-between', css(s.formName))}>
                <div className="align-center gap-2">
                    <Language />

                    <h3>Знание языков</h3>
                </div>

                <div className="align-center gap-6">
                    {langCount > 1 && (
                        <EditSizeL onClick={() => setRemoveMode(!removeMode)} className="pointer dis_sl" />
                    )}

                    <PlusRoundedSizeL
                        className="pointer dis_sl"
                        onClick={() => setFieldValue(
                            'languageLevel',
                            { ...langs, [langCount + 1]: { lang: null, level: null } },
                        )} />
                </div>
            </div>

            <div className={css(s.block)}>
                {Object.keys(langs).map((langKey) => (
                    <LanguageBlock
                        key={+langKey}
                        index={+langKey}
                        langs={langs}
                        count={langCount}
                        onRemove={onRemove}
                        removeMode={removeMode}
                        setFieldValue={setFieldValue}
                        availableLanguages={availableLanguages}
                    />
                ))}
            </div>
        </div>
    )
}

export const LanguagesForm = memo(MemoizedComponent)

const s = StyleSheet.create({
    box: {
        background: COLORS.white,
        border: `1px solid ${COLORS.smoothGray}`,
        boxShadow: '0px 2px 4px rgba(130, 130, 130, 0.05)',
        borderRadius: 4,
    },
    formName: {
        height: 'calc(3rem - 1px)',
        padding: '0 22px 0 24px',
        fontSize: 13,
        fontWeight: '600',
        color: COLORS.dark,
        borderBottom: `1px solid ${COLORS.lightGray}`,
        textTransform: 'uppercase',
        ':nth-child(1n) > :last-child': {
            ':nth-child(1n) > *': {
                ':hover': {
                    ':nth-child(1n) > *': {
                        stroke: COLORS.skyblue,
                    },
                },
            },
        },
    },
    inpGr: {
        display: 'flex',
        flexDirection: 'column',
        gridGap: 16,
        position: 'relative',
        border: '1px solid',
        borderColor: 'transparent',
        borderRadius: 4,
    },
    removable_field: {
        borderColor: COLORS.lightRed,
        ':nth-child(1n) > :first-child': {
            cursor: 'pointer',
            position: 'absolute',
            right: -12,
            top: -12,
        },
    },
    horizontalLine: {
        height: 1,
        width: '100%',
        backgroundColor: '#EDEDEE',
    },
    lb: {
        fontSize: 12,
        fontWeight: '500',
        color: COLORS.midGray,
    },
    lab: {
        ':nth-child(1n) > span': {
            display: 'inline-flex',
        },
    },
    block: {
        padding: '16px 24px 24px',
        display: 'flex',
        flexDirection: 'column',
        gap: 16,
    },
    lvl: {
        background: COLORS.white,
        border: 'solid rgba(0, 0, 0, 0.15)',
        borderTopWidth: 1,
        borderBottomWidth: 1,
        borderLeftWidth: 0.5,
        borderRightWidth: 0.5,
        padding: '11px 17px',
        fontWeight: '500',
        fontSize: 14,
        color: COLORS.lightGray,
        cursor: 'pointer',
        ':hover': {
            background: COLORS.smoothGray,
            color: COLORS.gray,
        },
        ':first-of-type': {
            borderLeftWidth: 1,
            borderRadius: '3px 0 0 3px',
        },
        ':last-of-type': {
            borderRightWidth: 1,
            borderRadius: '0 3px 3px 0',
        },
    },
    selected: {
        background: `linear-gradient(180deg, ${COLORS.mainColor} 0%, ${COLORS.lightMainColor} 100%)`,
        borderRight: '1px solid rgba(0, 0, 0, 0.15)',
        pointerEvents: 'none',
        color: COLORS.white,
        backgroundClip: 'padding-box',
    },
    tooltip: {
        left: '-115%',
        ':nth-child(1n) > :last-child': {
            left: '40%',
        },
    },
    topic: {
        fontWeight: '500',
        fontSize: 12,
        color: COLORS.midGray,
    },
})
