import cn from 'classnames'
import { css, StyleSheet } from 'aphrodite'
import React, { useEffect, useRef } from 'react'
import { InputFromTo } from '../common/InputFromTo'
import { COLORS } from '../../utils/colors'

export function RangeSection({ name, setFilter, filter }) {
    const innerRef = useRef()

    const onChange = (event, type) => {
        const value = event.target.value.replace(/\s/g, '')

        if (type === 'from') {
            setFilter({ ...filter, [name]: `${value}:${filter[name] ? filter[name].split(':')[1] : ''}` })
        } else if (type === 'to') {
            setFilter({ ...filter, [name]: `${filter[name] ? filter[name].split(':')[0] : ''}:${value}` })
        }
    }

    useEffect(() => {
        if (!filter[name]) innerRef.current.clearValue()
    }, [filter[name]])

    return (
        <InputFromTo
            ref={innerRef}
            name={name}
            defaultValue={filter[name] ? [filter[name].split(':')[0], filter[name].split(':')[1]] : ['', '']}
            placeholder={['от', 'до']}
            className={cn(css(s.rangeInput))}
            onChange={onChange}
        />
    )
}

const s = StyleSheet.create({
    rangeInput: {
        height: 34,
        padding: '8px 16px',
        boxSizing: 'border-box',
        display: 'flex',
        alignItems: 'center',
        ':nth-child(1n) > :first-child': {
            ':nth-child(1n) > *': {
                display: 'flex',
                alignItems: 'center',
                ':nth-child(1n) > h3': {
                    fontSize: 10,
                    fontColor: COLORS.gray,
                },
            },
        },
    },
})
