import React from 'react'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import { COLORS } from '../../../utils/colors'
import { DescendingNumber } from '../../svgs/Svgs'

export default function VacancyTableHeader() {
    return (
        <thead className={cn(css(s.tableHead))}>
            <tr>
                <th className={css(s.item, s.nm)}>
                    №
                </th>

                <th className={css(s.item, s.name)}>
                    Вакансия
                </th>

                <th className={css(s.item, s.creator)}>
                    Создатель
                </th>

                <th className={css(s.item, s.recruiter)}>
                    Рекрутер
                </th>

                <th className={css(s.item, s.responsible)}>
                    Ответственный
                </th>

                <th className={css(s.item, s.created)}>
                    <div className="align-center gap-2">
                        <h3>Создано</h3> <DescendingNumber className="pointer" />
                    </div>
                </th>

                <th className={css(s.item, s.date)}>
                    Дата начала и окончания
                </th>

                <th className={css(s.item, s.salary)}>
                    Зарплатная вилка
                </th>

                <th className={css(s.item, s.accept)}>
                    Нанято
                </th>
            </tr>
        </thead>
    )
}

const s = StyleSheet.create({
    tableHead: {
        position: 'sticky',
        top: 0,
        textTransform: 'uppercase',
        ':nth-child(1n) > :first-child': {
            background: '#EAF4F0',
            height: 36,
        },
    },
    item: {
        fontSize: 13,
        color: COLORS.midGray,
        fontWeight: '500',
        textAlign: 'start',
        boxSizing: 'border-box',
        ':first-of-type': {
            textAlign: 'center',
        },
        ':not(:first-of-type)': {
            paddingLeft: 12,
        },
        ':not(:last-of-type)': {
            borderRight: `1px solid ${COLORS.lightGray}`,
        },
    },
    nm: {
        width: '3%',
    },
    name: {
        width: '12%',
    },
    creator: {
        width: '15%',
    },
    recruiter: {
        width: '15%',
    },
    responsible: {
        width: '15%',
    },
    created: {
        width: '6%',
    },
    date: {
        width: '14%',
    },
    salary: {
        width: '14%',
    },
    accept: {
        width: '6%',
    },
})
