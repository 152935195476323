import { Reject } from '../components/candidates/reject/Reject'
import { Accept } from '../components/candidates/accept/Accept'
import { Meetup } from '../components/candidates/meetup/Meetup'
import { Offer } from '../components/candidates/offer/Offer'

export const CandidateActionTypes = {
    reject: Reject,
    accept: Accept,
    meetup: Meetup,
    offer: Offer,
}
