import React, { forwardRef, useImperativeHandle, useState } from 'react'
import { COLORS } from '../../utils/colors'

export const CountdownTimer = forwardRef(({ seconds, size, onClick }, ref) => {
    const [color, setColor] = useState({ btn: COLORS.lightGray, border: 'rgba(0, 0, 0, 0.4)' })
    const milliseconds = seconds * 1000
    const circumference = size * 3
    const [countdown, setCountdown] = useState(milliseconds)
    const [isPlaying, setIsPlaying] = useState(false)
    let count = milliseconds

    function strokeDashoffset() {
        return -(circumference - (countdown / milliseconds) * circumference)
    }

    function startTimer() {
        setIsPlaying(true)
        setColor({
            btn: COLORS.lightGray,
            border: 'rgba(0, 0, 0, 0.4)',
        })

        const interval = setInterval(() => {
            setCountdown((prev) => prev - 10)
            count -= 10

            if (count === 0) {
                clearInterval(interval)
                setIsPlaying(false)
                setCountdown(milliseconds)
                setColor({ border: 'url(#gradient)', btn: 'url(#gradient)' })
            }
        }, 10)
    }

    const countdownSizeStyles = isPlaying
        ? { cursor: 'not-allowed' }
        : {
            cursor: 'pointer',
            boxShadow: '0px 4px 12px rgba(44, 67, 50, 0.1), '
                + 'inset 0px -1px 0px rgba(0, 0, 0, 0.25), inset 0px 1px 0px rgba(255, 255, 255, 0.1)',
        }

    useImperativeHandle(ref, () => ({
        onStart() { startTimer() },
    }))

    return (
        <div
            onClick={() => { if (isPlaying) return; startTimer(); onClick() }}
            style={({ ...styles.countdownContainer, ...countdownSizeStyles })}>
            <p style={{ ...styles.textStyles, ...{ color: isPlaying ? COLORS.lightBlack : COLORS.white } }}>
                Отправить заново
            </p>

            <svg style={{ ...styles.svg,
                ...{
                    boxShadow: !isPlaying ? '0px 4px 12px rgba(44, 67, 50, 0.1)'
                        + 'inset 0px -1px 0px rgba(0, 0, 0, 0.25), inset 0px 1px 0px rgba(255, 255, 255, 0.1)' : 'none',
                } }}>
                <linearGradient id="gradient" gradientTransform="rotate(180)">
                    <stop offset="0%" stopColor={COLORS.mainColor} />
                    <stop offset="100%" stopColor={COLORS.lightMainColor} />
                </linearGradient>

                <rect
                    width="100%"
                    height="40"
                    rx={4}
                    ry={4}
                    r={4}
                    fill={color.btn}
                    stroke={color.btn}
                    strokeWidth={2}
                />
            </svg>

            <svg style={styles.svg}>
                <linearGradient id="gradient">
                    <stop offset="0%" stopColor={COLORS.mainColor} />
                    <stop offset="100%" stopColor={COLORS.lightMainColor} />
                </linearGradient>

                <rect
                    width="100%"
                    height="40"
                    strokeDasharray={circumference}
                    strokeDashoffset={isPlaying ? strokeDashoffset() : 0}
                    r={4}
                    rx={4}
                    ry={4}
                    fill="none"
                    strokeLinecap="round"
                    stroke={color.border}
                    strokeWidth={2}
                />
            </svg>
        </div>
    )
})

const styles = {
    countdownContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        margin: 'auto',
        height: 40,
        width: 395,
    },
    textStyles: {
        fontSize: 14,
        fontWeight: '500',
        zIndex: 1,
    },
    svg: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        transform: 'rotateX(0deg)',
        overflow: 'visible',
    },
    button: {
        fontSize: 16,
        padding: '15px 40px',
        margin: '10px auto 30px',
        display: 'block',
        backgroundColor: '#4d4d4d',
        color: 'lightgray',
        border: 'none',
        cursor: 'pointer',
        outline: 0,
    },
}
