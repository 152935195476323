import React, { useRef, useState } from 'react'
import cn from 'classnames'
import { Field, getIn, useFormikContext } from 'formik'
import { css, StyleSheet } from 'aphrodite'
import { isEmpty } from 'lodash'
import { Calendar as CalendarIcon } from '../svgs/Svgs'
import { COLORS } from '../../utils/colors'
import { CalendarPopup } from './CalendarPopup'
import { useOutsideDetector } from '../../hooks/outsideDetector'

export default function InputDate({
    isMulti,
    name,
    help,
    icon,
    label,
    loading,
    onInput,
    validate,
    position,
    mainName,
    onChange,
    nameSplit,
    className,
    staticText,
    placeholder,
    withOptions,
    fieldClassName,
    labelClassName,
    noRequiredValidation,
    initialSelectedOption,
    ...attributes
}) {
    const fromRef = useRef()
    const toRef = useRef()
    const contRef = useRef()
    const [showCalendar, setShowCalendar] = useState(false)
    const { touched, errors, setFieldValue, values } = useFormikContext()

    useOutsideDetector(contRef, (event, isOutside) => {
        if (isOutside) {
            setShowCalendar(false)
        }
    })
    const isRequired = typeof validate === 'function' && !noRequiredValidation

    const isValid = isMulti
        ? (getIn(errors, name[0]) && getIn(touched, name[0])) || (getIn(errors, name[1]) && getIn(touched, name[1]))
        : getIn(errors, name) && getIn(touched, name)

    const resultObj = nameSplit && values[nameSplit.name]

    const result = resultObj
        ? Array.isArray(resultObj) ? values[nameSplit.name][nameSplit.count] : values[nameSplit.name]
        : null

    const selectedDays = nameSplit && result && {
        from: result[nameSplit.keys[0]] ? new Date(result[nameSplit.keys[0]]) : undefined,
        to: result[nameSplit.keys[1]] ? new Date(result[nameSplit.keys[1]]) : undefined,
    }

    const min = '1900-01-01'
    const max = '2099-12-31'

    // const selectedDay = !isMulti
    //     ? !isEmpty(values[name]) ? new Date(values[name]) : null
    //     : (selectedDays || null)

    // const handleChange = (e, inputName) => {
    //     const { value } = e.target
    //     setFieldValue(inputName, value)
    //     if (max > value > min) {
    //         setSelected(new Date(value))
    //     }
    //     // setFieldValue(inputName, max > value > min ? value : value > max ? max : min)
    // }

    return (
        <div ref={contRef} className={cn(css(s.formField), fieldClassName)}>
            <div className={cn('control', css(s.field))}>
                {label ? (
                    <label className={css(s.lb, labelClassName)} htmlFor={name}>
                        {label} {isRequired && (
                            <span style={{ color: isValid ? COLORS.lightRed : COLORS.midGray }}>
                                *
                            </span>
                        )}
                    </label>
                ) : null}

                <div
                    onClick={() => (
                        isMulti && fromRef.current.value.length > 0 ? toRef.current.focus() : fromRef.current.focus()
                    )}
                    className={cn(
                        'form-inp_pl ',
                        css(s.cont, isValid && s.dng, className),
                    )}
                >
                    <div className="align-start">
                        <Field
                            type="date"
                            min={min}
                            max={max}
                            innerRef={fromRef}
                            validate={validate}
                            placeholder="дд.мм.гггг"
                            id={isMulti ? name[0] : name}
                            name={isMulti ? name[0] : name}
                            // onChange={(e) => handleChange(e, isMulti ? name[0] : name)}
                            onClick={(e) => e.stopPropagation()}
                            onFocus={() => {
                                setShowCalendar(true)
                            }}
                            className={cn('input', css(s.inp))}
                            {...attributes} />

                        {isMulti && (
                            <>
                                <div>-</div>

                                <Field
                                    onClick={(e) => e.stopPropagation()}
                                    min={min}
                                    max={max}
                                    type="date"
                                    innerRef={toRef}
                                    id={name[1]}
                                    name={name[1]}
                                    // onChange={(e) => handleChange(e, name[1])}
                                    onFocus={() => {
                                        setShowCalendar(true)
                                    }}
                                    validate={validate}
                                    className={cn('input', css(s.inp))}
                                    {...attributes} />
                            </>
                        )}
                    </div>

                    {icon || <CalendarIcon />}
                </div>

                {help && (!errors[name] || !touched[name]) ? (
                    <p className={css(s.formHint)}>{help}</p>
                ) : null}
            </div>

            {showCalendar && (
                <CalendarPopup
                    name={name}
                    withOptions={withOptions}
                    position={position}
                    isMulti={isMulti}
                    // selected={selected}
                    // setSelected={setSelected}
                    refValue={{ fromRef, toRef }}
                    selectedDay={!isMulti
                        ? !isEmpty(values[name]) ? new Date(values[name]) : null
                        : (selectedDays || null)}
                    setFieldValue={setFieldValue}
                    initialSelectedOption={initialSelectedOption}
                    onClose={() => setShowCalendar(false)}
                />
            )}
        </div>
    )
}

const s = StyleSheet.create({
    formField: {
        position: 'relative',
    },
    field: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
    },
    lb: {
        fontSize: 12,
        fontWeight: '500',
        color: COLORS.midGray,
        marginBottom: 8,
    },
    cont: {
        border: `1px solid ${COLORS.lightGray}`,
        borderRadius: 4,
        height: 42,
        padding: '0 9px 0 16px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        ':nth-child(1n) > :first-child': {
            gap: '0.25rem',
            ':nth-child(1n) > *': {
                cursor: 'text',
            },
        },
    },
    inp: {
        outline: 'none',
        border: 'none',
        width: '100%',
        overflow: 'hidden',
        padding: 0,
        fontSize: 14,
        fontWeight: '500',
        color: COLORS.dark,
        '::placeholder': {
            color: COLORS.gray,
        },
        fontFamily: 'Roboto, sans-serif',
    },
    icon: {
        paddingRight: 46,
    },
    dng: {
        borderColor: COLORS.lightRed,
    },
    formHint: {
        marginTop: 4,
        fontSize: 12,
        fontWeight: '400',
        color: COLORS.lightBlack,
    },
})
