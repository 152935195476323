import React, { useState } from 'react'
import { css, StyleSheet } from 'aphrodite'
import { Form, Formik } from 'formik'
import cn from 'classnames'
import { isEmpty } from 'lodash'
import { useNavigate } from 'react-router-dom'
import LayoutRegister from '../../components/common/LayoutRegister'
import { Eye, EyeClose } from '../../components/svgs/Svgs'
import FormInput from '../../components/common/FormInput'
import { email as emailValidator, password as passwordValidator, required, validator } from '../../utils/validators'
import { usePostRequest } from '../../hooks/request'
import { useMessage } from '../../hooks/message'
import { REGISTRATION } from '../../urls'
import { COLORS } from '../../utils/colors'
import Button from '../../components/common/Button'

export default function Registration() {
    const navigate = useNavigate()
    const [showMessage] = useMessage()
    // const inputRef = useRef(null)
    // const [img, setImg] = useState('')
    const registration = usePostRequest({ url: REGISTRATION })
    const [passGroup, setPassGroup] = useState({ password: false, confirmPassword: false })

    async function onSubmit(data, helpers) {
        const { setFieldError } = helpers

        if (data.password !== data.confirmPassword) return setFieldError('confirmPassword', 'Пароли не совпадают')

        const { success, error } = await registration.request({ data })

        if (success) {
            localStorage.setItem('user', JSON.stringify(
                { firstName: data.firstName, lastName: data.lastName, username: data.username },
            ))

            showMessage('Ссылка отправлен на вашу почту', 'success-msg')
            return navigate('/confirmation/recruiter', { state: { isAllowed: true } })
        }

        const errorText = error.data.errors[0].message || error.data.message || 'ERROR'
        return showMessage(errorText, 'error-msg')
    }

    const icons = (name) => (passGroup[name] ? (
        <Eye pointer onClick={() => setPassGroup((prev) => ({ ...prev, [name]: false }))} />
    ) : (
        <EyeClose pointer onClick={() => setPassGroup((prev) => ({ ...prev, [name]: true }))} />
    ))

    return (
        <LayoutRegister>
            <div className={cn('is-flex direction-column gap-7', css(s.formWrap))}>
                <h3 className={css(s.regTxt)}>Регистрация пользователя</h3>

                <Formik
                    onSubmit={(data, helpers) => onSubmit(data, helpers)}
                    initialValues={{ firstName: '', lastName: '', username: '', password: '', confirmPassword: '' }}>
                    {({ errors, values }) => (
                        <React.Fragment>
                            <Form>
                                {/* {!img ? ( */}
                                {/*     <span onClick={() => inputRef.current.click()} className={css(s.upload)}> */}
                                {/*         <UploadPhoto /> */}

                                {/*         <h3>Загрузить мою фотографию</h3> */}
                                {/*     </span> */}
                                {/* ) : ( */}
                                {/*     <div className={css(s.uploadCont)}> */}
                                {/*         <img */}
                                {/*             src={img} */}
                                {/*             alt="#avatar" */}
                                {/*             className={css(s.uplImg)} /> */}

                                {/*         <div className={css(s.btn_group)}> */}
                                {/*             <Button onClick={() => inputRef.current.click()}> */}
                                {/*                 Отправить другую фотографию */}
                                {/*             </Button> */}

                                {/*             <Button onClick={() => { inputRef.current.value = ''; setImg('') }}> */}
                                {/*                 <Trash /> Удалить фотографию */}
                                {/*             </Button> */}
                                {/*         </div> */}
                                {/*     </div> */}
                                {/* )} */}

                                <FormInput
                                    label="Имя"
                                    name="firstName"
                                    validate={required}
                                    placeholder="Введите имя"
                                    labelClassName={css(s.lab)}
                                    fieldClassName={css(s.field)} />

                                <FormInput
                                    name="lastName"
                                    label="Фамилия"
                                    validate={required}
                                    labelClassName={css(s.lab)}
                                    placeholder="Введите фамилию"
                                    fieldClassName={css(s.field)} />

                                <FormInput
                                    name="username"
                                    label="Электронная почта"
                                    labelClassName={css(s.lab)}
                                    fieldClassName={css(s.field)}
                                    placeholder="Например “abcdef@gmail.com”"
                                    validate={validator(required, emailValidator)} />

                                <FormInput
                                    label="Пароль"
                                    name="password"
                                    labelClassName={css(s.lab)}
                                    validate={passwordValidator}
                                    icon={icons('password')}
                                    placeholder="Введите"
                                    fieldClassName={cn(css(s.field), 'dis_sl')}
                                    type={passGroup.password ? 'text' : 'password'}
                                    help="Пароль должен быть от 8 символов и включать буквы и цифры" />

                                <FormInput
                                    validate={required}
                                    name="confirmPassword"
                                    label="Повторите пароль"
                                    labelClassName={css(s.lab)}
                                    placeholder="Введите"
                                    icon={icons('confirmPassword')}
                                    fieldClassName={cn(css(s.field), 'dis_sl')}
                                    type={passGroup.confirmPassword ? 'text' : 'password'} />

                                <h3 className={css(s.term)}>
                                    Регистрируясь в системе, я принимаю {' '}

                                    <a href={process.env.REACT_APP_PRIVACY_POLICY} target="_blank" rel="noreferrer">
                                        Пользовательское соглашение
                                    </a>
                                </h3>

                                <Button
                                    loaderColor={COLORS.white}
                                    loaderWidth={20}
                                    strokeWidth={6}
                                    loading={registration.loading}
                                    type="submit"
                                    className={cn(
                                        css(s.btn),
                                        { [css(s.sbmBtn)]:
                                            isEmpty(errors) && Object.values(values).every((e) => e !== '') },
                                    )}
                                >
                                    Создать профиль
                                </Button>

                                {/* <input */}
                                {/*     id="file" */}
                                {/*     type="file" */}
                                {/*     ref={inputRef} */}
                                {/*     accept="image/*" */}
                                {/*     readOnly="readonly" */}
                                {/*     className={css(s.up_inp)} */}
                                {/*     onChange={(e) => (e.target.files[0] */}
                                {/*         ? setImg(URL.createObjectURL(e.target.files[0])) */}
                                {/*         : '' */}
                                {/*     )} /> */}
                            </Form>
                        </React.Fragment>
                    )}
                </Formik>
            </div>
        </LayoutRegister>
    )
}

const s = StyleSheet.create({
    upload: {
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        marginTop: 13,
        marginBottom: 25,
        fontSize: 12,
        fontWeight: '500',
        color: COLORS.skyblue,
        ':nth-child(1n) > :last-child': {
            letterSpacing: -0.5,
            marginLeft: 8,
        },
    },
    regTxt: {
        fontSize: 36,
        fontWeight: '600',
        color: '#181A19',
    },
    lab: {
        fontSize: 12,
        fontWeight: '500',
        color: COLORS.midGray,
        marginBottom: 8,
    },
    formWrap: {
        marginTop: 20,
        maxWidth: '50%',
        marginRight: 32,
        paddingBottom: 20,
        marginLeft: '19.5%',
        ':nth-child(1n) > form': {
            width: '80%',
            display: 'flex',
            flexDirection: 'column',
            // gap: '1.5rem',
        },
    },
    field: {
        position: 'relative',
        paddingBottom: '1.5rem',
    },
    btn: {
        border: 'none',
        padding: 0,
        outline: 'none',
        width: '100%',
        height: 40,
        background: COLORS.gray,
        borderRadius: 4,
        fontWeight: '500',
        fontSize: 14,
        color: '#FFF',
        marginTop: 12,
        cursor: 'not-allowed',
        boxShadow: '0 4px 12px rgba(44, 67, 50, 0.1), inset 0px -1px 0px rgba(0, 0, 0, 0.25), '
            + 'inset 0px 1px 0px rgba(255, 255, 255, 0.1)',
    },
    sbmBtn: {
        cursor: 'pointer',
        background: `linear-gradient(180deg, #2EA97D 0%, ${COLORS.lightMainColor} 100%)`,
        boxShadow: '0 4px 12px rgba(44, 67, 50, 0.1), inset 0px -1px 0px rgba(0, 0, 0, 0.25), '
            + 'inset 0px 1px 0px rgba(255, 255, 255, 0.1)',
        ':hover': {
            background: `linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), linear-gradient(180deg, #2EA97D 0%, ${COLORS.lightMainColor} 100%)`, // eslint-disable-line
            boxShadow: '0px 2px 4px rgba(130, 130, 130, 0.2), inset 0px -1px 0px rgba(0, 0, 0, 0.25), inset 0px 1px 0px rgba(255, 255, 255, 0.1)', // eslint-disable-line
        },
    },
    term: {
        letterSpacing: -0.4,
        color: COLORS.gray,
        fontSize: 11,
        fontWeight: '400',
        ':nth-child(1n) > :first-child': {
            fontWeight: '500',
            fontSize: 11,
            color: COLORS.skyblue,
            cursor: 'pointer',
            ':hover ': {
                textDecoration: 'underline',
            },
        },
    },
    up_inp: {
        display: 'none',
    },
    uploadCont: {
        margin: '32px 0',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    uplImg: {
        width: 96,
        height: 96,
        border: `1px solid ${COLORS.lightGray}`,
        borderRadius: 8,
    },
    btn_group: {
        ':nth-child(1n) > :first-child': {
            padding: '12px 16px',
            color: '#2EA97D',
            fontSize: 14,
            fontWeight: '500',
            border: `1px solid ${COLORS.smoothGray}`,
            background: COLORS.mintCream,
            boxShadow: '0 4px 20px rgba(44, 67, 50, 0.05)',
            borderRadius: 4,
            height: 38,
        },
        ':nth-child(1n) > :last-child': {
            marginTop: 12,
            padding: '12px 15px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            border: `1px solid ${COLORS.gray}`,
            background: 'transparent',
            filter: 'drop-shadow(0px 4px 12px rgba(44, 67, 50, 0.1))',
            borderRadius: 4,
            color: COLORS.gray,
            fontSize: 13,
            fontWeight: '500',
            height: 38,
            ':nth-child(1n) > :first-child': {
                marginRight: 8,
            },
        },
    },
})
