import React from 'react'
import { css, StyleSheet } from 'aphrodite'
import { Form, Formik } from 'formik'
import { COLORS } from '../../utils/colors'
import FormInput from '../common/FormInput'
import { TrashSizeL } from '../svgs/Svgs'

export default function EmployeeArchive({ employee = {}, onClose, onSubmit }) {
    return (
        <div className={css(s.cont)}>
            <h3 className={css(s.title)}>Вы уверены, что хотите уволить сотрудника <span>{employee.name}?</span></h3>

            <div className={css(s.hrLine)} />

            <Formik initialValues={{ reason: '', id: employee.id }} onSubmit={onSubmit}>
                {() => (
                    <Form className="is-flex direction-column gap-5">
                        <FormInput
                            name="reason"
                            label="Причина увольнения"
                            placeholder="Введите причину"
                            labelClassName={css(s.label)}
                        />

                        <div className="justify-end gap-4">
                            <button
                                type="reset"
                                onClick={onClose}
                                className={css(s.cancelBtn, s.btn)}>Отмена
                            </button>

                            <button
                                type="submit"
                                className={css(s.confirmBtn, s.btn)}>
                                <TrashSizeL color={COLORS.white} />

                                <h3>Уволить</h3>
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>

        </div>
    )
}

const s = StyleSheet.create({
    cont: {
        gap: 20,
        display: 'flex',
        flexDirection: 'column',
        borderRadius: 8,
        padding: '20px 24px',
        background: COLORS.white,
    },
    title: {
        fontSize: 20,
        fontWeight: '500',
        color: COLORS.lightBlack,
        ':nth-child(1n) > :first-child': {
            fontWeight: '700',
            color: COLORS.dark,
        },
    },
    hrLine: {
        height: 1,
        background: '#C9D2CE',
        borderRadius: 12,
    },
    label: {
        fontSize: 12,
        fontWeight: '500',
        marginBottom: 5,
        color: COLORS.midGray,
    },
    btn: {
        height: 40,
        fontSize: 14,
        borderRadius: 4,
        fontWeight: '500',
        border: '1px solid',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
    },
    cancelBtn: {
        color: COLORS.gray,
        padding: '0 16px',
        borderColor: COLORS.gray,
        background: 'transparent',
    },
    confirmBtn: {
        gap: 8,
        padding: '0 24px',
        color: COLORS.white,
        borderColor: COLORS.lightRed,
        background: COLORS.lightRed,
        boxShadow: '0px 4px 12px rgba(44, 67, 50, 0.1), inset 0px -1px 0px rgba(0, 0, 0, 0.25), '
            + 'inset 0px 1px 0px rgba(255, 255, 255, 0.1)',
    },
})
