import { useContext } from 'react'
import { isEmpty } from 'lodash'
import { Context } from '../contexts/GlobalContext'
import { ROLES } from './roles'
import { Permissions } from './permissions'

export function cp(permission) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { user } = useContext(Context)

    if (isEmpty(user) || user.loading) return null

    const role = ROLES[user.data.role]

    if (role === 'Owner') return true

    const permissionLevels = permission.split('.')
    let currentPermissions = Permissions[role]

    const checkPermission = permissionLevels[permissionLevels.length - 1]

    if (!currentPermissions) return false

    for (let i = 0; i < permissionLevels.length - 1; i++) {
        if (!currentPermissions[permissionLevels[i]]) return false
        currentPermissions = currentPermissions[permissionLevels[i]]
    }

    return currentPermissions && currentPermissions.includes(checkPermission)
}
