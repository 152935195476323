import React from 'react'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import Button from '../../common/Button'
import { COLORS } from '../../../utils/colors'
import { CloseSizeXL } from '../../svgs/Svgs'

export default function CantTransfer({ onClose }) {
    return (
        <div className={css(s.wrap)}>
            <div className="flex-cent">
                <div className={css(s.close)}>
                    <CloseSizeXL />
                </div>
            </div>

            <div className="is-flex direction-column gag-2">
                <h3 className={css(s.title)}>Вы не можете передать права владельца</h3>

                <p className={css(s.desc)}>
                    Для передачи плав владельца в компании должен быть хотя бы 1 администратор. Также,
                    вы не можете удалить аккаунт, не передав права владельца
                </p>
            </div>

            <Button onClick={onClose} className={cn('full-width', css(s.btn))}>
                Понятно
            </Button>
        </div>
    )
}

const s = StyleSheet.create({
    wrap: {
        background: COLORS.white,
        padding: '20px 24px',
        borderRadius: 4,
        gap: 16,
        display: 'flex',
        flexDirection: 'column',
        width: '37.25rem',
        textAlign: 'center',
    },
    title: {
        color: COLORS.dark,
        fontSize: 20,
        fontWeight: '700',
    },
    close: {
        display: 'inline-flex',
        padding: 12,
        borderRadius: 128,
        background: 'rgba(243, 59, 92, 0.10)',
        boxShadow: '0px 2px 4px 0px rgba(243, 59, 92, 0.20)',
    },
    desc: {
        color: '#343836',
        fontSize: 14,
        fontWeight: '400',
    },
    btn: {
        height: 40,
        border: `1px solid ${COLORS.lightGray}`,
        color: '#343836',
        fontSize: 14,
        fontWeight: 500,
    },
})
