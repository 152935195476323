import React from 'react'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import { useNavigate } from 'react-router-dom'
import { COLORS } from '../../utils/colors'
import Button from '../common/Button'
import { PlusRounded } from '../svgs/Svgs'
import { AddVacancies } from '../svgs/SvgPictures'

export default function NoVacancies() {
    const navigate = useNavigate()

    return (
        <div className={css(s.cont)}>
            <div className={cn('align-center', css(s.header))}>
                <h3 className={css(s.topic)}>0 вакансий</h3>
            </div>

            <div className={cn('flex-cent direction-column gap-7', css(s.body))}>
                <AddVacancies />

                <div className="is-flex direction-column gap-2">
                    <h3 className={cn('text-centered', css(s.topic))}>Вы еще не создали вакансии</h3>

                    <Button
                        className={css(s.btn)}
                        icon={<PlusRounded />}
                        onClick={() => navigate('/vacancies/form/create?mode=edit')}
                    >
                        Создать вакансию
                    </Button>
                </div>
            </div>
        </div>
    )
}

const s = StyleSheet.create({
    cont: {
        backgroundColor: COLORS.white,
    },
    header: {
        height: 40,
        boxSizing: 'border-box',
        borderRadius: '4px 4px 0px 0px',
        borderBottom: `1px solid${COLORS.lightGray}`,
        padding: '0 16px',
    },
    topic: {
        color: COLORS.dark,
        fontSize: 16,
        fontWeight: '600',
    },
    body: {
        padding: '24px 0 29px 0',
    },
    btn: {
        width: '18.375rem',
        height: 32,
        borderRadius: 4,
        background: COLORS.blue,
        // eslint-disable-next-line max-len
        boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.10) inset, 0px -1px 0px 0px rgba(0, 0, 0, 0.25) inset, 0px 4px 12px 0px rgba(44, 67, 50, 0.10)',
        color: COLORS.white,
        fontSize: 13,
        fontWeight: '500',
    },
})
