import React from 'react'
import cn from 'classnames'
import { css, StyleSheet } from 'aphrodite'
import { Form, Formik } from 'formik'
import { components } from 'react-select'
import Highlighter from 'react-highlight-words'
import { CheckSizeM } from '../../svgs/Svgs'
import Modal from '../../common/Modal'
import { useGetRequest, usePostRequest } from '../../../hooks/request'
import { ADMIN_LIST, TRANSFER_OWNER_ROLE } from '../../../urls'
import { useMessage } from '../../../hooks/message'
import { COLORS } from '../../../utils/colors'
import SelectForm from '../../common/SelectForm'
import { required } from '../../../utils/validators'
import Button from '../../common/Button'
import CantTransfer from './CantTransfer'
import LoadActions from './LoadActions'

function FormComponent({ setShowModal, admins }) {
    const transfer = usePostRequest({ url: TRANSFER_OWNER_ROLE })
    const [showMessage] = useMessage()

    const onSubmit = async (data) => {
        const { success, error } = await transfer.request({ data: {
            userId: data.userId.value,
        } })

        if (success) {
            setShowModal(false)
            showMessage('Ссылка отправлен на вашу почту', 'success-msg')
            return
        }

        showMessage(error.data.message || error.data.errors[0].message, 'error-msg')
    }

    const Option = (props) => {
        const { selectProps, data } = props
        const { label, department, position, photo, shortName } = data

        return (
            <components.Option {...props}>
                <div className="align-center gap-2" style={{ height: 52, boxSizing: 'border-box' }}>
                    {photo ? <img className={css(s.img)} src={photo} alt="#avatar" /> : (
                        <div className={cn('flex-cent', css(s.defaultPhoto))}>
                            <h3>{shortName}</h3>
                        </div>
                    )}

                    <div className="is-flex direction-column align-start justify-center">
                        <Highlighter
                            textToHighlight={label.slice(0, 25) + (label.length > 25 ? '...' : '')}
                            activeClassName="highlight"
                            searchWords={[selectProps.inputValue.trim()]}
                            className={css(s.optLab)}
                        />

                        <div className="align-center gap-1">
                            <h3 className={css(s.txt)}>
                                {department.slice(0, 15) + (department.length > 15 ? '...' : '')}
                            </h3>

                            <span>•</span>

                            <h3 className={css(s.txt)}>
                                {position.slice(0, 15) + (position.length > 15 ? '...' : '')}
                            </h3>
                        </div>
                    </div>
                </div>
            </components.Option>
        )
    }

    return (
        <Formik initialValues={{ userId: '' }} onSubmit={onSubmit}>
            {() => (
                <Form>
                    <div className={cn('is-flex direction-column', css(s.exitModal))}>
                        <div className={css(s.modalTxt)}>
                            <h3>Вы уверены, что хотите передать права владельца?</h3>

                            <p className={css(s.delInf)}>
                                Вы являетесь <b>владельцем</b> компании. Вы можете передать права владельца
                                <br /> другому администратору в компании
                            </p>
                        </div>

                        <div>
                            <SelectForm
                                placeholderStyles={{ color: COLORS.gray, fontFamily: 'Roboto, sans-serif' }}
                                valueContainerStyles={{ padding: '11.5px 16px' }}
                                label="Новый владелец компании"
                                validate={required}
                                optStyle={{ padding: '0 16px' }}
                                customComponents={{ Option }}
                                errorWithMessage={false}
                                placeholder="Выберите среди администраторов"
                                name="userId"
                                options={admins} />
                        </div>

                        <div className={cn('justify-end', css(s.btnGr))}>
                            <button
                                type="reset"
                                className={css(s.cancelBtn)}
                                onClick={() => setShowModal(false)}>
                                Отмена
                            </button>

                            <Button
                                iconRight
                                type="submit"
                                loaderWidth={24}
                                loading={transfer.loading}
                                loaderColor={COLORS.white}
                                strokeWidth={4}
                                icon={<CheckSizeM color={COLORS.white} />}
                                className={cn('flex-cent', css(s.delBtn))}
                            >
                                Подтвердить передачу
                            </Button>
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
    )
}

export default function Transfer({ showModal, setShowModal }) {
    const getAdmins = useGetRequest({ url: ADMIN_LIST })
    const admins = getAdmins.response ? getAdmins.response.data.map((e) => ({
        value: e.userId,
        photo: e.photo[4],
        position: e.position,
        department: e.department,
        label: `${e.firstName} ${e.lastName}`,
        shortName: e.firstName.slice(0, 1) + e.lastName.slice(0, 1),
    })) : []

    return !getAdmins.loading ? (
        <Modal
            open={showModal}
            onClose={() => setShowModal(false)}
            onOpen={() => getAdmins.request()}
            closeOnDocumentClick
            closeOnEscape>
            {admins.length
                ? (
                    <FormComponent admins={admins} setShowModal={setShowModal} />
                )
                : <CantTransfer onClose={() => setShowModal(false)} />}
        </Modal>
    ) : <LoadActions showModal={showModal} setShowModal={setShowModal} />
}

const s = StyleSheet.create({
    exitModal: {
        background: COLORS.white,
        padding: '20px 24px',
        borderRadius: 4,
        gap: 20,
        width: '37.25rem',
    },
    modalTxt: {
        color: COLORS.dark,
        fontSize: 20,
        fontWeight: '500',
        ':nth-child(1n) > :last-child': {
            marginTop: 8,
        },
    },
    btnGr: {
        gap: 16,
    },
    cancelBtn: {
        fontWeight: '500',
        color: COLORS.gray,
        padding: '11px 16px',
        border: `1px solid ${COLORS.gray}`,
        borderRadius: 4,
        background: 'transparent',
        cursor: 'pointer',
        outline: 'none',
    },
    delInf: {
        fontWeight: '400',
        color: COLORS.lightBlack,
        fontSize: 14,
    },
    delBtn: {
        cursor: 'pointer',
        outline: 'none',
        borderRadius: 4,
        gap: 8,
        width: 'auto',
        background: COLORS.lightRed,
        color: COLORS.white,
        border: 'none',
        padding: '9px 16px',
        ':hover': {
            background: `linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), ${COLORS.lightRed}`,
            boxShadow: '0px 2px 4px rgba(168, 168, 168, 0.15), inset 0px -1px 0px rgba(0, 0, 0, 0.25), inset 0px 1px 0px rgba(255, 255, 255, 0.1)', // eslint-disable-line
        },
    },
    img: {
        borderRadius: 32,
    },
    defaultPhoto: {
        background: '#1B93C0',
        width: 32,
        height: 32,
        borderRadius: 32,
        fontWeight: '600',
        fontSize: 12,
        color: '#FFFFFF',
        border: '1px solid transparent',
        textTransform: 'uppercase',
    },
    txt: {
        fontWeight: '400',
        fontSize: 11,
        color: COLORS.midGray,
    },
    optLab: {
        fontWeight: '500',
        fontSize: 13,
        color: COLORS.dark,
    },
})
