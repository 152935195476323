import React, { memo } from 'react'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import { COLORS } from '../../../utils/colors'
import FormInput from '../../common/FormInput'
import { ArrowNextSizeL, PlusRounded, TrashSizeL } from '../../svgs/Svgs'
import { required } from '../../../utils/validators'

const initialState = { key: '', value: '' }

function MemoizedComponent({ values, setFieldValue }) {
    const { options } = values

    const onRemove = (ind) => {
        options.splice(ind, 1)
        setFieldValue('options', [...options])
    }

    return (
        <div className="is-flex direction-column gap-3">
            <h3 className={css(s.title)}>
                Если у вас есть дополнительные критерии к кандидату, вы можете добавить
            </h3>

            {options.map((_, ind) => (
                <div className="align-center gap-7">
                    <div className="align-center gap-3 flex-1">
                        <FormInput
                            fieldClassName="flex-1"
                            placeholder="Название"
                            validate={options[ind].value && required}
                            name={`options[${ind}].key`}
                            errorWithMessage={false}
                        />

                        <span>
                            <ArrowNextSizeL />
                        </span>

                        <FormInput
                            fieldClassName="flex-1"
                            placeholder="Требование или информация"
                            validate={options[ind].key && required}
                            name={`options[${ind}].value`}
                            errorWithMessage={false}
                        />
                    </div>

                    <div
                        onClick={() => onRemove(ind)}
                        className={cn('flex-cent pointer', css(s.trash))}>
                        <TrashSizeL />
                    </div>
                </div>
            ))}

            <button
                className={cn('flex-cent gap-2 pointer', css(s.btn))}
                onClick={() => setFieldValue('options', [...options, initialState])}
            >
                <PlusRounded color={COLORS.mainColor} /> Добавить критерий
            </button>
        </div>
    )
}

export const AdditionalCriteria = memo(MemoizedComponent)

const s = StyleSheet.create({
    title: {
        color: COLORS.midGray,
        fontSize: 12,
        fontWeight: '500',
    },
    cont: {
        padding: '15px 1.5rem 1.5rem',
    },
    trash: {
        width: 44,
        height: 44,
        borderRadius: 4,
        background: COLORS.smoothGray,
        boxShadow: '0px 2px 4px 0px rgba(130, 130, 130, 0.05)',
        ':hover': {
            boxShadow: '0px 2px 4px 0px rgba(130, 130, 130, 0.1)',
        },
    },
    btn: {
        height: 40,
        gap: 8,
        borderRadius: 4,
        border: '1px solid #DAF1E9',
        background: COLORS.mintCream,
        boxShadow: '0px 2px 4px 0px rgba(130, 130, 130, 0.05)',
        color: COLORS.mainColor,
        fontSize: 14,
        fontWeight: '500',
        outline: 'none',
        ':hover': {
            boxShadow: '0px 2px 4px 0px rgba(130, 130, 130, 0.1)',
        },
    },
})
