import React from 'react'
import cn from 'classnames'
import { css, StyleSheet } from 'aphrodite'
import { COLORS } from '../../../utils/colors'
import { AvailableLanguages, LanguageLevels } from '../../../utils/options'

export default function Languages({ values }) {
    const langs = values.languageLevel

    return (
        <div className={cn('is-flex direction-column gap-5')}>
            <h3 className={css(s.name)}>Знание языков:</h3>

            <div className={cn('align-center gap-2')}>
                <ul className={cn('no_dot_list', css(s.lng))}>
                    {langs.slice(0, langs.length - 1).map((lang) => (
                        <li>
                            • {AvailableLanguages.find((e) => e.short === lang.language).ru} — {' '}

                            <span>
                                {lang.level} ({LanguageLevels.find((e) => e.level === lang.level).ru})
                            </span>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    )
}
const s = StyleSheet.create({
    name: {
        color: COLORS.dark,
        fontSize: 20,
        fontWeight: '600',
    },
    lng: {
        color: '#343836',
        fontSize: 14,
        fontWeight: '600',
        ':nth-child(1n) > *': {
            ':nth-child(1n) > span': {
                fontWeight: '500',
            },
        },
    },
})
