import React, { useCallback, useState } from 'react'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import { debounce } from 'lodash'
import { CheckSizeL, ChevronDownS, Clear, MagnifierSizeM } from '../svgs/Svgs'
import { Popup } from '../Popup'
import { COLORS } from '../../utils/colors'

function Sort({ title, name, sort, setSort }) {
    const ascending = `-${name}`
    const descending = name

    return (
        <Popup
            position="left"
            popupClassName={css(s.popup)}
            trigger={(
                <div className="align-center gap-2 pointer">
                    <h3>{title}</h3>
                    <ChevronDownS color={COLORS.midGray} />
                </div>
            )}>
            <div className={cn('is-flex direction-column gap-1', css(s.dropdown))}>
                <h3 className={css(s.sortTitle)}>Сортировать:</h3>

                <ul className="no_dot_list">
                    <li
                        className={css(s.sortItem, sort[name] === ascending && s.actTxt)}
                        onClick={() => setSort({ ...sort, [name]: sort[name] !== ascending ? ascending : '' })}>
                        <h3>По возрастанию</h3>

                        {sort[name] === ascending && <CheckSizeL />}
                    </li>

                    <li
                        className={css(s.sortItem, sort[name] === descending && s.actTxt)}
                        onClick={() => setSort({ ...sort, [name]: sort[name] !== descending ? descending : '' })}>
                        <h3>По убыванию</h3>

                        {sort[name] === descending && <CheckSizeL />}
                    </li>
                </ul>
            </div>
        </Popup>
    )
}

function Input({ name, placeholder, setKeyword }) {
    const [value, setValue] = useState('')

    const sendRequest = useCallback(
        debounce((val) => {
            setKeyword(val)
        }, 500),
        [],
    )

    const handleInputChange = useCallback(
        (event) => {
            const newValue = event.target.value
            setValue(newValue)
            sendRequest(newValue)
        },
        [sendRequest],
    )

    return (
        <div
            id={name}
            onClick={() => {
                document.querySelector(`#${name}`).classList.add(css(s.actSrBtn))
                document.querySelector(`#${name} input`).focus()
            }}>
            <MagnifierSizeM color={COLORS.mainColor} />

            <input
                id="srcInp"
                type="text"
                autoComplete="off"
                name={name}
                value={value}
                className={css(s.inp)}
                placeholder={placeholder}
                onChange={handleInputChange}
                onBlur={() => (
                    value.length === 0 ? document.querySelector(`#${name}`).classList.remove(css(s.actSrBtn)) : {}
                )}
            />

            {value.length > 0 && (
                <span className="flex-cent pointer">
                    <Clear onClick={() => {
                        setValue('')
                        setKeyword('')
                    }} />
                </span>
            )}
        </div>
    )
}

export default function CandidateTableHeader({ headerColumns }) {
    return (
        <thead className={cn(css(s.table_head))}>
            <tr>
                {headerColumns.map((item) => (item.isSearchable ? (
                    <th
                        key={item.id}
                        style={{ paddingRight: '3.375rem' }}
                        className={cn(css(s.item, s.srBtn, columnWidth(item.width)))}>
                        <Input
                            name={item.name}
                            setKeyword={item.setValue}
                            placeholder={item.placeholder}
                        />
                    </th>
                ) : item.isSortable ? (
                    <th key={item.id} className={css(s.item, columnWidth(item.width))}>
                        <Sort
                            name={item.name}
                            title={item.title}
                            sort={item.value}
                            setSort={item.setValue} />
                    </th>
                ) : (
                    <th key={item.id} className={css(s.item, columnWidth(item.width))}>
                        {item.name}
                    </th>
                )))}
            </tr>
        </thead>
    )
}

const s = StyleSheet.create({
    table_head: {
        height: 44,
        color: COLORS.midGray,
        fontSize: 13,
        textTransform: 'uppercase',
        ':nth-child(1n) > :first-child': {
            background: '#EAF4F0',
            ':nth-child(1n) > *': {
                fontWeight: '500',
                paddingTop: 7,
                paddingBottom: 7,
            },
            ':nth-child(1n) > :first-child': {
                borderRadius: '4px 0 0 0',
            },
            ':nth-child(1n) > :last-child': {
                borderRadius: '0 4px 0 0',
                paddingRight: '1.5rem',
            },
        },
    },
    inp: {
        fontSize: 13,
        fontWeight: '400',
        color: COLORS.midGray,
        outline: 'none',
        border: 'none',
        width: 'calc(100% - 46px)',
        backgroundColor: 'transparent',
        '::placeholder': {
            color: COLORS.midGray,
            fontSize: 13,
            fontWeight: '400',
        },
    },
    srBtn: {
        ':nth-child(1n) > :first-child': {
            display: 'flex',
            alignItems: 'center',
            gap: 8,
            // maxWidth: '30.25rem', // TODO make max-width
            height: '1.75rem',
            padding: '0 8px',
            border: '1px solid rgba(0, 0, 0, 0.06)',
            borderRadius: 4,
            ':nth-child(1n) > :last-child': {
                fontSize: 13,
                fontWeight: '400',
                color: COLORS.midGray,
                outline: 'none',
                border: 'none',
                backgroundColor: 'transparent',
            },
        },

    },
    chb: {
        border: '1px solid #898989 !important',
    },
    chk: {
        background: COLORS.white,
        border: `1px solid ${COLORS.mainColor}`,
    },
    actSrBtn: {
        backgroundColor: '#FFF',
        borderColor: COLORS.lightGray,
    },
    sortItem: {
        padding: '0 8px',
        height: 24,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        fontSize: 13,
        fontWeight: '400',
        color: '#A9ABAA',
        cursor: 'pointer',
        userSelect: 'none',
        ':not(:last-of-type)': {
            marginBottom: 4,
        },
        ':hover': {
            background: 'rgba(255, 255, 255, 0.05)',
            borderRadius: 4,
        },
    },
    actTxt: {
        color: COLORS.white,
    },
    dropdown: {
        maxHeight: '11.25rem',
        overflowY: 'auto',
        fontWeight: '500',
        fontSize: 12,
        textTransform: 'none',
        color: 'rgba(255, 255, 255, 0.8)',
        background: 'rgba(65, 70, 68, 0.9)',
        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.25)',
        backdropFilter: 'blur(8px)',
        borderRadius: 8,
        padding: '12px 8px',
        ':nth-child(1n) > ul': {
            width: '12.25rem',
        },
    },
    item: {
        ':first-of-type': {
            paddingRight: '1.5rem',
            paddingLeft: '1.5rem',
        },
        ':last-of-type': {
            textAlign: 'right',
        },
    },
    popup: {
        zIndex: 999,
    },
    sortTitle: {
        fontSize: 10,
        color: '#F7C11D',
        fontWeight: '500',
        textTransform: 'uppercase',
        padding: '0 8px',
    },
})

const columnWidth = (width) => StyleSheet.create({
    main: { width },
}).main
