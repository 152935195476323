import React, { memo, useCallback, useState } from 'react'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import { COLORS } from '../../../utils/colors'
import EditableContent from '../../common/EditableContent'
import { useGetRequest } from '../../../hooks/request'
import { KNOWLEDGE_LIST } from '../../../urls'

function MemoizedComponent({ values, setFieldValue }) {
    const { knowledge } = values
    const getKnowledge = useGetRequest({ url: KNOWLEDGE_LIST })
    const [knowledgeList, setKnowledgeList] = useState([])
    const [knowledgeCreating, setKnowledgeCreating] = useState(false)

    const selectedKn = knowledge.map((e) => e.id)

    const onRemove = (id) => {
        setFieldValue('knowledge', knowledge.filter((e) => e.id !== id))
    }

    const searchKnowledge = useCallback(async (text) => {
        const { response, success } = await getKnowledge.request({ params: { key: text } })
        if (success) setKnowledgeList(response.data.filter((e) => !selectedKn.includes(e.id)))
    }, [selectedKn])

    return (
        <div className="is-flex direction-column gap-3">
            <h3 className={css(s.title)}>
                Требования знаний для кандидата
            </h3>

            <div className={cn('align-center gap-5 is-flex-wrap', css(s.knowledge))}>
                {knowledge.map((item) => (
                    <EditableContent
                        data={knowledge}
                        dataItem={item}
                        setData={(e) => setFieldValue('knowledge', e)}
                        maxLength={100}
                        options={knowledgeList}
                        setOptions={setKnowledgeList}
                        onChange={searchKnowledge}
                        onRemove={onRemove}
                    />
                ))}

                {knowledgeCreating && (
                    <EditableContent
                        onChange={searchKnowledge}
                        data={knowledge}
                        options={knowledgeList}
                        setOptions={setKnowledgeList}
                        setCreating={setKnowledgeCreating}
                        creating={knowledgeCreating}
                        dataItem={{ id: '', name: '' }}
                        setData={(e) => {
                            setFieldValue('knowledge', e)
                        }}
                        maxLength={100}
                    />
                )}

                {!knowledgeCreating && (
                    <span className="pointer" onClick={() => setKnowledgeCreating(true)}>
                        <h3 className={css(s.add)}>+ Добавить навык</h3>
                    </span>
                )}
            </div>
        </div>
    )
}

export const Knowledge = memo(MemoizedComponent)

const s = StyleSheet.create({
    add: {
        color: COLORS.mainColor,
        fontSize: 14,
        fontWeight: '500',
    },
    knowledge: {
        minHeight: 28,
    },
    title: {
        color: COLORS.midGray,
        fontSize: 14,
        fontWeight: '500',
    },
})
