import React, { memo, useEffect, useRef } from 'react'
import { css, StyleSheet } from 'aphrodite'
import { Draggable } from 'react-beautiful-dnd'
import cn from 'classnames'
import { useNavigate } from 'react-router-dom'
import uuid from 'react-uuid'
import { Archive, EditPencil, File, ThreeDotsMenu } from '../svgs/Svgs'
import PopupSimple from '../common/PopupSimple'
import { COLORS } from '../../utils/colors'
import { cp } from '../../utils/checkPermission'

// const COLS = ['New', 'In Progress', 'Stopped', 'Closed']

function Menu({ id, navigate, onArchive }) {
    return (
        <div className={css(s.colsCont)}>
            {/* <div className={cn(css(s.colsHead), 'align-center')}> */}
            {/*     <h3>Перенести в:</h3> */}
            {/* </div> */}

            <ul className={css(s.cols)}>
                {/* {COLS.map((col, index) => ( */}
                {/*     <li */}
                {/*         // eslint-disable-next-line react/no-array-index-key */}
                {/*         key={index} */}
                {/*         className={cn('justify-between pointer')}> */}
                {/*         {col} */}
                {/*     </li> */}
                {/* ))} */}

                <li
                    onClick={() => navigate(`/vacancies/${id}/form/update?mode=edit`)}
                    className={cn('align-center pointer')}>
                    <EditPencil /> Редактировать
                </li>

                <li
                    onClick={() => onArchive(id)}
                    className={cn('align-center pointer')}>
                    <Archive /> В архив
                </li>
            </ul>
        </div>
    )
}

const MemoizedComponent = ({ data, index, listId, onArchive }) => {
    const ref = useRef()
    const navigate = useNavigate()

    const popupCloser = () => ref.current.close()

    useEffect(() => {
        const listNode = document.getElementById(listId)
        const contNode = document.getElementById('rp-c')

        listNode.addEventListener('scroll', popupCloser)
        contNode.addEventListener('scroll', popupCloser)

        return () => {
            listNode.removeEventListener('scroll', popupCloser)
            contNode.removeEventListener('scroll', popupCloser)
        }
    }, [])

    return (
        <React.Fragment>
            <Draggable
                index={index}
                key={data.id}
                draggableId={data.id.toString()}
                isDragDisabled={!cp('vacancies.drag_and_drop')}
            >
                {(provided, snapshot) => {
                    const left = snapshot.isDragging ? provided.draggableProps.style.left : 0
                    const itemStyle = {
                        ...provided.draggableProps.style,
                        transform: `translateX(${left}px)`,
                    }
                    return (
                        <div
                            style={itemStyle}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            onClick={() => navigate(`/vacancies/${data.id}/info`)}
                            className={cn(css(s.wrap), { [css(s.onDrag)]: snapshot.isDragging })}>
                            <div className="justify-between-start">
                                <div className="is-flex direction-column align-start">
                                    <h3 className={css(s.pos)}>{data.name}</h3>

                                    {data.department ? (
                                        <h3 className={css(s.wp)}>{data.department.name}</h3>
                                    ) : null}
                                </div>

                                <PopupSimple
                                    position="bottom center"
                                    innerRef={ref}
                                    contentStyle={{ marginTop: 10 }} // transform: 'translateX(-40%)'
                                    trigger={(
                                        <span className={cn('pointer', css(s.menuIcon))}>
                                            <ThreeDotsMenu />
                                        </span>
                                    )}>
                                    <Menu
                                        id={data.id}
                                        navigate={navigate}
                                        onArchive={onArchive} />
                                </PopupSimple>
                            </div>

                            {data.tags && (
                                <div className={cn('is-flex', css(s.stat))}>
                                    {data.tags.slice(0, 2).map((tag) => (
                                        <span
                                            title={tag.name}
                                            key={uuid()} // replace to tag.id after mvp
                                            className={cn('align-center gap-1 pointer', tagStyles(tag.color))}>
                                            {tag.name.length >= (data.tags.length > 1 ? 13 : 27)
                                                ? `${tag.name.slice(0, (data.tags.length > 1 ? 10 : 24))}...`
                                                : tag.name}
                                        </span>
                                    ))}

                                    {data.tags.length > 2 ? (
                                        <span className={cn('align-center gap-1 pointer', tagStyles(COLORS.midGray))}>
                                            + {data.tags.length - 2}
                                        </span>
                                    ) : null}
                                </div>
                            )}

                            <div className="justify-between" style={{ paddingTop: 8 }}>
                                {data.recruiter && (
                                    <div className={cn('align-center', css(s.rec))}>
                                        <div className={cn('flex-cent', css(s.defaultAvatar))}>
                                            <h3>
                                                {data.recruiter.firstName.slice(0, 1)}
                                                {data.recruiter.lastName.slice(0, 1)}
                                            </h3>
                                        </div>

                                        <div className="is-flex direction-column">
                                            <h3>Рекрутер</h3>

                                            <h3 className={css(s.name)}>
                                                {data.recruiter.firstName} {data.recruiter.lastName}
                                            </h3>
                                        </div>
                                    </div>
                                )}

                                <div className="align-center gap-1 justify-end flex-1">
                                    {/* <span className={css(s.st)}> */}
                                    {/*    <Candidates /> {data.candidates} */}
                                    {/* </span> */}

                                    <span className={css(s.st)}>
                                        <File /> {data.candidates || 0}
                                    </span>
                                </div>
                            </div>
                        </div>
                    )
                }}
            </Draggable>
        </React.Fragment>
    )
}

export const VacancyCard = memo(MemoizedComponent)

const s = StyleSheet.create({
    wrap: {
        overflowX: 'hidden',
        backgroundColor: '#fff',
        margin: '0 12px 12px',
        padding: 12,
        cursor: 'pointer',
        borderRadius: 4,
        border: `1px solid ${COLORS.smoothGray}`,
        boxShadow: '0px 2px 4px rgba(136, 136, 136, 0.25)',
        ':hover': {
            border: `1px solid ${COLORS.lightGray}`,
            boxShadow: '0px 2px 4px rgba(136, 136, 136, 0.35)',
        },
    },
    onDrag: {
        background: 'rgba(255, 255, 255, 0.1)',
        border: `1px solid ${COLORS.skyblue}`,
        boxShadow: '0px 4px 16px rgba(0, 113, 218, 0.4)',
        backdropFilter: 'blur(4px)',
    },
    pos: {
        fontSize: 14,
        fontWeight: '600',
        color: COLORS.dark,
        overflowWrap: 'anywhere',
    },
    menuIcon: {
        width: 20,
        height: 20,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    wp: {
        fontSize: 12,
        fontWeight: '400',
        color: COLORS.midGray,
        lineHeight: 1.2,
        overflowWrap: 'anywhere',
    },
    st: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: 11,
        gap: 2,
        fontWeight: '500',
        color: COLORS.lightBlack,
        backgroundColor: COLORS.mintCream,
        border: '1px solid #ebf3ef',
        borderRadius: 4,
        height: 16,
        padding: '4px',
    },
    rec: {
        fontSize: 11,
        fontWeight: '400',
        color: COLORS.gray,
        lineHeight: 1.2,
        gap: '0.25rem',
    },
    name: {
        lineHeight: 1.2,
        fontSize: 12,
        fontWeight: '500',
        color: COLORS.lightBlack,
    },
    stat: {
        paddingTop: 8,
        paddingBottom: 6,
        gap: 6,
    },
    cardSetting: {
        width: '8.75rem',
        right: 12,
        ':nth-child(1n) > :first-child': {
            ':nth-child(1n) > :first-child': {
                marginRight: 8,
            },
        },
    },
    colsCont: {
        width: '15.25rem',
        borderRadius: 4,
        backgroundColor: '#414644E5',
    },
    colsHead: {
        color: '#F6C11D',
        fontSize: 10,
        fontWeight: '500',
        textTransform: 'uppercase',
        padding: '8px 12px',
    },
    cols: {
        ':nth-child(1n) > *': {
            color: '#FFFFFF',
            height: 40,
            padding: '0 12px',
            fontSize: 12,
            borderBottom: '1px solid rgba(99, 99, 99, 0.9)',
            fontWeight: '500',
            ':nth-child(1n) > :first-child': {
                marginRight: 8,
            },
            ':hover': {
                backgroundColor: 'rgba(65,70,68,0.6)',
            },
        },
        ':nth-child(1n) > :last-child': {
            borderBottom: 'none',
            borderRadius: '0 0 5px 5px',
        },
    },
    located: {
        color: COLORS.white,
    },
    defaultAvatar: {
        background: '#1B93C0',
        width: 24,
        height: 24,
        borderRadius: 32,
        fontWeight: '500',
        fontSize: 11,
        color: '#FFFFFF',
        textTransform: 'uppercase',
    },
})

const tagStyles = (color) => css(StyleSheet.create({
    main: {
        color,
        height: 'calc(1.5rem - 2px)',
        padding: '0 6px',
        borderRadius: 4,
        fontWeight: '500',
        fontSize: 11,
        whiteSpace: 'nowrap',
        outline: 'none',
        border: `1px solid ${color + 33}`,
        background: `${color}1A`,
        ':nth-child(1n) > :first-child': {
            ':nth-child(1n) > :first-child': {
                ':nth-child(1n) > *': {
                    stroke: color,
                },
            },
        },
    },
}).main)
