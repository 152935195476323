import axios from 'axios'
import humps from 'humps'
import { USER_INFO } from '../urls'

export const domain = process.env.REACT_APP_BASE_URL || `${window.location.protocol}//${window.location.hostname}`
//  Add Base URL and change snake_case to camelCase
const baseAxios = axios.create({
    baseURL: `${domain}`,
    transformResponse: [
        ...axios.defaults.transformResponse,
        humps.camelizeKeys,
    ],
    transformRequest: [
        decamelize,
        ...axios.defaults.transformRequest,

    ],
})

baseAxios.interceptors.request.use((config) => ({
    ...config,
    params: humps.decamelizeKeys(config.params),
}))

export default baseAxios

// eslint-disable-next-line consistent-return
function decamelize(object) {
    if (!(object && !(object instanceof File))) return object

    if (object instanceof FormData) {
        const formData = new FormData()
        // eslint-disable-next-line no-restricted-syntax
        for (const [key, value] of object.entries()) {
            formData.append(humps.decamelize(key), value)
        }
        return formData
    }

    if (typeof object === 'object') {
        return humps.decamelizeKeys(object)
    }
}

export async function fetchUserInfo(setUser) {
    setUser({ data: {}, loading: true, checked: true })
    const { data } = await axios.get(`${domain + USER_INFO}`, {
        headers: {
            Authorization: `Bearer ${JSON.parse(localStorage.token)}`,
            'accept-language': 'ru',
        },
        transformResponse: [
            ...axios.defaults.transformResponse,
            humps.camelizeKeys,
        ],
    })

    if (data.success) {
        setUser({ ...data, loading: false, checked: true })
    }
}
