import { useEffect, useMemo, useState } from 'react'

export function isInViewport(el) {
    const rect = el.getBoundingClientRect()
    return (
        rect.top >= 0
        && rect.left >= 0
        && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight)
        && rect.right <= (window.innerWidth || document.documentElement.clientWidth)

    )
}

export function useIsInViewport(ref, threshold = 0) {
    const [isIntersecting, setIsIntersecting] = useState(false)

    const observer = useMemo(
        () => new IntersectionObserver(
            ([entry]) => setIsIntersecting(entry.isIntersecting),
            {
                threshold,
            },
        ),
        [],
    )

    useEffect(() => {
        observer.observe(ref.current)

        return () => {
            observer.disconnect()
        }
    }, [ref, observer])

    return isIntersecting
}
