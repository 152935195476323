import React from 'react'
import DepartmentForm from './DepartmentForm'
import { useMessage } from '../../../hooks/message'
import { usePutRequest } from '../../../hooks/request'
import { DEPARTMENT_UPDATE } from '../../../urls'
import SidebarLayout from '../../common/SidebarLayout'

export default function DepartmentUpdate({ onSuccess, onClose, innerRef }) {
    const { id, name } = innerRef.current
    const update = usePutRequest({ url: DEPARTMENT_UPDATE.replace('{id}', id) })
    const [showMessage] = useMessage()

    const onSubmit = async (data) => {
        const { success, error } = await update.request({ data: {
            name: data.name,
        } })

        if (success) {
            showMessage('Успешно!', 'success-msg')
            onSuccess()
        } else showMessage(error.data.errors[0].message || error.data.message, 'error-msg')
    }

    const initialValues = { name }

    return (
        <SidebarLayout title="Редактирование отдела" onClose={onClose}>
            <DepartmentForm
                onClose={onClose}
                onSubmit={onSubmit}
                loading={update.loading}
                initialValues={initialValues}
            />
        </SidebarLayout>
    )
}
