import React from 'react'
import cn from 'classnames'
import { Field, getIn, useFormikContext } from 'formik'
import { css, StyleSheet } from 'aphrodite'
import ValidationErrorMessage from './ValidationErrorMessage'
import { COLORS } from '../../utils/colors'

export default function FormInput({
    name,
    help,
    icon,
    type,
    label,
    loading,
    validate,
    className,
    onBlur = () => {},
    iconStyles,
    fieldClassName,
    labelClassName,
    noRequiredValidation,
    component = 'input',
    errorWithMessage = true,
    ...attributes
}) {
    const { errors, touched, handleBlur } = useFormikContext()
    const isValid = !errorWithMessage && getIn(errors, name) && getIn(touched, name)
    const isRequired = typeof validate === 'function' && !noRequiredValidation

    return (
        <div className={cn('field', fieldClassName)}>
            <div className={cn('control', css(s.field))}>
                {label ? (
                    <label className={labelClassName} htmlFor={name}>
                        {label} {isRequired && (
                            <span style={{ color: isValid ? COLORS.lightRed : COLORS.midGray }}>
                                *
                            </span>
                        )}
                    </label>
                ) : null}

                <Field
                    id={name}
                    name={name}
                    validate={validate}
                    type={type || 'text'}
                    component={component}
                    className={cn(
                        className, css(s.inp, isValid && s.dng), 'form-inp_pl', { [css(s.icon)]: icon },
                    )}
                    onBlur={(e) => { handleBlur(e); onBlur(e) }}
                    {...attributes} />

                <span style={{ position: 'absolute', right: 16, top: 32, ...iconStyles }}>{icon}</span>

                {help && (!errors[name] || !touched[name]) ? (
                    <p className={css(s.formHint)}>{help}</p>
                ) : null}

                {errorWithMessage && (
                    <ValidationErrorMessage
                        name={name}
                        errorMsg={help ? <h3 style={{ fontSize: 12 }}>{help}</h3> : ''}
                    />
                )}
            </div>
        </div>
    )
}

const s = StyleSheet.create({
    field: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        ':nth-child(1n) > label': {
            letterSpacing: -0.5,
        },
    },
    inp: {
        border: `1px solid ${COLORS.lightGray}`,
        borderRadius: 4,
        height: '42px',
        outline: 'none',
        padding: '0 16px',
        ':disabled': {
            backgroundColor: '#f2f2f2',
        },
    },
    icon: {
        paddingRight: 46,
    },
    dng: {
        border: `1px solid ${COLORS.lightRed}`,
    },
    formHint: {
        letterSpacing: -0.2,
        marginTop: 4,
        fontSize: 12,
        fontWeight: '400',
        color: COLORS.gray,
    },
})
