import React from 'react'
import '../../static/css/toggle-switch.css'
import cn from 'classnames'

export default function ToggleSwitch({ className, name, onClick, isChecked }) {
    return (
        <label className={cn('switch dis_sl', className)}>
            <input name={name} readOnly className="switchable" type="checkbox" checked={isChecked} />

            <span onClick={onClick} className="slider round" />
        </label>
    )
}
