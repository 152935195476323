import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import { Field, Form, useFormikContext } from 'formik'
import { isEmpty } from 'lodash'
import React, { memo, useEffect, useState } from 'react'
import { components } from 'react-select'
import { useLocation } from 'react-router-dom'
import { useGetRequest } from '../../../hooks/request'
import { useSidebar } from '../../../hooks/sidebar'
import { DEPARTMENT_LIST, HH_BILLING_TYPES, POSITIONS_LIST } from '../../../urls'
import { COLORS } from '../../../utils/colors'
import { format, integersOnly } from '../../../utils/number'
import { banknoteOptions,
    BillingTypes,
    educationLvl,
    Experience,
    WorkFormat,
    WorkSchedule } from '../../../utils/options'
import { min, required, validator } from '../../../utils/validators'
import FormInput from '../../common/FormInput'
import InputFromToForm from '../../common/InputFromToForm'
import SelectForm from '../../common/SelectForm'
import ToggleSwitch from '../../common/ToggleSwitch'
import DepartmentCreate from '../../settings/departments/DepartmentCreate'
import PositionCreate from '../../settings/positions/PositionCreate'
import { Tags } from '../../settings/tags/Tags'
import { EyeClose, PlusRounded } from '../../svgs/Svgs'
import { API_HH, checkHHIntegration } from '../../../integrations/hh'
import { useMessage } from '../../../hooks/message'
import LoadingSpinner from '../../common/LoadingSpinner'
import hh from '../../../static/images/hh.svg'
import { LoadingSkeleton } from '../../common/LoadingSkeleton'

// const countries = [{ value: 97, label: 'Узбекистан' }]
const loadingOptions = new Array(3).fill({ value: '', label: '' })

function MenuFooter({ title, showForm }) {
    return (
        <div onClick={showForm} className={cn(css(s.menuFooter), 'flex-cent pointer gap-2')}>
            <PlusRounded color={COLORS.mainColor} strokeWidth={2} />

            <h3>{title}</h3>
        </div>
    )
}

function MemoizedComponent({ setFieldValue, values, innerRef }) {
    const [showMessage] = useMessage()
    const { pathname } = useLocation()
    const { errors } = useFormikContext()
    const [hhIntegration, setHhIntegration] = useState({ loading: true })
    const getHHBillings = useGetRequest({ url: HH_BILLING_TYPES })
    const hhBillings = getHHBillings.response ? getHHBillings.response.data : {}
    const hhBillTypes = Object.keys(hhBillings)

    const handleCheck = async () => {
        const status = await checkHHIntegration(showMessage)
        setHhIntegration({ loading: false, status })
    }

    useEffect(() => {
        handleCheck()
        if (values.pushToHh && isEmpty(regions)) getRegion.request()
        if (values.pushToHh && isEmpty(filteredCountries)) getCountry.request()
    }, [])

    useEffect(() => {
        if (values.pushToHh) getHHBillings.request()
    }, [values.pushToHh])

    const getPositions = useGetRequest()
    const getDepartments = useGetRequest({ url: DEPARTMENT_LIST })

    const getRegion = useGetRequest({ baseURL: API_HH.areas.replace('{id}', 97), headers: {} }) // 97: Uzbekistan

    const getCountry = useGetRequest({ baseURL: API_HH.countries })
    const filteredCountries = getCountry.response ? getCountry.response.find(({ id }) => id === '97') : {}
    const countries = [{ value: filteredCountries.id, label: filteredCountries.name }]

    const regions = getRegion.response
        ? getRegion.response.areas.map(({ id, name }) => ({ value: id, label: name }))
        : []

    const positions = getPositions.response || {}

    const hhPositions = positions.categories ? [].concat(...positions.categories.map((category) => (
        category.roles.map(({ name, id }) => ({ value: id, label: name }))
    ))) : []

    const systemPositions = positions.data
        ? positions.data.map((e) => ({ value: e.id, label: e.name }))
        : []

    const departments = getDepartments.response
        ? getDepartments.response.data.map((e) => ({ value: e.id, label: e.name }))
        : []

    const controlStyles = { height: 44 }

    const [showDepCreateForm, hideDepCreateForm] = useSidebar(
        <DepartmentCreate
            onClose={() => hideDepCreateForm()}
            onSuccess={() => { getDepartments.request(); hideDepCreateForm() }} />,
    )

    const [showPosCreateForm, hidePosCreateForm] = useSidebar(
        <PositionCreate
            onClose={() => hidePosCreateForm()}
            onSuccess={() => { getPositions.request({ url: POSITIONS_LIST }); hidePosCreateForm() }} />,
    )

    const onSelectTag = (tagId) => {
        const t = values.tag
        if (t.includes(tagId)) setFieldValue('tag', t.filter((e) => e !== tagId))
        else setFieldValue('tag', [...t, tagId])
    }

    const Menu = ({ children, ...props }) => (
        <components.Menu {...props}>
            <div>{children}</div>

            {props.menuFooter}
        </components.Menu>
    )

    return (
        <div id="step-1" ref={innerRef} data-step={1} className={css(s.box)}>
            <div className={cn('justify-between', css(s.formName))}>
                <h3>Основная информация</h3>

                {/* <div className="align-center gap-6"> */}
                {/*     <EditSizeL onClick={() => setRemoveMode(!removeMode)} className="pointer dis_sl" /> */}

                {/*     <PlusRoundedSizeL className="pointer" /> */}
                {/* </div> */}
            </div>

            <Form>
                <div className={css(s.inpGr)}>
                    {pathname.endsWith('create') || values.hhVacancyId ? (
                        <div>
                            <div className={cn(
                                'justify-between',
                                css(s.switchBox, hhIntegration.status !== 'success' && s.disableHH),
                            )}>
                                <div className="align-center gap-2">
                                    <img className={css(s.logo)} src={hh} alt="#hh" />

                                    <h3>Опубликовать вакансию в hh.uz</h3>
                                </div>

                                {hhIntegration.loading
                                    ? <LoadingSpinner color={COLORS.gray} width={20} strokeWidth={4} />
                                    : (
                                        <ToggleSwitch
                                            name="pushToHh"
                                            isChecked={values.pushToHh}
                                            // eslint-disable-next-line max-len
                                            onClick={() => {
                                                if (hhIntegration.status === 'success' && !values.hhVacancyId) {
                                                    setFieldValue('pushToHh', !values.pushToHh)
                                                    setFieldValue('position', '')
                                                }
                                            }}
                                        />
                                    )}
                            </div>

                            <h3 className={css(s.helpTxt)}>
                                Все откликнувшиеся в hh.uz кандидаты будут попадать также в Workly HR
                            </h3>
                        </div>
                    ) : null}

                    {/* <div> */}
                    {/*     <div className={cn('justify-between', css(s.switchBox))}> */}
                    {/*         <div className="align-center gap-2"> */}
                    {/*             <img className={css(s.logo)} src={telegram} alt="#hh" /> */}

                    {/*             <h3>Опубликовать в Telegram и вебсайте</h3> */}
                    {/*         </div> */}

                    {/*         <ToggleSwitch */}
                    {/*             name="webPublish" */}
                    {/*             isChecked={values.webPublish} */}
                    {/*             onClick={() => setFieldValue('webPublish', !values.webPublish)} */}
                    {/*         /> */}
                    {/*     </div> */}

                    {/*     <h3 className={css(s.helpTxt)}>Вакансия будет доступна всем в Telegram и вебсайте</h3> */}
                    {/* </div> */}

                    <div className={cn(css(s.nameFld), 'justify-between gap-7')}>
                        {(values.pushToHh || values.hhStatus === 2)
                            && (pathname.endsWith('create') || values.hhVacancyId) ? (
                                <div className="direction-column justify-center">
                                    <label className={css(s.addInpLab)}>Тариф публикации в hh.uz</label>

                                    <div className={cn(
                                        'justify-evenly',
                                        css(s.billCont, errors.hhBillingType && s.dng),
                                    )}>
                                        {BillingTypes.map(({ value, label }) => (
                                            !getHHBillings.loading ? (
                                                <Field
                                                    validate={required}
                                                    type="button"
                                                    value={label}
                                                    name="hhBillingType"
                                                    disabled={!hhBillTypes.includes(value) || values.hhVacancyId}
                                                    onClick={() => setFieldValue('hhBillingType', value)}
                                                    className={css(s.billBtn,
                                                        values.hhBillingType === value && s.chosen,
                                                        !hhBillTypes.includes(value) && s.disabled)} />
                                            ) : <LoadingSkeleton width={110} />))}
                                    </div>
                                </div>
                            ) : null}

                        <FormInput
                            validate={required}
                            name="name"
                            errorWithMessage={false}
                            fieldClassName={css(s.fld)}
                            label="Название вакансии"
                            placeholder="Введите название"
                            labelClassName={css(s.addInpLab)}
                        />
                    </div>

                    <SelectForm
                        errorWithMessage={false}
                        label="Должность"
                        options={!getPositions.loading ? values.pushToHh
                            ? hhPositions : systemPositions : loadingOptions}
                        loading={getPositions.loading}
                        name="position"
                        validate={required}
                        value={values.position}
                        labelClassName={css(s.lb)}
                        controlStyles={controlStyles}
                        placeholder="Выберите должность"
                        labelContClassName={css(s.lab)}
                        indicatorStyle={{ paddingRight: 10 }}
                        valueContainerStyles={{ padding: '11.5px 16px' }}
                        onChange={(value) => setFieldValue('position', value)}
                        onMenuOpen={() => {
                            if (isEmpty(values.pushToHh ? hhPositions : systemPositions)) {
                                getPositions.request({ url: values.pushToHh ? API_HH.positions : POSITIONS_LIST })
                            }
                        }}
                        customComponents={!values.pushToHh ? { Menu: (props) => (
                            <Menu
                                menuFooter={<MenuFooter title="Новая должность" showForm={showPosCreateForm} />}
                                {...props} />
                        ) } : null}
                        placeholderStyles={{ color: COLORS.gray, fontFamily: 'Roboto, sans-serif' }}
                    />

                    <SelectForm
                        label="Отдел"
                        options={!getDepartments.loading ? departments : loadingOptions}
                        loading={getDepartments.loading}
                        validate={required}
                        name="department"
                        errorWithMessage={false}
                        value={values.department}
                        labelClassName={css(s.lb)}
                        controlStyles={controlStyles}
                        placeholder="Выберите отдел"
                        labelContClassName={css(s.lab)}
                        menuListClassName={css(s.menuList)}
                        indicatorStyle={{ paddingRight: 10 }}
                        valueContainerStyles={{ padding: '11.5px 16px' }}
                        onChange={(value) => setFieldValue('department', value)}
                        onMenuOpen={() => isEmpty(departments) && getDepartments.request()}
                        placeholderStyles={{ color: COLORS.gray, fontFamily: 'Roboto, sans-serif' }}
                        customComponents={{ Menu: (props) => (
                            <Menu
                                menuFooter={<MenuFooter title="Новый отдел" showForm={showDepCreateForm} />}
                                {...props} />
                        ) }}
                    />

                    <FormInput
                        maxLength="6"
                        validate={validator(required, min(1))}
                        name="employeeCount"
                        errorWithMessage={false}
                        className={css(s.addInp)}
                        fieldClassName={css(s.fld)}
                        label="Количество сотрудников"
                        placeholder="Введите количество"
                        labelClassName={css(s.addInpLab)}
                        onChange={(event) => {
                            setFieldValue('employeeCount', format(integersOnly(event.target.value)))
                        }} />

                    {/* <InputFromToForm */}
                    {/*    bothRequired */}
                    {/*    maxLength={2} */}
                    {/*    staticText="лет" */}
                    {/*    name="experience" */}
                    {/*    label="Опыт работы" */}
                    {/*    placeholder={['от', 'до']} */}
                    {/*    fieldClassName={css(s.fld)} */}
                    {/*    className={cn(css(s.addInp))} */}
                    {/*    labelClassName={css(s.addInpLab)} */}
                    {/* /> */}

                    <SelectForm
                        labelContClassName={css(s.lab)}
                        placeholderStyles={{ color: COLORS.gray, fontFamily: 'Roboto, sans-serif' }}
                        labelClassName={css(s.lb)}
                        value={values.experience}
                        valueContainerStyles={{ padding: '11.5px 16px' }}
                        indicatorStyle={{ paddingRight: 10 }}
                        controlStyles={controlStyles}
                        label="Опыт работы"
                        placeholder="Выберите опыт"
                        name="experience"
                        validate={required}
                        errorWithMessage={false}
                        options={Experience} />

                    <SelectForm
                        labelContClassName={css(s.lab)}
                        placeholderStyles={{ color: COLORS.gray, fontFamily: 'Roboto, sans-serif' }}
                        labelClassName={css(s.lb)}
                        value={values.format}
                        valueContainerStyles={{ padding: '11.5px 16px' }}
                        indicatorStyle={{ paddingRight: 10 }}
                        controlStyles={controlStyles}
                        label="Формат работы"
                        placeholder="Выберите формат"
                        name="format"
                        validate={required}
                        errorWithMessage={false}
                        options={WorkFormat} />

                    <SelectForm
                        labelContClassName={css(s.lab)}
                        placeholderStyles={{ color: COLORS.gray, fontFamily: 'Roboto, sans-serif' }}
                        labelClassName={css(s.lb)}
                        value={values.schedule}
                        valueContainerStyles={{ padding: '11.5px 16px' }}
                        indicatorStyle={{ paddingRight: 10 }}
                        controlStyles={controlStyles}
                        label="График работы"
                        validate={required}
                        errorWithMessage={false}
                        placeholder="Выберите график"
                        name="schedule"
                        options={WorkSchedule} />

                    <SelectForm
                        labelContClassName={css(s.lab)}
                        placeholderStyles={{ color: COLORS.gray, fontFamily: 'Roboto, sans-serif' }}
                        labelClassName={css(s.lb)}
                        valueContainerStyles={{ padding: '11.5px 16px' }}
                        controlStyles={controlStyles}
                        indicatorStyle={{ paddingRight: 10 }}
                        label="Образование"
                        value={values.education}
                        placeholder="Выберите уровень"
                        name="education"
                        options={educationLvl} />

                    <InputFromToForm
                        isSelectable
                        name="salary"
                        maxLength={11}
                        label="Заработная плата"
                        options={banknoteOptions}
                        placeholder={['от', 'до']}
                        fieldClassName={css(s.fld)}
                        cssClassName={s.addInp}
                        labelClassName={css(s.addInpLab)}
                    />

                    {values.pushToHh && (
                        <div className={cn('align-center gap-7', css(s.nameFld))}>
                            <SelectForm
                                labelContClassName={css(s.lab)}
                                placeholderStyles={{ color: COLORS.gray, fontFamily: 'Roboto, sans-serif' }}
                                labelClassName={css(s.lb)}
                                valueContainerStyles={{ padding: '11.5px 16px' }}
                                controlStyles={controlStyles}
                                indicatorStyle={{ paddingRight: 10 }}
                                label="Страна *"
                                contClassname="flex-1"
                                value={countries[0]}
                                placeholder="Выберите уровень"
                                name="country"
                                onMenuOpen={() => isEmpty(filteredCountries) && getCountry.request()}
                                options={countries} />

                            <SelectForm
                                isDisabled={!values.country}
                                loading={getRegion.loading}
                                labelContClassName={css(s.lab)}
                                placeholderStyles={{ color: COLORS.gray, fontFamily: 'Roboto, sans-serif' }}
                                labelClassName={css(s.lb)}
                                valueContainerStyles={{ padding: '11.5px 16px' }}
                                controlStyles={controlStyles}
                                indicatorStyle={{ paddingRight: 10 }}
                                label="Регион *"
                                contClassname="flex-1"
                                value={values.region || regions.find((r) => r.value === `${values.hhAreaId}`)}
                                onMenuOpen={() => isEmpty(regions) && getRegion.request()}
                                placeholder="Выберите регион"
                                name="region"
                                options={!getRegion.loading ? regions : loadingOptions} />
                        </div>
                    )}

                    <div className={css(s.nameFld)}>
                        <div className={cn('justify-between', css(s.switchBox))}>
                            <div className="align-center gap-2">
                                <EyeClose color={COLORS.gray} />

                                <h3 className={css(s.hide)}>Скрыть вакансию</h3>
                            </div>

                            <ToggleSwitch
                                name="hidden"
                                isChecked={values.hidden}
                                onClick={() => setFieldValue('hidden', !values.hidden)}
                            />
                        </div>

                        <h3 className={css(s.helpTxt)}>Скрытая вакансия не будет доступна рекрутерам и сотрудникам</h3>
                    </div>

                    <Tags
                        values={values}
                        onSelectTag={onSelectTag}
                        setFieldValue={setFieldValue} />
                </div>
            </Form>
        </div>
    )
}

export const MainInfo = memo(MemoizedComponent)

const s = StyleSheet.create({
    box: {
        scrollMarginTop: 24,
        background: COLORS.white,
        border: `1px solid ${COLORS.smoothGray}`,
        boxShadow: '0px 2px 4px rgba(130, 130, 130, 0.05)',
        borderRadius: 4,
    },
    formName: {
        height: 'calc(3rem - 1px)',
        padding: '0 22px 0 24px',
        fontSize: 13,
        fontWeight: '600',
        color: COLORS.dark,
        borderBottom: `1px solid ${COLORS.lightGray}`,
        textTransform: 'uppercase',
        ':nth-child(1n) > :last-child': {
            ':nth-child(1n) > *': {
                ':hover': {
                    ':nth-child(1n) > *': {
                        stroke: COLORS.skyblue,
                    },
                },
            },
        },
    },
    inpGr: {
        padding: '16px 24px 24px',
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gridGap: 24,
        alignItems: 'start',
        ':nth-child(1n) > :first-child': {
            gridColumn: '1 / 3',
        },
    },
    lb: {
        fontSize: 12,
        fontWeight: '500',
        color: COLORS.midGray,
    },
    addInp: {
        fontSize: 14,
        fontWeight: '500',
        color: COLORS.dark,
    },
    addInpLab: {
        fontSize: 12,
        fontWeight: '500',
        color: COLORS.midGray,
        marginBottom: 8,
    },
    nameFld: {
        gridColumn: '1 / 3',
        ':nth-child(1n) > *': {
            flex: 1,
        },
    },
    dng: {
        border: `1px solid ${COLORS.lightRed}`,
    },
    billCont: {
        height: 36,
        boxSizing: 'border-box',
        padding: 4,
        borderRadius: 6,
        backgroundColor: COLORS.smoothGray,
    },
    billBtn: {
        height: 28,
        boxSizing: 'border-box',
        width: '100%',
        cursor: 'pointer',
        color: COLORS.dark,
        fontSize: 12,
        border: '1px solid transparent',
        fontWeight: '500',
        borderRadius: 4,
        ':hover': {
            border: `1px solid ${COLORS.lightGray}`,
            background: COLORS.white,
            boxShadow: '0px 2px 8px 0px rgba(94, 94, 94, 0.15)',
        },
    },
    chosen: {
        border: `1px solid ${COLORS.lightGray}`,
        background: COLORS.white,
        boxShadow: '0px 2px 8px 0px rgba(94, 94, 94, 0.15)',
    },
    fld: {
        position: 'relative',
    },
    lab: {
        ':nth-child(1n) > span': {
            display: 'inline-flex',
        },
    },
    switchBox: {
        padding: '0 16px',
        border: '1px solid',
        borderRadius: 4,
        fontWeight: '500',
        fontSize: 14,
        color: COLORS.dark,
        position: 'relative',
        height: 44,
        boxSizing: 'border-box',
        borderColor: COLORS.lightGray,
    },
    hide: {
        color: COLORS.gray,
    },
    disabled: {
        background: 'inherit',
        color: COLORS.gray,
        border: 'none',
        boxShadow: 'none',
        ':hover': 'none',
    },
    helpTxt: {
        color: COLORS.midGray,
        fontSize: 11,
        fontWeight: '400',
    },
    menuFooter: {
        borderRadius: '0px 0px 4px 4px',
        borderTop: '1px solid #D9EDE4',
        background: COLORS.mintCream,
        padding: '12px 0',
        ':nth-child(1n) > h3': {
            color: COLORS.mainColor,
            fontSize: 16,
            fontWeight: '600',
        },
    },
    disableHH: {
        backgroundColor: COLORS.smoothGray,
    },
})
