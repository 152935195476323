import React, { useCallback } from 'react'
import { Form, Formik } from 'formik'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import moment from 'moment/moment'
import InputDate from '../../common/InputDate'
import { COLORS } from '../../../utils/colors'
import { CalendarSizeS } from '../../svgs/Svgs'
import { banknoteOptions } from '../../../utils/options'
import InputFromToForm from '../../common/InputFromToForm'
import { getFullDate } from '../../../utils/date'
import { integersOnly } from '../../../utils/number'
import FilterSelectField from '../FilterSelectField'
import { useGetRequest } from '../../../hooks/request'
import { DEPARTMENT_LIST, EMPLOYEE_LIST } from '../../../urls'

const today = moment()

const initialValues = {
    date: { from: getFullDate(today.clone().subtract(29, 'days').toDate()), to: getFullDate(today.toDate()) },
    departments: [],
    users: [],
    salary: { from: '', to: '', currency: banknoteOptions[0] },
}

export default function Filter({ setParams, formikRef, loadInfo }) {
    const getDepartments = useGetRequest({ url: DEPARTMENT_LIST, params: { 'per-page': 100 } })
    const getEmployees = useGetRequest({ url: EMPLOYEE_LIST, params: { 'per-page': 100, userExist: 1 } })

    const departments = getDepartments.response ? getDepartments.response.data.map((e) => ({
        value: e.id,
        label: e.name,
    })) : []

    const employees = getEmployees.response ? getEmployees.response.data.map((e) => ({
        value: e.id,
        label: `${e.firstName} ${e.lastName}`,
        department: e.department,
        position: e.position,
    })) : []

    const onSubmit = useCallback(async (data) => {
        if (loadInfo.loading) return

        const { date, salary, departments: departmentsForm } = data

        await setParams({
            isReinitialized: true,
            startDate: date.from,
            endDate: date.to,
            departmentIds: departmentsForm.map(({ value }) => value).join(','),
            salaryFrom: +integersOnly(salary.from) || '',
            salaryEnd: +integersOnly(salary.to) || '',
            userIds: [],
        })
    }, [loadInfo.loading])

    return (
        <div className={cn('align-center', css(s.cont))}>
            <Formik innerRef={formikRef} onSubmit={onSubmit} initialValues={initialValues}>
                {() => (
                    <Form className="align-center gap-5">
                        <InputDate
                            isMulti
                            withOptions
                            name={['date.from', 'date.to']}
                            nameSplit={{ name: 'date', count: 0, keys: ['from', 'to'] }}
                            labelClassName={s.label}
                            label="Дата создания вакансии"
                            position="left"
                            className={s.inp}
                            icon={<CalendarSizeS />}
                        />

                        <FilterSelectField
                            name="users"
                            options={employees}
                            fieldLabel="Пользователи"
                            placeholder="Выбрать пользователей"
                            multiValueText="Пользователи"
                            onMenuOpen={() => getEmployees.request()}
                        />

                        <FilterSelectField
                            name="departments"
                            options={departments}
                            fieldLabel="Отделы"
                            placeholder="Выбрать"
                            multiValueText="Отделы"
                            onMenuOpen={() => getDepartments.request()}
                        />

                        <InputFromToForm
                            name="salary"
                            maxLength={11}
                            labelClassName={css(s.label)}
                            label="Зарплатная вилка"
                            cssClassName={s.salaryInp}
                            options={banknoteOptions}
                            placeholder={['от', 'до']}
                            staticText={<span className={css(s.currency)}>UZS</span>}
                        />
                    </Form>
                )}
            </Formik>
        </div>
    )
}

const s = StyleSheet.create({
    cont: {
        padding: '9px 24px',
        borderBottom: `1px solid ${COLORS.smoothGray}`,
        background: COLORS.white,
        height: 54,
    },
    label: {
        marginBottom: 3,
        fontSize: 10,
        color: COLORS.midGray,
        fontWeight: '500',
    },
    inp: {
        height: '32px !important',
        boxSizing: 'border-box',
        minWidth: '15rem',
    },
    salaryInp: {
        height: '32px !important',
        boxSizing: 'border-box',
        minWidth: '15rem',
        ':nth-child(1n) > :first-child': {
            flex: 1,
            ':nth-child(1n) > :last-child': {
                marginLeft: 'auto',
            },
        },
    },
    currency: {
        paddingLeft: 32,
        color: COLORS.mainColor,
        fontSize: 12,
        fontWeight: '500',
    },
    select: {
        width: '15rem',
    },
})
