import React, { useContext } from 'react'
import { css, StyleSheet } from 'aphrodite'
import { FormattedMessage } from 'react-intl'
import { isEmpty } from 'lodash'
import { COLORS } from '../../utils/colors'
import { Context } from '../../contexts/GlobalContext'
import { LoadingSkeleton } from '../common/LoadingSkeleton'

export default function DashboardHeader() {
    const { user } = useContext(Context)
    const { firstName, lastName } = user.data || {}

    return (
        <div className={css(s.head)}>
            <div>
                <span className={css(s.greeting)}>
                    <FormattedMessage id="greetings" />, &nbsp;

                    <div className="align-center gap-2">
                        {!isEmpty(user) && !user.loading
                            ? `${firstName} ${lastName}`
                            : (
                                <>
                                    <LoadingSkeleton width={120} height={28} />
                                    <LoadingSkeleton width={120} height={28} />
                                </>
                            )}
                    </div> &nbsp; 👋
                </span>

                {/* <ShowResultsByPeriod options={[ */}
                {/*     { id: 1, value: 'Сегодня' }, */}
                {/*     { id: 2, value: 'Текущая неделя' }, */}
                {/*     { id: 3, value: 'Текущий месяц' }, */}
                {/*     { id: 4, value: 'Последние 30 дней' }, */}
                {/*     { id: 5, value: 'Текущий год' }, */}
                {/* ]} /> */}
            </div>

            {/* <div className={css(s.btnGroup)}> */}
            {/*     <span className="flex-cent"><Download /></span> */}

            {/*     <span className="flex-cent gap-2"> */}
            {/*         <Settings /> */}
            {/*         <h3>Настройки виджетов</h3> */}
            {/*     </span> */}
            {/* </div> */}
        </div>

    )
}

const s = StyleSheet.create({
    head: {
        marginTop: '1.5rem',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        ':nth-child(1n) > *': {
            display: 'flex',
            alignItems: 'center',
        },
    },
    greeting: {
        display: 'inline-flex',
        fontSize: 24,
        fontWeight: '600',
        color: COLORS.dark,
        margin: 0,
    },
    btnGroup: {
        gap: 12,
        ':nth-child(1n) > *': {
            background: COLORS.white,
            boxSizing: 'border-box',
            border: `1px solid ${COLORS.smoothGray}`,
            borderRadius: 4,
            height: '2rem',
            cursor: 'pointer',
            ':nth-child(1n) > :first-child': {
                ':nth-child(1n) > *': {
                    stroke: COLORS.lightBlack,
                },
            },
            ':hover': {
                boxShadow: '0px 2px 4px rgba(168, 168, 168, 0.15)',
            },
        },
        ':nth-child(1n) > :first-child': {
            padding: '0 6px',
        },
        ':nth-child(1n) > :last-child': {
            padding: '0 12px',
            color: COLORS.lightBlack,
            fontSize: 13,
            fontWeight: '500',
        },
    },
})
