import React, { memo } from 'react'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import uuid from 'react-uuid'
import { COLORS } from '../../../utils/colors'
import { AvailableLanguages, LanguageLevels } from '../../../utils/options'
import SelectForm from '../../common/SelectForm'
import { CloseSidebar } from '../../svgs/Svgs'

const initialValue = { language: '', lvl: null }

function MemoizedComponent({ setFieldValue, values }) {
    const { languageLevel } = values

    const index = languageLevel.length ? languageLevel.length - 1 : 0
    const blockValue = languageLevel[index] ? languageLevel[index].language : ''
    const allowSelectLvl = languageLevel.length && languageLevel[languageLevel.length - 1].language
    const existedLangs = languageLevel.length ? languageLevel.map((e) => (e.level ? e.language : '')) : []
    const availableLanguages = AvailableLanguages.filter((e) => !existedLangs.includes(e.short))

    const onRemove = (ind) => {
        languageLevel.splice(ind, 1)
        setFieldValue('languageLevel', [...languageLevel])
    }

    return (
        <div className="is-flex direction-column gap-5 ">
            <div className="align-center gap-7 is-flex-wrap">
                <SelectForm
                    labelContClassName={css(s.lab)}
                    placeholderStyles={{ color: COLORS.gray, fontFamily: 'Roboto, sans-serif' }}
                    labelClassName={css(s.lb)}
                    valueContainerStyles={{ padding: '11.5px 16px' }}
                    indicatorStyle={{ paddingRight: 10 }}
                    placeholder="Выберите язык"
                    contClassname="flex-1"
                    label="Знание языков кандидата"
                    value={availableLanguages.find((e) => e.short === blockValue) || null}
                    getOptionLabel={(option) => option.ru}
                    name={`languageLevel[${index}]`}
                    className={css(s.selectField)}
                    onChange={(value) => {
                        const newData = languageLevel
                        newData[index] = { language: value.short, level: null }
                        setFieldValue('languageLevel', [...newData])
                    }}
                    options={availableLanguages}
                />

                <div className={cn('is-flex direction-column gap-2', css(s.lvlCont))}>
                    <h3 className={css(s.topic)}>Уровень знания языка</h3>

                    <div className="is-flex">
                        {LanguageLevels.map((e) => (
                            <span
                                key={uuid()}
                                onClick={() => {
                                    if (allowSelectLvl) {
                                        const newData = languageLevel

                                        newData[index] = { ...languageLevel[index], level: e.level }
                                        setFieldValue('languageLevel', [...newData, initialValue])
                                    }
                                }}
                                className={cn(
                                    'flex-cent', css(
                                        s.lvl,
                                        allowSelectLvl && s.selectLvl,
                                        languageLevel[index] && languageLevel[index].level === e.level && s.selected,
                                    ),
                                )}>
                                {e.level}
                            </span>
                        ))}
                    </div>
                </div>
            </div>

            <div className={cn('align-center gap-5 is-flex-wrap', css(s.knowledge))}>
                {languageLevel.map(({ language, level }, ind) => language && level && (
                    <div className={cn('align-center', css(s.tag))}>
                        <div className={cn('flex-cent', css(s.skill))}>
                            {AvailableLanguages.find((e) => e.short === language).ru} - {level}
                        </div>

                        <div onClick={() => onRemove(ind)} className={cn('flex-cent pointer', css(s.closing))}>
                            <CloseSidebar />
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export const Languages = memo(MemoizedComponent)

const s = StyleSheet.create({
    add: {
        color: COLORS.mainColor,
        fontSize: 14,
        fontWeight: '500',
    },
    title: {
        color: COLORS.midGray,
        fontSize: 14,
        fontWeight: '500',
    },
    topic: {
        fontWeight: '500',
        fontSize: 12,
        color: COLORS.midGray,
    },
    selected: {
        background: `linear-gradient(180deg, ${COLORS.mainColor} 0%, ${COLORS.lightMainColor} 100%)`,
        borderRight: '1px solid rgba(0, 0, 0, 0.15)',
        pointerEvents: 'none',
        color: COLORS.white,
        backgroundClip: 'padding-box',
    },
    lvl: {
        background: COLORS.smoothGray,
        border: 'solid rgba(0, 0, 0, 0.15)',
        borderTopWidth: 1,
        borderBottomWidth: 1,
        borderLeftWidth: 0.5,
        borderRightWidth: 0.5,
        fontWeight: '500',
        fontSize: 14,
        height: 43,
        flex: 1,
        boxSizing: 'border-box',
        color: COLORS.lightGray,
        ':first-of-type': {
            borderLeftWidth: 1,
            borderRadius: '3px 0 0 3px',
        },
        ':last-of-type': {
            borderRightWidth: 1,
            borderRadius: '0 3px 3px 0',
        },
    },
    selectLvl: {
        background: COLORS.white,
        cursor: 'pointer',
        ':hover': {
            background: COLORS.smoothGray,
            color: COLORS.gray,
        },
    },
    lvlCont: {
        maxWidth: 372,
        width: '45%',
    },
    selectField: {
        borderColor: COLORS.midGray,
    },
    tag: {
        height: 28,
        boxSizing: 'border-box',
        background: COLORS.white,
        border: `1px solid ${COLORS.gray}`,
        borderRadius: 4,
        color: COLORS.lightBlack,
        fontWeight: '500',
        fontSize: 13,
    },
    skill: {
        outline: 'none',
        border: 'none',
        padding: '0 8px',
    },
    closing: {
        padding: '0 6px',
        borderLeft: `1px solid ${COLORS.gray}`,
        background: COLORS.smoothGray,
        height: '100%',
        borderRadius: '0 4px 4px 0',
    },
})
