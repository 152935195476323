import React, { useEffect, useState } from 'react'
import cn from 'classnames'
import { css, StyleSheet } from 'aphrodite'
import { useDropzone } from 'react-dropzone'
import uuid from 'react-uuid'
import { isEmpty } from 'lodash'
import { CloudDownload, EditSizeL, XCircleSizeL } from '../../svgs/Svgs'
import { COLORS } from '../../../utils/colors'
import { useDeleteRequest, useLoad, usePostRequest } from '../../../hooks/request'
import { COMPANY_PHOTOS } from '../../../urls'
import { useMessage } from '../../../hooks/message'
import { LoadingSkeleton } from '../../common/LoadingSkeleton'
import LoadingSpinner from '../../common/LoadingSpinner'

const ALLOWED_TYPES = ['jpg', 'png', 'jpeg']
const MAX_FILE_SIZE = 3145728

export default function CompanyImages({ loadInfo, hasPermission }) {
    const [showMessage] = useMessage()
    const [editImg, setEditImg] = useState(false)
    const [imageData, setImageData] = useState({
        images: [],
        uploaded: [],
        deleted: [],
    })
    const deleteImage = useDeleteRequest({ url: COMPANY_PHOTOS })
    const postImage = usePostRequest({ url: COMPANY_PHOTOS })
    const loadImages = useLoad({ url: COMPANY_PHOTOS })

    const images = loadImages.response ? loadImages.response.data : []

    const isDisabled = postImage.loading || deleteImage.loading

    useEffect(() => {
        if (images.length) {
            setImageData((prev) => ({ ...prev, images }))
        }
    }, [images])

    // Functions for file uploads for company images
    const onDrop = (acceptedFiles) => {
        const filesLen = imageData.images.length + acceptedFiles.length

        if (filesLen > 5) {
            showMessage('Вы можете загрузить до 5 изображений', 'error-msg')
        } else {
            for (const file of acceptedFiles) {
                const current = file.name.split('.')
                const allowed = ALLOWED_TYPES.includes(current[current.length - 1])

                if (!allowed) {
                    showMessage('Поддерживаемые типы файлов: jpg, jpeg, png', 'error-msg')
                    return
                }

                if (file.size > MAX_FILE_SIZE) {
                    showMessage('Размер файла слишком велик', 'error-msg')
                    return
                }
            }

            const uploadedImages = acceptedFiles.map((image) => ({
                name: image.name,
                link: [URL.createObjectURL(image)],
            }))

            setImageData((prev) => ({
                ...prev,
                uploaded: [...prev.uploaded, ...acceptedFiles],
                images: [...prev.images, ...uploadedImages],
            }))
        }
    }

    const { getRootProps, getInputProps, open } = useDropzone({
        onDrop,
        noClick: true,
        noKeyboard: true,
        multiple: true,
        accept: {
            'image/*': ['.png', '.jpeg', '.jpg'],
        },
    })

    // Function for delete images
    const onDeleteImages = async () => {
        const imagesToDelete = imageData.deleted.filter(
            (imageName) => images.find((originalImg) => originalImg.name === imageName),
        )

        if (!imagesToDelete.length) {
            setEditImg(false)
            setImageData((prev) => ({ ...prev, deleted: [] }))
            return
        }
        if (!imageData.deleted.length) return
        const { success } = await deleteImage.request({ data: { name: imagesToDelete } })

        if (success) {
            setEditImg(false)
            setImageData((prev) => ({ ...prev, deleted: [] }))
        }
    }

    // Function for upload images
    const sendImages = async () => {
        const formData = new FormData()

        imageData.uploaded.forEach((file) => {
            formData.append('file[]', file)
        })

        await postImage.request({ data: formData })
            .then(({ success, error }) => {
                if (success) {
                    setEditImg(false)
                    setImageData((prev) => ({ ...prev, uploaded: [] }))
                    showMessage('Успешно!', 'success-msg')
                } else if (error) {
                    showMessage(error.data.errors[0].message || error.data.message, 'error-msg')
                    setImageData((prev) => ({ ...prev, images: [...images] }))
                }
            }).catch(() => {
                showMessage('Файл слишком большой', 'error-msg')
                setImageData((prev) => ({ ...prev, images: [...images] }))
            })
    }

    // Function for save image changes
    const onSaveImageChanges = () => {
        if (imageData.deleted.length) {
            return onDeleteImages()
        }
        if (imageData.uploaded.length > 0) {
            return sendImages()
        }
        return setEditImg(false)
    }

    const onCancelImageChanges = () => {
        setImageData({ images, uploaded: [], deleted: [] })
        setEditImg(false)
    }

    // Function for select images to delete
    const onSelectImages = (name) => {
        if (name) {
            const updatedImages = imageData.images.filter((img) => img.name !== name)

            const updatedUploaded = imageData.uploaded.filter((img) => img.name !== name)

            setImageData((prev) => ({
                ...prev,
                images: updatedImages,
                uploaded: updatedUploaded,
                deleted: [...prev.deleted, name],
            }))
        }
    }

    return isEmpty(imageData.images) && !hasPermission ? null : (
        <div className={cn('is-flex direction-column', css(s.imgCont))}>
            <div className="justify-between">
                <div className={cn(css(s.titleWrap), 'align-center gap-2')}>
                    <h3 className={css(s.title)}>Фото компании</h3>

                    { !editImg && imageData.images.length ? (
                        <EditSizeL
                            className="pointer"
                            onClick={() => setEditImg(true)} color={COLORS.mainColor} />
                    ) : ''}
                </div>

                {(editImg || imageData.uploaded.length) ? (
                    <div className={cn('align-center gap-2', css(s.btnGr))}>
                        <button onClick={onCancelImageChanges} className={css(s.cancelBtn)}>Отмена</button>

                        <button
                            type="submit"
                            disabled={isDisabled}
                            onClick={onSaveImageChanges}
                            className={cn(css(s.saveBtn, isDisabled && s.disabled), 'align-center gap-1')}>
                            {!isDisabled ? 'Сохранить' : 'Сохранение'}

                            {isDisabled ? <LoadingSpinner color={COLORS.white} width={12} strokeWidth={3} /> : ''}
                        </button>
                    </div>
                ) : ''}
            </div>

            { !loadInfo.loading || !loadImages.loading ? (
                <div className={cn('is-flex', css(s.images))}>
                    {imageData.images.length ? imageData.images.map((i) => (
                        <div key={uuid()} className={cn(
                            css(s.imgWrap), { [css(s.imgWrap, s.editImgCont)]: editImg },
                        )}>
                            <img className={css(s.img)} src={i.link && i.link[0]} alt="#companyImage" />

                            <span onClick={() => onSelectImages(i.name && i.name)} className={css(s.xcircle)}>
                                <XCircleSizeL />
                            </span>
                        </div>
                    )) : ''}

                    {hasPermission && imageData.images.length < 5 ? (
                        <div className={css(s.dropZoneWrap)}>
                            <div {...getRootProps({
                                className: cn('flex-cent direction-column', css(s.dropZone)),
                            })}>
                                <CloudDownload />

                                <p>Перетащите файл сюда или</p>

                                <span onClick={open}>Выбрать с компьютера</span>

                                <input {...getInputProps()} />
                            </div>
                        </div>
                    ) : ''}
                </div>
            ) : (
                <div className={css(s.skeleton)}>
                    <LoadingSkeleton width={258} height={150} />
                </div>
            )}
        </div>
    )
}
const s = StyleSheet.create({
    titleWrap: {
        height: '28px',
        ':nth-child(1n) > :last-child': {
            cursor: 'pointer',
            ':nth-child(1n) > path': {
                stroke: COLORS.mainColor,
            },
        },
    },
    title: {
        fontSize: 20,
        fontWeight: '700',
        color: COLORS.lightBlack,
    },
    imgCont: {
        // userSelect: 'none',
    },
    images: {
        gap: 20,
        overflowX: 'auto',
        '::-webkit-scrollbar': {
            display: 'none',
        },
        boxSizing: 'border-box',
    },
    dropZoneWrap: {
        paddingTop: 16,
    },
    dropZone: {
        boxSizing: 'border-box',
        height: 150,
        width: 258,
        letterSpacing: -0.25,
        fontWeight: '400',
        fontSize: 11,
        color: COLORS.midGray,
        background: '#FAFBFF',
        border: `1px dashed ${COLORS.skyblue}`,
        borderRadius: 4,
        ':nth-child(1n) > p': {
            marginTop: '1.25rem',
        },
        ':nth-child(1n) > span': {
            color: COLORS.skyblue,
            textDecoration: 'underline',
            cursor: 'pointer',
        },
    },
    btnGr: {
        ':nth-child(1n) > *': {
            height: 28,
            boxSizing: 'border-box',
            fontWeight: '500',
            fontSize: 11,
            borderRadius: 4,
        },
    },
    cancelBtn: {
        color: COLORS.gray,
        border: `1px solid ${COLORS.gray}`,
        background: 'transparent',
        padding: '7.5px 12px',
        boxShadow: '0px 2px 4px rgba(130, 130, 130, 0.05)',
        borderRadius: 4,
        cursor: 'pointer',
    },
    saveBtn: {
        color: COLORS.white,
        border: 'none',
        padding: '7.5px 8px',
        background: `linear-gradient(180deg, ${COLORS.mainColor} 0%, ${COLORS.lightMainColor} 100%)`,
        // eslint-disable-next-line max-len
        boxShadow: '0px 4px 12px rgba(44, 67, 50, 0.1), inset 0px -1px 0px rgba(0, 0, 0, 0.25), inset 0px 1px 0px rgba(255, 255, 255, 0.1)',
        borderRadius: 4,
        cursor: 'pointer',
    },
    disabled: {
        background: COLORS.lightGray,
        boxShadow: 'none',
        cursor: 'not-allowed',
    },
    skeleton: {
        marginTop: 16,
    },
    img: {
        width: 258,
        height: 150,
        objectFit: 'cover',
        objectPosition: 'center',
        border: `1px solid ${COLORS.smoothGray}`,
        borderRadius: 4,
        boxSizing: 'border-box',
    },
    imgWrap: {
        paddingTop: 16,
        position: 'relative',
        ':nth-child(1n) > span': {
            display: 'none',
        },
        '@media (max-width: 768px)': {
            paddingTop: 8,
        },
    },
    xcircle: {
        position: 'absolute',
        top: 5,
        right: -11,
        cursor: 'pointer',
    },
    editImgCont: {
        ':nth-child(1n) > span': {
            display: 'block',
        },
        ':nth-child(1n) > img': {
            border: `1px solid ${COLORS.lightRed}`,
        },
    },
})
