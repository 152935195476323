import React, { useRef, useState } from 'react'
import cn from 'classnames'
import { css, StyleSheet } from 'aphrodite'
import Button from '../../common/Button'
import { AddPhoto, Trash } from '../../svgs/Svgs'
import { SettingsAvatar } from '../../svgs/SvgPictures'
import { COLORS } from '../../../utils/colors'

export default function EmployeeImage({ values, setFieldValue }) {
    const [img, setImg] = useState('')
    const inputRef = useRef(null)

    const isImageUploaded = values.avatar || img

    const onChange = (e) => {
        setFieldValue('avatar', e.target.files[0])
        if (e.target.files[0]) setImg(URL.createObjectURL(e.target.files[0]))
    }

    return (
        <div className={cn('align-center', css(s.loadPhCont))}>
            {isImageUploaded ? (
                <React.Fragment>
                    <img
                        src={img || values.avatar}
                        alt="#avatar"
                        className={css(s.avatarImg)} />

                    <div className={cn('is-flex direction-column', css(s.btn_group))}>
                        <Button className="flex-cent gap-2" type="button" onClick={() => inputRef.current.click()}>
                            <AddPhoto /> Загрузить фотографию
                        </Button>

                        <Button
                            className="flex-cent gap-2"
                            type="reset"
                            onClick={() => {
                                setFieldValue('avatar', '')
                                inputRef.current.value = ''
                                setImg('')
                            }}>
                            <Trash /> Удалить фотографию
                        </Button>
                    </div>
                </React.Fragment>
            ) : (
                <React.Fragment>
                    <div className={css(s.avatar)}>
                        <SettingsAvatar />
                    </div>

                    <div
                        onClick={() => inputRef.current.click()}
                        className={cn('flex-cent pointer gap-2', css(s.loadPh))}>
                        <AddPhoto />

                        <h3>Загрузить фотографию</h3>
                    </div>
                </React.Fragment>
            )}

            <input
                id="file"
                type="file"
                readOnly="readonly"
                accept="image/png, image/jpg"
                ref={inputRef}
                className="not-visible"
                onChange={(e) => onChange(e)} />
        </div>
    )
}

const s = StyleSheet.create({
    avatarImg: {
        width: 96,
        height: 96,
        border: `1px solid ${COLORS.lightGray}`,
        borderRadius: 4,
    },
    btn_group: {
        gap: 12,
        ':nth-child(1n) > *': {
            width: '16.625rem',
            fontWeight: '500',
            borderRadius: 4,
            height: 40,
        },
        ':nth-child(1n) > :first-child': {
            fontSize: 14,
            color: COLORS.mainColor,
            background: COLORS.mintCream,
            border: '1px solid #CCEADB',
        },
        ':nth-child(1n) > :last-child': {
            border: `1px solid ${COLORS.smoothGray}`,
            background: 'transparent',
            color: COLORS.gray,
            fontSize: 13,
        },
    },
    loadPhCont: {
        gap: 32,
    },
    avatar: {
        border: '1px solid #C9D2CE',
        borderRadius: 4,
        display: 'flex',
    },
    loadPh: {
        background: COLORS.mintCream,
        border: '1px solid #CCEADB',
        boxShadow: '0px 2px 20px rgba(137, 152, 141, 0.02)',
        borderRadius: 4,
        padding: '0 16px',
        fontWeight: '500',
        fontSize: 14,
        color: COLORS.mainColor,
        height: 40,
        ':hover': {
            boxShadow: '0px 2px 4px rgba(168, 168, 168, 0.15)',
            border: '1px solid rgba(46, 169, 125, 0.5)',
        },
    },
})
