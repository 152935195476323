import React from 'react'
import { css, StyleSheet } from 'aphrodite'
import PersonalForm from './PersonalForm'

export default function PersonalSettings() {
    return (
        <div className={css(s.cont)}>
            <PersonalForm />

            {/* <PersonalNotificationForm /> */}

            {/* <PersonalDisplayForm /> */}
        </div>
    )
}

const s = StyleSheet.create({
    cont: {
        display: 'grid',
        gridTemplateColumns: '2fr 1fr',
        gap: 24,
        padding: '1.5rem 2rem',
        alignItems: 'start',
    },
})
