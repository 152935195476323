import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { createPortal } from 'react-dom'
import cn from 'classnames'
import { css, StyleSheet } from 'aphrodite'

export const SidebarWithTrigger = forwardRef(({ children, onClose }, ref) => {
    const [open, setOpen] = useState(false)

    useEffect(() => {
        setTimeout(() => setOpen(true), 100)
    }, [])

    const handleClose = () => {
        setOpen(false)
        setTimeout(() => onClose(), 500)
    }

    useImperativeHandle(ref, () => ({
        onClose() { handleClose() },
    }))

    useEffect(() => {
        const handleKeyboard = (event) => { if (event.keyCode === 27) handleClose() }

        window.addEventListener('keydown', handleKeyboard)
        return () => {
            window.removeEventListener('keydown', handleKeyboard)
        }
        // eslint-disable-next-line
    }, [onClose])

    return createPortal((
        <div className={css(s.cont)}>
            <div className={cn(css(s.bg))} onClick={handleClose} />

            <div className={cn(css(s.wrap), { [css(s.actSide)]: open })}>
                {children}
            </div>
        </div>
    ), document.querySelector('#sidebar-root'))
})

const s = StyleSheet.create({
    cont: {
        position: 'absolute',
        width: '100%',
        top: '3.25rem',
        height: 'calc(100% - 3.25rem)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        zIndex: 1,
        overflow: 'hidden',
    },
    bg: {
        height: '100%',
        width: '100%',
        backgroundColor: 'rgba(0,0,0, 0.6)',
    },
    wrap: {
        overflowX: 'hidden',
        position: 'absolute',
        height: '100%',
        top: 0,
        right: '-26rem',
        transition: '0.3s linear',
        width: '26rem',
        maxWidth: '45%',
        backgroundColor: '#F6F6F6',
    },
    actSide: {
        right: 0,
        transition: '0.3s linear',
    },
})
