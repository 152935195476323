import React from 'react'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import { isEmpty, range } from 'lodash'
import { COLORS } from '../../utils/colors'
import SearchInput from '../common/SearchInput'
import Checkbox from '../common/Checkbox'
import { ChevronPrev } from '../svgs/Svgs'
import { useInfiniteScroll } from '../../hooks/request'
import { LoadingSkeleton } from '../common/LoadingSkeleton'

function ListSection({ filter, section, setFilter }) {
    const loadInfo = useInfiniteScroll({ url: section.meta.url }, [])
    const info = loadInfo.response ? loadInfo.response.data : []

    function onCheck(id) {
        if (filter[section.name]) {
            if (filter[section.name].includes(id)) {
                setFilter({
                    ...filter,
                    [section.name]: filter[section.name].filter((fil) => fil !== id),
                })
            } else {
                setFilter({ ...filter, [section.name]: [...filter[section.name], id] })
            }
        } else setFilter({ ...filter, [section.name]: [id] })
    }

    const isChecked = (id) => filter[section.name] && filter[section.name].includes(id)

    return (
        <ul className="no_dot_list is-flex direction-column gap-2">
            {!loadInfo.loading ? info.map((option) => (
                <li onClick={() => onCheck(option.id)} key={option.id} className="justify-between pointer">
                    <div className="align-center gap-2">
                        <Checkbox checked={isChecked(option.id)} onClick={() => {}} />

                        <h3 className={css(s.label, !isChecked(option.id) && s.labelColor)}>
                            {option.name}
                        </h3>
                    </div>

                    {/* <span className={cn(
                                'flex-cent', css(s.count, !isChecked(option.id) && s.countColor
                            ))}> */}
                    {/*     {option.count} */}
                    {/* </span> */}
                </li>
            )) : range(5).map((key) => (
                <li key={key} className="align-center gap-2">
                    <LoadingSkeleton inline width={16} height={16} />
                    <LoadingSkeleton inline width={100} height={13} />
                </li>
            ))}

            {!loadInfo.loading && loadInfo.ref && <div ref={loadInfo.ref} />}
        </ul>
    )
}

export default function FullSection({ setFullSection, section, setFilter, filter }) {
    function onSelect(id) {
        if (filter.tag !== id) {
            setFilter((prev) => ({ ...prev, tag: id }))
        } else {
            const { tag: sectionFilter, ...clearedFilter } = filter

            setFilter(clearedFilter)
        }
    }

    const isSelected = (id) => filter.tag && filter.tag === id

    return (
        <div className="is-flex direction-column full-height">
            <div className={cn('is-flex direction-column gap-3', css(s.topic))}>
                <div className="align-center gap-1">
                    <ChevronPrev
                        pointer
                        color={COLORS.dark}
                        onClick={() => setFullSection({ act: false })} />

                    <h3 className={css(s.title)}>
                        {section.meta.title || section.title}

                        {!isEmpty(filter[section.name]) && (
                            <span onClick={() => {
                                const { [section.name]: sectionFilter, ...clearedFilter } = filter
                                setFilter(clearedFilter)
                            }}>
                                Очистить
                            </span>
                        )}
                    </h3>
                </div>

                {!section.isTag && (
                    <SearchInput
                        placeholder={section.placeholder}
                        className={css(s.input)}
                        onChange={() => {}}
                        magnifierColor={COLORS.midGray} />
                )}
            </div>

            <div className={css(s.cont)}>
                {section.isTag ? (
                    <div className="is-flex direction-column gap-3">
                        <div className="is-flex is-flex-wrap gap-1">
                            {section.options.map((tag) => (
                                <span
                                    key={tag.id}
                                    onClick={() => onSelect(tag.id)}
                                    className={cn(
                                        'flex-cent pointer', activeTag(tag.color, isSelected(tag.id)), css(s.tag),
                                    )}>
                                    {tag.name}
                                </span>
                            ))}
                        </div>
                    </div>
                ) : (
                    <ListSection section={section} filter={filter} setFilter={setFilter} />
                )}
            </div>
        </div>
    )
}

const s = StyleSheet.create({
    topic: {
        padding: '16px 16px 12px',
        borderBottom: '1px solid #EDEDEE',
    },
    tag: {
        height: 19,
        borderRadius: 4,
        fontSize: 11,
        fontWeight: '500',
        boxSizing: 'border-box',
        padding: '4px 6px',
    },
    cont: {
        padding: '12px 10px 12px 16px',
        height: 'calc(100% - 41px)',
        boxSizing: 'border-box',
        overflowY: 'auto',
    },
    title: {
        fontWeight: '600',
        fontSize: 14,
        color: COLORS.dark,
        whiteSpace: 'nowrap',
        ':nth-child(1n) > span': {
            cursor: 'pointer',
            fontSize: 10,
            marginLeft: 4,
            textTransform: 'uppercase',
            color: COLORS.skyblue,
        },
    },
    label: {
        fontSize: 11,
        fontWeight: '500',
        color: COLORS.lightBlack,
    },
    labelColor: {
        color: COLORS.gray,
    },
    input: {
        width: '100%',
        height: 24,
        background: '#FAFAFA',
        border: '1px solid rgba(0, 0, 0, 0.06)',
        padding: '0 8px',
        ':nth-child(1n) > input': {
            fontSize: 11,
            paddingTop: 3,
            fontWeight: '400',
            '::placeholder': {
                color: COLORS.midGray,
            },
            color: COLORS.dark,
        },
        ':hover': {
            border: '1px solid rgba(0, 0, 0, 0.06)',
            boxShadow: 'none',
        },
    },
    count: {
        border: '1px solid #2EA97D33',
        borderRadius: 4,
        background: '#FAFFFC',
        color: COLORS.mainColor,
        fontSize: 10,
        fontWeight: '500',
        height: 16,
        padding: '0 4px',
    },
    countColor: {
        color: COLORS.midGray,
        background: COLORS.smoothGray,
        border: `1px solid ${COLORS.smoothGray}`,
    },
})

export const activeTag = (color, isActive) => css(StyleSheet.create({
    main: {
        color: isActive ? COLORS.white : COLORS.gray,
        border: `1px solid ${isActive ? 'transparent' : COLORS.smoothGray}`,
        background: isActive ? `${color}` : COLORS.lotion,
        ':nth-child(1n) > :first-child': {
            ':nth-child(1n) > :first-child': {
                ':nth-child(1n) > *': {
                    stroke: color,
                },
            },
        },
    },
}).main)
