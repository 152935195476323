import React from 'react'
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom'
import uuid from 'react-uuid'
import GlobalContext from './contexts/GlobalContext'
import { ProtectedRoute } from './components/common/ProtectedRoute'
import { protectedRoutes } from './utils/routes'
import BaseContextWrapper from './contexts/BaseContextWrapper'
import FilterContextWrapper from './contexts/FilterContext'
import NotFound from './components/errors/NotFound'
import { AutoLogger } from './components/AutoLogger'

export default function App() {
    return (
        <Router>
            <GlobalContext>
                <BaseContextWrapper>
                    <FilterContextWrapper>
                        <Routes>
                            {protectedRoutes.map(({
                                path, protectedBy, redirectPath, component: Component, permission = '',
                            }) => (
                                <Route
                                    key={uuid()}
                                    path={path}
                                    element={(
                                        <ProtectedRoute
                                            path={path}
                                            permission={permission}
                                            protectedBy={protectedBy}
                                            redirectPath={redirectPath}>
                                            <Component />
                                        </ProtectedRoute>
                                    )} />
                            ))}

                            <Route path="/" element={<Navigate to="/dashboard" replace />} />
                            <Route path="/autologin/:token" element={<AutoLogger />} />
                            <Route path="*" element={<NotFound />} />
                        </Routes>
                    </FilterContextWrapper>
                </BaseContextWrapper>
            </GlobalContext>
        </Router>
    )
}
