import React from 'react'
import { css, StyleSheet } from 'aphrodite'
import { COLORS } from '../../utils/colors'

export default function FilterTabs({ tabs, activeTab, setActiveTab }) {
    return (
        <div className={css(s.wrapper)}>
            {tabs.map((tab) => (
                <div
                    key={tab.key}
                    className={css(s.tab)}
                    onClick={() => setActiveTab(tab.name)}
                    // eslint-disable-next-line react/no-unknown-property
                    datatype={activeTab === tab.name ? 'selected' : undefined}>
                    <h3 className={css(activeTab !== tab.name ? s.typeTxt : s.actTypeTxt)}>
                        {tab.label}
                    </h3>

                    <div className={css(s.ind, activeTab === tab.name && s.actInd)} />
                </div>
            ))}
        </div>
    )
}

const s = StyleSheet.create({
    wrapper: {
        height: 41,
        boxSizing: 'border-box',
        borderBottom: '1px solid #EDEDEE',
        padding: '16px 1rem 0',
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'flex-start',
        // transition: 'all 0.5s linear',
        gap: '0.5rem',
    },
    actInd: {
        backgroundColor: '#2EA97D',
    },
    actTypeTxt: {
        color: COLORS.mainColor,
        fontSize: 12,
        fontWeight: '600',
        whiteSpace: 'nowrap',
    },
    typeTxt: {
        fontSize: 12,
        fontWeight: '600',
        color: COLORS.gray,
        whiteSpace: 'nowrap',
    },
    tab: {
        cursor: 'pointer',
        ':not([datatype=selected])': {
            ':hover': {
                ':nth-child(1n) > :last-child': {
                    backgroundColor: '#969F9B',
                },
            },
        },
    },
    ind: {
        width: '100%',
        height: 3,
        marginTop: 8,
        borderTopLeftRadius: 12,
        borderTopRightRadius: 12,
        marginBottom: -1,
    },
})
