import React from 'react'
import cn from 'classnames'
import { css, StyleSheet } from 'aphrodite'
import { convertToHTML } from 'draft-convert'
import DOMPurify from 'isomorphic-dompurify'
import { COLORS } from '../../../utils/colors'

export default function Description({ values }) {
    const description = typeof values.description === 'object'
        ? convertToHTML(values.description.getCurrentContent()) : DOMPurify.sanitize(values.description)

    return (
        <div className={cn('is-flex direction-column gap-5')}>
            <h3 className={css(s.name)}>Описание вакансии:</h3>

            <div
                className={css(s.desc)}
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: description }} />
        </div>
    )
}
const s = StyleSheet.create({
    topic: {
        padding: 24,
        borderBottom: `1px solid ${COLORS.lightGray}`,
    },
    name: {
        color: COLORS.dark,
        fontSize: 20,
        fontWeight: '600',
    },
    desc: {
        color: COLORS.midGray,
        fontSize: 14,
        fontWeight: '500',
        overflowWrap: 'anywhere',
        ':nth-child(1n) > ol': {
            padding: '15px !important',
        },
        ':nth-child(1n) > ul': {
            listStyleType: 'none',
            ':nth-child(1n) > *': {
                '::before': {
                    content: '"\\2022"',
                    color: COLORS.midGray,
                    display: 'inline-block',
                    width: '1em',
                    // fontSize: '1.5em',
                    marginLeft: 0,
                },
            },
        },
    },
})
