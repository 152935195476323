import React from 'react'
import { Form, Formik } from 'formik'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import { isEmpty } from 'lodash'
import { useNavigate } from 'react-router-dom'
import FormInput from '../../components/common/FormInput'
import { email, required, validator } from '../../utils/validators'
import Button from '../../components/common/Button'
import { usePostRequest } from '../../hooks/request'
import { FORGOT_PASSWORD } from '../../urls'
import { GoBack } from '../../components/svgs/Svgs'
import { COLORS } from '../../utils/colors'
import LayoutAuth from '../../components/common/LayoutAuth'
import { useMessage } from '../../hooks/message'

export default function RequestResetPassword() {
    const navigate = useNavigate()
    const [showMessage] = useMessage()
    const forgot = usePostRequest({ url: FORGOT_PASSWORD })

    async function onSubmit(data) {
        const { response, error, success } = await forgot.request({ data: { username: data.email } })

        if (success) {
            localStorage.setItem('reset-password', JSON.stringify(response.data))
            showMessage('Ссылка отправлен на вашу почту', 'success-msg')
            return
        }

        showMessage(error.data.errors[0].message || error.data.message, 'error-msg')
    }

    return (
        <LayoutAuth>
            <div className={css(s.cont)}>
                <span onClick={() => navigate(-1)} className={cn('flex-cent pointer', css(s.goBack))}><GoBack /></span>

                <div className={css(s.form)}>
                    <h3>Забыли пароль?</h3>

                    <p className={css(s.helpText)}>
                        Отправьте вашу элекронную почту, и мы отправим ссылку с подтверждением
                    </p>

                    <Formik
                        onSubmit={(data) => onSubmit(data)}
                        initialValues={{ email: '' }}>
                        {({ errors, values }) => (
                            <React.Fragment>
                                <Form>
                                    <FormInput
                                        validate={validator(required, email)}
                                        placeholder="Например “abcdef@gmail.com”"
                                        name="email"
                                        label="Электронная почта"
                                        fieldClassName={css(s.field)}
                                        labelClassName={css(s.lab)}
                                    />

                                    <Button
                                        type="submit"
                                        className={cn(
                                            css(s.btn), { [css(s.sbmBtn)]: isEmpty(errors) && values.email !== '' },
                                        )}>
                                        Далее
                                    </Button>
                                </Form>
                            </React.Fragment>
                        )}
                    </Formik>
                </div>
            </div>
        </LayoutAuth>
    )
}

const s = StyleSheet.create({
    cont: {
        margin: 'auto',
        maxWidth: 'calc(29rem - 1px)',
        display: 'flex',
        alignItems: 'flex-start',
        gap: 'calc(2rem - 1px)',
    },
    form: {
        width: 'calc(24.75rem - 1px)',
        ':nth-child(1n) > :first-child': {
            fontSize: 36,
            color: '#181A19',
            fontWeight: '600',
        },
    },
    lab: {
        fontSize: 12,
        fontWeight: '500',
        color: COLORS.midGray,
        marginBottom: 8,
    },
    field: {
        position: 'relative',
        paddingBottom: '6.4%',
    },
    btn: {
        width: '100%',
        padding: '12px 0',
        background: COLORS.gray,
        // eslint-disable-next-line max-len
        boxShadow: '0 4px 12px rgba(44, 67, 50, 0.1), inset 0px -1px 0px rgba(0, 0, 0, 0.25), inset 0px 1px 0px rgba(255, 255, 255, 0.1)',
        borderRadius: 4,
        color: '#FFFFFF',
        fontSize: 14,
        fontWeight: '500',
    },
    sbmBtn: {
        background: `linear-gradient(180deg, #2EA97D 0%, ${COLORS.lightMainColor} 100%)`,
        // eslint-disable-next-line max-len
        boxShadow: '0px 4px 12px rgba(44, 67, 50, 0.1), inset 0px -1px 0px rgba(0, 0, 0, 0.25), inset 0px 1px 0px rgba(255, 255, 255, 0.1)',
    },
    helpText: {
        color: COLORS.midGray,
        fontSize: 14,
        fontWeight: '500',
        marginTop: 13,
        marginBottom: 31,
        letterSpacing: '-0.25px',
    },
    goBack: {
        marginTop: 2,
        width: '2.25rem',
        height: '2.25rem',
        background: COLORS.lotion,
        borderRadius: 64,
        ':nth-child(1n) > :first-child': {
            ':nth-child(1n) > :first-child': {
                stroke: '#181A19',
            },
        },
    },
})
