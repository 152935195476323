import React from 'react'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import { DisplayByColumnSizeL,
    DisplayByGrid,
    DisplayByScroll } from '../../../svgs/Svgs'
import { COLORS } from '../../../../utils/colors'

export function Header({ setSwitchView, switchView, totalCount, companyName }) {
    return (
        <div className={css(s.companyVacHeader)}>
            <div className="align-center gap-2">
                <h3 className={css(s.title)}>Вакансии {companyName} <span>•</span> {totalCount}</h3>
            </div>

            <div className="align-center gap-5">
                <div className={css(s.dpl)}>
                    <span
                        onClick={() => setSwitchView('column')}
                        className={cn(css(s.col), { [css(s.actCol)]: switchView === 'column' })}>
                        <DisplayByScroll />
                    </span>

                    <span
                        onClick={() => setSwitchView('grid')}
                        className={cn(css(s.col), { [css(s.actCol)]: switchView === 'grid' })}>
                        <DisplayByGrid />
                    </span>

                    <span
                        onClick={() => setSwitchView('table')}
                        className={cn(css(s.col), { [css(s.actCol)]: switchView === 'table' })}>
                        <DisplayByColumnSizeL />
                    </span>
                </div>
            </div>
        </div>
    )
}

const s = StyleSheet.create({
    companyVacHeader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '0 24px',
        '@media (max-width: 1024px)': {
            padding: '0 16px',
            gap: 12,
        },
    },
    title: {
        gap: 8,
        fontWeight: '700',
        fontSize: 20,
        color: COLORS.lightBlack,
        ':nth-child(1n) > :first-child': { color: COLORS.green },
        ':nth-child(1n) > :last-child': {
            color: COLORS.gray,
            fontWeight: '500',
        },
        '@media (max-width: 768px)': {
            fontSize: 16,
            gap: 4,
        },
    },
    dpl: {
        display: 'flex',
        alignItems: 'center',
        ':nth-child(1n) > *': {
            padding: 4,
            display: 'inline-flex',
            cursor: 'pointer',
        },
        ':nth-child(1n) > :first-child': {
            borderTopLeftRadius: 4,
            borderBottomLeftRadius: 4,
        },
        ':nth-child(1n) > :last-child': {
            borderTopRightRadius: 4,
            borderBottomRightRadius: 4,
            '@media (max-width: 1024px)': {
                display: 'none',
            },
        },
    },
    col: {
        background: '#fff',
        ':not(:last-of-type)': {
            ':nth-child(1n) > :first-child': {
                ':nth-child(1n) > *': {
                    stroke: '#92929D',
                },
            },
            ':last-of-type': {
                ':nth-child(1n) > :first-child': {
                    ':nth-child(1n) > *': {
                        fill: '#92929D',
                    },
                },
            },
        },
    },
    actCol: {
        background: COLORS.mainColor,
        ':not(:last-of-type)': {
            ':nth-child(1n) > :first-child': {
                ':nth-child(1n) > *': {
                    stroke: '#FFF',
                },
            },
        },
        ':last-of-type': {
            ':nth-child(1n) > :first-child': {
                ':nth-child(1n) > *': {
                    fill: '#FFF',
                },
            },
        },
    },
})
