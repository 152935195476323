import React, { memo } from 'react'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import InputForm from '../../common/InputForm'
import { InfoSizeM } from '../../svgs/Svgs'
import { COLORS } from '../../../utils/colors'

function MemoizedComponent() {
    return (
        <div className={css(s.box)}>
            <div className={cn('align-center gap-2', css(s.formName))}>
                <InfoSizeM />
                <h3>Доп. параметры</h3>
            </div>

            <div className={cn('is-flex direction-column gap-7', css(s.cont))}>
                <InputForm
                    name="comment"
                    component="textarea"
                    placeholder="Введите комментарий"
                    label="Комментарий"
                    labelClassName={css(s.lbAboutEvent)}
                    className={cn(css(s.inp), css(s.commentAreaField))}
                />
            </div>
        </div>
    )
}

export const AdditionalOptions = memo(MemoizedComponent)

const s = StyleSheet.create({
    box: {
        background: COLORS.white,
        border: `1px solid ${COLORS.smoothGray}`,
        boxShadow: '0px 2px 4px rgba(130, 130, 130, 0.05)',
        borderRadius: 4,
    },
    formName: {
        height: 'calc(3rem - 1px)',
        padding: '0 22px 0 24px',
        fontSize: 13,
        fontWeight: '600',
        color: '#181A19',
        borderBottom: `1px solid ${COLORS.lightGray}`,
        textTransform: 'uppercase',
    },
    cont: {
        padding: '15px 1.5rem 1.5rem',
    },
    inp: {
        width: '100%',
        border: `1px solid ${COLORS.lightGray}`,
        borderRadius: 4,
        padding: '14px 16px',
        ':first-of-type': {
            paddingTop: 15,
        },
    },
    commentAreaField: {
        height: 'calc(8.5rem - 25px)',
    },
    lbAboutEvent: {
        paddingBottom: 8,
    },
})
