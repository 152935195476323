import cn from 'classnames'
import { css, StyleSheet } from 'aphrodite'
import React from 'react'
import { isEmpty } from 'lodash'
import DOMPurify from 'isomorphic-dompurify'
import { BillCircle,
    Briefcase,
    DisplayByRowSizeM,
    HourGlass,
    LightingSizeM,
    RecruiterVacancies,
    Sitemap,
    Time,
    UserCheck } from '../svgs/Svgs'
import { LoadingSkeleton } from '../common/LoadingSkeleton'
import { COLORS } from '../../utils/colors'
import { fromToStr } from '../../utils/string'
import { format } from '../../utils/number'
import { getFullDate } from '../../utils/date'
import { Experience } from '../../utils/options'

export function MainInfo({ data, isShort, loading }) {
    const dangerouslySetDescription = DOMPurify.sanitize(data.description)

    return (
        <div className={cn(css(s.wrap), { [css(s.shortWrap)]: isShort })}>
            <div>
                <ul className={cn('no_dot_list is-flex direction-column gap-2', css(s.infLst))}>
                    <li className={css(s.infItm)}>
                        <div className="align-center gap-2">
                            <DisplayByRowSizeM /> <h3>Колонка</h3>
                        </div>

                        <h3>{!loading
                            ? data.columnName || <div>&#8212;&#8212;&#8212;</div>
                            : <LoadingSkeleton width="8rem" height={22} />}
                        </h3>
                    </li>

                    <hr className={css(s.hr)} />

                    <li className={css(s.infItm)}>
                        <div>
                            <LightingSizeM /> <h3>Статус</h3>
                        </div>

                        {!loading ? !isEmpty(data.tags) ? (
                            <div className={cn('is-flex gap-2', css(s.tags))}>
                                {data.tags.map((tag) => (
                                    <span
                                        key={tag.id}
                                        className={cn('align-center gap-1 pointer', tagStyles(tag.color))}>
                                        {tag.name}
                                    </span>
                                ))}
                            </div>
                        ) : <div>&#8212;&#8212;&#8212;</div> : <LoadingSkeleton width="8rem" height={22} />}
                    </li>

                    <hr className={css(s.hr)} />

                    <li className={css(s.infItm)}>
                        <div>
                            <RecruiterVacancies /> <h3>В отдел</h3>
                        </div>

                        <h3>{!loading
                            ? data.department
                                ? data.department.name
                                : <div>&#8212;&#8212;&#8212;</div>
                            : <LoadingSkeleton width="8rem" height={22} />}
                        </h3>
                    </li>

                    <hr className={css(s.hr)} />

                    <li className={css(s.infItm)}>
                        <div>
                            <Sitemap /> <h3>Должность</h3>
                        </div>

                        <h3>{!loading
                            ? data.position
                                ? data.position.name
                                : <div>&#8212;&#8212;&#8212;</div>
                            : <LoadingSkeleton width="8rem" height={22} />}
                        </h3>
                    </li>

                    <hr className={css(s.hr)} />

                    <li className={css(s.infItm)}>
                        <div>
                            <UserCheck /> <h3>Сотрудников необходимо</h3>
                        </div>

                        <h3>
                            {!loading
                                ? data.employeeCount : <LoadingSkeleton width="8rem" height={22} /> }
                        </h3>
                    </li>

                    <hr className={css(s.hr)} />

                    <li className={css(s.infItm)}>
                        <div>
                            <Time /> <h3>Дата начала</h3>
                        </div>

                        <h3>
                            {!loading
                                ? (data.startAt
                                    ? getFullDate(data.startAt, true)
                                    : <div>&#8212;&#8212;&#8212;</div>)
                                : <LoadingSkeleton width="8rem" height={22} />}
                        </h3>
                    </li>

                    <hr className={css(s.hr)} />

                    <li className={css(s.infItm)}>
                        <div>
                            <HourGlass /> <h3>Дедлайн</h3>
                        </div>

                        <h3>
                            {!loading
                                ? (data.deadline
                                    ? getFullDate(data.deadline, true)
                                    : <div>&#8212;&#8212;&#8212;</div>)
                                : <LoadingSkeleton width="8rem" height={22} />}
                        </h3>
                    </li>

                    <hr className={css(s.hr)} />

                    <li className={css(s.infItm)}>
                        <div>
                            <BillCircle /> <h3>Зарплатная вилка</h3>
                        </div>

                        <h3>
                            {!loading && data.salary
                                // eslint-disable-next-line max-len
                                ? fromToStr(data.salary.from ? format(data.salary.from) : '', data.salary.to ? format(data.salary.to) : '', data.currency, <div>&#8212;&#8212;&#8212;</div>)
                                : <LoadingSkeleton width="8rem" height={22} />}
                        </h3>
                    </li>

                    <hr className={css(s.hr)} />

                    <li className={css(s.infItm)}>
                        <div>
                            <Briefcase /> <h3>Опыт работы</h3>
                        </div>

                        <h3>
                            {!loading ? data.experience
                                && typeof data.experience === 'number'
                                ? Experience[data.experience].label : Experience[0].label
                                : <LoadingSkeleton width="8rem" height={22} />}
                        </h3>
                    </li>
                </ul>
            </div>

            <div className={css(s.stick)} />

            {data.description && (
                <div className={cn('is-flex direction-column gap-3', css(s.desc))}>
                    <h3>Описание вакансии</h3>

                    {/* eslint-disable-next-line react/no-danger */}
                    <div dangerouslySetInnerHTML={{ __html: dangerouslySetDescription }} />
                </div>
            )}
        </div>
    )
}

const s = StyleSheet.create({
    wrap: {
        height: '100%',
        display: 'grid',
        gridTemplateColumns: '1fr auto 1fr',
        padding: '0 1.5rem',
        gap: '1.5rem',
        transition: 'all 0.5s linear',
        ':nth-child(1n) > :first-child': {
            overflowY: 'auto',
            scrollbarGutter: 'stable',
        },
    },
    shortWrap: {
        // display: 'none',
        // transition: 'all 0.5s linear',
    },
    infLst: {
        overflow: 'scroll',
        padding: '1rem 0 1.5rem 0',
    },
    infItm: {
        color: COLORS.midGray,
        fontSize: 13,
        minHeight: 24,
        fontWeight: '500',
        display: 'grid',
        alignItems: 'center',
        gridTemplateColumns: '45% 55%',
        ':not(:first-of-type)': {
            ':nth-child(1n) > :first-child': {
                display: 'flex',
                alignItems: 'center',
                gap: 8,
                ':nth-child(1n) > :first-child': {
                    ':nth-child(1n) > *': {
                        stroke: COLORS.spanishGray,
                    },
                },
            },
        },
        ':nth-child(1n) > :last-child': {
            color: COLORS.dark,
        },
    },
    hr: {
        border: `0.5px solid ${COLORS.smoothGray}`,
        borderRadius: 12,
        margin: 0,
    },
    tags: {
        flexWrap: 'wrap',
    },
    desc: {
        overflowY: 'auto',
        scrollbarGutter: 'stable',
        color: COLORS.dark,
        padding: '1rem 1rem 1.5rem 0',
        ':nth-child(1n) > :first-child': {
            fontSize: 14,
            fontWeight: '500',
            display: 'block',
        },
    },
    stick: {
        height: '100%',
        width: 1,
        backgroundColor: COLORS.smoothGray,
    },
})

const tagStyles = (color) => css(StyleSheet.create({
    main: {
        color,
        height: 'calc(1.5rem - 2px)',
        padding: '0 6px',
        borderRadius: 4,
        fontWeight: '500',
        fontSize: 11,
        whiteSpace: 'nowrap',
        outline: 'none',
        border: `1px solid ${color + 33}`,
        background: `${color}1A`,
        ':nth-child(1n) > :first-child': {
            ':nth-child(1n) > :first-child': {
                ':nth-child(1n) > *': {
                    stroke: color,
                },
            },
        },
    },
}).main)
