import React from 'react'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import { COLORS } from '../../../utils/colors'
import { PieChart } from '../../common/PieChart'
import Table from '../../common/Table'
import { useLoad } from '../../../hooks/request'
import { REPORT_VACANCY_INFO_CHART } from '../../../urls'
import { strCutter } from '../../../utils/string'

const calculatePercentage = (data) => {
    let newData = data
    const totalCount = data.reduce((acc, item) => acc + item.count, 0)

    if (data.length > 10) {
        const otherDepTotalCount = data.slice(9).reduce((acc, item) => acc + item.count, 0)
        newData = [...data.slice(0, 9), { name: 'Другие отделы', count: otherDepTotalCount }]
    }

    return {
        data: newData.map(({ count, name }, index) => (
            { index, name, value: count, percent: ((count / totalCount) * 100).toFixed(2) }
        )),
        totalCount,
    }
}

function TableHeader() {
    return (
        <thead>
            <tr>
                <th className={css(s.hItem)}>Отдел</th>

                <th className={css(s.hItem)}>Кол-во</th>

                <th className={css(s.hItem)}>Процент</th>
            </tr>
        </thead>
    )
}

const TableItem = ({ name, value, percent, index, colorsPalette }) => (
    <tr className={css(s.item)}>
        <td className={css(s.dItem)}>
            <div className="align-center gap-2">
                <div className={markStyleSheet(colorsPalette[index])} />

                <h3 title={name}>{strCutter(name, 24)}</h3>
            </div>
        </td>

        <td className={css(s.dItem)}>{value}</td>

        <td className={css(s.dItem)}>{percent}%</td>
    </tr>
)

export default function Chart({ params, colorsPalette }) {
    const loadChartData = useLoad({ url: REPORT_VACANCY_INFO_CHART, params }, [params])
    const data = loadChartData.response ? loadChartData.response.data : []

    const { data: chartData, totalCount } = calculatePercentage(data)

    return chartData.length > 0 && (
        <div className={css(s.wrap)}>
            <div className={cn('justify-between', css(s.header))}>
                <h3 className={css(s.topic)}>За этот период создано {totalCount} вакансий</h3>

                {/* <CloseSizeM color="#343836" /> */}
            </div>

            <div className={cn('justify-center flex-1', css(s.cont))}>
                <PieChart
                    data={chartData}
                    colors={colorsPalette}
                    labelText={[totalCount, 'Вакансий создано']}
                />

                <div className={cn('align-center', css(s.stats))}>
                    <Table
                        items={chartData.slice(0, 5)}
                        cssClassName={s.table}
                        customHeader={<TableHeader />}
                        renderItem={({ name, value, percent }, index) => (
                            <TableItem
                                name={name}
                                index={index}
                                value={value}
                                percent={percent}
                                colorsPalette={colorsPalette}
                            />
                        )}
                    />

                    {chartData.length > 5 && (
                        <Table
                            items={chartData.slice(5)}
                            cssClassName={s.table}
                            customHeader={<TableHeader />}
                            renderItem={({ name, value, percent }, index) => (
                                <TableItem
                                    name={name}
                                    index={index + 5}
                                    value={value}
                                    percent={percent}
                                    colorsPalette={colorsPalette}
                                />
                            )}
                        />
                    )}
                </div>
            </div>
        </div>
    )
}

const s = StyleSheet.create({
    wrap: {
        borderRadius: 2,
        border: `1px solid ${COLORS.smoothGray}`,
        background: '#FFF',
        boxShadow: '0px 2px 4px 0px rgba(130, 130, 130, 0.05)',
    },
    topic: {
        color: COLORS.dark,
        fontSize: 16,
        fontWeight: '600',
    },
    header: {
        height: 40,
        padding: '0 24px',
        borderBottom: `1px solid ${COLORS.lightGray}`,
    },
    table: {
        border: 'none',
        borderSpacing: '12px 12px',
        borderCollapse: 'separate',
    },
    hItem: {
        fontSize: 12,
        fontWeight: '500',
        textAlign: 'start',
        color: COLORS.midGray,
    },
    dItem: {
        fontSize: 14,
        fontWeight: '500',
        textAlign: 'start',
        color: COLORS.dark,
    },
    cont: {
        maxWidth: '90%',
        padding: '20px 0 24px',
        margin: 'auto',
        gap: 72,
    },
    stats: {
        gap: 48,
    },
})

const markStyleSheet = (backgroundColor) => css(StyleSheet.create({
    main: {
        width: 18,
        height: 18,
        borderRadius: 4,
        backgroundColor,
    },
}).main)
