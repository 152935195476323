import React, { useEffect, useState } from 'react'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import moment from 'moment'
import VacancyTable from './VacancyTable'
import Filter from './Filter'
import { useInfiniteScroll } from '../../../hooks/request'
import { REPORT_VACANCY_METRICS } from '../../../urls'
import { getFullDate } from '../../../utils/date'
import NoVacanciesByFilter from '../NoVacanciesByFilter'
import NoVacancies from '../NoVacancies'

const today = moment()

const initialParams = {
    periodEnd: getFullDate(today.toDate()),
    periodStart: getFullDate(today.clone().subtract(29, 'days').toDate()),
    isReinitialized: false,
}

export default function VacancyMetrics({ formikRef, vacancies, vacancyLoading }) {
    const [params, setParams] = useState(initialParams)
    const loadMetrics = useInfiniteScroll({ url: REPORT_VACANCY_METRICS, params })
    const metrics = loadMetrics.response ? loadMetrics.response.data : []

    useEffect(() => {
        if (params.isReinitialized) {
            loadMetrics.reset()
        }
    }, [params])

    return (
        <>
            <Filter vacancies={vacancies} loadMetrics={loadMetrics} formikRef={formikRef} setParams={setParams} />

            <div className={cn('is-flex direction-column gap-7', css(s.cont))}>
                {metrics.length ? metrics.map((item) => (
                    <VacancyTable key={item.id} item={item} params={params} />
                )) : null}

                {loadMetrics.loading ? <VacancyTable loading /> : null}

                {!loadMetrics.loading && !metrics.length
                    ? !vacancyLoading
                        ? vacancies.length ? <NoVacanciesByFilter /> : <NoVacancies />
                        : <VacancyTable loading />
                    : null }

                {!loadMetrics.loading && loadMetrics.hasMore && <div ref={loadMetrics.ref} />}
            </div>
        </>
    )
}

const s = StyleSheet.create({
    cont: {
        padding: 24,
    },
})
