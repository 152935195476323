import React from 'react'
import cn from 'classnames'
import { css, StyleSheet } from 'aphrodite'
import { COLORS } from '../../../utils/colors'
import { LoadingSkeleton } from '../../common/LoadingSkeleton'

export function HeaderSkeleton() {
    return (
        <div className={cn('full-width justify-between', css(s.cont))}>
            <div className="align-center">
                <div className="align-center gap-2">
                    <LoadingSkeleton height={18} width={112} /> • <LoadingSkeleton height={18} width={65} />
                </div>

                <div className="is-flex direction-column">
                    <h3 className={css(s.title)}>Дата начала и окончания</h3>

                    <LoadingSkeleton height={14} width={112} />
                </div>

                <div className="is-flex direction-column">
                    <h3 className={css(s.title)}>Зарплатная вилка</h3>

                    <LoadingSkeleton height={14} width={112} />
                </div>
            </div>
        </div>
    )
}

export function TableItemSkeleton() {
    return (
        <tr className={css(s.item)}>
            <td>
                <div className="flex-cent">
                    <LoadingSkeleton width={16} height={14} />
                </div>
            </td>

            <td className="text-start"><LoadingSkeleton width={112} height={14} /></td>

            <td className="text-start"><LoadingSkeleton width={112} height={14} /></td>

            <td className="text-start"><LoadingSkeleton width="80%" height={14} /></td>

            <td className="text-start"><LoadingSkeleton width={112} height={14} /></td>

            <td className="text-start">
                <div className={cn('align-center gap-1', css(s.math))}>
                    <LoadingSkeleton width={24} height={14} />

                    <span>+</span>

                    <LoadingSkeleton width={24} height={14} />

                    <span>+</span>

                    <LoadingSkeleton width={24} height={14} />

                    <span>=</span>

                    <LoadingSkeleton width={24} height={14} />
                </div>

            </td>

            <td className="text-start">
                <div className={cn('align-center gap-1', css(s.math))}>
                    <LoadingSkeleton width={24} height={14} />

                    <span>+</span>

                    <LoadingSkeleton width={24} height={14} />

                    <span>+</span>

                    <LoadingSkeleton width={24} height={14} />

                    <span>=</span>

                    <LoadingSkeleton width={24} height={14} />
                </div>
            </td>

            <td className="text-start">
                <div className={cn('align-center gap-1', css(s.math))}>
                    <LoadingSkeleton width={24} height={14} />

                    <span>+</span>

                    <LoadingSkeleton width={24} height={14} />

                    <span>+</span>

                    <LoadingSkeleton width={24} height={14} />

                    <span>=</span>

                    <LoadingSkeleton width={24} height={14} />
                </div>
            </td>

            <td className="text-start"><LoadingSkeleton width={24} height={14} /></td>
        </tr>
    )
}

const s = StyleSheet.create({
    cont: {
        padding: '0 16px',
        height: 40,
        borderRadius: '2px 2px 0 0',
        border: '1px solid #EBEBEB',
        boxSizing: 'border-box',
        borderBottom: 'none',
        background: '#EAF4F0',
        color: COLORS.gray,
        ':nth-child(1n) > :first-child': {
            gap: 32,
        },
    },
    title: {
        color: COLORS.midGray,
        fontSize: 9,
        fontWeight: '500',
    },

    item: {
        height: '1.875rem',
        background: COLORS.white,
        ':nth-child(1n) > :not(:first-of-type)': {
            paddingLeft: 12,
        },
        ':nth-child(1n) > :not(:last-of-type)': {
            borderRight: `1px solid ${COLORS.lightGray}`,
        },
    },
    math: {
        color: COLORS.gray,
    },
})
