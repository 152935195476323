import React from 'react'
import Popup from 'reactjs-popup'
import { css, StyleSheet } from 'aphrodite'

export default function PopupSimple({
    on = 'click',
    open,
    nested,
    onOpen,
    onClose,
    items,
    innerRef,
    renderItem,
    trigger,
    children,
    position,
    contentStyle,
    arrow = false,
    ...args
}) {
    return (
        <Popup
            {...args}
            ref={innerRef}
            nested={nested}
            on={on}
            open={open}
            contentStyle={{ ...contentStyle, overflowY: 'auto !important' }}
            onOpen={onOpen}
            onClose={onClose}
            arrow={arrow}
            position={position || 'bottom right'}
            trigger={trigger}>
            {items ? (
                <ul className={css(s.pSet)}>
                    {items.map((item) => renderItem(item))}
                </ul>
            ) : children }
        </Popup>
    )
}

const s = StyleSheet.create({
    pSet: {
        borderRadius: 4,
        margin: 0,
        ':nth-child(1n) > *': {
            color: '#fff',
            fontSize: 12,
            background: 'rgba(65, 70, 68, 0.9)',
            backdropFilter: 'blur(8px)',
            fontWeight: '500',
            padding: '10px 12px',
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
            borderBottom: '1px solid #5e6360',
            ':hover': {
                background: 'rgba(87, 90, 89, 0.9)',
            },
            ':nth-child(1n) > :first-child': {
                marginRight: 8,
            },
        },
        ':nth-child(1n) > :last-child': {
            borderBottomWidth: 0,
            borderBottomLeftRadius: 4,
            borderBottomRightRadius: 4,
        },
        ':nth-child(1n) > :first-child': {
            borderTopLeftRadius: 4,
            borderTopRightRadius: 4,
        },
    },
})
