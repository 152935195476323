import React, { useRef, useState } from 'react'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import { COMPANY_INFO, COMPANY_UPDATE } from '../../../urls'
import { useLoad, usePutRequest } from '../../../hooks/request'
import { cp } from '../../../utils/checkPermission'
import { Popup } from '../../Popup'
import { SettingsLayout, Share } from '../../svgs/Svgs'
import ControlSettings from './ControlSettings'
import Modal from '../../common/Modal'
import ShareModal from './ShareModal'
import { InstructionNotification } from './InstructionNotification'
import Banner from './Banner'
import Vacancies from './vacancies/Vacancies'
import { useMessage } from '../../../hooks/message'
import { COLORS } from '../../../utils/colors'

export default function PublicView() {
    const loadInfo = useLoad({ url: COMPANY_INFO })
    const popupRef = useRef()
    const [showMessage] = useMessage()
    const [showModal, setShowModal] = useState(false)
    const updateSettings = usePutRequest({ url: COMPANY_UPDATE })

    const info = loadInfo.response ? loadInfo.response.data : []

    async function handleToggleChange(name, isChecked) {
        const { success, error } = await updateSettings.request({ data: { [name]: isChecked } })

        if (success) {
            showMessage('Успешно изменено', 'success-msg')
            loadInfo.setResponse({ success: true, data: { ...loadInfo.response.data, [name]: isChecked } })
        }

        showMessage(error.data.errors[0].message || error.data.message, 'error-msg')
    }

    return (
        <div className={css(s.cont)}>
            <div className={css(s.section)}>
                <div className={css(s.companyHeader)}>
                    <h3 className={css(s.title)}>Страница о компании</h3>

                    <div className="align-center gap-5">
                        {cp('settings.publicCompany.page_display') && (
                            <Popup
                                ref={popupRef}
                                activeTriggerClassName={css(s.active)}
                                trigger={(
                                    <div className={cn('align-center gap-2 pointer', css(s.block))}>
                                        <SettingsLayout color="#414644" />

                                        <h3>Настройки страницы</h3>
                                    </div>
                                )}>
                                <ControlSettings
                                    companyInfo={info}
                                    loadInfo={loadInfo}
                                    // eslint-disable-next-line react/jsx-no-bind
                                    handleToggleChange={handleToggleChange}
                                />
                            </Popup>
                        )}

                        <Modal
                            trigger={(
                                <div className={cn('align-center gap-2 pointer', css(s.block))}>
                                    <Share />

                                    <h3>Поделиться</h3>
                                </div>
                            )}
                            open={showModal}
                            closeOnEscape
                            closeOnDocumentClick
                            overlayStyle={{ top: 52 }}
                            onClose={() => setShowModal(false)}
                            onOpen={() => setShowModal(true)}
                        >
                            <ShareModal
                                onCLose={() => setShowModal(false)}
                                shareLink={`${process.env.REACT_APP_JOBS_URL}/c/${info.slug}`}
                            />
                        </Modal>
                    </div>
                </div>

                <InstructionNotification />

                <Banner
                    info={info}
                    loadInfo={loadInfo}
                />
            </div>

            <Vacancies info={info} />
        </div>
    )
}

const s = StyleSheet.create({
    cont: {
        display: 'flex',
        flexDirection: 'column',
        paddingBottom: 32,
        gap: 32,
        '@media (max-width: 768px)': {
            gap: 20,
            paddingBottom: 16,
        },
    },
    section: {
        display: 'flex',
        flexDirection: 'column',
    },
    companyHeader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        margin: '20px 24px 0',
        '@media (max-width: 1024px)': {
            margin: '16px 16px 0 16px',
        },
        '@media (max-width: 768px)': {
            margin: 0,
        },
    },
    title: {
        fontWeight: '600',
        fontSize: 24,
        color: '#181A19',
        '@media (max-width: 768px)': {
            display: 'none',
        },
    },
    block: {
        height: '2rem',
        padding: '0 8px',
        color: '#414644',
        fontSize: 13,
        fontWeight: '500',
        background: '#FFFFFF',
        border: '1px solid #EDEDEE',
        borderRadius: 4,
        ':hover': {
            border: '1px solid #C9D2CE',
            boxShadow: '0px 2px 4px rgba(168, 168, 168, 0.15)',
        },
    },
    active: {
        ':nth-child(1n) > :first-child': {
            color: COLORS.skyblue,
            ':nth-child(1n) > :first-child': {
                ':nth-child(1n) > path': {
                    stroke: COLORS.skyblue,
                },
            },
        },
    },
})
