import React, { memo, useRef, useState } from 'react'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import { Form } from 'formik'
import { range } from 'lodash'
import { EditSizeL, LightingSizeM,
    PlusRoundedSizeL,
    XCircleSizeL } from '../../svgs/Svgs'
import FormInput from '../../common/FormInput'
import { date } from '../../../utils/validators'
import { COLORS } from '../../../utils/colors'
import InputDate from '../../common/InputDate'

function WorkHistoryForm({ values, removeMode, ind, setFieldValue }) {
    const ref = useRef()

    return (
        <div ref={ref} className={css(s.inpGr)}>
            <div
                style={{ borderColor: 'transparent' }}
                className={cn(css(s.edit_field), { [css(s.removable_field)]: removeMode && ind !== 0 })}>
                {removeMode && ind !== 0 && (
                    <XCircleSizeL onClick={() => {
                        // eslint-disable-next-line no-param-reassign
                        values.experience[ind] = {}
                        setFieldValue('experience', values.experience)
                        ref.current.remove()
                    }} />
                )}
                <FormInput
                    name={`experience[${ind}].job`}
                    className={css(s.addInp)}
                    fieldClassName={css(s.fld)}
                    label="Последнее место работы"
                    placeholder="Введите название компании"
                    labelClassName={css(s.addInpLab)} />

                <InputDate
                    isMulti
                    position="left"
                    validate={date}
                    noRequiredValidation
                    label="Дата начала и окончания"
                    name={[`experience[${ind}].start_date`, `experience[${ind}].end_date`]}
                />

                <FormInput
                    name={`experience[${ind}].position`}
                    className={css(s.addInp)}
                    fieldClassName={css(s.fld)}
                    label="Должность"
                    placeholder="Введите"
                    labelClassName={css(s.addInpLab)} />

                <FormInput
                    name={`experience[${ind}].achievements`}
                    className={css(s.addInp)}
                    fieldClassName={css(s.fld)}
                    label="Обязанности и достижения (опционально)"
                    placeholder="Введите"
                    labelClassName={css(s.addInpLab)} />
            </div>
        </div>
    )
}

function MemoizedComponent({ values, setFieldValue }) {
    const [count, setCount] = useState(1)
    const [removeMode, setRemoveMode] = useState(false)

    return (
        <div className={css(s.box)}>
            <div className={cn('justify-between', css(s.formName))}>
                <div className="align-center gap-2">
                    <LightingSizeM /> <h3>опыт работы</h3>
                </div>

                <div className="align-center gap-6">
                    <EditSizeL onClick={() => setRemoveMode(!removeMode)} className="pointer" />

                    <PlusRoundedSizeL onClick={() => setCount(count + 1)} className="pointer" />
                </div>
            </div>

            <Form>
                {range(0, count).map((ind) => (
                    <WorkHistoryForm
                        key={ind}
                        ind={ind}
                        values={values}
                        removeMode={removeMode}
                        setFieldValue={setFieldValue} />
                ))}
            </Form>
        </div>
    )
}

export const WorkHistory = memo(MemoizedComponent)

const s = StyleSheet.create({
    box: {
        background: COLORS.white,
        border: `1px solid ${COLORS.smoothGray}`,
        boxShadow: '0px 2px 4px rgba(130, 130, 130, 0.05)',
        borderRadius: 4,
    },
    formName: {
        height: 'calc(3rem - 1px)',
        padding: '0 22px 0 24px',
        fontSize: 13,
        fontWeight: '600',
        color: '#181A19',
        borderBottom: `1px solid ${COLORS.lightGray}`,
        textTransform: 'uppercase',
        ':nth-child(1n) > :first-child': {
            ':nth-child(1n) > :first-child': {
                ':nth-child(1n) > *': {
                    stroke: '#181A19',
                },
            },
        },
    },
    cont: {
        padding: '15px 1.5rem 1.5rem',
    },
    tags: {
        flexWrap: 'wrap',
    },
    tag: {
        padding: '5px 6px',
        background: COLORS.smoothGray,
        borderRadius: 4,
        border: '1px solid',
        borderColor: 'transparent',
        color: COLORS.midGray,
        fontWeight: '500',
        cursor: 'pointer',
        fontSize: 11,
        outline: 'none',
    },
    wrap: {
        fontWeight: '500',
        fontSize: 12,
        color: COLORS.midGray,
        border: '1px solid transparent',
        borderRadius: 5,
        position: 'relative',
    },
    selected: {
        background: 'rgba(46, 169, 125, 0.03)',
        borderColor: 'rgba(46, 169, 125, 0.2)',
        color: COLORS.mainColor,
    },
    edit_field: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gridGap: 24,
        alignItems: 'start',
        borderWidth: 1,
        borderStyle: 'solid',
        borderRadius: 5,
        position: 'relative',
    },
    removable_field: {
        borderColor: COLORS.lightRed,
        ':nth-child(1n) > :first-child': {
            cursor: 'pointer',
            position: 'absolute',
            right: -12,
            top: -12,
        },
    },
    inpGr: {
        padding: '16px 24px 24px',
        borderBottom: `1px solid ${COLORS.lightGray}`,
        ':last-of-type': {
            borderBottom: 'none',
        },
    },
    lab: {
        ':nth-child(1n) > span': {
            display: 'inline-flex',
        },
    },
    lb: {
        fontSize: 12,
        fontWeight: '500',
        color: COLORS.midGray,
    },
    addInp: {
        position: 'relative',
        border: `1px solid ${COLORS.lightGray}`,
        borderRadius: 4,
        fontSize: 14,
        fontWeight: '500',
        color: '#181A19',
    },
    addInpLab: {
        fontSize: 12,
        fontWeight: '500',
        color: COLORS.midGray,
        marginBottom: 8,
    },
    fld: {
        position: 'relative',
    },
})
