import React from 'react'
import '../../static/css/tooltip.css'
import cn from 'classnames'
import { css, StyleSheet } from 'aphrodite'
import { COLORS } from '../../utils/colors'

export default function Tooltip({ trigger, children, className, arrow = true }) {
    return (
        <div className="t-tip">
            <div className={cn(css(s.tool), 't-ch', className)}>
                {children}
                {arrow ? <div className="arrow" /> : null}
            </div>

            {trigger}
        </div>
    )
}

const s = StyleSheet.create({
    tool: {
        ':nth-child(1n) > :first-child': {
            background: COLORS.lightBlack,
            border: `1px solid ${COLORS.midGray}`,
            borderRadius: 8,
            color: COLORS.white,
            position: 'relative',
            left: -12,
            fontSize: 13,
            fontWeight: 400,
            padding: '8px 16px',
        },
    },
})
