import React from 'react'
import { useNavigate } from 'react-router-dom'
import { isEmpty } from 'lodash'
import axios from 'axios'
import { usePostRequest } from '../hooks/request'
import { EMPLOYEE_CREATE, EMPLOYEE_PHOTO_POST } from '../urls'
import { useMessage } from '../hooks/message'
import EmployeeForm from '../components/employees/EmployeeForm'
import { domain } from '../utils/request'
import { auth } from '../utils/auth'

export default function EmployeeCreate() {
    const navigate = useNavigate()
    const [showMessage] = useMessage()
    const create = usePostRequest({ url: EMPLOYEE_CREATE })

    const onSubmit = async (data) => {
        const { avatar, phoneNumber, additionalPhoneNumber } = data

        const { response, success, error } = await create.request({ data: {
            ...data,
            phoneNumber: phoneNumber ? `+998${phoneNumber.replace(/\s+/g, '')}` : null,
            additionalPhoneNumber: additionalPhoneNumber ? `+998${additionalPhoneNumber.replace(/\s+/g, '')}` : null,
            role: data.role || null,
            email: data.email || null,
            additionalEmail: data.additionalEmail || null,
            positionId: data.position.value,
            locationId: data.location.value,
            departmentId: data.department.value,
            gender: data.gender || 'not_set',
            salary: +data.salary.replace(/\s+/g, ''),
            headOfEmployee: data.headOfEmployee.value || null,
            languageLevel: Object.values(data.languageLevel).filter((e) => !(e.lang === null && e.level === null)),
            education: Object.values(data.education[0]).every((e) => isEmpty(e)) ? [] : data.education,
            experience: Object.values(data.experience[0]).every((e) => isEmpty(e)) ? [] : data.experience,
        } })

        if (success) {
            const { employeeId } = response.data

            if (avatar) {
                const formData = new FormData()
                formData.append('file', avatar)

                axios.post(
                    domain + EMPLOYEE_PHOTO_POST.replace('{id}', employeeId),
                    formData,
                    { ...auth() },
                ).then(() => {
                    navigate('/employees')
                    return showMessage('Успешно!', 'success-msg')
                }).catch(({ response: errorData }) => {
                    showMessage(errorData.data.errors[0].message || 'Photo Error', 'error-msg')
                })
            }

            navigate('/employees')
            return showMessage('Успешно!', 'success-msg')
        }

        return showMessage(error.data.errors[0].message || error.data.message, 'error-msg')
    }

    return (
        <EmployeeForm onCancel={() => navigate(-1)} onSubmit={onSubmit} type="create" loading={create.loading} />
    )
}
