import React, { useRef } from 'react'
import cn from 'classnames'
import { css, StyleSheet } from 'aphrodite'
import moment from 'moment'
import { DefaultLocation,
    EditPencil,
    EyeCloseSizeM,
    EyeSizeM,
    MapPin,
    ThreeDotsMenu,
    WalletSizeM } from '../../../svgs/Svgs'
import { COLORS } from '../../../../utils/colors'
import { Popup } from '../../../Popup'
import { format } from '../../../../utils/number'
import { fromToStr, strCutter } from '../../../../utils/string'

export default function RowViewItem({ item, onClick, onActivate, onEdit, companyLogo }) {
    const popUpRef = useRef()

    return (
        <div
            onClick={onClick}
            className={cn(css(s.cardWrap), { [css(s.inactive)]: !item.publish })}>
            <div className="justify-between-start">
                <div className={css(s.compInfo)}>
                    {companyLogo ? (
                        <div className={css(s.logoCont)}>
                            <img src={companyLogo} alt="#CompanyLogo" />
                        </div>
                    ) : (
                        <div className={css(s.defaultLogo)}>
                            <DefaultLocation color={COLORS.white} />
                        </div>
                    )}

                    <div className={css(s.comp_titles)}>
                        <h3>
                            {strCutter(item.name, 20)}
                        </h3>

                        <h3>{moment(item.createdAt).format('DD MMMM YYYY')}</h3>
                    </div>
                </div>

                <Popup
                    ref={popUpRef}
                    onClick={(event) => {
                        event.stopPropagation()
                        popUpRef.current.onOpen()
                    }}
                    trigger={(
                        <div className={cn('justify-end pointer', css(s.menu))}>
                            <ThreeDotsMenu />
                        </div>
                    )}>
                    <ul className={css(s.popUpWrap)}>
                        <li
                            onClick={onEdit}
                            className={cn('justify-between', css(s.cardSetting))}>
                            <div className="align-center gap-2">
                                <EditPencil /> Изменить
                            </div>
                        </li>

                        <li
                            className={cn('justify-between', css(s.cardSetting))}
                            onClick={onActivate}
                        >
                            <div className="align-center" style={{ gap: 8 }}>
                                {item.publish ? <EyeCloseSizeM /> : <EyeSizeM />}
                                {item.publish ? 'Деактивировать' : 'Активировать'}
                            </div>
                        </li>
                    </ul>
                </Popup>
            </div>

            <div className="is-flex direction-column gap-2">
                <div className={css(s.detailWrap)}>
                    <WalletSizeM />

                    {fromToStr(
                        item.salaryMin ? format(item.salaryMin) : '',
                        item.salaryMax ? format(item.salaryMax) : '',
                        item.currency,
                        <h3 style={{ color: COLORS.gray }}>По итогам собеседование</h3>,
                    )}
                </div>

                <div className={css(s.detailWrap)}>
                    <MapPin />

                    {item.location ? (
                        <h3>
                            {item.location && item.location.length > 27
                                ? `${item.location.slice(0, 27)}...` : item.location}
                        </h3>
                    ) : <h3 style={{ color: COLORS.gray }}>Не указан</h3>}
                </div>
            </div>
        </div>
    )
}

const s = StyleSheet.create({
    cardWrap: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        minWidth: '21rem',
        cursor: 'pointer',
        backgroundColor: COLORS.white,
        border: `1px solid ${COLORS.smoothGray}`,
        boxShadow: '0px 2px 4px rgba(130, 130, 130, 0.05)',
        borderRadius: '4px',
        padding: 16,
        ':hover': {
            border: `1px solid ${COLORS.lightGray}`,
            boxShadow: '0px 2px 8px rgba(136, 136, 136, 0.25)',
        },
        '@media (max-width: 768px)': {
            padding: 12,
            gap: 8,
        },
    },
    compInfo: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        gap: '12px',
        marginBottom: '16px',
        ':nth-child(1n) > img': {
            width: 40,
            height: 40,
        },
    },
    comp_titles: {
        display: 'flex',
        flexDirection: 'column',
        gap: '4px',
        ':nth-child(1n) > :first-child': {
            fontWeight: 700,
            fontSize: 16,
            color: COLORS.dark,
            '@media (max-width: 768px)': {
                fontSize: 13,
            },
        },
        ':nth-child(1n) > :last-child': {
            fontWeight: 400,
            fontSize: 13,
            color: COLORS.gray,
        },
        '@media (max-width: 768px)': {
            gap: 2,
            fontSize: 13,
        },
    },
    detailWrap: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 8,
        ':nth-child(1n) > svg': {
            ':nth-child(1n) > :first-child': {
                stroke: COLORS.mainColor,
            },
            ':nth-child(1n) > :last-child': {
                stroke: COLORS.mainColor,
            },
        },
        background: 'inherit',
        border: `1px solid ${COLORS.smoothGray}`,
        boxShadow: '0px 2px 4px rgba(130, 130, 130, 0.05)',
        borderRadius: '16px',
        padding: '4px 12px',
        fontWeight: 500,
        fontSize: '13px',
        color: '#414644',
        width: 'fit-content',
        '@media (max-width: 768px)': {
            padding: '6px 8px',
            fontSize: 10,
            gap: 4,
        },
    },
    menu: {
        width: 24,
        height: 24,
    },
    cardSetting: {
        width: '10rem',
        userSelect: 'none',
    },
    popUpWrap: {
        borderRadius: 4,
        margin: 0,
        ':nth-child(1n) > *': {
            color: '#fff',
            fontSize: 12,
            background: 'rgba(65, 70, 68, 0.9)',
            backdropFilter: 'blur(8px)',
            fontWeight: '500',
            padding: '10px 12px',
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
            borderBottom: '1px solid #5e6360',
            ':hover': {
                background: 'rgba(87, 90, 89, 0.9)',
            },
            ':nth-child(1n) > :first-child': {
                marginRight: 8,
            },
        },
        ':nth-child(1n) > :last-child': {
            borderBottomWidth: 0,
            borderBottomLeftRadius: 4,
            borderBottomRightRadius: 4,
        },
        ':nth-child(1n) > :first-child': {
            borderTopLeftRadius: 4,
            borderTopRightRadius: 4,
        },
    },
    inactive: {
        backgroundColor: COLORS.smoothGray,
        ':hover': {
            border: `1px solid ${COLORS.smoothGray}`,
            boxShadow: '0px 2px 4px rgba(130, 130, 130, 0.05)',
        },
    },
    logoCont: {
        width: 40,
        height: 40,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 4,
        boxSizing: 'border-box',
        ':nth-child(1n) > img': {
            width: 'inherit',
            borderRadius: 4,
        },
    },
    defaultLogo: {
        width: 40,
        height: 40,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: COLORS.mainColor,
        border: `1px solid ${COLORS.white}`,
        borderRadius: 4,
        boxSizing: 'border-box',
    },
})
