import React, { useRef } from 'react'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import moment from 'moment'
import Highlighter from 'react-highlight-words'
import { isEmpty } from 'lodash'
import { ChevronNext, ThreeDotsMenu, EditPencil, Archive } from '../svgs/Svgs'
import { Popup } from '../Popup'
import { LoadingSkeleton } from '../common/LoadingSkeleton'
import { COLORS, tagStyleSheet } from '../../utils/colors'

function Skeleton() {
    return (
        <tr className={css(s.loadItem)}>
            <td className={css(s.colItem)}>
                <div className={cn('align-start direction-column gap-1')}>
                    <LoadingSkeleton height={15} width="10rem" />

                    <LoadingSkeleton height={13} width="8rem" />
                </div>
            </td>

            <td className={css(s.colItem)}>
                <div className={cn('align-center gap-2')}>
                    <LoadingSkeleton width={24} height={24} borderRadius="50%" />

                    <LoadingSkeleton height={15} width="12rem" />
                </div>
            </td>

            <td><LoadingSkeleton height={20} width="50%" /></td>

            <td><LoadingSkeleton height={20} width="50%" /></td>

            <td><LoadingSkeleton height={20} width="50%" /></td>

            <td><LoadingSkeleton height={20} width="50%" /></td>

            <td className={css(s.colItem)}>
                <div className="justify-end">
                    <div className={css(s.menuItem)}>
                        <ThreeDotsMenu className="pointer" />
                    </div>
                </div>
            </td>
        </tr>
    )
}

export default function VacancyTableItem({ item, keyword, loading, onClick, onEdit, onArchive }) {
    const popUpRef = useRef()
    const popupItems = [
        { id: 1, value: 'Редактировать', icon: EditPencil, onClick: onEdit },
        { id: 3, value: 'В архив', icon: Archive, onClick: (id, name, st) => onArchive(id, st, popUpRef) },
    ]

    const formattedDate = (date) => moment(date).format('D MMMM YYYY')

    return !loading ? (
        <tr onClick={onClick} className={css(s.item)}>
            <td className={css(s.colItem)}>
                <Highlighter
                    textToHighlight={item.name || ''}
                    searchWords={[keyword]}
                    highlightClassName="highlight-bg"
                />

                <div title={item.department.name} className="align-center gap-1">
                    <Highlighter
                        textToHighlight={item.department.name || ''}
                        searchWords={[keyword]}
                        highlightClassName="highlight-bg"
                    />
                </div>
            </td>

            <td>{
                !isEmpty(item.recruiter)
                    ? `${item.recruiter.firstName} ${item.recruiter.lastName}`
                    : <div style={{ paddingLeft: 15 }}>&#8212;&#8212;&#8212;</div>
            }
            </td>

            <td>{item.columnName}</td>

            <td className={css(s.candCount)}>
                {item.candidates || 0}
            </td>

            <td>
                {!formattedDate(item.deadline) || formattedDate(item.deadline) === 'Invalid date'
                    ? <div style={{ paddingLeft: 15 }}>&#8212;&#8212;&#8212;</div> : formattedDate(item.deadline)}
            </td>

            <td>
                {!isEmpty(item.tags)
                    ? (
                        <div className="align-center gap-1">
                            <div className={cn('flex-cent', css(s.tag), tagStyleSheet(item.tags[0].color))}>
                                {item.tags[0].name}
                            </div>

                            {item.tags.length > 1 && (
                                <div className={cn('flex-cent', css(s.tag), tagStyleSheet(COLORS.midGray))}>
                                    +{item.tags.length - 1}
                                </div>
                            )}
                        </div>
                    ) : (
                        <div style={{ paddingLeft: 15 }}>&#8212;&#8212;&#8212;</div>
                    )}
            </td>

            <td className={css(s.colItem)}>
                <div className="justify-end">
                    <Popup
                        ref={popUpRef}
                        items={popupItems}
                        trigger={(
                            <div className={css(s.menuItem)}>
                                <ThreeDotsMenu className="pointer" />
                            </div>
                        )}
                        renderItem={(e) => (
                            <li
                                key={e.id}
                                onClick={(event) => {
                                    event.stopPropagation()
                                    e.onClick(item.id, item.hhStatus)
                                }}
                                className={cn('justify-between', css(s.cardSetting))}>
                                <div className="align-center gap-2">
                                    <e.icon /> {e.value}
                                </div>

                                {e.navigate ? <ChevronNext /> : null}
                            </li>
                        )}
                    />
                </div>
            </td>
        </tr>
    ) : <Skeleton />
}

const s = StyleSheet.create({
    status: {
        display: 'inline-flex',
        alignItems: 'center',
        borderRadius: 4,
        padding: '4.5px 8px',
        fontSize: 11,
        fontWeight: '500',
    },
    item: {
        height: '3.75rem',
        background: COLORS.white,
        color: '#181A19',
        fontSize: 13,
        fontWeight: '500',
        wordBreak: 'break-word',
        ':nth-child(1n) > :last-child': {
            paddingRight: '1rem',
        },
    },
    cardSetting: {
        width: '11rem',
        ':nth-child(1n) > :first-child': {
            ':nth-child(1n) > :first-child': {
                ':nth-child(1n) > *': {
                    stroke: '#CCCDCD',
                },
            },
        },
    },
    menuItem: {
        width: 20,
        height: 20,
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'center',
    },
    loadItem: {
        height: '3.75rem',
        background: '#FFFFFF',
        ':nth-child(1n) > :last-child': {
            paddingRight: '1rem',
        },
    },
    colItem: {
        ':first-of-type': {
            paddingLeft: '1.5rem',
            ':nth-child(1n) > :last-child': {
                fontWeight: '400',
                fontSize: 11,
                color: '#6D7471',
            },
        },
        ':last-of-type': {
            textAlign: 'right',
        },
    },
    tag: {
        height: 24,
        padding: '0 6px',
        borderRadius: 4,
    },
    candCount: {
        paddingLeft: 25,
    },
})
