import axios from 'axios'
import { domain } from '../utils/request'
import { INTEGRATION_CHECK_HH } from '../urls'
import { auth } from '../utils/auth'

export const API_HH = {
    countries: 'https://api.hh.ru/areas/countries',
    areas: 'https://api.hh.ru/areas/{id}',
    positions: 'https://api.hh.ru/professional_roles',
}

const REDIRECT_URI = 'https://hr.workly.uz/settings/company'
const CLIENT_ID = process.env.REACT_APP_HH_CLIENT_ID

// eslint-disable-next-line max-len
export const HH_INTEGRATION_URL = `https://hh.uz/oauth/authorize?response_type=code&client_id=${CLIENT_ID}&state={state}&redirect_uri=${REDIRECT_URI}`

function sendCheckRequest(showMessage, isFirstAuth) {
    return axios.get(domain + INTEGRATION_CHECK_HH, auth())
        .then((response) => {
            if (response.data.data.status === 'waiting') {
                if (isFirstAuth) showMessage('Please wait a few minutes', 'info-msg')
                return new Promise((resolve) => setTimeout(() => resolve(sendCheckRequest()), 3000))
            }

            return response
        })
}

export const checkHHIntegration = async (showMessage, isFirstAuth) => {
    const { data } = await sendCheckRequest(showMessage, isFirstAuth)
    const { status, message } = data.data

    if (isFirstAuth) {
        if (status === 'success') {
            showMessage('Success', 'success-msg')
        } else {
            showMessage(message || 'ERROR', 'error-msg')
        }
    }

    return status
}
