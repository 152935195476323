import React, { useState } from 'react'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import FilterTabs from './FilterTabs'
import FilterSection from './FilterSection'
import { COLORS } from '../../utils/colors'
import FullSection from './FullSection'
import Competencies from './Competencies'

export default function FilterBoard({ tabs, setFilter, filter }) {
    const [fullSection, setFullSection] = useState({ act: false })
    const [activeTab, setActiveTab] = useState('main')

    // const loadSkillBlocks = useLoad({ url: TEMPLATES_LIST1 })
    // const skillBlocks = loadSkillBlocks.response ? loadSkillBlocks.response.data : []

    return (
        <div className={cn('is-flex direction-column', css(s.cont))}>
            <div style={{ height: '100%' }}>
                {!fullSection.act ? (
                    <React.Fragment>
                        <FilterTabs tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} />

                        <div id="sec" className={cn('is-flex direction-column gap-5 overflowY-auto', css(s.sections))}>
                            {activeTab !== 'competencies'
                                ? tabs.find((tab) => tab.name === activeTab).sections.map((section) => (
                                    <FilterSection
                                        key={section.id}
                                        section={section}
                                        filter={filter}
                                        setFilter={setFilter}
                                        setFullSection={setFullSection} />
                                )) : (
                                    <Competencies
                                        filter={filter}
                                        setFilter={setFilter}
                                        // skillBlocks={skillBlocks}
                                    />
                                )}
                        </div>
                    </React.Fragment>
                ) : (
                    <FullSection
                        filter={filter}
                        setFilter={setFilter}
                        section={fullSection.section}
                        setFullSection={setFullSection} />
                )}
            </div>
        </div>
    )
}

const s = StyleSheet.create({
    cont: {
        backgroundColor: COLORS.white,
        height: '100%',
        width: '20%',
    },
    sections: {
        height: 'calc(100% - 41px)',
        boxSizing: 'border-box',
        // overflowY: 'auto',
        padding: '12px 10px 43px 16px',
        scrollbarGutter: 'stable',
    },
})
