import React, { useState } from 'react'
import { css, StyleSheet } from 'aphrodite'
import uuid from 'react-uuid'
import { useNavigate } from 'react-router-dom'
import Table from '../common/Table'
import EmployeesTableHeader from './EmployeesTableHeader'
import Modal from '../common/Modal'
import { usePutRequest } from '../../hooks/request'
import { EMPLOYEE_ARCHIVE } from '../../urls'
import { useMessage } from '../../hooks/message'
import EmployeeArchive from './EmployeeArchive'
import { roleOptions } from '../../utils/options'
import EmployeeTableItem from './EmployeeTableItem'
import EmployeeArchiveItem from './EmployeeArchiveItem'
import EmployeeDetailModal from './EmployeeDetailModal'

export default function EmployeesTable({
    meta,
    data,
    role,
    loading,
    setPage,
    request,
    keyword,
    setRole,
    isArchive,
    setKeyword,
    setPageSize,
}) {
    const navigate = useNavigate()
    const archive = usePutRequest()
    const [showMessage] = useMessage()
    const [modal, setModal] = useState({ act: false })
    const [archiveModal, setArchiveModal] = useState({ act: false })

    const headerColumns = [
        {
            id: 1,
            name: 'name',
            placeholder: 'Имя, отдел, должность',
            width: 'calc(100% * 3.5 / 12)',
            isSearchable: true,
            setValue: setKeyword,
        },
        { id: 2, name: 'Возраст', width: 'calc(100% * 2 / 12)' },
        {
            id: 3,
            name: 'Роль',
            popup: [{ value: 0, label: 'Все' }, ...roleOptions],
            value: role,
            setValue: setRole,
            width: 'calc(100% * 2 / 12)',
        },
        { id: 4, name: 'Добавлено', width: 'calc(100% * 2 / 12)' },
        { id: 5, name: 'начало работы', width: 'calc(100% * 2 / 12)' },
        { id: 6, name: '', width: 'calc(100% * 0.5 / 12)' },
    ]

    const headerArchiveColumns = [
        {
            id: 1,
            name: 'name',
            placeholder: 'Имя, отдел, должность',
            width: 'calc(100% * 3.5 / 12)',
            isSearchable: true,
            setValue: setKeyword,
        },
        { id: 2, name: 'начало работы', width: 'calc(100% * 2 / 12)' },
        { id: 3, name: 'Дата окончания', width: 'calc(100% * 2 / 12)' },
        { id: 4, name: 'Добавлено', width: 'calc(100% * 2 / 12)' },
        { id: 5, name: 'Причина увольнения', width: 'calc(100% * 2 / 12)' },
        { id: 6, name: '', width: 'calc(100% * 0.5 / 12)' },
    ]

    const onArchive = async (formData) => {
        const { success, error } = await archive.request({
            url: EMPLOYEE_ARCHIVE.replace('{id}', formData.id),
            data: { reasonDismiss: formData.reason },
        })

        if (success) {
            request()
            setArchiveModal({ act: false })
            showMessage('Успешно!', 'success-msg')
            return
        }
        showMessage(error.data.message || error.data.errors[0].message, 'error-msg')
    }

    return (
        <div className={css(s.tableWr)}>
            <Table
                isHoverable
                isStripped
                isScrollable
                page={meta.currentPage}
                count={meta.totalCount}
                pageCount={meta.pageCount}
                loading={loading}
                setPage={setPage}
                loaderItemCount={10}
                pageSize={meta.perPage}
                setPageSize={setPageSize}
                className={css(s.table)}
                items={data}
                customHeader={(
                    <EmployeesTableHeader headerColumns={!isArchive ? headerColumns : headerArchiveColumns} />
                )}
                renderItem={(item) => (!isArchive ? (
                    <EmployeeTableItem
                        item={item}
                        key={uuid()}
                        keyword={keyword}
                        loading={loading}
                        onEdit={() => navigate(`/employees/update/${item.id}`)}
                        onArchive={(id, name) => {
                            setArchiveModal({ act: true, item: { id, name } })
                        }}
                        onClick={() => setModal({ act: true, id: item.id })} />
                ) : (
                    <EmployeeArchiveItem
                        item={item}
                        key={uuid()}
                        keyword={keyword}
                        loading={loading}
                        onUnArchive={() => navigate(`/employees/unarchive/${item.id}`)}
                        onClick={() => setModal({ act: true, id: item.id })} />
                ))} />

            <EmployeeDetailModal
                id={modal.id}
                isArchive={isArchive}
                openModal={modal.act}
                onClose={() => setModal({ act: false })}
                onSuccess={() => {
                    request()
                    setModal({ act: false })
                }}
                onArchive={(id, name) => {
                    setArchiveModal({ act: true, item: { id, name } })
                    setModal({ act: false })
                }}
            />

            <Modal
                closeOnEscape
                open={archiveModal.act}
                closeOnDocumentClick
                contentStyle={{ minWidth: '44%' }}
                overlayStyle={{ top: 52, left: '5.625rem' }}
                onClose={() => setArchiveModal({ act: false })}>
                <EmployeeArchive
                    onClose={() => setArchiveModal({ act: false })}
                    employee={archiveModal.item}
                    onSubmit={onArchive}
                />
            </Modal>
        </div>
    )
}

const s = StyleSheet.create({
    tableWr: {
        paddingBottom: 20,
    },
    table: {
        width: '100%',
        textAlign: 'left',
        height: 'calc(100% - 50px)',
    },
})
