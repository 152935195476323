import React, { useContext, useEffect, useRef } from 'react'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import { useNavigate } from 'react-router-dom'
import { isEmpty } from 'lodash'
import { EditPencil, PersonalSettingsRounded } from '../svgs/Svgs'
import { ExitModal } from './ExitModal'
import { PasswordChange } from './PasswordChange'
import { SettingsAvatar } from '../svgs/SvgPictures'
import { COLORS } from '../../utils/colors'
import { LoadingSkeleton } from '../common/LoadingSkeleton'
import { roleOptions } from '../../utils/options'
import { Context } from '../../contexts/GlobalContext'
import DeleteActions from './deleteActions/DeleteActions'
import { useQueryParams } from '../../hooks/queryString'
import { usePostRequest } from '../../hooks/request'
import { TRANSFER_OWNER_ROLE_CONFIRM } from '../../urls'
import { useMessage } from '../../hooks/message'

export default function PersonalForm() {
    const actionsRef = useRef()
    const confirm = usePostRequest({ url: TRANSFER_OWNER_ROLE_CONFIRM })
    const { user, fetchInfo } = useContext(Context)
    const navigate = useNavigate()
    const { token } = useQueryParams()
    const [showMessage] = useMessage()

    const {
        role,
        photo,
        lastName,
        username,
        firstName,
        employeeId,
        retryDeleteAccount,
        waitingTransferOwner,
    } = user.data || {}

    const fullName = `${firstName} ${lastName}`
    const imageSrc = !isEmpty(photo) ? photo[2] : ''
    const isOwner = role && role === 1

    async function confirmDelete() {
        actionsRef.current.confirmModal('initial')
        const { success, error } = await confirm.request({ params: { token } })

        if (success) {
            actionsRef.current.confirmModal('confirmed')
            fetchInfo()
        } else {
            actionsRef.current.confirmModal('fail')
            showMessage(error.data.message || error.data.errors[0].message, 'error-msg')
        }
    }

    useEffect(() => {
        if (isOwner && token) {
            confirmDelete()
        }
    }, [isOwner])

    return (
        <div className={css(s.form)}>
            <div className={cn('justify-between', css(s.formName))}>
                <div className="align-center gap-2">
                    <PersonalSettingsRounded />

                    <h3>настройки аккаунта</h3>
                </div>

                <div
                    onClick={() => navigate(`/employees/update/${employeeId}`)}
                    className="align-center gap-2 pointer">
                    <EditPencil />

                    <h3>Редактировать данные</h3>
                </div>
            </div>

            <div className={css(s.accSet)}>
                <div className={cn('align-start gap-7', css(s.loadPhCont))}>
                    <div className={cn('is-flex', css(s.avatar))}>
                        {imageSrc ? (
                            <img src={imageSrc} alt="#avatar" />
                        ) : (
                            <SettingsAvatar />
                        )}
                    </div>

                    <div className="is-flex direction-column gap-5">
                        <div className={cn('is-flex direction-column gap-1', css(s.info))}>
                            {!user.loading ? (
                                <h3>{fullName}</h3>
                            ) : (
                                <LoadingSkeleton height={28} width={242} />
                            )}

                            {!user.loading ? (
                                <h3>{username}</h3>
                            ) : (
                                <LoadingSkeleton height={15} width={180} />
                            )}
                        </div>

                        <div style={{ width: '100%' }}>
                            {!user.loading ? role ? (
                                <div className={cn('align-center', css(s.role))}>
                                    {roleOptions[role - 1].label}
                                </div>
                            ) : null : (
                                <LoadingSkeleton height={24} width={97} />
                            )}
                        </div>
                    </div>
                </div>
            </div>

            <div className={cn('justify-between', css(s.dgArea))}>
                <div>
                    <h3>Изменение пароля</h3><br />

                    <h3>Если вы не уверены за безопасность аккаунта, измените пароль</h3>
                </div>

                <PasswordChange userLoading={user.loading} />
            </div>

            <div className={cn('justify-between', css(s.dgArea))}>
                <div>
                    <h3>Выход</h3><br />

                    <h3>После того, как вы вышли с аккаунта, вам придется заново ввести логин и пароль для входа</h3>
                </div>

                <ExitModal userLoading={user.loading} />
            </div>

            <div className={cn('justify-between', css(s.dgArea))}>
                <div>
                    <h3>Удаление аккаунта</h3> <br />

                    <h3>
                        {isOwner
                            ? 'Вы являетесь владельцем компании и для удаления вы должны сначала передать '
                            + 'права владельца другому администратору'
                            : 'Это приведет к полной очистке всех ваших данных и истории. '
                            + 'Вы ничего не сможете восстановить'}
                    </h3>
                </div>

                <DeleteActions
                    isOwner={isOwner}
                    ref={actionsRef}
                    userLoading={user.loading}
                    deleteWaiting={retryDeleteAccount}
                    transferWaiting={waitingTransferOwner}
                />
            </div>
        </div>
    )
}

const s = StyleSheet.create({
    form: {
        backgroundColor: '#fff',
        borderRadius: 4,
    },
    formName: {
        height: 'calc(3rem - 1px)',
        padding: '0 24px',
        fontSize: 13,
        borderBottom: `1px solid ${COLORS.lightGray}`,
        ':nth-child(1n) > :first-child': {
            textTransform: 'uppercase',
            fontWeight: '600',
            color: COLORS.dark,
        },
        ':nth-child(1n) > :last-child': {
            ':hover': {
                color: 'rgba(0,149,242,0.8)',
                ':nth-child(1n) > :first-child': {
                    ':nth-child(1n) > *': {
                        stroke: 'rgba(0,149,242,0.8)',
                    },
                },
            },
            ':nth-child(1n) > :first-child': {
                ':nth-child(1n) > *': {
                    stroke: COLORS.skyblue,
                },
            },
            fontWeight: '500',
            color: COLORS.skyblue,
        },
    },
    accSet: {
        padding: '16px 0 24px',
        margin: '0 24px',
        borderBottom: `1px solid ${COLORS.lightGray}`,
    },
    loadPhCont: {
        gap: 32,
    },
    dgArea: {
        padding: '24px 0',
        margin: '0 24px',
        gap: 32,
        borderBottom: `1px solid ${COLORS.lightGray}`,
        ':last-of-type': {
            border: 'none',
        },
        ':nth-child(1n) > :first-child': {
            width: 'calc(100% - 160px)',
            ':nth-child(1n) > :first-child': {
                color: COLORS.dark,
                fontSize: 16,
                fontWeight: '600',
            },
            ':nth-child(1n) > :last-child': {
                color: COLORS.gray,
                fontSize: 12,
                fontWeight: '500',
            },
        },
    },
    avatar: {
        border: `1px solid ${COLORS.lightGray}`,
        borderRadius: 5,
        ':nth-child(1n) > img': {
            borderRadius: 4,
        },
    },
    info: {
        ':nth-child(1n) > :first-child': {
            fontWeight: '600',
            fontSize: 24,
            color: '#181A19',
        },
        ':nth-child(1n) > :last-child': {
            fontWeight: '400',
            fontSize: 13,
            color: COLORS.gray,
        },
    },
    role: {
        height: 24,
        background: 'rgba(0, 149, 242, 0.1)',
        border: '1px solid rgba(0, 149, 242, 0.2)',
        borderRadius: 4,
        fontWeight: '500',
        fontSize: 11,
        width: 'auto',
        padding: '0 6px',
        color: COLORS.skyblue,
        display: 'inline-flex',
    },
})
