import React, { useState } from 'react'
import cn from 'classnames'
import { css, StyleSheet } from 'aphrodite'
import { COLORS } from '../../../utils/colors'
import { Map, MapWrapper } from '../../common/Map'
import { CloseSizeL, MapPinSizeL } from '../../svgs/Svgs'

export default function Location({ values }) {
    const { label, lat, lng } = values.location
    const [showMap, setShowMap] = useState(true)

    return (
        <div className={cn('is-flex direction-column gap-5')}>
            <h3 className={css(s.name)}>Локация работы:</h3>

            <h3 className={css(s.lctName)}>{label}</h3>

            <div>
                <div onClick={() => setShowMap(!showMap)} className="align-center gap-2 pointer">
                    { showMap
                        ? (
                            <>
                                <CloseSizeL color={COLORS.mainColor} />
                                <h3 className={css(s.mapTriggerText)}>Скрыть карту</h3>
                            </>
                        )
                        : (
                            <>
                                <MapPinSizeL color={COLORS.mainColor} />
                                <h3 className={css(s.mapTriggerText)}>Показать карту</h3>
                            </>
                        )}
                </div>

                <MapWrapper>
                    {showMap && (
                        <Map
                            center={lat && lng ? { lat, lng } : null}
                            markers={[{ lat, lng }]}
                            mapContainerClassName={css(s.map)}
                        />
                    )}
                </MapWrapper>
            </div>
        </div>
    )
}
const s = StyleSheet.create({
    topic: {
        padding: 24,
        borderBottom: `1px solid ${COLORS.lightGray}`,
    },
    name: {
        color: COLORS.dark,
        fontSize: 20,
        fontWeight: '600',
    },
    lctName: {
        color: '#343836',
        fontSize: 14,
        fontWeight: '600',
    },
    mapTriggerText: {
        color: COLORS.mainColor,
        fontSize: 12,
        fontWeight: '500',
    },
    map: {
        marginTop: 4,
        borderRadius: 4,
        overflow: 'hidden',
        border: `1px solid ${COLORS.lightGray}`,
    },
})
