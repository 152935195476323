import React from 'react'
import FilterBoard from '../filter/FilterBoard'
import { useLoad } from '../../hooks/request'
import { DEPARTMENT_LIST, POSITIONS_LIST } from '../../urls'
import { dateRangeFilter } from '../../utils/filter'

export function VacancyFilter({ vacancyFilter, setVacancyFilter }) {
    const loadDepartments = useLoad({ url: DEPARTMENT_LIST })
    const loadPositions = useLoad({ url: POSITIONS_LIST })

    const departments = loadDepartments.response ? loadDepartments.response.data : []
    const departmentsMeta = loadDepartments.response ? loadDepartments.response.meta : []

    const positions = loadPositions.response ? loadPositions.response.data : []
    const positionsMeta = loadPositions.response ? loadPositions.response.meta : []

    const mainSections = [
        {
            id: 1,
            title: 'Отдел',
            name: 'departments',
            isSearchable: true,
            placeholder: 'Найти отдел',
            isCheckbox: true,
            options: departments,
            loading: loadDepartments.loading,
            meta: { ...departmentsMeta, url: DEPARTMENT_LIST, title: 'Все отделы' },
        },
        {
            id: 2,
            title: 'Должность',
            name: 'positions',
            isSearchable: true,
            placeholder: 'Найти должность',
            isCheckbox: true,
            options: positions,
            loading: loadPositions.loading,
            meta: { ...positionsMeta, url: POSITIONS_LIST, title: 'Все должности' },
        },
        {
            id: 3,
            title: 'Опыт',
            name: 'experience',
            isCheckbox: true,
            options: [
                { id: 0, name: 'Без опыта', count: '29' },
                { id: 1, name: 'от 1 до 3 лет', count: '10' },
                { id: 2, name: 'от 3 до 6 лет', count: '22' },
                { id: 3, name: 'более 6 лет', count: '120' },
            ],
        },
        {
            id: 4,
            title: 'Зарплата',
            name: 'salary',
            isRange: true,
            options: [],
        },
    ]

    const aboutSections = [
        {
            id: 1,
            title: 'Дата создания',
            name: 'createdDate',
            isRadio: true,
            options: dateRangeFilter,
        },
        {
            id: 2,
            title: 'Дата начала',
            name: 'startedDate',
            isRadio: true,
            options: dateRangeFilter,
        },
        {
            id: 3,
            title: 'Дедлайн',
            name: 'deadline',
            isRadio: true,
            options: dateRangeFilter,
        },
        // {
        //     id: 4,
        //     isSelect: true,
        //     name: 'recruiters',
        //     title: 'Рекрутеры',
        //     customSelectOption: true,
        //     options: [
        //         { value: 1, label: 'Test Name 1' },
        //         { value: 2, label: 'Test Name 2' },
        //         { value: 3, label: 'Test Name 3' },
        //         { value: 4, label: 'Test Name 4' },
        //         { value: 5, label: 'Test Name 5' },
        //     ],
        // },
        // {
        //     id: 5,
        //     isSelect: true,
        //     name: 'responsible',
        //     title: 'Ответственный',
        //     customSelectOption: true,
        //     options: [
        //         { value: 1, label: 'Test Name 1' },
        //         { value: 2, label: 'Test Name 2' },
        //         { value: 3, label: 'Test Name 3' },
        //         { value: 4, label: 'Test Name 4' },
        //         { value: 5, label: 'Test Name 5' },
        //     ],
        // },
    ]

    // responsible, recruiter

    const tabs = [
        { key: 1, name: 'main', label: 'Основное', sections: mainSections },
        { key: 2, name: 'about', label: 'О заявке', sections: aboutSections },
        { key: 3, name: 'competencies', label: 'Компетенции' },
    ]
    return (
        <FilterBoard setFilter={setVacancyFilter} tabs={tabs} filter={vacancyFilter} />
    )
}
