import React, { forwardRef, useImperativeHandle, useState } from 'react'
import cn from 'classnames'
import { css, StyleSheet } from 'aphrodite'
import DeleteModal from './DeleteModal'
import Transfer from './Transfer'
import { MailSizeM, RepeatSizeL, Time, Trash } from '../../svgs/Svgs'
import { COLORS } from '../../../utils/colors'
import Button from '../../common/Button'
import LoadActions from './LoadActions'
import Success from './Success'

const DeleteActions = forwardRef(({ isOwner, deleteWaiting, transferWaiting, userLoading }, ref) => {
    const [successTransfer, setSuccessTransfer] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [loading, setLoading] = useState(false)

    useImperativeHandle(ref, () => ({
        confirmModal(type) {
            if (type === 'initial') {
                setShowModal(true)
                setLoading(true)
            } else if (type === 'confirmed') {
                setSuccessTransfer(true)
                setLoading(false)
            } else {
                setShowModal(false)
                setLoading(false)
            }
        },
    }))

    return (
        <React.Fragment>
            <Button
                disabled={userLoading}
                icon={isOwner
                    ? !transferWaiting ? <RepeatSizeL /> : <Time color={COLORS.dark} />
                    : !deleteWaiting ? <Trash color={COLORS.midGray} /> : <MailSizeM color={COLORS.midGray} />}
                onClick={() => setShowModal(true)}
                className={cn(
                    'flex-cent text-no-wrap',
                    css(s.delBtn, isOwner && s.tfOwner, transferWaiting && s.transfer),
                )}
            >
                {isOwner
                    ? !transferWaiting ? 'Передача прав владельца' : 'В ожидании'
                    : !deleteWaiting ? 'Удалить' : 'Отправить письмо снова'}
            </Button>

            {!loading ? !successTransfer ? isOwner
                ? (
                    <Transfer
                        showModal={showModal}
                        setShowModal={setShowModal}
                    />
                )
                : <DeleteModal showModal={showModal} setShowModal={setShowModal} />
                : (
                    <Success
                        showModal={showModal}
                        setShowModal={setShowModal}
                        onClose={() => setSuccessTransfer(false)} />
                )
                : <LoadActions showModal={showModal} setShowModal={setShowModal} />}
        </React.Fragment>
    )
})

export default DeleteActions

const s = StyleSheet.create({
    delBtn: {
        background: 'transparent',
        borderRadius: 4,
        color: COLORS.midGray,
        padding: '9px 16px',
        minWidth: '11.75rem',
        gap: 8,
        height: 40,
        fontSize: 14,
        fontWeight: '500',
        border: `1px solid ${COLORS.midGray}`,
        ':hover': {
            boxShadow: '0px 2px 4px 0px rgba(130, 130, 130, 0.15)',
        },
    },
    tfOwner: {
        width: 'auto',
        padding: '6.5px 16px',
        boxSizing: 'border-box',
        whiteSpace: 'nowrap',
    },
    transfer: {
        background: '#F6C11D',
        color: COLORS.dark,
        boxShadow: '0px 2px 4px 0px rgba(130, 130, 130, 0.05)',
        border: 'none',
    },
})
