import React, { memo } from 'react'
import { css, StyleSheet } from 'aphrodite'
import { useLocation, useNavigate } from 'react-router-dom'
import cn from 'classnames'
import { Employees, FileSizeM, Home, SettingsLayout, UserSearch, Vacancy } from './svgs/Svgs'
import SidebarItem from './common/SidebarItem'
import { COLORS } from '../utils/colors'

function MemoizedComponent({ className }) {
    const navigate = useNavigate()
    const location = useLocation()

    const startsWith = (path) => location.pathname.startsWith(path)

    return (
        <aside className={cn(css(s.s_bar), className)}>
            <ul>
                <SidebarItem
                    title="Главная"
                    icon={<Home color={startsWith('/dashboard') ? COLORS.dark : COLORS.gray} />}
                    startsWith={startsWith('/dashboard')}
                    navigate={() => navigate('/dashboard')} />

                <SidebarItem
                    title="Вакансии"
                    icon={<Vacancy color={startsWith('/vacancies') ? COLORS.dark : COLORS.gray} />}
                    startsWith={startsWith('/vacancies')}
                    navigate={() => navigate('/vacancies?view=board')} />

                <SidebarItem
                    title="Кандидаты"
                    icon={<UserSearch color={startsWith('/candidates') ? COLORS.dark : COLORS.gray} />}
                    startsWith={startsWith('/candidates')}
                    navigate={() => navigate('/candidates?view=board')} />

                <SidebarItem
                    title="Сотрудники"
                    icon={<Employees color={startsWith('/employees') ? COLORS.dark : COLORS.gray} />}
                    startsWith={startsWith('/employees')}
                    navigate={() => navigate('/employees')} />

                {/* <SidebarItem */}
                {/*     title="Отчеты" */}
                {/*     icon={<FileSizeM color={startsWith('/reports') ? COLORS.dark : COLORS.gray} />} */}
                {/*     startsWith={startsWith('/reports')} */}
                {/*     navigate={() => navigate('/reports/vacancy-metrics')} /> */}

                <SidebarItem
                    title="Отчеты"
                    icon={<FileSizeM color={startsWith('/reports') ? COLORS.dark : COLORS.gray} />}
                    startsWith={startsWith('/reports')}
                    navigate={() => navigate('/reports/vacancy-info')} />

                <SidebarItem
                    title="Настройки"
                    icon={<SettingsLayout color={startsWith('/settings') ? COLORS.dark : COLORS.gray} />}
                    startsWith={startsWith('/settings')}
                    navigate={() => navigate('/settings/personal')} />
            </ul>
        </aside>
    )
}

export const Sidebar = memo(MemoizedComponent)

const s = StyleSheet.create({
    s_bar: {
        borderRight: `1px solid ${COLORS.smoothGray}`,
        width: '5.625rem',
        background: '#FFF',
        padding: '6px 0',
        boxShadow: '-1px 0px 16px rgba(0, 0, 0, 0.06)',
    },
    m_item: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        height: 44,
        ':not([datatype=selected])': {
            ':hover': {
                background: '#F2F2F2',
            },
        },
        ':nth-child(1n) > :last-child': {
            padding: '10px 0 10px 20px',
            width: 'calc(100% - 4px)',
            display: 'flex',
            alignItems: 'center',
            color: COLORS.midGray,
            fontSize: 13,
            fontWeight: '600',
        },
    },
    act_item: {
        ':nth-child(1n) > :last-child': {
            background: '#EDFCF4',
            color: COLORS.mainColor,
            ':nth-child(1n) > :first-child': {
                ':nth-child(1n) > *': {
                    stroke: COLORS.dark,
                },
            },
        },
    },
    act_icon: {
        ':nth-child(1n) > *': {
            fill: COLORS.dark,
        },
    },
    act_item_s: {
        ':nth-child(1n) > :last-child': {
            background: '#EDFCF4',
            color: COLORS.mainColor,
            ':nth-child(1n) > :first-child': {
                ':nth-child(1n) > *': {
                    fill: COLORS.dark,
                },
            },
        },
    },
    bdr: {
        minWidth: 4,
        height: '100%',
    },
    a_bdr: {
        borderRadius: '0 12px 12px 0',
        backgroundColor: COLORS.mainColor,
    },
    lstText: {
        flex: 1,
        ':nth-child(1n) > :first-child': {
            width: 28,
        },
        ':nth-child(1n) > :last-child': {
            minWidth: '65%',
        },
    },
    arrLeft: {
        cursor: 'pointer',
        border: '2px solid',
        borderColor: '#F6F6F6',
        borderRadius: 32,
        position: 'absolute',
        right: -12,
    },
    arrBrd: {
        borderColor: COLORS.white,
    },
})
