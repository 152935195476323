import React from 'react'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import { Phone } from '../../svgs/Svgs'
import FormInput from '../../common/FormInput'
import { COLORS } from '../../../utils/colors'
import FormInputPhone from '../../common/FormInputPhone'
import { email, phone } from '../../../utils/validators'

export default function ContactInformation({ handleBlur, handleChange }) {
    return (
        <div className={css(s.box)}>
            <div className={cn('justify-between', css(s.formName))}>
                <div className="align-center gap-2">
                    <Phone className={css(s.icon)} />

                    <h3>контактная информация</h3>
                </div>
            </div>

            <div className={css(s.inpGr)}>
                <FormInputPhone
                    name="phone"
                    placeholder="__ ___ __ __"
                    className={css(s.addInp)}
                    onChange={handleChange}
                    validate={phone}
                    onBlur={handleBlur}
                    errorWithMessage={false}
                    label="Номер телефона"
                    fieldClassName="is-flex direction-column"
                    labelClassName={css(s.addInpLab)} />

                <FormInput
                    name="email"
                    className={css(s.addInp)}
                    validate={email}
                    noRequiredValidation
                    errorWithMessage={false}
                    label="Электронная почта"
                    placeholder="Например “abcdef@gmail.com”"
                    onChange={handleChange}
                    fieldClassName={css(s.fld)}
                    labelClassName={css(s.addInpLab)} />
            </div>
        </div>
    )
}

const s = StyleSheet.create({
    box: {
        flex: 1,
        background: COLORS.white,
        border: `1px solid ${COLORS.smoothGray}`,
        boxShadow: '0px 2px 4px rgba(130, 130, 130, 0.05)',
        borderRadius: 4,
    },
    formName: {
        height: 'calc(3rem - 1px)',
        padding: '0 22px 0 24px',
        fontSize: 13,
        fontWeight: '600',
        color: '#181A19',
        borderBottom: `1px solid ${COLORS.lightGray}`,
        textTransform: 'uppercase',
    },
    inpGr: {
        padding: '16px 24px 24px',
        display: 'flex',
        flexDirection: 'column',
        gap: 24,
    },
    lb: {
        fontSize: 12,
        fontWeight: '500',
        color: COLORS.midGray,
    },
    addInp: {
        position: 'relative',
        border: `1px solid ${COLORS.lightGray}`,
        borderRadius: 4,
        fontSize: 14,
        fontWeight: '500',
        color: '#181A19',
    },
    addInpLab: {
        fontSize: 12,
        fontWeight: '500',
        color: COLORS.midGray,
        marginBottom: 8,
    },
    fld: {
        position: 'relative',
        // padding
    },
    lab: {
        ':nth-child(1n) > span': {
            display: 'inline-flex',
        },
    },
    switchBox: {
        padding: '13px 16px',
        border: `1px solid ${COLORS.lightGray}`,
        borderRadius: 4,
        fontWeight: '500',
        fontSize: 14,
        color: COLORS.gray,
    },
    switchCont: {
        gridColumn: '1 / 3',
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gridGap: 24,
        alignItems: 'end',
        height: '4.125rem',
    },
    icon: {
        ':nth-child(1n) > *': {
            stroke: '#181A19',
        },
    },
})
