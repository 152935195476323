import React from 'react'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import { COLORS } from '../../../utils/colors'
import Table from '../../common/Table'
import { useLoad } from '../../../hooks/request'
import { REPORT_APPLICANT_SOURCE_CHART_ACCEPT } from '../../../urls'
import { strCutter } from '../../../utils/string'
import { PieChart } from '../../common/PieChart'
import { ApplicantSources } from '../../../utils/options'
import { CheckSizeL } from '../../svgs/Svgs'
import { LoadingSkeleton } from '../../common/LoadingSkeleton'

const SourcesColors = ApplicantSources.reduce((acc, { value, color }) => {
    acc[value] = color
    return acc
}, {})

const EmptyChart = [{ value: 1, name: '', color: COLORS.smoothGray }]

function TableHeader() {
    return (
        <thead>
            <tr>
                <th className={css(s.hItem)}>источник</th>

                <th className={css(s.hItem)}>Кол-во</th>

                <th className={css(s.hItem)}>Процент</th>
            </tr>
        </thead>
    )
}

const TableItem = ({ name, value, percent, image, color }) => (
    <tr className={css(s.item)}>
        <td className={markStyleSheet(color)}>
            <div className="align-center gap-2">
                <img src={image} alt="#logo" />

                <h3 title={name}>{strCutter(name, 24)}</h3>
            </div>
        </td>

        <td className={css(s.stat)}>{value}</td>

        <td className={css(s.stat)}>{!Number.isNaN(+percent) ? percent : 0}%</td>
    </tr>
)

const getPercent = (data, value) => (data.accepted ? ((data.accepted[value] / data.acceptedCount) * 100).toFixed(2) : 0)

export default function ChartApplicantsBySources({ params }) {
    const loadChartData = useLoad({ url: REPORT_APPLICANT_SOURCE_CHART_ACCEPT, params }, [params])
    const data = loadChartData.response ? loadChartData.response.data : {}
    const chartData = data.accepted && data.acceptedCount > 0 ? Object.entries(data.accepted).map(([key, value]) => ({
        value, name: key, color: SourcesColors[key], percent: getPercent(data, value),
    })) : EmptyChart

    return (
        <div className={css(s.wrap)}>
            <div className={cn('justify-between', css(s.header))}>
                <h3 className={css(s.topic)}>Найм по источникам</h3>

                {/* <CloseSizeM color="#343836" /> */}
            </div>

            <div className={cn('direction-column align-center', css(s.cont))}>
                <PieChart
                    width={190}
                    height={190}
                    data={chartData}
                    innerRadius={70}
                    outerRadius={95}
                    labelText={[data.acceptedCount || 0, 'Нанято сотрудников']} />

                <div className={css(s.stats)}>
                    <Table
                        items={ApplicantSources}
                        cssClassName={s.table}
                        customHeader={<TableHeader />}
                        renderItem={({ label, value, color, img }) => (
                            <TableItem
                                image={img}
                                name={label}
                                color={color}
                                value={data.accepted ? data.accepted[value] : 0}
                                percent={getPercent(data, value)}
                            />
                        )}
                    />
                </div>
            </div>

            <div className={cn('align-center gap-1', css(s.footer))}>
                <CheckSizeL color={COLORS.mainColor} />

                <div className="align-center gap-1">
                    <h3>Нанято сотрудников за этот период:</h3>

                    <span>
                        {!loadChartData.loading ? data.acceptedCount : <LoadingSkeleton height={14} width={20} />}
                    </span>
                </div>
            </div>
        </div>
    )
}

const s = StyleSheet.create({
    wrap: {
        flex: 1,
        height: '33rem',
        borderRadius: 2,
        border: `1px solid ${COLORS.smoothGray}`,
        background: '#FFF',
        display: 'flex',
        flexDirection: 'column',
        boxShadow: '0px 2px 4px 0px rgba(130, 130, 130, 0.05)',
    },
    topic: {
        color: COLORS.dark,
        fontSize: 16,
        fontWeight: '600',
    },
    header: {
        height: 40,
        padding: '0 24px',
        borderBottom: `1px solid ${COLORS.lightGray}`,
    },
    table: {
        width: '100%',
        border: 'none',
        borderSpacing: '0 12px',
        borderCollapse: 'separate',
    },
    hItem: {
        fontSize: 12,
        fontWeight: '500',
        textAlign: 'start',
        color: COLORS.midGray,
        textTransform: 'uppercase',
    },
    cont: {
        padding: '20px 24px 30px',
        gap: 16,
        flex: 1,
    },
    stats: {
        width: '100%',
        borderRadius: 8,
        padding: '4px 24px',
        boxSizing: 'border-box',
        border: `1px solid ${COLORS.smoothGray}`,
        background: COLORS.lotion,
    },
    stat: {
        width: '15%',
        fontSize: 12,
        fontWeight: '500',
        textAlign: 'start',
        color: COLORS.dark,
    },
    footer: {
        borderTop: `1px solid ${COLORS.lightGray}`,
        padding: '8px 24px',
        color: COLORS.midGray,
        fontSize: 13,
        fontWeight: '500',
        ':nth-child(1n) > :last-child': {
            ':nth-child(1n) > span': {
                color: COLORS.dark,
                fontWeight: '600',
            },
        },
    },
})

const markStyleSheet = (color) => css(StyleSheet.create({
    main: {
        color,
        width: '70%',
        fontSize: 13,
        fontWeight: '500',
        textAlign: 'start',
    },
}).main)
