import React from 'react'
import DepartmentForm from './DepartmentForm'
import { useMessage } from '../../../hooks/message'
import { usePostRequest } from '../../../hooks/request'
import { DEPARTMENT_CREATE } from '../../../urls'
import SidebarLayout from '../../common/SidebarLayout'

export default function DepartmentCreate({ onSuccess, onClose }) {
    const create = usePostRequest({ url: DEPARTMENT_CREATE })
    const [showMessage] = useMessage()

    const onSubmit = async (data) => {
        const { name, headOfDepartment } = data

        const { success } = await create.request({ data: {
            name,
            headOfDepartment: headOfDepartment.value,
        } })

        if (success) {
            showMessage('Успешно!', 'success-msg')
            onSuccess()
        }
    }
    return (
        <SidebarLayout title="Создание отдела" onClose={onClose}>
            <DepartmentForm loading={create.loading} onClose={onClose} onSubmit={onSubmit} />
        </SidebarLayout>
    )
}
