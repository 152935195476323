import React from 'react'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import moment from 'moment'
import { isEmpty } from 'lodash'
import { Info } from '../svgs/Svgs'
import Tooltip from '../common/Tooltip'
import { COLORS } from '../../utils/colors'
import { roleOptions } from '../../utils/options'
import { useLoad } from '../../hooks/request'
import { EMPLOYEE_STATISTICS } from '../../urls'
import { NewEmployeesEmpty } from '../svgs/SvgPictures'

export default function NewEmployeesBar() {
    const loadEmployees = useLoad({ url: EMPLOYEE_STATISTICS })
    const employees = loadEmployees.response ? loadEmployees.response.data : []
    const imageSrc = !isEmpty(employees.photo) ? employees.photo[4] : ''

    return (
        <div className={cn('is-flex direction-column', css(s.box))}>
            <div className={css(s.top)}>
                <div className="align-center">
                    <h3 className={css(s.topTxt)}>Новые сотрудники</h3>

                    <Tooltip
                        trigger={(
                            <span className={cn(css(s.info), 'flex-cent')}>
                                <Info />
                            </span>
                        )}>
                        <div>Здесь вы будете видеть только последних <br />добавленных сотрудников в систему</div>
                    </Tooltip>
                </div>
            </div>

            {employees.length > 0 ? employees.map((employee) => (
                <div key={employee.id} className={css(s.boxItem)}>
                    {imageSrc ? (
                        <img className={css(s.avatar)} src={imageSrc} alt="#logo" />
                    ) : (
                        <div
                            style={{ width: 32, height: 32 }}
                            className={cn('flex-cent', css(s.defaultAvatar))}>
                            <h3>
                                {employee.firstName && employee.lastName
                                    ? `${employee.firstName.slice(0, 1)}${employee.lastName.slice(0, 1)}` : ''}
                            </h3>
                        </div>
                    )}

                    <div className="justify-between flex-1">
                        <div className="is-flex direction-column gap-2">
                            <div className="align-center gap-2">
                                <h3>{employee.firstName} {employee.lastName}</h3>

                                {employee.role && (
                                    <div className={cn('align-center', css(s.role))}>
                                        {roleOptions[employee.role - 1].label}
                                    </div>
                                )}
                            </div>

                            <p>{employee.departmentName} • {employee.positionName}</p>
                        </div>

                        <div className={cn(css(s.addedAt), 'flex-cent')}>
                            <h3><span>Дата добавления:</span> {moment(employee.createdAt).format('DD MMMM YYYY')}</h3>
                        </div>
                    </div>
                </div>
            )) : (
                <div className="justify-evenly direction-column flex-1">
                    <NewEmployeesEmpty />

                    <h3 className={css(s.emp_txt)}>В системе пока нет сотрудников, скорее добавьте коллег 😇</h3>
                </div>
            )}
        </div>
    )
}

const s = StyleSheet.create({
    box: {
        background: COLORS.white,
        boxShadow: '0px 2px 8px rgba(136, 136, 136, 0.25)',
        borderRadius: 12,
        height: '100%',
        boxSizing: 'border-box',
        padding: 20,
        zIndex: 1,
    },
    emp_txt: {
        fontSize: 13,
        width: '70%',
        textAlign: 'center',
        fontWeight: '600',
        color: COLORS.midGray,
    },
    avatar: {
        width: 32,
        height: 32,
        borderRadius: 50,
    },
    defaultAvatar: {
        background: '#1B93C0',
        borderRadius: 32,
        fontWeight: '600',
        fontSize: 12,
        color: '#FFFFFF',
        textTransform: 'uppercase',
    },
    top: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 16,
    },
    topTxt: {
        color: '#181A19',
        fontSize: 15,
        fontWeight: '600',
        marginRight: 6,
    },
    info: {
        cursor: 'help',
        width: 14,
        height: 14,
        ':hover': {
            ':nth-child(1n) > :first-child': {
                ':nth-child(1n) > *': {
                    stroke: COLORS.skyblue,
                },
            },
        },
    },
    boxItem: {
        display: 'flex',
        alignItems: 'center',
        padding: 12,
        gap: 8,
        height: 60,
        boxSizing: 'border-box',
        borderRadius: 4,
        marginBottom: 12,
        border: `1px solid ${COLORS.smoothGray}`,
        background: COLORS.white,
        boxShadow: '0px 2px 4px 0px rgba(130, 130, 130, 0.05)',
        ':nth-child(1n) > :last-child': {
            ':nth-child(1n) > :first-child': {
                ':nth-child(1n) > :first-child': {
                    fontSize: 13,
                    fontWeight: '500',
                    color: '#181A19',
                },
                ':nth-child(1n) > :last-child': {
                    fontSize: 11,
                    fontWeight: '400',
                    color: COLORS.midGray,
                },
            },
        },
        ':last-of-type': {
            marginBottom: 0,
        },
    },
    role: {
        height: 24,
        boxSizing: 'border-box',
        background: 'rgba(0, 149, 242, 0.1)',
        border: '1px solid rgba(0, 149, 242, 0.2)',
        borderRadius: 4,
        fontWeight: '500',
        fontSize: 11,
        padding: '0 6px',
        color: COLORS.skyblue,
    },
    addedAt: {
        borderRadius: 4,
        border: `1px solid ${COLORS.smoothGray}`,
        background: COLORS.white,
        height: 30,
        padding: '0 8px',
        color: COLORS.dark,
        fontSize: 13,
        fontWeight: '600',
        ':nth-child(1n) > h3': {
            ':nth-child(1n) > span': {
                color: COLORS.gray,
                fontWeight: '500',
            },
        },
    },
})
