import React, { useContext } from 'react'
import cn from 'classnames'
import { css, StyleSheet } from 'aphrodite'
import SidebarLayout from '../../common/SidebarLayout'
import { ChevronNext, PeopleGroup, ThreeDotsMenu } from '../../svgs/Svgs'
import { Popup } from '../../Popup'
import { Context } from '../../../contexts/GlobalContext'
import { COLORS } from '../../../utils/colors'
import { cp } from '../../../utils/checkPermission'

export default function LocationList({ onClose, popupItems }) {
    const { locations } = useContext(Context)

    return (
        <SidebarLayout title="Локации" onClose={onClose}>
            <div className={css(s.lst)}>
                <ul className={cn('no_dot_list is-flex direction-column gap-4')}>
                    {locations.map((loc, ind) => (
                        <React.Fragment key={loc.id}>
                            <li className={cn('justify-between', css(s.lstItem))}>
                                <div className="align-center gap-4">
                                    <div className={css(s.icon)}>
                                        <PeopleGroup />
                                    </div>

                                    <div className={cn('is-flex direction-column gap-1', css(s.inf))}>
                                        <h3>{loc.name}</h3>
                                        <h3>{loc.employeesCount} сотрудников</h3>
                                    </div>
                                </div>

                                {cp('settings.company.edit_location') && (
                                    <Popup
                                        items={popupItems}
                                        renderItem={(item) => (
                                            <li
                                                key={item.id}
                                                onClick={() => {
                                                    if (item.id === 2) onClose()
                                                    item.onClick(loc.id)
                                                }}
                                                className={cn('justify-between', css(s.cardSetting))}>
                                                <div className="align-center">
                                                    <item.icon /> {item.value}
                                                </div>

                                                {item.navigate ? <ChevronNext /> : null}
                                            </li>
                                        )}
                                        trigger={(
                                            <span className={css(s.menuIcon)}>
                                                <ThreeDotsMenu className="pointer" />
                                            </span>
                                        )} />
                                )}
                            </li>

                            {ind !== locations.length - 1 && <hr className={css(s.stick)} />}
                        </React.Fragment>
                    ))}
                </ul>
            </div>
        </SidebarLayout>
    )
}

const s = StyleSheet.create({
    lst: {
        overFlowX: 'hidden',
        marginTop: 16,
        padding: 12,
        background: COLORS.white,
        border: `1px solid ${COLORS.smoothGray}`,
        boxShadow: '0px 2px 4px rgba(130, 130, 130, 0.05)',
        borderRadius: 4,
    },
    lstItem: {
        height: 40,
    },
    inf: {
        ':nth-child(1n) > :first-child': {
            fontWeight: '500',
            fontSize: 13,
            color: COLORS.dark,
        },
        ':nth-child(1n) > :last-child': {
            fontWeight: '400',
            fontSize: 11,
            color: COLORS.midGray,
        },
    },
    stick: {
        width: '100%',
        border: `0.5px solid ${COLORS.smoothGray}`,
        margin: 0,
    },
    icon: {
        width: 34,
        height: 34,
        borderRadius: 4,
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: COLORS.mintCream,
        border: '1px solid #D3ECE3',
        ':nth-child(1n) > :first-child': {
            ':nth-child(1n) > *': {
                stroke: COLORS.mainColor,
            },
        },
    },
    menuIcon: {
        paddingRight: 11,
    },
    cardSetting: {
        width: '11rem',
        ':nth-child(1n) > :first-child': {
            ':nth-child(1n) > :first-child': {
                marginRight: 8,
            },
        },
    },
})
