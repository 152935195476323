import { useEffect } from 'react'

/* eslint-disable max-len, react-hooks/exhaustive-deps */
export function useOutsideDetector(ref, func) {
    useEffect(() => {
        document.addEventListener('mousedown', (event) => func(event, ref.current && !ref.current.contains(event.target)))
        return () => {
            document.removeEventListener('mousedown', (event) => func(event, ref.current && !ref.current.contains(event.target)))
        }
    }, [ref])
}
