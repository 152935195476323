import React, { useEffect } from 'react'
import { css, StyleSheet } from 'aphrodite'
import { useLocation, useNavigate } from 'react-router-dom'
import cn from 'classnames'
import { Logo, Ru, SearchForCandidate, SearchForJob } from '../svgs/Svgs'
import Carousel from './Carousel'
import { COLORS } from '../../utils/colors'

export default function LayoutRegister({ children }) {
    const navigate = useNavigate()
    const { pathname } = useLocation()

    useEffect(() => {
        document.body.parentNode.style.backgroundColor = COLORS.white
    }, [])

    return (
        <div className={css(s.cont)}>
            <div className={css(s.wrap)}>
                <div className={css(s.logoBar)}>
                    <Logo />

                    <span className="align-start gap-2">
                        <Ru />
                        <h3>Русский</h3>
                    </span>
                </div>

                <Carousel />
            </div>

            <div className={css(s.body)}>
                {!pathname.endsWith('/project/add') && (
                    <div className={css(s.goToLogin)}>
                        <div className={cn(css(s.box), 'is-flex gap-1')}>
                            <div className={cn(css(s.iconGreen), 'is-flex align-center justify-center pointer gap-2')}>
                                <div>
                                    <SearchForCandidate />
                                </div>

                                <h3 className={css(s.title)}>Ищу сотрудников</h3>
                            </div>

                            <a target="_blank" href={process.env.REACT_APP_JOBS_URL} rel="noreferrer">
                                <div className={cn(
                                    css(s.iconWhite),
                                    'is-flex align-center justify-center pointer gap-2',
                                )}>
                                    <div>
                                        <SearchForJob />
                                    </div>

                                    <h3 className={css(s.title)}>Ищу работу</h3>
                                </div>
                            </a>
                        </div>

                        <button
                            className="flex-cent"
                            onClick={() => navigate('/auth/recruiter')}>
                            Войти в систему
                        </button>
                    </div>
                )}

                {children}
            </div>
        </div>
    )
}

const s = StyleSheet.create({
    cont: {
        display: 'flex',
        height: '100%',
    },
    goToLogin: {
        gap: 16,
        padding: '36px 26px 18px',
        display: 'flex',
        justifyContent: 'flex-end',
        ':nth-child(1n) > button': {
            height: 40,
            fontSize: 14,
            outline: 'none',
            borderRadius: 4,
            color: COLORS.skyblue,
            fontWeight: '500',
            cursor: 'pointer',
            padding: '0 13.5px',
            boxSizing: 'border-box',
            backgroundColor: '#EAF6FF',
            border: '1px solid',
            borderColor: 'transparent',
            ':hover': {
                borderColor: COLORS.skyblue,
                boxShadow: '0px 2px 4px rgba(168, 168, 168, 0.15)',
            },
        },
    },
    wrap: {
        position: 'fixed',
        left: 0,
        padding: '36px 0',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: 'calc(4rem - 1px)',
        minWidth: '255px',
        width: '31.25%',
        background: 'linear-gradient(180deg, #EFFFF9 0%, #F6F1FF 100%)',
    },
    logoBar: {
        padding: '0 35px 0 32px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        fontSize: 12,
        fontWeight: '500',
        color: 'rgba(24, 26, 25, 0.78)',
        ':nth-child(1n) > span': {
            cursor: 'pointer',
            boxSizing: 'border-box',
            padding: '7px 13px',
            borderRadius: 4,
            border: '1px solid',
            borderColor: 'transparent',
            ':hover': {
                borderColor: COLORS.smoothGray,
                background: COLORS.white,
            },
        },
    },
    body: {
        overflow: 'auto',
        marginLeft: '31.25%',
        width: '68.75%',
        height: '100%',
    },
    box: {
        maxWidth: 220,
        padding: 4,
        borderRadius: 4,
        border: '1px solid #EAEAEA',
        background: COLORS.white,
        boxShadow: '0px 2px 4px 0px rgba(130, 130, 130, 0.05',
    },
    title: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    iconGreen: {
        width: 32,
        height: 32,
        borderRadius: 2,
        background: COLORS.mainColor,
        transition: 'width ease 0.3s',
        ':nth-child(1n) > h3': {
            color: COLORS.white,
            fontSize: 13,
            fontWeight: '500',
        },
        ':nth-child(1n) > :first-child': {
            marginLeft: 6,
        },
        ':hover': {
            width: '11.125rem',
            ':nth-child(1n) > :first-child': {
                marginLeft: 0,
            },
        },
    },
    iconWhite: {
        width: 32,
        height: 32,
        borderRadius: 2,
        ':nth-child(1n) > h3': {
            color: COLORS.midGray,
            fontSize: 13,
            fontWeight: '500',
        },
        transition: 'width ease 0.3s',
        ':nth-child(1n) > :first-child': {
            marginLeft: 6,
        },
        ':hover': {
            width: '11.125rem',
            ':nth-child(1n) > :first-child': {
                marginLeft: 0,
            },
        },
    },
})
