import React, { memo, useState } from 'react'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import { Form } from 'formik'
import FormInput from '../../common/FormInput'
import { CheckUpList } from '../../svgs/Svgs'
import { banknoteOptions } from '../../../utils/options'
import { date, required, validator } from '../../../utils/validators'
import { COLORS } from '../../../utils/colors'
import FormInputCurrency from '../../common/FormInputCurrency'
import { Tags } from '../../settings/tags/Tags'
import InputDate from '../../common/InputDate'

function MemoizedComponent({ values, setFieldValue }) {
    const [removeMode] = useState(false)
    const onSelectTag = (tagId) => setFieldValue('tagId', values.tagId !== tagId ? tagId : null)

    return (
        <div className={css(s.box)}>
            <div className={cn('justify-between', css(s.formName))}>
                <div className="align-center gap-2">
                    <CheckUpList /> <h3>Основная информация</h3>
                </div>

                {/* <span className={cn('align-center', css(s.add))}>Добавить существующего кандидата</span> */}
            </div>

            <Form>
                <div className={css(s.inpGr)}>
                    <FormInput
                        errorWithMessage={false}
                        validate={required}
                        name="firstName"
                        className={css(s.addInp)}
                        fieldClassName={css(s.fld)}
                        label="Имя"
                        placeholder="Введите"
                        labelClassName={css(s.addInpLab)} />

                    <FormInput
                        errorWithMessage={false}
                        validate={required}
                        name="lastName"
                        className={css(s.addInp)}
                        fieldClassName={css(s.fld)}
                        label="Фамилия"
                        placeholder="Введите"
                        labelClassName={css(s.addInpLab)} />

                    <InputDate validate={validator(required, date)} name="birthday" label="Год рождения кандидата" />

                    <FormInput
                        errorWithMessage={false}
                        validate={required}
                        name="location"
                        className={css(s.addInp)}
                        fieldClassName={css(s.fld)}
                        label="Адрес проживания"
                        placeholder="Страна, город, район, улица, дом"
                        labelClassName={css(s.addInpLab)} />

                    <FormInputCurrency
                        isSelectable
                        errorWithMessage={false}
                        validate={required}
                        name="desiredSalary"
                        maxLength={11}
                        label="Желаемая заработная плата"
                        options={banknoteOptions}
                        placeholder="Введите сумму"
                        fieldClassName={css(s.fld)}
                        className={cn(css(s.addInp))}
                        labelClassName={css(s.addInpLab)}
                    />

                    <Tags
                        isCandidateTags
                        values={values}
                        removeMode={removeMode}
                        onSelectTag={onSelectTag}
                        setFieldValue={setFieldValue} />
                </div>
            </Form>
        </div>
    )
}

export const MainInfo = memo(MemoizedComponent)

const s = StyleSheet.create({
    box: {
        background: COLORS.white,
        border: `1px solid ${COLORS.smoothGray}`,
        boxShadow: '0px 2px 4px rgba(130, 130, 130, 0.05)',
        borderRadius: 4,
    },
    formName: {
        height: 'calc(3rem - 1px)',
        padding: '0 22px 0 24px',
        borderBottom: `1px solid ${COLORS.lightGray}`,
        ':nth-child(1n) > :first-child': {
            fontSize: 13,
            fontWeight: '600',
            color: '#181A19',
            textTransform: 'uppercase',
        },
    },
    add: {
        fontWeight: '500',
        fontSize: 12,
        color: COLORS.skyblue,
        cursor: 'pointer',
    },
    inpGr: {
        padding: '16px 24px 24px',
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gridGap: 24,
        alignItems: 'start',
    },
    addInp: {
        position: 'relative',
        border: `1px solid ${COLORS.lightGray}`,
        borderRadius: 4,
        fontSize: 14,
        fontWeight: '500',
        color: '#181A19',
    },
    addInpLab: {
        fontSize: 12,
        fontWeight: '500',
        color: COLORS.midGray,
        marginBottom: 8,
    },
    fld: {
        position: 'relative',
    },
})
