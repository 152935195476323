import React, { useEffect, useState } from 'react'
import { css, StyleSheet } from 'aphrodite'
import { useNavigate } from 'react-router-dom'
import Table from '../common/Table'
import { useGetRequest, usePutRequest } from '../../hooks/request'
import { VACANCY_ARCHIVE, VACANCY_LIST } from '../../urls'
import { fetchDataByFilter } from '../../utils/filter'
import VacancyTableHeader from './VacancyTableHeader'
import VacancyTableItem from './VacancyTableItem'
import { useMessage } from '../../hooks/message'

export default function VacancyTable({ vacancyFilter, setVacanciesCount }) {
    const navigate = useNavigate()
    const [showMessage] = useMessage()
    const [page, setPage] = useState(1)
    const [pageSize, setPageSize] = useState(10)
    const [sort, setSort] = useState({})
    const getGridInfo = useGetRequest({ url: VACANCY_LIST })
    const archive = usePutRequest()

    const data = getGridInfo.response ? getGridInfo.response.data : []
    const meta = getGridInfo.response ? getGridInfo.response.meta : {}

    const additionalParams = { page, 'per-page': pageSize, sort: Object.values(sort).filter((e) => e !== '').join('&') }

    useEffect(() => {
        fetchDataByFilter(getGridInfo, vacancyFilter, additionalParams)
    }, [vacancyFilter, page, pageSize, sort])

    useEffect(() => {
        if (meta) setVacanciesCount(meta.totalCount)
    }, [meta])

    const onArchive = (id, status, ref) => {
        if (!id) return

        archive.request({ url: VACANCY_ARCHIVE.replace('{id}', id), data: { push_to_hh: status === 2 } })
            .then((res) => {
                if (res.response && res.response.success) {
                    ref.current.onClose()
                    getGridInfo.setResponse((prev) => ({
                        ...getGridInfo.response,
                        data: prev.data.filter((v) => v.id !== id),
                    }))
                    showMessage('Успешно!', 'success-msg')
                    return
                }

                showMessage(res.error.data.message, 'error-msg')
            })
    }

    const headerColumns = [
        { id: 1, name: 'Название вакансии', width: 'calc(100% * 2.25 / 12)' },
        { id: 2, name: 'Рекрутер', width: 'calc(100% * 2.25 / 12)' },
        {
            id: 3,
            title: 'Колонка',
            name: 'column_name',
            value: sort,
            isSortable: true,
            setValue: setSort,
            width: 'calc(100% * 1.75 / 12)',
        },
        {
            id: 4,
            title: 'Откликов',
            name: 'candidates',
            value: sort,
            isSortable: true,
            setValue: setSort,
            width: 'calc(100% * 1.75 / 12)',
        },
        {
            id: 5,
            title: 'Дедлайн',
            name: 'deadline',
            value: sort,
            isSortable: true,
            setValue: setSort,
            width: 'calc(100% * 1.75 / 12)',
        },
        {
            id: 6,
            title: 'Статус',
            name: 'status',
            value: sort,
            isSortable: true,
            setValue: setSort,
            width: 'calc(100% * 1.75 / 12)',
        },
        { id: 7, name: '', width: 'calc(100% * 0.5 / 12)' },
    ]

    return (
        <div className={css(s.cont)}>
            <Table
                isHoverable
                isStripped
                items={data}
                isScrollable
                setPage={setPage}
                loaderItemCount={10}
                pageSize={meta.perPage}
                page={meta.currentPage}
                count={meta.totalCount}
                className={css(s.table)}
                setPageSize={setPageSize}
                pageCount={meta.pageCount}
                loading={getGridInfo.loading}
                customHeader={<VacancyTableHeader headerColumns={headerColumns} />}
                renderItem={(item) => (
                    <VacancyTableItem
                        item={item}
                        onArchive={onArchive}
                        loading={getGridInfo.loading}
                        onEdit={() => navigate(`/vacancies/${item.id}/form/update?mode=edit`)}
                        onClick={() => navigate(`/vacancies/${item.id}/info`)}
                    />
                )} />
        </div>
    )
}

const s = StyleSheet.create({
    cont: {
        width: '100%',
        padding: '0 24px 24px',
        boxSizing: 'border-box',
    },
    table: {
        width: '100%',
        textAlign: 'left',
        height: 'calc(100% - 50px)',
    },
})
