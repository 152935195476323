import React from 'react'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import { useNavigate } from 'react-router-dom'
import { Info, Vacancy } from '../svgs/Svgs'
import Tooltip from '../common/Tooltip'
import { COLORS } from '../../utils/colors'
import { useLoad } from '../../hooks/request'
import { APPLIED_VACANCIES_STATISTICS } from '../../urls'
import { Briefcase } from '../svgs/SvgPictures'
import { strCutter } from '../../utils/string'

export default function PostsByVacancies() {
    const navigate = useNavigate()
    const loadVacancies = useLoad({ url: APPLIED_VACANCIES_STATISTICS })
    const vacancies = loadVacancies.response ? loadVacancies.response.data : []

    return (
        <div className={css(s.box)}>
            <div className={css(s.top)}>
                <div className="align-center">
                    <h3 className={css(s.topTxt)}>Отклики по вакансиям</h3>

                    <Tooltip
                        className={css(s.tip)}
                        trigger={(
                            <span className={cn(css(s.info), 'flex-cent')}>
                                <Info />
                            </span>
                        )}>
                        <div>Отклики кандидатов по созданным <br />вакансиям</div>
                    </Tooltip>
                </div>
            </div>

            {vacancies.length > 0 ? vacancies.map((vacancy) => (
                <div
                    key={vacancy.id}
                    onClick={() => navigate(`/vacancies/${vacancy.id}/info`)}
                    className={css(s.boxItem)}
                >
                    <div className={cn('flex-cent', css(s.icon))}>
                        <Vacancy color={COLORS.mainColor} />
                    </div>

                    <div className="justify-between full-width">
                        <div className="is-flex direction-column gap-1">
                            <h3 title={vacancy.name}>{strCutter(vacancy.name, 120)}</h3>

                            <p>
                                <span title={vacancy.departmentName}>{strCutter(vacancy.departmentName, 45)}</span>
                                {' • '}
                                <span title={vacancy.name}>{strCutter(vacancy.name, 45)}</span>
                            </p>
                        </div>

                        <div className={cn(css(s.addedAt), 'flex-cent')}>
                            {vacancy.count}
                        </div>
                    </div>
                </div>
            )) : (
                <div className="justify-evenly direction-column flex-1">
                    <Briefcase />

                    <h3 className={css(s.emp_txt)}>Пока в системе нет вакансий и откликов по ним</h3>
                </div>
            )}
        </div>
    )
}

const s = StyleSheet.create({
    box: {
        background: COLORS.white,
        boxShadow: '0px 2px 8px rgba(136, 136, 136, 0.25)',
        borderRadius: 12,
        height: '100%',
        boxSizing: 'border-box',
        padding: 20,
        display: 'flex',
        flexDirection: 'column',
    },
    emp_txt: {
        fontSize: 13,
        width: '70%',
        textAlign: 'center',
        fontWeight: '600',
        color: COLORS.midGray,
    },
    top: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 16,
    },
    icon: {
        width: 36,
        height: 36,
        borderRadius: 4,
        border: '1px solid #D3ECE3',
        background: '#FAFFFC',
    },
    topTxt: {
        color: '#181A19',
        fontSize: 15,
        fontWeight: '600',
        marginRight: 6,
    },
    info: {
        cursor: 'help',
        width: 14,
        height: 14,
        ':hover': {
            ':nth-child(1n) > :first-child': {
                ':nth-child(1n) > *': {
                    stroke: COLORS.skyblue,
                },
            },
        },
    },
    boxItem: {
        display: 'flex',
        alignItems: 'center',
        padding: 12,
        gap: 8,
        cursor: 'pointer',
        height: 60,
        overflow: 'hidden',
        boxSizing: 'border-box',
        borderRadius: 4,
        marginBottom: 12,
        border: `1px solid ${COLORS.smoothGray}`,
        background: COLORS.white,
        boxShadow: '0px 2px 4px 0px rgba(130, 130, 130, 0.05)',
        ':nth-child(1n) > :last-child': {
            ':nth-child(1n) > :first-child': {
                ':nth-child(1n) > :first-child': {
                    fontSize: 13,
                    fontWeight: '500',
                    color: '#181A19',
                },
                ':nth-child(1n) > :last-child': {
                    fontSize: 11,
                    fontWeight: '400',
                    color: COLORS.midGray,
                },
            },
        },
        ':last-of-type': {
            marginBottom: 0,
        },
    },
    role: {
        height: 24,
        boxSizing: 'border-box',
        background: 'rgba(0, 149, 242, 0.1)',
        border: '1px solid rgba(0, 149, 242, 0.2)',
        borderRadius: 4,
        fontWeight: '500',
        fontSize: 11,
        padding: '0 6px',
        color: COLORS.skyblue,
    },
    addedAt: {
        borderRadius: 4,
        border: `1px solid ${COLORS.smoothGray}`,
        background: COLORS.white,
        height: 30,
        padding: '0 8px',
        color: COLORS.dark,
        fontSize: 13,
        fontWeight: '500',
    },
    tip: {
        zIndex: 1,
    },
})
