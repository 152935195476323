import React, { useState } from 'react'
import { css, StyleSheet } from 'aphrodite'
import { TableView } from './TableView'
import { COLORS } from '../../../../utils/colors'
import { useInfiniteScroll, usePutRequest } from '../../../../hooks/request'
import { COMPANY_VACANCIES, VACANCY_PUBLISH } from '../../../../urls'
import { useMessage } from '../../../../hooks/message'
import { Header } from './Header'
import GridView from './GridView'
import RowView from './RowView'
import { AddVacancies } from '../../../svgs/SvgPictures'

export default function Vacancies({ info }) {
    const [showMessage] = useMessage()
    const publishVacancy = usePutRequest()

    const loadVacancies = useInfiniteScroll({ url: COMPANY_VACANCIES, params: { 'per-page': 20 } }, [])
    const vacancies = loadVacancies.response ? loadVacancies.response.data : []
    const meta = loadVacancies.response ? loadVacancies.response.meta : []

    const [switchView, setSwitchView] = useState('grid')

    const handleActivateVacancy = async (id, status, fetchedVacancies) => {
        const { success, error } = await publishVacancy.request(
            { url: VACANCY_PUBLISH.replace('{id}', id), data: { status: !status } },
        )

        if (success) {
            fetchedVacancies.setResponse({
                ...fetchedVacancies.response,
                data: vacancies.map((v) => ({ ...v, publish: v.id === id ? !status : v.publish })),
            })

            showMessage('Успешно!', 'success-msg')
            return
        }

        showMessage(error.data.errors[0].message || error.data.message, 'error-msg')
    }

    const goTo = (id) => `/settings/company-info/vacancy/${id}`
    const companyLogo = info.logo ? info.logo[0] : ''

    return (
        <div className="is-flex direction-column gap-5">
            <Header
                totalCount={meta.totalCount}
                companyName={info.name}
                switchView={switchView}
                setSwitchView={setSwitchView}
            />

            {switchView === 'grid' ? (
                <GridView
                    info={info}
                    goTo={goTo}
                    vacancies={vacancies}
                    companyLogo={companyLogo}
                    loadVacancies={loadVacancies}
                    handleActivateVacancy={handleActivateVacancy}
                />
            ) : switchView === 'table' ? (
                <TableView info={info} goTo={goTo} handleActivateVacancy={handleActivateVacancy} />
            ) : (
                <React.Fragment>
                    <RowView
                        goTo={goTo}
                        vacancies={vacancies}
                        companyLogo={companyLogo}
                        loadVacancies={loadVacancies}
                        handleActivateVacancy={handleActivateVacancy}
                    />
                </React.Fragment>
            )}

            {!loadVacancies.loading && !vacancies.length ? (
                <div className={css(s.noCardWrap)}>
                    <div>
                        <AddVacancies />

                        <h3 className={css(s.noCard)}>
                            Добавляйте новые вакансии в заявках компании 🙌
                        </h3>
                    </div>
                </div>
            ) : null}
        </div>
    )
}
const s = StyleSheet.create({
    noCardWrap: {
        ':nth-child(1n) > :first-child': {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
        },
    },
    noCard: {
        lineHeight: 1.2,
        fontSize: 13,
        fontWeight: '600',
        color: COLORS.midGray,
        textAlign: 'center',
        marginTop: '1.5rem',
    },
})
