import React from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import CandidateForm from '../components/candidates/CandidateForm'
import { usePostRequest } from '../hooks/request'
import { CANDIDATE_CREATE } from '../urls'
import { useMessage } from '../hooks/message'

export default function CandidateCreate() {
    const { vacancyId } = useParams()
    const { state } = useLocation()
    const navigate = useNavigate()
    const create = usePostRequest({ url: CANDIDATE_CREATE })
    const [showMessage] = useMessage()

    const onSubmit = async (data) => {
        const { languageLevel, phoneNumber, scNumber, ...formData } = data

        const { success, error } = await create.request({ data: {
            ...formData,
            vacancyId,
            phoneNumber: phoneNumber ? `+998${phoneNumber.replace(/\s+/g, '')}` : null,
            scNumber: scNumber ? `+998${scNumber.replace(/\s+/g, '')}` : null,
            languages: Object.values(data.languageLevel).filter((e) => !(e.lang === null && e.level === null)),
            desiredSalary: +data.desiredSalary.replace(/\s+/g, ''),
        } })
        if (success) {
            navigate(`/vacancies/${vacancyId}/info`)
            return showMessage('Успешно добавлено!', 'success-msg')
        }
        return showMessage(error.data.errors[0].message || error.data.message, 'error-msg')
    }

    return (
        <CandidateForm
            onSubmit={onSubmit}
            loading={create.loading}
            onCancel={() => navigate(-1)}
            vacancyName={state ? state.vacancyName : ''} />
    )
}
