import React, { useEffect } from 'react'
import { css, StyleSheet } from 'aphrodite'
import { Form, Formik } from 'formik'
import cn from 'classnames'
import { isEmpty } from 'lodash'
import { useLocation, useNavigate } from 'react-router-dom'
import LayoutRegister from '../components/common/LayoutRegister'
import { phone, required, validator } from '../utils/validators'
import FormInput from '../components/common/FormInput'
import SelectForm from '../components/common/SelectForm'
import FormInputPhone from '../components/common/FormInputPhone'
import { useLoad, usePostRequest } from '../hooks/request'
import { COMPANY_CREATE, INDUSTRIES_LIST } from '../urls'
import { useMessage } from '../hooks/message'
import { GoBack } from '../components/svgs/Svgs'
import { COLORS } from '../utils/colors'
import Button from '../components/common/Button'

export default function AddProject() {
    const { state } = useLocation()
    const navigate = useNavigate()

    useEffect(() => {
        if (state && state.isAllowed) return
        navigate('/dashboard')
    }, [])

    const loadIndustries = useLoad({ url: INDUSTRIES_LIST })
    const companyCreate = usePostRequest({ url: COMPANY_CREATE })
    const [showMessage] = useMessage()

    const industries = loadIndustries.response ? loadIndustries.response.data : []
    // const [img, setImg] = useState('')
    // const inputRef = useRef(null)

    async function onSubmit(data) {
        const { success, error } = await companyCreate.request({
            data: {
                name: data.name,
                phone_number: `+998${data.phone.replace(/\s/g, '')}`,
                industry_id: data.industry.value,
            },
        })
        if (success) {
            showMessage('Успешно!', 'success-msg')
            return navigate('/projects', { state: { isAllowed: true } })
        }
        return showMessage(error.data.errors[0].message || error.data.message, 'error-msg')
    }

    return (
        <LayoutRegister>
            <div className={css(s.wrapper)}>
                <span onClick={() => navigate(-1)} className={cn('flex-cent pointer', css(s.goBack))}><GoBack /></span>

                <div className={css(s.form)}>
                    <h3 className={css(s.regTxt)}>Регистрация компании</h3>

                    <Formik
                        onSubmit={(data, helpers) => onSubmit(data, helpers)}
                        initialValues={{ name: '', phone: '', industry: '' }}>
                        {({ errors, values }) => (
                            <React.Fragment>
                                <Form className="is-flex direction-column">
                                    {/* <> */}
                                    {/*     {!img ? ( */}
                                    {/*         <span onClick={() => { */}
                                    {/*             inputRef.current.click() */}
                                    {/*         }} className={css(s.upload)}> */}
                                    {/*             <UploadPhoto /> */}

                                    {/*             <h3>Загрузить логотип компании</h3> */}
                                    {/*         </span> */}
                                    {/*     ) : ( */}
                                    {/*         <div className={css(s.uploadCont)}> */}
                                    {/*             <img */}
                                    {/*                 src={img} */}
                                    {/*                 alt="#avatar" */}
                                    {/*                 className={css(s.uplImg)} /> */}

                                    {/*             <div className={css(s.btn_group)}> */}
                                    {/*                 <Button onClick={() => inputRef.current.click()}> */}
                                    {/*                     Отправить другую фотографию */}
                                    {/*                 </Button> */}

                                    {/*                 <Button onClick={() => { */}
                                    {/*                     inputRef.current.value = '' */}
                                    {/*                     setImg('') */}
                                    {/*                 }}> */}
                                    {/*                     <Trash /> */}
                                    {/*                     Удалить фотографию */}
                                    {/*                 </Button> */}
                                    {/*             </div> */}
                                    {/*         </div> */}
                                    {/*     )} */}
                                    {/* </> */}

                                    <FormInput
                                        validate={required}
                                        placeholder="Введите название"
                                        name="name"
                                        errorWithMessage={false}
                                        label="Название вашей компании"
                                        fieldClassName={css(s.field)}
                                        labelClassName={css(s.lab)}
                                    />

                                    <FormInputPhone
                                        name="phone"
                                        errorWithMessage={false}
                                        validate={validator(required, phone)}
                                        placeholder="_ _  _ _ _  _ _  _ _"
                                        label="Номер телефона компании"
                                        fieldClassName={css(s.field)}
                                        labelClassName={css(s.lab)}
                                    />

                                    <SelectForm
                                        label="Отрасль работы компании"
                                        placeholder="Выберите отрасль"
                                        name="industry"
                                        errorWithMessage={false}
                                        valueContainerStyles={{ paddingTop: 11.5, paddingBottom: 11.5 }}
                                        validate={(val) => {
                                            if (isEmpty(val)) return 'Это обязательное поле'
                                            return null
                                        }}
                                        indicatorStyle={{ paddingRight: 15 }}
                                        labelContClassName={css(s.selectLabel)}
                                        placeholderStyles={{ color: COLORS.gray }}
                                        options={industries.map(({ id, name }) => ({ value: id, label: name }))} />

                                    <Button
                                        loaderColor={COLORS.white}
                                        loaderWidth={20}
                                        strokeWidth={6}
                                        loading={companyCreate.loading}
                                        type="submit"
                                        className={cn(
                                            css(s.btn),
                                            { [css(s.sbmBtn)]: isEmpty(errors)
                                                && Object.values(values).every((e) => e !== '') },
                                        )}
                                    >
                                        Далее
                                    </Button>

                                    {/* <button */}
                                    {/*    disabled={!isEmpty(errors) */}
                                    {/*        || companyCreate.loading} */}
                                    {/*    type="submit" */}
                                    {/*    className={cn( */}
                                    {/*        css(s.btn), */}
                                    {/*        { [css(s.sbmBtn)]: */}
                                    {/*        isEmpty(errors) */}
                                    {/*        && !companyCreate.loading */}
                                    {/*        && Object.values(values).every((e) => e !== '') }, */}
                                    {/*    )}> */}
                                    {/*    Далее */}
                                    {/* </button> */}

                                    {/* <input */}
                                    {/*    id="file" */}
                                    {/*    type="file" */}
                                    {/*    readOnly="readonly" */}
                                    {/*    accept="image/*" */}
                                    {/*    ref={inputRef} */}
                                    {/*    className={css(s.up_inp)} */}
                                    {/*    onChange={(e) => (e.target.files[0] */}
                                    {/*        ? setImg(URL.createObjectURL(e.target.files[0])) */}
                                    {/*        : '' */}
                                    {/*    )} /> */}
                                </Form>
                            </React.Fragment>
                        )}
                    </Formik>
                </div>
            </div>
        </LayoutRegister>
    )
}

const s = StyleSheet.create({
    wrapper: {
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        marginLeft: '9.4%',
        maxWidth: '29rem',
        display: 'flex',
        alignItems: 'flex-start',
        gap: '2rem',
        marginTop: 20,
        paddingBottom: 20,
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        gap: 18,
        flex: 1,
        ':nth-child(1n) > form': {
            gap: 32,
        },
    },
    upload: {
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        ':nth-child(1n) > :last-child': {
            marginLeft: 8,
            fontSize: 12,
            fontWeight: '500',
            color: COLORS.skyblue,
        },
    },
    regTxt: {
        fontSize: 36,
        fontWeight: '600',
        color: '#181A19',
        whiteSpace: 'nowrap',
    },
    lab: {
        display: 'block',
        fontSize: 12,
        fontWeight: '500',
        color: COLORS.midGray,
        marginBottom: 8,
    },
    field: {
        position: 'relative',
    },
    btn: {
        border: 'none',
        padding: 0,
        outline: 'none',
        width: '100%',
        height: 40,
        background: COLORS.gray,
        borderRadius: 4,
        fontWeight: '500',
        fontSize: 14,
        color: '#FFF',
        cursor: 'not-allowed',
        boxShadow: '0 4px 12px rgba(44, 67, 50, 0.1), inset 0px -1px 0px rgba(0, 0, 0, 0.25), '
            + 'inset 0px 1px 0px rgba(255, 255, 255, 0.1)',
    },
    sbmBtn: {
        cursor: 'pointer',
        background: `linear-gradient(180deg, #2EA97D 0%, ${COLORS.lightMainColor} 100%)`,
        boxShadow: '0 4px 12px rgba(44, 67, 50, 0.1), inset 0px -1px 0px rgba(0, 0, 0, 0.25), '
            + 'inset 0px 1px 0px rgba(255, 255, 255, 0.1)',
    },
    up_inp: {
        display: 'none',
    },
    uploadCont: {
        margin: '32px 0',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    uplImg: {
        width: 96,
        height: 96,
        border: `1px solid ${COLORS.lightGray}`,
        borderRadius: 8,
    },
    btn_group: {
        ':nth-child(1n) > :first-child': {
            padding: '12px 16px',
            color: '#2EA97D',
            fontSize: 14,
            fontWeight: '500',
            border: `1px solid ${COLORS.smoothGray}`,
            background: COLORS.mintCream,
            boxShadow: '0 4px 20px rgba(44, 67, 50, 0.05)',
            borderRadius: 4,
            height: 38,
        },
        ':nth-child(1n) > :last-child': {
            marginTop: 12,
            padding: '12px 15px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            border: `1px solid ${COLORS.gray}`,
            background: 'transparent',
            filter: 'drop-shadow(0px 4px 12px rgba(44, 67, 50, 0.1))',
            borderRadius: 4,
            color: COLORS.gray,
            fontSize: 13,
            fontWeight: '500',
            height: 38,
            ':nth-child(1n) > :first-child': {
                marginRight: 8,
            },
        },
    },
    goBack: {
        marginTop: 2,
        width: '2.25rem',
        height: '2.25rem',
        background: COLORS.lotion,
        borderRadius: 64,
        ':nth-child(1n) > :first-child': {
            ':nth-child(1n) > :first-child': {
                stroke: '#181A19',
            },
        },
    },
    selectLabel: {
        ':nth-child(1n) > :first-child': {
            display: 'inline-flex',
        },
    },
})
