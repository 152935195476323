import React, { memo, useCallback, useState } from 'react'
import cn from 'classnames'
import { css, StyleSheet } from 'aphrodite'
import moment from 'moment'
import { range } from 'lodash'
import { CalendarLayoutSizeS, ChevronPrev, CloseSizeS, TemplateTabler } from '../../svgs/Svgs'
import PopupSimple from '../../common/PopupSimple'
import { COLORS } from '../../../utils/colors'
import { useDeleteRequest, useGetRequest } from '../../../hooks/request'
import { TEMPLATE_DELETE, TEMPLATE_DETAIL, TEMPLATE_LIST } from '../../../urls'
import { useMessage } from '../../../hooks/message'
import { LoadingSkeleton } from '../../common/LoadingSkeleton'
import { strCutter } from '../../../utils/string'
import { banknoteOptions, educationLvl, genderOptions, WorkFormat, WorkSchedule } from '../../../utils/options'
import { format, integersOnly } from '../../../utils/number'

const setInitialValues = (info) => ({
    ...info,
    skills: info.skill || [],
    tag: info.tag,
    format: info.format ? WorkFormat[info.format - 1] : '',
    schedule: info.schedule ? WorkSchedule[info.schedule - 1] : '',
    education: educationLvl.find((e) => e.value === info.education),
    gender: genderOptions.find((e) => e.value === info.gender),
    salary: {
        from: info.salaryFrom ? format(integersOnly(info.salaryFrom)) : '',
        to: info.salaryTo ? format(integersOnly(info.salaryTo)) : '',
        currency: banknoteOptions.find((e) => e.label === info.currency),
    },
    experience: {
        from: info.experienceFrom ? format(integersOnly(info.experienceFrom)) : '',
        to: info.experienceTo ? format(integersOnly(info.experienceTo)) : '',
    },
    languageLevel: info.languageLevel ? [...info.languageLevel, { language: '', lvl: null }] : [],
    options: info.options || [{ key: '', value: '' }],
    position: info.position && { value: info.position.id, label: info.position.name },
    department: info.department && { value: info.department.id, label: info.department.name },
    // eslint-disable-next-line max-len
    location: info.location && info.location.id ? { ...info.location, value: info.location.id, label: info.location.name } : null,
    recruiter: info.recruiter && info.recruiter.id ? { value: info.recruiter.id, label: info.recruiter.name } : null, // eslint-disable-line max-len
    responsible: info.responsible && info.responsible.id ? { value: info.responsible.id, label: info.responsible.name } : null, // eslint-disable-line max-len
    isReinitialized: true,
})

function MemoizedComponent({ setTemplateInfo }) {
    const [open, setOpen] = useState(false)
    const deleteTemplate = useDeleteRequest()
    const getTemplates = useGetRequest({ url: TEMPLATE_LIST })
    const getTemplate = useGetRequest()
    const template = getTemplates.response ? getTemplates.response.data : []
    const [showMessage] = useMessage()

    const onDelete = useCallback(async (event, id, ind) => {
        event.stopPropagation()
        const { success, error } = await deleteTemplate.request({ url: TEMPLATE_DELETE.replace('{id}', id) })

        if (success) {
            template.splice(ind, 1)
            getTemplates.setResponse({ ...getTemplates.response, data: template })
        } else {
            showMessage(error.data.errors[0].message || error.data.message, 'error-msg')
        }
    }, [template])

    const onSelect = useCallback(async (id) => {
        const { response, success, error } = await getTemplate.request({ url: TEMPLATE_DETAIL.replace('{id}', id) })

        if (success) {
            setTemplateInfo(setInitialValues(response.data))
            setOpen(false)
        } else {
            showMessage(error.data.errors[0].message || error.data.message, 'error-msg')
        }
    }, [])

    return (
        <PopupSimple
            open={open}
            onOpen={() => {
                setOpen(true)
                if (!template.length && !getTemplates.loading) getTemplates.request()
            }}
            onClose={() => setOpen(false)}
            position="bottom left"
            trigger={(
                <div className={cn('align-center pointer dis_sl gap-2', css(s.temp))}>
                    <h3>Шаблоны вакансий</h3>

                    <ChevronPrev rotate={-90} />
                </div>
            )}
        >
            <div className={css(s.list)}>
                <ul className="no_dot_list">
                    {!getTemplates.loading ? template.length !== 0 ? template.map((e, ind) => (
                        <li
                            key={e.id}
                            onClick={() => onSelect(e.id)}
                            className={`align-center gap-2 ${deleteTemplate.loading ? 'progress-cursor' : 'pointer'}`}>
                            <div className={cn('flex-cent', css(s.icon))}>
                                <TemplateTabler />
                            </div>

                            <div className="is-flex direction-column gap-1 flex-1">
                                <h3 className={css(s.name)}>{strCutter(e.name, 30)}</h3>

                                <h3 className={css(s.work)}>
                                    {strCutter(e.department, 20)} • {strCutter(e.position, 20)}
                                </h3>
                            </div>

                            <div className={cn('align-center gap-2', css(s.date))}>
                                <CalendarLayoutSizeS />

                                <h3 className={css(s.dateText)}>{moment(e.createdAt).format('DD MMMM YYYY')}</h3>
                            </div>

                            <CloseSizeS onClick={(event) => onDelete(event, e.id, ind)} className={css(s.rmIcon)} />
                        </li>
                    )) : (
                        <li className="align-center gap-2">
                            <h3 className={css(s.noTempText)}>У вас пока нет шаблонов</h3>
                        </li>
                    ) : range(5).map((e) => (
                        <li key={e} className="align-center gap-2">
                            <div className={cn('flex-cent', css(s.icon))}>
                                <TemplateTabler />
                            </div>

                            <div className="is-flex direction-column gap-1 flex-1">
                                <LoadingSkeleton height={16} />

                                <div className="align-center gap-1">
                                    <LoadingSkeleton height={14} width={100} />

                                    <h3 className={css(s.work)}>•</h3>

                                    <LoadingSkeleton height={14} width={100} />
                                </div>
                            </div>

                            <LoadingSkeleton height={16} />
                        </li>
                    ))}
                </ul>
            </div>
        </PopupSimple>
    )
}

export const Templates = memo(MemoizedComponent)

const s = StyleSheet.create({
    temp: {
        height: 36,
        padding: '0 12px',
        borderRadius: 4,
        border: `1px solid${COLORS.lightGray}`,
        background: '#FFF',
        boxShadow: '0px 2px 4px 0px rgba(130, 130, 130, 0.05)',
        color: COLORS.dark,
        fontSize: 14,
        fontWeight: '500',
    },
    list: {
        marginTop: 10,
        borderRadius: 4,
        border: `1px solid${COLORS.lightGray}`,
        background: '#FFF',
        maxHeight: 284,
        overflowY: 'auto',
        boxShadow: '0px 4px 16px 0px rgba(62, 89, 78, 0.15)',
        ':nth-child(1n) > :first-child': {
            ':nth-child(1n) > *': {
                padding: '0 16px',
                height: 56,
                width: '26.25rem',
                borderBottom: `1px solid ${COLORS.lightGray}`,
                ':hover': {
                    background: COLORS.smoothGray,
                },
            },
            ':nth-child(1n) > :first-child': {
                borderTopRightRadius: 4,
                borderTopLeftRadius: 4,
            },
            ':nth-child(1n) > :last-child': {
                borderBottomWidth: 0,
                borderBottomRightRadius: 4,
                borderBottomLeftRadius: 4,
            },
        },
    },
    name: {
        color: COLORS.dark,
        fontSize: 13,
        fontWeight: '500',
    },
    work: {
        color: COLORS.midGray,
        fontSize: 11,
        fontWeight: '400',
    },
    date: {
        height: 24,
        padding: '0 6px',
        borderRadius: 4,
        background: COLORS.smoothGray,
    },
    icon: {
        width: 36,
        height: 36,
        borderRadius: 4,
        border: '1px solid #D3ECE3',
        background: COLORS.mintCream,
    },
    dateText: {
        color: COLORS.dark,
        fontSize: 10,
        fontWeight: '500',
    },
    rmIcon: {
        ':hover': {
            ':nth-child(1n) > :first-child': {
                stroke: COLORS.dark,
            },
        },
    },
    noTempText: {
        color: COLORS.gray,
        fontSize: 15,
        fontWeight: '500',
    },
})
