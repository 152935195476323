import React, { memo, useEffect, useState } from 'react'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import { components } from 'react-select'
import Highlighter from 'react-highlight-words'
import { isEmpty } from 'lodash'
import SelectForm from '../../common/SelectForm'
import { COLORS } from '../../../utils/colors'
import { useGetRequest, useLoad } from '../../../hooks/request'
import { EMPLOYEE_LIST, LOCATION_LIST } from '../../../urls'
import InputDate from '../../common/InputDate'
import { Map, MapWrapper } from '../../common/Map'
import { CloseSizeL, MapPinSizeL } from '../../svgs/Svgs'
import EditorField from '../../common/EditorField'
import { maxLength } from '../../../utils/validators'

function MemoizedComponent({ values, setFieldValue, innerRef }) {
    const [showMap, setShowMap] = useState(false)
    const loadLocations = useLoad({ url: LOCATION_LIST })
    const getEmployees = useGetRequest({ url: EMPLOYEE_LIST, params: { 'per-page': 100, userExist: 1 } })

    const locations = loadLocations.response
        ? loadLocations.response.data.map((e) => ({ value: e.id, label: e.name, lat: +e.latitude, lng: +e.longitude }))
        : []

    const employees = getEmployees.response ? getEmployees.response.data.map((e) => ({
        value: e.userId,
        role: e.role,
        photo: e.photo[4],
        position: e.position,
        department: e.department,
        label: `${e.firstName} ${e.lastName}`,
        shortName: e.firstName.slice(0, 1) + e.lastName.slice(0, 1),
    })) : []

    const executor = employees.filter((e) => [2, 3].includes(e.role))

    const Option = (props) => {
        const { selectProps, data } = props
        const { label, department, position, photo, shortName } = data

        return (
            <components.Option {...props}>
                <div className="align-center gap-2" style={{ height: 52 }}>
                    {photo ? <img className={css(s.img)} src={photo} alt="#avatar" /> : (
                        <div className={cn('flex-cent', css(s.defaultPhoto))}>
                            <h3>{shortName}</h3>
                        </div>
                    )}

                    <div className="is-flex direction-column align-start justify-center">
                        <Highlighter
                            textToHighlight={label.slice(0, 25) + (label.length > 25 ? '...' : '')}
                            activeClassName="highlight"
                            searchWords={[selectProps.inputValue.trim()]}
                            className={css(s.optLab)}
                        />

                        <div className="align-center gap-1">
                            <h3 className={css(s.txt)}>
                                {department.slice(0, 15) + (department.length > 15 ? '...' : '')}
                            </h3>

                            <span>•</span>

                            <h3 className={css(s.txt)}>
                                {position.slice(0, 15) + (position.length > 15 ? '...' : '')}
                            </h3>
                        </div>
                    </div>
                </div>
            </components.Option>
        )
    }

    useEffect(() => {
        if (values.location && !('lat' in values.location)) {
            setFieldValue('location', locations.find((e) => e.value === values.location.value))
        }
    }, [values.location])

    return (
        <div id="step-2" ref={innerRef} data-step={2} className={css(s.box)}>
            <div className={cn('justify-between', css(s.formName))}>
                <h3>информация о вакансии</h3>
            </div>

            <div className={css(s.inpGr)}>
                <InputDate label="Дата начала вакансии" name="startAt" />

                <InputDate label="Дата закрытия вакансии" name="deadline" />

                <SelectForm
                    name="recruiter"
                    options={executor}
                    loading={getEmployees.loading}
                    label="Рекрутеры"
                    value={values.recruiter}
                    styles={{
                        menu: (menuStyles) => ({
                            ...menuStyles,
                            zIndex: 2,
                        }),
                    }}
                    labelClassName={css(s.lb)}
                    customComponents={{ Option }}
                    labelContClassName={css(s.lab)}
                    optStyle={{ padding: '0 16px' }}
                    placeholder="Выберите рекрутера"
                    indicatorStyle={{ paddingRight: 10 }}
                    valueContainerStyles={{ padding: '11.5px 16px' }}
                    onChange={(value) => setFieldValue('recruiter', value)}
                    onMenuOpen={() => isEmpty(executor) && getEmployees.request()}
                    placeholderStyles={{ color: COLORS.gray, fontFamily: 'Roboto, sans-serif' }}
                />

                <SelectForm
                    name="responsible"
                    options={employees}
                    loading={getEmployees.loading}
                    styles={{
                        menu: (menuStyles) => ({
                            ...menuStyles,
                            zIndex: 2,
                        }),
                    }}
                    value={values.responsible}
                    labelClassName={css(s.lb)}
                    customComponents={{ Option }}
                    label="Ответственный за заявку"
                    labelContClassName={css(s.lab)}
                    optStyle={{ padding: '0 16px' }}
                    placeholder="Выберите ответственного"
                    indicatorStyle={{ paddingRight: 10 }}
                    valueContainerStyles={{ padding: '11.5px 16px' }}
                    onChange={(value) => setFieldValue('responsible', value)}
                    onMenuOpen={() => isEmpty(employees) && getEmployees.request()}
                    placeholderStyles={{ color: COLORS.gray, fontFamily: 'Roboto, sans-serif' }}
                />

                <div className={css(s.editorCont)}>
                    <EditorField
                        name="description"
                        label="Описание вакансии"
                        validate={(value) => (
                            maxLength(200)(
                                typeof value === 'string' ? value
                                    : value && value.getCurrentContent().getPlainText('\u0001'),
                            )
                        )}
                        defaultValue={values.description}
                        onChange={(value) => setFieldValue('description', value)} />
                </div>

                <SelectForm
                    name="location"
                    options={locations}
                    loading={loadLocations.loading}
                    label="Локация работы"
                    value={values.location}
                    labelClassName={css(s.lb)}
                    labelContClassName={css(s.lab)}
                    placeholder="Выберите локацию"
                    indicatorStyle={{ paddingRight: 10 }}
                    valueContainerStyles={{ padding: '11.5px 16px' }}
                    onChange={(value) => setFieldValue('location', value)}
                    placeholderStyles={{ color: COLORS.gray, fontFamily: 'Roboto, sans-serif' }}
                    contClassname={css(s.selectCont)}
                />

                <div className={css(s.mapCont)}>
                    <div onClick={() => setShowMap(!showMap)} className="align-center gap-2 pointer">
                        { showMap
                            ? (
                                <>
                                    <CloseSizeL color={COLORS.mainColor} />
                                    <h3 className={css(s.mapTriggerText)}>Скрыть карту</h3>
                                </>
                            )
                            : (
                                <>
                                    <MapPinSizeL color={COLORS.mainColor} />
                                    <h3 className={css(s.mapTriggerText)}>Показать карту</h3>
                                </>
                            )}
                    </div>

                    <MapWrapper>
                        {showMap && (
                            <Map
                                center={!!values.location && !!values.location.lat && values.location} // TODO api fix
                                mapContainerClassName={css(s.map)}
                                markers={locations} />
                        )}
                    </MapWrapper>
                </div>
            </div>
        </div>
    )
}

export const VacancyInfo = memo(MemoizedComponent)

const s = StyleSheet.create({
    box: {
        scrollMarginTop: 24,
        background: COLORS.white,
        border: `1px solid ${COLORS.smoothGray}`,
        boxShadow: '0px 2px 4px rgba(130, 130, 130, 0.05)',
        borderRadius: 4,
    },
    formName: {
        height: 'calc(3rem - 1px)',
        padding: '0 22px 0 24px',
        fontSize: 13,
        fontWeight: '600',
        color: COLORS.dark,
        borderBottom: `1px solid ${COLORS.lightGray}`,
        textTransform: 'uppercase',
        ':nth-child(1n) > :last-child': {
            ':nth-child(1n) > *': {
                ':hover': {
                    ':nth-child(1n) > *': {
                        stroke: COLORS.skyblue,
                    },
                },
            },
        },
    },
    inpGr: {
        padding: '16px 24px 24px',
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gridGap: 24,
    },
    lb: {
        fontSize: 12,
        fontWeight: '500',
        color: COLORS.midGray,
    },
    lab: {
        ':nth-child(1n) > span': {
            display: 'inline-flex',
        },
    },
    img: {
        borderRadius: 32,
    },
    defaultPhoto: {
        background: '#1B93C0',
        width: 32,
        height: 32,
        borderRadius: 32,
        fontWeight: '600',
        fontSize: 12,
        color: '#FFFFFF',
        border: '1px solid transparent',
        textTransform: 'uppercase',
    },
    txt: {
        fontWeight: '400',
        fontSize: 11,
        color: COLORS.midGray,
    },
    optLab: {
        fontWeight: '500',
        fontSize: 13,
        color: COLORS.dark,
    },
    selectCont: {
        gridColumn: '1 / 3',
    },
    editorCont: {
        zIndex: 0,
        gridColumn: '1 / 3',
    },
    mapTriggerText: {
        color: COLORS.mainColor,
        fontSize: 12,
        fontWeight: '500',
    },
    mapCont: {
        gridColumn: '1 / 3',
        ':nth-child(1n) > :first-child': {
            marginBottom: 12,
        },
    },
    map: {
        borderRadius: 4,
        overflow: 'hidden',
        border: `1px solid ${COLORS.lightGray}`,
    },
})
