import React from 'react'
import cn from 'classnames'
import { css, StyleSheet } from 'aphrodite'
import { isEmpty } from 'lodash'
import { Form } from 'formik'
import Button from '../../common/Button'
import { CheckSizeL, ChevronNext } from '../../svgs/Svgs'
import { COLORS } from '../../../utils/colors'
import { Templates } from './Templates'

export default function Header({
    errors,
    loading,
    navigate,
    isPreview,
    initialValues,
    setFieldValue,
    requiredFields,
    setTemplateInfo,
}) {
    return (
        <Form>
            <div className={cn('justify-between gap-7', css(s.hd_box))}>
                <div className="align-center gap-5">
                    <h3 className={css(s.title)}>
                        {!isPreview ? (initialValues.name || 'Создание вакансии') : 'Просмотр вакансии'}
                    </h3>

                    {!isPreview && isEmpty(initialValues) && (
                        <Templates setFieldValue={setFieldValue} setTemplateInfo={setTemplateInfo} />
                    )}
                </div>

                <div className="align-center gap-4">
                    <Button
                        type="reset"
                        className={css(s.cnl)}
                        onClick={() => navigate('/vacancies?view=board')}>

                        Отмена
                    </Button>

                    <Button
                        iconRight
                        type="submit"
                        strokeWidth={4}
                        loaderWidth={20}
                        loading={loading}
                        loaderColor={COLORS.white}
                        icon={isPreview ? <CheckSizeL /> : <ChevronNext color={COLORS.white} />}
                        className={cn(css(s.btn), {
                            [css(s.sbm)]: isEmpty(errors) && Object.values(requiredFields).every((e) => e !== ''),
                        })}>
                        {isPreview ? isEmpty(initialValues) ? 'Создать' : 'Сохранить' : 'Далее'}
                    </Button>
                </div>
            </div>
        </Form>
    )
}

const s = StyleSheet.create({
    hd_box: {
        backgroundColor: '#fff',
        padding: '14px 32px',
    },
    title: {
        fontSize: 24,
        fontWeight: '600',
        color: COLORS.dark,
        outline: 'none',
        border: 'none',
        flex: 1,
    },
    cnl: {
        padding: '11px 16px',
        color: COLORS.gray,
        fontSize: 14,
        fontWeight: '500',
        backgroundColor: 'transparent',
        border: `1px solid ${COLORS.gray}`,
        borderRadius: 4,
        ':hover': {
            background: COLORS.lotion,
        },
    },
    btn: {
        padding: '10px 16px',
        color: COLORS.white,
        fontSize: 14,
        height: 40,
        boxSizing: 'border-box',
        fontWeight: '500',
        borderRadius: 4,
        background: COLORS.gray,
        cursor: 'not-allowed',
        boxShadow: '0px 4px 12px rgba(44, 67, 50, 0.1), inset 0px -1px 0px rgba(0, 0, 0, 0.25), inset 0px 1px 0px rgba(255, 255, 255, 0.1)', // eslint-disable-line max-len
    },
    sbm: {
        cursor: 'pointer',
        boxShadow: '0px 4px 12px rgba(44, 67, 50, 0.1), inset 0px -1px 0px rgba(0, 0, 0, 0.25), inset 0px 1px 0px rgba(255, 255, 255, 0.1)', // eslint-disable-line max-len
        background: `linear-gradient(180deg, ${COLORS.mainColor} 0%, ${COLORS.lightMainColor} 100%)`,
        ':hover': {
            background: `linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), linear-gradient(180deg, ${COLORS.mainColor} 0%, ${COLORS.lightMainColor} 100%)`, // eslint-disable-line max-len
            boxShadow: '0px 2px 4px rgba(130, 130, 130, 0.2), inset 0px -1px 0px rgba(0, 0, 0, 0.25), inset 0px 1px 0px rgba(255, 255, 255, 0.1)', // eslint-disable-line max-len
        },
    },
})
