import React from 'react'
import cn from 'classnames'
import { css, StyleSheet } from 'aphrodite'
import moment from 'moment'
import { isEmpty } from 'lodash'
import { COLORS, tagStyleSheet } from '../../../utils/colors'
import { format } from '../../../utils/number'
import { strCutter } from '../../../utils/string'
// import XLSX from '../../../static/svgs/excel.svg'

export default function VacancyInfoHeader({ item }) {
    return (
        <div className={cn('full-width justify-between', css(s.cont))}>
            <div className="align-center flex-1">
                <div className={css(s.nameCont)}>
                    <h3 className={css(s.name)}>
                        {strCutter(item.name, 150)}
                        <span> • {strCutter(item.departmentName, 50)}</span>
                    </h3>
                </div>

                {!isEmpty(item.tags) && (
                    <div className="align-center gap-2">
                        {item.tags.slice(0, 4).map(({ id, name, color }) => (
                            <div key={id} className={cn('align-center', css(s.tag), tagStyleSheet(color))}>
                                {name}
                            </div>
                        ))}

                        {item.tags.length > 4 ? (
                            <span className={cn('align-center', css(s.tag), tagStyleSheet(COLORS.midGray))}>
                                + {item.tags.length - 4}
                            </span>
                        ) : null}
                    </div>
                )}

                {(item.deadline || item.startedAt) && (
                    <div className="is-flex direction-column">
                        <h3 className={css(s.title)}>Дата начала и окончания</h3>

                        <h3 className={css(s.value)}>
                            {item.startedAt
                                ? moment(item.startedAt).format('DD MMMM YYYY')
                                : <div className="is-inline-block">&#8212;&#8212;&#8212;</div>}

                            {' - '}

                            {item.deadline
                                ? moment(item.deadline).format('DD MMMM YYYY')
                                : <div className="is-inline-block">&#8212;&#8212;&#8212;</div>}
                        </h3>
                    </div>
                )}

                {(item.salaryMin || item.salaryMax) && (
                    <div className="is-flex direction-column">
                        <h3 className={css(s.title)}>Зарплатная вилка</h3>

                        <h3 className={css(s.value)}>
                            {item.salaryMin
                                ? format(item.salaryMin)
                                : <div className="is-inline-block">&#8212;&#8212;&#8212;</div>}

                            {' - '}

                            {item.salaryMax
                                ? format(item.salaryMax)
                                : <div className="is-inline-block">&#8212;&#8212;&#8212;</div>}

                            {item.currency || ' UZS'}
                        </h3>
                    </div>
                )}
            </div>

            {/* <div className="align-center gap-3"> */}
            {/*    <Button className={css(s.fileBtn)}> */}
            {/*        <FilePDF /> */}
            {/*    </Button> */}

            {/*    <Button className={css(s.fileBtn)}> */}
            {/*        <img src={XLSX} alt="#xlsx" /> */}
            {/*    </Button> */}
            {/* </div> */}
        </div>
    )
}

const s = StyleSheet.create({
    cont: {
        padding: '0 16px',
        height: 40,
        borderRadius: '2px 2px 0 0',
        border: '1px solid #EBEBEB',
        boxSizing: 'border-box',
        borderBottom: `1px solid ${COLORS.lightGray}`,
        background: '#EAF4F0',
        ':nth-child(1n) > :first-child': {
            gap: 32,
        },
    },
    name: {
        color: COLORS.dark,
        fontSize: 15,
        fontWeight: '600',
        ':nth-child(1n) > span': {
            color: COLORS.midGray,
            fontSize: 12,
            fontWeight: '500',
        },
    },
    nameCont: {
        maxWidth: '50%',
    },
    tag: {
        height: 24,
        boxSizing: 'border-box',
        padding: '0 6px',
        borderRadius: 4,
        fontSize: 11,
        fontWeight: '500',
    },
    title: {
        color: COLORS.midGray,
        fontSize: 9,
        fontWeight: '500',
    },
    value: {
        color: COLORS.dark,
        fontSize: 11,
        fontWeight: '500',
    },
    fileBtn: {
        border: 'none',
        padding: 0,
    },
})
