import React, { useCallback, useState } from 'react'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import { debounce, range } from 'lodash'
import { ChevronDownS, Clear, MagnifierSizeM } from '../svgs/Svgs'
import Checkbox from '../common/Checkbox'
import { Popup } from '../Popup'
import { COLORS } from '../../utils/colors'
import { compareArrays } from '../../utils/array'

function Dropdown({ title, list, values, setValues }) {
    const onChecked = (value) => {
        if (value === 0) {
            if (values.includes(value)) {
                setValues([])
            } else {
                setValues(range(0, 5))
            }
        } else if (values.includes(value)) {
            setValues(values.filter((f) => f !== value && f !== 0))
        } else {
            const newValue = [...values, value]
            setValues(compareArrays(newValue, range(1, 5)) ? [...newValue, 0] : newValue)
        }
    }

    return (
        <Popup
            position="left"
            popupClassName={css(s.popup)}
            trigger={(
                <div className="align-center gap-2 pointer">
                    <h3>{title}</h3>
                    <ChevronDownS color={COLORS.midGray} />
                </div>
            )}>
            <div className={css(s.dropdown)}>
                <ul className="no_dot_list">
                    {list.map((e) => (
                        <li
                            key={e.value}
                            className={css(s.txt, values.includes(e.value) && s.actTxt)}
                            onClick={() => onChecked(e.value)}>
                            <Checkbox
                                setChecked={() => {}}
                                className={values.includes(e.value) ? css(s.chk) : css(s.chb)}
                                checked={values.includes(e.value)} />

                            {e.label}
                        </li>
                    ))}
                </ul>
            </div>
        </Popup>
    )
}

function Input({ name, placeholder, setKeyword }) {
    const [value, setValue] = useState('')

    const sendRequest = useCallback(
        debounce((val) => {
            setKeyword(val)
        }, 500),
        [],
    )

    const handleInputChange = useCallback(
        (event) => {
            const newValue = event.target.value
            setValue(newValue)
            sendRequest(newValue)
        },
        [sendRequest],
    )

    return (
        <div
            id={name}
            onClick={() => {
                document.querySelector(`#${name}`).classList.add(css(s.actSrBtn))
                document.querySelector(`#${name} input`).focus()
            }}>
            <MagnifierSizeM color={COLORS.mainColor} />

            <input
                id="srcInp"
                type="text"
                autoComplete="off"
                name={name}
                value={value}
                className={css(s.inp)}
                placeholder={placeholder}
                onChange={handleInputChange}
                onBlur={() => (
                    value.length === 0 ? document.querySelector(`#${name}`).classList.remove(css(s.actSrBtn)) : {}
                )}
            />

            {value.length > 0 && (
                <span className="flex-cent pointer">
                    <Clear onClick={() => {
                        setValue('')
                        setKeyword('')
                    }} />
                </span>
            )}
        </div>
    )
}

export default function EmployeesTableHeader({ headerColumns }) {
    return (
        <thead className={cn(css(s.table_head))}>
            <tr>
                {headerColumns.map((item) => (item.popup ? (
                    <th key={item.id} className={css(s.item, columnWidth(item.width))}>
                        <Dropdown
                            values={item.value}
                            title={item.name}
                            setValues={item.setValue}
                            list={item.popup} />
                    </th>

                ) : item.isSearchable ? (
                    <th key={item.id} className={cn(css(s.item, s.srBtn, columnWidth(item.width)))}>
                        <Input
                            name={item.name}
                            setKeyword={item.setValue}
                            placeholder={item.placeholder}
                        />
                    </th>
                ) : (
                    <th key={item.id} className={css(s.item, columnWidth(item.width))}>
                        {item.name}
                    </th>
                )))}
            </tr>
        </thead>
    )
}

const s = StyleSheet.create({
    table_head: {
        color: COLORS.midGray,
        fontSize: 13,
        textTransform: 'uppercase',
        ':nth-child(1n) > :first-child': {
            background: '#EAF4F0',
            ':nth-child(1n) > *': {
                fontWeight: '500',
                paddingTop: 7,
                paddingBottom: 7,
            },
            ':nth-child(1n) > :first-child': {
                borderRadius: '4px 0 0 0',
            },
            ':nth-child(1n) > :last-child': {
                borderRadius: '0 4px 0 0',
                paddingRight: '1.5rem',
            },
        },
    },
    inp: {
        fontSize: 13,
        fontWeight: '400',
        color: COLORS.midGray,
        outline: 'none',
        border: 'none',
        width: 'calc(100% - 46px)',
        backgroundColor: 'transparent',
        '::placeholder': {
            color: COLORS.midGray,
            fontSize: 13,
            fontWeight: '400',
        },
    },
    srBtn: {
        ':nth-child(1n) > :first-child': {
            display: 'flex',
            alignItems: 'center',
            gap: 8,
            // maxWidth: '30.25rem', // TODO make max-width
            height: '1.75rem',
            padding: '0 8px',
            border: '1px solid rgba(0, 0, 0, 0.06)',
            borderRadius: 4,
            ':nth-child(1n) > :last-child': {
                fontSize: 13,
                fontWeight: '400',
                color: COLORS.midGray,
                outline: 'none',
                border: 'none',
                backgroundColor: 'transparent',
            },
        },

    },
    chb: {
        border: '1px solid #898989 !important',
    },
    chk: {
        background: COLORS.white,
        border: `1px solid ${COLORS.mainColor}`,
    },
    actSrBtn: {
        backgroundColor: '#FFF',
        borderColor: COLORS.lightGray,
    },
    txt: {
        fontWeight: '500',
        fontSize: 12,
        color: 'rgba(255, 255, 255, 0.8)',
    },
    actTxt: {
        color: COLORS.white,
    },
    dropdown: {
        maxHeight: '11.25rem',
        overflowY: 'auto',
        fontWeight: '500',
        fontSize: 12,
        textTransform: 'none',
        color: 'rgba(255, 255, 255, 0.8)',
        background: 'rgba(65, 70, 68, 0.9)',
        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.25)',
        backdropFilter: 'blur(8px)',
        borderRadius: 8,
        padding: '10px 12px',
        ':nth-child(1n) > ul': {
            width: '10rem',
            gap: 8,
            display: 'flex',
            flexDirection: 'column',
            ':nth-child(1n) > *': {
                height: '1rem',
                flex: 1,
                display: 'flex',
                alignItems: 'center',
                gap: '0.75rem',
                cursor: 'pointer',
            },
        },
    },
    item: {
        ':first-of-type': {
            paddingRight: '3.375rem',
            paddingLeft: '1.5rem',
        },
        ':last-of-type': {
            textAlign: 'right',
        },
    },
    popup: {
        zIndex: 999,
    },
})

const columnWidth = (width) => StyleSheet.create({
    main: { width },
}).main
