import React, { useContext } from 'react'
import { Context } from '../contexts/GlobalContext'

export function useSidebar(component, onClose = null) {
    const { setSidebar, closeSidebar } = useContext(Context)

    function showSidebar(props) {
        const content = React.isValidElement(component) ? component : component(props)
        setSidebar({ content, onClose })
    }

    function hideSidebar() {
        if (typeof onClose === 'function') {
            onClose()
        }

        closeSidebar()
    }

    return [showSidebar, hideSidebar]
}
