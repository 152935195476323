import React from 'react'
import { css, StyleSheet } from 'aphrodite'
import { Form, Formik } from 'formik'
import cn from 'classnames'
import { isEmpty } from 'lodash'
import { COLORS } from '../../../utils/colors'
import { MainInformation } from './MainInformation'
import Button from '../../common/Button'
import { ChevronNextSizeL } from '../../svgs/Svgs'
import { usePostRequest } from '../../../hooks/request'
import { CANDIDATE_MEETUP } from '../../../urls'
import { useMessage } from '../../../hooks/message'
import ContactInformation from '../offer/ContactInformation'
import { deepCopy } from '../../../utils/object'

export function Meetup({
    id,
    name,
    after,
    onClose,
    onSuccess,
    setColumns,
    sourceColumnIndex,
    cardIndex = 0,
    destinationIndex = 0,
}) {
    const meetup = usePostRequest({ url: CANDIDATE_MEETUP })
    const [showMessage] = useMessage()

    const onSubmit = async (data) => {
        const { success, error } = await meetup.request({ data: {
            ...data,
            after,
            candidateId: id,
            phone: `+998${data.phone}`,
            otherParticipants: data.otherParticipants.map((e) => e.value),
        } })

        if (success) {
            if (typeof setColumns === 'function') {
                setColumns((prevColumns) => {
                    let updatedColumns = deepCopy(prevColumns)

                    const movedCard = updatedColumns[sourceColumnIndex].cards.splice(cardIndex, 1)[0]

                    movedCard.params = {
                        status: 1,
                        salary: data.salary,
                        date: data.date,
                        time: data.time,
                        location: data.location,
                        format: data.format.value,
                    }

                    updatedColumns[2].cards.splice(destinationIndex, 0, movedCard)

                    updatedColumns = {
                        ...updatedColumns,
                        [sourceColumnIndex]: {
                            ...updatedColumns[sourceColumnIndex],
                            count: updatedColumns[sourceColumnIndex].count - 1,
                        },
                        2: {
                            ...updatedColumns[2],
                            count: updatedColumns[2].count + 1,
                        },
                    }

                    return updatedColumns
                })
            }
            onSuccess()
            showMessage('Успешно!', 'success-msg')
            return
        }

        showMessage(error.data.errors[0].message || error.data.message, 'error-msg')
    }

    return (
        <Formik
            onSubmit={onSubmit}
            initialValues={{
                date: '',
                time: '',
                phone: '',
                email: '',
                format: '',
                location: '',
                otherParticipants: [],
                otherCandidates: '',
                description: '',
            }}>
            {({ errors, touched, handleChange, values }) => (
                <Form className={css(s.cont)}>
                    <div className={cn(css(s.hd), 'justify-between')}>
                        <h3>Собеседование с кандидатом {name}</h3>

                        <div className="align-center gap-4">
                            <Button onClick={onClose} type="reset" className={css(s.cnl)}>
                                Отмена
                            </Button>

                            <Button
                                iconRight
                                type="submit"
                                strokeWidth={4}
                                loaderWidth={24}
                                loading={meetup.loading}
                                loaderColor={COLORS.white}
                                icon={<ChevronNextSizeL />}
                                className={cn(css(s.btn), {
                                    [css(s.sbm)]: isEmpty(errors) && !isEmpty(touched),
                                })}>
                                Отправить
                            </Button>
                        </div>
                    </div>

                    <div className={css(s.form)}>
                        <MainInformation values={values} handleChange={handleChange} />

                        <div className="is-flex justify-center gap-7">
                            <ContactInformation handleChange={handleChange} />

                            <div style={{ flex: 1 }} />
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
    )
}

const s = StyleSheet.create({
    cont: {
        width: '69%',
        backgroundColor: COLORS.smoothGray,
        borderRadius: 4,
        paddingTop: 1,
        maxHeight: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    hd: {
        backgroundColor: COLORS.white,
        padding: '20px 24px',
        borderRadius: '4px 4px 0 0',
        margin: '0 1px',
        borderBottom: `1px solid ${COLORS.lightGray}`,
        ':nth-child(1n) > :first-child': {
            fontSize: 20,
            fontWeight: '600',
            color: COLORS.dark,
        },
    },
    form: {
        padding: '24px 18px 24px 24px',
        display: 'flex',
        flexDirection: 'column',
        gap: 24,
        scrollbarGutter: 'stable',
        overflowY: 'auto',
    },
    cnl: {
        padding: '11px 16px',
        color: COLORS.gray,
        fontSize: 14,
        fontWeight: '500',
        backgroundColor: 'transparent',
        border: `1px solid ${COLORS.gray}`,
        borderRadius: 4,
        ':hover': {
            background: COLORS.lotion,
        },
    },
    btn: {
        padding: '10px 16px',
        color: COLORS.white,
        fontSize: 14,
        height: 40,
        boxSizing: 'border-box',
        fontWeight: '500',
        borderRadius: 4,
        background: COLORS.gray,
        cursor: 'not-allowed',
        boxShadow: '0px 4px 12px rgba(44, 67, 50, 0.1), inset 0px -1px 0px rgba(0, 0, 0, 0.25), inset 0px 1px 0px rgba(255, 255, 255, 0.1)', // eslint-disable-line
    },
    sbm: {
        cursor: 'pointer',
        boxShadow: '0px 4px 12px rgba(44, 67, 50, 0.1), inset 0px -1px 0px rgba(0, 0, 0, 0.25), inset 0px 1px 0px rgba(255, 255, 255, 0.1)', // eslint-disable-line
        background: `linear-gradient(180deg, ${COLORS.mainColor} 0%, ${COLORS.lightMainColor} 100%)`,
        ':hover': {
            background: `linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), linear-gradient(180deg, ${COLORS.mainColor} 0%, ${COLORS.lightMainColor} 100%)`, // eslint-disable-line
            boxShadow: '0px 2px 4px rgba(130, 130, 130, 0.2), inset 0px -1px 0px rgba(0, 0, 0, 0.25), inset 0px 1px 0px rgba(255, 255, 255, 0.1)', // eslint-disable-line
        },
    },
})
