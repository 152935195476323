import React from 'react'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import { COLORS } from '../../../utils/colors'
import LoadingSpinner from '../../common/LoadingSpinner'
import Modal from '../../common/Modal'

export default function LoadActions({ showModal, setShowModal }) {
    return (
        <Modal
            open={showModal}
            onClose={() => setShowModal(false)}
            closeOnDocumentClick
            closeOnEscape>
            <div className={cn('flex-cent', css(s.wrap))}>
                <LoadingSpinner width={64} />
            </div>
        </Modal>
    )
}

const s = StyleSheet.create({
    wrap: {
        background: COLORS.white,
        padding: '20px 24px',
        borderRadius: 4,
        gap: 20,
        width: '37.25rem',
    },
})
