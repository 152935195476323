import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import LocationForm from './LocationForm'
import { useMessage } from '../hooks/message'
import { useLoad, usePutRequest } from '../hooks/request'
import { LOCATION_DETAIL, LOCATION_UPDATE } from '../urls'

export default function LocationUpdate() {
    const navigate = useNavigate()
    const [showMessage] = useMessage()
    const { id } = useParams()
    const loadInfo = useLoad({ url: LOCATION_DETAIL.replace('{id}', id) })
    const update = usePutRequest({ url: LOCATION_UPDATE.replace('{id}', id) })

    const info = loadInfo.response ? loadInfo.response.data : []

    const initialValues = {
        ...info,
        latitude: parseFloat(info.latitude),
        longitude: parseFloat(info.longitude),
    }

    const onSubmit = async (data) => {
        const { success, error } = await update.request({ data: {
            ...data,
            latitude: data.latitude.toString(),
            longitude: data.longitude.toString(),
        } })

        if (success) {
            showMessage('Успешно!', 'success-msg')
            navigate(-1)
            return
        }

        showMessage(error.data.errors[0].message || error.data.message, 'error-msg')
    }

    return (
        <LocationForm
            loading={update.loading}
            navigate={navigate}
            onSubmit={onSubmit}
            initialValues={initialValues}
            title="Редактирование локации" />
    )
}
