import React from 'react'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import moment from 'moment'
import Highlighter from 'react-highlight-words'
import { ChevronNext, InfoSizeM, ThreeDotsMenu, Repeat } from '../svgs/Svgs'
import { Popup } from '../Popup'
import { LoadingSkeleton } from '../common/LoadingSkeleton'
import { COLORS } from '../../utils/colors'
import { cp } from '../../utils/checkPermission'

function Skeleton() {
    return (
        <tr className={css(s.loadItem)}>
            <td className={css(s.colItem)}>
                <div className={cn('align-start direction-column gap-1')}>
                    <LoadingSkeleton height={15} width="13.5rem" />

                    <LoadingSkeleton height={13} width="12rem" />
                </div>
            </td>

            <td><LoadingSkeleton height={20} width="50%" /></td>

            <td><LoadingSkeleton height={20} width="50%" /></td>

            <td><LoadingSkeleton height={20} width="50%" /></td>

            <td><LoadingSkeleton height={20} width="50%" /></td>

            <td className={css(s.colItem)}>
                <div className="justify-end">
                    <div className={css(s.menuItem)}>
                        <ThreeDotsMenu className="pointer" />
                    </div>
                </div>
            </td>
        </tr>
    )
}

export default function EmployeeArchiveItem({ item, keyword, loading, onClick, onUnArchive }) {
    const unArchiveItem = cp('employees.unarchive')
        ? [{ id: 2, value: 'Восстановить', icon: Repeat, onClick: onUnArchive }]
        : []

    const popupItems = [
        { id: 1, value: 'О сотруднике', icon: InfoSizeM, onClick },
        ...unArchiveItem,
    ]

    const formattedDate = (name) => moment(name).format('D MMMM YYYY')

    return !loading ? (
        <tr onClick={onClick} className={css(s.item)}>
            <td className={css(s.colItem)}>
                <Highlighter
                    textToHighlight={`${item.firstName} ${item.lastName}`}
                    searchWords={[keyword]}
                    highlightClassName="highlight-bg"
                />

                <div title={`${item.department} • ${item.position}`} className="align-center gap-1">
                    <Highlighter
                        textToHighlight={item.department || ''}
                        searchWords={[keyword]}
                        highlightClassName="highlight-bg"
                    />

                    <span>•</span>

                    <Highlighter
                        textToHighlight={item.position || ''}
                        searchWords={[keyword]}
                        highlightClassName="highlight-bg"
                    />
                </div>
            </td>

            <td>
                {item.startedAt ? formattedDate(item.startedAt)
                    : <div style={{ paddingLeft: 15 }}>&#8212;&#8212;&#8212;</div>}
            </td>

            <td>
                {item.deletedAt ? formattedDate(item.deletedAt)
                    : <div style={{ paddingLeft: 15 }}>&#8212;&#8212;&#8212;</div>}
            </td>

            <td>
                {item.createdAt ? formattedDate(item.createdAt)
                    : <div style={{ paddingLeft: 15 }}>&#8212;&#8212;&#8212;</div>}
            </td>

            <td>{item.reasonDismiss || <div style={{ paddingLeft: 15 }}>&#8212;&#8212;&#8212;</div>}</td>

            <td className={css(s.colItem)}>
                <div className="justify-end">
                    <Popup
                        items={popupItems}
                        trigger={(
                            <div className={css(s.menuItem)}>
                                <ThreeDotsMenu className="pointer" />
                            </div>
                        )}
                        renderItem={(e) => (
                            <li
                                key={e.id}
                                onClick={(event) => {
                                    event.stopPropagation()
                                    e.onClick(item.id, `${item.firstName} ${item.lastName}`)
                                }}
                                className={cn('justify-between', css(s.cardSetting))}>
                                <div className="align-center gap-2">
                                    <e.icon color="#CCCDCD" /> {e.value}
                                </div>

                                {e.navigate ? <ChevronNext /> : null}
                            </li>
                        )}
                    />
                </div>
            </td>
        </tr>
    ) : <Skeleton />
}

const s = StyleSheet.create({
    status: {
        display: 'inline-flex',
        alignItems: 'center',
        borderRadius: 4,
        padding: '4.5px 8px',
        fontSize: 11,
        fontWeight: '500',
    },
    item: {
        height: '3.75rem',
        background: COLORS.white,
        color: '#181A19',
        fontSize: 13,
        fontWeight: '500',
        wordBreak: 'break-word',
        ':nth-child(1n) > :last-child': {
            paddingRight: '1rem',
            textAlign: 'right',
        },
    },
    cardSetting: {
        width: '11rem',
        ':nth-child(1n) > :first-child': {
            ':nth-child(1n) > :first-child': {
                ':nth-child(1n) > *': {
                    // stroke: '#CCCDCD',
                },
            },
        },
    },
    menuItem: {
        width: 20,
        height: 20,
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'center',
    },
    loadItem: {
        height: '3.75rem',
        background: '#FFFFFF',
        ':nth-child(1n) > :last-child': {
            paddingRight: '1rem',
            textAlign: 'right',
        },
    },
    colItem: {
        ':first-of-type': {
            paddingLeft: '1.5rem',
            ':nth-child(1n) > :last-child': {
                fontWeight: '400',
                fontSize: 11,
                color: '#6D7471',
            },
        },
    },
})
