import React, { memo } from 'react'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import { components } from 'react-select'
import Highlighter from 'react-highlight-words'
import { CheckUpList, ChevronDown, Time } from '../../svgs/Svgs'
import SelectForm from '../../common/SelectForm'
import FormInput from '../../common/FormInput'
import { COLORS } from '../../../utils/colors'
import { required } from '../../../utils/validators'
import { useLoad } from '../../../hooks/request'
import { EMPLOYEE_LIST } from '../../../urls'
import InputForm from '../../common/InputForm'
import Checkbox from '../../common/Checkbox'
import InputDate from '../../common/InputDate'

function MemoizedComponent({ values, handleChange }) {
    const loadEmployees = useLoad({ url: EMPLOYEE_LIST, params: { 'per-page': 100 } })
    // const loadCandidates = useLoad({ url: CANDIDATE_TABLE_LIST.replace('{id}', id) })

    const employees = loadEmployees.response
        ? loadEmployees.response.data.filter((e) => e.userId).map((e) => (
            { value: e.userId, label: `${e.firstName} ${e.lastName}` }
        ))
        : []

    // const candidates = loadCandidates.response
    //     ? loadCandidates.response.data.map((e) => ({ value: e.id, label: e.fullname }))
    //     : []

    const options = [
        { value: 1, label: 'Онлайн' },
        { value: 2, label: 'Оффлайн' },
    ]

    const styles = {
        menuList: (baseStyles) => ({
            ...baseStyles,
            maxHeight: 200,
        }),
        multiValue: (baseStyles) => ({
            ...baseStyles,
            backgroundColor: 'transparent',
            margin: 0,
            padding: 0,
        }),
        multiValueLabel: (baseStyles) => ({
            ...baseStyles,
            padding: 0,
            paddingLeft: 0,
            fontWeight: '500',
            fontSize: 12,
            color: '#181A19',
            display: 'flex',
            gap: 4,
            ':nth-of-type(1n) > span': {
                color: '#386E56',
            },
        }),
    }

    const MultiValue = (props) => {
        const { index, children, getValue } = props

        return index === 0 ? (
            <components.MultiValue {...props}>
                {children.length > 17 ? `${children.slice(0, 17)}...` : children}

                {getValue().length > 1 ? (
                    <React.Fragment>
                        <span>•</span>

                        <h3>+ {getValue().length - 1}</h3>
                    </React.Fragment>
                ) : null}
            </components.MultiValue>
        ) : null
    }

    const Option = (props) => {
        const { children, isSelected, selectProps } = props

        return (
            <components.Option {...props}>
                <div className="align-center gap-2">
                    <Checkbox checked={isSelected} />

                    <div className="align-center gap-2">
                        <Highlighter
                            textToHighlight={children}
                            activeClassName="highlight"
                            searchWords={[selectProps.inputValue.trim()]}
                            highlightClassName={css(s.optLab)}
                        />
                    </div>
                </div>
            </components.Option>
        )
    }

    const IndicatorsContainer = (props) => (
        <components.IndicatorsContainer {...props}>
            <span className={cn('is-flex pointer')} style={{ paddingRight: 10 }}>
                <ChevronDown />
            </span>
        </components.IndicatorsContainer>
    )

    return (
        <div className={css(s.box)}>
            <div className={cn('justify-between', css(s.formName))}>
                <div className="align-center gap-2">
                    <CheckUpList />

                    <h3>Основная информация</h3>
                </div>
            </div>

            <div className={css(s.inpGr)}>
                <InputDate validate={required} name="date" label="Дата собеседования" />

                <FormInput
                    name="time"
                    type="time"
                    errorWithMessage={false}
                    validate={required}
                    icon={<Time />}
                    placeholder="Выберите время"
                    className={css(s.addInp)}
                    label="Время собеседования"
                    fieldClassName={css(s.fld)}
                    labelClassName={css(s.addInpLab)}
                    iconStyles={{ top: 34 }} />

                <SelectForm
                    errorWithMessage={false}
                    validate={required}
                    labelContClassName={css(s.lab)}
                    placeholderStyles={{ color: COLORS.gray, fontFamily: 'Roboto, sans-serif' }}
                    labelClassName={css(s.lb)}
                    valueContainerStyles={{ padding: '11.5px 16px' }}
                    indicatorStyle={{ paddingRight: 10 }}
                    label="Формат встречи"
                    placeholder="Выберите формат"
                    name="format"
                    options={options} />

                <FormInput
                    errorWithMessage={false}
                    validate={required}
                    name="location"
                    placeholder="Введите адрес"
                    className={css(s.addInp)}
                    label="Место проведения"
                    fieldClassName={css(s.fld)}
                    labelClassName={css(s.addInpLab)} />

                <SelectForm
                    isMulti
                    styles={styles}
                    options={employees}
                    label="Другие участники"
                    labelClassName={css(s.lb)}
                    name="otherParticipants"
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    labelContClassName={css(s.lab)}
                    placeholder="Выберите сотрудников"
                    valueContainerStyles={{ padding: '11.5px 16px' }}
                    customComponents={{ MultiValue, Option, MultiValueRemove: () => null, IndicatorsContainer }}
                    placeholderStyles={{ color: COLORS.gray, fontFamily: 'Roboto, sans-serif' }}
                />

                {/* <SelectForm */}
                {/*     isMulti */}
                {/*     labelContClassName={css(s.lab)} */}
                {/*     placeholderStyles={{ color: COLORS.gray, fontFamily: 'Roboto, sans-serif' }} */}
                {/*     labelClassName={css(s.lb)} */}
                {/*     styles={styles} */}
                {/*     closeMenuOnSelect={false} */}
                {/*     hideSelectedOptions={false} */}
                {/*     valueContainerStyles={{ padding: '11.5px 16px' }} */}
                {/*     label="Другие кандидаты (опционально)" */}
                {/*     placeholder="Выберите кандидатов" */}
                {/*     name="otherCandidates" */}
                {/*     customComponents={{ MultiValue, Option, MultiValueRemove: () => null, IndicatorsContainer }} */}
                {/*     options={candidates} /> */}

                <div className={css(s.textarea)}>
                    <InputForm
                        name="description"
                        component="textarea"
                        placeholder="Введите"
                        // contClassname={css(s.textarea)}
                        label="Сообщение кандидату (опционально)"
                        labelClassName={css(s.lbAboutEvent)}
                        className={css(s.inp)}
                        value={values.description}
                        onChange={handleChange}
                        maxLength="255"
                    />

                    <span
                        className={cn(css(s.charCount),
                            { [css(s.reachedLimit)]: values.description.length >= 255 })}
                    >
                        Макс. количество: {values.description.length} / 255
                    </span>
                </div>
            </div>
        </div>
    )
}

export const MainInformation = memo(MemoizedComponent)

const s = StyleSheet.create({
    box: {
        background: COLORS.white,
        border: `1px solid ${COLORS.smoothGray}`,
        boxShadow: '0px 2px 4px rgba(130, 130, 130, 0.05)',
        borderRadius: 4,
    },
    formName: {
        height: 'calc(3rem - 1px)',
        padding: '0 22px 0 24px',
        fontSize: 13,
        fontWeight: '600',
        color: '#181A19',
        borderBottom: `1px solid ${COLORS.lightGray}`,
        textTransform: 'uppercase',
    },
    inpGr: {
        padding: '16px 24px 24px',
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gridGap: 24,
        alignItems: 'end',
    },
    lb: {
        fontSize: 12,
        fontWeight: '500',
        color: COLORS.midGray,
    },
    addInp: {
        position: 'relative',
        border: `1px solid ${COLORS.lightGray}`,
        borderRadius: 4,
        fontSize: 14,
        fontWeight: '500',
        color: '#181A19',
    },
    addInpLab: {
        fontSize: 12,
        fontWeight: '500',
        color: COLORS.midGray,
        marginBottom: 8,
    },
    fld: {
        position: 'relative',
        // padding
    },
    lab: {
        ':nth-child(1n) > span': {
            display: 'inline-flex',
        },
    },
    inp: {
        width: '100%',
        border: `1px solid ${COLORS.lightGray}`,
        borderRadius: 4,
        padding: '14px 16px',
        height: 'calc(8.5rem - 25px)',
        ':first-of-type': {
            paddingTop: 15,
        },
    },
    charCount: {
        fontSize: 12,
        fontWeight: '400',
        color: COLORS.gray,
        marginTop: 10,
    },
    reachedLimit: {
        color: COLORS.lightRed,
    },
    lbAboutEvent: {
        paddingBottom: 8,
    },
    textarea: {
        gridColumn: '1 / 3',
    },
    img: {
        width: 32,
        height: 32,
        borderRadius: '50%',
        border: '1px solid rgba(0, 0, 0, 0.2)',
    },
})
