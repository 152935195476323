import React, { useContext, useEffect, useRef } from 'react'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import { Briefcase, ChevronNext, EditPencil, Poses, ThreeDotsMenu, Trash } from '../../svgs/Svgs'
import { useDeleteRequest, useLoad } from '../../../hooks/request'
import { POSITIONS_DELETE, POSITIONS_LIST } from '../../../urls'
import { useSidebar } from '../../../hooks/sidebar'
import { useMessage } from '../../../hooks/message'
import PositionList from './PositionList'
import { Popup } from '../../Popup'
import { COLORS } from '../../../utils/colors'
import PositionCreate from './PositionCreate'
import PositionUpdate from './PositionUpdate'
import { Context } from '../../../contexts/GlobalContext'
import { cp } from '../../../utils/checkPermission'

export default function Positions() {
    const [showMessage] = useMessage()
    const ref = useRef({})
    const deletePos = useDeleteRequest()
    const { positions, setPositions } = useContext(Context)
    const loadPoses = useLoad({ url: POSITIONS_LIST })
    const meta = loadPoses.response ? loadPoses.response.meta : []

    useEffect(() => {
        if (loadPoses.response && loadPoses.response.data) setPositions(loadPoses.response.data)
    }, [loadPoses])

    const onDelete = async (id) => {
        const { success, error } = await deletePos.request({
            url: POSITIONS_DELETE.replace('{id}', id),
        })

        if (success) {
            loadPoses.request()
            showMessage('Успешно!', 'success-msg')
            return
        }

        showMessage(error.data.message || error.data.errors[0].message, 'error-msg')
    }

    const update = (id, name) => {
        ref.current = { id, name }
        showUpdateForm()
    }

    const popupItems = [
        // { id: 1, value: 'Добавить сотрудника', icon: UserPlus, navigate: false, onClick: () => {} },
        { id: 2, value: 'Изменить', icon: EditPencil, navigate: true, onClick: (id, name) => update(id, name) },
        { id: 3, value: 'Удалить', icon: Trash, navigate: false, onClick: (id) => onDelete(id) },
    ]

    const [showCreateForm, hideCreateForm] = useSidebar(
        <PositionCreate
            onClose={() => hideCreateForm()}
            onSuccess={() => { loadPoses.request(); hideCreateForm() }}
        />,
    )

    const [showUpdateForm, hideUpdateForm] = useSidebar(
        <PositionUpdate
            innerRef={ref}
            onClose={() => hideUpdateForm()}
            onSuccess={() => { loadPoses.request(); hideUpdateForm() }} />,
    )

    const [showListSidebar, hideListSidebar] = useSidebar(
        <PositionList
            popupItems={popupItems}
            onClose={() => hideListSidebar()} />,
    )

    return (
        <div className={css(s.form)}>
            <div className={cn('justify-between', css(s.formName))}>
                <div className="align-center">
                    <Poses />

                    <h3>Должности</h3>
                </div>

                {cp('settings.company.edit_position') && (
                    <h3 onClick={() => showCreateForm()} className={css(s.add)}>Добавить</h3>
                )}
            </div>

            <div className={css(s.wrap)}>
                <ul className={css(s.listH)}>
                    {positions.slice(0, 5).map((pos) => (
                        <li key={pos.id} className="no_dot_list justify-between">
                            <div className="flex-cent">
                                <div className={css(s.icon)}>
                                    <Briefcase />
                                </div>

                                <div className={cn('is-flex flex-1 direction-column', css(s.changeTxt))}>
                                    <h3 className={css(s.txt)}>{pos.name}</h3>

                                    <span className={css(s.pos)}>{pos.employeesCount} сотрудников</span>
                                </div>
                            </div>

                            {cp('settings.company.edit_department') && (
                                <Popup
                                    items={popupItems}
                                    renderItem={(item) => (
                                        <li
                                            key={item.id}
                                            onClick={() => item.onClick(pos.id, pos.name)}
                                            className={cn('justify-between', css(s.cardSetting))}>
                                            <div className="align-center">
                                                <item.icon /> {item.value}
                                            </div>

                                            {item.navigate ? <ChevronNext /> : null}
                                        </li>
                                    )}
                                    trigger={(
                                        <span>
                                            <ThreeDotsMenu className="pointer" />
                                        </span>
                                    )} />
                            )}
                        </li>
                    ))}
                </ul>
            </div>

            {meta.totalCount > 5 && (
                <div onClick={() => showListSidebar()} className={css(s.foot)}>
                    + Ещё {meta.totalCount - 5}
                </div>
            )}
        </div>
    )
}

const s = StyleSheet.create({
    form: {
        backgroundColor: '#fff',
        borderRadius: 4,
    },
    formName: {
        height: 'calc(3rem - 1px)',
        padding: '0 24px',
        fontSize: 13,
        fontWeight: '600',
        color: COLORS.dark,
        borderBottom: `1px solid ${COLORS.lightGray}`,
        textTransform: 'uppercase',
        ':nth-child(1n) > :first-child': {
            ':nth-child(1n) > :first-child': {
                marginRight: 8,
            },
        },
    },
    wrap: {
        padding: '15px 24px',
    },
    pos: {
        fontSize: 11,
        fontWeight: '400',
        color: COLORS.midGray,
    },
    changeTxt: {
        width: 'calc(100% - 36px)',
    },
    txt: {
        fontSize: 13,
        fontWeight: '500',
        color: COLORS.dark,
        lineHeight: 1,
        marginBottom: 3,
    },
    listH: {
        display: 'flex',
        flexDirection: 'column',
        gap: 16,
        ':nth-child(1n) > *': {
            height: 40,
        },
    },
    icon: {
        width: 34,
        height: 34,
        borderRadius: 4,
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: COLORS.mintCream,
        border: '1px solid #D3ECE3',
        marginRight: 12,
        ':nth-child(1n) > :first-child': {
            ':nth-child(1n) > *': {
                stroke: COLORS.mainColor,
            },
        },
    },
    foot: {
        borderTop: `1px solid ${COLORS.smoothGray}`,
        padding: '12px 0',
        textAlign: 'center',
        fontWeight: '500',
        fontSize: 11,
        textTransform: 'uppercase',
        color: COLORS.skyblue,
        cursor: 'pointer',
        ':hover': {
            // eslint-disable-next-line max-len
            background: `linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(0deg, ${COLORS.skyblue}, ${COLORS.skyblue})`,
            '-webkit-background-clip': 'text',
            '-webkit-text-fill-color': 'transparent',
        },
    },
    add: {
        cursor: 'pointer',
        fontWeight: '500',
        fontSize: 12,
        color: COLORS.skyblue,
        textTransform: 'capitalize',
        ':hover': {
            // eslint-disable-next-line max-len
            background: `linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(0deg, ${COLORS.skyblue}, ${COLORS.skyblue})`,
            '-webkit-background-clip': 'text',
            '-webkit-text-fill-color': 'transparent',
        },
    },
    cardSetting: {
        width: '11rem',
        ':nth-child(1n) > :first-child': {
            ':nth-child(1n) > :first-child': {
                marginRight: 8,
            },
        },
    },
})
