import React from 'react'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import uuid from 'react-uuid'
import { Rocket } from '../svgs/Svgs'
import { COLORS } from '../../utils/colors'
import { getFullDate } from '../../utils/date'

export default function ExperienceInfo({ experience }) {
    return (
        <div className="is-flex direction-column gap-2">
            <label className={cn('align-center gap-2', css(s.label))}><Rocket /> ОПыт работы</label>

            {experience.map((exp) => (
                <ul key={uuid()} className={cn('no_dot_list is-flex direction-column gap-3', css(s.list))}>
                    <li className={cn('align-center', css(s.info_item))}>
                        <div>Опыт работы</div>

                        <div>{getFullDate(exp.startDate, true)} - {getFullDate(exp.endDate, true)}</div>
                    </li>

                    <hr className={css(s.hr)} />

                    <li className={cn('align-center', css(s.info_item))}>
                        <div>Предыдущий работодатель</div>

                        <div>{exp.job || <div className={css(s.no_info)} />} </div>
                    </li>

                    <hr className={css(s.hr)} />

                    <li className={cn('align-center', css(s.info_item))}>
                        <div>Занимаемая должность</div>

                        <div>{exp.position || <div className={css(s.no_info)} /> }</div>
                    </li>

                    <hr className={css(s.hr)} />

                    <li className={cn('align-center', css(s.info_item))}>
                        <div>Обязанности и достижения</div>

                        <div>{exp.achievements || <div className={css(s.no_info)} />}</div>
                    </li>
                </ul>
            ))}
        </div>
    )
}

const s = StyleSheet.create({
    list: {
        background: COLORS.white,
        padding: 20,
        border: `1px solid ${COLORS.chineseWhite}`,
        boxShadow: '0px 2px 4px rgba(130, 130, 130, 0.05)',
        borderRadius: 4,
    },
    label: {
        fontWeight: '600',
        fontSize: 13,
        color: '#181A19',
        textTransform: 'uppercase',
    },
    hr: {
        border: `.5px solid ${COLORS.smoothGray}`,
        borderRadius: 12,
        margin: 0,
    },
    info_item: {
        ':nth-child(1n) > :first-child': {
            width: '37%',
            fontWeight: '400',
            fontSize: 13,
            color: COLORS.midGray,
        },
        ':nth-child(1n) > :last-child': {
            width: '63%',
            fontWeight: '500',
            fontSize: 13,
            color: '#181A19',
        },
    },
    no_info: {
        width: '20%',
        height: 1,
        background: '#181A19',
    },
})
