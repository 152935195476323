import React, { useContext } from 'react'
import { Navigate } from 'react-router-dom'
import { cp } from '../../utils/checkPermission'
import { Context } from '../../contexts/GlobalContext'
import LoadingSpinner from './LoadingSpinner'

export const ProtectedRoute = ({
    path,
    permission,
    redirectPath = '/auth/recruiter',
    protectedBy = localStorage.token,
    children,
}) => {
    const { user } = useContext(Context)
    const userStorage = JSON.parse(localStorage.getItem('user'))
    const hasCompany = userStorage && userStorage.companyId

    if (localStorage.token && user.loading) {
        return (
            <div style={{ paddingTop: '20rem' }} className="is-flex align-center justify-center">
                <LoadingSpinner width="10vw" />
            </div>
        )
    }

    if (protectedBy) {
        if (localStorage.token && !['/projects', '/project/add'].includes(path) && !hasCompany && user.checked) {
            return <Navigate to="/projects" replace />
        }

        if (permission && cp(permission) !== null && !cp(permission) && user.checked) {
            return <Navigate to="/dashboard" replace />
        }

        return children
    }

    return <Navigate to={redirectPath} replace />
}
