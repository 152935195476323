import React from 'react'
import { css, StyleSheet } from 'aphrodite'
import { components } from 'react-select'
import cn from 'classnames'
import Highlighter from 'react-highlight-words'
import { COLORS } from '../../utils/colors'
import SelectForm from '../common/SelectForm'
import Checkbox from '../common/Checkbox'
import { strCutter } from '../../utils/string'
import { ChevronDown } from '../svgs/Svgs'

export default function FilterSelectField({ name, options, fieldLabel, onMenuOpen, placeholder, multiValueText }) {
    const styles = {
        menu: (menuStyles) => ({
            ...menuStyles,
            width: '18rem',
            zIndex: 2,
            overflow: 'hidden',
        }),
        multiValue: (baseStyles) => ({
            ...baseStyles,
            backgroundColor: 'transparent',
            margin: 0,
            padding: 0,
        }),
        multiValueLabel: (baseStyles) => ({
            ...baseStyles,
            padding: 0,
            paddingLeft: 0,
            fontWeight: '500',
            fontSize: 12,
            color: '#181A19',
            display: 'flex',
            gap: 4,
            ':nth-of-type(1n) > span': {
                color: '#386E56',
            },
        }),
        option: (optStyles, { isSelected, isFocused }) => ({
            ...optStyles,
            backgroundColor: isSelected ? '#D6F2E5' : isFocused ? COLORS.smoothGray : 'transparent',
            color: isSelected ? COLORS.dark : COLORS.midGray,
            cursor: 'pointer',
            minHeight: 40,
            display: 'flex',
            alignItems: 'center',
            borderBottom: `1px solid ${COLORS.smoothGray}`,
            ':last-of-type': {
                borderBottom: 'none',
            },
            '&:hover': {
                backgroundColor: isSelected ? '#D6F2E5' : COLORS.smoothGray,
            },
        }),
        menuList: (optStyles) => ({
            ...optStyles,
            padding: 0,
        }),

    }

    const MultiValue = (props) => {
        const { getValue, index } = props

        return index === 0 ? (
            <components.MultiValue {...props}>
                <h3 className={css(s.selectValue)}>{multiValueText}: {getValue().length}</h3>
            </components.MultiValue>
        ) : null
    }

    const Option = (props) => {
        const { selectProps, isSelected, data } = props
        const { label, department, position } = data

        return (
            <components.Option {...props}>
                <div className={cn('align-center gap-5', css(s.option))}>
                    <Checkbox className={css(s.checkbox)} checked={isSelected} onClick={() => {}} />

                    <div className="is-flex direction-column align-start justify-center">
                        <Highlighter
                            title={label}
                            textToHighlight={strCutter(label, 30)}
                            activeClassName="highlight"
                            searchWords={[selectProps.inputValue.trim()]}
                            className={css(s.optLab)}
                        />

                        {(department || position) && (
                            <div className="align-center gap-1">
                                <h3 title={`${department} • ${position}`} className={css(s.txt)}>
                                    {strCutter(department, 20)}

                                    <span> • </span>

                                    {strCutter(position, 20)}
                                </h3>
                            </div>
                        )}
                    </div>
                </div>
            </components.Option>
        )
    }

    const IndicatorsContainer = (props) => (
        <components.IndicatorsContainer {...props}>
            <span className={cn('is-flex pointer')} style={{ paddingRight: 10 }}>
                <ChevronDown />
            </span>
        </components.IndicatorsContainer>
    )

    const valueContainerStyles = {
        gap: 4,
        fontSize: 12,
        cursor: 'text',
        fontWeight: 500,
        padding: '0 4px 0 16px',
        minHeight: '30px',
        color: COLORS.dark,
    }

    return (
        <SelectForm
            isMulti
            name={name}
            options={options}
            styles={styles}
            labelContClassName={css(s.label)}
            label={fieldLabel}
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            onMenuOpen={onMenuOpen}
            contClassname={css(s.select)}
            customComponents={{ Option, MultiValue, MultiValueRemove: () => null, IndicatorsContainer }}
            controlStyles={{ minHeight: 32 }}
            placeholder={placeholder}
            valueContainerStyles={valueContainerStyles}
            placeholderStyles={{ color: COLORS.gray, fontFamily: 'Roboto, sans-serif' }}
        />
    )
}

const s = StyleSheet.create({
    select: {
        width: '16rem',
    },
    label: {
        ':nth-child(1n) > :first-child': {
            paddingBottom: 3,
            ':nth-child(1n) > :first-child': {
                fontSize: 10,
            },
        },
    },
    optLab: {
        color: COLORS.dark,
        fontSize: 13,
        fontWeight: '500',
    },
    txt: {
        color: COLORS.midGray,
        fontSize: 11,
        fontWeight: '400',
    },
    selectValue: {
        color: COLORS.dark,
        fontSize: 12,
        fontWeight: 500,
    },
    checkbox: {
        backgroundColor: COLORS.white,
    },
})
