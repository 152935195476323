import { css, StyleSheet } from 'aphrodite'

export const COLORS = {
    mainColor: '#2EA97D',
    white: '#FFFFFF',
    dark: '#181A19',
    lightBlack: '#414644',
    gray: '#969F9B',
    lightGray: '#C9D2CE',
    smoothGray: '#EDEDEE',
    midGray: '#6D7471',
    lightRed: '#F33B5C',
    lightMainColor: '#138753',
    skyblue: '#0095F2',
    mintCream: '#FAFFFC',
    blue: '#1B93C0',
    green: '#386E56',
    chineseWhite: '#E0E0E0',
    lotion: '#FAFAFA',
    spanishGray: '#96A09B',
    saladColor: '#F2FAF6',
    pink: '#FFE1E7',
}

export const TAG_COLORS = [
    '#FF7474',
    '#FF8413',
    '#FFBA7A',
    '#FFD600',
    '#D0D500',
    '#15BC06',
    '#5AEA4E',
    '#2EA97D',
    '#33E3A4',
    '#12D6BE',
    '#00AFE7',
    '#0066C5',
    '#856EC6',
    '#933EFF',
    '#DF0086',
    '#DE0000',
    '#9F0000',
    '#181A19',
]

export const DASHBOARD_COLORS = ['#FFFFFF', '#FAFFFC', '#F6F6F6', '#EFEFEF', '#C9D2CE', '#96A09B', '#96A09B']

export const tagStyleSheet = (color) => css(StyleSheet.create({
    main: {
        color,
        border: `1px solid ${color + 33}`,
        background: `${color}1A`,
        ':hover': {
            border: `1px solid ${color + 80}`,
        },
        ':nth-child(1n) > :first-child': {
            ':nth-child(1n) > :first-child': {
                ':nth-child(1n) > *': {
                    stroke: color,
                },
            },
        },
    },
}).main)
