import React, { memo, useRef } from 'react'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import { useNavigate } from 'react-router-dom'
import { CheckSizeL, ChevronDownS, DefaultLocation, ExitSizeM, List, PlusRounded, SettingsLayout } from '../svgs/Svgs'
import { Popup } from '../Popup'
import { COLORS } from '../../utils/colors'
import { FilterSwitcher } from '../filter/FilterSwitcher'
import { useSidebar } from '../../hooks/sidebar'
import EmployeesSidebar from './EmployeesSidebar'
import { cp } from '../../utils/checkPermission'

function MemoizedHeader({ employeeFilter, setEmployeeFilter, count, listType, setListType }) {
    const popupRef = useRef()
    const navigate = useNavigate()
    const [showSettingsBar, hideSettingsBar] = useSidebar(
        <EmployeesSidebar onClose={() => hideSettingsBar()} />,
    )

    const popupItems = [
        { id: 1, label: 'Активные', icon: List },
        { id: 2, label: 'Архив сотрудников', icon: ExitSizeM },
    ]

    return (
        <div className={cn(css(s.hd), 'justify-between')}>
            <div className="align-center gap-5">
                <div className="align-center gap-3">
                    <div className="align-center gap-3">
                        <div className={cn('flex-cent', css(s.defaultCompanyLogo))}>
                            <DefaultLocation color={COLORS.white} />
                        </div>

                        <h3 className={css(s.projectInfo)}>
                            Сотрудники
                        </h3>
                    </div>

                    <span className={css(s.dot)}>•</span>

                    <h3 className={css(s.projectInfo)}>{count}</h3>
                </div>

                <Popup
                    ref={popupRef}
                    trigger={(
                        <div className={cn(css(s.dTrigger), 'flex-cent gap-1')}>
                            <h3>Показать: <span className={css(s.fil)}>{popupItems[listType - 1].label}</span></h3>

                            <ChevronDownS />
                        </div>
                    )}
                    items={popupItems}
                    renderItem={(e) => (
                        <li
                            key={e.id}
                            onClick={() => { setListType(e.id); popupRef.current.onClose() }}
                            className={cn(
                                'justify-between',
                                css(s.cardSetting),
                            )}>
                            <div className="align-center gap-2">
                                <e.icon className={listType !== e.id ? css(s.popupItemIcon) : css(s.slIcon)} />

                                <h3 className={cn(css(s.popupItemLabel), { [css(s.slLabel)]: listType === e.id })}>
                                    {e.label}
                                </h3>
                            </div>

                            {listType === e.id ? <CheckSizeL /> : null}
                        </li>
                    )}
                />
            </div>

            <div className={cn('align-center', css(s.panel))}>
                {cp('employees.create') && (
                    <span
                        onClick={() => navigate('/employees/create')}
                        className={cn('align-center gap-2', css(s.add))}>
                        <PlusRounded /> Добавить сотрудника
                    </span>
                )}

                <FilterSwitcher setFilter={setEmployeeFilter} filter={employeeFilter} />

                <span
                    onClick={() => showSettingsBar()}
                    className={css(s.panItem)}><SettingsLayout />
                </span>
            </div>
        </div>
    )
}

export const EmployeesHeader = memo(MemoizedHeader)

const s = StyleSheet.create({
    hd: {
        margin: '16px 0 14px',
    },
    title: {
        fontWeight: '600',
        fontSize: 24,
        color: '#181A19',
        ':nth-child(1n) > :first-child': {
            color: COLORS.green,
        },
        ':nth-child(1n) > :last-child': {
            color: COLORS.midGray,
            fontWeight: '500',
        },
    },
    panel: {
        ':nth-child(1n) > *': {
            marginRight: 16,
        },
        ':nth-child(1n) > :last-child': {
            marginRight: 0,
        },
    },
    panItem: {
        cursor: 'pointer',
        display: 'inline-flex',
        padding: 6,
        background: COLORS.white,
        borderRadius: 4,
        border: '1px solid',
        borderColor: COLORS.smoothGray,
        ':hover': {
            borderColor: COLORS.lightGray,
            boxShadow: '0px 2px 4px rgba(168, 168, 168, 0.15)',
        },
        ':last-of-type': {
            ':nth-child(1n) > :first-child': {
                ':nth-child(1n) > *': {
                    stroke: COLORS.midGray,
                },
            },

        },
    },
    fil: {
        color: '#181A19',
        fontWeight: '600',
        fontSize: 14,
    },
    dpl: {
        ':nth-child(1n) > *': {
            padding: 8,
            display: 'inline-flex',
            cursor: 'pointer',
        },
        ':nth-child(1n) > :first-child': {
            borderTopLeftRadius: 4,
            borderBottomLeftRadius: 4,
        },
        ':nth-child(1n) > :last-child': {
            borderTopRightRadius: 4,
            borderBottomRightRadius: 4,
        },
    },
    col: {
        background: '#fff',
        ':nth-child(1n) > :first-child': {
            ':nth-child(1n) > :first-child': {
                fill: '#92929D',
            },
        },
    },
    actCol: {
        background: COLORS.mainColor,
        ':nth-child(1n) > :first-child': {
            ':nth-child(1n) > :first-child': {
                fill: '#FFF',
            },
        },
    },
    add: {
        height: 32,
        padding: '0 12px',
        background: COLORS.blue,
        boxShadow: '0px 4px 12px rgba(44, 67, 50, 0.1), inset 0px -1px 0px rgba(0, 0, 0, 0.25), inset 0px 1px 0px rgba(255, 255, 255, 0.1)', // eslint-disable-line
        borderRadius: 4,
        color: COLORS.white,
        fontSize: 13,
        fontWeight: '500',
        cursor: 'pointer',
    },
    dTrigger: {
        border: `1px solid ${COLORS.lightGray}`,
        borderRadius: 4,
        height: 32,
        padding: '0 12px',
        cursor: 'pointer',
        background: COLORS.white,
        color: COLORS.midGray,
        fontSize: 14,
        fontWeight: '500',
    },
    cardSetting: {
        width: '15.25rem',
    },
    popupItemLabel: {
        fontWeight: '500',
        fontSize: 12,
        color: COLORS.lightGray,
    },
    popupItemIcon: {
        ':nth-child(1n) > *': {
            stroke: '#CCCDCD',
        },
    },
    slIcon: {
        ':nth-child(1n) > *': {
            stroke: COLORS.white,
        },
    },
    slLabel: {
        color: COLORS.white,
    },
    projectInfo: {
        color: COLORS.dark,
        fontSize: 20,
        fontWeight: '700',
    },
    defaultCompanyLogo: {
        height: 36,
        width: 36,
        borderRadius: 4,
        boxSizing: 'border-box',
        border: `1px solid ${COLORS.lightGray}`,
        background: COLORS.mainColor,
    },
    dot: {
        color: COLORS.gray,
    },
})
