import React, { createContext, useState } from 'react'

export const BaseContext = createContext()

export default function BaseContextWrapper({ children }) {
    const [vacanciesCount, setVacanciesCount] = useState(0)
    const [candidatesCount, setCandidatesCount] = useState(0)

    return (
        <BaseContext.Provider value={{
            vacanciesCount,
            setVacanciesCount,
            candidatesCount,
            setCandidatesCount,
        }}>
            {children}
        </BaseContext.Provider>
    )
}
