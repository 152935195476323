export const Permissions = {
    Owner: {},
    Administrator: {
        header: [
            'notifications',
            'dropdown_profile',
            'log_out',
            'help',
            'about_us',
        ],
        dashboard: [
            'download',
            'widget_settings',
            'vacancies',
            'closed_vacancies',
            'average_age_candidate',
            'conduct_interview',
            'events',
            'event_create',
            'new_employees',
            'candidate_source',
            'closed_vacancies_stat',
            'department_vacancies',
            'hired_employees',
            'kanban_vacancies_time',
        ],
        vacancies: [
            'view',
            'kanban_view',
            'top_filter',
            'settings',
            'set_recruiter',
            'set_admin',
            'edit_status',
            'edit_theme',
            'last_changes',
            'edit_kanban',
            'filter',
            'search',
            'drag_and_drop',
            'archive',
            'set_recruiter_on_create',
            'add_candidate_to_vacancy',
        ],
        candidates: [
            'view',
            'create',
            'update',
            'add_kanban_column',
            'edit_kanban_column',
            'filter',
            'search',
            'drag_and_drop',
            'duplicate_column',
            'share',
            'archive',
            'settings',
            'set_recruiter',
            'set_admin',
            'edit_status',
            'edit_theme',
            'candidate_history',
            'candidate_card',
            'edit_candidate_apply',
            'actions',
            'reject',
            'accept',
            'offer',
            'meetup',
            'main_info',
            'skills_and_experience',
            'history',
            'add_candidate',
        ],
        employees: [
            'view',
            'search',
            'role_filter',
            'filter',
            'create',
            'update',
            'settings',
            'give_recruiter_role',
            'give_administrator_role',
            'edit_tag',
            'edit_theme',
            'employee_history',
            'employee_info',
            'add_employee_to_event',
            'calendar',
            'archive',
            'unarchive',
            'employee_card',
            'main_info',
            'employee_salary',
            'skills_and_experience',
            'history',
            'change_role',
        ],
        settings: {
            company: [
                'edit',
                'edit_admin',
                'edit_recruiter',
                'edit_employee',
                'edit_department',
                'edit_position',
                'edit_location',
                'display_currency',
                'integration',

            ],
            templates: ['edit', 'delete'],
            billing: [
                'view_plans',
                'view_cards',
            ],
            companyPublic: [
                'view',
                'page_display',
                'edit_vacancies',
                'edit_info',
            ],
        },
    },
    Recruiter: {
        header: [
            'notifications',
            'dropdown_profile',
            'log_out',
            'help',
            'about_us',
        ],
        dashboard: [
            'download',
            'widget_settings',
            'vacancies',
            'closed_vacancies',
            'average_age_candidate',
            'conduct_interview',
            'events',
            'event_create',
            'new_employees',
            'candidate_source',
            'closed_vacancies_stat',
            'department_vacancies',
            'hired_employees',
            'kanban_vacancies_time',
        ],
        vacancies: [
            'view',
            'kanban_view',
            'top_filter',
            'settings',
            'set_recruiter',
            'edit_status',
            'edit_theme',
            'last_changes',
            'edit_kanban',
            'filter',
            'search',
            'drag_and_drop',
            'archive',
            'set_recruiter_on_create',
            'add_candidate_to_vacancy',
        ],
        candidates: [
            'view',
            'create',
            'update',
            'add_kanban_column',
            'edit_kanban_column',
            'filter',
            'search',
            'drag_and_drop',
            'duplicate_column',
            'share',
            'archive',
            'settings',
            'set_recruiter',
            'set_admin',
            'edit_status',
            'edit_theme',
            'candidate_history',
            'candidate_card',
            'edit_candidate_apply',
            'actions',
            'reject',
            'accept',
            'offer',
            'meetup',
            'main_info',
            'skills_and_experience',
            'history',
            'add_candidate',
        ],
        employees: [
            'view',
            'search',
            'role_filter',
            'filter',
            'create',
            'update',
            'archive',
            'unarchive',
            'settings',
            'give_recruiter_role',
            'give_administrator_role',
            'edit_tag',
            'edit_theme',
            'employee_history',
            'employee_info',
            'add_employee_to_event',
            'calendar',
            'employee_card',
            'main_info',
            'employee_salary',
            'skills_and_experience',
            'history',
        ],
        settings: {
            company: [
                'edit',
                'edit_recruiter',
                'edit_employee',
                'edit_department',
                'edit_position',
                'edit_location',
                'display_currency',
                'integration',

            ],
            templates: ['edit', 'delete'],
            billing: [
                'view_plans',
            ],
            companyPublic: [
                'view',
                'edit_vacancies',
                'edit_info',
            ],
        },
    },
    RecruiterView: {
        header: [
            'notifications',
            'dropdown_profile',
            'log_out',
            'help',
            'about_us',
        ],
        dashboard: [
            'download',
            'widget_settings',
            'vacancies',
            'closed_vacancies',
            'average_age_candidate',
            'conduct_interview',
            'events',
            'event_create',
            'new_employees',
            'candidate_source',
            'closed_vacancies_stat',
            'department_vacancies',
            'hired_employees',
            'kanban_vacancies_time',
        ],
        vacancies: [
            'view',
            'kanban_view',
            'settings',
            'edit_status',
            'edit_theme',
            'last_changes',
            'filter',
            'search',
            'drag_and_drop',
            'archive',
            'add_candidate_to_vacancy',
        ],
        candidates: [
            'view',
            'filter',
            'search',
            'create',
            'update',
            'drag_and_drop',
            'duplicate_column',
            'share',
            'archive',
            'settings',
            'edit_status',
            'edit_theme',
            'candidate_history',
            'candidate_card',
            'actions',
            'reject',
            'accept',
            'offer',
            'meetup',
            'main_info',
            'skills_and_experience',
            'history',
            'add_candidate',
        ],
        employees: [
            'view',
            'search',
            'role_filter',
            'filter',
            'create',
            'update',
            'settings',
            'edit_tag',
            'edit_theme',
            'employee_history',
            'employee_info',
            'add_employee_to_event',
            'calendar',
            'employee_card',
            'main_info',
            'employee_salary',
            'skills_and_experience',
            'history',
        ],
        settings: {
            company: [
                'edit_employee',
                'edit_department',
                'edit_position',
                'edit_location',
                'display_currency',
                'integration',
            ],
            templates: ['edit', 'delete'],
            companyPublic: ['view'],
        },
    },
    Employee: {
        header: [
            'notifications',
            'dropdown_profile',
            'log_out',
            'help',
            'about_us',
        ],
        dashboard: [
            'download',
            'widget_settings',
            'conduct_interview',
            'events',
            'event_create',
            'new_employees',
            'closed_vacancies_stat',
            'kanban_vacancies_time',
        ],
        vacancies: [
            'view',
            'kanban_view',
            'settings',
            'edit_theme',
            'last_changes',
            'filter',
            'search',
            'archive',
        ],
        candidates: [],
        employees: [
            'view',
            'search',
            'role_filter',
            'filter',
            'settings',
            'edit_theme',
            'employee_history',
            'employee_info',
            'employee_card',
            'main_info',
            'skills_and_experience',
            'history',
        ],
        settings: {
            company: [
                'display_currency',
                'integration',
            ],
            templates: [],
            billing: [],
            companyPublic: [],
        },
    },
}
