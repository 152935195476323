import React from 'react'
import { useNavigate } from 'react-router-dom'
import { convertToHTML } from 'draft-convert'
import VacancyForm from '../components/vacancies/VacancyForm'
import { usePostRequest } from '../hooks/request'
import { VACANCY_CREATE } from '../urls'
import { useMessage } from '../hooks/message'

export default function VacancyCreate() {
    const navigate = useNavigate()
    const create = usePostRequest({ url: VACANCY_CREATE })
    const [showMessage] = useMessage()

    const onSubmit = async (submitData) => {
        const {
            position, department, skills, location, recruiter, responsible, salary, experience, ...formData
        } = submitData

        submitData.languageLevel.pop()

        const data = {
            ...formData,
            // eslint-disable-next-line max-len
            description: submitData.description ? typeof submitData.description === 'object' ? convertToHTML(submitData.description.getCurrentContent()) : submitData.description : null,
            education: submitData.education ? submitData.education.value : '',
            employeeCount: submitData.employeeCount.replace(/\s/g, ''),
            schedule: submitData.schedule.value || null,
            salaryFrom: salary.from.replace(/\s/g, '') || null,
            salaryTo: salary.to.replace(/\s/g, '') || null,
            currency: salary.currency ? salary.currency.label : '',
            experience: experience.value || 0,
            hhAreaId: submitData.region.value || null,
            languageLevel: submitData.languageLevel,
            name: submitData.name,
            skill: skills.map((e) => e.id),
            knowledge: submitData.knowledge.map((e) => e.id),
            positionId: position.value,
            positionName: position.label,
            format: submitData.format.value || null,
            departmentId: department.value,
            locationId: location ? location.value : '',
            recruiter: recruiter ? recruiter.value : '',
            responsible: responsible ? responsible.value : '',
        }

        const { success, error } = await create.request({ data })

        if (success) {
            navigate('/vacancies?view=board')
            return showMessage('Успешно создано!', 'success-msg')
        }

        return showMessage(error.data.errors[0].message || error.data.message, 'error-msg')
    }

    return (
        <VacancyForm onSubmit={onSubmit} loading={create.loading} />
    )
}
