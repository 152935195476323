import React, { useState } from 'react'
import cn from 'classnames'
import { css, StyleSheet } from 'aphrodite'
import uuid from 'react-uuid'
import { CheckSizeM } from '../svgs/Svgs'
import { COLORS, DASHBOARD_COLORS } from '../../utils/colors'

export function ColorsPalette({ onClick, defaultColor }) {
    const [active, setActive] = useState(defaultColor || 2)

    return (
        <div className={cn(css(s.colorsCont), 'justify-between')}>
            {DASHBOARD_COLORS.map((color, index) => (
                <span key={uuid()} className={colorsFrame(color, active === index)}>
                    <div onClick={() => {
                        setActive(index)
                        if (typeof onClick === 'function') onClick({ color, index })
                    }}>
                        {active === index && <CheckSizeM />}
                    </div>
                </span>
            ))}
        </div>
    )
}

const s = StyleSheet.create({
    colorsCont: { marginTop: 16 },
})

const colorsFrame = (backgroundColor, isActive) => {
    const main = StyleSheet.create({
        color: {
            width: 40,
            height: 40,
            boxSizing: 'border-box',
            borderRadius: 50,
            // marginRight: 5,
            // ':last-of-type': {
            //     marginRight: 0,
            // },
            ':nth-child(1n) > :first-child': {
                backgroundColor,
                cursor: 'pointer',
                borderRadius: 50,
                display: 'inline-flex',
                justifyContent: 'center',
                alignItems: 'center',
            },
        },
        actColor: isActive ? {
            border: `1px solid ${COLORS.mainColor}`,
            ':nth-child(1n) > :first-child': {
                width: 32,
                height: 32,
                borderWidth: 0,
            },
        } : {
            border: '1px solid transparent',
            ':hover': {
                border: `1px solid ${COLORS.lightGray}`,
                ':nth-child(1n) > :first-child': {
                    border: '1px solid transparent',
                },
            },
            ':nth-child(1n) > :first-child': {
                width: 30,
                height: 30,
                border: `1px solid ${COLORS.lightGray}`,
            },
        },
    })

    return cn(css(main.color), css(main.actColor), 'flex-cent')
}
