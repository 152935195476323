import React from 'react'
import VacancyTable from './VacancyTable'
import VacancyColumns from './VacancyColumns'
import VacancyArchiveTable from './VacancyArchiveTable'

export default function VacancyDashboard({ vacancyFilter, setVacanciesCount, view }) {
    return view === 'archive' ? (
        <VacancyArchiveTable setVacanciesCount={setVacanciesCount} />
    ) : view === 'board'
        ? (
            <VacancyColumns vacancyFilter={vacancyFilter} setVacanciesCount={setVacanciesCount} />
        ) : (
            <VacancyTable
                vacancyFilter={vacancyFilter}
                setVacanciesCount={setVacanciesCount}
            />
        )
}
