import React from 'react'
import cn from 'classnames'
import { css, StyleSheet } from 'aphrodite'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import { CalendarLayout,
    Copy,
    EditPencil,
    MailSizeM,
    Phone,
    Trash } from '../../svgs/Svgs'
import { COLORS } from '../../../utils/colors'
import { ageRuFormat, formatPhoneNumber } from '../../../utils/number'
import { cp } from '../../../utils/checkPermission'

export default function EmployeeShortInfo({ info, onArchive, isArchive }) {
    const navigate = useNavigate()
    const imageSrc = info.photo && info.photo.length > 0 ? info.photo[1] : ''

    return (
        <div className={cn('direction-column', css(s.short_info_cont))}>
            <div className="is-flex direction-column">
                {imageSrc ? (
                    <img className={css(s.candidate_img)} src={imageSrc} alt="#avatar" />
                ) : (
                    <div className={cn('flex-cent', css(s.avatar))}>
                        <h3>
                            {info.firstName && info.lastName
                                ? info.firstName.slice(0, 1) + info.lastName.slice(0, 1) : ''}
                        </h3>
                    </div>
                )}

                <h3 className={css(s.name)}>{info.firstName} {info.lastName}</h3>

                {(info.position || info.age) && (
                    <h3 className={css(s.position)}>
                        {info.position ? `${info.position.name}` : ''}

                        {info.age ? `・ ${ageRuFormat(info.age.toString())}` : ''}
                    </h3>
                )}

                {/* <SelectSimple */}
                {/*     label="Роль в системе" */}
                {/*     defaultValue={roleOptions[info.role ? info.role.id : 3]} */}
                {/*     isSearchable={false} */}
                {/*     valueContainerStyles={{ padding: '10px 16px' }} */}
                {/*     placeholderStyles={{ fontSize: 14, fontWeight: '500', color: COLORS.gray }} */}
                {/*     className={css(s.select)} */}
                {/*     options={roleOptions} /> */}

                <ul className={css(s.short_list)}>
                    {info.phoneNumber ? (
                        <li>
                            <Phone />

                            <div className={css(s.lstItem)}>
                                <h3>Номер телефона</h3>

                                <h3>{formatPhoneNumber(info.phoneNumber)}</h3>
                            </div>

                            <Copy
                                className={css(s.clipboard)}
                                onClick={() => navigator.clipboard.writeText(info.phoneNumber)} />
                        </li>
                    ) : null}

                    {info.additionalPhoneNumber && (
                        <li>
                            <Phone />

                            <div className={css(s.lstItem)}>
                                <h3>Дополнительный номер</h3>

                                <h3>{formatPhoneNumber(info.additionalPhoneNumber)}</h3>
                            </div>

                            <Copy
                                className={css(s.clipboard)}
                                onClick={() => navigator.clipboard.writeText(info.additionalPhoneNumber)} />
                        </li>
                    )}

                    {info.email && (
                        <li>
                            <MailSizeM />

                            <div className={css(s.lstItem)}>
                                <h3>Электронная почта</h3>

                                <h3>{info.email}</h3>
                            </div>

                            <Copy
                                className={css(s.clipboard)}
                                onClick={() => navigator.clipboard.writeText(info.email)} />
                        </li>
                    )}

                    <li>
                        <CalendarLayout className={css(s.icon)} />

                        <div className={css(s.lstItem)}>
                            <h3>Дата и время добавления</h3>

                            <h3>{moment(info.createdAt).format('DD MMMM YYYY ・ HH:mm')}</h3>
                        </div>
                    </li>

                    {/* <li> */}
                    {/*     <FileSymlink /> */}

                    {/*     <div className={css(s.lstItem)}> */}
                    {/*         <h3>Способ добавления</h3> */}
                    {/*         <h3>headhunter.uz</h3> */}
                    {/*     </div> */}
                    {/* </li> */}
                </ul>

                {/* <div className={css(s.contact_via)}> */}
                {/*     <h3>Связаться с помощью:</h3> */}

                {/*     <div> */}
                {/*         {[telegram, gmail, hh, whatsapp].map((img) => ( */}
                {/*             <img key={uuid()} className={css(s.network_logo)} src={img} alt="#logo" /> */}
                {/*         ))} */}
                {/*     </div> */}
                {/* </div> */}
            </div>

            { !isArchive ? (
                <div className={css(s.reply)}>
                    {/* <span><CalendarPlus /></span> */}

                    {cp('employees.update') && (
                        <span onClick={() => navigate(`/employees/update/${info.id}`)}>
                            <EditPencil color={COLORS.lightBlack} />
                        </span>
                    )}

                    {(cp('employees.archive') && typeof onArchive === 'function') && (
                        <span onClick={() => onArchive(info.id, `${info.firstName} ${info.lastName}`)}>
                            <Trash color={COLORS.lightBlack} />
                        </span>
                    )}
                </div>
            ) : null}
        </div>
    )
}

const s = StyleSheet.create({
    short_info_cont: {
        padding: '1.25rem 1.5rem',
        borderRight: `1px solid ${COLORS.chineseWhite}`,
        display: 'flex',
        justifyContent: 'space-between',
    },
    candidate_img: {
        border: `1px solid ${COLORS.lightGray}`,
        borderRadius: 4,
        width: '15.75rem',
        height: '13.875rem',
        marginBottom: '1rem',
    },
    avatar: {
        background: '#1B93C0',
        width: '15.75rem',
        height: '13.875rem',
        borderRadius: 4,
        fontWeight: '600',
        fontSize: 80,
        color: '#FFFFFF',
        marginBottom: '1rem',
        border: '1px solid transparent',
        textTransform: 'uppercase',
    },
    name: {
        fontSize: 16,
        color: '#181A19',
        fontWeight: '600',
    },
    position: {
        fontSize: 13,
        color: COLORS.gray,
        fontWeight: '500',
        marginBottom: '1rem',
    },
    select: {
        border: `1px solid ${COLORS.lightGray}`,
        borderRadius: 4,
    },
    short_list: {
        margin: '0.75rem 0',
        padding: '0.75rem',
        listStyle: 'none',
        background: COLORS.lotion,
        border: `1px solid ${COLORS.smoothGray}`,
        boxShadow: '0px 2px 4px rgba(130, 130, 130, 0.05)',
        borderRadius: 4,
        display: 'flex',
        flexDirection: 'column',
        gap: 8,
        ':nth-child(1n) > *': {
            display: 'flex',
            alignItems: 'center',
            gap: 12,
            paddingBottom: 8,
            borderBottom: `1px solid ${COLORS.smoothGray}`,
        },
        ':nth-child(1n) > :last-child': {
            paddingBottom: 0,
            borderBottom: 'none',
        },
        // marginBottom: 80,
    },
    clipboard: {
        cursor: 'pointer',
        ':hover': {
            ':nth-child(1n) > *': {
                stroke: COLORS.skyblue,
            },
        },
    },
    lstItem: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        ':nth-child(1n) > :first-child': {
            fontSize: 12,
            color: COLORS.midGray,
            fontWeight: '400',
        },
        ':nth-child(1n) > :last-child': {
            fontSize: 13,
            color: '#181A19',
            fontWeight: '500',
        },
    },
    contact_via: {
        ':nth-child(1n) > :first-child': {
            fontSize: 12,
            color: COLORS.midGray,
            fontWeight: '500',
        },
        ':nth-child(1n) > :last-child': {
            marginTop: 8,
            display: 'flex',
            gap: '1rem',
        },
        marginBottom: 36, // TODO add margin more
    },
    network_logo: {
        width: 28,
        height: 28,
    },
    reply: {
        display: 'flex',
        gap: 12,
        ':nth-child(1n) > *': {
            cursor: 'pointer',
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: 4,
            padding: '9px 0',
            flex: 1,
            border: `1px solid ${COLORS.lightGray}`,
        },
    },
    offer: {
        background: 'rgba(0, 149, 242, 0.1)',
        border: '1px solid rgba(0, 149, 242, 0.2)',
        fontWeight: '500',
        fontSize: 14,
        color: COLORS.skyblue,
        flex: 1,
    },
    icon: {
        ':nth-child(1n) > :first-child': {
            ':nth-child(1n) > :first-child': {
                fill: COLORS.spanishGray,
            },
        },
    },
})
