import React from 'react'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import moment from 'moment'
import Highlighter from 'react-highlight-words'
import { ChevronNext, InfoSizeM, ThreeDotsMenu, Trash, EditPencil } from '../svgs/Svgs'
import { ageRuFormat } from '../../utils/number'
import { Popup } from '../Popup'
import { LoadingSkeleton } from '../common/LoadingSkeleton'
import { COLORS } from '../../utils/colors'
import { roleOptions } from '../../utils/options'
import { cp } from '../../utils/checkPermission'

function Skeleton() {
    return (
        <tr className={css(s.loadItem)}>
            <td className={css(s.colItem)}>
                <div className="align-center gap-2">
                    <LoadingSkeleton height={32} width={32} borderRadius={32} />

                    <div className={cn('align-start direction-column gap-1')}>
                        <LoadingSkeleton height={15} width="10rem" />

                        <LoadingSkeleton height={13} width="8rem" />
                    </div>
                </div>
            </td>

            <td><LoadingSkeleton height={20} width="50%" /></td>

            <td><LoadingSkeleton height={20} width="50%" /></td>

            <td><LoadingSkeleton height={20} width="50%" /></td>

            <td><LoadingSkeleton height={20} width="50%" /></td>

            <td className={css(s.colItem)}>
                <div className="justify-end">
                    <div className={css(s.menuItem)}>
                        <ThreeDotsMenu className="pointer" />
                    </div>
                </div>
            </td>
        </tr>
    )
}

export default function EmployeeTableItem({ item, keyword, loading, onClick, onEdit, onArchive }) {
    // eslint-disable-next-line max-len
    const updateItem = cp('employees.update') ? [{ id: 2, value: 'Редактировать', icon: EditPencil, onClick: onEdit }] : []
    // eslint-disable-next-line max-len
    const archiveItem = cp('employees.archive') && item.role !== 1 ? [{ id: 3, value: 'Уволить', icon: Trash, onClick: (id, name) => onArchive(id, name) }] : []

    const popupItems = [
        { id: 1, value: 'О сотруднике', icon: InfoSizeM, onClick },
        ...updateItem,
        ...archiveItem,
    ]

    const imageSrc = item.photo && item.photo.length > 0 ? item.photo[1] : ''

    return !loading ? (
        <tr onClick={onClick} className={css(s.item)}>
            <td className={css(s.colItem)}>
                <div className="align-center gap-2">
                    {imageSrc ? (
                        <img className={css(s.employeeImg)} src={imageSrc} alt="#avatar" />
                    ) : (
                        <div className={cn('flex-cent', css(s.defaultAvatar))}>
                            <h3>
                                {item.firstName && item.lastName
                                    ? `${item.firstName.slice(0, 1)}${item.lastName.slice(0, 1)}` : ''}
                            </h3>
                        </div>
                    )}

                    <div className="is-flex direction-column gap-1 flex-1">
                        <Highlighter
                            textToHighlight={`${item.firstName} ${item.lastName}` || ''}
                            searchWords={[keyword]}
                            className={css(s.name)}
                            highlightClassName="highlight-bg"
                        />

                        <div className="align-center gap-1">
                            <Highlighter
                                textToHighlight={item.department || ''}
                                searchWords={[keyword]}
                                highlightClassName="highlight-bg"
                            />

                            <span>•</span>

                            <Highlighter
                                textToHighlight={item.position || ''}
                                searchWords={[keyword]}
                                highlightClassName="highlight-bg"
                            />
                        </div>
                    </div>
                </div>
                {/* <Highlighter */}
                {/*     textToHighlight={`${item.firstName} ${item.lastName}`} */}
                {/*     searchWords={[keyword]} */}
                {/*     highlightClassName="highlight-bg" */}
                {/* /> */}

                {/* <div title={`${item.department} • ${item.position}`} className="align-center gap-1"> */}
                {/*     <Highlighter */}
                {/*         textToHighlight={item.department || ''} */}
                {/*         searchWords={[keyword]} */}
                {/*         highlightClassName="highlight-bg" */}
                {/*     /> */}

                {/*     <span>•</span> */}

                {/*     <Highlighter */}
                {/*         textToHighlight={item.position || ''} */}
                {/*         searchWords={[keyword]} */}
                {/*         highlightClassName="highlight-bg" */}
                {/*     /> */}
                {/* </div> */}
            </td>

            <td>
                {item.age
                    ? ageRuFormat(item.age.toString())
                    : <div style={{ paddingLeft: 15 }}>&#8212;&#8212;&#8212;</div>}
            </td>

            <td>
                {item.role
                    ? roleOptions[item.role - 1].label
                    : <div style={{ paddingLeft: 15 }}>&#8212;&#8212;&#8212;</div>}
            </td>

            <td>
                {item.createdAt
                    ? moment(item.createdAt).format('D MMMM YYYY')
                    : <div style={{ paddingLeft: 15 }}>&#8212;&#8212;&#8212;</div>}
            </td>

            <td>
                {item.startedAt
                    ? moment(item.startedAt).format('D MMMM YYYY')
                    : <div style={{ paddingLeft: 15 }}>&#8212;&#8212;&#8212;</div>}
            </td>

            <td className={css(s.colItem)}>
                <div className="justify-end">
                    <Popup
                        items={popupItems}
                        trigger={(
                            <div className={css(s.menuItem)}>
                                <ThreeDotsMenu className="pointer" />
                            </div>
                        )}
                        renderItem={(e) => (
                            <li
                                key={e.id}
                                onClick={(event) => {
                                    event.stopPropagation()
                                    e.onClick(item.id, `${item.firstName} ${item.lastName}`)
                                }}
                                className={cn('justify-between', css(s.cardSetting))}>
                                <div className="align-center gap-2">
                                    <e.icon /> {e.value}
                                </div>

                                {e.navigate ? <ChevronNext /> : null}
                            </li>
                        )}
                    />
                </div>
            </td>
        </tr>
    ) : <Skeleton />
}

const s = StyleSheet.create({
    status: {
        display: 'inline-flex',
        alignItems: 'center',
        borderRadius: 4,
        padding: '4.5px 8px',
        fontSize: 11,
        fontWeight: '500',
    },
    item: {
        height: '3.75rem',
        background: COLORS.white,
        color: '#181A19',
        fontSize: 13,
        fontWeight: '500',
        wordBreak: 'break-word',
        ':nth-child(1n) > :last-child': {
            paddingRight: '1rem',
        },
    },
    cardSetting: {
        width: '11rem',
        ':nth-child(1n) > :first-child': {
            ':nth-child(1n) > :first-child': {
                ':nth-child(1n) > *': {
                    stroke: '#CCCDCD',
                },
            },
        },
    },
    menuItem: {
        width: 20,
        height: 20,
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'center',
    },
    loadItem: {
        height: '3.75rem',
        background: '#FFFFFF',
        ':nth-child(1n) > :last-child': {
            paddingRight: '1rem',
        },
    },
    colItem: {
        ':first-of-type': {
            paddingLeft: '1.5rem',
            ':nth-child(1n) > :last-child': {
                fontWeight: '400',
                fontSize: 11,
                color: '#6D7471',
            },
        },
        ':last-of-type': {
            textAlign: 'right',
        },
    },
    name: {
        fontWeight: '500',
        fontSize: 13,
        color: COLORS.dark,
    },
    defaultAvatar: {
        background: '#1B93C0',
        width: 32,
        height: 32,
        borderRadius: 32,
        fontWeight: '600',
        fontSize: 12,
        color: '#FFFFFF',
        textTransform: 'uppercase',
    },
    employeeImg: {
        width: 30,
        height: 30,
        borderRadius: 32,
        border: '1px solid rgba(0, 0, 0, 0.2)',
    },
})
