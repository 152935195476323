import React, { forwardRef, useImperativeHandle, useState } from 'react'
import cn from 'classnames'
import { css, StyleSheet } from 'aphrodite'
import { Exit } from '../svgs/Svgs'
import Modal from '../common/Modal'
import { usePostRequest } from '../../hooks/request'
import { LOGOUT } from '../../urls'
import { useMessage } from '../../hooks/message'
import { COLORS } from '../../utils/colors'
import Button from '../common/Button'

export const ExitModal = forwardRef(({ onlyModal = false, userLoading }, ref) => {
    const logout = usePostRequest({ url: LOGOUT })
    const [showModal, setShowModal] = useState(false)
    const [showMessage] = useMessage()

    async function onExit() {
        const { success } = await logout.request()

        if (success) showMessage('Успешно!', 'success-msg')
        else showMessage('Что-то пошло не так', 'error-msg')

        localStorage.clear()
        // eslint-disable-next-line no-restricted-globals
        window.location.pathname = '/'
    }

    useImperativeHandle(ref, () => ({
        onOpenModal() { setShowModal(true) },
    }), [])

    return (
        <React.Fragment>
            {!onlyModal && (
                <Button
                    disabled={userLoading}
                    onClick={() => setShowModal(true)}
                    className={cn('flex-cent', css(s.exitBtn))}>
                    <Exit color={COLORS.midGray} /> Выйти
                </Button>
            )}

            <Modal
                open={showModal}
                onClose={() => setShowModal(false)}
                closeOnDocumentClick
                closeOnEscape>
                <div className={cn('is-flex direction-column', css(s.exitModal))}>
                    <div className={cn(css(s.modalTxt), css(s.extModal))}>
                        <div>
                            <h3>Вы уверены, что хотите выйти из системы?</h3>
                        </div>
                    </div>

                    <div className={cn('justify-end', css(s.btnGr))}>
                        <button
                            className={css(s.cancelBtn)}
                            onClick={() => setShowModal(false)}>
                            Отмена
                        </button>

                        <button
                            onClick={() => onExit()}
                            className={cn('flex-cent', css(s.exitBtn, s.mExitBtn))}>
                            <Exit /> Выйти
                        </button>
                    </div>
                </div>
            </Modal>
        </React.Fragment>
    )
})

const s = StyleSheet.create({
    exitBtn: {
        cursor: 'pointer',
        background: 'transparent',
        borderRadius: 4,
        color: COLORS.midGray,
        padding: '7px 0',
        width: '8.5rem',
        gap: 8,
        outline: 'none',
        border: `1px solid ${COLORS.midGray}`,
        ':hover': {
            boxShadow: '0px 2px 4px 0px rgba(130, 130, 130, 0.15)',
        },
        ':first-of-type': {
            width: '11.75rem',
        },
    },
    mExitBtn: {
        background: '#FFE1E7',
        border: '1px solid #FFD1DA',
        color: COLORS.lightRed,
        ':hover': {
            border: `1px solid ${COLORS.lightRed}`,
            boxShadow: '0px 2px 4px rgba(168, 168, 168, 0.15)',
        },
    },
    exitModal: {
        background: COLORS.white,
        padding: '20px 24px',
        borderRadius: 4,
    },
    modalTxt: {
        color: COLORS.lightBlack,
        fontSize: 20,
        fontWeight: '500',
        paddingBottom: 20,
        marginBottom: 20,
        borderBottom: `1px solid ${COLORS.lightGray}`,
        // ':nth-child(1n) > :first-child': {
        //     width: '85%',
        // },
    },
    btnGr: {
        gap: 16,
    },
    cancelBtn: {
        fontWeight: '500',
        fontSize: 14,
        color: COLORS.gray,
        padding: '11px 16px',
        border: `1px solid ${COLORS.gray}`,
        borderRadius: 4,
        background: 'transparent',
        cursor: 'pointer',
        outline: 'none',
    },
    extModal: {
        paddingRight: '8.25rem',
        whiteSpace: 'nowrap',
    },
})
