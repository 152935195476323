import React from 'react'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import Button from '../../common/Button'
import { COLORS } from '../../../utils/colors'
import { RepeatSizeXL } from '../../svgs/Svgs'
import Modal from '../../common/Modal'

export default function Success({ showModal, setShowModal, onClose }) {
    return (
        <Modal
            open={showModal}
            onClose={() => {
                onClose()
                setShowModal(false)
            }}>
            <div className={css(s.wrap)}>
                <div className="flex-cent">
                    <div className={css(s.close)}>
                        <RepeatSizeXL />
                    </div>
                </div>

                <div className="is-flex direction-column gag-2">
                    <h3 className={css(s.title)}>Права владельца успешно переданы</h3>

                    <p className={css(s.desc)}>
                        {/* Новым владельцем компании является Омина Курбанова. */}
                        Ваша роль в системе поменялась на Администратор и вы имеете возможность удалить свой аккаунт
                        при необходимости
                    </p>
                </div>

                <Button
                    onClick={() => {
                        onClose()
                        setShowModal(false)
                    }}
                    className={cn('full-width', css(s.btn))}>
                    Понятно
                </Button>
            </div>
        </Modal>
    )
}

const s = StyleSheet.create({
    wrap: {
        background: COLORS.white,
        padding: '20px 24px',
        borderRadius: 4,
        gap: 16,
        display: 'flex',
        flexDirection: 'column',
        width: '37.25rem',
        textAlign: 'center',
    },
    title: {
        color: COLORS.dark,
        fontSize: 20,
        fontWeight: '700',
    },
    close: {
        display: 'inline-flex',
        padding: 12,
        borderRadius: 128,
        background: 'rgba(46, 169, 125, 0.10)',
        boxShadow: '0px 2px 4px 0px rgba(42, 136, 104, 0.25)',
    },
    desc: {
        color: '#343836',
        fontSize: 14,
        fontWeight: '400',
    },
    btn: {
        height: 40,
        border: `1px solid ${COLORS.lightGray}`,
        color: '#343836',
        fontSize: 14,
        fontWeight: 500,
    },
})
