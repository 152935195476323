import React from 'react'
import cn from 'classnames'
import { css, StyleSheet } from 'aphrodite'
import { CandidateExperience, CandidateInfo, StatusAndSource } from './CardInfo'
import { COLORS } from '../../../utils/colors'
import { CheckSizeL, CloseSizeM, Time } from '../../svgs/Svgs'

const Statuses = {
    1: Waiting,
    2: Accept,
    3: Reject,
    5: Accept,
}

function Waiting() {
    const statusStyles = statusStylesheet(COLORS.midGray, COLORS.lotion, COLORS.smoothGray)

    return (
        <div className={css(statusStyles.cont)}>
            <div className="align-center gap-1">
                <Time color={COLORS.gray} />

                <h3 className={css(statusStyles.label)}>Приглашение на рассмотрении</h3>
            </div>
        </div>
    )
}

function Accept() {
    const statusStyles = statusStylesheet(COLORS.mainColor, '#E3F3EC', '#E3F3EC')

    return (
        <div className={css(statusStyles.cont)}>
            <div className="align-center gap-1">
                <CheckSizeL color={COLORS.mainColor} />

                <h3 className={css(statusStyles.label)}>Я готов приступить</h3>
            </div>
        </div>
    )
}

function Reject() {
    const statusStyles = statusStylesheet(COLORS.lightRed, '#FEEBEF', '#FEEBEF')

    return (
        <div className={css(statusStyles.cont)}>
            <div className="align-center gap-1">
                <CloseSizeM color={COLORS.lightRed} />

                <h3 className={css(statusStyles.label)}>Я не смогу выйти на работу</h3>
            </div>
        </div>
    )
}

export default function CardAccept({ data, isDragging, setModal }) {
    const { params } = data
    const StatusComponent = Statuses[params.status]

    return (
        <div
            className={cn(css(s.wrap), { [css(s.onDrag)]: isDragging })}
            onClick={() => setModal({ act: true, id: data.id, type: 'detail' })}
        >
            <div className="is-flex gap-2 direction-column">
                <CandidateInfo data={data} />

                <div className={cn(
                    'is-flex direction-column gap-2',
                )}>
                    <CandidateExperience data={data} />

                    <StatusComponent params={params} />

                    <StatusAndSource data={data} />
                </div>
            </div>
        </div>
    )
}

const s = StyleSheet.create({
    wrap: {
        backgroundColor: '#fff',
        padding: 12,
        cursor: 'pointer',
        borderRadius: 4,
        border: `1px solid ${COLORS.smoothGray}`,
        boxShadow: '0px 2px 4px rgba(136, 136, 136, 0.25)',
        ':hover': {
            border: `1px solid ${COLORS.lightGray}`,
            boxShadow: '0px 2px 4px rgba(136, 136, 136, 0.35)',
        },
    },
    onDrag: {
        background: 'rgba(255, 255, 255, 0.1)',
        border: `1px solid ${COLORS.skyblue}`,
        boxShadow: '0px 4px 16px rgba(0, 113, 218, 0.4)',
        backdropFilter: 'blur(4px)',
    },
})

const statusStylesheet = (color, backgroundColor, borderColor) => StyleSheet.create({
    cont: {
        borderRadius: 4,
        padding: 6,
        border: `1px solid ${borderColor}`,
        backgroundColor,
        display: 'flex',
        flexDirection: 'column',
        gap: 6,
    },
    label: {
        fontSize: 11,
        fontWeight: '500',
        color,
    },
})
