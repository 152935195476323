/* eslint-disable max-len */
export const CandidateColumnTypes = {
    NEW: 'first',
    IN_PROGRESS: 'second',
    MEETUP: 'meetup',
    OFFER: 'offer',
    ACCEPT: 'accept',
    REJECT: 'reject',
}

const invalidCases = [
    { sourceType: CandidateColumnTypes.NEW, destinationType: CandidateColumnTypes.OFFER },

    { sourceType: CandidateColumnTypes.IN_PROGRESS, destinationType: CandidateColumnTypes.OFFER },
    { sourceType: CandidateColumnTypes.IN_PROGRESS, destinationType: CandidateColumnTypes.NEW },

    { sourceType: CandidateColumnTypes.ACCEPT, destinationType: CandidateColumnTypes.NEW },
    { sourceType: CandidateColumnTypes.ACCEPT, destinationType: CandidateColumnTypes.IN_PROGRESS },
    { sourceType: CandidateColumnTypes.ACCEPT, destinationType: CandidateColumnTypes.MEETUP },
    { sourceType: CandidateColumnTypes.ACCEPT, destinationType: CandidateColumnTypes.OFFER },

    { sourceType: CandidateColumnTypes.REJECT, destinationType: CandidateColumnTypes.NEW },
    { sourceType: CandidateColumnTypes.REJECT, destinationType: CandidateColumnTypes.MEETUP },
    { sourceType: CandidateColumnTypes.REJECT, destinationType: CandidateColumnTypes.OFFER },
    { sourceType: CandidateColumnTypes.REJECT, destinationType: CandidateColumnTypes.ACCEPT },
]

export const meetupInvalidCases = {
    1: [CandidateColumnTypes.NEW, CandidateColumnTypes.IN_PROGRESS, CandidateColumnTypes.MEETUP, CandidateColumnTypes.OFFER, CandidateColumnTypes.ACCEPT, CandidateColumnTypes.REJECT], // waiting status
    2: [CandidateColumnTypes.NEW, CandidateColumnTypes.IN_PROGRESS, CandidateColumnTypes.MEETUP, CandidateColumnTypes.ACCEPT, CandidateColumnTypes.REJECT], // approved status
    3: [CandidateColumnTypes.NEW, CandidateColumnTypes.ACCEPT], // rejected status
}

export const offerInvalidCases = {
    1: [CandidateColumnTypes.NEW, CandidateColumnTypes.IN_PROGRESS, CandidateColumnTypes.MEETUP, CandidateColumnTypes.ACCEPT], // waiting status
    2: [CandidateColumnTypes.NEW, CandidateColumnTypes.IN_PROGRESS, CandidateColumnTypes.MEETUP, CandidateColumnTypes.REJECT], // approved status
    3: [CandidateColumnTypes.NEW, CandidateColumnTypes.ACCEPT], // rejected status
}

export function checkDragNDrop(sourceType, destinationType, status) {
    if (sourceType === CandidateColumnTypes.MEETUP) return !meetupInvalidCases[status].includes(destinationType)
    if (sourceType === CandidateColumnTypes.OFFER) return !offerInvalidCases[status].includes(destinationType)

    return !invalidCases.some(({ sourceType: s, destinationType: d }) => (sourceType === s && destinationType === d))
}

/* eslint-enable max-len */
