import React from 'react'
import { css, StyleSheet } from 'aphrodite'
import moment from 'moment'
import cn from 'classnames'
import { COLORS } from '../../../utils/colors'
import { TableItemSkeleton } from './LoadingMetrics'

export default function VacancyTableItem({ vacancy, index, loading }) {
    const {
        new: newCol,
        accept,
        acceptAccept,
        acceptNotWaiting,
        acceptReject,
        acceptWaiting,
        meetup,
        meetupAccept,
        meetupReject,
        meetupWaiting,
        offer,
        offerAccept,
        offerReject,
        offerWaiting,
        reject,
        review,
    } = vacancy.params || {}

    return !loading && !vacancy.isLoading ? (
        <tr className={css(s.item)}>
            <td className="text-centered">{index + 1}</td>

            <td className="text-start">{moment(vacancy.date).format('DD MMMM YYYY')}</td>

            <td className="text-start">{vacancy.colName}</td>

            <td className="text-start">{newCol}</td>

            <td className="text-start">{review}</td>

            <td className="text-start">
                <div className={cn(css(s.math), 'is-flex align-center')}>
                    {meetupWaiting} <span>+</span> {meetupAccept} <span>+</span> {meetupReject} <span>=</span> {meetup}
                </div>
            </td>

            <td className="text-start">
                <div className={cn(css(s.math), 'is-flex align-center')}>
                    {offerWaiting} <span>+</span> {offerAccept} <span>+</span> {offerReject} <span>=</span> {offer}
                </div>
            </td>

            <td className="text-start">
                <div className={cn(css(s.math), 'is-flex align-center')}>
                    {acceptWaiting} <span>+</span> {acceptAccept + acceptNotWaiting} <span>+</span> {acceptReject}
                    <span> =</span> {accept}
                </div>
            </td>

            <td className="text-start">{reject}</td>
        </tr>
    ) : <TableItemSkeleton />
}

const s = StyleSheet.create({
    item: {
        // display: 'table',
        // tableLayout: 'fixed',
        width: '100%',
        height: '1.875rem',
        background: COLORS.white,
        color: '#181A19',
        fontSize: 13,
        fontWeight: '500',
        wordBreak: 'break-word',
        ':nth-child(1n) > *': {
            color: COLORS.dark,
            fontSize: 12,
            fontWeight: '500',
        },
        ':nth-child(1n) > :not(:first-of-type)': {
            paddingLeft: 12,
        },
        ':nth-child(1n) > :not(:last-of-type)': {
            borderRight: `1px solid ${COLORS.lightGray}`,
        },
    },
    math: {
        gap: 8,
        paddingLeft: 8,
        ':nth-child(1n) > span': {
            color: COLORS.gray,
        },
    },
})
