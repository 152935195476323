import React, { useRef, useState } from 'react'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import { isEmpty } from 'lodash'
import { COLORS } from '../../utils/colors'
import { ChevronDownS } from '../svgs/Svgs'
import { SelectSection } from './SelectSection'
import { educationLvl } from '../../utils/options'
import LanguageSection from './LanguageSection'

export default function Competencies({ filter, setFilter }) {
    const selectRef = useRef()
    const [eduCollapse, setEduCollapse] = useState(false)

    return (
        <div className="is-flex direction-column gap-3">
            {/* <SearchInput */}
            {/*     placeholder="Найти навык" */}
            {/*     className={css(s.input)} */}
            {/*     magnifierColor={COLORS.midGray} /> */}

            <div className="is-flex direction-column gap-2">
                <div className="justify-between">
                    <h3 className={css(s.title)}>
                        Образование

                        {!isEmpty(filter.edu) && (
                            <span
                                onClick={() => {
                                    const {
                                        edu: sectionFilter,
                                        ...clearedFilter
                                    } = filter
                                    setFilter(clearedFilter)
                                    selectRef.current.clearValue()
                                }}
                                className="dis_sl">
                                Очистить
                            </span>
                        )}
                    </h3>

                    <ChevronDownS
                        color={COLORS.dark}
                        onClick={() => setEduCollapse(!eduCollapse)}
                        className={cn('pointer dis_sl', css(eduCollapse ? s.chevronDown : s.chevronUp))} />
                </div>

                {/* <div className={collapsableSection(eduCollapse, eduCollapse ? 0 : 36)}> */}
                <SelectSection
                    isMulti
                    name="edu"
                    innerRef={selectRef}
                    withCheckbox={false}
                    customOption={false}
                    options={educationLvl}
                    defaultValue={filter.edu}
                    placeholder="Уровень образования"
                    optionStyles={{ fontSize: 12, fontWeight: '500' }}
                    onChange={(value) => { setFilter({ ...filter, edu: value }) }} />
                {/* </div> */}
            </div>

            <LanguageSection filter={filter} setFilter={setFilter} />

            {/* <hr className={css(s.line)} /> */}

            {/* <SkillsSection */}
            {/*     filter={filter} */}
            {/*     setFilter={setFilter} */}
            {/*     skillBlocks={skillBlocks} /> */}
        </div>
    )
}

const s = StyleSheet.create({
    title: {
        fontWeight: '600',
        fontSize: 14,
        whiteSpace: 'nowrap',
        color: COLORS.dark,
        ':nth-child(1n) > span': {
            cursor: 'pointer',
            fontSize: 10,
            marginLeft: 4,
            textTransform: 'uppercase',
            color: COLORS.skyblue,
            ':hover': {
                color: `${COLORS.skyblue}CC`,
            },
        },
    },
    input: {
        width: '100%',
        height: 24,
        background: '#FAFAFA',
        border: '1px solid rgba(0, 0, 0, 0.06)',
        padding: '0 8px',
        ':nth-child(1n) > input': {
            fontSize: 11,
            paddingTop: 3,
            fontWeight: '400',
            '::placeholder': {
                color: COLORS.midGray,
            },
            color: COLORS.dark,
        },
    },
    line: {
        margin: 0,
        width: '100%',
        border: 'none',
        borderTop: `1px solid ${COLORS.lightGray}`,
    },
    chevronDown: {
        transform: 'rotateZ(0deg)',
        transition: 'transform 0.3s',
    },
    chevronUp: {
        transform: 'rotateZ(180deg)',
        transition: 'transform 0.3s',
    },
})

// const collapsableSection = (collapse, maxHeight) => {
//     const sy = StyleSheet.create({
//         collapsed: {
//             maxHeight,
//             transition: 'max-height 0.15s linear',
//             gap: 8,
//         },
//         unCollapsed: {
//             maxHeight,
//             transition: 'max-height 0.25s linear',
//             gap: 8,
//         },
//         main: {
//             overflowY: 'hidden',
//             display: 'flex',
//             flexDirection: 'column',
//         },
//     })
//
//     if (collapse) return css(sy.collapsed, sy.main)
//     return css(sy.collapsed, sy.main)
// }
