import React from 'react'
import Modal from '../common/Modal'
import { CandidateActionTypes } from '../../utils/actions'

export const CandidateActions = ({ modal, setModal, onSuccess, setColumns }) => {
    const modalContentStyle = {
        width: '100%',
        margin: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '12px 0',
    }

    const onClose = () => {
        setModal({ act: false })
    }

    const ActionComponent = CandidateActionTypes[modal.type]

    return ActionComponent && (
        <Modal
            closeOnEscape
            open={modal.act}
            closeOnDocumentClick
            onClose={onClose}
            overlayStyle={{ top: 52, left: '5.625rem' }}
            contentStyle={modalContentStyle}>
            <ActionComponent
                id={modal.id}
                onClose={onClose}
                name={modal.name}
                after={modal.after}
                email={modal.email}
                onSuccess={onSuccess}
                setColumns={setColumns}
                cardIndex={modal.index}
                sourceColumnIndex={modal.sourceColumnIndex}
                destinationIndex={modal.destinationIndex}
            />
        </Modal>
    )
}
