import React, { useContext, useEffect, useRef } from 'react'
import { css, StyleSheet } from 'aphrodite'
import { useLocation, useNavigate } from 'react-router-dom'
import cn from 'classnames'
import { ExitSizeL, Logo, Ru, SearchForCandidate, SearchForJob } from '../svgs/Svgs'
import { Context } from '../../contexts/GlobalContext'
import { COLORS } from '../../utils/colors'
import { ExitModal } from '../settings/ExitModal'

export default function LayoutAuth({
    children,
    navigateText = 'Зарегистрироваться',
    navigatePlace = '/registration/recruiter',
}) {
    const { lang, setLang } = useContext(Context)
    const navigate = useNavigate()
    const { pathname } = useLocation()
    const exitRef = useRef()

    useEffect(() => {
        document.body.parentNode.style.backgroundColor = COLORS.white
    }, [])

    return (
        <div className={css(s.lay)}>
            <div className={css(s.login)}>
                <Logo />

                <div onClick={() => {
                    setLang(lang === 'ru' ? 'en' : 'ru')
                }} className={css(s.lang)}>
                    <div className="flex-cent">
                        <Ru /> <h3>Русский</h3>
                    </div>
                </div>

                {!pathname.endsWith('projects') && (
                    <div className="is-flex align-center justify-end gap-5 flex-1">
                        <div className={cn(css(s.box), 'is-flex gap-1')}>
                            <div className={cn(css(s.iconGreen), 'is-flex align-center justify-center pointer gap-2')}>
                                <div>
                                    <SearchForCandidate />
                                </div>

                                <h3 className={css(s.title)}>Ищу сотрудников</h3>
                            </div>

                            <a target="_blank" href={process.env.REACT_APP_JOBS_URL} rel="noreferrer">
                                <div className={cn(
                                    css(s.iconWhite),
                                    'is-flex align-center justify-center pointer gap-2',
                                )}>
                                    <div>
                                        <SearchForJob />
                                    </div>

                                    <h3 className={css(s.title)}>Ищу работу</h3>
                                </div>
                            </a>
                        </div>

                        <button
                            className={cn('pointer flex-cent', css(s.btn))}
                            onClick={() => navigate(navigatePlace)}>
                            {navigateText}
                        </button>
                    </div>
                )}

                {localStorage.token ? (
                    <div className="is-flex align-center justify-end">
                        <button
                            onClick={() => exitRef.current.onOpenModal()}
                            className={cn(css(s.logoutBtn), 'is-flex align-center gap-2 pointer')}>
                            <ExitSizeL />

                            Выход из аккаунта
                        </button>
                    </div>
                ) : null}

                <ExitModal ref={exitRef} onlyModal />
            </div>

            {children}
        </div>
    )
}

const s = StyleSheet.create({
    lay: {
        paddingBottom: 36,
        backgroundColor: COLORS.white,
        display: 'flex',
        flexDirection: 'column',
        gap: 'calc(7.25rem - 1px)',
    },
    login: {
        zIndex: 2,
        // padding: '33px 8.6% 0 8.93%',
        padding: '40px 128px 0 128px',
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'space-between',
        ':nth-child(1n) > *': {
            flex: 1,
        },
    },
    logoutBtn: {
        borderRadius: 4,
        padding: '4px 16px',
        border: '1px solid #FFD1DA',
        background: COLORS.pink,
        fontSize: 14,
        color: COLORS.lightRed,
        fontWeight: '500',
        ':hover': {
            border: '1px solid #FFC1CD',
            boxShadow: '0px 2px 4px 0px rgba(130, 130, 130, 0.05)',
        },
    },
    btn: {
        padding: '0 16px',
        height: 40,
        letterSpacing: '-0.4px',
        backgroundColor: '#EAF6FF',
        borderRadius: 4,
        border: '1px solid',
        borderColor: 'transparent',
        outline: 'none',
        color: COLORS.skyblue,
        fontSize: 14,
        fontWeight: '500',
        ':hover': {
            borderColor: COLORS.skyblue,
        },
    },
    lang: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        // width: '10rem',
        color: '#181A19C7',
        fontSize: 12,
        fontWeight: '500',
        // padding: '0 2px 11px 0',
        ':nth-child(1n) > :first-child': {
            border: '1px solid',
            borderColor: 'transparent',
            cursor: 'pointer',
            boxSizing: 'border-box',
            borderRadius: 4,
            padding: '0 16px',
            gap: '0.5rem',
            height: 30,
            ':hover': {
                borderColor: COLORS.smoothGray,
                background: COLORS.white,
                boxShadow: '0px 2px 4px rgba(168, 168, 168, 0.15)',
            },
        },
    },
    box: {
        maxWidth: 220,
        padding: 4,
        borderRadius: 4,
        border: '1px solid #EAEAEA',
        background: COLORS.white,
        boxShadow: '0px 2px 4px 0px rgba(130, 130, 130, 0.05',
    },
    title: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    iconGreen: {
        width: 32,
        height: 32,
        borderRadius: 2,
        background: COLORS.mainColor,
        transition: 'width ease 0.3s',
        ':nth-child(1n) > h3': {
            color: COLORS.white,
            fontSize: 13,
            fontWeight: '500',
        },
        ':nth-child(1n) > :first-child': {
            marginLeft: 6,
        },
        ':hover': {
            width: '11.125rem',
            ':nth-child(1n) > :first-child': {
                marginLeft: 0,
            },
        },
    },
    iconWhite: {
        width: 32,
        height: 32,
        borderRadius: 2,
        ':nth-child(1n) > h3': {
            color: COLORS.midGray,
            fontSize: 13,
            fontWeight: '500',
        },
        transition: 'width ease 0.3s',
        ':nth-child(1n) > :first-child': {
            marginLeft: 6,
        },
        ':hover': {
            width: '11.125rem',
            ':nth-child(1n) > :first-child': {
                marginLeft: 0,
            },
        },
    },
})
