import React from 'react'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import CompanyInfoForm from './CompanyInfoForm'
import { Briefcase, EditSizeL, LinkIcon, Phone } from '../../svgs/Svgs'
import { COLORS } from '../../../utils/colors'
import { LoadingSkeleton } from '../../common/LoadingSkeleton'
import { formatPhoneNumber } from '../../../utils/number'

export default function CompanyInfo({ info, loadInfo, hasPermission, edit, setEdit }) {
    return (
        <div className={css(s.info)}>
            {edit ? <CompanyInfoForm loadInfo={loadInfo} info={info} onClose={() => setEdit(false)} /> : (
                <div className="is-flex direction-column gap-5">
                    <div className={cn(css(s.titleWrap), 'align-center gap-2')}>
                        <h3 className={cn(css(s.title), 'is-flex align-center gap-2')}>
                            О компании {info.name}
                        </h3>

                        {(hasPermission && !loadInfo.loading)
                            ? <EditSizeL onClick={() => setEdit(true)} /> : null}
                    </div>

                    <div className={css(s.aboutComp)}>
                        <div className={cn(css(s.details), 'align-center gap-5')}>
                            {info.industryName && (
                                <div className={cn('align-center gap-2', css(s.tag))}>
                                    <Briefcase color={COLORS.mainColor} />

                                    <h3 className={css(s.tagTxt)}>
                                        <span>Отрасль работы:</span>

                                        {!loadInfo.loading ? (
                                            <span>
                                                {info.industryName && info.industryName.ru}
                                            </span>
                                        ) : <LoadingSkeleton height={14} />}
                                    </h3>
                                </div>
                            )}

                            {info.phoneNumber && (
                                <div className={cn('align-center gap-2', css(s.tag))}>
                                    <Phone color={COLORS.mainColor} />

                                    <h3 className={css(s.tagTxt)}>
                                        <span>Номер телефона:</span>

                                        {!loadInfo.loading ? (
                                            <a href={`tel:${info.phoneNumber}`}>
                                                {formatPhoneNumber(info.phoneNumber)}
                                            </a>
                                        ) : <LoadingSkeleton height={14} />}
                                    </h3>
                                </div>
                            )}

                            {info.link && (
                                <div className={cn('align-center gap-2', css(s.tag))}>
                                    <LinkIcon color={COLORS.mainColor} />

                                    <h3 className={css(s.tagTxt)}>
                                        <span>Веб-сайт:</span>

                                        {!loadInfo.loading ? (
                                            <a href={`https://${info.link}`}
                                                target="_blank" rel="noreferrer">
                                                {info.link}
                                            </a>
                                        ) : <LoadingSkeleton height={14} />}
                                    </h3>
                                </div>
                            )}
                        </div>

                        {info.description && (
                            <div className={css(s.descr)}>
                                <p>{info.description}</p>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    )
}

const s = StyleSheet.create({
    titleWrap: {
        height: '28px',
        ':nth-child(1n) > :last-child': {
            cursor: 'pointer',
            ':nth-child(1n) > path': {
                stroke: COLORS.mainColor,
            },
        },
    },
    tag: {
        padding: '6px 12px',
        border: `1px solid ${COLORS.lightGray}`,
        boxShadow: '0px 2px 4px rgba(130, 130, 130, 0.05)',
        borderRadius: 16,
        '@media (max-width: 768px)': {
            padding: '4px 10px',
        },
    },
    info: {
        marginTop: 24,
    },
    aboutComp: {
        display: 'flex',
        flexDirection: 'column',
        gap: 16,
    },
    details: {
        flexWrap: 'wrap',
    },
    title: {
        fontSize: 20,
        fontWeight: '700',
        color: COLORS.lightBlack,
        '@media (max-width: 768px)': {
            fontSize: 16,
        },
    },
    tagTxt: {
        fontSize: 13,
        fontWeight: '600',
        color: COLORS.lightBlack,
        ':nth-child(1n) > :first-child': {
            fontWeight: '400',
            color: COLORS.gray,
        },
        ':nth-child(2n) > :last-child': {
            textDecoration: 'none',
            color: COLORS.dark,
        },
        display: 'flex',
        alignItems: 'center',
        gap: 4,
        '@media (max-width: 768px)': {
            fontSize: 12,
        },
    },
    descr: {
        whiteSpace: 'break-spaces',
        wordBreak: 'break-word',
        fontWeight: '600',
        fontSize: 16,
        color: COLORS.lightBlack,
        '@media (max-width: 768px)': {
            fontSize: 13,
            lineHeight: '15px',
        },
    },
})
