import React, { useRef, useState } from 'react'
import cn from 'classnames'
import { MagnifierSizeM, XCircle } from '../svgs/Svgs'
import { useOutsideDetector } from '../../hooks/outsideDetector'

export default function SearchInput({
    className,
    magnifierColor = '#C9D2CE',
    withTrigger = false,
    onChange,
    keyword,
    ...args
}) {
    const wrapperRef = useRef(null)
    const inpRef = useRef(null)
    const [show, setShow] = useState(false)

    useOutsideDetector(wrapperRef, (event) => {
        if (withTrigger && wrapperRef.current && !inpRef.current.value && !wrapperRef.current.contains(event.target)) {
            wrapperRef.current.classList.add('initial-condition', 'pointer')
        }
    })

    return (
        <div
            ref={wrapperRef}
            onClick={(e) => {
                let event = e.target
                while (event && !event.classList.contains('sr-form')) {
                    event = event.parentElement
                }
                event.classList.remove('initial-condition', 'pointer')
                inpRef.current.focus()
            }}
            style={{ minHeight: 24 }}
            className={cn(
                'align-center sr-form pointer',
                { 'initial-condition': withTrigger },
                className,
            )}>
            <MagnifierSizeM color={magnifierColor} className="sr-mag" />

            <input
                ref={inpRef}
                onBlur={(e) => setShow(!!e.target.value)}
                onChange={(e) => {
                    onChange(e.target.value)
                    setShow(true)
                }}
                className="sr-inp" type="text" name="search" id="sr-inp" {...args}
            />

            {(show || keyword) && (
                <XCircle className="x-circle pointer" onClick={() => {
                    document.getElementById('sr-inp').value = ''
                    onChange('')
                }} />
            )}
        </div>
    )
}
