import React, { useState } from 'react'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import { isEmpty } from 'lodash'
import { ChevronDownS } from '../svgs/Svgs'
import { COLORS } from '../../utils/colors'
import { RangeSection } from './RangeSection'
import { CheckboxSection } from './CheckboxSection'
import Button from '../common/Button'
import { RadioSection } from './RadioSection'
import { SelectSection } from './SelectSection'
import TagsSection from './TagsSection'

export default function FilterSection({ section, setFullSection, setFilter, filter }) {
    const [isCollapsed, setIsCollapsed] = useState(false)

    const emptyChecker = typeof filter[section.name] === 'number'
        ? filter[section.name] : !isEmpty(filter[section.name])

    const strChecker = filter[section.name] === ':'

    return (
        <div className="is-flex direction-column gap-2">
            <div className="justify-between">
                <h3 className={css(s.title)}>
                    {section.title}

                    {emptyChecker && !strChecker && (
                        <span
                            onClick={() => {
                                const {
                                    [section.name]: sectionFilter,
                                    ...clearedFilter
                                } = filter
                                setFilter(clearedFilter)
                            }}
                            className="dis_sl">Очистить
                        </span>
                    )}
                </h3>

                <ChevronDownS
                    color={COLORS.dark}
                    onClick={() => setIsCollapsed(!isCollapsed)}
                    className={cn('pointer dis_sl', css(isCollapsed ? s.chevronDown : s.chevronUp))} />
            </div>

            <div className={collapsableSection(isCollapsed, isCollapsed ? 0 : 176)}>
                {/* {section.isSearchable && ( */}
                {/*     <SearchInput */}
                {/*         className={css(s.input)} */}
                {/*         placeholder={section.placeholder} */}
                {/*         magnifierColor={COLORS.midGray} /> */}
                {/* )} */}

                {section.isRadio && (
                    <RadioSection
                        name={section.name}
                        options={section.options}
                        filter={filter}
                        setFilter={setFilter} />
                )}

                {section.isCheckbox && (
                    <CheckboxSection
                        loading={section.loading}
                        name={section.name}
                        options={section.options}
                        filter={filter}
                        setFilter={setFilter} />
                )}

                {section.isTag && (
                    <TagsSection
                        tags={section.options}
                        filter={filter}
                        setFilter={setFilter} />
                )}

                {section.isRange && (
                    <RangeSection name={section.name} setFilter={setFilter} filter={filter} />
                )}

                {section.isSelect && (
                    <SelectSection
                        name={section.name}
                        options={section.options}
                        customOption={section.customSelectOption} />
                )}

                {section.meta && section.meta.totalCount > (!section.isTag ? 5 : 10) && (
                    <Button
                        className={css(s.btnMore)}
                        onClick={() => setFullSection({ act: true, section })}>
                        + ещё {section.meta.totalCount - (!section.isTag ? 5 : 10)}
                    </Button>
                )}
            </div>
        </div>
    )
}

const s = StyleSheet.create({
    title: {
        fontWeight: '600',
        fontSize: 14,
        color: COLORS.dark,
        whiteSpace: 'nowrap',
        ':nth-child(1n) > span': {
            cursor: 'pointer',
            fontSize: 10,
            marginLeft: 4,
            textTransform: 'uppercase',
            color: COLORS.skyblue,
            ':hover': {
                color: `${COLORS.skyblue}CC`,
            },
        },
    },
    dd: {
        overflow: 'hidden',
        transition: 'height 0.3s ease',
    },
    chevronDown: {
        transform: 'rotateZ(0deg)',
        transition: 'transform 0.3s',
    },
    chevronUp: {
        transform: 'rotateZ(180deg)',
        transition: 'transform 0.3s',
    },
    input: {
        width: '100%',
        height: 24,
        background: '#FAFAFA',
        border: '1px solid rgba(0, 0, 0, 0.06)',
        padding: '0 8px',
        ':nth-child(1n) > input': {
            fontSize: 11,
            paddingTop: 3,
            fontWeight: '400',
            '::placeholder': {
                color: COLORS.midGray,
            },
            color: COLORS.dark,
        },
        ':hover': {
            border: '1px solid rgba(0, 0, 0, 0.06)',
            boxShadow: 'none',
        },
    },
    btnMore: {
        width: '100%',
        background: '#FAFFFC',
        border: '1px solid #D6F0DD',
        borderRadius: 4,
        fontWeight: '400',
        fontSize: 11,
        height: 24,
        color: COLORS.mainColor,
    },
})

const collapsableSection = (collapse, maxHeight) => {
    const sy = StyleSheet.create({
        collapsed: {
            maxHeight,
            transition: 'max-height 0.15s linear',
            gap: 8,
        },
        unCollapsed: {
            maxHeight,
            transition: 'max-height 0.25s linear',
            gap: 8,
        },
        main: {
            overflowY: 'hidden',
            display: 'flex',
            flexDirection: 'column',
            height: 'auto',
            gap: 8,
        },
    })

    if (collapse) return css(sy.collapsed, sy.main)
    return css(sy.collapsed, sy.main)
}
