import React from 'react'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import { COLORS } from '../../utils/colors'

export default function ScrollAnimation({
    contClassName,
    headerClassName,
    bodyClassName,
    bodyChildren,
    headerChildren,
    style,
}) {
    const onScrollHandler = (e) => {
        const header = document.querySelector('#header')

        if (e.target.scrollTop > 0) {
            header.classList.add(css(s.onScroll))
        } else {
            header.classList.remove(css(s.onScroll))
        }
    }

    return (
        <div style={style} className={contClassName}>
            <div id="header" className={cn('is-relative z-index-1', headerClassName)}>{headerChildren}</div>

            <div onScroll={onScrollHandler} className={bodyClassName}>
                {bodyChildren}
            </div>
        </div>
    )
}

const s = StyleSheet.create({
    onScroll: {
        borderBottom: `1px solid ${COLORS.lightGray}`,
        '-webkit-box-shadow': '0px 6px 5px -2px rgba(34, 60, 80, 0.2)',
        '-moz-box-shadow': '0px 6px 5px -2px rgba(34, 60, 80, 0.2)',
        boxShadow: '0px 6px 5px -2px rgba(34, 60, 80, 0.2)',

        padding: 24,
        '@media (max-width: 768px)': {
            padding: 0,
        },
    },
})
