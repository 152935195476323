import React from 'react'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import DOMPurify from 'isomorphic-dompurify'
import { COLORS } from '../../utils/colors'

export default function CandidateCoverLetter({ info }) {
    const clean = DOMPurify.sanitize(info.coverLetter)

    return (
        <div className={cn('is-flex direction-column gap-7', css(s.cont))}>
            {info.coverLetter ? (
                <div className={css(s.box)}>
                    {/* eslint-disable-next-line react/no-danger */}
                    <div dangerouslySetInnerHTML={{ __html: clean }} />
                </div>
            ) : (
                <div className="flex-cent flex-1">
                    <h3 style={{ color: COLORS.midGray, fontWeight: '400', fontSize: 22 }}>Пусто</h3>
                </div>
            )}
        </div>
    )
}

const s = StyleSheet.create({
    cont: {
        padding: '16px 24px',
        overflowY: 'auto',
        height: '100%',
    },
    box: {
        background: '#FFFFFF',
        border: '1px solid #E0E0E0',
        boxShadow: '0px 2px 4px rgba(130, 130, 130, 0.05)',
        borderRadius: 4,
        padding: '16px 20px',
        fontWeight: '400',
        fontSize: 14,
        color: '#181A19',
        wordWrap: 'break-word',
    },
})
