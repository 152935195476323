import React from 'react'
import { css, StyleSheet } from 'aphrodite'
import ToggleSwitch from '../../common/ToggleSwitch'
import { COLORS } from '../../../utils/colors'

export default function ControlSettings({ companyInfo, handleToggleChange }) {
    return (
        <div className={css(s.popUpWrap)}>
            {/* <h3 className={css(s.popupTitle)}>Доступ к этой странице имеют:</h3> */}

            {/* <div className={css(s.itemsWrap)}> */}
            {/*     <div className="justify-between"> */}
            {/*         <h3>Администраторы</h3> */}

            {/*         <ToggleSwitch */}
            {/*             name="administrator" */}
            {/*             isChecked={companyInfo.administrator} */}
            {/*             onClick={() => handleToggleChange('administrator', !companyInfo.administrator)} */}
            {/*         /> */}
            {/*     </div> */}

            {/*     <div className="justify-between"> */}
            {/*         <h3>Рекрутеры</h3> */}

            {/*         <ToggleSwitch */}
            {/*             name="recruiter" */}
            {/*             isChecked={companyInfo.recruiter} */}
            {/*             onClick={() => handleToggleChange('recruiter', !companyInfo.recruiter)} */}
            {/*         /> */}
            {/*     </div> */}

            {/*     <div className="justify-between"> */}
            {/*         <h3>Сотрудники компании</h3> */}

            {/*         <ToggleSwitch */}
            {/*             name="employee" */}
            {/*             isChecked={companyInfo.employee} */}
            {/*             onClick={() => handleToggleChange('employee', !companyInfo.employee)} */}
            {/*         /> */}
            {/*     </div> */}
            {/* </div> */}

            {/* <div className={css(s.divider)} /> */}

            <h3 className={css(s.popupTitle)}>Публикуется:</h3>

            <div className={css(s.itemsWrap)}>
                <div className="justify-between">
                    <h3>О компании</h3>

                    <ToggleSwitch
                        name="aboutCompany"
                        isChecked={companyInfo.aboutCompany}
                        onClick={() => handleToggleChange('aboutCompany', !companyInfo.aboutCompany)}
                    />
                </div>

                <div className="justify-between">
                    <h3>Вакансии</h3>

                    <ToggleSwitch
                        name="vacancy"
                        isChecked={companyInfo.vacancy}
                        onClick={() => handleToggleChange('vacancy', !companyInfo.vacancy)}
                    />
                </div>
            </div>
        </div>
    )
}

const s = StyleSheet.create({
    popUpWrap: {
        width: '18.188rem',
        background: COLORS.lightBlack,
        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.25)',
        borderRadius: 4,
        padding: '12px 12px 18px 12px',
        userSelect: 'none',
    },
    itemsWrap: {
        width: '100%',
        marginTop: 14,
        display: 'flex',
        flexDirection: 'column',
        gap: 20,
        fontWeight: '500',
        fontSize: 14,
        color: COLORS.white,
    },
    divider: {
        background: 'rgba(99, 99, 99, 0.9)',
        backdropFilter: 'blur(4px)',
        height: 1,
        margin: '22px 0 16px 0',
    },
    popupTitle: {
        fontWeight: '500',
        fontSize: 11,
        textTransform: 'uppercase',
        color: '#F7C11D',
    },
})
