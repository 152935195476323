import React, { memo } from 'react'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import { Briefcase } from '../../svgs/Svgs'
import InputForm from '../../common/InputForm'
import { COLORS } from '../../../utils/colors'

function MemoizedComponent() {
    return (
        <div className={css(s.box)}>
            <div className={cn('justify-between', css(s.formName))}>
                <div className="align-center gap-2">
                    <Briefcase />

                    <h3>Трудовые обязанности</h3>
                </div>
            </div>

            <div className={cn('is-flex direction-column gap-7', css(s.cont))}>
                <InputForm
                    name="description"
                    component="textarea"
                    placeholder="Введите"
                    labelClassName={css(s.lbAboutEvent)}
                    className={cn(css(s.inp), css(s.commentAreaField))}
                />
            </div>
        </div>
    )
}

export const WorkingObligations = memo(MemoizedComponent)

const s = StyleSheet.create({
    box: {
        background: COLORS.white,
        border: `1px solid ${COLORS.smoothGray}`,
        boxShadow: '0px 2px 4px rgba(130, 130, 130, 0.05)',
        borderRadius: 4,
    },
    formName: {
        height: 'calc(3rem - 1px)',
        padding: '0 22px 0 24px',
        fontSize: 13,
        fontWeight: '600',
        color: '#181A19',
        borderBottom: `1px solid ${COLORS.lightGray}`,
        textTransform: 'uppercase',
    },
    cont: {
        padding: '15px 1.5rem 1.5rem',
    },
    wrap: {
        fontWeight: '500',
        fontSize: 12,
        color: COLORS.midGray,
    },
    tags: {
        flexWrap: 'wrap',
    },
    tag: {
        padding: '5px 8px',
        background: 'rgba(46, 169, 125, 0.03)',
        border: '1px solid rgba(46, 169, 125, 0.2)',
        borderRadius: 4,
        color: COLORS.mainColor,
        fontWeight: '500',
        fontSize: 11,
        outline: 'none',
    },
    inp: {
        width: '100%',
        border: `1px solid ${COLORS.lightGray}`,
        borderRadius: 4,
        padding: '14px 16px',
        ':first-of-type': {
            paddingTop: 15,
        },
    },
    commentAreaField: {
        height: 'calc(8.5rem - 25px)',
    },
    lbAboutEvent: {
        paddingBottom: 8,
    },
})
