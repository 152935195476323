import moment from 'moment'
// eslint-disable-next-line import/no-cycle
import { dateRangeFilter } from './filter'

export const DATE_FORMAT = {
    yearFirst: 'YYYY-MM-DD',
    dayFirst: 'DD MMMM YYYY',
}

export const DATETIME_FORMAT = 'YYYY.MM.DD HH:mm'

export const weekdaysShort = [
    { id: 1, key: 'Mon', title: 'Понедельник', shortTitle: 'ПН' },
    { id: 2, key: 'Tue', title: 'Вторник', shortTitle: 'ВТ' },
    { id: 3, key: 'Wed', title: 'Среда', shortTitle: 'СР' },
    { id: 4, key: 'Thu', title: 'Четверг', shortTitle: 'ЧТ' },
    { id: 5, key: 'Fri', title: 'Пятница', shortTitle: 'ПТ' },
    { id: 6, key: 'Sat', title: 'Суббота', shortTitle: 'СБ' },
    { id: 0, key: 'Sun', title: 'Воскресенье', shortTitle: 'ВС' },
]

export const ruMonth = [
    { id: 1, key: 'Jan', title: 'Январь', shortTitle: 'ЯНВ' },
    { id: 2, key: 'Feb', title: 'Февраль', shortTitle: 'ФЕВ' },
    { id: 3, key: 'Mar', title: 'Март', shortTitle: 'МАР' },
    { id: 4, key: 'Apr', title: 'Апрель', shortTitle: 'АПР' },
    { id: 5, key: 'May', title: 'Май', shortTitle: 'МАЯ' },
    { id: 6, key: 'Jun', title: 'Июнь', shortTitle: 'ИЮН' },
    { id: 7, key: 'Jul', title: 'Июль', shortTitle: 'ИЮЛ' },
    { id: 8, key: 'Aug', title: 'Август', shortTitle: 'АВГ' },
    { id: 9, key: 'Sep', title: 'Сентябрь', shortTitle: 'СЕН' },
    { id: 10, key: 'Oct', title: 'Октябрь', shortTitle: 'ОКТ' },
    { id: 11, key: 'Nov', title: 'Ноябрь', shortTitle: 'НОЯ' },
    { id: 12, key: 'Dec', title: 'Декабрь', shortTitle: 'ДЕК' },
]

export function getCertainDayOfWeek(date, day = 0) {
    const current = new Date(date.getTime())
    current.setDate((current.getDate() - (current.getDay() !== 0 ? current.getDay() : 7) + (day + 1)))
    return current // default return first day of week
}

export function weekDays(data) {
    const week = []
    const current = getCertainDayOfWeek(data)

    for (let i = 0; i < 7; i += 1) {
        week.push({
            date: new Date(current).getDate(),
            week: weekdaysShort.filter((e) => e.key === new Date(current).toDateString().split(' ')[0])[0].shortTitle,
            month: ruMonth.filter((e) => e.key === new Date(current).toDateString().split(' ')[1])[0].shortTitle,
            additional: new Date(current),
        })
        current.setDate(current.getDate() + 1)
    }

    return week
}

export function getWeekDays(date) {
    const week = weekDays(date)

    return `${week.at(0).date} ${week.at(0).month.toLowerCase()}`
        + ` - ${week.at(-1).date} ${week.at(-1).month.toLowerCase()}`
}

export function getMonth(date = new Date(), withYear = false) {
    const format = withYear ? 'MMMM YYYY' : 'MMMM'
    const month = moment(date).format(format)
    return (month)[0].toUpperCase() + (month).slice(1)
}

export function getDateTime(date = new Date()) {
    return moment(date).format(DATETIME_FORMAT)
}

export function getDayOfWeek(date = new Date(), last = false, withFormat = true) {
    const curr = new Date(date.getTime())
    const firstDate = curr.setDate((curr.getDate() - (curr.getDay() !== 0 ? curr.getDay() : 7) + 1))
    const lastDate = curr.setDate((curr.getDate() - (curr.getDay() !== 0 ? curr.getDay() : 7) + 7))
    return withFormat
        ? moment(!last ? firstDate : lastDate).format(DATE_FORMAT.yearFirst)
        : new Date(!last ? firstDate : lastDate)
}

export function getFullDate(date = new Date(), dayFirst = false) {
    return moment(date).format(!dayFirst ? DATE_FORMAT.yearFirst : DATE_FORMAT.dayFirst)
}

export function timeFormatter(num, totalLength) {
    return String(num).padStart(totalLength, '0')
}

export function getDatesInRange(startDate, endDate) {
    const date = new Date(startDate.getTime())

    const dates = []

    while (date <= endDate) {
        dates.push(new Date(date))
        date.setDate(date.getDate() + 1)
    }

    return dates
}

export function getDateRangeByKey(id) {
    const dateRange = dateRangeFilter.find((range) => range.id === id)
    if (!dateRange) {
        return null // id not found in dateRangeFilter
    }

    const today = new Date()
    const year = today.getFullYear()
    const month = today.getMonth()

    switch (dateRange.key) {
    case 'today':
        return [today]
    case 'week': {
        const startOfWeek = new Date(year, month, today.getDate() - today.getDay() + 1)
        const endOfWeek = new Date(year, month, startOfWeek.getDate() + 6)
        return [startOfWeek, endOfWeek]
    }
    case 'month': {
        const startOfMonth = new Date(year, month, 1)
        const endOfMonth = new Date(year, month + 1, 0)
        return [startOfMonth, endOfMonth]
    }
    case 'lastMonth': {
        const startOfLastMonth = new Date(year, month - 1, 1)
        const endOfLastMonth = new Date(year, month, 0)
        return [startOfLastMonth, endOfLastMonth]
    }
    default:
        return null // invalid key
    }
}
