import React from 'react'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import moment from 'moment'
import { isEmpty } from 'lodash'
import { ThreeDotsMenu, ZoomCancel, UserCheckSizeS } from '../svgs/Svgs'
import { LoadingSkeleton } from '../common/LoadingSkeleton'
import { COLORS } from '../../utils/colors'

function Skeleton() {
    return (
        <tr className={css(s.loadItem)}>
            <td className={css(s.colItem)}>
                <div className={cn('align-start direction-column gap-1')}>
                    <LoadingSkeleton height={15} width="10rem" />

                    <LoadingSkeleton height={13} width="8rem" />
                </div>
            </td>

            <td><LoadingSkeleton height={20} width="50%" /></td>

            <td><LoadingSkeleton height={20} width="50%" /></td>

            <td><LoadingSkeleton height={20} width="50%" /></td>

            <td><LoadingSkeleton height={20} width="50%" /></td>

            <td className={css(s.colItem)}>
                <div className={cn('align-center gap-2')}>
                    <LoadingSkeleton width={24} height={24} borderRadius="50%" />

                    <LoadingSkeleton height={15} width="12rem" />
                </div>
            </td>

            <td className={css(s.colItem)}>
                <div className="justify-end">
                    <div className={css(s.menuItem)}>
                        <ThreeDotsMenu className="pointer" />
                    </div>
                </div>
            </td>
        </tr>
    )
}

export default function VacancyTableArchiveItem({ item, loading }) {
    const imgSrc = !isEmpty(item.recruiter) && item.recruiter.photo[6]

    return !loading ? (
        <tr className={css(s.item)}>
            <td className={css(s.colItem)}>
                <h3>{item.vacancyName}</h3>

                <div title={item.department.name} className="align-center gap-1">
                    <h3>{item.department.name}</h3>
                </div>
            </td>

            <td>
                {item.closed
                    ? <div className="align-center gap-2"><ZoomCancel /> Вакансия закрыта</div>
                    : (
                        <div className="align-center gap-2">
                            <UserCheckSizeS size={16} color="#000" /> Деактивирована
                        </div>
                    )}
            </td>

            <td>{item.acceptedCandidates} / {item.employeeCount}</td>

            <td>{moment(item.createdAt).format('DD MMMM YYYY')}</td>

            <td>
                {item.archivedAt
                    ? moment(item.archivedAt).format('DD MMMM YYYY')
                    : <div>&#8212;&#8212;&#8212;</div>}
            </td>

            <td>
                {!isEmpty(item.recruiter) ? (
                    <div className="align-center gap-2">
                        {imgSrc ? (
                            <img className={css(s.img)} src={imgSrc} alt="#recImg" />
                        ) : (
                            <div className={cn('flex-cent', css(s.img, s.defaultAvatar))}>
                                {item.recruiter.firstName.slice(0, 1) + item.recruiter.lastName.slice(0, 1)}
                            </div>
                        )}

                        <h3>{`${item.recruiter.firstName} ${item.recruiter.lastName}`}</h3>
                    </div>
                ) : <div>&#8212;&#8212;&#8212;</div>}
            </td>

            <td className={css(s.colItem)}>
                {/* <div className="justify-end"> */}
                {/*    <Popup */}
                {/*        items={popupItems} */}
                {/*        trigger={( */}
                {/*            <div className={css(s.menuItem)}> */}
                {/*                <ThreeDotsMenu className="pointer" /> */}
                {/*            </div> */}
                {/*        )} */}
                {/*        renderItem={(e) => ( */}
                {/*            <li */}
                {/*                key={e.id} */}
                {/*                onClick={(event) => { */}
                {/*                    event.stopPropagation() */}
                {/*                    e.onClick(item.id, `${item.firstName} ${item.lastName}`) */}
                {/*                }} */}
                {/*                className={cn('justify-between', css(s.cardSetting))}> */}
                {/*                <div className="align-center gap-2"> */}
                {/*                    <e.icon /> {e.value} */}
                {/*                </div> */}

                {/*                {e.navigate ? <ChevronNext /> : null} */}
                {/*            </li> */}
                {/*        )} */}
                {/*    /> */}
                {/* </div> */}
            </td>
        </tr>
    ) : <Skeleton />
}

const s = StyleSheet.create({
    status: {
        display: 'inline-flex',
        alignItems: 'center',
        borderRadius: 4,
        padding: '4.5px 8px',
        fontSize: 11,
        fontWeight: '500',
    },
    item: {
        height: '3.75rem',
        background: COLORS.white,
        color: '#181A19',
        fontSize: 13,
        fontWeight: '500',
        wordBreak: 'break-word',
        ':nth-child(1n) > :last-child': {
            paddingRight: '1rem',
        },
    },
    cardSetting: {
        width: '11rem',
        ':nth-child(1n) > :first-child': {
            ':nth-child(1n) > :first-child': {
                ':nth-child(1n) > *': {
                    stroke: '#CCCDCD',
                },
            },
        },
    },
    menuItem: {
        width: 20,
        height: 20,
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'center',
    },
    loadItem: {
        height: '3.75rem',
        background: '#FFFFFF',
        ':nth-child(1n) > :last-child': {
            paddingRight: '1rem',
        },
    },
    colItem: {
        ':first-of-type': {
            paddingLeft: '1.5rem',
            ':nth-child(1n) > :last-child': {
                fontWeight: '400',
                fontSize: 11,
                color: '#6D7471',
            },
        },
        ':last-of-type': {
            textAlign: 'right',
        },
    },
    tag: {
        height: 24,
        padding: '0 6px',
        borderRadius: 4,
    },
    img: {
        width: 24,
        height: 24,
        borderRadius: 128,
        border: `1px solid ${COLORS.smoothGray}`,
        boxSizing: 'border-box',
    },
    defaultAvatar: {
        background: COLORS.blue,
        color: '#FFF',
        fontSize: 9,
        fontWeight: '500',
    },
})
