import React from 'react'
import { css, StyleSheet } from 'aphrodite'
import uuid from 'react-uuid'
import { AvailableLanguages, LanguageLevels } from '../../../../utils/options'
import { COLORS } from '../../../../utils/colors'

export default function Languages({ languages }) {
    return (
        <div className={css(s.langInfo)}>
            <h3>Знание языков:</h3>

            <div key={uuid()} className={css(s.listWrap)}>
                <ul className="no_dot_list">
                    {languages.map(({ language, level }) => (
                        <li>
                            <h3>
                                {'•  '}
                                {AvailableLanguages.find((e) => e.short === language).ru}
                                {' язык'}
                            </h3>

                            {' - '}

                            <h3>
                                {level} ({LanguageLevels.find((e) => e.level === level).ru})
                            </h3>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    )
}

const s = StyleSheet.create({
    langInfo: {
        display: 'flex',
        flexDirection: 'column',
        ':nth-child(1n) > h3': {
            fontWeight: '600',
            fontSize: 20,
            color: COLORS.dark,
            marginBottom: 12,
            '@media (max-width: 768px)': {
                fontSize: 16,
            },
        },
    },
    listWrap: {
        paddingLeft: 2,
        fontWeight: '500',
        fontSize: 14,
        color: '#343836',
        '@media (max-width: 768px)': {
            fontSize: 12,
        },
    },
})
