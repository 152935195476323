import React from 'react'
import cn from 'classnames'
import { css, StyleSheet } from 'aphrodite'
import { CandidateExperience, CandidateInfo, StatusAndSource } from './CardInfo'
import { COLORS } from '../../../utils/colors'
import { MarkNew } from '../../svgs/Svgs'

export default function CardNew({ data, isDragging, setModal, setColumns, index }) {
    const { isNew } = data.params

    return (
        <div
            className={cn(css(s.wrap, isDragging && s.onDrag, isNew && s.isNew))}
            onClick={() => {
                if (data.params.isNew) {
                    setColumns((prev) => {
                        const newColumns = { ...prev }
                        newColumns[0].cards[index].params.isNew = false
                        return newColumns
                    })
                }
                setModal({ act: true, id: data.id, type: 'detail' })
            }}
        >
            <div className="is-flex gap-2 direction-column">
                <CandidateInfo data={data} />

                <div className={cn('is-flex direction-column gap-3')}>
                    <CandidateExperience data={data} />

                    {!isNew ? (
                        <StatusAndSource data={data} />
                    ) : (
                        <div>
                            <span className="justify-end">
                                <MarkNew />
                            </span>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

const s = StyleSheet.create({
    wrap: {
        backgroundColor: '#fff',
        padding: '12px 8px 8px 12px',
        cursor: 'pointer',
        borderRadius: 4,
        border: `1px solid ${COLORS.smoothGray}`,
        boxShadow: '0px 2px 4px rgba(136, 136, 136, 0.25)',
        ':hover': {
            border: `1px solid ${COLORS.lightGray}`,
            boxShadow: '0px 2px 4px rgba(136, 136, 136, 0.35)',
        },
    },
    isNew: {
        boxShadow: '0px 0px 16px 0px rgba(173, 231, 210, 0.25)',
        border: `1px solid ${COLORS.mainColor}`,
        ':hover': {
            border: `1px solid ${COLORS.mainColor}`,
        },
    },
    onDrag: {
        background: 'rgba(255, 255, 255, 0.1)',
        border: `1px solid ${COLORS.skyblue}`,
        boxShadow: '0px 4px 16px rgba(0, 113, 218, 0.4)',
        backdropFilter: 'blur(4px)',
    },
})
