import en from './locales/en.json'
import ru from './locales/ru.json'

const translations = {
    en,
    ru,
}

export function translationsMessage(lang) {
    return translations[lang]
}
