import React from 'react'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import { COLORS } from '../../utils/colors'
import { AddTable } from '../svgs/SvgPictures'

export default function NoVacanciesByFilter() {
    return (
        <div className={css(s.cont)}>
            <div className={cn('align-center', css(s.header))}>
                <h3 className={css(s.topic)}>0 вакансий</h3>
            </div>

            <div className={cn('flex-cent direction-column gap-7', css(s.body))}>
                <AddTable />

                <div className="is-flex direction-column gap-1">
                    <h3 className={cn('text-centered', css(s.topic))}>По выбранным вами фильтрам нет данных</h3>

                    <h3 className={cn('text-centered', css(s.desc))}>Попробуйте ввести в фильтр другие параметры</h3>
                </div>
            </div>
        </div>
    )
}

const s = StyleSheet.create({
    cont: {
        backgroundColor: COLORS.white,
    },
    header: {
        height: 40,
        boxSizing: 'border-box',
        borderRadius: '4px 4px 0px 0px',
        borderBottom: `1px solid${COLORS.lightGray}`,
        padding: '0 16px',
    },
    topic: {
        color: COLORS.dark,
        fontSize: 16,
        fontWeight: '600',
    },
    body: {
        padding: '24px 0 29px 0',
    },
    desc: {
        color: COLORS.midGray,
        fontSize: 12,
        fontWeight: '500',
    },
})
