import React, { memo } from 'react'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import { LightingSizeM, MapPinSizeL } from '../../svgs/Svgs'
import SelectForm from '../../common/SelectForm'
import FormInput from '../../common/FormInput'
import ToggleSwitch from '../../common/ToggleSwitch'
import { COLORS } from '../../../utils/colors'
import { useLoad } from '../../../hooks/request'
import { EMPLOYEE_LIST } from '../../../urls'

function MemoizedComponent({ values, setFieldValue }) {
    const loadEmployees = useLoad({ url: EMPLOYEE_LIST })

    const employees = loadEmployees.response
        ? loadEmployees.response.data.map((e) => ({ value: e.id, label: `${e.firstName} ${e.lastName}` }))
        : []

    return (
        <div className={css(s.box)}>
            <div className={cn('justify-between', css(s.formName))}>
                <div className="align-center gap-2">
                    <LightingSizeM className={css(s.icon)} />

                    <h3>Дополнительно</h3>
                </div>
            </div>

            <div className={css(s.inpGr)}>
                <FormInput
                    type="number"
                    name="holiday"
                    placeholder="Введите количество"
                    className={css(s.addInp)}
                    label="Количество отпускных дней"
                    fieldClassName={css(s.fld)}
                    labelClassName={css(s.addInpLab)} />

                <FormInput
                    type="number"
                    name="sick"
                    placeholder="Введите количество"
                    className={css(s.addInp)}
                    label="Количество больничных дней"
                    fieldClassName={css(s.fld)}
                    labelClassName={css(s.addInpLab)} />

                <SelectForm
                    labelContClassName={css(s.lab)}
                    placeholderStyles={{ color: COLORS.gray, fontFamily: 'Roboto, sans-serif' }}
                    labelClassName={css(s.lb)}
                    valueContainerStyles={{ padding: '11.5px 16px' }}
                    indicatorStyle={{ paddingRight: 10 }}
                    label="Руководитель"
                    placeholder="Выберите сотрудника"
                    name="director"
                    options={employees} />

                <SelectForm
                    labelContClassName={css(s.lab)}
                    placeholderStyles={{ color: COLORS.gray, fontFamily: 'Roboto, sans-serif' }}
                    labelClassName={css(s.lb)}
                    valueContainerStyles={{ padding: '11.5px 16px' }}
                    indicatorStyle={{ paddingRight: 10 }}
                    label="Подчиненные"
                    placeholder="Выберите сотрудников"
                    name="headOfEmployee"
                    options={employees} />

                <FormInput
                    name="location"
                    icon={<MapPinSizeL />}
                    placeholder="Введите адрес"
                    className={css(s.addInp)}
                    label="Расположение офиса"
                    fieldClassName={css(s.fld)}
                    labelClassName={css(s.addInpLab)} />

                <div className={cn('justify-between', css(s.switchBox))}>
                    <h3>Возможность удаленной работы</h3>
                    <ToggleSwitch
                        name="remote"
                        isChecked={values.remote}
                        onClick={() => setFieldValue('remote', !values.remote)}
                    />
                </div>
            </div>
        </div>
    )
}

export const More = memo(MemoizedComponent)

const s = StyleSheet.create({
    box: {
        background: COLORS.white,
        border: `1px solid ${COLORS.smoothGray}`,
        boxShadow: '0px 2px 4px rgba(130, 130, 130, 0.05)',
        borderRadius: 4,
    },
    formName: {
        height: 'calc(3rem - 1px)',
        padding: '0 22px 0 24px',
        fontSize: 13,
        fontWeight: '600',
        color: '#181A19',
        borderBottom: `1px solid ${COLORS.lightGray}`,
        textTransform: 'uppercase',
    },
    inpGr: {
        padding: '16px 24px 24px',
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gridGap: 24,
        alignItems: 'end',
    },
    lb: {
        fontSize: 12,
        fontWeight: '500',
        color: COLORS.midGray,
    },
    addInp: {
        position: 'relative',
        border: `1px solid ${COLORS.lightGray}`,
        borderRadius: 4,
        fontSize: 14,
        fontWeight: '500',
        color: '#181A19',
    },
    addInpLab: {
        fontSize: 12,
        fontWeight: '500',
        color: COLORS.midGray,
        marginBottom: 8,
    },
    fld: {
        position: 'relative',
        // padding
    },
    lab: {
        ':nth-child(1n) > span': {
            display: 'inline-flex',
        },
    },
    switchBox: {
        padding: '13px 16px',
        border: `1px solid ${COLORS.lightGray}`,
        borderRadius: 4,
        fontWeight: '500',
        fontSize: 14,
        color: COLORS.gray,
    },
    switchCont: {
        gridColumn: '1 / 3',
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gridGap: 24,
        alignItems: 'end',
        height: '4.125rem',
    },
    icon: {
        ':nth-child(1n) > *': {
            stroke: '#181A19',
        },
    },
})
