import React from 'react'
import RecruiterForm from './RecruiterForm'
import SidebarLayout from '../../common/SidebarLayout'
import { usePutRequest } from '../../../hooks/request'
import { SET_ROLE } from '../../../urls'
import { useMessage } from '../../../hooks/message'

export default function RecruiterCreate({ onClose, onGoBack, goBack, onSuccess }) {
    const update = usePutRequest({ url: SET_ROLE })
    const [showMessage] = useMessage()

    const onSubmit = ({ employeeIds }) => {
        update.request({ data: {
            employeeIds: employeeIds.map((e) => e.value),
            role: 3,
        } }).then(() => {
            onSuccess(employeeIds)
            showMessage('Успешно!', 'success-msg')
        }).catch((err) => showMessage(err.response.data && err.response.data.errors[0].message, 'success-msg'))
    }

    return (
        <SidebarLayout
            goBack={goBack}
            onClose={onClose}
            onGoBack={onGoBack}
            title="Добавить рекрутера">
            <RecruiterForm onClose={onClose} onSubmit={onSubmit} />
        </SidebarLayout>
    )
}
