import React from 'react'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import CandidateShortInfo from './CandidateShortInfo'
import CandidateInfo from './CandidateInfo'
import { useLoad } from '../../hooks/request'
import { CANDIDATE_INFO } from '../../urls'

export default function CandidateDetail({ onClose, id, setModal }) {
    const loadInfo = useLoad({ url: CANDIDATE_INFO.replace('{id}', id) })
    const info = loadInfo.response ? loadInfo.response.data : {}

    return (
        <div className={cn('is-flex', css(s.modal_body))}>
            <CandidateShortInfo loading={loadInfo.loading} setModal={setModal} info={info} />

            <CandidateInfo info={info} onClose={onClose} />
        </div>
    )
}

const s = StyleSheet.create({
    modal_body: {
        background: '#FFF',
        borderRadius: 4,
        height: '100%',
        width: '76.8%',
        overflow: 'hidden',
    },
})
