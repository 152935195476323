import React, { useState } from 'react'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import { useNavigate, useParams } from 'react-router-dom'
import { isEmpty } from 'lodash'
import Layout from '../components/common/Layout'
import { ChevronNextSizeL, DefaultLocationSizeS, EditPencil, Share } from '../components/svgs/Svgs'
import ScrollAnimation from '../components/common/ScrollAnimation'
import { COLORS } from '../utils/colors'
import Modal from '../components/common/Modal'
import ShareModal from '../components/settings/companyPublic/ShareModal'
import Button from '../components/common/Button'
import DetailLoading from '../components/settings/companyPublic/vacancies/DetailLoading'
import { useLoad } from '../hooks/request'
import { VACANCY_INFO } from '../urls'
import Description from '../components/settings/companyPublic/vacancies/Description'
import Languages from '../components/settings/companyPublic/vacancies/Languages'
import BaseInfo from '../components/settings/companyPublic/vacancies/BaseInfo'
import KeySkills from '../components/vacancies/preview/KeySkills'
import MoreOptions from '../components/vacancies/preview/MoreOptions'
import Location from '../components/vacancies/preview/Location'

export default function VacancyDetailPublic() {
    const params = useParams()
    const navigate = useNavigate()
    const [showModal, setShowModal] = useState(false)
    const loadVacancy = useLoad({ url: VACANCY_INFO.replace('{id}', `${params.id}`) })
    const vacancy = loadVacancy.response ? loadVacancy.response.data : {}

    const vacancySlug = vacancy.slug
    const companySlug = vacancy.company && vacancy.company.slug

    return (
        <Layout padded>
            <div className={css(s.mainWrap)}>
                <div
                    onClick={() => navigate(-1)}
                    className={css(s.chevronWrap)}>
                    <ChevronNextSizeL />
                </div>

                {!loadVacancy.loading && Object.values(vacancy).length ? (
                    <ScrollAnimation
                        contClassName={css(s.infoWrap)}
                        bodyClassName={css(s.vacDescBody)}
                        headerClassName={css(s.contHeader)}
                        headerChildren={(
                            <>
                                <div className={css(s.flxBtw)}>
                                    <div className="is-flex direction-column gap-2 flex-1">
                                        <h3 className={css(s.vacancyName)}>
                                            {vacancy.name}
                                        </h3>

                                        <div className={cn('align-center gap-2', css(s.companyName))}>
                                            {vacancy.company.logo.length ? (
                                                <div className={css(s.logoCont)}>
                                                    <img
                                                        alt="#CompanyLogo"
                                                        src={vacancy.company.logo[1]}
                                                    />
                                                </div>
                                            ) : (
                                                <div className={css(s.defaultLogo)}>
                                                    <DefaultLocationSizeS color={COLORS.white} />
                                                </div>
                                            )}

                                            <p className={css(s.companyName)}>{vacancy.company.name}</p>
                                        </div>
                                    </div>

                                    <div className={cn('align-center gap-5', css(s.btnGrp))}>
                                        <Modal
                                            trigger={(
                                                <button className={css(s.shareBtn)}><Share /></button>
                                            )}
                                            open={showModal}
                                            closeOnEscape
                                            overlayStyle={{ top: 52 }}
                                            closeOnDocumentClick
                                            onClose={() => setShowModal(false)}
                                            onOpen={() => setShowModal(true)}
                                        >
                                            <ShareModal
                                                shareLink={
                                                    `${process.env.REACT_APP_JOBS_URL}/c/${companySlug}/${vacancySlug}`
                                                }
                                                onCLose={() => setShowModal(false)}
                                            />
                                        </Modal>

                                        <Button
                                            className={css(s.editBtn)}
                                            onClick={() => navigate(`/vacancies/${params.id}/form/update?mode=edit`)}
                                        >
                                            <EditPencil /> Редактировать вакансию
                                        </Button>
                                    </div>
                                </div>
                            </>
                        )}
                        bodyChildren={(
                            <React.Fragment>
                                <BaseInfo vacancy={vacancy} />

                                {vacancy.description ? (
                                    <Description text={vacancy.description} />
                                ) : null}

                                {(vacancy.languageLevel && vacancy.languageLevel.length) ? (
                                    <Languages languages={vacancy.languageLevel} />
                                ) : null}

                                {!isEmpty(vacancy) && (!!vacancy.skill.length || !!vacancy.knowledge.length) && (
                                    <KeySkills values={{ skills: vacancy.skill, knowledge: vacancy.knowledge }} />
                                )}

                                {vacancy.location && !!vacancy.location.id && (
                                    <Location
                                        values={{ location: {
                                            label: vacancy.location.name,
                                            lng: +vacancy.location.longitude,
                                            lat: +vacancy.location.latitude,
                                        } }} />
                                )}

                                {!isEmpty(vacancy.options) && <MoreOptions values={vacancy} />}

                                <div className={css(s.mobileBtn)}>
                                    <Button
                                        className={css(s.editBtn)}
                                        onClick={() => navigate(`/vacancies/${params.id}/form/update?mode=edit`)}
                                    >
                                        <EditPencil /> Редактировать вакансию
                                    </Button>
                                </div>
                            </React.Fragment>
                        )}
                    />
                ) : <DetailLoading />}
            </div>
        </Layout>
    )
}

const s = StyleSheet.create({
    mainWrap: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'center',
        margin: '24px 32px 32px 32px',
        '@media (max-width: 1200px)': {
            flexDirection: 'column-reverse',
            gap: 16,
            margin: '16px 24px 24px 24px',
        },
        '@media (max-width: 768px)': {
            margin: '16px 0',
        },
    },
    companyName: {
        fontWeight: '500',
        fontSize: 14,
        color: COLORS.midGray,
    },
    defaultLogo: {
        width: 16,
        height: 16,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        border: `1px solid ${COLORS.white}`,
        background: COLORS.mainColor,
        borderRadius: 4,
        boxSizing: 'border-box',
    },
    logoCont: {
        width: 16,
        height: 16,
        border: `1px solid ${COLORS.white}`,
        borderRadius: 2,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        boxSizing: 'border-box',
        ':nth-child(1n) > img': {
            width: 'inherit',
            borderRadius: 4,
        },
    },
    infoWrap: {
        flex: 2.5,
        background: COLORS.white,
        border: `1px solid ${COLORS.smoothGray}`,
        boxShadow: '0px 2px 4px rgba(130, 130, 130, 0.05)',
        borderRadius: 4,
        '@media (max-width: 1200px)': {
            borderRadius: 0,
            width: 'calc(100% - 2px)',
        },
        '@media (max-width: 768px)': {
            borderRadius: 0,
        },
    },
    vacDescBody: {
        maxHeight: 'calc(100vh - 255px)',
        overflowY: 'auto',
        transition: 'all 0.2s linear',
        scrollbarGutter: 'stable',
        padding: 24,
        display: 'flex',
        flexDirection: 'column',
        gap: 32,
        '-webkit-scrollbar': {
            display: 'none',
        },
        '@media (max-width: 768px)': {
            padding: 16,
            gap: 16,
        },
    },
    chevronWrap: {
        ':nth-child(1n) > :first-child': {
            transform: 'rotate(180deg)',
            ':nth-child(1n) > path': {
                stroke: COLORS.dark,
            },
        },
        background: COLORS.white,
        border: `1px solid ${COLORS.smoothGray}`,
        borderRadius: 64,
        width: 36,
        height: 36,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: 16,
        cursor: 'pointer',
        '@media (max-width: 1200px)': {
            display: 'none',
        },
    },
    vacancyName: {
        display: 'flex',
        alignItems: 'center',
        fontWeight: '600',
        fontSize: 24,
        color: COLORS.dark,
        gap: 4,
        overflowWrap: 'anywhere',
        ':nth-child(1n) > :first-child': {
            cursor: 'pointer',
            width: 18,
            '@media (min-width: 1200px)': {
                display: 'none',
            },
            transform: 'rotate(180deg)',
            ':nth-child(1n) > path': {
                stroke: COLORS.dark,
            },
        },
        '@media (max-width: 1200px)': {
            fontSize: 20,
        },
    },
    btnGrp: {
        '@media (max-width: 768px)': {
            display: 'none',
        },
    },
    shareBtn: {
        height: 32,
        background: COLORS.white,
        border: `1px solid ${COLORS.smoothGray}`,
        borderRadius: 4,
        padding: '0 6px',
        display: 'flex',
        alignItems: 'center',
        ':hover': {
            background: COLORS.smoothGray,
        },
    },
    mobileBtn: {
        '@media (min-width: 768px)': {
            display: 'none',
        },
        ':nth-child(1n)> *': {
            width: '100%',
        },
    },
    editBtn: {
        background: COLORS.white,
        border: `1px solid ${COLORS.smoothGray}`,
        borderRadius: 4,
        fontWeight: '500',
        color: COLORS.lightBlack,
        fontSize: 13,
        height: 32,
        padding: '8.5px 6px',
        // eslint-disable-next-line max-len
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 8,
        ':nth-child(1n) > :first-child': {
            ':nth-child(1n) > path': {
                stroke: COLORS.lightBlack,
            },
        },
        ':hover': {
            background: COLORS.smoothGray,
        },
    },
    contHeader: {
        borderBottom: `1px solid ${COLORS.lightGray}`,
        padding: 24,
        '@media (max-width: 768px)': {
            padding: 0,
            borderBottom: 'none',
        },
    },
    flxBtw: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
    },
})
