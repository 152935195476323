import React, { useState } from 'react'
import { css, StyleSheet } from 'aphrodite'
import { CloseSidebar } from '../../svgs/Svgs'
// import telegram from '../../static/images/telegramSizeL.svg'
// import whatsapp from '../../static/images/whatsappSizeL.svg'
// import gmail from '../../static/images/gmailSizeL.svg'
// import viber from '../../static/images/viber.svg'
// import instagram from '../../static/images/instagram.svg'
import { COLORS } from '../../../utils/colors'

export default function ShareModal({ onCLose, shareLink }) {
    const [copied, setCopied] = useState(false)
    const [message, setMessage] = useState('')

    function forceCopyToClipboard(text) {
        const textarea = document.createElement('textarea')
        textarea.value = text
        textarea.setAttribute('readonly', '')
        textarea.style.position = 'absolute'
        textarea.style.left = '-9999px'
        document.body.appendChild(textarea)

        textarea.select()

        try {
            document.execCommand('copy')
            setMessage('СКОПИРОВАНА!')
        } catch (err) {
            setMessage('НЕ УДАЛОСЬ КOПИРОВАТЬ')
        }

        document.body.removeChild(textarea)
    }

    const copyToClipboard = (content) => {
        if (window.isSecureContext && navigator.clipboard) {
            try {
                navigator.clipboard.writeText(content)
                setMessage('СКОПИРОВАНА!')
                setCopied(true)
            } catch (err) {
                setMessage('НЕ УДАЛОСЬ КOПИРОВАТЬ')
            }
        } else {
            forceCopyToClipboard(content)
            setCopied(true)
        }
    }

    return (
        <div className={css(s.mainWrap)}>
            <div className={css(s.modalHeader)}>
                <h3>Поделиться</h3>

                <CloseSidebar onClick={onCLose} />
            </div>

            <div className={css(s.modalBody)}>
                <div className={css(s.copyLink)}>
                    <a href={shareLink} target="_blank" rel="noreferrer">{shareLink}</a>

                    <h3 onClick={() => copyToClipboard(shareLink)}>{copied ? message : 'копировать'}</h3>
                </div>

                {/* <div className="justify-between align-center gap-7"> */}
                {/*    <div className={css(s.divider)} /> */}

                {/*    <h3 className={css(s.dividerTxt)}>или</h3> */}

                {/*    <div className={css(s.divider)} /> */}
                {/* </div> */}

                {/* <div className={css(s.socialIconsWrap)}> */}
                {/*    <div className={css(s.socialIconWrap)}> */}
                {/*        <img src={telegram} alt="#telegram" /> */}

                {/*        <h3>Telegram</h3> */}
                {/*    </div> */}

                {/*    <div className={css(s.socialIconWrap)}> */}
                {/*        <img src={whatsapp} alt="#whatsapp" /> */}

                {/*        <h3>Whatsapp</h3> */}
                {/*    </div> */}

                {/*    <div className={css(s.socialIconWrap)}> */}
                {/*        <img src={gmail} alt="#gmail" /> */}

                {/*        <h3>Gmail</h3> */}
                {/*    </div> */}

                {/*    <div className={css(s.socialIconWrap)}> */}
                {/*        <img src={viber} alt="#viber" /> */}

                {/*        <h3>Viber</h3> */}
                {/*    </div> */}

                {/*    <div className={css(s.socialIconWrap)}> */}
                {/*        <img src={instagram} alt="#instagram" /> */}

                {/*        <h3>Instagram</h3> */}
                {/*    </div> */}
                {/* </div> */}
            </div>
        </div>
    )
}

const s = StyleSheet.create({
    mainWrap: {
        width: '37rem',
        background: COLORS.smoothGray,
        borderRadius: 4,
        boxSizing: 'border-box',
    },
    modalHeader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '20px 24px',
        background: COLORS.white,
        borderBottom: `1px solid ${COLORS.lightGray}`,
        borderRadius: '4px 4px 0px 0px',
        ':nth-child(1n) > h3': {
            fontWeight: '600',
            fontSize: 20,
            color: COLORS.dark,
        },
        ':nth-child(1n) > :last-child': {
            cursor: 'pointer',
        },
    },
    copyLink: {
        background: COLORS.white,
        border: `1px solid ${COLORS.lightGray}`,
        borderRadius: 4,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '15px 16px',
        ':nth-child(1n) > a': {
            fontWeight: '500',
            fontSize: 12,
            color: COLORS.midGray,
            outline: 'none',
            ':hover': {
                color: COLORS.skyblue,
            },
        },
        ':nth-child(1n) > h3': {
            fontWeight: '500',
            fontSize: 12,
            textAlign: 'right',
            textTransform: 'uppercase',
            color: COLORS.skyblue,
            cursor: 'pointer',
        },
    },
    modalBody: {
        padding: '16px 24px 24px 24px',
    },
    socialIconsWrap: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 16,
        marginTop: 24,
    },
    socialIconWrap: {
        background: COLORS.white,
        border: '1px solid #E6E6E6',
        boxShadow: '0px 2px 4px rgba(136, 136, 136, 0.1)',
        borderRadius: 4,
        padding: '10px 0px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 12,
        fontWeight: '600',
        fontSize: 12,
        color: COLORS.dark,
        ':nth-child(1n) > img': {
            padding: '0 29px',
        },
        userSelect: 'none',
        cursor: 'pointer',
    },
    divider: {
        background: COLORS.lightGray,
        width: '100%',
        height: 1,
    },
    dividerTxt: {
        fontWeight: '600',
        fontSize: 14,
        textTransform: 'uppercase',
        color: COLORS.gray,
    },
})
