import React from 'react'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import Layout from '../common/Layout'
import cableLeft from '../../static/images/cableLeft.png'
import cableRight from '../../static/images/cableRight.png'
import { COLORS } from '../../utils/colors'
import { NotFoundStatusCode } from '../svgs/SvgPictures'

export default function NotFound() {
    return (
        <Layout hideSideBar padded={false} className={css(s.ly)}>
            <div className={css(s.cont)}>
                <div className="justify-between align-center">
                    <div className={cn('align-start full-height', css(s.rightCable))}>
                        <img src={cableLeft} alt="#error" />
                    </div>

                    <div className="justify-center align-center direction-column full-height gap-3">
                        <NotFoundStatusCode />

                        <h3 className={css(s.title)}>Ууууупс! Страница не найдена</h3>

                        <h3 className={css(s.desc)}>Страница, которую вы искали была перемещена или удалена</h3>
                    </div>

                    <div className="align-end full-height">
                        <img src={cableRight} alt="#error" />
                    </div>
                </div>
            </div>
        </Layout>
    )
}

const s = StyleSheet.create({
    ly: {
        width: '88.4%',
        scrollbarGutter: 'auto',
    },
    rightCable: {
        marginLeft: '-7%',
    },
    cont: {
        height: 'calc(100% - 3.75rem)',
        width: '100%',
        marginTop: '3.75rem',
        ':nth-child(1n) > :first-child': {
            height: '60%',
        },
    },
    code: {
        color: COLORS.dark,
        fontWeight: '800',
        fontSize: 160,
    },
    title: {
        marginTop: 24,
        color: COLORS.dark,
        fontWeight: '600',
        fontSize: 24,
    },
    desc: {
        fontSize: 20,
        color: COLORS.gray,
        fontWeight: '400',
    },
})
