import React, { useEffect, useState } from 'react'
import { css, StyleSheet } from 'aphrodite'
import { components } from 'react-select'
import Highlighter from 'react-highlight-words'
import SelectSimple from '../common/Select'
import Checkbox from '../common/Checkbox'

export function SelectSection({
    name,
    options,
    isMulti,
    innerRef,
    menuStyles,
    customOption,
    defaultValue,
    optionStyles,
    onChange = () => {},
    withCheckbox = true,
    placeholder = 'Выберите',
    ...args
}) {
    const [isOpen, setIsOpen] = useState(false)

    useEffect(() => {
        const element = document.getElementById('sec')

        if (isOpen) {
            element.style.overflowY = 'clip'
            element.classList.add(css(s.overridePadding))
            element.classList.remove(css(s.defaultPadding))
        } else {
            element.style.overflowY = 'auto'
            element.classList.add(css(s.defaultPadding))
            element.classList.remove(css(s.overridePadding))
        }
    }, [isOpen])

    const styles = {
        multiValue: (baseStyles) => ({
            ...baseStyles,
            backgroundColor: 'transparent',
            margin: 0,
            padding: 0,
        }),
        multiValueLabel: (baseStyles) => ({
            ...baseStyles,
            padding: 0,
            paddingLeft: 0,
            fontWeight: '500',
            fontSize: 12,
            color: '#181A19',
            display: 'flex',
            gap: 4,
            ':nth-of-type(1n) > span': {
                color: '#386E56',
            },
        }),
        dropdownIndicator: (optStyles) => ({
            ...optStyles,
            padding: '0 12px 0 8px',
        }),
        menu: (baseStyles) => ({
            ...baseStyles,
            width: '17.75rem',
            overflowY: 'auto',
            height: '55vh',
            maxHeight: '25.5rem',
            ...menuStyles,
        }),
        menuList: (baseStyles) => ({
            ...baseStyles,
            height: '100%',
            maxHeight: 'none',
            overflowY: 'auto',
        }),
    }

    const MultiValue = (props) => {
        const { index, children, getValue } = props
        return index === 0 ? (
            <components.MultiValue {...props}>
                {children.length > 17 ? `${children.slice(0, 17)}...` : children}

                {getValue().length > 1 ? (
                    <React.Fragment>
                        <span>•</span>

                        <h3>+ {getValue().length - 1}</h3>
                    </React.Fragment>
                ) : null}
            </components.MultiValue>
        ) : null
    }

    const Option = (props) => {
        const { children, isSelected, selectProps } = props

        return (
            <components.Option {...props}>
                <div className="align-center gap-2">
                    <Checkbox checked={isSelected} />

                    <div className="align-center gap-2">
                        {/* <img className={css(s.img)} src={avatar} alt="#avatar" /> */}

                        <Highlighter
                            textToHighlight={children}
                            activeClassName="highlight"
                            searchWords={[selectProps.inputValue.trim()]}
                            highlightClassName={css(s.optLab)}
                        />
                    </div>
                </div>
            </components.Option>
        )
    }

    const customComponent = customOption ? {
        Option,
        MultiValue,
        ClearIndicator: () => null,
        MultiValueRemove: () => null,
    } : {
        MultiValue,
        ClearIndicator: () => null,
        MultiValueRemove: () => null,
    }

    return (
        <SelectSimple
            innerRef={innerRef}
            name={name}
            styles={styles}
            options={options}
            isMulti={isMulti}
            menuIsOpen={isOpen}
            closeMenuOnSelect={!isMulti}
            onChange={onChange}
            defaultValue={defaultValue}
            placeholder={placeholder}
            className={css(s.select)}
            optStyle={optionStyles}
            hideSelectedOptions={false}
            withCheckbox={withCheckbox}
            customComponent={customComponent}
            onMenuOpen={() => setIsOpen(true)}
            onMenuClose={() => setIsOpen(false)}
            valueContainerStyles={{ padding: '0 0 0 12px' }}
            placeholderStyles={{ fontSize: 14, fontWeight: '500', color: '#969F9B' }}
            controlStyles={{ minHeight: '100%', boxSizing: 'border-box', cursor: 'pointer' }}
            {...args}
        />
    )
}

const s = StyleSheet.create({
    select: {
        height: 36,
        border: '1px solid #C9D2CE',
        borderRadius: 4,
    },
    img: {
        width: 32,
        height: 32,
        borderRadius: '50%',
        border: '1px solid rgba(0, 0, 0, 0.2)',
    },
    defaultPadding: {
        paddingRight: 10,
    },
    overridePadding: {
        paddingRight: 16,
    },
})
