import React from 'react'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import { COLORS } from '../../../utils/colors'
import { CheckSizeS, Clock, CloseSizeS, DescendingNumber } from '../../svgs/Svgs'

export default function VacancyTableHeader() {
    return (
        <thead className={cn(css(s.tableHead))}>
            <tr>
                <th className={css(s.item, s.nm)}>
                    №
                </th>

                <th className={css(s.item, s.date)}>
                    <div className="align-center gap-2">
                        <h3>Дата</h3> <DescendingNumber className="pointer" />
                    </div>
                </th>

                <th className={css(s.item, s.cols)}>
                    Колонка
                </th>

                <th className={css(s.item, s.new)}>
                    Новые
                </th>

                <th className={css(s.item, s.progress)}>
                    на рассмотрении
                </th>

                <th className={css(s.item, s.meetup)}>
                    <div>
                        <h3>Собеседования</h3>

                        <div className={cn('align-center gap-6', css(s.icons))}>
                            <Clock />

                            <CheckSizeS />

                            <CloseSizeS color={COLORS.lightRed} />
                        </div>
                    </div>
                </th>

                <th className={css(s.item, s.offer)}>
                    <div>
                        <h3>Оффер</h3>

                        <div className={cn('align-center gap-6', css(s.icons))}>
                            <Clock />

                            <CheckSizeS />

                            <CloseSizeS color={COLORS.lightRed} />
                        </div>
                    </div>
                </th>

                <th className={css(s.item, s.accept)}>
                    <div>
                        <h3>Приглашенные</h3>

                        <div className={cn('align-center gap-6', css(s.icons))}>
                            <Clock />

                            <CheckSizeS />

                            <CloseSizeS color={COLORS.lightRed} />
                        </div>
                    </div>
                </th>

                <th className={css(s.item, s.rejected)}>
                    Отказанные
                </th>
            </tr>
        </thead>
    )
}

const s = StyleSheet.create({
    tableHead: {
        position: 'sticky',
        top: 0,
        textTransform: 'uppercase',
        ':nth-child(1n) > :first-child': {
            background: '#EAF4F0',
            height: 36,
        },
    },
    item: {
        fontSize: 13,
        color: COLORS.midGray,
        fontWeight: '500',
        textAlign: 'start',
        boxSizing: 'border-box',
        ':first-of-type': {
            textAlign: 'center',
        },
        ':not(:first-of-type)': {
            paddingLeft: 12,
        },
        ':not(:last-of-type)': {
            borderRight: `1px solid ${COLORS.lightGray}`,
        },
    },
    icons: {
        paddingLeft: 4,
    },
    nm: {
        width: '3%',
    },
    date: {
        width: '13%',
    },
    cols: {
        width: '13%',
    },
    new: {
        width: '6%',
    },
    progress: {
        width: '12%',
    },
    meetup: {
        width: '14%',
    },
    offer: {
        width: '14%',
    },
    accept: {
        width: '14%',
    },
    rejected: {
        width: '11%',
    },
})
