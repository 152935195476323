import React from 'react'
import { css, StyleSheet } from 'aphrodite'
import DOMPurify from 'isomorphic-dompurify'
import { COLORS } from '../../../../utils/colors'

export default function Description({ text }) {
    const dangerouslySetDescription = DOMPurify.sanitize(text)

    return (
        <div className={css(s.desc)}>
            <h3>Описание вакансии:</h3>

            {/* eslint-disable-next-line react/no-danger */}
            <div dangerouslySetInnerHTML={{ __html: dangerouslySetDescription }} />
        </div>
    )
}

const s = StyleSheet.create({
    desc: {
        display: 'flex',
        flexDirection: 'column',
        gap: 16,
        fontWeight: '500',
        fontSize: 14,
        whiteSpace: 'break-spaces',
        wordBreak: 'break-word',
        color: COLORS.lightBlack,
        overflowWrap: 'anywhere',
        ':nth-child(1n) > :last-child': {
            ':nth-child(1n) > ol': {
                padding: '15px !important',
            },
            ':nth-child(1n) > ul': {
                listStyleType: 'none',
                ':nth-child(1n) > *': {
                    '::before': {
                        content: '"\\2022"',
                        color: COLORS.midGray,
                        display: 'inline-block',
                        width: '1em',
                        // fontSize: '1.5em',
                        marginLeft: 0,
                    },
                },
            },
        },
        ':nth-child(1n) > h3': {
            fontWeight: '600',
            fontSize: 20,
            color: COLORS.dark,
            '@media (max-width: 768px)': {
                fontSize: 16,
            },
        },
        '@media (max-width: 768px)': {
            fontSize: 12,
            gap: 12,
        },
    },
})
