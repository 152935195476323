import React, { useCallback } from 'react'
import { Form, Formik } from 'formik'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import moment from 'moment/moment'
import InputDate from '../../common/InputDate'
import { COLORS } from '../../../utils/colors'
import { CalendarSizeS } from '../../svgs/Svgs'
import { banknoteOptions } from '../../../utils/options'
import InputFromToForm from '../../common/InputFromToForm'
import VacanciesSelectField from './VacanciesSelectField'
import { getFullDate } from '../../../utils/date'
import { integersOnly } from '../../../utils/number'

const today = moment()

const initialValues = {
    date: { from: '', to: '' },
    period: { from: getFullDate(today.clone().subtract(29, 'days').toDate()), to: getFullDate(today.toDate()) },
    vacancies: [],
    salary: { from: '', to: '', currency: banknoteOptions[0] },
}

export default function Filter({ setParams, formikRef, vacancies, loadMetrics }) {
    const onSubmit = useCallback(async (data) => {
        if (!vacancies.length || loadMetrics.loading) return

        const { vacancies: submitVacancies, period, date, salary } = data

        await setParams({
            isReinitialized: true,
            vacancyIds: submitVacancies.map(({ value }) => value).join(','),
            periodEnd: period.to,
            periodStart: period.from,
            startDate: date.from,
            endDate: date.to,
            salaryFrom: +integersOnly(salary.from) || '',
            salaryEnd: +integersOnly(salary.to) || '',
        })
    }, [vacancies, loadMetrics.loading])

    return (
        <div className={cn('align-center', css(s.cont))}>
            <Formik innerRef={formikRef} onSubmit={onSubmit} initialValues={initialValues}>
                {() => (
                    <Form className="align-center gap-5">
                        <InputDate
                            isMulti
                            withOptions
                            name={['date.from', 'date.to']}
                            nameSplit={{ name: 'date', count: 0, keys: ['from', 'to'] }}
                            labelClassName={s.label}
                            label="Дата создания вакансии"
                            position="left"
                            className={s.inp}
                            icon={<CalendarSizeS />}
                        />

                        <InputDate
                            isMulti
                            withOptions
                            name={['period.from', 'period.to']}
                            nameSplit={{ name: 'period', count: 0, keys: ['from', 'to'] }}
                            labelClassName={s.label}
                            label="Период истории вакансий"
                            position="left"
                            className={s.inp}
                            icon={<CalendarSizeS />}
                        />

                        <VacanciesSelectField vacancies={vacancies} />

                        <InputFromToForm
                            name="salary"
                            maxLength={11}
                            labelClassName={css(s.label)}
                            label="Зарплатная вилка"
                            cssClassName={s.salaryInp}
                            options={banknoteOptions}
                            placeholder={['от', 'до']}
                            staticText={<span className={css(s.currency)}>UZS</span>}
                        />
                    </Form>
                )}
            </Formik>
        </div>
    )
}

const s = StyleSheet.create({
    cont: {
        padding: '9px 24px',
        borderBottom: `1px solid ${COLORS.smoothGray}`,
        background: COLORS.white,
        height: 54,
    },
    label: {
        marginBottom: 3,
        fontSize: 10,
        fontWeight: '500',
        color: COLORS.midGray,
    },
    inp: {
        height: '32px !important',
        boxSizing: 'border-box',
        minWidth: '15rem',
    },
    salaryInp: {
        height: '32px !important',
        boxSizing: 'border-box',
        minWidth: '15rem',
        ':nth-child(1n) > :first-child': {
            flex: 1,
            ':nth-child(1n) > :last-child': {
                marginLeft: 'auto',
            },
        },
    },
    currency: {
        paddingLeft: 32,
        color: COLORS.mainColor,
        fontSize: 12,
        fontWeight: '500',
    },
    select: {
        width: '15rem',
    },
})
