import React, { useLayoutEffect, useState } from 'react'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import { useNavigate } from 'react-router-dom'
import { Form, Formik } from 'formik'
import { isEmpty } from 'lodash'
import axios from 'axios'
import { Eye, EyeClose } from '../../components/svgs/Svgs'
import FormInput from '../../components/common/FormInput'
import { email, required, validator } from '../../utils/validators'
import Checkbox from '../../components/common/Checkbox'
import LayoutAuth from '../../components/common/LayoutAuth'
import { useGetRequest, usePostRequest } from '../../hooks/request'
import { TOKEN, USER_INFO, VERIFY_EMAIL } from '../../urls'
import { useMessage } from '../../hooks/message'
import { COLORS } from '../../utils/colors'
import { useQueryParams } from '../../hooks/queryString'
import { domain } from '../../utils/request'
import Button from '../../components/common/Button'

export default function Authorization() {
    const { token } = useQueryParams()
    const navigate = useNavigate()
    const getUserInfo = useGetRequest({ url: USER_INFO })
    const auth = usePostRequest({ url: TOKEN })
    const [rememberMe, setRememberMe] = useState(false)
    const [passIcon, setPassIcon] = useState(false)
    const [showMessage] = useMessage()

    useLayoutEffect(() => {
        if (token) {
            axios.get(domain + VERIFY_EMAIL, { params: { token } }).then(() => {
                showMessage('Успешно проверено', 'success-msg')
            }).catch(() => {
                showMessage('Ошибка токена', 'error-msg')
            })
        }
    }, [])

    const icons = () => (passIcon ? (
        <Eye pointer onClick={() => setPassIcon(false)} />
    ) : (
        <EyeClose pointer onClick={() => setPassIcon(true)} />
    ))

    async function onSubmit(data) {
        const { success, response, error } = await auth.request({
            data: {
                username: data.email,
                password: data.password,
                grant_type: 'password',
                client_id: process.env.REACT_APP_CLIENT_ID || '',
                client_secret: process.env.REACT_APP_CLIENT_SECRET || '',
            },
        })

        if (success) {
            localStorage.setItem('token', JSON.stringify(response.data.accessToken))
            localStorage.setItem('refresh_token', JSON.stringify(response.data.refreshToken))
            const userResponse = await getUserInfo.request()

            if (userResponse.success) {
                const { firstName, lastName, username, companyId } = userResponse.response.data
                localStorage.setItem('user', JSON.stringify({ firstName, lastName, username, companyId }))
            } else {
                showMessage('ОШИБКА ИНФОРМАЦИИ О ПОЛЬЗОВАТЕЛЕ', 'error-msg')
            }

            navigate('/projects')
        } else {
            showMessage(error.data.errors[0].message || error.data.message, 'error-msg')
        }
    }

    return (
        <LayoutAuth>
            <div className={cn('is-flex direction-column', css(s.cont))}>
                <h3 className={css(s.topic)}>Войти в систему</h3>

                {/* <div className={css(s.authWith)}> */}
                {/*     <span className="flex-cent cursor gap-4"> */}
                {/*         <Google /> <h3>Войти с Google</h3> */}
                {/*     </span> */}

                {/*     <span className="flex-cent cursor gap-4"> */}
                {/*         <Facebook /> <h3>Войти с Facebook</h3> */}
                {/*     </span> */}
                {/* </div> */}

                {/* <div className={css(s.an_t)}> */}
                {/*     <span><hr style={{ border: '0.5px solid #D9D9D9', borderRadius: 50 }} /></span> */}
                {/*     <h3 className={css(s.ant_orT)}>ИЛИ</h3> */}
                {/*     <span><hr style={{ border: '0.5px solid #D9D9D9', borderRadius: 50 }} /></span> */}
                {/* </div> */}

                <Formik
                    // eslint-disable-next-line react/jsx-no-bind
                    onSubmit={onSubmit}
                    initialValues={{ email: '', password: '' }}>
                    {({ errors, values }) => (
                        <React.Fragment>
                            <Form>
                                <FormInput
                                    validate={validator(required, email)}
                                    placeholder="Например “abcdef@gmail.com”"
                                    name="email"
                                    label="Электронная почта"
                                    fieldClassName={css(s.field)}
                                    labelClassName={css(s.lab)}
                                />

                                <FormInput
                                    type={passIcon ? 'text' : 'password'}
                                    label="Пароль"
                                    name="password"
                                    placeholder="Введите пароль "
                                    labelClassName={css(s.lab)}
                                    validate={required}
                                    fieldClassName={cn(css(s.field), 'dis_sl')}
                                    icon={icons()}
                                />

                                <div className={css(s.rem)}>
                                    <div className="dis_sl">
                                        <Checkbox checked={rememberMe} setChecked={setRememberMe} />

                                        <h3>Запомнить меня</h3>
                                    </div>

                                    <h3 onClick={() => navigate('/reset-password')}>Забыли пароль?</h3>
                                </div>

                                <Button
                                    loaderColor={COLORS.white}
                                    loaderWidth={20}
                                    strokeWidth={6}
                                    loading={auth.loading || getUserInfo.loading}
                                    type="submit"
                                    className={cn(
                                        css(s.btn),
                                        { [css(s.sbmBtn)]:
                                            isEmpty(errors) && Object.values(values).every((e) => e !== '') },
                                    )}
                                >
                                    Войти
                                </Button>
                            </Form>
                        </React.Fragment>
                    )}
                </Formik>
            </div>
        </LayoutAuth>
    )
}

const s = StyleSheet.create({
    cont: {
        margin: 'auto',
        width: 'calc(24.75rem - 1px)',
        padding: '0 0 32px 5px',
        gap: '2rem',
    },
    topic: {
        fontSize: 36,
        fontWeight: '600',
        color: '#181A19',
    },
    // authWith: {
    //     display: 'flex',
    //     justifyContent: 'space-between',
    //     alignItems: 'center',
    //     gap: '1rem',
    //     fontSize: 14,
    //     fontWeight: '500',
    //     color: '#fff',
    //     ':nth-child(1n) > *': {
    //         height: 40,
    //         width: '50%',
    //         borderRadius: 4,
    //     },
    //     ':nth-child(1n) > :first-child': {
    //         backgroundColor: '#EA4335',
    //     },
    //     ':nth-child(1n) > :last-child': {
    //         backgroundColor: '#1977F3',
    //     },
    // },
    // an_t: {
    //     display: 'flex',
    //     justifyContent: 'space-between',
    //     alignItems: 'center',
    //     ':nth-child(1n) > :first-child': {
    //         width: '42.26%',
    //     },
    //     ':nth-child(1n) > :last-child': {
    //         width: '42.26%',
    //     },
    // },
    // ant_orT: {
    //     fontSize: 12,
    //     fontWeight: '500',
    //     color: COLORS.midGray,
    // },
    lab: {
        fontSize: 12,
        fontWeight: '500',
        color: COLORS.midGray,
        marginBottom: 7,
    },
    field: {
        position: 'relative',
        paddingBottom: '2rem',
    },
    rem: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        ':nth-child(1n) > :first-child': {
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
            ':nth-child(1n) > :first-child': {
                ':nth-child(1n) > :first-child': {
                    ':nth-child(1n) > :first-child': {
                        position: 'relative',
                        top: '0.5px',
                    },
                },
            },
            ':nth-child(1n) > :last-child': {
                fontSize: 12,
                fontWeight: '500',
                color: COLORS.midGray,
                marginLeft: 8,
            },
        },
        ':nth-child(1n) > :last-child': {
            fontSize: 12,
            fontWeight: '500',
            color: COLORS.skyblue,
            cursor: 'pointer',
        },
    },
    btn: {
        width: '100%',
        marginTop: 32,
        padding: 0,
        height: 40,
        outline: 'none',
        border: 'none',
        background: COLORS.gray,
        boxShadow: '0 4px 12px rgba(44, 67, 50, 0.1), inset 0px -1px 0px rgba(0, 0, 0, 0.25), inset 0px 1px 0px rgba(255, 255, 255, 0.1)', // eslint-disable-line
        borderRadius: 4,
        color: '#FFFFFF',
        fontSize: 14,
        cursor: 'pointer',
        fontWeight: '500',
    },
    sbmBtn: {
        background: `linear-gradient(180deg, #2EA97D 0%, ${COLORS.lightMainColor} 100%)`,
        boxShadow: '0px 4px 12px rgba(44, 67, 50, 0.1), inset 0px -1px 0px rgba(0, 0, 0, 0.25), inset 0px 1px 0px rgba(255, 255, 255, 0.1)', // eslint-disable-line
        ':hover': {
            background: `linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), linear-gradient(180deg, #2EA97D 0%, ${COLORS.lightMainColor} 100%)`, // eslint-disable-line
            boxShadow: '0px 2px 4px rgba(130, 130, 130, 0.2), inset 0px -1px 0px rgba(0, 0, 0, 0.25), inset 0px 1px 0px rgba(255, 255, 255, 0.1)', // eslint-disable-line
        },
    },
})
