import React from 'react'
import cn from 'classnames'
import LoadingSpinner from './LoadingSpinner'

export default function Button({
    className,
    children,
    disabled,
    loading,
    loaderWidth = 24,
    loaderColor,
    icon,
    iconRight,
    strokeWidth,
    ...attr
}) {
    return (
        <button
            disabled={disabled || loading}
            className={cn(`btn-smp ${loading ? ' not-allowed' : ''}`, className)}
            {...attr}
        >
            <div className="align-center gap-2">
                <div className={`align-center gap-2${iconRight ? ' row-reverse' : ''}`}>
                    {!loading && icon}
                    {children}
                </div>
                {loading && <LoadingSpinner width={loaderWidth} color={loaderColor} strokeWidth={strokeWidth} />}
            </div>
        </button>
    )
}
