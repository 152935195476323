import React, { useEffect, useRef, useState } from 'react'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import axios from 'axios'
import { AddPhoto, DefaultCompanyLogoSizeL } from '../../svgs/Svgs'
import { COLORS } from '../../../utils/colors'
import { COMPANY_BANNER } from '../../../urls'
import { domain } from '../../../utils/request'
import { auth } from '../../../utils/auth'
import { useMessage } from '../../../hooks/message'
import CompanyImages from './CompanyImages'
import CompanyInfo from './CompanyInfo'
import { cp } from '../../../utils/checkPermission'
import SocialNetworkTags from './SocialNetworkTags'

export default function Banner({ info, loadInfo }) {
    const [edit, setEdit] = useState(false)
    const [banner, setBanner] = useState([])
    const inputRef = useRef(null)
    const [showMessage] = useMessage()

    useEffect(() => {
        if (info.banner && info.banner[0]) setBanner(info.banner)
    }, [info.banner])

    // onChange function for banner image
    const onChange = async (e) => {
        if (e.target.files[0]) setBanner((prev) => [URL.createObjectURL(e.target.files[0]), ...prev])

        if (typeof e.target.files[0] === 'object') {
            const formData = new FormData()

            formData.append('file', e.target.files[0])

            await axios.post(domain + COMPANY_BANNER, formData, { ...auth() })
                .then(({ data }) => {
                    if (data.success) {
                        showMessage('Успешно!', 'success-msg')
                        loadInfo.setResponse({ ...loadInfo.response,
                            data: {
                                ...info,
                            } })
                    }
                }).catch((err) => showMessage(err.response.data && err.response.data.errors[0].message, 'error-msg'))
        }
    }

    const hasPermission = cp('settings.companyPublic.edit_info')

    return (
        <div className={css(s.cont)}>
            <div>
                <div className={bgStyle(banner)}>
                    {hasPermission ? (
                        <div
                            onClick={() => inputRef.current.click()}
                            className={cn('align-center gap-2', css(s.addBanner))}>
                            <AddPhoto type="button" />

                            <h3>Добавить фотографию</h3>

                            <input
                                id="file"
                                type="file"
                                readOnly="readonly"
                                accept="image/png, image/jpg, image/jpeg"
                                ref={inputRef}
                                className="not-visible"
                                onChange={(e) => onChange(e)} />
                        </div>
                    ) : null}
                </div>

                <div className="justify-center is-relative">
                    {info.logo && info.logo.length ? (
                        <div className={css(s.logoCont)}>
                            <img src={info.logo[1]} alt="#CompanyLogo" />
                        </div>
                    ) : (
                        <div className={css(s.defaultLogo)}>
                            <DefaultCompanyLogoSizeL color={COLORS.white} />
                        </div>
                    )}
                </div>
            </div>

            <div className={css(s.infoWrap)}>
                <CompanyInfo
                    info={info}
                    loadInfo={loadInfo}
                    hasPermission={hasPermission}
                    edit={edit}
                    setEdit={setEdit}
                />

                <CompanyImages
                    loadInfo={loadInfo}
                    hasPermission={hasPermission}
                />

                <SocialNetworkTags loading={loadInfo.loading} tags={info.socialLinks} />
            </div>
        </div>
    )
}

const s = StyleSheet.create({
    cont: {
        margin: '22px 32px 0 32px',
        background: '#FFFFFF',
        boxShadow: '0px 2px 4px rgba(130, 130, 130, 0.05)',
        borderRadius: 4,
        '@media (max-width: 1024px)': {
            margin: '16px 16px 0 16px',
        },
        '@media (max-width: 768px)': {
            margin: 0,
            borderRadius: 0,
        },
    },
    infoWrap: {
        padding: '24px 32px 32px 32px',
        display: 'flex',
        flexDirection: 'column',
        gap: 24,
        '@media (max-width: 768px)': {
            padding: 16,
            gap: 16,
        },
    },
    addBanner: {
        ':nth-child(1n) > h3': {
            userSelect: 'none',
        },
        ':nth-child(1n) > :first-child': {
            ':nth-child(1n) > path': {
                stroke: COLORS.white,
            },
        },
        cursor: 'pointer',
    },
    chevronBack: {
        width: 48,
        height: 48,
        ':nth-child(1n) > :only-child': {
            ':nth-child(1n) > path': {
                stroke: COLORS.white,
            },
        },
        borderRadius: 64,
        background: 'rgba(0, 0, 0, 0.20)',
        boxShadow: '0px 2px 4px 0px rgba(130, 130, 130, 0.05)',
    },
    logoCont: {
        width: 64,
        height: 64,
        borderRadius: 4,
        boxSizing: 'border-box',
        position: 'absolute',
        top: -32,
        ':nth-child(1n) > img': {
            background: COLORS.white,
            borderRadius: 4,
            width: '100%',
            height: '100%',
        },
    },
    defaultLogo: {
        width: 64,
        height: 64,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: COLORS.mainColor,
        border: `1px solid ${COLORS.white}`,
        borderRadius: 4,
        boxSizing: 'border-box',
        position: 'absolute',
        top: -32,
    },
})

const bgStyle = (banner) => css(StyleSheet.create({
    bg: {
        padding: '16px 32px',
        boxSizing: 'border-box',
        display: 'flex',
        alignItems: 'flex-end',
        width: '100%',
        height: '8.75rem',
        // eslint-disable-next-line max-len
        background: banner && banner.length ? `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${banner[0]})` : `linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), ${COLORS.mainColor}`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        color: '#FFFFFF',
        fontSize: 12,
        fontWeight: '500',
        borderRadius: 4,
        '@media (max-width: 768px)': {
            borderRadius: 0,
        },
    },
}).bg)
