import React from 'react'
import '../../static/css/tree.css'
import cn from 'classnames'
import { css, StyleSheet } from 'aphrodite'

export default function SidebarItem({ navigate, startsWith, title, icon }) {
    return (
        <li
            onClick={navigate}
            className={cn(css(s.m_item), { [css(s.act_item)]: startsWith })}
            datatype={startsWith ? 'selected' : undefined}> {/* eslint-disable-line */}
            <span className={cn(css(s.bdr), { [css(s.a_bdr)]: startsWith })} />

            <div className={cn('is-flex direction-column justify-between dis_sl')}>
                {icon}

                <h3>{title}</h3>
            </div>
        </li>
    )
}

const s = StyleSheet.create({
    bdr: {
        width: 4,
        height: '100%',
        borderRadius: '0 12px 12px 0',
    },
    a_bdr: {
        backgroundColor: '#2EA97D',
    },
    m_item: {
        height: 52,
        display: 'flex',
        cursor: 'pointer',
        color: '#6D7471',
        fontSize: 11,
        fontWeight: '500',
        textAlign: 'center',
        ':nth-child(1n) > :last-child': {
            padding: '8px 0',
            width: 'calc(100% - 4px)',
        },
        ':not([datatype=selected])': {
            ':hover': {
                background: '#F2F2F2',
            },
        },
    },
    act_item: {
        background: '#EDFCF4',
        color: '#2EA97D',
    },
})
