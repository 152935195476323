import React from 'react'
import moment from 'moment'
import ReactDOM from 'react-dom/client'
import App from './Router'
import 'moment/locale/ru'
import './static/css/styles.css'
import 'react-day-picker/dist/style.css'
import './static/css/calendar.css'
import 'react-loading-skeleton/dist/skeleton.css'

moment.locale('ru')

const app = ReactDOM.createRoot(document.getElementById('root'))
app.render(<App />)
