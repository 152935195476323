import React, { useContext, useEffect } from 'react'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import { useNavigate } from 'react-router-dom'
import Layout from '../components/common/Layout'
import VacancyDashboard from '../components/vacancies/VacancyDashboard'
import { Header } from '../components/vacancies/Header'
import { Context } from '../contexts/GlobalContext'
import { DASHBOARD_COLORS } from '../utils/colors'
import { VacancyFilter } from '../components/vacancies/VacancyFilter'
import { FilterContext } from '../contexts/FilterContext'
import { BaseContext } from '../contexts/BaseContextWrapper'
import { useQueryParams } from '../hooks/queryString'

function VacanciesInfo({ vacancyFilter, setVacancyFilter }) {
    const { vacanciesCount, setVacanciesCount } = useContext(BaseContext)
    const navigate = useNavigate()
    const { view } = useQueryParams()

    useEffect(() => {
        if (!view || !['board', 'table', 'archive'].includes(view)) navigate('?view=board')
    }, [])

    return (
        <React.Fragment>
            <Header
                view={view}
                count={vacanciesCount}
                vacancyFilter={vacancyFilter}
                setVacancyFilter={setVacancyFilter} />

            <VacancyDashboard
                view={view}
                vacancyFilter={vacancyFilter}
                setVacanciesCount={setVacanciesCount}
            />
        </React.Fragment>
    )
}

export default function Vacancies() {
    const { theme } = useContext(Context)
    const { vacancyFilter, setVacancyFilter } = useContext(FilterContext)
    const s = createStyles(DASHBOARD_COLORS[theme.rB])

    // TODO clear filter on change company

    return (
        <Layout padded={false} className={css(s.ly)}>
            <div className={cn('is-flex', css(s.cont))}>
                {vacancyFilter.act
                    ? <VacancyFilter vacancyFilter={vacancyFilter} setVacancyFilter={setVacancyFilter} />
                    : null}

                <div className={css(s.infoCont, vacancyFilter.act ? s.w80 : s.w100)}> {/* TODO fix width */}
                    <VacanciesInfo setVacancyFilter={setVacancyFilter} vacancyFilter={vacancyFilter} />
                </div>
            </div>
        </Layout>
    )
}

const createStyles = (backgroundColor) => StyleSheet.create({
    ly: {
        width: '88.4%',
        scrollbarGutter: 'stable',
        backgroundColor,
    },
    cont: {
        height: '100%',
    },
    w100: {
        width: '100%',
    },
    w80: {
        width: '80%',
    },
    infoCont: {
        overflowY: 'auto',
        height: '100%',
    },
})
