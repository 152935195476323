import React from 'react'
import { css, StyleSheet } from 'aphrodite'
import moment from 'moment'
import { isEmpty } from 'lodash'
import cn from 'classnames'
import { NavLink } from 'react-router-dom'
import { COLORS, tagStyleSheet } from '../../../utils/colors'
import { TableItemSkeleton } from './LoadingMetrics'
import { EyeSizeS } from '../../svgs/Svgs'

export default function VacancyTableItem({ index, vacancy, loading }) {
    const { tags } = vacancy

    return !loading && !vacancy.isLoading ? (
        <tr className={css(s.item)}>
            <td className="text-centered">{index + 1}</td>

            <td className="text-start">
                <NavLink className={css(s.link)} to={`/vacancies/${vacancy.id}/info`}>
                    <h3>{vacancy.name}</h3>
                </NavLink>

                <br />

                <h4 className={columnTextStyleSheet(vacancy.columnColor)}>{vacancy.columnName}</h4>
            </td>

            <td className="text-start">
                {!isEmpty(tags) ? (
                    <div className="align-center gap-2">
                        {tags.slice(0, 1).map(({ id, name, color }) => (
                            <div key={id} className={cn('align-center', css(s.tag), tagStyleSheet(color))}>
                                {name}
                            </div>
                        ))}

                        {tags.length > 1 ? (
                            <span className={cn('align-center', css(s.tag), tagStyleSheet(COLORS.midGray))}>
                                + {tags.length - 1}
                            </span>
                        ) : null}
                    </div>
                ) : <div>&#8212;&#8212;&#8212;</div>}
            </td>

            <td className="text-start">
                {moment(vacancy.createdAt).format('DD MMM YYYY')}
            </td>

            <td className="text-start">
                {vacancy.startedAt || vacancy.deadline ? (
                    <div className="align-center gap-1">
                        {vacancy.startedAt ? moment(vacancy.startedAt).format('DD MMM YYYY')
                            : <div>&#8212;&#8212;&#8212;</div>}
                        {' - '}
                        {vacancy.deadline ? moment(vacancy.deadline).format('DD MMM YYYY')
                            : <div>&#8212;&#8212;&#8212;</div>}
                    </div>
                ) : <div>&#8212;&#8212;&#8212;</div>}
            </td>

            <td className="text-centered">
                <h3>{vacancy.addedTelegram}</h3>

                <div className="flex-cent">
                    <div className={cn('flex-cent gap-1', css(s.viewBox))}>
                        <EyeSizeS /> <h3>{vacancy.viewTelegram}</h3>
                    </div>
                </div>
            </td>

            <td className="text-centered">
                <h3>{vacancy.addedWeb}</h3>

                <div className="flex-cent">
                    <div className={cn('flex-cent gap-1', css(s.viewBox))}>
                        <EyeSizeS /> <h3>{vacancy.viewWeb}</h3>
                    </div>
                </div>
            </td>

            <td className="text-centered">
                <h3>{vacancy.addedHeadhunter}</h3>

                <div className="flex-cent">
                    <div className={cn('flex-cent gap-1', css(s.viewBox))}>
                        <EyeSizeS /> <h3>{vacancy.viewHeadhunter}</h3>
                    </div>
                </div>
            </td>

            <td className="text-centered">
                <h3>{vacancy.addedRecruiter}</h3>
            </td>

            <td className="text-start">
                {vacancy.employeeAccept}/{vacancy.employeeCount}
            </td>
        </tr>
    ) : <TableItemSkeleton />
}

const s = StyleSheet.create({
    item: {
        width: '100%',
        height: '2.5rem',
        background: COLORS.white,
        color: '#181A19',
        fontSize: 13,
        fontWeight: '500',
        wordBreak: 'break-word',
        ':nth-child(1n) > *': {
            color: COLORS.dark,
            fontSize: 12,
            fontWeight: '500',
            ':nth-child(1n) > h5': {
                all: 'unset',
                color: COLORS.midGray,
                fontSize: 9,
                fontWeight: '400',
            },
        },

        ':nth-child(1n) > :not(:first-of-type)': {
            paddingLeft: 12,
        },
        ':nth-child(1n) > :not(:last-of-type)': {
            borderRight: `1px solid ${COLORS.lightGray}`,
        },
    },
    link: {
        textDecoration: 'none',
        color: COLORS.dark,
        ':hover': {
            textDecoration: 'underline',
            textDecorationColor: COLORS.skyblue,
            color: COLORS.skyblue,
        },
    },
    viewBox: {
        height: 16,
        minWidth: 40,
        borderRadius: 128,
        background: COLORS.smoothGray,
        color: COLORS.midGray,
        fontSize: 9,
        fontWeight: '500',
    },
    tag: {
        height: 24,
        boxSizing: 'border-box',
        padding: '0 6px',
        borderRadius: 4,
        fontSize: 11,
        fontWeight: '500',
    },
})

const columnTextStyleSheet = (color) => css(StyleSheet.create({
    main: {
        all: 'unset',
        color,
        fontSize: 9,
        fontWeight: '400',
    },
}).main)
