import React from 'react'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import Layout from '../components/common/Layout'
import MainReports from '../components/dashboard/MainReports'
import UpcomingEvents from '../components/dashboard/UpcomingEvents'
import DashboardHeader from '../components/dashboard/DashboardHeader'
import NewEmployeesBar from '../components/dashboard/NewEmployeesBar'
import PostsByVacancies from '../components/dashboard/PostsByVacancies'
import { cp } from '../utils/checkPermission'

export default function Dashboard() {
    return (
        <Layout>
            <div className={css(s.wr)}>
                <DashboardHeader />

                {cp('dashboard.vacancies') && (
                    <MainReports />
                )}

                <UpcomingEvents />

                <div className={cn('justify-between', css(s.can))}>
                    <NewEmployeesBar />

                    <PostsByVacancies />
                </div>

                {/* <CandidateSourcesBar /> */}

                {/* <ClosedApplicationsBar /> */}

                {/* <ApplicationsByDepartments /> */}

                {/* <HiredEmployees /> */}

                {/* <AverageApplication /> */}
            </div>
        </Layout>
    )
}

const s = StyleSheet.create({
    wr: {
        paddingBottom: 36,
    },
    can: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gap: 24,
        height: 398,
        alignItems: 'start',
    },
})
