import React from 'react'
import { Radio } from '../common/Radio'

export function RadioSection({ name, options, filter, setFilter }) {
    const onCheck = (value) => {
        if (filter[name] !== value) setFilter((prev) => ({ ...prev, [name]: value }))
    }

    return (
        <ul className="no_dot_list is-flex direction-column gap-2">
            {options.map((option) => (
                <li key={option.id} className="justify-between">
                    <Radio
                        name={name}
                        value={option.id}
                        onCheck={onCheck}
                        label={option.name}
                        checked={filter[name] === option.id}
                    />
                </li>
            ))}
        </ul>
    )
}
