import React, { useEffect, useRef, useState } from 'react'
import { css, StyleSheet } from 'aphrodite'
import { Formik } from 'formik'
import { useNavigate } from 'react-router-dom'
import { isEmpty } from 'lodash'
import Layout from '../common/Layout'
import Header from './vacancyForm/Header'
import NavigationBar from './vacancyForm/NavigationBar'
import Edit from './Edit'
import Preview from './Preview'
import { useQueryParams } from '../../hooks/queryString'
import { Experience, banknoteOptions } from '../../utils/options'

const formInitialValues = {
    pushToHh: false,
    webPublish: false,
    hhBillingType: null,
    position: '',
    employeeCount: '1',
    department: '',
    country: [{ value: 97, label: 'Узбекистан' }],
    region: '', // hhAreaId
    name: '',
    location: '',
    experience: Experience[0],
    salary: { from: '', to: '', currency: banknoteOptions[0] },
    education: '',
    tag: [],
    startAt: '',
    deadline: '',
    languageLevel: [{ language: '', lvl: null }],
    comment: '',
    description: '',
    recruiter: '',
    responsible: '',
    format: '',
    schedule: '',
    skills: [],
    knowledge: [],
    options: [{ key: '', value: '' }],
    hidden: false,
    publish: false,
    templateSave: false,
}

export default function VacancyForm({ initialValues = {}, onSubmit, loading }) {
    const [init, setInit] = useState(formInitialValues)

    // Костыль :(
    useEffect(() => {
        if (!init.isReinitialized && (!isEmpty(initialValues) || !isEmpty(templateInfo))) {
            setInit({ ...formInitialValues, ...templateInfo, ...initialValues })
        }
    }, [initialValues])
    // ====

    const [templateInfo, setTemplateInfo] = useState({})
    const [currentStep, setCurrentStep] = useState(1)
    const stepRefs = useRef(Array.from({ length: 4 }, () => React.createRef()))

    const scrollToStep = (step) => {
        if (stepRefs.current[step - 1].current) {
            stepRefs.current[step - 1].current.scrollIntoView({ behavior: 'smooth' })
        }
    }

    const navigate = useNavigate()
    const { mode } = useQueryParams()

    useEffect(() => {
        if (!mode || !['edit', 'preview'].includes(mode)) navigate('?mode=edit')
    }, [])

    const handleSubmit = (data) => {
        if (mode === 'preview') onSubmit(data)
        else navigate('?mode=preview')
    }

    return (
        <Layout className={css(s.ly)} padded={false}>
            <Formik
                enableReinitialize
                initialValues={init}
                onSubmit={handleSubmit}>
                {({ setFieldValue, values, errors }) => {
                    const { department, position, employeeCount, name, description } = values
                    const requiredFields = { department, position, employeeCount, name }

                    return (
                        <>
                            <Header
                                loading={loading}
                                setFieldValue={setFieldValue}
                                errors={errors}
                                navigate={navigate}
                                description={description}
                                setTemplateInfo={setTemplateInfo}
                                initialValues={initialValues}
                                isPreview={mode === 'preview'}
                                requiredFields={requiredFields}
                            />

                            <NavigationBar
                                values={values}
                                initialValues={initialValues}
                                currentStep={currentStep}
                                scrollToStep={scrollToStep}
                                setFieldValue={setFieldValue}
                                isPreview={mode === 'preview'}
                            />

                            <div id="rp-body" className={css(s.cont)}>
                                {mode === 'preview'
                                    ? (
                                        <Preview
                                            values={values}
                                            requiredFields={requiredFields}
                                            goBack={() => navigate('?mode=edit')} />
                                    )
                                    : (
                                        <Edit
                                            setCurrentStep={setCurrentStep}
                                            currentStep={currentStep}
                                            stepRefs={stepRefs}
                                            values={values}
                                            setFieldValue={setFieldValue} />
                                    )}
                            </div>
                        </>
                    )
                }}
            </Formik>
        </Layout>
    )
}

const s = StyleSheet.create({
    ly: {
        display: 'flex',
        flexDirection: 'column',
        scrollbarGutter: 'auto',
    },
    cont: {
        flex: 1,
        overflowY: 'auto',
        display: 'flex',
        width: '63.7%',
        padding: '1.5rem calc(36.3% / 2)',
        flexDirection: 'column',
        gap: '1.5rem',
    },
})
