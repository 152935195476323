import React from 'react'
import FilterBoard from '../filter/FilterBoard'
import { useLoad } from '../../hooks/request'
import { CANDIDATE_TAGS } from '../../urls'
import { dateRangeFilter } from '../../utils/filter'

export function CandidateFilter({ setCandidateFilter, candidateFilter }) {
    const loadTags = useLoad({ url: CANDIDATE_TAGS })

    const tags = loadTags.response ? loadTags.response.data : []

    const mainSections = [
        {
            id: 1,
            title: 'Пол',
            name: 'gender',
            isCheckbox: true,
            options: [
                { id: 1, name: 'Мужской', count: '10' },
                { id: 2, name: 'Женский', count: '29' },
                { id: 3, name: 'Не указано', count: '142' },
            ],
        },
        {
            id: 2,
            title: 'Опыт',
            name: 'experience',
            isCheckbox: true,
            options: [
                { id: 0, name: 'Без опыта', count: '29' },
                { id: 1, name: 'от 1 до 3 лет', count: '10' },
                { id: 2, name: 'от 3 до 6 лет', count: '22' },
                { id: 3, name: 'более 6 лет', count: '120' },
            ],
        },
        {
            id: 3,
            title: 'Возраст',
            name: 'age',
            isCheckbox: true,
            options: [
                { id: 0, name: 'до 20 лет', count: '29' },
                { id: 1, name: 'от 20 до 30 лет', count: '10' },
                { id: 2, name: 'от 30 до 40 лет', count: '22' },
                { id: 3, name: 'cтарше 40 лет', count: '120' },
            ],
        },
        {
            id: 4,
            title: 'Зарплата',
            name: 'salary',
            isRange: true,
            options: [],
        },
    ]

    const candidateSections = [
        {
            id: 1,
            title: 'Дата отклика',
            name: 'createdDate',
            isRadio: true,
            options: dateRangeFilter,
        },
        // {
        //     id: 2,
        //     isSelect: true,
        //     name: 'recruiters',
        //     title: 'Рекрутеры',
        //     customSelectOption: true,
        //     options: [
        //         { value: 1, label: 'Test Name 1' },
        //         { value: 2, label: 'Test Name 2' },
        //         { value: 3, label: 'Test Name 3' },
        //         { value: 4, label: 'Test Name 4' },
        //         { value: 5, label: 'Test Name 5' },
        //     ],
        // },
        // {
        //     id: 3,
        //     isSelect: true,
        //     name: 'responsible',
        //     title: 'Ответственный',
        //     customSelectOption: true,
        //     options: [
        //         { value: 1, label: 'Test Name 1' },
        //         { value: 2, label: 'Test Name 2' },
        //         { value: 3, label: 'Test Name 3' },
        //         { value: 4, label: 'Test Name 4' },
        //         { value: 5, label: 'Test Name 5' },
        //     ],
        // },
        {
            id: 4,
            title: 'Статус кандидата',
            name: 'tag',
            isTag: true,
            options: tags,
            loading: loadTags.loading,
            meta: { totalCount: tags ? tags.length : 0 },
        },
        // {
        //     id: 5,
        //     title: 'Способ добавления',
        //     name: 'addedBy',
        //     isCheckbox: true,
        //     options: [
        //         { id: 1, name: 'до 20 лет', count: '29' },
        //         { id: 2, name: 'от 20 до 30 лет', count: '10' },
        //         { id: 3, name: 'от 30 до 40 лет', count: '22' },
        //         { id: 4, name: 'cтарше 40 лет', count: '120' },
        //     ],
        // },
    ]

    // responsible, recruiter

    const tabs = [
        { key: 1, name: 'main', label: 'Основное', sections: mainSections },
        { key: 2, name: 'candidate', label: 'Кандидат', sections: candidateSections },
        { key: 3, name: 'competencies', label: 'Компетенции' },
    ]
    return (
        <FilterBoard setFilter={setCandidateFilter} tabs={tabs} filter={candidateFilter} />
    )
}
