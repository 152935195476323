import React from 'react'
import { css, StyleSheet } from 'aphrodite'
import CompanyMainForm from './CompanyMainForm'
import RolesSettings from './RolesSettings'
import Locations from '../locations/Locations'
import Departments from '../departments/Departments'
import Positions from '../positions/Positions'
import Integrations from './Integrations'
import { useLoad } from '../../../hooks/request'
import { COMPANY_INFO } from '../../../urls'

export default function CompanyForm() {
    const loadInfo = useLoad({ url: COMPANY_INFO })
    const info = loadInfo.response ? loadInfo.response.data : {}

    return (
        <div className={css(s.cont)}>
            <div className="is-flex direction-column gap-7">
                <CompanyMainForm info={info} />

                <RolesSettings />

                <Integrations />
            </div>

            <div className="is-flex direction-column gap-7">
                <Locations />

                <Departments />

                <Positions />
            </div>

            {/* <FinanceForm /> */}
        </div>
    )
}

const s = StyleSheet.create({
    cont: {
        display: 'grid',
        gridTemplateColumns: '2fr 1fr',
        gap: 24,
        padding: '1.5rem 2rem',
        alignItems: 'start',
    },
})
