import React, { useState } from 'react'
import AdminCreate from '../settings/admins/AdminCreate'
import CandidatesSettings from './CandidatesSettings'
import RecruiterCreate from '../settings/recruiters/RecruiterCreate'

export default function CandidatesSidebar({ onClose }) {
    const [content, setContent] = useState('main')

    return content === 'admin'
        ? (
            <AdminCreate
                goBack
                images={[]}
                onClose={onClose}
                onSuccess={() => setContent('main')}
                onGoBack={() => setContent('main')}
            />
        )
        : content === 'recruiter'
            ? (
                <RecruiterCreate
                    goBack
                    images={[]}
                    onClose={onClose}
                    onSuccess={() => setContent('main')}
                    onGoBack={() => setContent('main')}
                />
            )
            : <CandidatesSettings onClose={onClose} setContent={setContent} />
}
