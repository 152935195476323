import { format } from './number'

export function removeExtraSpaces(str) {
    return str.replace(/\s+/g, ' ').trim()
}

export function strCutter(str, maxLength) {
    if (!str || typeof str !== 'string') return null

    return str.length > maxLength ? `${str.slice(0, maxLength - 3)}...` : str
}

export function fromToStr(from, to, helpText, noText) {
    const formattedFor = from ? format(from) : ''
    const formattedTo = to ? format(to) : ''

    return formattedFor && formattedTo
        ? `${formattedFor} - ${formattedTo} ${helpText}`
        : formattedFor
            ? `от ${formattedFor} ${helpText}`
            : formattedTo
                ? `до ${formattedTo} ${helpText}`
                : (noText || '')
}
