import React from 'react'
import '../../static/css/tooltip.css'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import { COLORS } from '../../utils/colors'

export default function AmazingTooltip({ children, trigger, className, onClick, position = 'center' }) {
    const isLeft = position === 'left'
    const isRight = position === 'right'

    return (
        <div onClick={onClick} className={cn(css(s.wrap), 'tooltip-wrap', className)}>
            <div className={cn(css(s.tooltip, isLeft && s.left, isRight && s.right), 'tooltip')}>
                {children}
            </div>

            <span className={css(s.trigger)}>
                {trigger}
            </span>
        </div>
    )
}

const s = StyleSheet.create({
    tooltip: {
        background: COLORS.lightBlack,
        padding: '10px 18px',
        borderRadius: 8,
        border: `1px solid ${COLORS.midGray}`,
        color: COLORS.white,
        fontSize: 13,
        fontWeight: '400',
        ':before': {
            background: COLORS.lightBlack,
            borderBottom: `1px solid ${COLORS.midGray}`,
            borderRight: `1px solid ${COLORS.midGray}`,
            borderRadius: '0 0 4px 0',
        },
    },
    left: {
        left: -10,
        ':before': {
            left: 25,
        },
    },
    right: {
        right: -10,
        ':before': {
            left: 'auto',
            right: 11,
        },
    },
    trigger: {
        display: 'flex',
    },
})
