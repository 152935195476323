import React from 'react'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import { COLORS } from '../../utils/colors'
import { ArrowSizeS } from '../svgs/Svgs'
import { LoadingSkeleton } from '../common/LoadingSkeleton'

function SkeletonItem() {
    return (
        <div className={css(s.box)}>
            <LoadingSkeleton width={143} height={16} />

            <div className={css(s.stat)}>
                <LoadingSkeleton width={159} height={28} />
            </div>

            <LoadingSkeleton width={206} height={16} />
        </div>
    )
}

export default function MainReportItem({ column, title, loading }) {
    const percent = column && +column.comparedPercent

    return !loading && column ? (
        <div className={css(s.box)}>
            <h3 className={css(s.title)}>{title}</h3>

            <div className={css(s.stat)}>
                <span className={css(s.curr)}>{column.currentMonth}&nbsp;</span>

                {column.all && <span className={css(s.all)}>{column.all} &nbsp;</span>}

                <span className={cn(css(s.percent), percent < 0 ? css(s.rd) : css(s.gr))}>
                    { percent !== 0
                        ? `${percent > 0 ? '+' : ''}${Number.isInteger(percent) ? percent : percent.toFixed(2)}%`
                        : <span style={{ color: '#F6C11D' }}>&#8212;</span>}&nbsp;
                </span>

                {percent !== 0 && (
                    <ArrowSizeS
                        rotate={percent < 0 ? 0 : 180}
                        color={percent < 0 ? COLORS.lightRed : '#18D88B'} />
                )}
            </div>

            <h3 className={css(s.statInDate)}>В прошлом месяце {column.lastMonth}</h3>
        </div>
    ) : <SkeletonItem />
}

const s = StyleSheet.create({
    box: {
        height: 128,
        boxSizing: 'border-box',
        padding: 20,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        background: COLORS.white,
        border: '1px solid #EDEDED',
        boxShadow: '0px 2px 8px rgba(136, 136, 136, 0.25)',
        borderRadius: 12,
    },
    title: {
        fontSize: 15,
        fontWeight: '600',
        color: COLORS.lightBlack,
    },
    stat: {
        display: 'flex',
        alignItems: 'center',
        margin: '16px 0 8px',
    },
    statInDate: {
        fontSize: 14,
        fontWeight: '400',
        color: '#696974',
    },
    curr: {
        fontSize: 24,
        fontWeight: '600',
        color: COLORS.dark,
    },
    all: {
        fontSize: 16,
        fontWeight: '500',
        color: COLORS.gray,
    },
    percent: {
        fontSize: 14,
        fontWeight: '600',
    },
    rd: {
        color: COLORS.lightRed,
    },
    gr: {
        color: '#18D88B',
    },
})
