import Registration from '../pages/auth/Registration'
import Projects from '../pages/Projects'
import AddProject from '../pages/AddProject'
import Confirmation from '../pages/auth/Confirmation'
import Vacancies from '../pages/Vacancies'
import Candidates from '../pages/Candidates'
import Employees from '../pages/Employees'
import Settings from '../pages/Settings'
import VacancyCreate from '../pages/VacancyCreate'
import LocationCreate from '../pages/LocationCreate'
import LocationUpdate from '../pages/LocationUpdate'
import VacancyDetail from '../pages/VacancyDetail'
import CandidateCreate from '../pages/CandidateCreate'
import EmployeeCreate from '../pages/EmployeeCreate'
import Authorization from '../pages/auth/Authorization'
import NewPassword from '../pages/auth/NewPassword'
import RequestResetPassword from '../pages/auth/RequestResetPassword'
import EmployeeUpdate from '../pages/EmployeeUpdate'
import VacancyUpdate from '../pages/VacancyUpdate'
import VacancyDetailPublic from '../pages/VacancyDetailPublic'
import EmployeeUnArchive from '../pages/EmployeeUnArchive'
import Dashboard from '../pages/Dashboard'
import DeleteAccount from '../pages/DeleteAccount'
import Reports from '../pages/Reports'

const token = localStorage.getItem('token')

/* eslint-disable max-len */
export const protectedRoutes = [
    { path: '/auth/recruiter', redirectPath: '/dashboard', protectedBy: !token, component: Authorization },
    { path: '/registration/recruiter', redirectPath: '/dashboard', protectedBy: !token, component: Registration },
    { path: '/reset-password', redirectPath: '/dashboard', protectedBy: !token, component: RequestResetPassword },
    { path: '/new-password', redirectPath: '/dashboard', protectedBy: !token, component: NewPassword },
    { path: '/confirmation/recruiter', redirectPath: '/registration/recruiter', protectedBy: !token, component: Confirmation },

    { path: '/projects', component: Projects },
    { path: '/project/add', component: AddProject },

    { path: '/dashboard', component: Dashboard },
    // { path: '/dashboard/event/create', component: EventCreate },

    { path: '/vacancies/form/create', component: VacancyCreate },
    { path: '/vacancies', component: Vacancies },
    { path: '/vacancies/:id/info', component: VacancyDetail },
    { path: '/vacancies/:id/form/update', component: VacancyUpdate },

    { path: '/candidates', component: Candidates },
    { path: '/candidates/:vacancyId/form/create', component: CandidateCreate, permission: 'candidates.create' },

    { path: '/employees/', component: Employees },
    { path: '/employees/create', component: EmployeeCreate, permission: 'employees.create' },
    { path: '/employees/update/:id', component: EmployeeUpdate }, // TODO issue edit yourself - permission: 'employees.update'
    { path: '/employees/unarchive/:id', component: EmployeeUnArchive },

    { path: '/reports/vacancy-info', component: Reports },
    { path: '/reports/applicant-sources', component: Reports },
    { path: '/reports/vacancy-metrics', component: Reports },
    { path: '/reports/user-metrics', component: Reports },

    // { path: '/calendar/:id', component: Calendar },

    { path: '/settings/personal', component: Settings },
    { path: '/settings/company', component: Settings },
    { path: '/settings/company/location/form/create', component: LocationCreate, permission: 'settings.company.edit_location' },
    { path: '/settings/company/location/form/:id/update', component: LocationUpdate, permission: 'settings.company.edit_location' },
    { path: '/settings/company-info', component: Settings, permission: 'settings.companyPublic.view' },
    { path: '/settings/company-info/vacancy/:id', component: VacancyDetailPublic, permission: 'settings.companyPublic.view' },
    { path: '/delete-account/confirm', component: DeleteAccount, protectedBy: true },
]

/* eslint-enable max-len */
