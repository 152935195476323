import React from 'react'
import cn from 'classnames'
import { css, StyleSheet } from 'aphrodite'
import { CandidateExperience, CandidateInfo, StatusAndSource } from './CardInfo'
import { COLORS } from '../../../utils/colors'

export default function CardInProgress({ data, isDragging, setModal }) {
    return (
        <div
            className={cn(css(s.wrap), { [css(s.onDrag)]: isDragging })}
            onClick={() => setModal({ act: true, id: data.id, type: 'detail' })}
        >
            <div className="is-flex gap-2 direction-column">
                <CandidateInfo data={data} />

                <div className={cn(
                    'is-flex direction-column gap-3',
                )}>
                    <CandidateExperience data={data} />

                    <StatusAndSource data={data} />
                </div>
            </div>
        </div>
    )
}

const s = StyleSheet.create({
    wrap: {
        backgroundColor: '#fff',
        padding: 12,
        cursor: 'pointer',
        borderRadius: 4,
        border: `1px solid ${COLORS.smoothGray}`,
        boxShadow: '0px 2px 4px rgba(136, 136, 136, 0.25)',
        ':hover': {
            border: `1px solid ${COLORS.lightGray}`,
            boxShadow: '0px 2px 4px rgba(136, 136, 136, 0.35)',
        },
    },
    onDrag: {
        background: 'rgba(255, 255, 255, 0.1)',
        border: `1px solid ${COLORS.skyblue}`,
        boxShadow: '0px 4px 16px rgba(0, 113, 218, 0.4)',
        backdropFilter: 'blur(4px)',
    },
})
