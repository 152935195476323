import React, { memo, useState } from 'react'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import { isEmpty } from 'lodash'
import { BillCircle, Briefcase, DoubleChevron, HourGlass, RecruiterVacancies, UserCheck } from '../svgs/Svgs'
import { MoreInfo } from './MoreInfo'
import { format } from '../../utils/number'
import { MainInfo } from './MainInfo'
import { COLORS } from '../../utils/colors'
import { fromToStr } from '../../utils/string'
import { LoadingSkeleton } from '../common/LoadingSkeleton'
import { getFullDate } from '../../utils/date'

function Header({ section, setSection, isShort, setIsShort }) {
    return (
        <div className={cn('justify-between-start', css(s.wrapper))}>
            <div className={cn('align-center', css(s.types))}>
                <div
                    datatype={section === 'main' ? 'selected' : undefined} // eslint-disable-line
                    className={css(s.tab)}
                    onClick={() => setSection('main')}>
                    <h3 className={cn(
                        'align-center',
                        css(s.typeTxt),
                        { [css(s.actTypeTxt)]: section === 'main' },
                    )}>
                        Основная информация
                    </h3>

                    <div className={cn(css(s.ind), { [css(s.actInd)]: section === 'main' })} />
                </div>

                <div
                    datatype={section === 'more' ? 'selected' : undefined} // eslint-disable-line
                    className={css(s.tab)}
                    onClick={() => setSection('more')}>
                    <h3 className={cn(
                        'align-center',
                        css(s.typeTxt),
                        { [css(s.actTypeTxt)]: section === 'more' },
                    )}>
                        Дополнительная информация
                    </h3>

                    <div className={cn(css(s.ind), { [css(s.actInd)]: section === 'more' })} />
                </div>

                {/* <div */}
                {/*     datatype={section === 'comments' ? 'selected' : undefined} // eslint-disable-line */}
                {/*     className={css(s.tab)} */}
                {/*     onClick={() => setSection('comments')}> */}
                {/*     <h3 className={cn( */}
                {/*         'align-center', */}
                {/*         css(s.typeTxt), */}
                {/*         { [css(s.actTypeTxt)]: section === 'comments' }, */}
                {/*     )}> */}
                {/*         Комментарии */}
                {/*     </h3> */}

                {/*     <div className={cn(css(s.ind), { [css(s.actInd)]: section === 'comments' })} /> */}
                {/* </div> */}
            </div>

            <div
                onClick={() => setIsShort(!isShort)}
                className={cn(css(s.trig), { [css(s.actTrig)]: isShort })}>
                <DoubleChevron />
            </div>
        </div>
    )
}

function ShortHeader({ isShort, setIsShort, data }) {
    return (
        <div className={cn('justify-between gap-6', css(s.shortList))}>
            <div className="align-center">
                {!isEmpty(data.tags) ? (
                    <div className={cn('is-flex gap-2', css(s.tags))}>
                        <span
                            className={cn('align-center gap-1 pointer', tagStyles(data.tags[0].color))}>
                            {data.tags[0].name}
                        </span>

                        {data.tags.length > 1 ? (
                            <span className={cn('align-center gap-1 pointer', tagStyles(COLORS.midGray))}>
                                +{data.tags.length - 1}
                            </span>
                        ) : null}
                    </div>
                ) : <div>&#8212;&#8212;&#8212;</div>}

                {!isEmpty(data.department) && (
                    <div className={cn('flex-1', css(s.listItem))}>
                        <RecruiterVacancies />

                        <div>
                            <h3>В отдел</h3>

                            <h3 className="overflow-wrap-anywhere">{data.department.name}</h3>
                        </div>
                    </div>
                )}

                {data.employeeCount && (
                    <div className={css(s.listItem)}>
                        <UserCheck />

                        <div>
                            <h3>Сотрудников</h3>

                            <h3>{format(data.employeeCount.toString())} сотрудника</h3>
                        </div>
                    </div>
                )}

                <div className={css(s.listItem)}>
                    <HourGlass />

                    <div>
                        <h3>Дедлайн</h3>

                        <h3>
                            {data.deadline
                                ? getFullDate(data.deadline, true)
                                : <div>&#8212;&#8212;&#8212;</div>}
                        </h3>
                    </div>
                </div>

                <div className={css(s.listItem)}>
                    <BillCircle />

                    <div>
                        <h3>Зарплатная вилка</h3>

                        <h3>
                            {data.salary
                                // eslint-disable-next-line max-len
                                ? fromToStr(data.salary.from ? format(data.salary.from) : '', data.salary.to ? format(data.salary.to) : '', data.currency, <div>&#8212;&#8212;&#8212;</div>)
                                : <LoadingSkeleton width="8rem" height={22} />}
                        </h3>
                    </div>
                </div>

                <div className={css(s.listItem)}>
                    <Briefcase />

                    <div>
                        <h3>Опыт работы</h3>

                        <h3>
                            {data.experience
                                // eslint-disable-next-line max-len
                                ? fromToStr(data.experience.from ? data.experience.from.toString() : '', data.experience.to ? data.experience.to.toString() : '', 'лет', 'не важно')
                                : <div>&#8212;&#8212;&#8212;</div>}
                        </h3>
                    </div>
                </div>
            </div>

            <div
                onClick={() => setIsShort(!isShort)}
                className={cn(css(s.trig), { [css(s.actTrig)]: isShort })}>
                <DoubleChevron />
            </div>
        </div>
    )
}

const MemoizeComponent = ({ data, loading, isShort, setIsShort }) => {
    const [section, setSection] = useState('main')

    return (
        <div className={css(s.info)}>
            { isShort
                ? <ShortHeader data={data} isShort={isShort} setIsShort={setIsShort} />
                : <Header isShort={isShort} setIsShort={setIsShort} section={section} setSection={setSection} />}

            <div className={cn(css(s.box), { [css(s.shortBox)]: isShort })}>
                {section === 'main' && <MainInfo loading={loading} data={data} isShort={isShort} />}

                {
                    (section === 'more' || section === 'comments')
                    && <MoreInfo data={data} isShort={isShort} loading={loading} />
                }
            </div>
        </div>
    )
}

export const VacancyFullInfo = memo(MemoizeComponent)

const s = StyleSheet.create({
    info: {
        background: COLORS.white,
        margin: '0 1.5rem',
        borderRadius: 4,
    },
    wrapper: {
        height: 59,
        boxSizing: 'border-box',
        borderBottom: `1px solid ${COLORS.lightGray}`,
        padding: '0 1.5rem',
        display: 'flex',
        alignItems: 'flex-end',
        transition: 'all 0.5s linear',
    },
    actInd: {
        backgroundColor: COLORS.mainColor,
    },
    actTypeTxt: {
        color: COLORS.mainColor,
    },
    tab: {
        cursor: 'pointer',
        ':not([datatype=selected])': {
            ':hover': {
                ':nth-child(1n) > :last-child': {
                    backgroundColor: COLORS.gray,
                },
            },
        },
    },
    typeTxt: {
        fontSize: 14,
        fontWeight: '600',
        color: COLORS.gray,
    },
    ind: {
        width: '100%',
        height: 3,
        marginTop: 18,
        borderTopLeftRadius: 12,
        borderTopRightRadius: 12,
        marginBottom: -1,
    },
    types: {
        gap: '2rem',
    },
    trig: {
        display: 'inline-flex',
        height: '100%',
        alignItems: 'center',
        cursor: 'pointer',
        ':nth-child(1n) > :first-child': {
            transform: 'rotateZ(0deg)',
            transition: 'all 0.5s ease-out',
            ':hover': {
                ':nth-child(1n) > *': {
                    stroke: COLORS.skyblue,
                },
            },
        },
    },
    actTrig: {
        ':nth-child(1n) > :first-child': {
            transform: 'rotateZ(180deg)',
            transition: 'all 0.5s ease-out',
        },
    },
    box: {
        height: '24rem',
        transition: 'all 0.5s ease-out',
        overflow: 'hidden',
    },
    shortBox: {
        height: 0,
        transition: 'all 0.5s ease-out',
    },
    stat: {
        background: 'rgba(243, 59, 92, 0.1)',
        border: '1px solid rgba(243, 59, 92, 0.2)',
        borderRadius: 4,
        fontWeight: '500',
        fontSize: 11,
        color: COLORS.lightRed,
        padding: '5px 6px',
    },
    shortList: {
        padding: '0 1.5rem',
        display: 'flex',
        height: 58,
        alignItems: 'center',
        transition: 'all 0.5s linear',
        ':nth-child(1n) > :first-child': {
            gap: '2rem',
        },
    },
    listItem: {
        display: 'flex',
        alignItems: 'center',
        gap: 8,
        ':nth-child(1n) > :first-child': {
            ':nth-child(1n) > *': {
                stroke: COLORS.spanishGray,
            },
        },
        ':nth-child(1n) > :last-child': {
            display: 'flex',
            flexDirection: 'column',
            fontWeight: '500',
            ':nth-child(1n) > :first-child': {
                color: COLORS.midGray,
                fontSize: 12,
            },
            ':nth-child(1n) > :last-child': {
                color: COLORS.dark,
                fontSize: 13,
            },
        },
    },
})

const tagStyles = (color) => css(StyleSheet.create({
    main: {
        color,
        height: 'calc(1.5rem - 2px)',
        padding: '0 5px',
        borderRadius: 4,
        fontWeight: '500',
        fontSize: 11,
        whiteSpace: 'nowrap',
        outline: 'none',
        border: `1px solid ${color + 33}`,
        background: `${color}1A`,
        ':nth-child(1n) > :first-child': {
            ':nth-child(1n) > :first-child': {
                ':nth-child(1n) > *': {
                    stroke: color,
                },
            },
        },
    },
}).main)
