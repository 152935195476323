import React, { useState } from 'react'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import { Form, Formik } from 'formik'
import axios from 'axios'
import { isEmpty } from 'lodash'
import { Briefcase } from '../../svgs/Svgs'
import FormInputPhone from '../../common/FormInputPhone'
import { useDeleteRequest, useLoad, usePutRequest } from '../../../hooks/request'
import { COMPANY_LOGO, COMPANY_UPDATE, INDUSTRIES_LIST } from '../../../urls'
import { useMessage } from '../../../hooks/message'
import { domain } from '../../../utils/request'
import { auth } from '../../../utils/auth'
import { COLORS } from '../../../utils/colors'
import { email, phone, required, validator } from '../../../utils/validators'
import CompanyImage from './CompanyImage'
import { cp } from '../../../utils/checkPermission'
import FormInput from '../../common/FormInput'
import SelectForm from '../../common/SelectForm'
import { formatPhoneNumber } from '../../../utils/number'

export default function CompanyMainForm({ info }) {
    const [showMessage] = useMessage()
    const loadIndustries = useLoad({ url: INDUSTRIES_LIST })
    const update = usePutRequest({ url: COMPANY_UPDATE })
    const deleteLogo = useDeleteRequest({ url: COMPANY_LOGO })
    const [img, setImg] = useState('')

    const isDisabled = !cp('settings.company.edit')

    const industries = loadIndustries.response
        ? loadIndustries.response.data.map(({ id, name }) => ({ value: id, label: name }))
        : []

    const initialValues = {
        name: info.name || '',
        email: info.email || '',
        phoneNumber: info.phoneNumber ? formatPhoneNumber(info.phoneNumber).replace('+998 ', '') : '',
        industryId: industries.filter((i) => i.value === info.industryId)[0] || '',
        logo: info.logo ? info.logo[1] : '',
    }

    const onSubmit = async (data, helpers) => {
        if (isDisabled) return
        if (typeof data.logo === 'object') {
            const formData = new FormData()
            formData.append('file', data.logo)

            axios.post(domain + COMPANY_LOGO, formData, { ...auth() })
                .catch(({ response }) => {
                    showMessage(response.data.errors[0].message, 'error-msg')
                })
        }

        if (data.logo === '') await deleteLogo.request()

        const { success, error } = await update.request({ data: {
            name: data.name,
            email: data.email,
            phoneNumber: `+998${data.phoneNumber.replace(/\s+/g, '')}`,
            industry_id: data.industryId.value,
        } })

        if (success) {
            helpers.setTouched({})
            showMessage('Успешно!', 'success-msg')
            return
        }

        showMessage(error.data.errors[0].message || error.data.message, 'error-msg')
    }

    return (
        <Formik
            enableReinitialize
            onSubmit={onSubmit}
            initialValues={initialValues}>
            {({ setFieldValue, values, dirty, touched, setTouched }) => (
                <div className={css(s.form)}>
                    <Form>
                        <div className={cn('justify-between', css(s.formName))}>
                            <div className="align-center gap-2">
                                <Briefcase />
                                <h3>КОМПАНИЯ</h3>
                            </div>

                            {dirty && !isEmpty(touched) && (
                                <div className={cn('align-center gap-2', css(s.btnGr))}>
                                    <button
                                        type="reset"
                                        onClick={() => setImg(info.logo ? info.logo[1] : '')}
                                        className={css(s.cancelBtn)}>Отмена
                                    </button>

                                    <button
                                        type="submit"
                                        className={cn(css(s.saveBtn), 'align-center')}>
                                        Сохранить
                                    </button>
                                </div>
                            )}
                        </div>

                        <div className={css(s.accSet)}>
                            <CompanyImage
                                img={img}
                                disabled={isDisabled}
                                info={info}
                                values={values}
                                setImg={setImg}
                                touched={touched}
                                setTouched={setTouched}
                                setFieldValue={setFieldValue} />

                            <div className={css(s.accInpGr)}>
                                <FormInput
                                    name="name"
                                    errorWithMessage={false}
                                    label="Название компании"
                                    validate={required}
                                    disabled={isDisabled}
                                    labelClassName={css(s.label)}
                                    placeholder="Введите полное имя"
                                    fieldClassName={css(s.field)}
                                />

                                {/* <SelectSimple */}
                                {/*    name="industryId" */}
                                {/*    label="Отрасль работы компании" */}
                                {/*    placeholder="---" */}
                                {/*    isDisabled={isDisabled} */}
                                {/*    labelClassName={css(s.label)} */}
                                {/*    valueContainerStyles={{ padding: '11px 16px' }} */}
                                {/*    placeholderStyles={{ fontSize: 14, fontWeight: '500', color: COLORS.gray }} */}
                                {/*    value={values.industryId} */}
                                {/*    contClassname={css(s.inpCont)} */}
                                {/*    onChange={(val) => setFieldValue('industryId', val)} */}
                                {/*    className={css(s.select)} */}
                                {/*    options={industries} /> */}

                                <SelectForm
                                    contClassname={css(s.textarea)}
                                    placeholderStyles={{ fontSize: 14, fontWeight: '500', color: COLORS.gray }}
                                    labelClassName={css(s.lb)}
                                    valueContainerStyles={{ padding: '11.5px 16px' }}
                                    indicatorStyle={{ paddingRight: 10 }}
                                    name="industryId"
                                    validate={required}
                                    value={values.industryId}
                                    label="Отрасль работы компании"
                                    placeholder="---"
                                    isDisabled={isDisabled}
                                    options={industries} />

                                <FormInput
                                    name="email"
                                    label="Электронная почта"
                                    validate={email}
                                    errorWithMessage={false}
                                    disabled={isDisabled}
                                    noRequiredValidation
                                    labelClassName={css(s.label)}
                                    placeholder="example@gmail.com"
                                    fieldClassName={css(s.field)}
                                />

                                <FormInputPhone
                                    validate={validator(phone, required)}
                                    disabled={isDisabled}
                                    errorWithMessage={false}
                                    name="phoneNumber"
                                    placeholder="_ _  _ _ _  _ _  _ _"
                                    label="Номер телефона"
                                    fieldClassName={css(s.field)}
                                    labelClassName={css(s.phoneLabel)}
                                />
                            </div>
                        </div>
                    </Form>
                </div>
            )}
        </Formik>
    )
}

const s = StyleSheet.create({
    form: {
        backgroundColor: '#fff',
        borderRadius: 4,
    },
    formName: {
        height: 'calc(3rem - 1px)',
        padding: '0 24px',
        fontSize: 13,
        fontWeight: '600',
        color: COLORS.dark,
        borderBottom: `1px solid ${COLORS.lightGray}`,
        textTransform: 'uppercase',
    },
    inp: {
        width: '100%',
        border: `1px solid ${COLORS.lightGray}`,
        borderRadius: 4,
        padding: '13.5px 16px',
    },
    label: {
        paddingBottom: 7,
        fontWeight: '500',
        fontSize: 12,
        color: COLORS.midGray,
    },
    phoneLabel: {
        fontSize: 12,
        fontWeight: '500',
        color: COLORS.midGray,
        paddingBottom: 7,
        display: 'block',
    },
    accSet: {
        padding: '15px 24px 24px',
    },
    loadPhCont: {
        gap: 32,
    },
    loadPh: {
        background: COLORS.mintCream,
        border: '1px solid #E3F8EE',
        boxShadow: '0px 2px 20px rgba(137, 152, 141, 0.02)',
        borderRadius: 4,
        padding: '10px 16px',
        fontWeight: '500',
        fontSize: 14,
        color: COLORS.mainColor,
        gap: 8,
        ':hover': {
            boxShadow: '0px 2px 4px rgba(168, 168, 168, 0.15)',
            border: '1px solid rgba(46, 169, 125, 0.5)',
        },
    },
    accInpGr: {
        marginTop: 23,
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gap: 24,
    },
    field: {
        position: 'relative',
    },
    avatarImg: {
        width: 96,
        height: 96,
        border: `1px solid ${COLORS.lightGray}`,
        borderRadius: 4,
    },
    btn_group: {
        gap: 24,
        ':nth-child(1n) > :first-child': {
            padding: '12px 16px',
            color: COLORS.mainColor,
            fontSize: 14,
            fontWeight: '500',
            border: `1px solid ${COLORS.smoothGray}`,
            background: COLORS.mintCream,
            boxShadow: '0 4px 20px rgba(44, 67, 50, 0.05)',
            borderRadius: 4,
            height: 38,
        },
        ':nth-child(1n) > :last-child': {
            padding: '12px 15px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            border: `1px solid ${COLORS.gray}`,
            background: 'transparent',
            filter: 'drop-shadow(0px 4px 12px rgba(44, 67, 50, 0.1))',
            borderRadius: 4,
            color: COLORS.gray,
            fontSize: 13,
            fontWeight: '500',
            height: 38,
            ':nth-child(1n) > :first-child': {
                marginRight: 8,
            },
        },
    },
    inpCont: {
        marginBottom: 16,
        ':last-of-type': {
            marginBottom: 0,
        },
    },
    select: {
        border: `1px solid ${COLORS.lightGray}`,
        borderRadius: 4,
    },
    cancelBtn: {
        color: COLORS.gray,
        border: `1px solid ${COLORS.gray}`,
        background: 'transparent',
        padding: '0 12px',
    },
    saveBtn: {
        color: COLORS.white,
        border: 'none',
        padding: '0 8px',
        background: `linear-gradient(180deg, ${COLORS.mainColor} 0%, ${COLORS.lightMainColor} 100%)`,
        // eslint-disable-next-line max-len
        boxShadow: '0px 4px 12px rgba(44, 67, 50, 0.1), inset 0px -1px 0px rgba(0, 0, 0, 0.25), inset 0px 1px 0px rgba(255, 255, 255, 0.1)',
    },
    btnGr: {
        ':nth-child(1n) > *': {
            height: 28,
            boxSizing: 'border-box',
            fontWeight: '500',
            fontSize: 11,
            borderRadius: 4,
            cursor: 'pointer',
        },
    },
})
