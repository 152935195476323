import React, { createContext, useEffect, useRef, useState } from 'react'
import { IntlProvider } from 'react-intl'
import Message from '../components/common/Message'
import { SidebarWithTrigger } from '../components/common/SidebarWithTrigger'
import { usePersistState } from '../hooks/state'
import { translationsMessage } from '../translate'
import { checkUser } from '../utils/auth'
import { fetchUserInfo } from '../utils/request'

export const Context = createContext()

export default function GlobalContext({ children }) {
    const [text, setText] = useState('')
    // eslint-disable-next-line max-len
    const [theme, setTheme] = usePersistState('theme', { rB: 2, eB: 2, cB: 2 }) // background colors: rB - vacancies, eb - employees, cB - candidates
    const [lang, setLang] = usePersistState('lang', 'ru')
    const [className, setClassName] = useState('')
    const [sidebar, setSidebar] = useState({})
    const [locations, setLocations] = useState([])
    const [departments, setDepartments] = useState([])
    const [positions, setPositions] = useState([])
    const [user, setUser] = useState({ data: {}, loading: true })

    const childRef = useRef()

    const closeSidebar = () => childRef.current.onClose()

    const fetchInfo = async () => {
        try {
            const { success, error } = await checkUser(setText, setClassName)

            if (success) fetchUserInfo(setUser)

            if (error) setUser({ ...user, loading: false })
        } catch (e) {
            console.log('ERROR: ', e)
        }
    }

    useEffect(async () => {
        if (window.location.pathname !== '/delete-account/confirm') fetchInfo()
    }, [])

    return (
        <Context.Provider value={{
            lang,
            user,
            theme,
            setUser,
            setLang,
            setText,
            setTheme,
            locations,
            fetchInfo,
            positions,
            setSidebar,
            departments,
            setPositions,
            closeSidebar,
            setClassName,
            setLocations,
            setDepartments,
        }}>
            <IntlProvider locale={lang} messages={translationsMessage(lang)}>
                {children}

                {text ? (
                    <Message
                        text={text}
                        className={className}
                        closeMessage={() => setText(null)} />
                ) : null}

                {sidebar && sidebar.content ? (
                    <SidebarWithTrigger
                        isActive
                        ref={childRef}
                        onClose={() => {
                            if (typeof sidebar.onClose === 'function') {
                                sidebar.onClose()
                            }
                            setSidebar(null)
                        }}>
                        {sidebar.content}
                    </SidebarWithTrigger>
                ) : null}
            </IntlProvider>
        </Context.Provider>
    )
}
