import React, { useEffect, useRef, useState } from 'react'
import cn from 'classnames'
import { debounce, isEmpty } from 'lodash'
import { css, StyleSheet } from 'aphrodite'
import { removeExtraSpaces } from '../../utils/string'
import { CloseSidebar } from '../svgs/Svgs'
import { COLORS } from '../../utils/colors'

const getName = (e) => (e.nameEn || e.nameRu || e.nameUz) // TODO remove after api fix

export default function EditableContent({
    data,
    maxLength,
    setData,
    dataItem,
    setCreating,
    creating,
    onRemove,
    options,
    setOptions,
    onChange,
}) {
    const [isActive, setIsActive] = useState(false)
    const ref = useRef()

    useEffect(() => {
        if (creating) ref.current.focus()
    }, [])

    const onBlur = (event) => {
        setTimeout(() => {
            setIsActive(false)
            if (creating) setCreating(false)

            if (!event.target.textContent || !options.some((kn) => getName(kn) === event.target.textContent)) {
                // eslint-disable-next-line no-param-reassign
                event.target.textContent = dataItem.name
            }

            setOptions([])
        }, 250)
    }

    const onSelect = (id, name) => {
        const value = { id, name }

        if (creating) {
            if (!isEmpty(name)) setData(data.length ? [...data, value] : [value])
            setCreating(false)
        } else {
            const indexToReplace = data.findIndex((e) => e.id === dataItem.id)

            if (indexToReplace !== -1) {
                // eslint-disable-next-line no-param-reassign
                data[indexToReplace] = value
            }
            setData([...data])
        }
    }

    let lastKeyCode

    function handleKeyDown(event) {
        const content = event.target.textContent
        const { length } = content

        if (event.keyCode === 13) ref.current.blur()

        if (
            (length >= maxLength && !allowKey(event))
            || (length === 0 && (event.keyCode === 8 || event.keyCode === 32))
            || (event.keyCode === 32 && lastKeyCode === event.keyCode)
        ) {
            event.stopPropagation()
            event.preventDefault()
        }

        lastKeyCode = event.keyCode
    }

    function handlePaste(e) {
        let pastedData

        e.stopPropagation()
        e.preventDefault()

        const clipboardData = e.clipboardData || window.clipboardData
        pastedData = clipboardData.getData('Text')
        pastedData = removeExtraSpaces(pastedData)

        const textContent = e.target.textContent || ''
        const textContentLength = textContent.length

        pastedData = pastedData.substring(0, maxLength - textContentLength)
        window.document.execCommand('insertText', false, pastedData)
    }

    function allowKey(event) {
        const { keyCode } = event
        const ctrlDown = event.ctrlKey || event.metaKey
        return ([8, 46, 37, 38, 39, 40].includes(keyCode) || (ctrlDown && [65, 67, 82, 86, 88, 90].includes(keyCode)))
    }

    return (
        <div className="is-relative">
            <div className={cn('align-center', css(s.tag))}>
                <div
                    ref={ref}
                    contentEditable
                    role="textbox"
                    tabIndex={0}
                    suppressContentEditableWarning
                    onFocus={() => setIsActive(true)}
                    onBlur={onBlur}
                    onInput={debounce((event) => {
                    // eslint-disable-next-line no-param-reassign
                        event.target.textContent = event.target.textContent.split('  ').join(' ').trimStart()
                        if (typeof onChange === 'function') onChange(event.target.textContent)
                    }, 500)}
                    onKeyDown={(event) => handleKeyDown(event)}
                    onPaste={(event) => handlePaste(event)}
                    className={cn('flex-cent', css(s.skill))}>
                    {dataItem.name}
                </div>

                <div onClick={() => onRemove(dataItem.id)} className={cn('flex-cent pointer', css(s.closing))}>
                    <CloseSidebar />
                </div>
            </div>

            {isActive && !!options.length && (
                <div className={cn('is-absolute z-index-1', css(s.drp))}>
                    <ul className="no_dot_list">
                        {options.map((kn) => (
                            <li
                                id={kn.id}
                                className={css(s.drpItem)}
                                onClick={(e) => {
                                    e.preventDefault()
                                    onSelect(kn.id, getName(kn))
                                }}
                            >
                                {getName(kn)}
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    )
}

const s = StyleSheet.create({
    tag: {
        height: 28,
        boxSizing: 'border-box',
        background: COLORS.white,
        border: `1px solid ${COLORS.gray}`,
        borderRadius: 4,
        color: COLORS.lightBlack,
        fontWeight: '500',
        fontSize: 13,
    },
    skill: {
        outline: 'none',
        border: 'none',
        padding: '0 8px',
    },
    closing: {
        padding: '0 6px',
        borderLeft: `1px solid ${COLORS.gray}`,
        background: COLORS.smoothGray,
        height: '100%',
        borderRadius: '0 4px 4px 0',
    },
    drp: {
        background: COLORS.white,
        border: `1px solid ${COLORS.lightGray}`,
        borderRadius: 4,
        marginTop: 10,
        maxHeight: 184,
        overflowY: 'auto',
        ':nth-child(1n) > :first-child': {
            ':nth-child(1n) > :first-child': {
                borderTopRightRadius: 4,
                borderTopLeftRadius: 4,
            },
            ':nth-child(1n) > :last-child': {
                borderBottomWidth: 0,
                borderBottomRightRadius: 4,
                borderBottomLeftRadius: 4,
            },
        },
    },
    drpItem: {
        width: 300,
        padding: '10px 8px',
        borderBottom: `1px solid ${COLORS.lightGray}`,
        color: COLORS.dark,
        cursor: 'pointer',
        overflowWrap: 'anywhere',
        fontSize: 13,
        fontWeight: '500',
        ':hover': {
            backgroundColor: COLORS.mintCream,
        },
    },
})
