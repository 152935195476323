// eslint-disable-next-line import/no-cycle
import { getDateRangeByKey, getFullDate } from './date'

export const genderFilter = {
    1: 'male',
    2: 'female',
    3: 'not_set',
}

export const dateRangeFilter = [
    { id: 1, name: 'Сегодня', key: 'today' },
    { id: 2, name: 'На этой неделе', key: 'week' },
    { id: 3, name: 'В этом месяце', key: 'month' },
    { id: 4, name: 'В прошлом месяце', key: 'lastMonth' },
]

export const sortFilter = [
    { id: '-v_created_at', name: 'Сначала новые', key: 'newFirst' },
    { id: 'v_created_at', name: 'Сначала старые', key: 'oldFirst' },
    { id: 'salary_min', name: 'Зарплата по возрастанию', key: 'salaryAsc' },
    { id: '-salary_max', name: 'Зарплата по убыванию', key: 'salaryDesc' },
]

export function fetchDataByFilter($api, filter, additionalParams = {}) {
    if (filter.act) {
        const {
            edu,
            age,
            gender,
            salary,
            skills,
            deadline,
            languages,
            positions,
            experience,
            departments,
            createdDate,
            startedDate,
            industry,
            interval,
            addedDate,
            sortBy,
            tag,
        } = filter

        const params = {
            experience: experience ? experience.join(',') : undefined,
            age: age ? age.join(',') : undefined,
            salary: salary || undefined,
            educationLevel: edu ? edu.map((e) => e.value).join(',') : undefined,
            // Private filter
            gender: gender ? gender.map((value) => genderFilter[value] || '').join(',') : undefined,
            department: departments ? departments.join(',') : undefined,
            position: positions ? positions.join(',') : undefined,
            lang: languages ? Object.keys(languages).join(',') : undefined,
            level: languages ? Object.values(languages).map((e) => e.join(',')).join(':') : undefined,
            skill: skills ? skills.join(',') : undefined,
            createdDate: getDateRangeByKey(createdDate)
                ? getDateRangeByKey(createdDate).map((date) => getFullDate(date)).join(':')
                : undefined,
            startedDate: getDateRangeByKey(startedDate)
                ? getDateRangeByKey(startedDate).map((date) => getFullDate(date)).join(':')
                : undefined,
            deadline: getDateRangeByKey(deadline)
                ? getDateRangeByKey(deadline).map((date) => getFullDate(date)).join(':')
                : undefined,
            tag,
            // Filter for public vacancies
            industry: industry ? industry.join(',') : undefined,
            date: addedDate,
            interval: interval || undefined,
            sort: sortBy || undefined,
        }

        $api.request({ params: { ...params, ...additionalParams } })
        return
    }
    $api.request({ params: additionalParams })
}
