import React from 'react'
import FilterBoard from '../filter/FilterBoard'
import { useLoad } from '../../hooks/request'
import { DEPARTMENT_LIST, POSITIONS_LIST } from '../../urls'
import { dateRangeFilter } from '../../utils/filter'

export function EmployeeFilter({ employeeFilter, setEmployeeFilter }) {
    const loadDepartments = useLoad({ url: DEPARTMENT_LIST })
    const loadPositions = useLoad({ url: POSITIONS_LIST })

    const departments = loadDepartments.response ? loadDepartments.response.data : []
    const departmentsMeta = loadDepartments.response ? loadDepartments.response.meta : []

    const positions = loadPositions.response ? loadPositions.response.data : []
    const positionsMeta = loadPositions.response ? loadPositions.response.meta : []

    const mainSections = [
        {
            id: 1,
            title: 'Пол',
            name: 'gender',
            isCheckbox: true,
            options: [
                { id: 1, name: 'Мужской', count: '10' },
                { id: 2, name: 'Женский', count: '29' },
                { id: 3, name: 'Не указано', count: '142' },
            ],
        },
        {
            id: 2,
            title: 'Отдел',
            name: 'departments',
            isSearchable: true,
            placeholder: 'Найти отдел',
            isCheckbox: true,
            options: departments,
            loading: loadDepartments.loading,
            meta: { ...departmentsMeta, url: DEPARTMENT_LIST, title: 'Все отделы' },
        },
        {
            id: 3,
            title: 'Должность',
            name: 'positions',
            isSearchable: true,
            placeholder: 'Найти должность',
            isCheckbox: true,
            options: positions,
            loading: loadPositions.loading,
            meta: { ...positionsMeta, url: POSITIONS_LIST, title: 'Все должности' },
        },
        {
            id: 4,
            title: 'Опыт',
            name: 'experience',
            isCheckbox: true,
            options: [
                { id: 0, name: 'Без опыта', count: '29' },
                { id: 1, name: 'от 1 до 3 лет', count: '10' },
                { id: 2, name: 'от 3 до 6 лет', count: '22' },
                { id: 3, name: 'более 6 лет', count: '120' },
            ],
        },
        {
            id: 5,
            title: 'Возраст',
            name: 'age',
            isCheckbox: true,
            options: [
                { id: 0, name: 'до 20 лет', count: '29' },
                { id: 1, name: 'от 20 до 30 лет', count: '10' },
                { id: 2, name: 'от 30 до 40 лет', count: '22' },
                { id: 3, name: 'cтарше 40 лет', count: '120' },
            ],
        },
        {
            id: 6,
            title: 'Зарплата',
            name: 'salary',
            isRange: true,
            options: [],
        },
    ]

    const aboutSections = [
        {
            id: 1,
            title: 'Дата добавления',
            name: 'createdDate',
            isRadio: true,
            options: dateRangeFilter,
        },
    ]

    // responsible, recruiter

    const tabs = [
        { key: 1, name: 'main', label: 'Основное', sections: mainSections },
        { key: 2, name: 'about', label: 'Добавление', sections: aboutSections },
        { key: 3, name: 'competencies', label: 'Компетенции' },
    ]
    return (
        <FilterBoard setFilter={setEmployeeFilter} tabs={tabs} filter={employeeFilter} />
    )
}
