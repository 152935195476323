import React, { useEffect, useState } from 'react'
import { css, StyleSheet } from 'aphrodite'
import Table from '../../../common/Table'
import { TableViewItem } from './TableViewItem'
import { TableHeader } from './TableHeader'
import { useGetRequest } from '../../../../hooks/request'
import { COMPANY_VACANCIES } from '../../../../urls'

export function TableView({ info, goTo, handleActivateVacancy }) {
    const [page, setPage] = useState(1)
    const [pageSize, setPageSize] = useState(20)
    const getVacancies = useGetRequest({ url: COMPANY_VACANCIES, params: { page, 'per-page': pageSize } })

    const meta = getVacancies.response ? getVacancies.response.meta : []
    const vacancies = getVacancies.response ? getVacancies.response.data : []

    useEffect(() => {
        getVacancies.request()
    }, [page, pageSize])

    return (
        <div className={css(s.tableWrap)}>
            <Table
                isStripped
                isHoverable
                page={meta.currentPage}
                pageCount={meta.pageCount}
                count={meta.totalCount}
                setPage={setPage}
                pageSize={pageSize}
                setPageSize={setPageSize}
                className={css(s.table)}
                items={vacancies}
                loading={getVacancies.loading}
                loaderItemCount={20}
                customHeader={<TableHeader />}
                renderItem={(item) => (
                    <TableViewItem
                        info={info}
                        item={item}
                        key={item.id}
                        goTo={goTo}
                        getVacancies={getVacancies}
                        handleActivateVacancy={handleActivateVacancy}
                    />
                )}
            />
        </div>
    )
}

const s = StyleSheet.create({
    table: {
        width: '100%',
        textAlign: 'center',
    },
    tableWrap: {
        padding: '0 24px',
        '@media (max-width: 1024px)': {
            display: 'none',
        },
    },
})
