import React from 'react'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import moment from 'moment'
import { EventEmpty } from '../svgs/SvgPictures'
import { MapPin } from '../svgs/Svgs'
import { COLORS } from '../../utils/colors'
import AmazingTooltip from '../common/AmazingTooltip'
import { LoadingSkeleton } from '../common/LoadingSkeleton'

const loadingCount = [1, 2, 3, 4, 5]

export default function EventsBoard({ date, events, loading }) {
    // const navigate = useNavigate()
    const selectedDate = moment(date).format('DD MMMM YYYY')

    const imageSrc = (item) => (item.photo && item.photo.length > 0 ? item.photo[7] : '')

    return (
        <div className={cn('is-flex direction-column flex-1 z-index-1', css(s.reps))}>
            <div className={css(s.top)}>
                <h3 className={css(s.date)}>
                    Мои ближайшие события <span>•</span> {events.length}
                </h3>

                {/* <h3 className={cn(css(s.add), 'dis_sl')} onClick={() => navigate('event/create')}> */}
                {/*     Новое событие */}
                {/* </h3> */}
            </div>

            <div className={cn('flex-1 overflowY-auto', css(s.list))}>
                {!loading ? events.map((item) => (
                    <div key={item.id} className={cn('justify-between', css(s.lCard))}>
                        <div className={cn('is-flex direction-column justify-center gap-1', css(s.txtGr))}>
                            <h3>Собеседование с кандидатом {item.firstName} {item.lastName}</h3>

                            <div className="align-center gap-1">
                                <MapPin color={COLORS.spanishGray} /> <h3>{item.location}</h3>
                            </div>
                        </div>

                        <div>
                            <div className={cn('justify-end', css(s.dateTime))}>
                                <h3>{`${selectedDate} • ${moment(item.time, 'HH:mm:ss').format('HH:mm')}`}</h3>
                                {item.tag}
                            </div>

                            {item.otherUsers && (
                                <div style={{ flexDirection: 'row-reverse' }} className="justify-start">
                                    {item.otherUsers.map((e, ind) => (
                                        <AmazingTooltip
                                            position="right"
                                            trigger={
                                                imageSrc(e) ? (
                                                    <img
                                                        style={{ right: -ind * 5 }}
                                                        alt="#avatar"
                                                        src={imageSrc(e)}
                                                        className={css(s.img)}
                                                    />
                                                ) : (
                                                    <div
                                                        style={{ right: -ind * 5 }}
                                                        className={cn('flex-cent', css(s.defaultAvatar))}>
                                                        <h3>
                                                            {e.firstName && e.lastName
                                                                ? e.firstName.slice(0, 1) + e.lastName.slice(0, 1)
                                                                : ''}
                                                        </h3>
                                                    </div>
                                                )
                                            }
                                        >
                                            <h3 className={css(s.tip)}>{e.firstName} {e.lastName}</h3>
                                        </AmazingTooltip>
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>
                )) : loadingCount.map((item) => (
                    <div key={item} className={cn('justify-between-start', css(s.lCard))}>
                        <div className="is-flex direction-column gap-2">
                            <LoadingSkeleton height={18} width={400} />

                            <LoadingSkeleton height={14} width={180} />
                        </div>

                        <div>
                            <LoadingSkeleton height={16} />
                        </div>
                    </div>
                ))}

                {!loading && !events.length && (
                    <div className={css(s.emp_cont)}>
                        <EventEmpty />

                        <h3 className={css(s.emp_txt)}>Добавляйте новые события и мероприятия 🙌</h3>
                    </div>
                )}
            </div>
        </div>
    )
}
const s = StyleSheet.create({
    reps: {
        border: '1px solid #EDEDED',
        boxShadow: '0px 2px 8px rgba(136, 136, 136, 0.25)',
        borderRadius: 12,
        width: 'calc(61% - 45px - 1.25rem)',
        height: '100%',
        backgroundColor: '#fff',
    },
    top: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '16px 0 8px 20px',
        borderBottom: '1px solid #EDEDED',
    },
    list: {
        padding: '12px 16px 16px',
        '::-webkit-scrollbar': {
            display: 'none',
        },
    },
    date: {
        color: COLORS.dark,
        fontSize: 15,
        fontWeight: '600',
        // ':'
    },
    add: {
        fontSize: 12,
        color: COLORS.skyblue,
        fontWeight: '500',
        cursor: 'pointer',
    },
    emp_cont: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        height: '95%',
    },
    tip: {
        whiteSpace: 'nowrap',
    },
    emp_txt: {
        fontSize: 13,
        fontWeight: '600',
        color: COLORS.midGray,
    },
    lCard: {
        background: COLORS.white,
        height: '3rem',
        boxShadow: '0px 2px 8px rgba(136, 136, 136, 0.25)',
        borderRadius: 8,
        marginBottom: 12,
        padding: '12px 20px',
        ':last-of-type': {
            marginBottom: 0,
        },
    },
    txtGr: {
        ':nth-child(1n) > :first-child': {
            fontSize: 13,
            fontWeight: '600',
            color: COLORS.dark,
        },
        ':nth-child(1n) > :last-child': {
            fontSize: 12,
            fontWeight: '400',
            color: '#575757',
        },
    },
    img: {
        border: `1px solid ${COLORS.lightGray}`,
        width: 20,
        height: 20,
        borderRadius: 50,
        position: 'relative',
    },
    defaultAvatar: {
        background: '#1B93C0',
        width: 18,
        height: 18,
        borderRadius: 32,
        border: `1px solid ${COLORS.lightGray}`,
        fontWeight: '600',
        position: 'relative',
        fontSize: 9,
        color: '#FFFFFF',
        textTransform: 'uppercase',
    },
    dateTime: {
        marginBottom: 6,
        ':nth-child(1n) > :first-child': {
            fontSize: 11,
            fontWeight: '500',
            color: COLORS.lightBlack,
        },
    },
})
