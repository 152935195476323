import React, { useContext, useEffect, useState } from 'react'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import Layout from '../components/common/Layout'
import { EmployeesHeader } from '../components/employees/EmployeesHeader'
import EmployeesTable from '../components/employees/EmployeesTable'
import { useGetRequest } from '../hooks/request'
import { EMPLOYEE_ARCHIVE_LIST, EMPLOYEE_LIST } from '../urls'
import { FilterContext } from '../contexts/FilterContext'
import { EmployeeFilter } from '../components/employees/EmployeeFilter'
import { fetchDataByFilter } from '../utils/filter'
import { Context } from '../contexts/GlobalContext'
import { DASHBOARD_COLORS } from '../utils/colors'

function EmployeesInfo({ employeeFilter, setEmployeeFilter }) {
    const [page, setPage] = useState(1)
    const [role, setRole] = useState([])
    const [keyword, setKeyword] = useState('')
    const [pageSize, setPageSize] = useState(10)
    const [listType, setListType] = useState(1)

    const additionalParams = { page, 'per-page': pageSize, keyword, sort: '-id', role: role.join(',') }
    const getActiveEmployees = useGetRequest({ url: EMPLOYEE_LIST, params: additionalParams })
    const getArchivedEmployees = useGetRequest({ url: EMPLOYEE_ARCHIVE_LIST, params: additionalParams })

    const getInfo = listType === 1 ? getActiveEmployees : getArchivedEmployees

    useEffect(() => {
        fetchDataByFilter(getInfo, employeeFilter, additionalParams)
    }, [page, pageSize, keyword, employeeFilter, role, listType])

    const data = getInfo.response ? getInfo.response.data : []
    const meta = getInfo.response ? getInfo.response.meta : []

    return (
        <React.Fragment>
            <EmployeesHeader
                listType={listType}
                setListType={setListType}
                count={meta.totalCount}
                employeeFilter={employeeFilter}
                setEmployeeFilter={setEmployeeFilter}
            />

            <EmployeesTable
                data={data}
                meta={meta}
                setPage={setPage}
                keyword={keyword}
                role={role}
                isArchive={listType !== 1}
                setRole={setRole}
                setKeyword={setKeyword}
                loading={getInfo.loading}
                setPageSize={setPageSize}
                request={getInfo.request}
            />
        </React.Fragment>
    )
}

export default function Employees() {
    const { theme } = useContext(Context)
    const { employeeFilter, setEmployeeFilter } = useContext(FilterContext)

    return (
        <Layout padded={false} className={css(s.ly, bgColor(DASHBOARD_COLORS[theme.eB]))}>
            <div className={cn('is-flex', css(s.cont))}>
                {employeeFilter.act
                    ? <EmployeeFilter employeeFilter={employeeFilter} setEmployeeFilter={setEmployeeFilter} />
                    : null}

                <div className={css(s.infoCont, employeeFilter.act ? s.w80 : s.w100)}> {/* TODO fix width */}
                    <EmployeesInfo setEmployeeFilter={setEmployeeFilter} employeeFilter={employeeFilter} />
                </div>
            </div>
        </Layout>
    )
}

const s = StyleSheet.create({
    ly: {
        width: '88.4%',
        scrollbarGutter: 'stable',
    },
    cont: {
        height: '100%',
    },
    w100: {
        width: '100%',
    },
    w80: {
        width: '80%',
    },
    infoCont: {
        scrollbarGutter: 'stable',
        height: '100%',
        padding: '0 18px 0 24px',
        overflowY: 'auto',
    },
})

const bgColor = (backgroundColor) => StyleSheet.create({
    main: {
        backgroundColor,
    },
}).main
