import React, { memo } from 'react'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import SelectForm from '../../common/SelectForm'
import FormInput from '../../common/FormInput'
import { COLORS } from '../../../utils/colors'
import { email, required, validator } from '../../../utils/validators'
import { useLoad } from '../../../hooks/request'
import { EMPLOYEE_LIST } from '../../../urls'
import InputForm from '../../common/InputForm'
import { roleOptions } from '../../../utils/options'
import ToggleSwitch from '../../common/ToggleSwitch'

function MemoizedComponent({ values, setFieldValue }) {
    const loadEmployees = useLoad({ url: EMPLOYEE_LIST })

    const employees = loadEmployees.response
        ? loadEmployees.response.data.map((e) => ({ value: e.id, label: `${e.firstName} ${e.lastName}` }))
        : []

    const isMailRequired = values.role ? values.role.value : ''

    return (
        <div className={css(s.inpGr)}>
            <SelectForm
                loading={loadEmployees.loading}
                contClassname={css(s.textarea)}
                labelContClassName={css(s.lab)}
                placeholderStyles={{ color: COLORS.gray, fontFamily: 'Roboto, sans-serif' }}
                labelClassName={css(s.lb)}
                valueContainerStyles={{ padding: '11.5px 16px' }}
                indicatorStyle={{ paddingRight: 10 }}
                label="Начальник сотрудника"
                validate={required}
                errorWithMessage={false}
                placeholder="Выберите начальника"
                name="headOfEmployee"
                options={employees} />

            <SelectForm
                validate={values.email ? required : {}}
                labelContClassName={css(s.lab)}
                errorWithMessage={false}
                placeholderStyles={{ color: COLORS.gray, fontFamily: 'Roboto, sans-serif' }}
                labelClassName={css(s.lb)}
                valueContainerStyles={{ padding: '11.5px 16px' }}
                indicatorStyle={{ paddingRight: 10 }}
                label="Роль сотрудника в системе"
                placeholder="Например: “Рекрутер”"
                name="role"
                options={[{ value: null, label: 'Не указано' }, ...roleOptions.slice(1, roleOptions.length)]} />

            <FormInput
                name="email"
                errorWithMessage={false}
                validate={validator(email, isMailRequired ? required : () => {})}
                noRequiredValidation={!isMailRequired}
                placeholder="Введите"
                className={css(s.addInp)}
                label="Электронная почта (для регистрации)"
                fieldClassName={css(s.fld)}
                labelClassName={css(s.addInpLab)} />

            <InputForm
                name="description"
                component="textarea"
                placeholder="Введите"
                contClassname={css(s.textarea)}
                label="Сообщение кандидату (опционально)"
                labelClassName={css(s.lbAboutEvent)}
                className={css(s.inp)}
            />

            <div className={cn('align-start', css(s.textarea))}>
                <div
                    className={cn('align-center gap-5 pointer', css(s.askCont))}
                    onClick={() => setFieldValue('askAnswer', !values.askAnswer)}
                >
                    <h3 className={css(s.askText)}>
                        Спросить в сообщении у кандидата, готов(а) ли он(а) выйти на работу
                    </h3>

                    <ToggleSwitch
                        name="askAnswer"
                        isChecked={values.askAnswer}
                        onClick={(e) => e.stopPropagation()}
                    />
                </div>
            </div>
        </div>
    )
}

export const MainInformation = memo(MemoizedComponent)

const s = StyleSheet.create({
    formName: {
        height: 'calc(3rem - 1px)',
        padding: '0 22px 0 24px',
        fontSize: 13,
        fontWeight: '600',
        color: '#181A19',
        borderBottom: `1px solid ${COLORS.lightGray}`,
        textTransform: 'uppercase',
    },
    inpGr: {
        padding: 24,
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gridGap: 24,
        overflowY: 'auto',
        alignItems: 'end',
    },
    lb: {
        fontSize: 12,
        fontWeight: '500',
        color: COLORS.midGray,
    },
    addInp: {
        position: 'relative',
        border: `1px solid ${COLORS.lightGray}`,
        borderRadius: 4,
        fontSize: 14,
        fontWeight: '500',
        color: '#181A19',
    },
    addInpLab: {
        fontSize: 12,
        fontWeight: '500',
        color: COLORS.midGray,
        marginBottom: 8,
    },
    fld: {
        position: 'relative',
        // padding
    },
    lab: {
        ':nth-child(1n) > span': {
            display: 'inline-flex',
        },
    },
    inp: {
        width: '100%',
        border: `1px solid ${COLORS.lightGray}`,
        borderRadius: 4,
        padding: '14px 16px',
        height: 'calc(8.5rem - 25px)',
        ':first-of-type': {
            paddingTop: 15,
        },
    },
    lbAboutEvent: {
        paddingBottom: 8,
    },
    textarea: {
        gridColumn: '1 / 3',
    },
    askCont: {
        borderRadius: 4,
        border: `1px solid ${COLORS.lightGray}`,
        background: COLORS.white,
        height: 44,
        padding: '0 16px',
    },
    askText: {
        color: COLORS.gray,
        fontSize: 14,
        fontWeight: '500',
    },
})
