import React, { useEffect, useState } from 'react'
import { css, StyleSheet } from 'aphrodite'
import Table from '../common/Table'
import { useGetRequest } from '../../hooks/request'
import { CANDIDATE_TABLE_LIST } from '../../urls'
import CandidateTableHeader from './CandidateTableHeader'
import CandidateTableItem from './CandidateTableItem'
import { fetchDataByFilter } from '../../utils/filter'
import Modal from '../common/Modal'
import CandidateDetail from './CandidateDetail'
import { Offer } from './offer/Offer'
import { Reject } from './reject/Reject'
import { Meetup } from './meetup/Meetup'
import { Accept } from './accept/Accept'

export default function CandidateTable({ candidateFilter, setCandidatesCount, selectedVacancy }) {
    const [page, setPage] = useState(1)
    const [pageSize, setPageSize] = useState(10)
    // const [sort, setSort] = useState({ id: '-id' })
    const [modal, setModal] = useState({ act: false, type: 'detail' })
    const getInfo = useGetRequest({ url: CANDIDATE_TABLE_LIST })

    const data = getInfo.response ? getInfo.response.data : Array(10).fill('1')
    const meta = getInfo.response ? getInfo.response.meta : []

    const additionalParams = {
        page,
        'per-page': pageSize,
        vacancy: selectedVacancy,
        // sort: Object.values(sort).filter((e) => e !== '').join('&'),
    }

    useEffect(() => {
        fetchDataByFilter(getInfo, candidateFilter, additionalParams)
    }, [page, pageSize, candidateFilter, selectedVacancy])

    useEffect(() => {
        if (meta) setCandidatesCount(meta.totalCount)
    }, [meta])

    const headerColumns = [
        { id: 1, name: 'Название вакансии', width: 'calc(100% * 2.75 / 12)' },
        { id: 2, name: 'Вакансия', width: 'calc(100% * 2.25 / 12)' },
        {
            id: 3,
            name: 'Колонка',
            // name: 'column_name',
            // value: sort,
            // isSortable: true,
            // setValue: setSort,
            width: 'calc(100% * 1.75 / 12)',
        },
        {
            id: 4,
            name: 'Опыт',
            // name: 'experience',
            // value: sort,
            // isSortable: true,
            // setValue: setSort,
            width: 'calc(100% * 1.5 / 12)',
        },
        { id: 5, name: 'Рекрутер', width: 'calc(100% * 1.75 / 12)' },
        {
            id: 6,
            name: 'Категория',
            // name: 'tag',
            // value: sort,
            // isSortable: true,
            // setValue: setSort,
            width: 'calc(100% * 1.5 / 12)',
        },
        { id: 7, name: '', width: 'calc(100% * 0.5 / 12)' },
    ]

    const modalContentStyle = {
        width: '100%',
        margin: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '12px 0',
    }

    return (
        <div className={css(s.tableWr)}>
            <Table
                isScrollable
                isStripped
                items={data}
                setPage={setPage}
                page={meta.currentPage}
                count={meta.totalCount}
                pageSize={meta.perPage}
                className={css(s.table)}
                setPageSize={setPageSize}
                loading={getInfo.loading}
                pageCount={meta.pageCount}
                isHoverable={!getInfo.loading}
                customHeader={<CandidateTableHeader headerColumns={headerColumns} />}
                renderItem={(item) => (
                    <CandidateTableItem
                        item={item}
                        loading={getInfo.loading}
                        onSendOffer={() => setModal({ act: true, id: item.id, type: 'offer', name: item.fullname })}
                        onMeetup={() => setModal({ act: true, id: item.id, type: 'meetup', name: item.fullname })}
                        onAccept={() => setModal({ act: true, id: item.id, type: 'accept', name: item.fullname })}
                        onReject={() => setModal({ act: true, id: item.id, type: 'reject', name: item.fullname })}
                        onClick={() => setModal({ act: true, id: item.id, type: 'detail' })} />
                )}
            />

            <Modal
                closeOnEscape
                open={modal.act}
                closeOnDocumentClick
                onClose={() => setModal({ act: false })}
                overlayStyle={{ top: 52, left: '5.625rem' }}
                contentStyle={modalContentStyle}>
                {modal.type === 'detail' && (
                    <CandidateDetail
                        id={modal.id}
                        setModal={setModal}
                        onClose={() => setModal({ act: false })} />
                )}
                {modal.type === 'offer' && (
                    <Offer
                        id={modal.id}
                        name={modal.name}
                        onSuccess={() => {
                            setModal({ act: false })
                        }}
                        onClose={() => setModal({ act: false })} />
                )}

                {modal.type === 'meetup' && (
                    <Meetup
                        id={modal.id}
                        name={modal.name}
                        onSuccess={() => {
                            setModal({ act: false })
                        }}
                        onClose={() => setModal({ act: false })} />
                )}

                {modal.type === 'accept' && (
                    <Accept
                        id={modal.id}
                        name={modal.name}
                        onSuccess={() => {
                            setModal({ act: false })
                        }}
                        onClose={() => setModal({ act: false })} />
                )}

                {modal.type === 'reject' && (
                    <Reject
                        id={modal.id}
                        name={modal.name}
                        onSuccess={() => {
                            setModal({ act: false })
                        }}
                        onClose={() => setModal({ act: false })}
                    />
                )}
            </Modal>
        </div>
    )
}

const s = StyleSheet.create({
    tableWr: {
        padding: '16px 24px 20px',
    },
    table: {
        width: '100%',
        textAlign: 'left',
        height: 'calc(100% - 50px)',
    },
})
