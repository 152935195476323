import React, { useContext, useEffect, useState } from 'react'
import { css, StyleSheet } from 'aphrodite'
import { Droppable } from 'react-beautiful-dnd'
import { isEmpty } from 'lodash'
import cn from 'classnames'
import { CheckSizeL, ThreeDotsMenu } from '../../svgs/Svgs'
import { AddVacancies } from '../../svgs/SvgPictures'
import PopupSimple from '../PopupSimple'
import { useColumnInfiniteScroll } from '../../../hooks/request'
import { CANDIDATE_CARDS } from '../../../urls'
import { COLORS as COLORS_PALETTE } from '../../../utils/colors'
import { FilterContext } from '../../../contexts/FilterContext'
import { getDateRangeByKey, getFullDate } from '../../../utils/date'
import { genderFilter } from '../../../utils/filter'
import { MainCard } from '../../candidates/columnCard/MainCard'

const COLORS = [
    { id: 1, name: 'Салатовый', color: COLORS_PALETTE.lightGray, backgroundColor: COLORS_PALETTE.saladColor },
    { id: 2, name: 'Оранжевый', color: '#F2994A', backgroundColor: 'rgba(242,153,74,0.1)' },
    { id: 3, name: 'Синий', color: COLORS_PALETTE.blue, backgroundColor: 'rgba(27,147,192,0.1)' },
    { id: 4, name: 'Фиолетовый', color: '#856EC6', backgroundColor: 'rgba(133,110,198,0.1)' },
    { id: 5, name: 'Зелёный', color: COLORS_PALETTE.green, backgroundColor: 'rgba(56,110,86,0.1)' },
    { id: 6, name: 'Черный', color: '#181a19', backgroundColor: 'rgba(24,26,25,0.1)' },
]

export function Column({ columnId, column, setColumns, vacancyId, getData, onChangeColumn, index }) {
    const { candidateFilter } = useContext(FilterContext)
    const [open, setOpen] = useState(false)

    const [scrollableId, setScrollableId] = useState(0)

    const filterParams = () => {
        if (candidateFilter.act) {
            const {
                edu,
                age,
                gender,
                salary,
                skills,
                deadline,
                languages,
                positions,
                experience,
                departments,
                createdDate,
                startedDate,
            } = candidateFilter

            return {
                gender: gender ? gender.map((value) => genderFilter[value] || '').join(',') : '',
                department: departments ? departments.join(',') : '',
                position: positions ? positions.join(',') : '',
                experience: experience ? experience.join(',') : '',
                age: age ? age.join(',') : '',
                salary: salary || '',
                lang: languages ? Object.keys(languages).join(',') : '',
                level: languages ? Object.values(languages).map((e) => e.join(',')).join(':') : '',
                skill: skills ? skills.join(',') : '',
                educationLevel: edu ? edu.map((e) => e.value).join(',') : '',
                createdDate: getDateRangeByKey(createdDate)
                    ? getDateRangeByKey(createdDate).map((date) => getFullDate(date)).join(':')
                    : [],
                startedDate: getDateRangeByKey(startedDate)
                    ? getDateRangeByKey(startedDate).map((date) => getFullDate(date)).join(':')
                    : [],
                deadline: getDateRangeByKey(deadline)
                    ? getDateRangeByKey(deadline).map((date) => getFullDate(date)).join(':')
                    : [],
            }
        }

        return {}
    }

    const getCardsOnScroll = useColumnInfiniteScroll(
        { url: CANDIDATE_CARDS.replace('{id}', column.id),
            params: vacancyId > 0 ? {
                vacancy: vacancyId,
                ...filterParams(),
            } : {} },
        [scrollableId, candidateFilter],
        2,
        scrollableId,
        column.count - 10,
        (cards) => (
            setColumns((prev) => ({
                ...prev,
                [columnId]: {
                    ...prev[columnId],
                    cards: [...prev[columnId].cards, ...cards.filter(
                        (e) => !prev[columnId].cards.map((i) => i.id).includes(e.id),
                    )],
                },
            }))
        ),
    )

    const listNodeId = `rbd-${column.id}`

    const onScroll = ({ target }) => {
        const viewport = target.scrollTop + target.clientHeight >= target.scrollHeight - 25

        if (viewport && column.count > 10 && !getCardsOnScroll.loading) {
            setScrollableId(column.id)
        }
    }

    useEffect(() => {
        const listNode = document.getElementById(listNodeId)
        listNode.addEventListener('scroll', onScroll)
        return () => {
            listNode.removeEventListener('scroll', onScroll)
        }
    }, [])

    const notDefaultColor = column.color.toLowerCase() !== '#c9d2ce'

    const s = createStyles(
        column.color,
        notDefaultColor ? `${column.color}1A` : '#F2FAF6',
        notDefaultColor ? column.color : COLORS_PALETTE.lightBlack,
        notDefaultColor ? column.color : COLORS_PALETTE.gray,
    )

    return (
        <div className={css(s.cont)}>
            <div className={cn(css(s.caption), 'justify-between')}>
                <h3 className={cn(css(s.title))}>
                    {column.colName} • {column.count}
                </h3>

                <div className={cn(css(s.add), 'align-center gap-5')}>
                    <PopupSimple
                        on="click"
                        open={open}
                        onOpen={() => setOpen(true)}
                        onClose={() => setOpen(false)}
                        trigger={(
                            <span style={{ height: 20 }} className="pointer">
                                <ThreeDotsMenu className={css(s.whIcon)} />
                            </span>
                        )}>
                        <div className={cn('is-flex direction-column', css(s.settings))}>
                            <div>
                                <h3>ВЫБРАТЬ ЦВЕТ</h3>
                            </div>

                            <ul className={css(s.colorsList)}>
                                {COLORS.map(({ color, name, id: colorId }) => (
                                    <li
                                        key={colorId}
                                        className="justify-between"
                                        onClick={() => onChangeColumn(column.id, index, color)}
                                    >
                                        <div
                                            className={cn(
                                                'align-center',
                                                { [css(s.actCol)]: column.color === color },
                                            )}>
                                            <div className={cn(css(s.colorFrame), colorFrames(color))} />
                                            {name}
                                        </div>

                                        {column.color === color ? <CheckSizeL /> : null}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </PopupSimple>
                </div>
            </div>

            <Droppable droppableId={columnId.toString()} key={columnId}>
                {(provided) => (
                    <div
                        id={listNodeId}
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        className={cn(css(s.lst))}>
                        {!isEmpty(column.cards) ? (
                            <React.Fragment>
                                {column.cards.map((item, ind) => (
                                    <MainCard
                                        data={item}
                                        key={item.id}
                                        cardIndex={ind}
                                        getData={getData}
                                        columnIndex={index}
                                        setColumns={setColumns}
                                    />
                                ))}

                                {(getCardsOnScroll.loading) && <div>LOADING...</div>}

                                {!getCardsOnScroll.loading && column.count > 10 && column.count > column.cards.length ? ( // eslint-disable-line
                                    <div ref={getCardsOnScroll.ref} />
                                ) : null }

                                {column.count > column.cards.length ? (
                                    <div id={`obs-${column.id}`} />
                                ) : null}
                            </React.Fragment>
                        ) : (
                            <div className={cn(css(s.noCardWrap))}>
                                <div>
                                    <AddVacancies />

                                    <h3 className={css(s.noCard)}>
                                        Если есть подходящие кандидаты, перетащите их сюда 🤓
                                    </h3>
                                </div>
                            </div>
                        )}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </div>
    )
}

const createStyles = (color, backgroundColor, titleColor, stroke) => StyleSheet.create({
    cont: {
        minWidth: 'calc(17rem - 1px)',
        width: 'calc(17rem - 1px)',
        height: '100%',
        transition: 'all 1s',
        ':last-of-type': {
            marginRight: 0,
        },
    },
    wrap: {
        height: '100%',
        paddingTop: 12,
        ':nth-child(1n) > :first-child': {
            marginTop: 0,
        },
    },
    add: {
        ':nth-child(1n) > :first-child': {
            ':hover': {
                ':nth-child(1n) > :first-child': {
                    stroke: COLORS_PALETTE.mainColor,
                },
            },
            ':nth-child(1n) > :first-child': {
                stroke: stroke || color,
                strokeWidth: 2,
            },
        },
        ':nth-child(1n) > :last-child': {
            ':nth-child(1n) > :first-child': {
                ':hover': {
                    ':nth-child(1n) > :first-child': {
                        stroke: COLORS_PALETTE.mainColor,
                    },
                },
            },
        },
    },
    whAddIcon: {
        ':nth-child(1n) > :first-child': {
            ':nth-child(1n) > :first-child': {
                stroke: '#fff',
            },
        },
    },
    list: {
        border: `1px solid ${COLORS_PALETTE.lightGray}`,
        borderTopWidth: 0,
        borderBottomRightRadius: 4,
        borderBottomLeftRadius: 4,
        '::-webkit-scrollbar': {
            display: 'none',
        },
    },
    caption: {
        borderTopRightRadius: 4,
        borderTopLeftRadius: 4,
        border: `1px solid ${color}`,
        height: 19,
        backgroundColor,
        padding: '9px 12px',
    },
    lst: {
        position: 'relative',
        padding: '12px 0 0px',
        background: COLORS_PALETTE.lotion,
        border: `1px solid ${color}`,
        borderRadius: '0 0 4px 4px',
        borderTopWidth: 0,
        boxSizing: 'border-box',
        overflowY: 'auto',
        overflowX: 'hidden',
        maxHeight: 'calc(100% - 39px)',
        minHeight: '80%',
        scrollbarGutter: 'stable',
    },
    title: {
        fontSize: 14,
        fontWeight: 500,
        color: titleColor || color,
    },
    noCardWrap: {
        position: 'absolute',
        paddingTop: '28.8%',
        ':nth-child(1n) > :first-child': {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
        },
    },
    noCard: {
        lineHeight: 1.2,
        fontSize: 13,
        fontWeight: '600',
        color: COLORS_PALETTE.midGray,
        textAlign: 'center',
        marginTop: '1.5rem',
    },
    colorFrame: {
        width: 20,
        height: 20,
        borderRadius: 50,
    },
    colorsList: {
        ':nth-child(1n) > *': {
            color: '#FFFFFFB3',
            fontSize: 12,
            fontWeight: '500',
            padding: '10px 12px',
            cursor: 'pointer',
            ':hover': {
                background: 'rgba(65, 70, 68, 0.6)',
            },
            ':nth-child(1n) > :first-child': {
                ':nth-child(1n) > :first-child': {
                    marginRight: 11,
                },
            },
        },
    },
    settings: {
        margin: 0,
        borderRadius: 4,
        width: '15.25rem',
        padding: '12px 0',
        background: 'rgba(65, 70, 68, 0.9)',
        backdropFilter: 'blur(6px)',
        ':nth-child(1n) > :first-child': {
            color: COLORS_PALETTE.lightGray,
            fontSize: 11,
            fontWeight: '400',
            padding: '0 12px',
            marginBottom: 5,
        },
        ':nth-child(1n) > :last-child': {
            color: COLORS.white,
            fontSize: 12,
            fontWeight: '500',
            ':nth-child(1n) > :first-child': {
                ':nth-child(1n) > :first-child': {
                    stroke: '#FFFFFFB2',
                },
            },
        },
    },
    actCol: {
        color: '#fff',
    },
    whIcon: {
        ':nth-child(1n) > *': {
            stroke: stroke || color,
        },
    },
    hr: {
        border: '1px solid rgba(99, 99, 99, 0.9)',
        backdropFilter: 'blur(8px)',
        margin: 0,
    },
})

const colorFrames = (backgroundColor) => css(StyleSheet.create({ cap: { backgroundColor } }).cap)
