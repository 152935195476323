import React, { useContext } from 'react'
import { css, StyleSheet } from 'aphrodite'
import { useNavigate } from 'react-router-dom'
import cn from 'classnames'
import moment from 'moment'
import LayoutAuth from '../components/common/LayoutAuth'
import { AddNewProject, DefaultProjectLogo } from '../components/svgs/Svgs'
import { useLoad, usePostRequest } from '../hooks/request'
import { COMPANY_LIST, COMPANY_SELECT } from '../urls'
import { useMessage } from '../hooks/message'
import { COLORS } from '../utils/colors'
import { roleOptions } from '../utils/options'
import { fetchUserInfo } from '../utils/request'
import { Context } from '../contexts/GlobalContext'

export default function Projects() {
    const { setUser } = useContext(Context)
    const navigate = useNavigate()

    const loadCompanies = useLoad({ url: COMPANY_LIST })
    const selectCompany = usePostRequest({ url: COMPANY_SELECT })
    const companies = loadCompanies.response ? loadCompanies.response.data : []
    const [showMessage] = useMessage()

    async function onSelect(id) {
        const { success, error } = await selectCompany.request({ data: { company_id: id } })

        if (success) {
            fetchUserInfo(setUser)

            const userStorage = JSON.parse(localStorage.getItem('user'))
            localStorage.setItem('user', JSON.stringify({ ...userStorage, companyId: id }))

            return navigate('/dashboard')
        }
        return showMessage(error.data.message || error.data.errors[0].message, 'error-msg')
    }

    return (
        <LayoutAuth>
            <div className={css(s.cont)}>
                <h3 className={css(s.title)}>Выберите компанию для работы</h3>

                <p className={css(s.subtitle)}>Выбрав компанию вы всегда можете поменять её</p>

                <div className={cn('pointer', css(s['p-cont']), { [css(s.load_cont)]: selectCompany.loading })}>
                    {companies.map(({ companyId, name, createdAt, role }) => (
                        <div onClick={() => onSelect(companyId)} key={companyId} className={css(s['p-box'])}>
                            <div>
                                <div className={cn('flex-cent', css(s.companyLogo))}>
                                    <DefaultProjectLogo />
                                </div>

                                <h3
                                    title={name}
                                    className={css(s.compName)}>
                                    {name.length >= 22 ? `${name.slice(0, 21)}...` : name}
                                </h3>

                                <div className="flex-cent">
                                    <div className={cn('align-center', css(s.role))}>
                                        {roleOptions[role - 1].label}
                                    </div>
                                </div>

                                <h4 className={css(s.createdAt)}>
                                    Дата создания: <span>{moment(createdAt).format('DD MMMM YYYY')}</span>
                                </h4>
                            </div>

                            {/* <Popup */}
                            {/*     open={open.includes(companyId)} */}
                            {/*     onOpen={() => setOpen([...open, companyId])} */}
                            {/*     onClose={() => setOpen(open.filter((e) => e !== companyId))} */}
                            {/*     position="bottom right" */}
                            {/*     contentStyle={{ marginTop: 12, overflowY: 'auto !important' }} */}
                            {/*     arrow={false} */}
                            {/*     trigger={<span style={{ height: 20 }} className="pointer">
                                    <ThreeDotsMenu /></span>}> */}
                            {/*     <ul className={css(s.pSet)}> */}
                            {/*         <li><EditPencil /> Изменить</li> */}

                            {/*         <li><Trash /> Удалить</li> */}
                            {/*     </ul> */}
                            {/* </Popup> */}
                        </div>
                    ))}
                    <div
                        className={css(s['add-box'])}
                        onClick={() => navigate('/project/add', { state: { isAllowed: true } })}>
                        <span className={css(s.addLogo)}>
                            <AddNewProject />
                        </span>

                        <h3 className={css(s.addTxt)}>Добавить компанию</h3>
                    </div>
                </div>
            </div>
        </LayoutAuth>
    )
}

const s = StyleSheet.create({
    cont: {
        padding: '0 19.4%',
    },
    title: {
        fontSize: 36,
        fontWeight: '600',
        color: '#181A19',
    },
    subtitle: {
        fontSize: 14,
        fontWeight: '500',
        color: COLORS.midGray,
        margin: '12px 0 32px',
    },
    'p-cont': {
        cursor: 'pointer',
        width: '100%',
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr',
        gridGap: '1.25rem',
    },
    'p-box': {
        padding: '19px 16px 16px',
        background: '#FFFFFF',
        border: `1px solid ${COLORS.smoothGray}`,
        boxShadow: '0 2px 8px rgba(136, 136, 136, 0.25)',
        borderRadius: 8,
        display: 'flex',
        ':nth-child(1n) > :first-child': {
            display: 'flex',
            flexDirection: 'column',
            width: '95.5%',
            paddingLeft: 11,
            boxSizing: 'border-box',
        },
        ':hover': {
            border: `1px solid ${COLORS.lightGray}`,
            boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.25)',
        },
    },
    companyLogo: {
        paddingTop: 13,
    },
    load_cont: {
        cursor: 'wait',
    },
    'add-box': {
        padding: 16,
        background: '#FFFFFF',
        border: '1px dashed #2EA97D',
        borderRadius: 8,
        display: 'flex',
        cursor: 'pointer',
        gridColumn: '0 / 1',
        flexDirection: 'column',
        ':hover': {
            boxShadow: '0px 2px 4px rgba(168, 168, 168, 0.15)',
        },
    },
    addLogo: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '0 auto',
        marginTop: 14,
        width: 92,
        height: 92,
    },
    compName: {
        textAlign: 'center',
        fontSize: 20,
        whiteSpace: 'nowrap',
        fontWeight: '600',
        color: '#181A19',
        margin: '11px 0 8px',
    },
    addTxt: {
        textAlign: 'center',
        fontSize: 20,
        fontWeight: '600',
        color: '#2EA97D',
        marginTop: 12,
        marginBottom: 43,
    },
    createdAt: {
        textAlign: 'center',
        fontSize: 13,
        fontWeight: '400',
        color: COLORS.gray,
        marginBottom: 7,
        margin: 0,
        ':nth-child(1n) > :first-child': {
            fontSize: 13,
            fontWeight: '500',
            color: '#181A19',
        },
    },
    pSet: {
        borderRadius: 4,
        margin: 0,
        ':nth-child(1n) > *': {
            width: '9.25rem',
            color: '#fff',
            fontSize: 12,
            background: 'rgba(65, 70, 68, 0.9)',
            backdropFilter: 'blur(8px)',
            fontWeight: '500',
            padding: '10px 12px',
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
            borderBottom: '1px solid #5e6360',
            ':hover': {
                background: 'rgba(65, 70, 68, 1)',
            },
            ':nth-child(1n) > :first-child': {
                marginRight: 8,
            },
        },
        ':nth-child(1n) > :last-child': {
            borderBottomWidth: 0,
            borderBottomLeftRadius: 4,
            borderBottomRightRadius: 4,
        },
        ':nth-child(1n) > :first-child': {
            borderTopLeftRadius: 4,
            borderTopRightRadius: 4,
        },
    },
    role: {
        height: 24,
        background: 'rgba(0, 149, 242, 0.1)',
        border: '1px solid rgba(0, 149, 242, 0.2)',
        borderRadius: 4,
        fontWeight: '500',
        fontSize: 11,
        marginBottom: 16,
        padding: '0 6px',
        color: COLORS.skyblue,
        display: 'inline-flex',
    },
})
