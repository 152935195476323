import React, { useEffect, useState } from 'react'
import { css, StyleSheet } from 'aphrodite'
import Table from '../common/Table'
import { useLoad } from '../../hooks/request'
import { CANDIDATE_ARCHIVE_LIST } from '../../urls'
import CandidateTableHeader from './CandidateTableHeader'
import CandidateTableArchiveItem from './CandidateTableArchiveItem'

export default function CandidateArchiveTable({ setCandidateCount, selectedVacancy }) {
    const [page, setPage] = useState(1)
    const [pageSize, setPageSize] = useState(10)
    const params = { page, 'per-page': pageSize, vacancy: selectedVacancy }
    const loadInfo = useLoad({ url: CANDIDATE_ARCHIVE_LIST, params }, [page, pageSize, selectedVacancy])

    const data = loadInfo.response ? loadInfo.response.data : []
    const meta = loadInfo.response ? loadInfo.response.meta : {}

    useEffect(() => {
        if (meta) setCandidateCount(meta.totalCount)
    }, [meta])

    const headerArchiveColumns = [
        { id: 1, name: 'Имя, отдел, должность', width: 'calc(100% * 2.75 / 12)' },
        { id: 2, name: 'Вакансия', width: 'calc(100% * 2.7 / 12)' },
        { id: 3, name: 'Колонка', width: 'calc(100% * 1.75 / 12)' },
        { id: 4, name: 'Опыт', width: 'calc(100% * 1.5 / 12)' },
        { id: 5, name: 'Рекрутер', width: 'calc(100% * 1.75 / 12)' },
        { id: 6, name: 'Категория', width: 'calc(100% * 1.5 / 12)' },
        { id: 7, name: '', width: 'calc(100% * 0.05 / 12)' },
    ]

    return (
        <div className={css(s.cont)}>
            <Table
                isHoverable
                isStripped
                items={data}
                isScrollable
                setPage={setPage}
                loaderItemCount={10}
                pageSize={meta.perPage}
                page={meta.currentPage}
                count={meta.totalCount}
                className={css(s.table)}
                setPageSize={setPageSize}
                pageCount={meta.pageCount}
                loading={loadInfo.loading}
                customHeader={(
                    <CandidateTableHeader headerColumns={headerArchiveColumns} />
                )}
                renderItem={(item) => (
                    <CandidateTableArchiveItem item={item} loading={loadInfo.loading} />
                )} />
        </div>
    )
}

const s = StyleSheet.create({
    cont: {
        width: '100%',
        padding: '16px 24px 24px',
        boxSizing: 'border-box',
    },
    table: {
        width: '100%',
        textAlign: 'left',
        height: 'calc(100% - 50px)',
    },
})
