import React, { memo, useState } from 'react'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import { CheckUpList } from '../../svgs/Svgs'
import SelectForm from '../../common/SelectForm'
import FormInput from '../../common/FormInput'
import ToggleSwitch from '../../common/ToggleSwitch'
import { COLORS } from '../../../utils/colors'
import { required } from '../../../utils/validators'
import { banknoteOptions } from '../../../utils/options'
import FormInputCurrency from '../../common/FormInputCurrency'
import InputTimeFromTo from '../../common/InputTimeFromTo'
import InputForm from '../../common/InputForm'

function Prepayment() {
    const [toggled, setToggled] = useState(false)

    return (
        <div className={css(s.switchCont)}>
            <div className={cn('justify-between', css(s.switchBox))}>
                <h3>Аванс</h3>

                <ToggleSwitch onClick={() => setToggled(!toggled)} name="prepayment" />
            </div>

            {toggled && (
                <InputForm
                    placeholderStyles={{ color: COLORS.gray, fontFamily: 'Roboto, sans-serif' }}
                    labelClassName={css(s.prepaylb)}
                    className={cn(css(s.inp))}
                    label="Дата получения аванса"
                    placeholder="15 числа каждого месяца"
                    name="prepayment"
                />
                // <SelectForm
                //     labelContClassName={css(s.lab)}
                //     placeholderStyles={{ color: COLORS.gray, fontFamily: 'Roboto, sans-serif' }}
                //     labelClassName={css(s.lb)}
                //     valueContainerStyles={{ padding: '11.5px 16px' }}
                //     indicatorStyle={{ paddingRight: 10 }}
                //     label="Дата получения аванса"
                //     placeholder="15 числа каждого месяца"
                //     name="prepayment"
                //     options={options} />
            )}
        </div>
    )
}

function Probation() {
    const [toggled, setToggled] = useState(false)

    return (
        <div className={css(s.switchCont)}>
            <div className={cn('justify-between', css(s.switchBox))}>
                <h3>Испытательный срок</h3>
                <ToggleSwitch onClick={() => setToggled(!toggled)} name="prepayment" />
            </div>

            {toggled && (
                <FormInput
                    name="probation"
                    placeholder="Введите количество дней"
                    label="Период испытательного срока"
                    className={css(s.addInp)}
                    fieldClassName={css(s.fld)}
                    labelClassName={css(s.addInpLab)} />
            )}
        </div>
    )
}

function MemoizedComponent() {
    const options = [
        { value: 1, label: 'Сдельная' },
        { value: 2, label: 'Каждый день' },
        { value: 3, label: 'Первые числа месяца' },
    ]

    return (
        <div className={css(s.box)}>
            <div className={cn('justify-between', css(s.formName))}>
                <div className="align-center gap-2">
                    <CheckUpList />

                    <h3>Основная информация</h3>
                </div>
            </div>

            <div className={css(s.inpGr)}>
                <InputTimeFromTo
                    label="Время работы"
                    fieldClassName={css(s.fld)}
                    labelClassName={css(s.addInpLab)}
                    placeholder={['00:00', '00:00']}
                    name={['startTime', 'endTime']}
                />
                {/* <FormInput */}
                {/*     name="time" */}
                {/*     icon={<Time />} */}
                {/*     placeholder="9:00 - 18:00" */}
                {/*     className={css(s.addInp)} */}
                {/*     label="Время работы" */}
                {/*     fieldClassName={css(s.fld)} */}
                {/*     labelClassName={css(s.addInpLab)} */}
                {/*     onFocus={(e) => { e.target.type = 'time' }} */}
                {/*     onBlur={(e) => { e.target.type = (!e.target.value && 'text') }} */}
                {/*     iconStyles={{ top: 34 }} /> */}

                <FormInput
                    errorWithMessage={false}
                    validate={required}
                    name="schedule"
                    placeholder="Например: “Понедельник - пятница”"
                    className={css(s.addInp)}
                    label="График работы"
                    fieldClassName={css(s.fld)}
                    labelClassName={css(s.addInpLab)} />

                {/* <FormInput */}
                {/*     name="schedule" */}
                {/*     placeholder="Например: “Понедельник - пятница”" */}
                {/*     className={css(s.addInp)} */}
                {/*     label="График работы" */}
                {/*     fieldClassName={css(s.fld)} */}
                {/*     labelClassName={css(s.addInpLab)} /> */}

                <FormInputCurrency
                    errorWithMessage={false}
                    validate={required}
                    name="salary"
                    maxLength={11}
                    label="Заработная плата"
                    options={banknoteOptions}
                    placeholder="Введите сумму"
                    fieldClassName={css(s.fld)}
                    className={cn(css(s.addInp))}
                    labelClassName={css(s.addInpLab)}
                />

                <SelectForm
                    errorWithMessage={false}
                    validate={required}
                    labelContClassName={css(s.lab)}
                    placeholderStyles={{ color: COLORS.gray, fontFamily: 'Roboto, sans-serif' }}
                    labelClassName={css(s.lb)}
                    valueContainerStyles={{ padding: '11.5px 16px' }}
                    indicatorStyle={{ paddingRight: 10 }}
                    label="Дата получения ЗП"
                    placeholder="1 числа каждого месяца"
                    name="receiveSalary"
                    options={options} />

                {/* <div className={cn('justify-between', css(s.switchBox))}> */}
                {/*     <h3>Аванс</h3> */}

                {/*     <ToggleSwitch */}
                {/*         name="prepayment" */}
                {/*         isChecked={values.prepayment} */}
                {/*         onClick={() => setFieldValue('prepayment', !values.prepayment)} */}
                {/*     /> */}
                {/* </div> */}

                <Prepayment />

                {/* <div className={cn('justify-between', css(s.switchBox))}> */}
                {/*     <h3>Испытательный срок</h3> */}

                {/*     <ToggleSwitch */}
                {/*         name="probation" */}
                {/*         isChecked={values.probation} */}
                {/*         onClick={() => setFieldValue('probation', !values.probation)} */}
                {/*     /> */}
                {/* </div> */}

                <Probation />
            </div>
        </div>
    )
}

export const MainInformation = memo(MemoizedComponent)

const s = StyleSheet.create({
    box: {
        background: COLORS.white,
        border: `1px solid ${COLORS.smoothGray}`,
        boxShadow: '0px 2px 4px rgba(130, 130, 130, 0.05)',
        borderRadius: 4,
    },
    formName: {
        height: 'calc(3rem - 1px)',
        padding: '0 22px 0 24px',
        fontSize: 13,
        fontWeight: '600',
        color: '#181A19',
        borderBottom: `1px solid ${COLORS.lightGray}`,
        textTransform: 'uppercase',
    },
    inpGr: {
        padding: '16px 24px 24px',
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gridGap: 24,
        alignItems: 'end',
    },
    lb: {
        fontSize: 12,
        fontWeight: '500',
        color: COLORS.midGray,
    },
    prepaylb: {
        paddingBottom: 8,
    },
    inp: {
        width: '100%',
        border: `1px solid ${COLORS.lightGray}`,
        borderRadius: 4,
        height: 44,
        boxSizing: 'border-box',
        padding: '14px 16px',
        ':first-of-type': {
            paddingTop: 15,
        },
    },
    commentAreaField: {
        height: 'calc(8.5rem - 25px)',
    },
    addInp: {
        position: 'relative',
        border: `1px solid ${COLORS.lightGray}`,
        borderRadius: 4,
        fontSize: 14,
        fontWeight: '500',
        color: '#181A19',
    },
    addInpLab: {
        fontSize: 12,
        fontWeight: '500',
        color: COLORS.midGray,
        marginBottom: 8,
    },
    fld: {
        position: 'relative',
        // padding
    },
    lab: {
        ':nth-child(1n) > span': {
            display: 'inline-flex',
        },
    },
    switchBox: {
        padding: '13px 16px',
        border: `1px solid ${COLORS.lightGray}`,
        borderRadius: 4,
        fontWeight: '500',
        fontSize: 14,
        color: COLORS.gray,
    },
    switchCont: {
        gridColumn: '1 / 3',
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gridGap: 24,
        alignItems: 'end',
        height: '4.25rem',
    },
})
