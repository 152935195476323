import React from 'react'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import { CloseSidebar, GoBack } from '../svgs/Svgs'
import { COLORS } from '../../utils/colors'

export default function SidebarLayout({ children, goBack = false, onClose, onGoBack, title }) {
    return (
        <div className={cn(css(s.cont))}>
            <div className="justify-between">
                <div className="align-center">
                    {goBack && <GoBack onClick={onGoBack} className={css(s.goBack)} />}

                    <h3 className={css(s.topic)}>{title}</h3>
                </div>

                <CloseSidebar className="pointer" onClick={onClose} />
            </div>

            {children}
        </div>
    )
}

const s = StyleSheet.create({
    cont: {
        overflowX: 'hidden',
        width: '100%',
        boxSizing: 'border-box',
        padding: '24px 32px',
        height: '100%',
        overflowY: 'scroll',
        '::-webkit-scrollbar': {
            display: 'none',
        },
    },
    topic: {
        fontWeight: '700',
        size: 20,
        color: COLORS.dark,
    },
    goBack: {
        cursor: 'pointer',
        marginRight: 22,
        ':nth-child(1n) > :first-child': {
            stroke: COLORS.dark,
            strokeWidth: 3,
        },
    },
})
