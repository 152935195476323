import React from 'react'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import { isEmpty } from 'lodash'
import MainInfo from './MainInfo'
import Education from './Education'
import Languages from './Languages'

export default function GeneralInfo({ info }) {
    return (
        <div className={cn('is-flex direction-column gap-7', css(s.cont))}>
            {!isEmpty(info) ? (
                <MainInfo info={info} />
            ) : null}

            {!isEmpty(info.education) ? (
                <Education education={info.education} />
            ) : null}

            {!isEmpty(info.languageLevel) ? (
                <Languages languages={info.languageLevel} />
            ) : null}
        </div>
    )
}

const s = StyleSheet.create({
    cont: {
        padding: '16px 24px',
        overflowY: 'auto',
        height: '100%',
    },
})
