import React from 'react'
import cn from 'classnames'
import { css, StyleSheet } from 'aphrodite'
import { Form, Formik } from 'formik'
import { isEmpty } from 'lodash'
import Layout from '../components/common/Layout'
import { PlusRounded } from '../components/svgs/Svgs'
import LocationMainForm from '../components/settings/locations/LocationMainForm'
import { COLORS } from '../utils/colors'
import Button from '../components/common/Button'

export default function LocationForm({ onSubmit, title, navigate, loading, initialValues = {} }) {
    return (
        <Layout padded={false}>
            <Formik
                enableReinitialize
                onSubmit={onSubmit}
                initialValues={{ name: '', address: '', latitude: '', longitude: '', ...initialValues }}>
                {({ setFieldValue, values }) => (
                    <Form>
                        <div className={cn('justify-between', css(s.wrapper))}>
                            <h3>{title}</h3>

                            <div className={cn('align-center')}>
                                <button
                                    type="reset"
                                    onClick={() => navigate(-1)}
                                    className={css(s.cancelBtn)}>
                                    Отмена
                                </button>

                                <Button
                                    type="submit"
                                    strokeWidth={4}
                                    loaderWidth={20}
                                    loading={loading}
                                    loaderColor={COLORS.white}
                                    icon={isEmpty(initialValues) && <PlusRounded />}
                                    className={cn(css(s.saveBtn), 'align-center pointer')}>
                                    {isEmpty(initialValues) ? 'Добавить' : 'Сохранить'}
                                </Button>
                            </div>
                        </div>

                        <div className={css(s.cont)}>
                            <LocationMainForm
                                onSubmit={onSubmit}
                                values={values}
                                setFieldValue={setFieldValue}
                                initialValues={initialValues} />

                            {/* <LocationEmployees pathname={pathname} /> */}
                        </div>
                    </Form>
                )}
            </Formik>
        </Layout>
    )
}

const s = StyleSheet.create({
    cont: {
        display: 'grid',
        gridTemplateColumns: '2fr 1fr',
        gap: 24,
        padding: '1.5rem 2rem',
        alignItems: 'start',
    },
    wrapper: {
        background: '#FFFFFF',
        borderBottom: `1px solid ${COLORS.smoothGray}`,
        padding: '14px 2rem',
        fontWeight: 600,
        fontSize: 24,
        color: '#181A19',
    },
    cancelBtn: {
        fontWeight: '500',
        fontSize: 14,
        color: COLORS.gray,
        padding: '11px 16px',
        border: `1px solid ${COLORS.gray}`,
        borderRadius: 4,
        background: 'transparent',
        marginRight: 16,
        cursor: 'pointer',
    },
    saveBtn: {
        fontWeight: '500',
        fontSize: 14,
        color: '#FFFFFF',
        height: '2.5rem',
        padding: '0 16px',
        border: 'none',
        borderRadius: 4,
        background: `linear-gradient(180deg, #2EA97D 0%, ${COLORS.lightMainColor} 100%)`,
        // eslint-disable-next-line max-len
        boxShadow: '0px 4px 12px rgba(44, 67, 50, 0.1), inset 0px -1px 0px rgba(0, 0, 0, 0.25), inset 0px 1px 0px rgba(255, 255, 255, 0.1)',
    },
})
