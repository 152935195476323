import React from 'react'
import { useNavigate } from 'react-router-dom'
import LocationForm from './LocationForm'
import { useMessage } from '../hooks/message'
import { usePostRequest } from '../hooks/request'
import { LOCATION_CREATE } from '../urls'

const GOOGLE_MAP_LINK = 'https://www.google.com/maps/search/?api=1&query={lat},{lng}'

export default function LocationCreate() {
    const [showMessage] = useMessage()
    const navigate = useNavigate()
    const create = usePostRequest({ url: LOCATION_CREATE })

    const onSubmit = async (data) => {
        const lat = data.latitude.toString()
        const lng = data.longitude.toString()

        const { success, error } = await create.request({ data: {
            ...data,
            latitude: data.latitude.toString(),
            longitude: data.longitude.toString(),
            link: GOOGLE_MAP_LINK.replace('{lat}', lat).replace('{lng}', lng),
        } })

        if (success) {
            showMessage('Успешно!', 'success-msg')
            navigate(-1)
            return
        }

        showMessage(error.data.errors[0].message || error.data.message, 'error-msg')
    }

    return (
        <LocationForm loading={create.loading} navigate={navigate} onSubmit={onSubmit} title="Добавление локации" />
    )
}
