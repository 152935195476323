import React, { useEffect, useState } from 'react'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import moment from 'moment'
import Filter from './Filter'
import { getFullDate } from '../../../utils/date'
import VacancyTable from './VacancyTable'
import { useInfiniteScroll } from '../../../hooks/request'
import { REPORT_APPLICANT_SOURCE } from '../../../urls'
import ChartApplicantsBySources from './ChartApplicantsBySources'
import NoVacanciesByFilter from '../NoVacanciesByFilter'
import NoVacancies from '../NoVacancies'
import ChartToolEffectiveness from './ChartToolEffectiveness'

const COLORS_PALETTE = [
    '#0095F2', '#71CEF0', '#F6C11D', '#E18700', '#2EA97D', '#B305CF', '#F20909', '#FF53A5', '#4302FF', '#969F9B',
]

const today = moment()

const initialParams = {
    endDate: getFullDate(today.toDate()),
    startDate: getFullDate(today.clone().subtract(29, 'days').toDate()),
    isReinitialized: false,
}

export default function ApplicantSources({ formikRef, vacancyCount, vacancyLoading }) {
    const [params, setParams] = useState(initialParams)
    const loadInfo = useInfiniteScroll({ url: REPORT_APPLICANT_SOURCE, params })
    const info = loadInfo.response ? loadInfo.response.data : []

    useEffect(() => {
        if (params.isReinitialized) {
            loadInfo.reset()
        }
    }, [params])

    return (
        <>
            <Filter formikRef={formikRef} setParams={setParams} loadInfo={loadInfo} />

            <div className={cn('is-flex direction-column gap-7', css(s.cont))}>
                <div className="align-center gap-7">
                    <ChartToolEffectiveness params={params} />

                    <ChartApplicantsBySources params={params} />
                </div>

                {info.length ? info.map((item, index) => (
                    <VacancyTable color={COLORS_PALETTE[index]} key={item.id} params={params} item={item} />
                )) : null}

                {loadInfo.loading ? <VacancyTable loading /> : null}

                {!loadInfo.loading && !info.length
                    ? !vacancyLoading
                        ? vacancyCount ? <NoVacanciesByFilter /> : <NoVacancies />
                        : <VacancyTable loading />
                    : null }

                {!loadInfo.loading && loadInfo.hasMore && <div ref={loadInfo.ref} />}
            </div>
        </>
    )
}

const s = StyleSheet.create({
    cont: {
        padding: 24,
    },
})
