import React, { useRef } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { endsWith } from 'lodash'
import Layout from '../components/common/Layout'
import Header from '../components/reports/Header'
import VacancyMetrics from '../components/reports/vacancyMetrics/VacancyMetrics'
import VacancyInfo from '../components/reports/vacancyInfo/VacancyInfo'
import { useLoad } from '../hooks/request'
import { VACANCY_LIST } from '../urls'
import ApplicantSources from '../components/reports/applicantSources/ApplicantSources'

export default function Reports() {
    const formikRef = useRef(null)
    const reportRequestRef = useRef({ loading: true })
    const navigate = useNavigate()
    const location = useLocation()
    const loadVacancies = useLoad({ url: VACANCY_LIST, params: { 'per-page': 100 } })

    const vacancies = loadVacancies.response ? loadVacancies.response.data.map((e) => ({
        value: e.id,
        label: e.name,
        department: e.department.name,
        position: e.position.name,
    })) : []

    return (
        <Layout padded={false}>
            <Header
                navigate={navigate}
                formikRef={formikRef}
                location={location.pathname}
                vacancyCount={vacancies.length}
                vacancyLoading={loadVacancies.loading}
                reportRequest={reportRequestRef.current}
            />

            {endsWith(location.pathname, 'vacancy-metrics') && (
                <VacancyMetrics
                    vacancies={vacancies}
                    formikRef={formikRef}
                    reportRequestRef={reportRequestRef}
                    vacancyLoading={loadVacancies.loading}
                />
            )}

            {endsWith(location.pathname, 'vacancy-info') && (
                <VacancyInfo
                    formikRef={formikRef}
                    vacancyCount={vacancies.length}
                    reportRequestRef={reportRequestRef}
                    vacancyLoading={loadVacancies.loading}
                />
            )}

            {endsWith(location.pathname, 'applicant-sources') && (
                <ApplicantSources
                    formikRef={formikRef}
                    vacancyCount={vacancies.length}
                    reportRequestRef={reportRequestRef}
                    vacancyLoading={loadVacancies.loading}
                />
            )}
        </Layout>
    )
}
