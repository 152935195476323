import React, { memo, useCallback, useState } from 'react'
import { css, StyleSheet } from 'aphrodite'
import { DragDropContext } from 'react-beautiful-dnd'
import { Column } from './Column'
import { CANDIDATE_CARDS, CANDIDATE_COLUMN, CANDIDATE_MOVE } from '../../../urls'
import { useGetRequest, usePutRequest } from '../../../hooks/request'
import { isInViewport } from '../../../utils/viewport'
import { CandidateActionTypes } from '../../../utils/actions'
import { CandidateActions } from '../../candidates/CandidateActions'
import { useMessage } from '../../../hooks/message'
import { checkDragNDrop } from '../../../utils/dragNDrop'

// Функция для отправки запроса на бэкенд для перетаскивания
const moveRequest = (
    source,
    destination,
    columns,
    setColumns,
    moveCard,
    candidateId,
    columnId,
    after,
    vacancyId,
    getCards,
) => {
    const sourceNodeId = `obs-${columns[source.droppableId].id}`
    const sourceNode = document.getElementById(sourceNodeId)

    moveCard.request({
        data: {
            candidateId,
            columnId,
            after,
        },
    })
        .then(() => {
            if (source.droppableId === destination.droppableId) return
            setColumns((prev) => ({
                ...prev,
                [source.droppableId]: { ...prev[source.droppableId], count: prev[source.droppableId].count - 1 },
                [destination.droppableId]: {
                    ...prev[destination.droppableId],
                    count: prev[destination.droppableId].count + 1,
                },
            }))
        })
        .then(() => {
            if (sourceNode && isInViewport(sourceNode)) {
                const vacancy = vacancyId > 0 ? { vacancy: vacancyId } : {}

                getCards.request({
                    url: CANDIDATE_CARDS.replace('{id}', columns[source.droppableId].id),
                    params: { page: 1, ...vacancy },
                })
                    .then(({ response }) => {
                        setColumns((prev) => {
                            const column = prev[source.droppableId]
                            return {
                                ...prev,
                                [source.droppableId]: {
                                    ...column,
                                    cards: [...column.cards, ...response.data.filter(
                                        (e) => !column.cards.map((i) => i.id).includes(e.id),
                                    )],
                                },
                            }
                        })
                    })
                    .catch(() => {})
            }
        })
}

// Функция для перетаскивания карточек между колонками.
const moveToDestination = (source, destination, columns, setColumns, moveCard, vacancyId, getCards) => {
    const sourceColumn = columns[source.droppableId]
    const destColumn = columns[destination.droppableId]
    const sourceItems = [...sourceColumn.cards]
    const destItems = [...destColumn.cards]

    const columnId = destColumn.id
    const candidateId = sourceItems[source.index].id
    const after = destItems[destination.index - 1] ? destItems[destination.index - 1].id : null

    const [removed] = sourceItems.splice(source.index, 1)
    destItems.splice(destination.index, 0, removed)
    setColumns({
        ...columns,
        [source.droppableId]: {
            ...sourceColumn,
            cards: sourceItems,
        },
        [destination.droppableId]: {
            ...destColumn,
            cards: destItems,
        },
    })

    moveRequest(source, destination, columns, setColumns, moveCard, candidateId, columnId, after, vacancyId, getCards)
}

// Функция для перетаскивания карточек внутри колонки.
const moveToIndex = (source, destination, columns, setColumns, moveCard, vacancyId, getCards) => {
    if (source.index === destination.index) return

    const column = columns[source.droppableId]
    const copiedItems = [...column.cards]
    const [removed] = copiedItems.splice(source.index, 1)
    copiedItems.splice(destination.index, 0, removed)

    const columnId = column.id
    const candidateId = copiedItems[destination.index].id
    const after = copiedItems[destination.index - 1] ? copiedItems[destination.index - 1].id : null

    setColumns({
        ...columns,
        [source.droppableId]: {
            ...column,
            cards: copiedItems,
        },
    })

    moveRequest(source, destination, columns, setColumns, moveCard, candidateId, columnId, after, vacancyId, getCards)
}

const onDragEnd = (result, columns, setColumns, moveCard, getCards, vacancyId, modal, setModal) => {
    const { source, destination } = result

    if (!result.destination) return

    const sourceType = columns[source.droppableId].type
    const destinationType = columns[destination.droppableId].type
    const card = columns[source.droppableId].cards[source.index]
    const { status } = card.params

    if (!checkDragNDrop(sourceType, destinationType, status)) return

    if (source.droppableId !== destination.droppableId) {
        const { type } = columns[destination.droppableId]

        if (typeof CandidateActionTypes[type] === 'function' && type) {
            const after = columns[destination.droppableId].cards[destination.index - 1]

            setModal({
                type,
                act: true,
                id: card.id,
                email: card.email,
                name: `${card.firstName} ${card.lastName}`,
                dragActions: { source, destination },
                index: source.index,
                sourceColumnIndex: source.droppableId,
                destinationDroppableId: destination.droppableId,
                destinationIndex: destination.index,
                after: after ? after.id : null,
            })
        } else moveToDestination(source, destination, columns, setColumns, moveCard, vacancyId, getCards)
    } else moveToIndex(source, destination, columns, setColumns, moveCard, vacancyId, getCards)
}

const MemoizedComponent = ({ columns, setColumns, sibHeight, getData, vacancyId, data }) => {
    const [modal, setModal] = useState({ act: false })
    const getCards = useGetRequest()
    const moveCard = usePutRequest({ url: CANDIDATE_MOVE })
    const s = styles(sibHeight)
    const putColumn = usePutRequest()
    const [showMessage] = useMessage()

    const onChangeColumn = useCallback(async (cId, cInd, color) => {
        const { success, error } = await putColumn.request({
            url: CANDIDATE_COLUMN.replace('{id}', cId),
            data: { color },
        })

        if (success) {
            const column = data[cInd]
            column.color = color
            data.splice(cInd, 1, column)
            getData.setResponse({ ...getData.response, data })
            setColumns([...data])
        } else {
            const { errors, message } = error.data
            showMessage(errors ? errors[0].message : message, 'error-msg')
        }
    }, [data])

    const onSuccessCandidateAction = () => {
        // const { source, destination } = modal.dragActions
        // moveToDestination(source, destination, columns, setColumns, moveCard, vacancyId, getCards)
        setModal({ act: false })
    }

    return (
        <>
            <DragDropContext
                onDragEnd={
                    (result) => onDragEnd(
                        result,
                        columns,
                        setColumns,
                        moveCard,
                        getCards,
                        vacancyId,
                        modal,
                        setModal,
                    )
                }>
                <div className={css(s.cont)}>
                    {Object.entries(columns).map(([columnId, column], index) => (
                        <Column
                            setColumns={setColumns}
                            index={index}
                            getData={getData}
                            vacancyId={vacancyId}
                            key={columnId}
                            column={column}
                            columnId={columnId}
                            onChangeColumn={onChangeColumn}
                        />
                    ))}
                </div>
            </DragDropContext>

            <CandidateActions
                modal={modal}
                setModal={setModal}
                setColumns={setColumns}
                onSuccess={onSuccessCandidateAction}
            />
        </>
    )
}

export const KanbanBoard = memo(MemoizedComponent)

const styles = (sibHeight) => StyleSheet.create({
    cont: {
        display: 'flex',
        marginBottom: 10,
        padding: '16px 24px 15px',
        width: '100%',
        boxSizing: 'border-box',
        minHeight: '80%',
        gap: '1.5rem',
        overflow: 'auto',
        height: `calc(100% - ${sibHeight})`,
    },
    context: {
        height: '100%',
        display: 'flex',
        transition: 'all 0.75s ease',
        flexDirection: 'row',
        gap: '1.5rem',
    },
})
