import React, { useEffect, useState } from 'react'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import { useNavigate } from 'react-router-dom'
import { COLORS } from '../../../utils/colors'
import hh from '../../../static/images/hh.svg'
import telegram from '../../../static/images/telegram.svg'
import Button from '../../common/Button'
import { CheckSizeXL, ChevronDown } from '../../svgs/Svgs'
import { useQueryParams } from '../../../hooks/queryString'
import { useGetRequest } from '../../../hooks/request'
import { INTEGRATION_HH } from '../../../urls'
import { useMessage } from '../../../hooks/message'
import { checkHHIntegration, HH_INTEGRATION_URL } from '../../../integrations/hh'
import LoadingSpinner from '../../common/LoadingSpinner'

export function ServiceHeadHunter() {
    const { code } = useQueryParams()
    const navigate = useNavigate()
    const setAuthCode = useGetRequest({ url: INTEGRATION_HH, params: { code } })

    const [showMessage] = useMessage()

    const [hhIntegration, setHhIntegration] = useState({ loading: true })

    const handleCheck = async () => {
        const status = await checkHHIntegration(showMessage)
        setHhIntegration({ loading: false, status })
    }

    useEffect(() => {
        if (!code) handleCheck()
        else handleAuthCode()
    }, [])

    async function handleAuthCode() {
        let status = 'error'
        const { success, error } = await setAuthCode.request()

        if (success) {
            status = await checkHHIntegration(showMessage, true)
        } else {
            showMessage(error.data.message || 'ERROR', 'error-msg')
        }

        setHhIntegration({ loading: false, status })
        navigate('.', { replace: true })
    }

    const s = styles('#D20A11', '#FFF0F1', '#F2E0E1')

    return (
        <div className={cn('is-flex direction-column gap-3', css(s.wrap))}>
            <div className={cn('justify-between', css(s.topic))}>
                <div className="align-center gap-2">
                    <img className={css(s.logo)} src={hh} alt="#hh" />
                    <h3>HeadHunter</h3>
                </div>

                {!hhIntegration.loading && !setAuthCode.loading
                    ? hhIntegration.status === 'success'
                        ? (
                            <CheckSizeXL color="#D20A11" />
                        ) : (
                            <a className="no_underline" target="_blank" href={HH_INTEGRATION_URL} rel="noreferrer">
                                <Button
                                    iconRight
                                    className={cn('align-center gap-1', css(s.btn))}
                                    icon={<ChevronDown color={COLORS.dark} rotate={-90} />}
                                >
                                    <h3>Запустить интеграцию</h3>
                                </Button>
                            </a>
                        ) : <LoadingSpinner width={28} color={COLORS.lightRed} />}
            </div>

            <div className={css(s.desc)}>
                <p>
                    Интеграция с <span>hh.uz</span> позволит вам:
                    <ul className={css(s.list)}>
                        <li>Публиковать вакансии в вашем аккаунте hh.uz</li>
                        <li>Собирать отклики кандидатов в системе</li>
                        <li>Формировать отчеты по просмотрам и откликам через hh.uz</li>
                    </ul>
                    Для более подробной информации обратитесь в службу поддержки))
                </p>
            </div>
        </div>
    )
}

export function ServiceTelegram() {
    const s = styles('#2DA4D7', '#EBF9FF', '#D3EBF5')

    return (
        <div className={cn('is-flex direction-column gap-3', css(s.wrap))}>
            <div className={cn('justify-between', css(s.topic))}>
                <div className="align-center gap-2">
                    <img className={css(s.logo)} src={telegram} alt="#hh" />
                    <h3>Telegram</h3>
                </div>

                <Button
                    iconRight
                    className={cn('align-center gap-1', css(s.btn))}
                    icon={<ChevronDown color={COLORS.dark} rotate={-90} />}
                >
                    <h3>Запустить интеграцию</h3>
                </Button>
            </div>

            <div className={css(s.desc)}>
                <p>
                    Система интегрирована с <span>Телеграм</span>, что позволяет вам:
                    <ul className={css(s.list)}>
                        <li>Публиковать вакансии в ваш телеграм-бот</li>
                        <li>Собирать отклики кандидатов с телеграм-бота в системе</li>
                        <li>Формировать отчеты по просмотрам и откликам из вашего телеграм-бота</li>
                    </ul>
                    Для более подробной информации обратитесь в службу поддержки))
                </p>
            </div>
        </div>
    )
}

const styles = (color, backgroundColor, borderColor) => StyleSheet.create({
    wrap: {
        borderRadius: 8,
        padding: 12,
        border: `1px solid ${COLORS.smoothGray}`,
        background: '#FFF',
        boxShadow: '0px 2px 4px 0px rgba(130, 130, 130, 0.05)',
    },
    topic: {
        borderRadius: 4,
        border: `1px solid ${borderColor}`,
        backgroundColor,
        padding: 11,
        color: COLORS.dark,
        fontSize: 14,
        height: 64,
        boxSizing: 'border-box',
        fontWeight: '500',
    },
    list: {
        display: 'block',
        paddingInlineStart: 24,
    },
    btn: {
        padding: '0 12px',
        height: 40,
        color: COLORS.dark,
        fontSize: 14,
        fontWeight: '500',
        borderRadius: 4,
        border: `1px solid ${borderColor}`,
        background: COLORS.white,
        boxShadow: '0px 2px 4px 0px rgba(130, 130, 130, 0.05)',
        textDecoration: 'none',
    },
    desc: {
        color: '#343836',
        fontSize: 14,
        fontWeight: '500',
        ':nth-child(1n) > p': {
            ':nth-child(1n) > span': {
                color,
            },
        },
    },
})
