import React from 'react'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import uuid from 'react-uuid'
import { BachelorHat } from '../svgs/Svgs'
import { educationLvl } from '../../utils/options'
import { COLORS } from '../../utils/colors'
import { getFullDate } from '../../utils/date'

export default function EducationInfo({ education }) {
    return (
        <div className="is-flex direction-column gap-2">
            <label className={cn('align-center gap-2', css(s.label))}><BachelorHat /> Образование</label>

            {education.map((edu) => (
                <ul key={uuid()} className={cn('no_dot_list is-flex direction-column gap-3', css(s.list))}>
                    <li className={cn('align-center', css(s.info_item))}>
                        <div>Образование</div>

                        <div>{educationLvl[edu.level - 1].label}</div>
                    </li>

                    <hr className={css(s.hr)} />

                    <li className={cn('align-center', css(s.info_item))}>
                        <div>Учебное заведение</div>

                        <div>{edu.institution}</div>
                    </li>

                    <hr className={css(s.hr)} />

                    <li className={cn('align-center', css(s.info_item))}>
                        <div>Дата окончания</div>

                        <div>{getFullDate(edu.endDate, true)}</div>
                    </li>

                    <hr className={css(s.hr)} />

                    <li className={cn('align-center', css(s.info_item))}>
                        <div>Доп. образование</div>

                        <div>
                            {edu.additional}
                        </div>
                    </li>
                </ul>
            ))}
        </div>
    )
}

const s = StyleSheet.create({
    list: {
        background: COLORS.white,
        padding: 20,
        border: `1px solid ${COLORS.chineseWhite}`,
        boxShadow: '0px 2px 4px rgba(130, 130, 130, 0.05)',
        borderRadius: 4,
    },
    label: {
        fontWeight: '600',
        fontSize: 13,
        color: '#181A19',
        textTransform: 'uppercase',
    },
    hr: {
        border: `.5px solid ${COLORS.smoothGray}`,
        borderRadius: 12,
        margin: 0,
    },
    info_item: {
        ':nth-child(1n) > :first-child': {
            width: '37%',
            fontWeight: '400',
            fontSize: 13,
            color: COLORS.midGray,
        },
        ':nth-child(1n) > :last-child': {
            width: '63%',
            fontWeight: '500',
            fontSize: 13,
            color: '#181A19',
        },
    },
    pdf: {
        background: COLORS.lotion,
        border: `1px solid ${COLORS.smoothGray}`,
        borderRadius: 4,
        padding: '8px 12px',
        gap: 6,
        ':nth-child(1n) > div': {
            ':nth-child(1n) > :first-child': {
                fontWeight: '500',
                fontSize: 11,
                color: COLORS.midGray,
            },
            ':nth-child(1n) > :last-child': {
                fontWeight: '600',
                fontSize: 12,
                color: '#181A19',
            },
        },
        ':nth-child(1n) > :last-child': {
            ':nth-child(1n) > *': {
                stroke: COLORS.mainColor,
            },
        },
    },
})
