import React, { useContext, useEffect, useState } from 'react'
import { css, StyleSheet } from 'aphrodite'
import { useParams } from 'react-router-dom'
import { isEmpty } from 'lodash'
import Layout from '../components/common/Layout'
import { Header } from '../components/vacancies/Header'
import { useLoad } from '../hooks/request'
import { VACANCY_CANDIDATES, VACANCY_DETAIL } from '../urls'
import { VacancyFullInfo } from '../components/vacancies/VacancyFullInfo'
import { KanbanBoard } from '../components/common/kanban/KanbanBoard'
import { Context } from '../contexts/GlobalContext'
import { DASHBOARD_COLORS } from '../utils/colors'
import { BaseContext } from '../contexts/BaseContextWrapper'

function VacanciesInfo() {
    const { id } = useParams()
    const [columns, setColumns] = useState({})
    const [isShort, setIsShort] = useState(false)
    const { vacanciesCount, setVacanciesCount } = useContext(BaseContext)
    const loadInfo = useLoad({ url: VACANCY_DETAIL.replace('{id}', id) })
    const loadColumns = useLoad({ url: VACANCY_CANDIDATES.replace('{id}', id) })

    const info = loadInfo.response ? loadInfo.response.data : {}
    const cols = loadColumns.response ? loadColumns.response.data : []

    useEffect(() => {
        if (!isEmpty(cols)) setVacanciesCount(Object.values(cols).map((col) => col.count).reduce((x, y) => x + y, 0))
    }, [cols])

    useEffect(() => {
        if (cols.length !== 0) {
            const result = {}

            // eslint-disable-next-line no-plusplus
            for (let i = 0; i < cols.length; i++) {
                result[i] = cols[i]
            }

            setColumns(result)
        }
    }, [cols])

    const handleScroll = (event) => {
        if (!isShort && event.target.scrollTop > 30) {
            setIsShort(true)
        }
    }
    const handleWheel = (event) => {
        if (isShort && event.deltaY < -50) {
            setIsShort(false)
        }
    }

    return (
        <React.Fragment>
            <Header count={vacanciesCount} vacancyName={info.name || ' '} vacancyId={id} />

            <div onScroll={handleScroll} onWheel={handleWheel} className={css(s.cont)}>
                <VacancyFullInfo setIsShort={setIsShort} isShort={isShort} data={info} loading={loadInfo.loading} />

                <KanbanBoard
                    projectId={id}
                    getData={loadColumns}
                    data={cols}
                    columns={columns}
                    setColumns={setColumns}
                    sibHeight={`${3.625 + (!isShort ? 24 : 0)}rem`}
                />
            </div>
        </React.Fragment>
    )
}

export default function VacancyDetail() {
    const { theme } = useContext(Context)

    return (
        <Layout padded={false} className={css(s.ly, bgColor(DASHBOARD_COLORS[theme.rB]))}>
            <VacanciesInfo />
        </Layout>
    )
}

const s = StyleSheet.create({
    ly: {
        width: '88.4%',
        scrollbarGutter: 'stable',
    },
    cont: {
        height: 'calc(100% - 64px)',
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'auto',
        gap: 20,
        '::-webkit-scrollbar': {
            display: 'none',
        },
    },
})

const bgColor = (backgroundColor) => StyleSheet.create({
    main: {
        backgroundColor,
    },
}).main
