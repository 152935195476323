import React, { memo } from 'react'
import { css, StyleSheet } from 'aphrodite'
import { Cell, Label, Pie, PieChart as Chart, Tooltip } from 'recharts'
import { COLORS } from '../../utils/colors'
import { strCutter } from '../../utils/string'

function CustomLabel({ viewBox, value1, value2 }) {
    const { cx, cy } = viewBox

    return (
        <svg
            className="recharts-text recharts-label"
            textAnchor="middle"
            dominantBaseline="central">
            <text y={cy - 5} x={cx} fill="#2E2C34">
                <tspan
                    x={cx}
                    fontSize="40"
                    fontWeight="600"
                    color={COLORS.dark}
                    fontFamily="Roboto, Arial, Helevtica, sans-serif"
                >
                    {value1}
                </tspan>
            </text>

            <text y={cy} x={cx} fill={COLORS.gray}>
                <tspan
                    x={cx}
                    dy="1.5em"
                    fontSize="13"
                    fontWeight="500"
                    fontFamily="Roboto, Arial, Helevtica, sans-serif"
                >
                    {value2}
                </tspan>
            </text>
        </svg>
    )
}

const CustomTooltip = ({ active, payload }) => {
    if (!active) return null

    const { name, value, percent } = payload[0].payload

    return name && (
        <div className={css(s.tool)}>
            <div>{strCutter(name, 30)} - {value} - {percent}%</div>
        </div>
    )
}

function MemoizedPieChart({
    colors,
    data,
    labelText,
    innerRadius = 75,
    outerRadius = 100,
    paddingAngle = 2,
    width = 220,
    height = 220,
}) {
    return (
        <div>
            <Chart key="vacancies" width={width} height={height}>
                <Pie
                    data={data}
                    cx="50%"
                    cy="50%"
                    isAnimationActive={false}
                    startAngle={90}
                    endAngle={450}
                    innerRadius={innerRadius}
                    outerRadius={outerRadius}
                    paddingAngle={paddingAngle}
                    dataKey="value"
                    outline="none"
                    nameKey="name">
                    <Label
                        width={30}
                        position="center"
                        content={<CustomLabel value1={labelText[0]} value2={labelText[1] || ''} />}
                    />
                    {data.map((entry, index) => (
                        <Cell
                            key={entry.id}
                            className={css(s.cell)}
                            fill={entry.color ? entry.color : colors[index % colors.length]} />
                    ))}
                </Pie>

                <Tooltip content={<CustomTooltip />} />
            </Chart>
        </div>
    )
}

export const PieChart = memo(MemoizedPieChart)

const s = StyleSheet.create({
    box: {
        marginTop: '2rem',
        background: COLORS.white,
        boxShadow: '0px 2px 8px rgba(136, 136, 136, 0.25)',
        borderRadius: 12,
        padding: '20px 20px 36px',
    },
    statCont: {
        marginTop: 20,
        padding: '0 32px 0 12px',
        ':nth-child(1n) > :first-child': {
            width: '17.8%',
        },
    },
    title: {
        fontSize: 15,
        fontWeight: '600',
        color: '#181A19',
    },
    tool: {
        // maxWidth: '6.25rem',
        outline: 'none',
        ':nth-child(1n) > :first-child': {
            background: COLORS.lightBlack,
            border: `1px solid ${COLORS.midGray}`,
            borderRadius: 8,
            color: COLORS.white,
            fontSize: 13,
            fontWeight: '400',
            padding: '8px 16px',
        },
    },
    tbs: {
        marginLeft: 52,
        width: 'calc(82.2% - 52px)',
        alignItems: 'flex-start',
    },
    tb: {
        width: 'calc(50% - 16px)',
        background: '#F9FCFF',
        border: `1px solid ${COLORS.smoothGray}`,
        borderRadius: 8,
        ':nth-child(1n) > :first-child': {
            borderBottom: '1px solid #EBEAED',
            ':nth-child(1n) > :first-child': {
                borderBottom: '1px solid #EBEAED',
                color: '#84818A',
                fontSize: 12,
                fontWeight: '600',
                ':nth-child(1n) > *': {
                    padding: '16px 0 8px',
                    borderBottom: '1px solid #EBEAED',
                },
                ':nth-child(1n) > :first-child': {
                    textAlign: 'left',
                    paddingLeft: 16,
                },
                ':nth-child(1n) > :last-child': {
                    textAlign: 'right',
                    paddingRight: 16,
                },
            },
        },
        ':nth-child(1n) > :last-child': {
            ':nth-child(1n) > *': {
                color: '#2E2C34',
                fontSize: 14,
                fontWeight: '600',
                ':nth-child(1n) > *': {
                    padding: '8px 0',
                    textAlign: 'center',
                },
                ':nth-child(1n) > :first-child': {
                    textAlign: 'left',
                    paddingLeft: 16,
                },
                ':nth-child(1n) > :last-child': {
                    paddingRight: 16,
                },
            },
            ':nth-child(1n) > :first-child': {
                ':nth-child(1n) > *': {
                    paddingTop: 12,
                },
            },
            ':nth-child(1n) > :last-child': {
                ':nth-child(1n) > *': {
                    paddingBottom: 16,
                },
            },
        },
    },
    c_bx: {
        marginRight: 10,
        width: 12,
        height: 12,
        borderRadius: 2,
    },
    cell: {
        outline: 'none',
        cursor: 'pointer',
    },
})
