import React from 'react'
import cn from 'classnames'
import { css, StyleSheet } from 'aphrodite'
import moment from 'moment'
import { CandidateExperience, CandidateInfo, StatusAndSource } from './CardInfo'
import { COLORS } from '../../../utils/colors'
import { CheckSizeL, CloseSizeM, DoubleCheckSizeM, Time, XCircleOutlineSizeM } from '../../svgs/Svgs'
import Button from '../../common/Button'

const Statuses = {
    1: Waiting,
    2: Accept,
    3: Reject,
    6: Passed,
    7: HasPassed,
    8: NotPassed,
}

function Waiting({ params }) {
    const statusStyles = statusStylesheet(COLORS.midGray, COLORS.lotion, COLORS.smoothGray)

    return (
        <div className={css(statusStyles.cont)}>
            <div className="align-center gap-1">
                <Time color={COLORS.gray} />

                <h3 className={css(statusStyles.label)}>В ожидании</h3>
            </div>

            <div className="is-flex direction-column">
                <h3 className={css(statusStyles.location)}>
                    {params.format === 1 ? 'Онлайн' : params.location}
                </h3>

                <h3 className={css(statusStyles.date)}>
                    {moment(params.date).format('DD MMMM YYYY')}・{moment(params.time, 'HH:mm:ss').format('HH:mm')}
                </h3>
            </div>
        </div>
    )
}

function Accept({ params }) {
    const statusStyles = statusStylesheet(COLORS.mainColor, '#E3F3EC', '#E3F3EC')

    return (
        <div className={css(statusStyles.cont)}>
            <div className="align-center gap-1">
                <CheckSizeL color={COLORS.mainColor} />

                <h3 className={css(statusStyles.label)}>Я приду на собеседование</h3>
            </div>

            <div className="is-flex direction-column">
                <h3 className={css(statusStyles.location)}>
                    {params.format === 1 ? 'Онлайн' : params.location}
                </h3>

                <h3 className={css(statusStyles.date)}>
                    {moment(params.date).format('DD MMMM YYY')}・{moment(params.time, 'HH:mm:ss').format('HH:mm')}
                </h3>
            </div>
        </div>
    )
}

function Reject({ params }) {
    const statusStyles = statusStylesheet(COLORS.lightRed, '#FEEBEF', '#FEEBEF')

    return (
        <div className={css(statusStyles.cont)}>
            <div className="align-center gap-1">
                <CloseSizeM color={COLORS.lightRed} />

                <h3 className={css(statusStyles.label)}>Я не смогу прийти</h3>
            </div>

            <div className="is-flex direction-column">
                <h3 className={css(statusStyles.location)}>
                    {params.format === 1 ? 'Онлайн' : params.location}
                </h3>

                <h3 className={css(statusStyles.date)}>
                    {moment(params.date).format('DD MMMM YYY')}・{moment(params.time, 'HH:mm:ss').format('HH:mm')}
                </h3>
            </div>
        </div>
    )
}

function NotPassed() {
    const statusStyles = statusStylesheet(COLORS.gray, COLORS.smoothGray, COLORS.smoothGray)

    return (
        <div className={css(statusStyles.cont)}>
            <div className="align-center gap-1">
                <XCircleOutlineSizeM />

                <h3 className={css(statusStyles.label)}>Собеседование не прошло</h3>
            </div>

            <div className="is-flex direction-column">
                <h3 className={css(statusStyles.location)}>Impact technology Hub</h3>

                <h3 className={css(statusStyles.date)}>
                    5 ноября 2023・09:00
                </h3>
            </div>
        </div>
    )
}

function Passed() {
    const statusStyles = statusStylesheet(COLORS.gray, COLORS.smoothGray, COLORS.smoothGray)

    return (
        <div className={css(statusStyles.cont)}>
            <div className="align-center gap-1">
                <DoubleCheckSizeM />

                <h3 className={css(statusStyles.label)}>Собеседование прошло</h3>
            </div>

            <div className="is-flex direction-column">
                <h3 className={css(statusStyles.location)}>Impact technology Hub</h3>

                <h3 className={css(statusStyles.date)}>
                    5 ноября 2023・09:00
                </h3>
            </div>
        </div>
    )
}

function HasPassed() {
    const statusStyles = statusStylesheet(COLORS.midGray, COLORS.lotion, COLORS.smoothGray)

    return (
        <div className="is-flex direction-column gap-2">
            <div className={css(statusStyles.cont)}>
                <div className="is-flex direction-column">
                    <h3 className={css(statusStyles.location)}>Impact technology Hub</h3>

                    <h3 className={css(statusStyles.date)}>
                        5 ноября 2023・09:00
                    </h3>
                </div>
            </div>

            <div className="is-flex direction-column gap-1">
                <h3 className={css(statusStyles.location)}>
                    Собеседование прошло?
                </h3>

                <div className={cn('align-center', css(s.btnGroup))}>
                    <Button onClick={(e) => e.stopPropagation()} className={css(s.btn, s.notAccept)}>Нет</Button>

                    <Button onClick={(e) => e.stopPropagation()} className={css(s.btn, s.accept)}>Да</Button>
                </div>
            </div>
        </div>
    )
}

export default function CardMeetup({ data, isDragging, setModal }) {
    const { params } = data
    const StatusComponent = params.status ? Statuses[params.status] : Waiting

    return (
        <div
            className={cn(css(s.wrap), { [css(s.onDrag)]: isDragging })}
            onClick={() => setModal({ act: true, id: data.id, type: 'detail' })}
        >
            <div className="is-flex gap-2 direction-column">
                <CandidateInfo data={data} />

                <div className={cn(
                    'is-flex direction-column gap-2',
                )}>
                    <CandidateExperience data={data} />

                    <StatusComponent params={params} />

                    <StatusAndSource data={data} />
                </div>
            </div>
        </div>
    )
}

const s = StyleSheet.create({
    wrap: {
        backgroundColor: '#fff',
        padding: 12,
        cursor: 'pointer',
        borderRadius: 4,
        border: `1px solid ${COLORS.smoothGray}`,
        boxShadow: '0px 2px 4px rgba(136, 136, 136, 0.25)',
        ':hover': {
            border: `1px solid ${COLORS.lightGray}`,
            boxShadow: '0px 2px 4px rgba(136, 136, 136, 0.35)',
        },
    },
    onDrag: {
        background: 'rgba(255, 255, 255, 0.1)',
        border: `1px solid ${COLORS.skyblue}`,
        boxShadow: '0px 4px 16px rgba(0, 113, 218, 0.4)',
        backdropFilter: 'blur(4px)',
    },
    btnGroup: {
        gap: 10,
    },
    btn: {
        flex: 1,
        height: 28,
        borderRadius: 4,
        fontSize: 13,
        fontWeight: '500',
    },
    notAccept: {
        color: COLORS.gray,
        border: `1px solid ${COLORS.gray}`,
    },
    accept: {
        color: COLORS.white,
        borderRadius: 4,
        background: 'linear-gradient(180deg, #2EA97D 0%, #138753 100%)',
        // eslint-disable-next-line max-len
        boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.10) inset, 0px -1px 0px 0px rgba(0, 0, 0, 0.25) inset, 0px 4px 12px 0px rgba(44, 67, 50, 0.10)',
    },
})

const statusStylesheet = (color, backgroundColor, borderColor) => StyleSheet.create({
    cont: {
        borderRadius: 4,
        padding: 6,
        border: `1px solid ${borderColor}`,
        backgroundColor,
        display: 'flex',
        flexDirection: 'column',
        gap: 6,
    },
    label: {
        fontSize: 11,
        fontWeight: '500',
        color,
    },
    location: {
        fontSize: 11,
        fontWeight: '500',
        color: COLORS.midGray,
    },
    date: {
        fontSize: 12,
        fontWeight: '700',
        color: '#343836',
    },
})
