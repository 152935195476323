import React, { useEffect, useState } from 'react'
import { css, StyleSheet } from 'aphrodite'
import Table from '../common/Table'
import { useLoad } from '../../hooks/request'
import { VACANCY_ARCHIVE_LIST } from '../../urls'
import VacancyTableHeader from './VacancyTableHeader'
import VacancyTableArchiveItem from './VacancyTableArchiveItem'

export default function VacancyArchiveTable({ setVacanciesCount }) {
    const [page, setPage] = useState(1)
    const [pageSize, setPageSize] = useState(10)
    const [sort, setSort] = useState({})
    const params = { page, 'per-page': pageSize, sort: Object.values(sort).filter((e) => e !== '').join('&') }
    const loadInfo = useLoad({ url: VACANCY_ARCHIVE_LIST, params }, [page, pageSize, sort])

    const data = loadInfo.response ? loadInfo.response.data : []
    const meta = loadInfo.response ? loadInfo.response.meta : {}

    useEffect(() => {
        if (meta) setVacanciesCount(meta.totalCount)
    }, [meta])

    const headerArchiveColumns = [
        { id: 1, name: 'Название вакансии', width: 'calc(100% * 2.25 / 12)' },
        { id: 2, name: 'причина архива', width: 'calc(100% * 2.25 / 12)' },
        {
            id: 3,
            name: 'нанято',
            width: 'calc(100% * 1.75 / 12)',
        },
        {
            id: 4,
            title: 'Создана',
            name: 'createdAt',
            value: sort,
            isSortable: true,
            setValue: setSort,
            width: 'calc(100% * 1.75 / 12)',
        },
        {
            id: 5,
            title: 'Архивирована',
            name: 'archivedAt',
            value: sort,
            isSortable: true,
            setValue: setSort,
            width: 'calc(100% * 1.75 / 12)',
        },
        {
            id: 6,
            name: 'Рекрутер',
            width: 'calc(100% * 1.75 / 12)',
        },
        { id: 7, name: '', width: 'calc(100% * 0.5 / 12)' },
    ]

    return (
        <div className={css(s.cont)}>
            <Table
                isHoverable
                isStripped
                items={data}
                isScrollable
                setPage={setPage}
                loaderItemCount={10}
                pageSize={meta.perPage}
                page={meta.currentPage}
                count={meta.totalCount}
                className={css(s.table)}
                setPageSize={setPageSize}
                pageCount={meta.pageCount}
                loading={loadInfo.loading}
                customHeader={(
                    <VacancyTableHeader headerColumns={headerArchiveColumns} />
                )}
                renderItem={(item) => (
                    <VacancyTableArchiveItem item={item} loading={loadInfo.loading} />
                )} />
        </div>
    )
}

const s = StyleSheet.create({
    cont: {
        width: '100%',
        padding: '0 24px 24px',
        boxSizing: 'border-box',
    },
    table: {
        width: '100%',
        textAlign: 'left',
        height: 'calc(100% - 50px)',
    },
})
