import React from 'react'
import cn from 'classnames'
import { css, StyleSheet } from 'aphrodite'
import { Form, Formik } from 'formik'
import { isEmpty } from 'lodash'
import Layout from '../common/Layout'
import { PlusRounded } from '../svgs/Svgs'
import Button from '../common/Button'
import { MainInfo } from './employeeForm/MainInfo'
import { Education } from './employeeForm/Education'
import { WorkHistory } from './employeeForm/WorkHistory'
import { ContactInfo } from './employeeForm/ContactInfo'
import { AdditionalOptions } from './employeeForm/AdditionalOptions'
import { COLORS } from '../../utils/colors'
import { LanguagesForm } from '../common/LanguagesForm'

console.error = () => {}

export default function EmployeeForm({ initialValues = {}, onSubmit, onCancel, type, loading }) {
    return (
        <Layout padded={false}>
            <Formik
                enableReinitialize
                initialValues={{
                    firstName: '',
                    lastName: '',
                    birthDate: '',
                    email: '',
                    avatar: '',
                    department: '',
                    position: '',
                    location: '',
                    gender: null,
                    salary: '',
                    currency: 'UZS',
                    startOfWork: '',
                    role: '',
                    headOfEmployee: '',
                    education: [{ level: '', start_date: '', end_date: '', institution: '', additional: '' }],
                    experience: [{ position: '', start_date: '', end_date: '', job: '', achievements: '' }],
                    skill: [],
                    phoneNumber: '',
                    additionalPhoneNumber: '',
                    additionalEmail: '',
                    languageLevel: { 1: { lang: null, level: null } },
                    comment: '',
                    ...initialValues,
                }}
                onSubmit={onSubmit}>
                {({ setFieldValue, handleSubmit, values, setErrors, errors, touched, dirty }) => (
                    <>
                        <Form>
                            <div className={cn('justify-between gap-7', css(s.hd_box))}>
                                <h3 className={css(s.title)}>
                                    {isEmpty(initialValues) ? 'Добавление' : 'Редактирование'} сотрудника
                                </h3>

                                <div className="align-center gap-4">
                                    <Button type="reset" onClick={onCancel} className={css(s.cnl)}>
                                        Отмена
                                    </Button>

                                    <Button
                                        type="submit"
                                        strokeWidth={4}
                                        loaderWidth={20}
                                        loading={loading}
                                        loaderColor={COLORS.white}
                                        icon={type === 'create' && <PlusRounded />}
                                        className={cn(css(s.btn), {
                                            [css(s.sbm)]: isEmpty(errors) && dirty,
                                        })}
                                        onClick={(e) => {
                                            if (type === 'update' && !dirty) {
                                                e.preventDefault()
                                                return
                                            }
                                            handleSubmit(e)
                                        }}
                                    >
                                        {type === 'create' ? 'Создать' : 'Сохранить'}
                                    </Button>
                                </div>
                            </div>
                        </Form>

                        <div className={css(s.cont)}>
                            <div className="is-flex direction-column gap-7">
                                <MainInfo
                                    employeeUser={{ id: initialValues.userId, email: initialValues.email }}
                                    errors={errors}
                                    values={values}
                                    touched={touched}
                                    setErrors={setErrors}
                                    setFieldValue={setFieldValue}
                                />

                                <Education
                                    values={values}
                                    initialValues={initialValues}
                                    setFieldValue={setFieldValue}
                                />

                                <WorkHistory
                                    values={values}
                                    initialValues={initialValues}
                                    setFieldValue={setFieldValue}
                                />
                            </div>

                            <div className="is-flex direction-column gap-7">
                                <ContactInfo />

                                <LanguagesForm setFieldValue={setFieldValue} values={values} />

                                <AdditionalOptions />
                            </div>
                        </div>
                    </>
                )}
            </Formik>
        </Layout>
    )
}

const s = StyleSheet.create({
    ly: {
        width: '88.4%',
        scrollbarGutter: 'auto',
    },
    hd_box: {
        backgroundColor: '#fff',
        padding: '14px 32px',
    },
    title: {
        fontWeight: '600',
        fontSize: 24,
        color: '#181A19',
    },
    cnl: {
        padding: '11px 16px',
        color: COLORS.gray,
        fontSize: 14,
        fontWeight: '500',
        backgroundColor: 'transparent',
        border: `1px solid ${COLORS.gray}`,
        borderRadius: 4,
    },
    cont: {
        display: 'grid',
        gridTemplateColumns: '2fr 1fr',
        gap: '1.5rem',
        margin: '1.5rem 2rem',
    },
    box: {
        background: COLORS.white,
        border: `1px solid ${COLORS.smoothGray}`,
        boxShadow: '0px 2px 4px rgba(130, 130, 130, 0.05)',
        borderRadius: 4,
    },
    formName: {
        height: 'calc(3rem - 1px)',
        padding: '0 24px',
        display: 'flex',
        alignItems: 'center',
        fontSize: 13,
        fontWeight: '600',
        color: '#181A19',
        borderBottom: `1px solid ${COLORS.lightGray}`,
        textTransform: 'uppercase',
        ':nth-child(1n) > :first-child': {
            marginRight: 8,
        },
    },
    btn: {
        padding: '10px 16px',
        color: COLORS.white,
        fontSize: 14,
        height: 40,
        boxSizing: 'border-box',
        fontWeight: '500',
        borderRadius: 4,
        background: COLORS.gray,
        cursor: 'not-allowed',
        boxShadow: '0px 4px 12px rgba(44, 67, 50, 0.1), inset 0px -1px 0px rgba(0, 0, 0, 0.25), inset 0px 1px 0px rgba(255, 255, 255, 0.1)', // eslint-disable-line
    },
    sbm: {
        cursor: 'pointer',
        boxShadow: '0px 4px 12px rgba(44, 67, 50, 0.1), inset 0px -1px 0px rgba(0, 0, 0, 0.25), inset 0px 1px 0px rgba(255, 255, 255, 0.1)', // eslint-disable-line
        background: `linear-gradient(180deg, ${COLORS.mainColor} 0%, ${COLORS.lightMainColor} 100%)`,
        ':hover': {
            background: `linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), linear-gradient(180deg, ${COLORS.mainColor} 0%, ${COLORS.lightMainColor} 100%)`, // eslint-disable-line
            boxShadow: '0px 2px 4px rgba(130, 130, 130, 0.2), inset 0px -1px 0px rgba(0, 0, 0, 0.25), inset 0px 1px 0px rgba(255, 255, 255, 0.1)', // eslint-disable-line
        },
    },
})
