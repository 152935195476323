import React, { memo, useRef } from 'react'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import { useNavigate } from 'react-router-dom'
import { Archive,
    CheckSizeL,
    ChevronDownS,
    DefaultLocation,
    DisplayByColumn,
    DisplayByRow,
    List,
    SettingsLayout } from '../svgs/Svgs'
import { COLORS } from '../../utils/colors'
import { FilterSwitcher } from '../filter/FilterSwitcher'
import { useSidebar } from '../../hooks/sidebar'
import CandidatesSidebar from './CandidatesSidebar'
import { Popup } from '../Popup'

function MemoizedHeader({ view, setCandidateFilter, candidateFilter, candidatesCount }) {
    const [showSettingsBar, hideSettingsBar] = useSidebar(
        <CandidatesSidebar onClose={() => hideSettingsBar()} />,
    )
    const navigate = useNavigate()
    const popupRef = useRef()
    const popupItems = [
        { id: 1, label: 'Активные кандидаты', icon: List, types: ['board', 'table'] },
        { id: 2, label: 'Архив кандидатов', icon: Archive, types: ['archive'] },
    ]
    const archiveView = view === 'archive'

    return (
        <div className={cn(css(s.hd), 'justify-between')}>
            <div className="align-center gap-5">
                <div className="align-center gap-3">
                    <div className="align-center gap-3">
                        <div className={cn('flex-cent', css(s.defaultCompanyLogo))}>
                            <DefaultLocation color={COLORS.white} />
                        </div>

                        <h3 className={css(s.projectInfo)}>
                            Кандидаты
                        </h3>
                    </div>

                    <span className={css(s.dot)}>•</span>

                    <h3 className={css(s.projectInfo)}>{candidatesCount}</h3>
                </div>

                <Popup
                    ref={popupRef}
                    trigger={(
                        <div className={cn(css(s.dTrigger), 'flex-cent gap-1')}>
                            <h3>
                                Показать: <span className={css(s.fil)}>{popupItems[archiveView ? 1 : 0].label}</span>
                            </h3>

                            <ChevronDownS />
                        </div>
                    )}
                    items={popupItems}
                    renderItem={(e) => (
                        <li
                            key={e.id}
                            onClick={() => {
                                navigate(`?view=${e.types[0]}`)
                                popupRef.current.onClose()
                                setCandidateFilter({ act: false })
                            }}
                            className={cn(
                                'justify-between',
                                css(s.cardSetting),
                            )}>
                            <div className="align-center gap-2">
                                <e.icon color={!e.types.includes(view) ? '#CCCDCD' : COLORS.white} />

                                <h3 className={cn(
                                    css(s.popupItemLabel), { [css(s.slLabel)]: e.types.includes(view) },
                                )}>
                                    {e.label}
                                </h3>
                            </div>

                            {e.types.includes(view) && <CheckSizeL />}
                        </li>
                    )}
                />
            </div>

            <div className={cn('align-center', css(s.panel))}>
                {!archiveView && (
                    <>
                        <div className={css(s.dpl)}>
                            <span
                                onClick={() => navigate('?view=board')}
                                className={cn(css(s.col), { [css(s.actCol)]: view === 'board' })}>
                                <DisplayByRow />
                            </span>

                            <span
                                onClick={() => navigate('?view=table')}
                                className={cn(css(s.col), { [css(s.actCol)]: view === 'table' })}>
                                <DisplayByColumn />
                            </span>
                        </div>

                        <FilterSwitcher setFilter={setCandidateFilter} filter={candidateFilter} />
                    </>
                )}

                <span onClick={() => showSettingsBar()} className={css(s.panItem)}><SettingsLayout /></span>
            </div>
        </div>
    )
}

export const CandidateHeader = memo(MemoizedHeader)

const s = StyleSheet.create({
    hd: {
        padding: '16px 24px 14px',
    },
    panel: {
        ':nth-child(1n) > *': {
            marginRight: 16,
        },
        ':nth-child(1n) > :last-child': {
            marginRight: 0,
        },
    },
    panItem: {
        cursor: 'pointer',
        display: 'inline-flex',
        padding: 6,
        background: COLORS.white,
        borderRadius: 4,
        border: '1px solid',
        borderColor: COLORS.smoothGray,
        ':hover': {
            borderColor: COLORS.lightGray,
            boxShadow: '0px 2px 4px rgba(168, 168, 168, 0.15)',
        },
        ':last-of-type': {
            ':nth-child(1n) > :first-child': {
                ':nth-child(1n) > *': {
                    stroke: COLORS.midGray,
                },
            },

        },
    },
    dpl: {
        ':nth-child(1n) > *': {
            padding: 8,
            display: 'inline-flex',
            cursor: 'pointer',
        },
        ':nth-child(1n) > :first-child': {
            borderTopLeftRadius: 4,
            borderBottomLeftRadius: 4,
        },
        ':nth-child(1n) > :last-child': {
            borderTopRightRadius: 4,
            borderBottomRightRadius: 4,
        },
    },
    col: {
        background: '#fff',
        ':nth-child(1n) > :first-child': {
            ':nth-child(1n) > *': {
                fill: '#92929D',
            },
        },
    },
    actCol: {
        background: COLORS.mainColor,
        ':nth-child(1n) > :first-child': {
            ':nth-child(1n) > *': {
                fill: '#FFF',
            },
        },
    },
    projectInfo: {
        color: COLORS.dark,
        fontSize: 20,
        fontWeight: '700',
    },
    defaultCompanyLogo: {
        height: 36,
        width: 36,
        borderRadius: 4,
        boxSizing: 'border-box',
        border: `1px solid ${COLORS.lightGray}`,
        background: COLORS.mainColor,
    },
    dot: {
        color: COLORS.gray,
    },
    dTrigger: {
        border: `1px solid ${COLORS.lightGray}`,
        borderRadius: 4,
        height: 32,
        padding: '0 12px',
        cursor: 'pointer',
        background: COLORS.white,
        color: COLORS.midGray,
        fontSize: 14,
        fontWeight: '500',
    },
    cardSetting: {
        width: '15.25rem',
    },
    popupItemLabel: {
        fontWeight: '500',
        fontSize: 12,
        color: COLORS.lightGray,
    },
    slLabel: {
        color: COLORS.white,
    },
    fil: {
        color: '#181A19',
        fontWeight: '600',
        fontSize: 14,
    },
})
