import React from 'react'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import { LoadingSkeleton } from '../common/LoadingSkeleton'
import { COLORS, tagStyleSheet } from '../../utils/colors'
import { strCutter } from '../../utils/string'
import { ageRuFormat } from '../../utils/number'

function Skeleton() {
    return (
        <tr className={css(s.loadItem)}>
            <td className={css(s.colItem)}>
                <div className={cn('align-start direction-column gap-1')}>
                    <LoadingSkeleton height={15} width="10rem" />

                    <LoadingSkeleton height={13} width="8rem" />
                </div>
            </td>

            <td><LoadingSkeleton height={20} width="50%" /></td>

            <td><LoadingSkeleton height={20} width="50%" /></td>

            <td><LoadingSkeleton height={20} width="50%" /></td>

            <td><LoadingSkeleton height={20} width="50%" /></td>

            <td className={css(s.colItem)}>
                <div className={cn('align-center gap-2')}>
                    <LoadingSkeleton width={24} height={24} borderRadius="50%" />

                    <LoadingSkeleton height={15} width="12rem" />
                </div>
            </td>

            <td className={css(s.colItem)} />
        </tr>
    )
}

export default function CandidateTableArchiveItem({ item, loading }) {
    return !loading ? (
        <tr className={css(s.item)}>
            <td className={css(s.colItem)}>
                <h3>{item.fullname}</h3>

                <div title={`${item.department} • ${item.position}`} className="align-center gap-1">
                    <h3>{strCutter(item.department, 25)} <span>•</span> {strCutter(item.position, 25)}</h3>
                </div>
            </td>

            <td>
                {item.vacancyName}
            </td>

            <td>{item.colName}</td>

            <td>{ageRuFormat(item.experience)}</td>

            <td>
                {item.recruiter || <div>&#8212;&#8212;&#8212;</div>}
            </td>

            <td>
                {item.tagName
                    ? (
                        <div className="is-flex">
                            <div className={cn('flex-cent', css(s.tag), tagStyleSheet(item.tagColor))}>
                                {item.tagName}
                            </div>
                        </div>
                    ) : (
                        <div style={{ paddingLeft: 15 }}>&#8212;&#8212;&#8212;</div>
                    )}
            </td>

            <td />
        </tr>
    ) : <Skeleton />
}

const s = StyleSheet.create({
    item: {
        height: '3.75rem',
        background: COLORS.white,
        color: '#181A19',
        fontSize: 13,
        fontWeight: '500',
        wordBreak: 'break-word',
        ':nth-child(1n) > :last-child': {
            paddingRight: '1rem',
        },
    },
    menuItem: {
        width: 20,
        height: 20,
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'center',
    },
    loadItem: {
        height: '3.75rem',
        background: '#FFFFFF',
        ':nth-child(1n) > :last-child': {
            paddingRight: '1rem',
        },
    },
    colItem: {
        ':first-of-type': {
            paddingLeft: '1.5rem',
            ':nth-child(1n) > :last-child': {
                fontWeight: '400',
                fontSize: 11,
                color: '#6D7471',
            },
        },
        ':last-of-type': {
            textAlign: 'right',
        },
    },
    tag: {
        height: 24,
        padding: '0 6px',
        borderRadius: 4,
    },
})
