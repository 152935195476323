import React, { useRef } from 'react'
import cn from 'classnames'
import { css, StyleSheet } from 'aphrodite'
import moment from 'moment/moment'
import { DefaultLocation,
    EditPencil,
    EyeCloseSizeM,
    EyeSizeM,
    MapPin,
    ThreeDotsMenu,
    WalletSizeM } from '../../../svgs/Svgs'
import { COLORS } from '../../../../utils/colors'
import { Popup } from '../../../Popup'
import { fromToStr, strCutter } from '../../../../utils/string'

export default function GridViewItem({ item, onClick, companyLogo, onEdit, onActivate }) {
    const popUpRef = useRef()

    return (
        <div onClick={onClick} className={cn(css(s.mainWrap), { [css(s.inactive)]: !item.publish })}>
            <div className={css(s.flexCont)}>
                <div className={css(s.bodyWrap)}>
                    <div className={css(s.companyInfo)}>
                        {companyLogo ? (
                            <div className={css(s.logoCont)}>
                                <img src={companyLogo} alt="#CompanyLogo" />
                            </div>
                        ) : (
                            <div className={css(s.defaultLogo)}>
                                <DefaultLocation color={COLORS.white} />
                            </div>
                        )}

                        <div className="is-flex direction-column">
                            <h3 title={item.name} className={css(s.positionName)}>
                                {strCutter(item.name, 36)}
                            </h3>

                            <h3 className={css(s.date)}>
                                {moment(item.createdAt).format('DD MMMM YYYY')}
                            </h3>
                        </div>
                    </div>

                    <div className={css(s.detailsWrap)}>
                        <div className={css(s.detailWrap)}>
                            <WalletSizeM />

                            <h3>
                                {fromToStr(
                                    item.salaryMin,
                                    item.salaryMax,
                                    item.currency,
                                    <h3 style={{ color: COLORS.gray }}>По итогам собеседование</h3>,
                                )}
                            </h3>
                        </div>

                        <div className={css(s.detailWrap)}>
                            <MapPin />

                            {item.location ? (
                                <h3>
                                    {strCutter(item.location, 27)}
                                </h3>
                            ) : <h3 style={{ color: COLORS.gray }}>Не указан</h3>}
                        </div>
                    </div>
                </div>

                <div>
                    <Popup
                        ref={popUpRef}
                        onClick={(event) => {
                            event.stopPropagation()
                            popUpRef.current.onOpen()
                        }}
                        trigger={(
                            <div className={css(s.menu)}>
                                <ThreeDotsMenu />
                            </div>
                        )}>
                        <ul className={css(s.popUpWrap)}>
                            <li onClick={onEdit} className={cn('justify-between', css(s.cardSetting))}>
                                <div className="align-center" style={{ gap: 8 }}>
                                    <EditPencil /> Изменить
                                </div>
                            </li>

                            <li
                                onClick={onActivate}
                                className={cn('justify-between', css(s.cardSetting))}>
                                <div className="align-center" style={{ gap: 8 }}>
                                    {item.publish
                                        ? <><EyeCloseSizeM /> Деактивировать</>
                                        : <><EyeSizeM /> Активировать</>}
                                </div>
                            </li>
                        </ul>
                    </Popup>
                </div>
            </div>
        </div>
    )
}

const s = StyleSheet.create({
    mainWrap: {
        minWidth: '100%',
        cursor: 'pointer',
        background: COLORS.white,
        padding: '16px 16px 16px 16px',
        boxSizing: 'border-box',
        border: `1px solid ${COLORS.smoothGray}`,
        boxShadow: '0px 2px 4px rgba(130, 130, 130, 0.05)',
        borderRadius: 4,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        ':hover': {
            border: `1px solid ${COLORS.lightGray}`,
            boxShadow: '0px 2px 8px rgba(136, 136, 136, 0.25)',
        },
    },
    flexCont: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    inactive: {
        background: COLORS.smoothGray,
        ':hover': {
            border: `1px solid ${COLORS.smoothGray}`,
            boxShadow: '0px 2px 4px rgba(130, 130, 130, 0.05)',
        },
    },
    logoCont: {
        width: 40,
        height: 40,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 4,
        boxSizing: 'border-box',
        ':nth-child(1n) > img': {
            width: 'inherit',
            borderRadius: 4,
        },
    },
    defaultLogo: {
        width: 40,
        height: 40,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: COLORS.mainColor,
        border: `1px solid ${COLORS.white}`,
        borderRadius: 4,
        boxSizing: 'border-box',
    },
    bodyWrap: {
        width: '100%',
    },
    menu: {
        paddingTop: '4px',
        cursor: 'pointer',
    },
    companyInfo: {
        display: 'flex',
        alignItems: 'center',
        gap: '12px',
        ':nth-child(1n) > img': {
            width: 40,
            height: 40,
        },
    },
    positionName: {
        fontWeight: 700,
        fontSize: 16,
        color: COLORS.dark,
    },
    date: {
        color: COLORS.gray,
        fontSize: 13,
        fontWeight: '400',
        marginTop: '4px',
    },
    detailsWrap: {
        marginTop: 17,
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        gap: '16px',
    },
    detailWrap: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 8,
        ':nth-child(1n) > svg': {
            ':nth-child(1n) > :first-child': {
                stroke: COLORS.mainColor,
            },
            ':nth-child(1n) > :last-child': {
                stroke: COLORS.mainColor,
            },
        },
        background: 'inherit',
        border: `1px solid ${COLORS.smoothGray}`,
        boxShadow: '0px 2px 4px rgba(130, 130, 130, 0.05)',
        borderRadius: '16px',
        padding: '4px 12px',
        fontWeight: 500,
        fontSize: '13px',
        color: '#414644',
        width: 'fit-content',
        whiteSpace: 'nowrap',
    },
    cardSetting: {
        width: '10rem',
        userSelect: 'none',
    },
    popUpWrap: {
        borderRadius: 4,
        margin: 0,
        ':nth-child(1n) > *': {
            color: '#fff',
            fontSize: 12,
            background: 'rgba(65, 70, 68, 0.9)',
            backdropFilter: 'blur(8px)',
            fontWeight: '500',
            padding: '10px 12px',
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
            borderBottom: '1px solid #5e6360',
            ':hover': {
                background: 'rgba(87, 90, 89, 0.9)',
            },
            ':nth-child(1n) > :first-child': {
                marginRight: 8,
            },
        },
        ':nth-child(1n) > :last-child': {
            borderBottomWidth: 0,
            borderBottomLeftRadius: 4,
            borderBottomRightRadius: 4,
        },
        ':nth-child(1n) > :first-child': {
            borderTopLeftRadius: 4,
            borderTopRightRadius: 4,
        },
    },
})
