import React from 'react'
import { useMessage } from '../../../hooks/message'
import { usePostRequest } from '../../../hooks/request'
import { POSITIONS_CREATE } from '../../../urls'
import SidebarLayout from '../../common/SidebarLayout'
import PositionForm from './PositionForm'

export default function PositionCreate({ onSuccess, onClose }) {
    const create = usePostRequest({ url: POSITIONS_CREATE })
    const [showMessage] = useMessage()

    const onSubmit = async (data) => {
        const { success } = await create.request({ data })

        if (success) {
            showMessage('Успешно!', 'success-msg')
            onSuccess()
        }
    }

    return (
        <SidebarLayout title="Создание должности" onClose={onClose}>
            <PositionForm loading={create.loading} onClose={onClose} onSubmit={onSubmit} />
        </SidebarLayout>
    )
}
