import React, { useCallback, useEffect, useState } from 'react'
import { css, StyleSheet } from 'aphrodite'
import { DragDropContext } from 'react-beautiful-dnd'
import { isEmpty } from 'lodash'
import { VacanciesList } from './VacanciesList'
import { useGetRequest, usePutRequest } from '../../hooks/request'
import { COLUMN_CARDS, COLUMN_LIST, VACANCY_COLUMN, VACANCY_MOVE } from '../../urls'
import { isInViewport } from '../../utils/viewport'
import { fetchDataByFilter } from '../../utils/filter'
import { useMessage } from '../../hooks/message'

const onDragEnd = (result, columns, setColumns, moveCard, getCards) => {
    const { source, destination } = result

    if (!result.destination) return

    let vacancyId
    let columnId
    let after

    if (source.droppableId !== destination.droppableId) {
        const sourceColumn = columns[source.droppableId]
        const destColumn = columns[destination.droppableId]
        const sourceItems = [...sourceColumn.cards]
        const destItems = [...destColumn.cards]

        columnId = destColumn.id
        vacancyId = sourceItems[source.index].id
        after = destItems[destination.index - 1] ? destItems[destination.index - 1].id : null

        const [removed] = sourceItems.splice(source.index, 1)
        destItems.splice(destination.index, 0, removed)
        setColumns({
            ...columns,
            [source.droppableId]: {
                ...sourceColumn,
                cards: sourceItems,
            },
            [destination.droppableId]: {
                ...destColumn,
                cards: destItems,
            },
        })
    } else {
        if (source.index === destination.index) return
        const column = columns[source.droppableId]
        const copiedItems = [...column.cards]
        const [removed] = copiedItems.splice(source.index, 1)
        copiedItems.splice(destination.index, 0, removed)

        columnId = column.id
        vacancyId = copiedItems[destination.index].id
        after = copiedItems[destination.index - 1] ? copiedItems[destination.index - 1].id : null

        setColumns({
            ...columns,
            [source.droppableId]: {
                ...column,
                cards: copiedItems,
            },
        })
    }

    const sourceNodeId = `obs-${columns[source.droppableId].id}`
    const sourceNode = document.getElementById(sourceNodeId)
    moveCard.request({
        data: {
            vacancyId,
            columnId,
            after,
        },
    })
        .then(() => {
            if (source.droppableId === destination.droppableId) return
            setColumns((prev) => ({
                ...prev,
                [source.droppableId]: { ...prev[source.droppableId], count: prev[source.droppableId].count - 1 },
                [destination.droppableId]: {
                    ...prev[destination.droppableId],
                    count: prev[destination.droppableId].count + 1,
                },
            }))
        })
        .then(() => {
            if (sourceNode && isInViewport(sourceNode)) {
                getCards.request({
                    url: COLUMN_CARDS.replace('{id}', columns[source.droppableId].id),
                    params: { page: 1 },
                })
                    .then(({ response }) => {
                        setColumns((prev) => {
                            const column = prev[source.droppableId]
                            return {
                                ...prev,
                                [source.droppableId]: {
                                    ...column,
                                    cards: [...column.cards, ...response.data.filter(
                                        (e) => !column.cards.map((i) => i.id).includes(e.id),
                                    )],
                                },
                            }
                        })
                    })
                    .catch(() => {})
            }
        })
}

export default function VacancyColumns({ vacancyFilter, setVacanciesCount }) {
    const getCards = useGetRequest()
    const [columns, setColumns] = useState([])
    const moveCard = usePutRequest({ url: VACANCY_MOVE })
    const getColumns = useGetRequest({ url: COLUMN_LIST })
    const putColumn = usePutRequest()
    const [showMessage] = useMessage()

    const data = getColumns.response ? getColumns.response.data : []

    const onChangeColumn = useCallback(async (cId, cInd, color) => {
        const { success, error } = await putColumn.request({
            url: VACANCY_COLUMN.replace('{id}', cId),
            data: { color },
        })

        if (success) {
            const column = data[cInd]
            column.color = color
            data.splice(cInd, 1, column)
            getColumns.setResponse({ ...getColumns.response, data })
            setColumns([...data])
        } else {
            const { errors, message } = error.data
            showMessage(errors ? errors[0].message : message, 'error-msg')
        }
    }, [data])

    useEffect(() => {
        fetchDataByFilter(getColumns, vacancyFilter)
    }, [vacancyFilter])

    useEffect(() => {
        if (!isEmpty(data)) {
            setColumns(data)
            setVacanciesCount(data.map((col) => col.count).reduce((x, y) => x + y, 0))
        }
    }, [data])

    return (
        <div id="rp-c" className={css(s.cont)}>
            <DragDropContext onDragEnd={(result) => onDragEnd(result, columns, setColumns, moveCard, getCards)}>
                {Object.entries(columns).map(([columnId, column], index) => (
                    <VacanciesList
                        index={index}
                        key={columnId}
                        column={column}
                        columns={columns}
                        columnId={columnId}
                        onChangeColumn={onChangeColumn}
                        setColumns={setColumns} />
                ))}
            </DragDropContext>
        </div>
    )
}

const s = StyleSheet.create({
    cont: {
        padding: '0 24px 10px',
        display: 'flex',
        boxSizing: 'border-box',
        gap: '1.5rem',
        overflow: 'auto',
        marginBottom: 10,
        height: 'calc(100% - 78px)',
    },
})
