import React from 'react'
import { ErrorMessage } from 'formik'
import { css, StyleSheet } from 'aphrodite'
import { COLORS } from '../../utils/colors'

export default function ValidationErrorMessage({ name, errorMsg = '', ...attributes }) {
    return (
        <ErrorMessage
            name={name}
            {...attributes}
            render={(msg) => <div className={css(s.dng)}>{errorMsg || msg}</div>} />
    )
}

const s = StyleSheet.create({
    dng: {
        fontWeight: '400',
        fontSize: 10,
        color: COLORS.lightRed,
        marginTop: 4,
    },
})
