import React, { useState } from 'react'
import cn from 'classnames'
import { css, StyleSheet } from 'aphrodite'
import { CloseSizeL } from '../../svgs/Svgs'
import { COLORS } from '../../../utils/colors'
import GeneralInfo from './GeneralInfo'
import Experience from './Experience'

export default function EmployeeInfo({ onClose, info }) {
    const [section, setSection] = useState('main')

    return (
        <div className={cn('is-flex direction-column', css(s.cont))}>
            <div className={cn('justify-between', css(s.types))}>
                <div className="align-center">
                    <div
                        className={css(s.tab)}
                        onClick={() => setSection('main')}
                        datatype={section === 'main' ? 'selected' : undefined}> {/* eslint-disable-line */}
                        <h3
                            className={cn(
                                'align-center',
                                css(s.type, section === 'main' ? s.actTypeTxt : s.typeColor),
                            )}>
                            Общая информация
                        </h3>

                        <div className={cn(css(s.ind), { [css(s.actInd)]: section === 'main' })} />
                    </div>

                    <div
                        className={css(s.tab)}
                        onClick={() => setSection('skills')}
                        datatype={section === 'skills' ? 'selected' : undefined}> {/* eslint-disable-line */}
                        <h3
                            className={cn(
                                'align-center',
                                css(s.type, section === 'skills' ? s.actTypeTxt : s.typeColor),
                            )}>
                            Опыт и навыки
                        </h3>

                        <div className={cn(css(s.ind), { [css(s.actInd)]: section === 'skills' })} />
                    </div>

                    {/* <div */}
                    {/*     className={css(s.tab)} */}
                    {/*     onClick={() => setSection('message')} */}
                    {/*     datatype={section === 'message' ? 'selected' : undefined}> /!* eslint-disable-line *!/ */}
                    {/*     <h3 */}
                    {/*         className={cn( */}
                    {/*             'align-center', */}
                    {/*             css(s.type, section === 'message' ? s.actTypeTxt : s.typeColor), */}
                    {/*         )}> */}
                    {/*         Письмо */}
                    {/*     </h3> */}

                    {/*     <div className={cn(css(s.ind), { [css(s.actInd)]: section === 'message' })} /> */}
                    {/* </div> */}
                </div>

                <div className="is-flex gap-3">
                    {/* <DownloadSizeM /> */}

                    <CloseSizeL style={{ cursor: 'pointer' }} onClick={onClose} />
                </div>
            </div>

            {section === 'main' ? <GeneralInfo info={info} /> : <Experience info={info} />}
        </div>
    )
}

const s = StyleSheet.create({
    cont: {
        background: COLORS.smoothGray,
        borderRadius: '0 4px 4px 0',
        flex: 1,
        height: '100%',
    },
    tab: {
        cursor: 'pointer',
        ':not([datatype=selected])': {
            ':hover': {
                ':nth-child(1n) > :last-child': {
                    backgroundColor: COLORS.gray,
                },
            },
        },
    },
    actInd: {
        backgroundColor: COLORS.mainColor,
    },
    actTypeTxt: {
        color: COLORS.mainColor,
    },
    type: {
        fontSize: 14,
        fontWeight: '600',
    },
    typeColor: {
        color: COLORS.gray,
    },
    activeColor: {
        color: '',
    },
    ind: {
        width: '100%',
        height: 3,
        marginTop: 16,
        borderTopLeftRadius: 12,
        borderTopRightRadius: 12,
        marginBottom: -1,
    },
    types: {
        padding: '0 1.5rem',
        borderBottom: `1px solid ${COLORS.chineseWhite}`,
        background: COLORS.white,
        borderTopRightRadius: 4,
        ':nth-child(1n) > :first-child': {
            gap: '2rem',
            paddingTop: '1.25rem',
        },
    },
})
