import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import { Form, Formik } from 'formik'
import React from 'react'
// import { components } from 'react-select'
import { useMessage } from '../../../hooks/message'
import { useLoad, usePutRequest } from '../../../hooks/request'
import { COMPANY_UPDATE, INDUSTRIES_LIST } from '../../../urls'
import { COLORS } from '../../../utils/colors'
import FormInput from '../../common/FormInput'
import FormInputPhone from '../../common/FormInputPhone'
import InputForm from '../../common/InputForm'
import SelectForm from '../../common/SelectForm'
import { LinkIconSizeL } from '../../svgs/Svgs'
// import { Context } from '../../../contexts/GlobalContext'

export default function CompanyInfoForm({ onClose, info, loadInfo }) {
    const loadIndustries = useLoad({ url: INDUSTRIES_LIST })
    const industries = loadIndustries.response ? loadIndustries.response.data : []
    const updateInfo = usePutRequest({ url: COMPANY_UPDATE })
    const [showMessage] = useMessage()
    // const { locations } = useContext(Context)

    // const companyLocations = locations.map((loc) => ({ ...loc, value: loc.id, label: loc.name }))

    // const styles = {
    //     multiValue: (baseStyles) => ({
    //         ...baseStyles,
    //         background: COLORS.mainColor,
    //         borderRadius: 4,
    //         margin: 0,
    //         height: 24,
    //         padding: '0 8px',
    //         display: 'flex',
    //         gap: 4,
    //         alignItems: 'center',
    //         justifyContent: 'center',
    //         ':nth-child(1n) > :last-child': {
    //             padding: 0,
    //             ':nth-child(1n) > :first-child': {
    //                 ':nth-child(1n) > path': {
    //                     fill: COLORS.white,
    //                 },
    //             },
    //         },
    //     }),
    //     multiValueLabel: () => ({
    //         color: COLORS.white,
    //         fontSize: 11,
    //         fontWeight: 500,
    //     }),
    // }

    // const IndicatorsContainer = (props) => <components.IndicatorsContainer {...props} />

    const initialValues = {
        industry: info.industryId || '',
        phoneNumber: info.phoneNumber || '',
        description: info.description || '',
        link: info.link || '',
        socialLinks: {
            telegram: '',
            instagram: '',
            facebook: '',
            linkedin: '',
            ...info.socialLinks,
        },
    }

    const onSubmit = async (data) => {
        if (data) {
            const { success, error } = await updateInfo.request({ data: {
                ...data,
                industryId: data.industry.value,
            } })

            if (success) {
                onClose()
                showMessage('Успешно!', 'success-msg')
                loadInfo.request()
                return
            }
            showMessage(error.data.errors[0].message || error.data.message, 'error-msg')
        }

        onClose()
    }

    return (
        <Formik initialValues={initialValues} onSubmit={onSubmit}>
            {({ values, handleChange }) => (
                <Form className={css(s.formikWrap)}>
                    <div className="justify-between align-center">
                        <div className="align-center gap-2">
                            <h3 className={css(s.title)}>О компании {info.name}</h3>
                        </div>

                        <div className={cn('align-center gap-2', css(s.btnGr))}>
                            <button type="reset" onClick={onClose} className={css(s.cancelBtn)}>
                                Отмена
                            </button>

                            <button type="submit" className={cn(css(s.saveBtn), 'align-center')}>
                                Сохранить
                            </button>
                        </div>
                    </div>

                    <div className={cn(css(s.inputArea), 'is-flex direction-column gap-7')}>
                        <div>
                            <SelectForm
                                label="Отрасль работы компании"
                                name="industry"
                                labelContClassName={css(s.label)}
                                contClassname={css(s.select)}
                                options={industries.map(({ id, name }) => ({ value: id, label: name }))}
                                placeholder={info.industryName && info.industryName.ru}
                            />

                            <FormInputPhone
                                label="Номер телефона"
                                name="phoneNumber"
                                labelClassName={css(s.label)}
                                className={cn(css(s.inputPhone))}
                                errorWithMessage={false}
                                fieldClassName={cn('is-flex direction-column gap-2', css(s.field))}
                                onChange={handleChange}
                            />

                            <FormInput
                                label="Ссылка на вебсайт"
                                name="link"
                                labelClassName={css(s.label)}
                                className={cn(css(s.input))}
                                errorWithMessage={false}
                                placeholder="Введите ссылку"
                                type="text"
                                onChange={handleChange}
                            />
                        </div>

                        <div>
                            <InputForm
                                label="О компании"
                                name="description"
                                labelClassName={css(s.label)}
                                className={css(s.textarea)}
                                placeholder="Введите"
                                component="textarea"
                                value={values.description}
                                onChange={handleChange}
                                maxLength="2000"
                            />

                            <span
                                className={cn(css(s.charCount),
                                    { [css(s.reachedLimit)]: values.description.length >= 2000 })}
                            >
                                Макс. количество: {values.description.length} / 2000
                            </span>
                        </div>

                        <div>
                            <FormInput
                                name="socialLinks.telegram"
                                icon={<LinkIconSizeL />}
                                label="Ссылка на Telegram"
                                placeholder="Введите ссылку"
                                labelClassName={css(s.label)}
                                fieldClassName={css(s.field)}
                                className={cn(css(s.input))}
                            />

                            <FormInput
                                name="socialLinks.instagram"
                                icon={<LinkIconSizeL />}
                                label="Ссылка на Instagram"
                                placeholder="Введите ссылку"
                                labelClassName={css(s.label)}
                                fieldClassName={css(s.field)}
                                className={cn(css(s.input))}
                            />

                            <FormInput
                                name="socialLinks.linkedin"
                                icon={<LinkIconSizeL />}
                                label="Ссылка на LinkedIn"
                                placeholder="Введите ссылку"
                                labelClassName={css(s.label)}
                                fieldClassName={css(s.field)}
                                className={cn(css(s.input))}
                            />

                            <FormInput
                                name="socialLinks.facebook"
                                icon={<LinkIconSizeL />}
                                label="Ссылка на Facebook"
                                placeholder="Введите ссылку"
                                labelClassName={css(s.label)}
                                fieldClassName={css(s.field)}
                                className={cn(css(s.input))}
                            />

                            {/* <SelectForm */}
                            {/*    isMulti */}
                            {/*    name="locations" */}
                            {/*    styles={styles} */}
                            {/*    eslint-disable-next-line max-len */}
                            {/*    value={values.locations.length ? values.locations.map((loc) => ({ ...loc, value: loc.id, label: loc.name })) : []} */}
                            {/*    closeMenuOnSelect={false} */}
                            {/*    customComponents={{ IndicatorsContainer, ClearIndicator: () => null }} */}
                            {/*    labelContClassName={css(s.lab)} */}
                            {/*    placeholderStyles={{ color: COLORS.gray, fontFamily: 'Roboto, sans-serif' }} */}
                            {/*    labelClassName={css(s.label)} */}
                            {/*    valueContainerStyles={{ padding: '8px 10px' }} */}
                            {/*    indicatorStyle={{ paddingRight: 10 }} */}
                            {/*    label="Локации компании" */}
                            {/*    placeholder={values.locations.length ? '+ Добавить ещё ' : 'Выберите места'} */}
                            {/*    options={companyLocations} */}
                            {/* /> */}
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
    )
}

const s = StyleSheet.create({
    title: {
        fontWeight: 700,
        fontSize: '20px',
        color: COLORS.lightBlack,
    },
    btnGr: {
        ':nth-child(1n) > *': {
            height: 28,
            boxSizing: 'border-box',
            fontWeight: '500',
            fontSize: 11,
            borderRadius: 4,
            cursor: 'pointer',
        },
    },
    cancelBtn: {
        color: COLORS.gray,
        border: `1px solid ${COLORS.gray}`,
        background: 'transparent',
        padding: '7.5px 12px',
        boxShadow: '0px 2px 4px rgba(130, 130, 130, 0.05)',
        borderRadius: '4px',
        cursor: 'pointer',
    },
    saveBtn: {
        color: COLORS.white,
        border: 'none',
        padding: '7.5px 8px',
        background: `linear-gradient(180deg, ${COLORS.mainColor} 0%, ${COLORS.lightMainColor} 100%)`,
        // eslint-disable-next-line max-len
        boxShadow: '0px 4px 12px rgba(44, 67, 50, 0.1), inset 0px -1px 0px rgba(0, 0, 0, 0.25), inset 0px 1px 0px rgba(255, 255, 255, 0.1)',
        borderRadius: '4px',
        cursor: 'pointer',
    },
    formikWrap: {
        width: '100%',
        ':nth-child(1n) > :first-child': {
            marginBottom: '12px',
        },
    },
    field: {
        position: 'relative',
    },
    textarea: {
        padding: '14px 16px',
        width: '100%',
        height: '132px',
        resize: 'none',
        marginTop: '8px',
        fontWeight: 500,
        fontSize: '14px',
        color: COLORS.dark,
        background: COLORS.white,
        border: `1px solid ${COLORS.lightGray}`,
        borderRadius: '4px',
        outline: 'none',
        ':placeholder': {
            color: COLORS.gray,
        },
        // eslint-disable-next-line max-len
        fontFamily: 'system-ui, -apple-system, "Roboto", "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif',
    },
    charCount: {
        fontSize: 12,
        fontWeight: '400',
        color: COLORS.gray,
        marginTop: 10,
    },
    reachedLimit: {
        color: COLORS.lightRed,
    },
    lab: {
        ':nth-child(1n) > span': {
            display: 'inline-flex',
        },
    },
    label: {
        fontWeight: 500,
        fontSize: '12px',
        color: COLORS.midGray,
    },
    input: {
        fontWeight: 500,
        fontSize: '14px',
        outline: 'none',
        marginTop: '8px',
        ':placeholder': {
            color: COLORS.gray,
        },
        display: 'flex',
        alignItems: 'center',
    },
    inputPhone: {
        fontWeight: 500,
        fontSize: '14px',
        outline: 'none',
        ':placeholder': {
            color: COLORS.gray,
        },
    },
    select: {
        fontWeight: 500,
        fontSize: '14px',
    },
    placeholder: {
        color: `${COLORS.gray} !important`,
    },
    inputArea: {
        width: '100%',
        padding: '0 32px 0 0',
        ':nth-child(1n) > :first-child': {
            display: 'grid',
            gridTemplateColumns: '1fr 1fr 1fr',
            gap: 20,
            alignItems: 'end',
            justifyContent: 'space-between',
        },
        ':nth-child(1n) > :last-child': {
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fit, minmax(17.25rem, 1fr))',
            gap: 20,
            ':nth-child(1n) > *': {
                flex: 1,
            },
        },
    },
    errorInput: {
        borderColor: COLORS.lightRed,
    },

    inp: {
        width: '100%',
        border: `1px solid ${COLORS.lightGray}`,
        borderRadius: 4,
        padding: '14px 16px',
        ':first-of-type': {
            paddingTop: 15,
        },
    },
    lbAboutEvent: {
        paddingBottom: 8,
    },
})
