import React, { createContext } from 'react'
import { usePersistState } from '../hooks/state'

export const FilterContext = createContext()

export default function FilterContextWrapper({ children }) {
    const [vacancyFilter, setVacancyFilter] = usePersistState('_r_fl', { act: false })
    const [employeeFilter, setEmployeeFilter] = usePersistState('_e_fl', { act: false })
    const [candidateFilter, setCandidateFilter] = usePersistState('_c_fl', { act: false })

    return (
        <FilterContext.Provider value={{
            vacancyFilter,
            setVacancyFilter,
            employeeFilter,
            setEmployeeFilter,
            candidateFilter,
            setCandidateFilter,
        }}>
            {children}
        </FilterContext.Provider>
    )
}
