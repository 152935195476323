import React, { useEffect, useState } from 'react'
import { css, StyleSheet } from 'aphrodite'
import usePlacesAutocomplete from 'use-places-autocomplete'
import { CheckUpList } from '../../svgs/Svgs'
import InputForm from '../../common/InputForm'
import { COLORS } from '../../../utils/colors'
import { required } from '../../../utils/validators'
import { Map, MapWrapper, MapSearcher } from '../../common/Map'
import FormInput from '../../common/FormInput'

function Address({ setFieldValue }) {
    const { suggestions: { data }, setValue, clearSuggestions } = usePlacesAutocomplete({ requestOptions: {
        locationBias: {
            east: 73.0551,
            west: 55.9965,
            north: 45.5854,
            south: 37.1841,
        },
        componentRestrictions: { country: 'uz' },
    } })

    return (
        <div style={{ position: 'relative' }}>
            <FormInput
                validate={required}
                errorWithMessage={false}
                name="address"
                onChange={({ target }) => {
                    setValue(target.value)
                    setFieldValue('address', target.value)
                }}
                placeholder="Введите адрес"
                labelClassName={css(s.label)}
                label="Адрес" />

            <MapSearcher
                onClear={() => {
                    setValue('')
                    setFieldValue('address', '')
                }}
                data={data}
                onSelect={({ address, lat, lng }) => {
                    setFieldValue('address', address)
                    setFieldValue('latitude', lat)
                    setFieldValue('longitude', lng)
                }}
                clearSuggestions={clearSuggestions} />
        </div>
    )
}

export default function LocationMainForm({ setFieldValue, values }) {
    const [userLocation, setUserLocation] = useState(null)

    useEffect(() => {
        const successCallback = (position) => {
            setUserLocation({ lat: position.coords.latitude, lng: position.coords.longitude })
        }

        navigator.geolocation.getCurrentPosition(successCallback)
    }, [])

    return (
        <div className={css(s.form)}>
            <div className={css(s.formName)}>
                <CheckUpList />

                <h3>Основная информация</h3>
            </div>

            <MapWrapper>
                <div className={css(s.accInpGr)}>
                    <FormInput
                        validate={required}
                        name="name"
                        errorWithMessage={false}
                        placeholder="Введите"
                        labelClassName={css(s.label)}
                        label="Название" />

                    <Address setFieldValue={setFieldValue} />

                    <InputForm
                        className={css(s.inp)}
                        name="latitude"
                        disabled
                        placeholder="Введите широту"
                        labelClassName={css(s.label)}
                        label="Широта" />

                    <InputForm
                        className={css(s.inp)}
                        name="longitude"
                        disabled
                        placeholder="Введите долготу"
                        labelClassName={css(s.label)}
                        label="Долгота" />

                    <div className={css(s.map)}>
                        <Map
                            // eslint-disable-next-line max-len
                            center={values.latitude && values.longitude ? { lat: values.latitude, lng: values.longitude } : null}
                            userLocation={userLocation}
                            onMapClick={({ address, lat, lng }) => {
                                setFieldValue('address', address)
                                setFieldValue('latitude', lat)
                                setFieldValue('longitude', lng)
                            }}
                            markers={values.latitude && values.longitude
                                ? [{ lat: +values.latitude, lng: +values.longitude }]
                                : undefined}
                        />
                    </div>
                </div>
            </MapWrapper>
        </div>
    )
}

const s = StyleSheet.create({
    form: {
        backgroundColor: '#fff',
        borderRadius: 4,
    },
    formName: {
        height: 'calc(3rem - 1px)',
        padding: '0 24px',
        display: 'flex',
        alignItems: 'center',
        fontSize: 13,
        fontWeight: '600',
        color: COLORS.dark,
        borderBottom: `1px solid ${COLORS.lightGray}`,
        textTransform: 'uppercase',
        ':nth-child(1n) > :first-child': {
            marginRight: 8,
        },
    },
    inp: {
        width: '100%',
        border: `1px solid ${COLORS.lightGray}`,
        borderRadius: 4,
        padding: '13.5px 16px',
    },
    label: {
        paddingBottom: 8,
        fontWeight: '500',
        fontSize: 12,
        color: COLORS.midGray,
    },
    accInpGr: {
        padding: '16px 24px 24px',
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gap: 24,
    },
    map: {
        gridColumn: '1 / 3',
        borderRadius: 4,
        overflow: 'hidden',
        border: `1px solid ${COLORS.lightGray}`,
    },
})
