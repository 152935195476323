import React from 'react'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import { useNavigate } from 'react-router-dom'
import { Briefcase } from '../svgs/Svgs'
import { ageRuFormat, format } from '../../utils/number'
import { COLORS } from '../../utils/colors'
import { genderOptions } from '../../utils/options'

export default function MainInfo({ info }) {
    const navigate = useNavigate()

    return (
        <div className="is-flex direction-column gap-2">
            <label className={cn('align-center gap-2', css(s.label))}><Briefcase /> основное</label>

            <ul className={cn('no_dot_list is-flex direction-column gap-3', css(s.list))}>
                <li className={cn('align-center', css(s.info_item))}>
                    <div>Вакансия</div>

                    <div>
                        {
                            <h3 onClick={() => navigate(`/vacancies/${info.vacancyId}/info`)}
                                className={cn('pointer', css(s.vacancyName))}>{info.vacancyName}
                            </h3>
                            || <div className={css(s.no_info)} />
                        }
                    </div>
                </li>

                <hr className={css(s.hr)} />

                <li className={cn('align-center', css(s.info_item))}>
                    <div>Возраст</div>

                    <div>{info.age ? ageRuFormat(info.age.toString()) : <div className={css(s.no_info)} />}</div>
                </li>

                <hr className={css(s.hr)} />

                <li className={cn('align-center', css(s.info_item))}>
                    <div>Пол</div>

                    <div>
                        { info.gender
                            ? genderOptions.find((e) => e.value === info.gender).label
                            : <div className={css(s.no_info)} />}
                    </div>
                </li>

                <hr className={css(s.hr)} />

                <li className={cn('align-center', css(s.info_item))}>
                    <div>Желаемая ЗП</div>

                    <div>
                        {info.desiredSalary
                            ? `${format(info.desiredSalary.toString())} ${info.currency || ''}`
                            : <div className={css(s.no_info)} />}
                    </div>
                </li>

                <hr className={css(s.hr)} />

                <li className={cn('align-center', css(s.info_item))}>
                    <div>Адрес проживания</div>

                    <div>{info.location || <div className={css(s.no_info)} />}</div>
                </li>

                <hr className={css(s.hr)} />

                <li className={cn('align-center', css(s.info_item))}>
                    <div>Удаленная работа</div>

                    <div>{info.remote ? 'Да' : 'Нет'}</div>
                </li>

                {/* <hr className={css(s.hr)} /> */}

                {/* <li className={cn('align-center', css(s.info_item))}> */}
                {/*     <div>Резюме</div> */}

                {/*     <div className="align-center"> */}
                {/*         <div className={cn('align-center', css(s.pdf))}> */}
                {/*             <img src={pdf} alt="#pdf" /> */}

                {/*             <div className="is-flex direction-column"> */}
                {/*                 <h3>PDF file</h3> */}
                {/*                 <h3>Резюме на позицию UI UX дизайнера</h3> */}
                {/*             </div> */}

                {/*             <DownloadSizeM /> */}
                {/*         </div> */}
                {/*     </div> */}
                {/* </li> */}
            </ul>
        </div>
    )
}

const s = StyleSheet.create({
    list: {
        background: COLORS.white,
        padding: 20,
        border: `1px solid ${COLORS.chineseWhite}`,
        boxShadow: '0px 2px 4px rgba(130, 130, 130, 0.05)',
        borderRadius: 4,
    },
    label: {
        fontWeight: '600',
        fontSize: 13,
        color: '#181A19',
        textTransform: 'uppercase',
    },
    hr: {
        border: `.5px solid ${COLORS.smoothGray}`,
        borderRadius: 12,
        margin: 0,
    },
    info_item: {
        ':nth-child(1n) > :first-child': {
            width: '37%',
            fontWeight: '400',
            fontSize: 13,
            color: COLORS.midGray,
        },
        ':nth-child(1n) > :last-child': {
            width: '63%',
            fontWeight: '500',
            fontSize: 13,
            color: '#181A19',
        },
    },
    no_info: {
        width: '20%',
        height: 1,
        background: '#181A19',
    },
    pdf: {
        background: COLORS.lotion,
        border: `1px solid ${COLORS.smoothGray}`,
        borderRadius: 4,
        padding: '8px 12px',
        gap: 6,
        ':nth-child(1n) > div': {
            ':nth-child(1n) > :first-child': {
                fontWeight: '500',
                fontSize: 11,
                color: COLORS.midGray,
            },
            ':nth-child(1n) > :last-child': {
                fontWeight: '600',
                fontSize: 12,
                color: '#181A19',
            },
        },
        ':nth-child(1n) > :last-child': {
            ':nth-child(1n) > *': {
                stroke: COLORS.mainColor,
            },
        },
    },

    vacancyName: {
        ':hover': {
            textDecoration: 'underline',
            color: COLORS.blue,
        },
    },
})
