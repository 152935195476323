import React from 'react'
import cn from 'classnames'
import { Field } from 'formik'
import { css, StyleSheet } from 'aphrodite'
import ValidationErrorMessage from './ValidationErrorMessage'
import { COLORS } from '../../utils/colors'

export default function InputForm({
    name,
    className,
    type,
    label,
    validate,
    component = 'input',
    optional = false,
    icon,
    disabled,
    contClassname,
    secondaryLabel,
    labelClassName,
    ...attributes
}) {
    return (
        <div className={contClassname}>
            {label ? (
                <div className="justify-between">
                    <span style={{ paddingBottom: 3 }} className={cn(css(s.lab), labelClassName)}>
                        <label htmlFor={name}>
                            {label} &nbsp;
                            {optional ? <span>не обязательно</span> : null}
                        </label>
                    </span>

                    {secondaryLabel || null}
                </div>
            ) : null}

            <div className="align-center is-relative">
                <Field
                    className={cn(
                        'form-inp_pl',
                        css(disabled && s.disabled),
                        { [css(s.icon)]: icon }, component, className,
                    )}
                    type={type || 'text'}
                    name={name}
                    id={name}
                    disabled={disabled}
                    component={component}
                    validate={validate}
                    {...attributes}
                />

                <span className="align-center" style={{ position: 'absolute', right: 16 }}>
                    {icon}
                </span>
            </div>

            <ValidationErrorMessage name={name} />
        </div>
    )
}

const s = StyleSheet.create({
    lab: {
        fontSize: 12,
        color: COLORS.midGray,
        fontWeight: '500',
    },
    icon: {
        paddingRight: 46,
    },
    disabled: {
        background: '#f2f2f2',
    },
})
