import React from 'react'
import { css, StyleSheet } from 'aphrodite'
import { range } from 'lodash'
import Checkbox from '../common/Checkbox'
import { COLORS } from '../../utils/colors'
import { LoadingSkeleton } from '../common/LoadingSkeleton'

export function CheckboxSection({ name, options, filter, setFilter, loading }) {
    function onCheck(id) {
        if (filter[name]) {
            if (filter[name].includes(id)) {
                setFilter({
                    ...filter,
                    [name]: filter[name].filter((fil) => fil !== id),
                })
            } else {
                setFilter({ ...filter, [name]: [...filter[name], id] })
            }
        } else setFilter({ ...filter, [name]: [id] })
    }

    const isChecked = (id) => filter[name] && filter[name].includes(id)

    return (
        <ul className="no_dot_list is-flex direction-column gap-2">
            {!loading ? options.slice(0, 5).map((option) => (
                <li onClick={() => onCheck(option.id)} key={option.id} className="justify-between pointer">
                    <div className="align-center gap-2">
                        <Checkbox checked={isChecked(option.id)} onClick={() => {}} />

                        <h3 className={css(s.label, !isChecked(option.id) && s.labelColor)}>
                            {option.name}
                        </h3>
                    </div>

                    {/* <span className={cn(
                                    'flex-cent', css(s.count, !isChecked(option.id) && s.countColor)
                                )}> */}
                    {/*     {option.count} */}
                    {/* </span> */}
                </li>
            )) : range(5).map((key) => (
                <li key={key} className="align-center gap-2">
                    <LoadingSkeleton inline width={16} height={16} />
                    <LoadingSkeleton inline width={100} height={13} />
                </li>
            ))}
        </ul>
    )
}

const s = StyleSheet.create({
    count: {
        border: '1px solid #2EA97D33',
        borderRadius: 4,
        background: '#FAFFFC',
        color: COLORS.mainColor,
        fontSize: 10,
        fontWeight: '500',
        height: 16,
        padding: '0 4px',
    },
    countColor: {
        color: COLORS.midGray,
        background: COLORS.smoothGray,
        border: `1px solid ${COLORS.smoothGray}`,
    },
    label: {
        fontSize: 11,
        fontWeight: '500',
        color: COLORS.lightBlack,
    },
    labelColor: {
        color: COLORS.gray,
    },
})
