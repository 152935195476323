import React, { useEffect } from 'react'
import { MainInfo } from './vacancyForm/MainInfo'
import { VacancyInfo } from './vacancyForm/VacancyInfo'
import { Requirements } from './vacancyForm/Requirements'
import { MoreOptions } from './vacancyForm/MoreOptions'
import { useIsInViewport } from '../../utils/viewport'

export default function Edit({ setFieldValue, values, stepRefs, setCurrentStep }) {
    const isInViewport = [
        useIsInViewport(stepRefs.current[0]),
        useIsInViewport(stepRefs.current[1], 0.25),
        useIsInViewport(stepRefs.current[2], 0.75),
        useIsInViewport(stepRefs.current[3], 0.85),
    ]

    useEffect(() => {
        const step = isInViewport.lastIndexOf(true)
        setCurrentStep(step + 1)
    }, [isInViewport])

    return (
        <>
            <MainInfo innerRef={stepRefs.current[0]} setFieldValue={setFieldValue} values={values} />

            <VacancyInfo values={values} innerRef={stepRefs.current[1]} setFieldValue={setFieldValue} />

            <Requirements innerRef={stepRefs.current[2]} values={values} setFieldValue={setFieldValue} />

            <MoreOptions innerRef={stepRefs.current[3]} setFieldValue={setFieldValue} values={values} />
        </>
    )
}
