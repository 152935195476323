import React, { useEffect, useRef } from 'react'
import cn from 'classnames'
import { css, StyleSheet } from 'aphrodite'
import { isEmpty } from 'lodash'
import { COLORS } from '../../../utils/colors'
import { ArrowNextSizeL } from '../../svgs/Svgs'
import Checkbox from '../../common/Checkbox'

const steps = [
    { step: 1, name: 'Основная информация' },
    { step: 2, name: 'О вакансии' },
    { step: 3, name: 'Язык и навыки' },
    { step: 4, name: 'Дополнительно' },
]

export default function NavigationBar({ isPreview, values, setFieldValue, currentStep, scrollToStep, initialValues }) {
    const ref = useRef()

    function shadowOnScroll(event) {
        if (!ref.current) return
        if (event.target.scrollTop > 24) {
            if (!ref.current.classList.contains(css(s.shadow))) ref.current.classList.add(css(s.shadow))
        } else ref.current.classList.remove(css(s.shadow))
    }

    useEffect(() => {
        const nodeElement = document.getElementById('rp-body')
        nodeElement.addEventListener('scroll', shadowOnScroll)

        return () => {
            nodeElement.removeEventListener('scroll', shadowOnScroll)
        }
    }, [])

    return !isPreview ? (
        <div ref={ref} className={cn('flex-cent', css(s.cont))}>
            <ul className="align-center no_dot_list gap-5">
                {steps.map(({ step, name }) => (
                    <li key={step} className="align-center gap-5  ">
                        <div onClick={() => scrollToStep(step)} className="align-center gap-3 pointer">
                            <div className={cn('flex-cent', css(s.stepNum, currentStep >= step && s.activeStepNum))}>
                                {step}
                            </div>

                            <h3 className={css(s.stepTitle, currentStep >= step && s.activeStepTitle)}>
                                {name}
                            </h3>
                        </div>

                        {step !== 4 && <ArrowNextSizeL color={currentStep >= step ? COLORS.mainColor : COLORS.gray} />}
                    </li>
                ))}
            </ul>
        </div>
    ) : isEmpty(initialValues) ? (
        <div ref={ref} className={cn('align-center gap-3', css(s.cont))}>
            <Checkbox
                isLarge
                checked={values.templateSave}
                setChecked={() => setFieldValue('templateSave', !values.templateSave)}
                className={cn('dis_sl', css(s.checkbox))} />

            <h3
                className={css(s.saveTxt)}
                onClick={() => setFieldValue('templateSave', !values.templateSave)}>
                Сохранить вакансию как шаблон
            </h3>
        </div>
    ) : null
}

const s = StyleSheet.create({
    cont: {
        height: 56,
        width: '100%',
        padding: '0 24px',
        background: COLORS.white,
        transition: 'all 0.1s linear',
        borderTop: `1px solid ${COLORS.smoothGray}`,
        borderBottom: `1px solid ${COLORS.smoothGray}`,
        position: 'relative',
        boxSizing: 'border-box',
    },
    stepNum: {
        transition: 'all 0.3s linear',
        backgroundColor: COLORS.smoothGray,
        color: COLORS.dark,
        borderRadius: 64,
        width: 32,
        height: 32,
        fontSize: 16,
        fontWeight: '500',
    },
    activeStepNum: {
        transition: 'all 0.3s linear',
        backgroundColor: COLORS.mainColor,
        color: COLORS.white,
    },
    stepTitle: {
        color: COLORS.gray,
        fontSize: 16,
        fontWeight: '500',
    },
    activeStepTitle: {
        color: COLORS.mainColor,
    },
    checkbox: {
        width: 24,
        height: 24,
        boxSizing: 'border-box',
    },
    saveTxt: {
        color: COLORS.gray,
        fontSize: 16,
        fontWeight: '500',
        cursor: 'pointer',
        userSelect: 'none',
    },
    shadow: {
        transition: 'all 0.2s linear',
        boxShadow: '0px 2px 4px 0px rgba(130, 130, 130, 0.05)',
    },
})
