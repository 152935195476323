import React from 'react'
import cn from 'classnames'
import { Field, getIn, useFormikContext } from 'formik'
import { css, StyleSheet } from 'aphrodite'
import { Time } from '../svgs/Svgs'
import { COLORS } from '../../utils/colors'

export default function InputTimeFromTo({
    name,
    help,
    icon,
    label,
    loading,
    onInput,
    options,
    validate,
    onChange,
    className,
    staticText,
    placeholder,
    fieldClassName,
    labelClassName,
    ...attributes
}) {
    const { touched, errors } = useFormikContext()

    const isValid = (getIn(errors, name[0]) && getIn(touched, name[0])) || (getIn(errors, name[1]) && getIn(touched, name[1])) // eslint-disable-line

    return (
        <div className={cn('field', fieldClassName)}>
            <div className={cn('control', css(s.field))}>
                {label ? (
                    <label className={labelClassName} htmlFor={name}>
                        {label} {isValid && <span style={{ color: COLORS.lightRed }}>*</span>}
                    </label>
                ) : null}

                <div className={cn(
                    css(s.cont),
                    'form-inp_pl ',
                    { [css(s.icon)]: icon },
                    { [css(s.dng)]: isValid },
                    className,
                )}>
                    <Field
                        defaultValue="00:00"
                        type="time"
                        id={name[0]}
                        name={name[0]}
                        validate={validate}
                        className={cn('input', css(s.inp))}
                        {...attributes} />

                    <div>-</div>

                    <Field
                        type="time"
                        id={name[1]}
                        name={name[1]}
                        validate={validate}
                        className={cn('input', css(s.inp))}
                        {...attributes} />
                </div>

                <span className="is-flex" style={{ position: 'absolute', right: 16, top: 34 }}><Time /></span>

                {help && (!errors[name] || !touched[name]) ? (
                    <p className={css(s.formHint)}>{help}</p>
                ) : null}
            </div>
        </div>
    )
}

const s = StyleSheet.create({
    field: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
    },
    cont: {
        border: `1px solid ${COLORS.lightGray}`,
        borderRadius: 4,
        height: 42,
        gap: 4,
        padding: '0 9px 0 16px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        ':nth-child(1n) > :first-child': {
            gap: '0.25rem',
            ':nth-child(1n) > *': {
                cursor: 'text',
            },
        },
    },
    input_wrap: {
        position: 'relative',
        minWidth: 1,
        maxWidth: 100,
        height: 16,
        ':nth-child(1n) > input': {
            position: 'absolute',
            left: 0,
        },
    },
    inp: {
        outline: 'none',
        border: 'none',
        overflow: 'hidden',
        padding: 0,
        fontSize: 14,
        fontWeight: '500',
        color: COLORS.dark,
        '::placeholder': {
            color: COLORS.gray,
        },
        fontFamily: 'Roboto, sans-serif',
    },
    icon: {
        paddingRight: 46,
    },
    dng: {
        borderColor: COLORS.lightRed,
    },
    formHint: {
        marginTop: 4,
        fontSize: 12,
        fontWeight: '400',
        color: COLORS.lightBlack,
    },
    text: {
        color: COLORS.gray,
        fontSize: 14,
        fontWeight: '400',
    },
})
