import React from 'react'
import cn from 'classnames'
import { css, StyleSheet } from 'aphrodite'
import { COLORS } from '../../../utils/colors'

export default function KeySkills({ values }) {
    const { skills, knowledge } = values

    return (
        <div className={cn('is-flex direction-column gap-5')}>
            <h3 className={css(s.name)}>Ключевые навыки:</h3>

            {!!knowledge.length && (
                <div className="is-flex direction-column gap-2">
                    <h3 className={css(s.title)}>Знание программ</h3>

                    <div className="align-center gap-2 is-flex-wrap">
                        {knowledge.map((kn) => (
                            <div className={cn('flex-cent', css(s.mark))}>{kn.name}</div>
                        ))}
                    </div>
                </div>
            )}

            {!!skills.length && (
                <div className="is-flex direction-column gap-2">
                    <h3 className={css(s.title)}>Навыки</h3>

                    <div className="align-center gap-2 is-flex-wrap">
                        {skills.map((skill) => (
                            <div className={cn('flex-cent', css(s.mark))}>{skill.name}</div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    )
}
const s = StyleSheet.create({
    topic: {
        padding: 24,
        borderBottom: `1px solid ${COLORS.lightGray}`,
    },
    name: {
        color: COLORS.dark,
        fontSize: 20,
        fontWeight: '600',
    },
    skills: {
    },
    title: {
        color: COLORS.midGray,
        fontSize: 12,
        fontWeight: '500',
    },
    mark: {
        height: 24,
        padding: '0 8px',
        borderRadius: 4,
        border: '1px solid rgba(46, 169, 125, 0.20)',
        background: 'rgba(46, 169, 125, 0.03)',
        color: COLORS.mainColor,
        fontSize: 11,
        fontWeight: '500',
    },
})
