import React, { forwardRef, useEffect, useImperativeHandle, useRef } from 'react'
import cn from 'classnames'
import { css, StyleSheet } from 'aphrodite'
import { format, integersOnly } from '../../utils/number'
import { COLORS } from '../../utils/colors'

export const InputFromTo = forwardRef(({
    name,
    label,
    className,
    staticText,
    placeholder,
    defaultValue,
    labelClassName,
    onChange = () => {},
}, ref) => {
    const fromRef = useRef()
    const toRef = useRef()

    useEffect(() => {
        if (defaultValue[0]) fromRef.current.style.width = `${fromRef.current.scrollWidth}px` // eslint-disable-line no-param-reassign, max-len
        if (defaultValue[1]) toRef.current.style.width = `${toRef.current.scrollWidth}px` // eslint-disable-line no-param-reassign, max-len
    }, [defaultValue])

    useImperativeHandle(ref, () => ({
        clearValue() {
            fromRef.current.value = ''
            fromRef.current.style.width = '1px'

            toRef.current.value = ''
            toRef.current.style.width = '1px'
        },
    }))

    function handleChange(event, innerRef, type) {
        event.target.value = format(integersOnly(event.target.value)) // eslint-disable-line no-param-reassign
        innerRef.current.style.width = '1px' // eslint-disable-line no-param-reassign
        if (innerRef.current.scrollWidth > 5) {
            innerRef.current.style.width = `${innerRef.current.scrollWidth}px` // eslint-disable-line no-param-reassign
        }

        onChange(event, type)
    }

    return (
        <div className={css(s.field)}>
            {label ? (
                <label className={labelClassName} htmlFor={name}>{label}</label>
            ) : null}

            <div
                onClick={() => (
                    fromRef.current.value.length === 0 ? fromRef.current.focus() : toRef.current.focus()
                )}
                className={cn(css(s.cont), 'form-inp_pl ', className)}>
                <div className="align-center">
                    <div
                        className="align-end"
                        onClick={(e) => { e.stopPropagation(); fromRef.current.focus() }}>
                        <h3 className={css(s.text)}>
                            {placeholder[0]}
                        </h3>

                        <input
                            name={`${name}.from`}
                            ref={fromRef}
                            defaultValue={format(defaultValue[0])}
                            maxLength="11"
                            pattern="[0-9]*"
                            className={css(s.inp)}
                            style={{ width: 1 }}
                            onChange={(event) => handleChange(event, fromRef, 'from')}
                        />
                    </div>

                    <h3>-</h3>

                    <div
                        style={{ marginLeft: 1 }}
                        className="align-end"
                        onClick={(e) => { e.stopPropagation(); toRef.current.focus() }}>
                        <h3 className={css(s.text)}>
                            {placeholder[1]}
                        </h3>

                        <input
                            name={`${name}.to`}
                            ref={toRef}
                            maxLength="11"
                            pattern="[0-9]*"
                            defaultValue={format(defaultValue[1])}
                            className={css(s.inp)}
                            style={{ width: 1 }}
                            onChange={(event) => handleChange(event, toRef, 'to')}
                        />
                    </div>

                    {staticText && <div className={css(s.text)}>{staticText}</div>}
                </div>
            </div>
        </div>
    )
})

const s = StyleSheet.create({
    field: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
    },
    cont: {
        border: `1px solid ${COLORS.lightGray}`,
        borderRadius: 4,
        height: 36,
        padding: '8px 16px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        ':nth-child(1n) > :first-child': {
            gap: 2,
            ':nth-child(1n) > *': {
                cursor: 'text',
            },
            ':nth-child(1n) > div': {
                gap: 2,
            },
            ':nth-child(1n) > h3': {
                fontSize: 11,
                fontWeight: '500',
                color: COLORS.dark,
            },
        },
    },
    inp: {
        border: 'none',
        outline: 'none',
        padding: 0,
        fontSize: 11,
        fontWeight: '500',
        color: COLORS.dark,
    },
    dng: {
        borderColor: COLORS.lightRed,
    },
    formHint: {
        marginTop: 4,
        fontSize: 12,
        fontWeight: '400',
        color: COLORS.lightBlack,
    },
    text: {
        color: COLORS.gray,
        fontSize: 14,
        fontWeight: '400',
    },
})
